import { Warning } from "@mui/icons-material";
import React from "react";

function HelpDecriptionComponent() {
  return (
    <div className="w-[350px] bg-white h-[600px] shadow-md p-[10px] text-gray select-none">
      <h3 className="font-[600] text-black text-[22px]">
        <Warning fontSize="14px" style={{ marginRight: "4px" }} />
        Hướng dẫn
      </h3>
      <hr className="w-full mb-2" />
      <p>1. Điền đầy đủ thông tin hiển thị trong CV</p>
      <p>2. Xem trước CV</p>
      <p>
        3. Bấm nút <b>Lưu CV</b>
      </p>
      <p>4. Tải CV về máy (nếu cần)</p>

      <p className="text-black my-2 font-[600]">Một số lưu ý chung: </p>
      <p>- Ảnh đại diện (bắt buộc)</p>
      <p>- Facebook và Linked in có thể không nhập</p>
      <p>- Sắp xếp thời gian từ mới -&gt; cũ</p>
      <p>- Ưu tiên thông tin các phần ứng tuyển lên đầu</p>
      <p>- Ngày tháng nên dùng 01/2023, 01-2023</p>

      <p className="text-black my-2 font-[600]">Lưu ý: </p>
      <p>
        1. Trong trường hợp bạn chưa có tài khoản trên hệ thống, bạn có thể chọn
        phần tạo tài khoản nhanh sau khi nhấn nút <b>Lưu CV</b>
      </p>
      <p>2. Đảm bảo rằng email và số điện thoại bạn cung cấp đúng định dạng</p>
      <p>vd: +8412345678 hoặc 012345678</p>
      <hr className="w-full my-2" />
      <p>
        Nếu bạn cần hỗ trợ vui lòng chọn phần chat dưới góc phải màn hình để
        được hỗ trợ.
      </p>
    </div>
  );
}

export default HelpDecriptionComponent;
