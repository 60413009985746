import React from "react";
import { cx } from "../../../lib/cx";
import { SaveAs } from "@mui/icons-material";
import { useTheme } from "../../../lib/theme/useTheme";
import { useConfigCvFile } from "../../builder/hook";

function SaveAsBlock() {
  const { theme } = useTheme();
  const { handleSaveCv } = useConfigCvFile();

  return (
    <div className="w-full mx-auto lg:max-w-[1170px] flex justify-end items-center mt-4">
      <button
        className={cx(
          "px-5 py-2 text-white rounded-[12px] text-[14px]",
          theme?.button?.background
        )}
        onClick={handleSaveCv}
      >
        <SaveAs style={{ fontSize: "16px", marginRight: "4px" }} />
        Lưu CV
      </button>
    </div>
  );
}

export default SaveAsBlock;
