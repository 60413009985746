import React, { useState } from "react";
import { cx } from "../../../lib/cx";
import { EyeFilled } from "@ant-design/icons";
import { Pagination } from "antd";
import { changePagination } from "../../../lib/theme/config";
import RecruiterContractModal from "../../components/Modal/RecruiterContractModal";

function RecruiterContract({
  search,
  limit = 10,
  onChangePage,
  totalPage = 10,
}) {
  const [toggleDetail, setToggleDetail] = useState();
  const [contractData, setContractData] = useState();
  const data = [
    {
      id: 1,
      code: 1110011,
      fromA: "Nguyễn Văn A",
      fromB: "Trần Văn B",
      startTime: "22-09-2023",
      endTime: "24-09-2023",
      note: "",
      status: "PROCESSING",
    },
    {
      id: 2,
      code: 1110012,
      fromA: "Nguyễn Văn A",
      fromB: "Trần Văn B",
      startTime: "22-09-2023",
      endTime: "24-09-2023",
      note: "",
      status: "SUCCESS",
    },
    {
      id: 3,
      code: 1110013,
      fromA: "Nguyễn Văn A",
      fromB: "Trần Văn B",
      startTime: "22-09-2023",
      endTime: "24-09-2023",
      note: "",
      status: "ERROR",
    },
    {
      id: 4,
      code: 1110014,
      fromA: "Nguyễn Văn A",
      fromB: "Trần Văn B",
      startTime: "22-09-2023",
      endTime: "24-09-2023",
      note: "",
      status: "DUED",
    },
  ];
  return (
    <>
      {data.length > 0 ? (
        <>
          <div className="overflow-x-auto pb-[10px]">
            <table className="w-full min-w-[1517px]">
              <thead>
                <tr>
                  {[
                    "STT",
                    "Mã hợp đồng",
                    "Bên A",
                    "Bên B",
                    "Ngày ký kết",
                    "Ngày hết hạn",
                    "Ghi chú",
                    "Trạng thái",
                    "",
                  ].map((item, index) => (
                    <th
                      key={item + index + "title"}
                      className="px-4 py-1 text-[14px] uppercase text-center bg-[#B7E1FF] rounded-[12px] border-[1px] md:border-[4px] border-[#F4F9FF]"
                    >
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.length > 0 &&
                  data.map((contract, idx) => (
                    <tr
                      key={"recruiter" + idx + "tab"}
                      className="bg-white hover:bg-gray-1 cursor-pointer"
                      onClick={() => {
                        setContractData(contract);
                        setToggleDetail(true);
                      }}
                    >
                      {[
                        (Number(search?.page) || 1) * limit + idx - limit + 1,
                        contract.code,
                        contract.fromA,
                        contract.fromB,
                        contract.startTime,
                        contract.endTime,
                        contract.note,
                      ].map((item, index) => (
                        <td
                          key={"item" + idx + index + "tab"}
                          className={cx(
                            "py-2 px-4 rounded-[12px] border-[1px] md:border-[6px] border-[#F4F9FF] text-center",
                            index === 2 && "!text-right",
                            index === 3 && "!text-right",
                            index === 4 && "!text-right",
                            index === 5 && "!text-right"
                          )}
                        >
                          {item}
                        </td>
                      ))}
                      <td
                        className={cx(
                          "py-2 px-4 rounded-[12px] border-[1px] md:border-[6px] border-[#F4F9FF] w-[130px]"
                        )}
                      >
                        <div
                          className={cx(
                            "text-[12px] rounded-[12px] text-white text-center",
                            contract.status === "SUCCESS" && "bg-green-500",
                            contract.status === "ERROR" && "bg-red-500",
                            contract.status === "DUED" && "bg-orange-500",
                            contract.status === "PROCESSING" && "bg-blue-500"
                          )}
                        >
                          {contract.status === "SUCCESS"
                            ? "Thành công"
                            : contract.status === "PROCESSING"
                            ? "Đang xử lý"
                            : contract.status === "DUED"
                            ? "Hết hạn"
                            : "Lỗi"}
                        </div>
                      </td>
                      <td
                        className={cx(
                          "py-2 px-4 rounded-[12px] border-[1px] md:border-[6px] border-[#F4F9FF] w-[100px]"
                        )}
                      >
                        <div
                          className={cx(
                            "text-[12px] bg-blue-400 rounded-[12px] text-white text-center"
                          )}
                        >
                          <EyeFilled /> Chi tiết
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="w-full my-4 flex justify-center items-center">
            {totalPage > 0 && (
              <Pagination
                defaultCurrent={search?.page ? Number(search?.page) : 1}
                total={totalPage}
                pageSize={limit}
                onChange={onChangePage}
                itemRender={changePagination}
              />
            )}
          </div>
        </>
      ) : (
        <p>Khách hàng chưa đăng ký dịch vụ trên hệ thống</p>
      )}
      <RecruiterContractModal
        data={contractData}
        active={toggleDetail}
        onChangeActive={() => setToggleDetail(!toggleDetail)}
      />
    </>
  );
}

export default RecruiterContract;
