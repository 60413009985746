import React from "react";
import { createGroupSchema } from "../../utils/validations";
import { useFormik } from "formik";
import InputAdmin from "../../admin/components/Input";
import UserService from "../../services/UserService";
import {
  adminRejectRecruiter,
  adminValidateRecruiter,
} from "../../services/PORT";
import { useAuth } from "../../admin/Auth/useAuth";

function RejectRecruiterModal(props) {
  const { admin } = useAuth();
  const { userId, active, onChangeActive } = props;

  const createGroupFormik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: createGroupSchema,
    onSubmit: (values) => {
      createNewGroup(values);
    },
  });

  const createNewGroup = async (values) => {
    if (userId.status === "new") {
      await UserService.verifyAccount(adminRejectRecruiter, {
        id: userId.id,
        reason: values.name,
        role: admin.role,
      })
        .then((res) => {
          window.history.back();
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (userId.status === "old") {
      await UserService.verifyPosting(adminValidateRecruiter, {
        id: userId.id,
        accountStatus: "Reject",
        reason: values.name,
        role: admin.role,
        isValidInfo: false,
      })
        .then((res) => {
          window.history.back();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <div
      className={`overflow-x-auto md:overflow-hidden fixed inset-0 z-9999 h-screen outline-none focus:outline-none justify-center items-center c-modal bg-black bg-opacity-20 ${
        active ? "block" : "hidden"
      }`}
    >
      <div className="relative flex justify-center items-center mx-auto w-full px-[10px] md:px-0 h-[100vh] select-none">
        <div className="border-0  relative flex flex-col w-full bg-white outline-none focus:outline-none sm:w-[420px] rounded-[12px]">
          <form
            className="relative flex-auto w-full sm:w-[420px]"
            encType="multipart/form-data"
          >
            <div className="w-full bg-white rounded-[12px] p-[20px]">
              <div className="text-black text-[20px] font-[700] mb-4 border-b-[1px]">
                Từ chối phê duyệt nội dụng
              </div>
              <div className="w-full">
                <div className="">
                  <InputAdmin
                    name="name"
                    label="Lý do"
                    placeholder="Lý do từ chối tài khoản"
                    onChange={createGroupFormik.handleChange}
                  />
                </div>
              </div>
              <div className="w-full flex justify-end items-center mt-[20px]">
                <div
                  className="px-9 py-2 border rounded-[12px] text-[12px] cursor-pointer mr-2"
                  onClick={onChangeActive}
                >
                  Hủy
                </div>
                <div
                  className="px-9 py-2 bg-[#1495F3] text-white rounded-[12px] text-[12px] cursor-pointer"
                  onClick={createGroupFormik.handleSubmit}
                >
                  Lưu
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RejectRecruiterModal;
