import React from "react";
import { cx } from "../../../lib/cx";
import { renderDefaultItem } from "../../../utils";
import { getListColor } from "../../configs/data";
import { EyeFilled } from "@ant-design/icons";
import { Upload } from "@mui/icons-material";
import { useTheme } from "../../../lib/theme/useTheme";

function CardTemplate({ data, onDetail, onEdit }) {
  const { theme } = useTheme();
  return (
    <div className="w-full h-[380px] bg-[white] shadow-lg cursor-pointer">
      <div
        className="w-full h-[280px] flex justify-center items-center bg-[rgba(0,0,0,0.1)] relative z-10"
        onClick={onDetail}
      >
        {renderDefaultItem(4).map((item) => (
          <p
            key={"break-item-" + item}
            className={cx(
              "absolute uppercase space-x-4 -rotate-45 z-30 text-white mt-[20px] whitespace-normal"
            )}
            style={{
              left: `-${15 * item}px`,
              top: `${item + 40 * item}px`,
            }}
          >
            {renderDefaultItem(item).map((c) => (
              <span key={"item-char-" + c} className="ml-4 opacity-50">
                LDO CV
              </span>
            ))}
          </p>
        ))}
        {renderDefaultItem(4).map((item) => (
          <p
            key={"break-item-" + item}
            className={cx(
              "absolute uppercase space-x-4 -rotate-45 z-30 text-white mb-[20px] whitespace-normal"
            )}
            style={{
              right: `-${1 * item}px`,
              bottom: `${item + 20 * item}px`,
            }}
          >
            {renderDefaultItem(item).map((c) => (
              <span key={"item-char-" + c} className="ml-4 opacity-50">
                LDO CV
              </span>
            ))}
          </p>
        ))}
        <div className="w-[180px] h-[240px] z-10">
          <img
            src={data?.image}
            alt=""
            className={cx("max-w-full max-h-full object-cover")}
          />
        </div>
      </div>
      <div className="p-4 flex justify-between items-center z-40">
        <div>
          <p className={"font-[600]"}>{data?.name}</p>
          <div className="flex space-x-2 z-30 my-2">
            {data?.subName &&
              (getListColor(data.subName) || []).map((item) => (
                <div
                  className="w-4 h-4 z-40 rounded-[4px]"
                  style={{ background: `${item}` }}
                ></div>
              ))}
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <button
            className="text-[12px] bg-[rgba(0,0,0,0.3)] text-white px-3 py-1 rounded-[10px]"
            onClick={onDetail}
          >
            <EyeFilled /> Xem trước
          </button>
          <button
            className={cx(
              "text-[12px] text-white px-3 py-1 rounded-[10px]",
              theme?.button?.background
            )}
            onClick={() => onEdit(data?.subName)}
          >
            <Upload fontSize="14px" /> Dùng mẫu
          </button>
        </div>
      </div>
    </div>
  );
}

export default CardTemplate;
