import React from "react";
import { cx } from "../../lib/cx";
import UserService from "../../services/UserService";

function DeleteCvModal({ active, data, onChangeActive, onReloadPage }) {
  const deleteCv = async () => {
    UserService.candidateRemoveCv(
      data?.isUpload
        ? {
            id: data?.data?.candidateId,
            cvUploadimg: data?.data?.original_image,
          }
        : {
            id: data?.data?.candidateId,
            cvPdfimg: data?.data?.original_image,
          }
    )
      .then(() => {
        onReloadPage();
      })
      .catch((err) => {
        console.log("error", new Error(err));
      });
  };
  return (
    <div
      className={cx(
        `overflow-hidden fixed inset-0 z-9999 h-screen outline-none focus:outline-none justify-center items-center c-modal bg-black bg-opacity-40`,
        active ? "block" : "hidden"
      )}
    >
      <div className="relative flex justify-center items-center mx-auto w-full px-[10px] md:px-0 h-[100vh] select-none">
        <div className="border-0  relative flex flex-col w-full bg-white outline-none focus:outline-none sm:w-[420px] rounded-[12px]">
          <div className="relative flex-auto p-4 md:p-[24px] w-full sm:w-[420px]">
            <div className="w-full">
              <p className="text-black text-[18px] font-[600] text-center pb-8">
                Xóa CV
              </p>
              <p>Bạn có chắc chắn xóa cv này khỏi hệ thống!</p>
              <p></p>
            </div>
            <div className="w-full flex justify-end items-center mt-[20px]">
              <div
                className="px-9 py-2 border rounded-[12px] text-[12px] cursor-pointer mr-2"
                onClick={onChangeActive}
              >
                Hủy
              </div>
              <div
                className="px-9 py-2 bg-[#1495F3] text-white rounded-[12px] text-[12px] cursor-pointer"
                onClick={deleteCv}
              >
                OK
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteCvModal;
