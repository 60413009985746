import {
  CaretDownOutlined,
  CaretUpOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { DeleteOutline } from "@mui/icons-material";
import React from "react";
import { debounce } from "../../../utils";
import { cx } from "../../../lib/cx";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  changeFormToChange,
  selectFormToChange,
} from "../../redux/slice/setting";
import {
  addNewItem,
  deleteItem,
  moveItemSelect,
} from "../../redux/slice/builder";

function SettingChildBock({ index, isActived, color, form, children }) {
  const dispatch = useAppDispatch();
  const { childActive } = useAppSelector(selectFormToChange);
  const actived = `${form}-${index}`;
  const handleChangeActive = () => {
    dispatch(changeFormToChange({ field: "childActive", value: actived }));
  };
  return (
    <div
      className="relative"
      onMouseMove={debounce(handleChangeActive, 10)}
    >
      <div
        className={cx(
          "hover:border-[1px] hover:border-dashed hover:cursor-pointer",
          color ? `hover:border-[#bbb]` : "hover:border-white",
          isActived &&
            childActive === actived &&
            "border hover:border-dashed border-[#bbb]"
        )}
      >
        {children}
      </div>
      {isActived && childActive === actived && (
        <div className="bg-[#ccc] p-[2px] shadow-md absolute -top-[20px] left-0 cursor-pointer z-50">
          <div className="w-full flex justify-center items-center">
            <div
              className="p-1 bg-white flex justify-center items-center mr-[2px]"
              onClick={debounce(() => {
                dispatch(moveItemSelect({ type: "up", id: index, form: form }));
              }, 100)}
            >
              <CaretUpOutlined style={{ fontSize: "8px" }} />
            </div>
            <div
              className="p-1 bg-white flex justify-center items-center mr-[2px]"
              onClick={debounce(() => {
                dispatch(
                  moveItemSelect({ type: "down", id: index, form: form })
                );
              }, 100)}
            >
              <CaretDownOutlined style={{ fontSize: "8px" }} />
            </div>
            <div
              className="px-3 w-[80px] py-[2px] bg-green-300 text-[8px] mr-[2px] flex justify-center items-center"
              onClick={() => {
                dispatch(addNewItem({ field: form, id: index }));
              }}
            >
              Thêm mới{" "}
              <PlusOutlined style={{ fontSize: "8px", marginLeft: "3px" }} />
            </div>
            <div
              className="px-3 py-[2px] bg-red-400 text-[8px] flex justify-center items-center"
              onClick={() => {
                dispatch(deleteItem({ field: form, id: index }));
              }}
            >
              Xóa{" "}
              <DeleteOutline
                style={{ color: "white", fontSize: "8px", marginLeft: "3px" }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SettingChildBock;
