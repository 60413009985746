import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { Welfare4 } from "../../components/CardInfor.js";
import "./index.css";
import { Col, Row } from "antd";
import { Flick2 } from "../../components/slick.js";
import { Carousel } from "antd";
import Banner from "../../components/Banner.jsx";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import UserService from "../../services/UserService.js";
import { getImage, getJD } from "../../services/PORT.js";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { routes } from "../PathRouting.js";
// import LazyLoad from "react-lazyload";
import {
  getImageUrl,
  getLocation,
  getSalary,
  renderDefaultItem,
} from "../../utils/index.js";
import queryString from "query-string";
import CardBox from "../../components/CardItem/CardBox.jsx";
import { RightOutlined } from "@ant-design/icons";
import CardBoxLoading from "../../components/CardItem/CardBoxLoading.jsx";
import { useTheme } from "../../lib/theme/useTheme.js";
import { cx } from "../../lib/cx.js";
import HeaderSeo from "../../components/Header/HeaderSeo.jsx";
import AdminService from "../../services/AdminService.js";
import ChooseCvCreate from "../../components/Modals/ChooseCvCreate.jsx";
import CardNewsFit, {
  CardNewsFitLoading,
} from "../../components/Card/CardNewsFit.jsx";

const jd1 = "/asserts/jd1.png";
const jd2 = "/asserts/jd2.png";
const jd3 = "/asserts/jd3.png";
const jd4 = "/asserts/jd4.png";
const jd5 = "/asserts/jd5.png";
const jd6 = "/asserts/jd6.png";
const jd7 = "/asserts/jd7.png";
const jd8 = "/asserts/jd8.png";

// Thêm các thẻ Welfare cần hiển thị ở đây

const FirstPage = () => {
  const location = useLocation();
  const { theme } = useTheme();
  // const [listCompany, setListCompany] = useState([]);
  const [listNewJob, setListNewJob] = useState();
  const history = useHistory();

  const [run, setRun] = useState(true);
  const loginStatus = JSON.parse(localStorage.getItem("loginStatus"));
  const [loadingNewJob, setLoadingNewJob] = useState(false);
  const [adsShow, setAdsShow] = useState();
  const [activeCvCreate, setActiveCvCreate] = useState(false);
  const [listNews, setListNews] = useState();
  const [loadingNews, setLoadingNews] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (location.pathname === routes.home.path && run) {
        if (loginStatus?.role && loginStatus.role === "Recruiter") {
          history.push(routes.recruiterDashboard.path);
          return;
        }
        try {
          setRun(false);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [run, location.pathname]);

  // const handlePressContact1 = (id) => {
  //   history.push(routes.listAllJD.parentPath + `/${id}`);
  // };

  // const handlePressContact2 = (id) => {
  //   history.push(routes.recruiterDetail.parentPath + `/${id}`, {
  //     id: id,
  //   });
  // };

  useEffect(() => {
    if (!listNewJob) {
      getNewListJd();
      getAdsPriority();
      getListNews();
    }
  }, [listNewJob]);

  const getListNews = async () => {
    setLoadingNews(true);
    const page = Math.floor(Math.random() * 8);

    const params = queryString.stringify({
      offset: page - 1,
      limit: 6,
      status: "ACTIVE",
    });

    AdminService.getAllNews(params).then(async (response) => {
      const data = response.data.data;

      let items = data.items;
      const image = items.map((item) => item.image);
      const listImage = await UserService.getImageByName(image);
      items = items.map((item) => ({
        ...item,
        image: getImageUrl(listImage.data.data, item.image),
      }));
      setListNews(items);
      setLoadingNews(false);
    });
  };

  const getNewListJd = () => {
    setLoadingNewJob(true);
    // UserService.countJDbyCompanyName(countJDbyCompanyName)
    //   .then(async (res) => {
    //     const arrayData = res.data.data;

    //     const listImage = arrayData.map((item) => item.avatar[0]);
    //     const listAvatar = await UserService.getImageByName(listImage);

    //     const listCompany = arrayData.map((item) => ({
    //       companyName: item.companyName,
    //       count: item.count,
    //       image: getImageUrl(listAvatar.data.data, item.avatar[0]),
    //       recruiterId: item.recruiterId,
    //       location: item.location,
    //     }));
    //     setListCompany(listCompany);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    UserService.getListLatestJD(true, getJD)
      .then(async (res) => {
        const arrayData = res.data.data.items;
        const listNewJobMap = await Promise.all(
          arrayData.map(async (data) => {
            let recruiter = await UserService.getDescriptionRecuiter(
              data.recruiterId
            );
            recruiter = recruiter.data.data;
            const image = await UserService.getImage(getImage, {
              filename: recruiter.avatarImg,
            });
            return {
              id: data.id,
              image:
                (image?.data?.data &&
                  image.data.data.length > 0 &&
                  image.data.data[0].fileUrl) ||
                "",
              job: data.jobName,
              name_company: recruiter.enterprise,
              location: getLocation(data.location),
              salary: getSalary(data.minSalary, data.maxSalary, data.salary),
            };
          })
        );
        setListNewJob(listNewJobMap);
        setLoadingNewJob(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAdsPriority = async () => {
    const response = await AdminService.getAdsShowHome();
    let result = response.data.data;
    const image = result.map((item) => item.image);
    const listImage = await UserService.getImageByName(image);
    result = result.map((item) => ({
      ...item,
      image: getImageUrl(listImage.data.data, item.image),
    }));
    setAdsShow(result);
  };

  const listBanner = ["/sale/banner-2.jpg", "/sale/banner-2.jpg"];

  const listBrand = [];

  const listDepartment = [
    {
      name: "Xây dựng",
      icon: jd1,
    },
    {
      name: "Khách sạn",
      icon: jd8,
    },
    {
      name: "Du lịch",
      icon: jd7,
    },
    {
      name: "Marketing",
      icon: jd4,
    },
    {
      name: "Điện cơ khí",
      icon: jd5,
    },
    {
      name: "Giáo dục",
      icon: jd3,
    },
    {
      name: "Tư vấn",
      icon: jd6,
    },
    {
      name: "Giao thông vận tải",
      icon: jd2,
    },
  ];

  const getLink = (name) => {
    const param = queryString.stringify({
      department: name,
      page: 1,
    });
    return routes.workList.path + "?" + param;
  };

  const handleCreateCv = () => {
    setActiveCvCreate(true);
  };

  return (
    <div>
      <HeaderSeo
        title="Tìm kiếm cơ hội việc làm trong nhiều lĩnh vực khác nhau"
        description="Lao động online- Nhanh chóng chớp cơ hội tìm kiếm việc làm phù hợp với bản thân"
      />
      <div
        className={cx(
          `bg-gradient-to-r from-gray-300 pt-[50px] pb-[30px]`,
          theme?.landingPage?.banner?.from,
          theme?.landingPage?.banner?.to
        )}
      >
        <div className="mx-auto h-auto w-full lg:max-w-[1170px] grid grid-cols-1 md:grid-cols-3 gap-2 relative">
          {renderDefaultItem(8).map((item, index) => (
            <div
              key={"border-item-" + index}
              className={cx(
                "absolute -top-2 md:top-0 w-[300px] sm:w-[370px] md:w-[260px] h-full border-r-2 border-t-2 rotate-45 md:rotate-[55deg] rounded-tr-[18px] border-t-white border-r-white border-solid"
              )}
              style={{ left: `${-200 + item * 20}px` }}
            ></div>
          ))}

          <div className="w-full px-[10px] z-[50]">
            <Banner className="w-full" bgButton={theme?.button?.background} />

            <div className="grid grid-cols-4 sm:grid-cols-8 md:grid-cols-4 gap-1 my-4">
              {listBrand.map((item) => (
                <div
                  key={"brand" + item}
                  className="w-full h-[80px] bg-white flex justify-center items-center p-1"
                >
                  <img
                    src={item}
                    alt=""
                    className="max-w-full max-h-full object-cover"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-1 md:col-span-2 rounded-[12px] px-[10px]">
            <Carousel
              autoplay
              autoplaySpeed={5000}
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, Pagination, Navigation]}
              slidesPerView={1}
              loop={true}
            >
              {listBanner.map((banner, idx) => (
                <div
                  key={"banner" + idx}
                  className="w-full h-[180px] sm:h-[350px] rounded-[10px]"
                >
                  <img
                    src={banner}
                    className="w-full h-full object-cover rounded-[10px]"
                    alt=""
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
      <section
        className={cx(
          "w-full py-[40px] z-[50]",
          theme?.landingPage?.sectionTop?.gradient,
          theme?.landingPage?.sectionTop?.from,
          theme?.landingPage?.sectionTop?.to
        )}
      >
        <div className="mx-auto h-auto w-full lg:max-w-[1170px] relative">
          <div className="absolute -top-[40px] -left-[32%] z-20 hidden xl2:block">
            <div className="w-[370px] h-[512px]">
              {adsShow && adsShow.length > 0 && adsShow[0] && (
                <img
                  src={adsShow[0]?.image}
                  alt=""
                  className="w-full h-full object-cover"
                />
              )}
            </div>
          </div>
          <div className="absolute top-[475px] -left-[32%] z-20 hidden xl2:block">
            <div className="w-[370px] h-[512px]">
              {adsShow && adsShow.length > 0 && adsShow[1] && (
                <img
                  src={adsShow[1]?.image}
                  alt=""
                  className="max-w-full h-full object-cover"
                />
              )}
            </div>
          </div>
          {/* <div className="px-4 py-[40px]">
            <h1
              className={cx(
                "text-[28px] md:text-[36px] font-[700]",
                theme?.landingPage?.heading?.title
              )}
            >
              Top công ty hàng đầu
            </h1>
            <div className="my-[40px]">
              <LazyLoad once={true} height={500}>
                <Flick data={listCompany} onClick={handlePressContact1} />
              </LazyLoad>
            </div>
          </div> */}
          <div className="absolute -top-[40px] -right-[32%] z-20 hidden xl2:block">
            <div className="w-[370px] h-[512px]">
              {adsShow && adsShow.length > 0 && adsShow[2] && (
                <img
                  src={adsShow[2]?.image}
                  alt=""
                  className="w-full h-full object-cover"
                />
              )}
            </div>
          </div>
          <div className="absolute top-[475px] -right-[32%] z-20 hidden xl2:block">
            <div className="w-[370px] h-[512px]">
              {adsShow && adsShow.length > 0 && adsShow[3] && (
                <img
                  src={adsShow[3]?.image}
                  alt=""
                  className="max-w-full h-full object-cover"
                />
              )}
            </div>
          </div>
        </div>

        <div className="mx-auto h-auto w-full lg:max-w-[1170px]">
          <div className="px-4 py-[40px]">
            <div className="w-full md:flex my-[40px] md:justify-between md:items-center">
              <div>
                <h1
                  className={cx(
                    "text-[28px] md:text-[36px] font-[700]",
                    theme?.landingPage?.heading?.title
                  )}
                >
                  Việc làm mới nhất
                </h1>
                <h4
                  className={cx(
                    "text-[14px] md:text-[18px] opacity-80",
                    theme?.landingPage?.heading?.subTitle
                  )}
                >
                  Khám phá cơ hội việc làm mới nhất tại chúng tôi
                </h4>
              </div>

              <div className="flex justify-end items-center">
                <div
                  className={cx(
                    "text-[16px] cursor-pointer",
                    theme?.landingPage?.heading?.title
                  )}
                  onClick={() => history.push(routes.workList.path)}
                >
                  Xem tất cả <RightOutlined />
                </div>
              </div>
            </div>

            <div className="w-full grid grid-rows-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
              {loadingNewJob ? (
                <>
                  {renderDefaultItem(21).map((item) => (
                    <CardBoxLoading key={"new-jd" + item} />
                  ))}
                </>
              ) : (
                <>
                  {listNewJob &&
                    listNewJob.length > 0 &&
                    listNewJob.map((item, idx) => (
                      <CardBox key={"new-job" + idx} data={item} />
                    ))}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="mx-auto h-auto w-full lg:max-w-[1170px]">
          <div className="px-4 py-[40px]">
            <div className="w-full md:flex my-[40px] md:justify-between md:items-center">
              <div>
                <h1
                  className={cx(
                    "text-[28px] md:text-[28px] font-[700]",
                    theme?.landingPage?.heading?.title
                  )}
                >
                  Tin tức
                </h1>
                <h4
                  className={cx(
                    "text-[14px] md:text-[18px] opacity-80",
                    theme?.landingPage?.heading?.subTitle
                  )}
                >
                  Danh sách tin tức mới nhất
                </h4>
              </div>

              <div className="flex justify-end items-center">
                <div
                  className={cx(
                    "text-[16px] cursor-pointer",
                    theme?.landingPage?.heading?.title
                  )}
                  onClick={() => history.push(routes.newsList.path)}
                >
                  Xem tất cả <RightOutlined />
                </div>
              </div>
            </div>

            <div className="w-full grid grid-rows-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
              {loadingNews &&
                renderDefaultItem(6).map((item) => (
                  <CardNewsFitLoading key={"news-loading" + item} />
                ))}
              {listNews?.map((news, idx) => (
                <CardNewsFit key={"news-item" + idx} data={news} />
              ))}
            </div>
          </div>
        </div>

        {/* <div className="mx-auto h-auto w-full lg:max-w-[1170px]">
          <div className="px-4">
            <div className="w-full md:flex md:justify-between md:items-center">
              <div className="w-full text-center my-[40px]">
                <h1
                  className={cx(
                    "text-[28px] md:text-[36px] font-[700]",
                    theme?.landingPage?.heading?.title
                  )}
                >
                  Nhà tuyển dụng hàng đầu
                </h1>
                <h4
                  className={cx(
                    "text-[18px] opacity-80",
                    theme?.landingPage?.heading?.subTitle
                  )}
                >
                  Xây dựng đội ngũ vượt trội với chúng tôi - Nhà tuyển dụng hàng
                  đầu
                </h4>
              </div>
            </div>

            <Layout className="bg-inherit">
              <Stack
                style={{
                  marginBottom: "4%",
                }}
              >
                <LazyLoad>
                  <Flick1 data={listCompany} onClick={handlePressContact2} />
                </LazyLoad>
              </Stack>
            </Layout>
          </div>
        </div> */}
      </section>

      <section
        className={cx(
          "w-full py-[40px]",
          theme?.landingPage?.sectionBottom?.gradient,
          theme?.landingPage?.sectionBottom?.from,
          theme?.landingPage?.sectionBottom?.to
        )}
      >
        <div className="mx-auto h-auto w-full lg:max-w-[1170px]">
          <div className="px-4">
            <div className="w-full md:flex md:justify-between md:items-center my-[40px]">
              <div>
                <h1
                  className={cx(
                    "text-[24px] md:text-[36px] font-[700]",
                    theme?.landingPage?.heading?.title
                  )}
                >
                  Các ngành nghề nổi bật
                </h1>
                <p
                  className={cx(
                    "text-[16px] md:text-[18px] font-plusJakartaSansBold opacity-80",
                    theme?.landingPage?.heading?.subTitle
                  )}
                >
                  Khám phá các ngành nghề nổi bật với chúng tôi
                </p>
              </div>

              <div className="flex justify-end items-center">
                <div
                  className={cx(
                    "text-[16px]",
                    theme?.landingPage?.heading?.title
                  )}
                >
                  Xem tất cả <RightOutlined />
                </div>
              </div>
            </div>
            <Stack direction={"row"}>
              <Row gutter={16} style={{ width: "100%", marginLeft: "0" }}>
                {listDepartment.map((item, idx) => (
                  <Col
                    key={"col" + idx + "1"}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}
                    xl={6}
                  >
                    <Welfare4
                      icon={item.icon}
                      title={item.name}
                      href={getLink(item.name)}
                    />
                  </Col>
                ))}
              </Row>
            </Stack>
          </div>
        </div>

        <div className="mx-auto h-auto w-full lg:max-w-[1170px] pt-[80px]">
          <div className="px-4">
            <div className="w-full md:flex md:justify-between md:items-center my-[40px]">
              <div>
                <h1
                  className={cx(
                    "text-[24px] md:text-[36px] font-[700]",
                    theme?.landingPage?.heading?.title
                  )}
                >
                  Trình tạo CV đúng chuẩn
                </h1>
                <p
                  className={cx(
                    "text-[16px] md:text-[18px] font-plusJakartaSansBold opacity-80",
                    theme?.landingPage?.heading?.subTitle
                  )}
                >
                  Tạo ấn tượng mạnh và thu hút nhà tuyển dụng!
                </p>
              </div>

              <div className="flex justify-end items-center">
                <div
                  className={cx(
                    "text-[16px]",
                    theme?.landingPage?.heading?.title
                  )}
                >
                  Xem tất cả <RightOutlined />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="w-full md:pt-[230px] flex-row">
                <p className={cx("pb-4", theme?.landingPage?.cv?.text)}>
                  Một CV được viết tốt mang lại nhiều lợi ích quan trọng: tạo ấn
                  tượng mạnh, thể hiện chuyên nghiệp và tập trung vào thành tựu,
                  kỹ năng và kinh nghiệm phù hợp, giúp nổi bật và tăng cơ hội
                  mời phỏng vấn.
                </p>
                <div
                  className={cx(
                    "cursor-pointer px-3 py-4 w-full font-plusJakartaSansBold text-white font-[800] text-[18px] text-center rounded-[18px] uppercase",
                    theme?.landingPage?.cv?.button
                  )}
                  onClick={handleCreateCv}
                >
                  Tạo CV ngay
                </div>
              </div>
              <div>
                <Flick2 />
              </div>
            </div>
          </div>
        </div>

        <div className="mx-auto h-auto w-full lg:max-w-[1170px] py-[80px]">
          <div className="px-4">
            <div className="w-full py-2">
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-4">
                {renderDefaultItem(9).map((item, idx) => (
                  <div
                    key={"partner" + idx}
                    className="w-full h-[150px] flex justify-center items-center bg-white p-4 shadow-gray"
                  >
                    <img
                      src={`/partner/partner_${item + 10}.png`}
                      alt=""
                      className="max-w-full max-h-full object-cover"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ChooseCvCreate
        active={activeCvCreate}
        onChangeActive={() => setActiveCvCreate(!activeCvCreate)}
      />
    </div>
  );
};

export default FirstPage;
