import { useEffect, useState } from "react";
import Input from "../../components/Input";
import SelectItem from "../../components/SelectItem";
import ExperienceComponent from "./ExperienceComponent";
import { CameraOutlined, SaveOutlined } from "@ant-design/icons";
import AddComponent from "./AddComponent";
import { useFormik } from "formik";
import { personalProfileSchema } from "../../utils/validations";
import EducationComponent from "./EducationComponent";
import CertificateComponent from "./CertificateComponent";
import UserService from "../../services/UserService";
import { uploadImage } from "../../services/PORT";
import { useHistory } from "react-router-dom";
import { routes } from "../../screens/PathRouting";
import dayjs from "dayjs";
import { notification } from "antd";
import { scrollSmooth } from "../../utils";

export const PersonalProfile = () => {
  const [dataExperiences, setDataExperiences] = useState([]);
  const [dataEducations, setDataEducations] = useState([]);
  const [dataCertificates, setDataCertificates] = useState([]);
  const [activeAdd, setActiveAdd] = useState([]);
  const [district, setDistrict] = useState([]);
  const [cities, setCities] = useState([]);
  const [message, setMessage] = useState("");
  const [avatar, setAvatar] = useState("");
  const [isClick, setIsClick] = useState(false);

  const user = JSON.parse(localStorage.getItem("User"));

  const history = useHistory();
  notification.config({ top: 80, placement: "topRight" });

  useEffect(() => {
    if (cities.length < 1) {
      if (user) {
        setDataExperiences(formatOllData(user.experiences));
        setDataEducations(formatOllData(user.educations));
        setDataCertificates(formatOllData(user.certificates));
        if (user.skills.length > 0) handleAddComponent("skills");
        if (user.languages.length > 0) handleAddComponent("languages");
      }

      UserService.getAllOption("location").then((result) => {
        setCities(result.data.data[0]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cities, user]);

  const personalProfileFormik = useFormik({
    initialValues: {
      fullName: user?.fullName || "",
      email: user?.email || "",
      jobTitle: user?.jobTitle || "",
      phoneNumber: user?.phoneNumber || "",
      gender: user?.gender || "Male",
      dateOfBirth:
        (user?.dateOfBirth &&
          dayjs(new Date(user?.dateOfBirth)).format("YYYY-MM-DD")) ||
        "",
      maritalStatus: user?.maritalStatus || "Single",
      nationality: "vietnam",
      city: user?.address?.city || "",
      district: user?.address?.district || "",
      location: user?.address?.location || "",
      educations: user?.educations || [],
      experiences: user?.experiences || [],
      skills: (user?.skills && user?.skills[0]) || "",
      languages: (user?.languages && user?.languages[0]) || "",
      certificates: user?.certificates || [],
    },
    validationSchema: personalProfileSchema,
    onSubmit: (values) => {
      const dataSend = {
        ...values,
        skills: values.skills ? [values.skills] : [],
        languages: values.languages ? [values.languages] : [],
        experiences: formatArrayData(dataExperiences),
        educations: formatArrayData(dataEducations),
        certificates: formatArrayData(dataCertificates),
        address: {
          city: values.city,
          district: values.district,
          location: values.location,
        },
        candidateId: user?.id,
      };

      delete dataSend.prefixPhone;
      delete dataSend.city;
      delete dataSend.district;
      delete dataSend.location;
      delete dataSend.email;
      if (avatar) {
        const formData = new FormData();
        formData.append("files", avatar);

        UserService.uploadImage(
          uploadImage,
          {
            role: "Candidate",
            email: values.email,
            type: "Avatar",
          },
          formData
        )
          .then((res) => {
            updateCandidate({ ...dataSend, avatarImg: res.data.data.message });
          })
          .catch((error) => {
            setMessage("Upload avatar failed");
          });
      } else {
        updateCandidate(dataSend);
      }
    },
  });

  const updateCandidate = (data) => {
    UserService.updateCandidate(data)
      .then((res) => {
        notification.success({
          message: "Thành công",
          description: "Cập nhật thông tin thành công!",
          duration: 4,
        });
        history.push(routes.userSetting.path);
      })
      .catch((error) => {
        setMessage("Update failed!");
      });
  };

  useEffect(() => {
    if (isClick) {
      const errors = Object.keys(personalProfileFormik.errors);
      if (errors.length > 0) scrollSmooth("id-profile");
      setIsClick(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClick]);

  const formatArrayData = (data) => {
    return data.map((c) => {
      delete c.id;
      delete c.isNew;
      return c;
    });
  };

  const formatOllData = (data) => {
    return data.map((item, index) => ({ ...item, id: index + 1 }));
  };

  const handleChangeArrayValue = (currentData, setData, id, data) => {
    if (!id) {
      const checkNew = currentData.filter((item) => item.isNew);
      if (checkNew.length < 10)
        setData([...currentData, { id: currentData.length + 1, isNew: true }]);
      return;
    }

    let newDataExperiences = currentData;
    if (id && data) {
      newDataExperiences = newDataExperiences.map((item) => {
        if (item.id === id) {
          return { ...item, [data.key]: data.value };
        }
        return item;
      });
    } else {
      newDataExperiences = currentData.filter((item) => item.id !== id);
    }
    setData(newDataExperiences);
  };

  const handleAddComponent = (fiel) => {
    if (!activeAdd.includes(fiel)) {
      setActiveAdd([...activeAdd, fiel]);
    }
  };

  const getFielAdd = (fiel) => {
    return activeAdd.includes(fiel) ? fiel : "";
  };

  const uploadAvatar = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setAvatar(e.target.files[0]);
    }
  };

  const handleChangeDistrict = (value) => {
    personalProfileFormik.values.district = "";
    setDistrict(cities[value]);
  };

  const getErrorMessage = (fiel) => {
    return personalProfileFormik.errors[fiel] &&
      personalProfileFormik.touched[fiel]
      ? personalProfileFormik.errors[fiel]
      : "";
  };

  return (
    <div className="bg-[#F2F7FF] z-1 select-none px-4" id="id-profile">
      <div className="my-[40px]">
        <p className="text-[24px] lg:text-[48px] text-black font-[700]">
          Thiết lập hồ sơ cá nhân
        </p>
        <p className="text-[14px] lg:text-[16px]">
          Hoàn thiện hồ sơ giúp tăng khả năng ứng tuyển ...
        </p>
      </div>

      <form
        className="w-full mx-auto lg:max-w-[1024px] mb-[160px]"
        onSubmit={personalProfileFormik.handleSubmit}
        encType="multipart/form-data"
      >
        <div className="w-full bg-white min-h-[140px] lg:min-h-[240px] rounded-tl-[22px] rounded-tr-[22px] relative">
          <img
            src="/images/background.png"
            alt="background"
            className="object-cover rounded-tl-[22px] rounded-tr-[22px]"
          />
          <div
            className="w-[80px] h-[80px] sm:w-[100px] sm:h-[100px] lg:w-[160px] lg:h-[160px] rounded-[50%] bg-gray-400 absolute left-[40%] -bottom-[20%] sm:left-[45%] cursor-pointer"
            onClick={() => document.getElementById("imageAvatar").click()}
          >
            <img
              src={
                avatar
                  ? URL.createObjectURL(avatar)
                  : user?.avatar
                  ? user.avatar
                  : "/images/avatar.jpeg"
              }
              alt="avatar"
              className="w-full h-full object-cover rounded-[50%]"
            />
            <input
              type="file"
              id="imageAvatar"
              name="imageAvatar"
              onChange={uploadAvatar}
              accept="image/*"
              hidden
            />
            <div className="w-[25px] h-[25px] pl-[5px] pt-[1px] lg:pt-[2px] rounded-[50%] opacity-80 bg-white absolute right-[35%] lg:right-[40%] bottom-1 lg:bottom-2 cursor-pointer">
              <CameraOutlined className="text-[16px]" />
            </div>
          </div>
        </div>

        {/** Information profile */}
        <div
          id="rollback"
          className="w-full bg-white min-h-[550px] mb-[24px] rounded-bl-[22px] rounded-br-[22px] px-[10px] lg:px-[80px] pt-[50px] lg:pt-[90px] pb-[40px]"
        >
          <p className="text-[32px]">Thông tin cá nhân</p>
          <div className="grid grid-cols-2 gap-6 mt-4">
            <Input
              name="fullName"
              value={personalProfileFormik.values.fullName}
              label="Họ và tên"
              placeholder="Nhập họ và tên của bạn"
              className="border-b-[#616161]"
              onChangeInput={personalProfileFormik.handleChange}
              required={true}
              error={getErrorMessage("fullName")}
            />
            <div>
              <label className="text-[16px] font-[600]">
                Ngày sinh <span className="text-red-500">*</span>
              </label>
              <div>
                <Input
                  name="dateOfBirth"
                  value={personalProfileFormik.values.dateOfBirth}
                  placeholder="Nhập ngày sinh của bạn"
                  type="date"
                  pattern="\d{2}-\d{2}\d{4}"
                  className="border-b-[#616161]"
                  onChangeInput={personalProfileFormik.handleChange}
                  required={true}
                  error={getErrorMessage("dateOfBirth")}
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mt-4">
            <Input
              name="email"
              label="Email"
              value={personalProfileFormik.values.email}
              placeholder="Nhập email của bạn"
              className="border-b-[#616161]"
              onChangeInput={personalProfileFormik.handleChange}
              required={true}
              error={getErrorMessage("email")}
              disabled={true}
            />
            <Input
              name="jobTitle"
              label="Nghề nghiệp"
              value={personalProfileFormik.values.jobTitle}
              placeholder="Nhập nghề nghiệp của bạn"
              className="border-b-[#616161]"
              onChangeInput={personalProfileFormik.handleChange}
              required={true}
              error={getErrorMessage("jobTitle")}
            />
          </div>

          <div className="grid grid-cols-2 gap-4 mt-4">
            <div className="w-full">
              <label className="text-[16px] font-[600]">
                Điện thoại <span className="text-red-500">*</span>
              </label>
              <div className="flex justify-center items-center mt-[12px]">
                <Input
                  name="phoneNumber"
                  value={personalProfileFormik.values.phoneNumber}
                  placeholder="Nhập số điện thoại của bạn"
                  className="border-b-[#616161]"
                  onChangeInput={personalProfileFormik.handleChange}
                  error={getErrorMessage("phoneNumber")}
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mt-4">
            <div>
              <p className="text-[16px] font-[600] text-black mb-2">
                Giới tính
              </p>
              <div className="lg:inline lg:mr-10">
                <input
                  name="gender"
                  type="radio"
                  value="Male"
                  checked={personalProfileFormik.values.gender === "Male"}
                  className="mr-[6px] cursor-pointer"
                  onChange={personalProfileFormik.handleChange}
                />
                Nam
              </div>
              <div className="lg:inline lg:ml-[20px] lg:mr-10">
                <input
                  name="gender"
                  type="radio"
                  value="Female"
                  checked={personalProfileFormik.values.gender === "Female"}
                  className="mr-[6px] cursor-pointer"
                  onChange={personalProfileFormik.handleChange}
                />
                Nữ
              </div>
              <div className="lg:inline lg:ml-[20px] lg:mr-10">
                <input
                  name="gender"
                  type="radio"
                  value="Other"
                  className="mr-[6px] cursor-pointer"
                  checked={personalProfileFormik.values.gender === "Other"}
                  onChange={personalProfileFormik.handleChange}
                />
                Khác
              </div>
            </div>

            <div>
              <p className="text-[16px] font-[600] text-black mb-2">
                Tình trạng hôn nhân
              </p>
              <div className="lg:inline lg:mr-10">
                <input
                  name="maritalStatus"
                  type="radio"
                  value="Single"
                  checked={
                    personalProfileFormik.values.maritalStatus === "Single"
                  }
                  className="mr-[6px] cursor-pointer"
                  onChange={personalProfileFormik.handleChange}
                />
                Độc thân
              </div>
              <div className="lg:inline lg:ml-[20px] lg:mr-10">
                <input
                  name="maritalStatus"
                  type="radio"
                  value="Married"
                  checked={
                    personalProfileFormik.values.maritalStatus === "Married"
                  }
                  className="mr-[6px] cursor-pointer"
                  onChange={personalProfileFormik.handleChange}
                />
                Đã kết hôn
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mt-4">
            <div className="w-full">
              <label className="text-[16px] font-[600]">
                Thành phố /Tỉnh <span className="text-red-500">*</span>
              </label>
              <div className="mt-4">
                <SelectItem
                  data={Object.keys(cities)}
                  title="+"
                  name="city"
                  valueInput={personalProfileFormik.values.city}
                  placeholder="Chọn tỉnh/ thành phố"
                  className="!min-h-0 !p-0 !pr-1 sha"
                  parentClass={`w-full mr-[12px] border-x-0 border-t-0 border-b-[1px] ${
                    getErrorMessage("city")
                      ? "border-b-[red]"
                      : "border-b-[#616161]"
                  }`}
                  childrenClass="shadow-lg !bg-[#eee] !top-[30px]"
                  onChangeValue={(event) => {
                    personalProfileFormik.handleChange(event);
                    handleChangeDistrict(event.target.value);
                  }}
                  search={true}
                />
              </div>
            </div>

            <div className="w-full">
              <label className="text-[16px] font-[600]">
                Quận/Huyện <span className="text-red-500">*</span>
              </label>
              <div className="mt-4">
                <SelectItem
                  data={district}
                  title="+"
                  name="district"
                  valueInput={personalProfileFormik.values.district}
                  placeholder="Chọn quận/huyện"
                  className="!min-h-0 !p-0 !pr-1"
                  parentClass={`w-full mr-[12px] border-x-0 border-t-0 border-b-[1px] ${
                    getErrorMessage("district")
                      ? "border-b-[red]"
                      : "border-b-[#616161]"
                  }`}
                  childrenClass="shadow-lg !bg-[#eee] !top-[30px]"
                  onChangeValue={personalProfileFormik.handleChange}
                  search={true}
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mt-4">
            <div className="w-full">
              <Input
                name="location"
                label="Nơi ở hiện tại"
                value={personalProfileFormik.values.location}
                placeholder="Nhập địa chỉ cụ thể"
                className="border-b-[#616161]"
                onChangeInput={personalProfileFormik.handleChange}
                error={getErrorMessage("location")}
                required={true}
              />
            </div>
          </div>
        </div>

        <div
          className="w-full bg-white min-h-[550px] mb-[24px] rounded-[22px] 
        px-[10px] lg:px-[80px] pt-[50px] pb-[40px]"
        >
          <p className="text-[32px]">Thông tin chuyên môn</p>

          <div className="my-[36px]">
            <p className="text-black font-[600] text-[18px] ">
              Kinh nghiệm làm việc
            </p>
            <p className="mb-[10px]">
              Mô tả kinh nghiệm làm việc của bạn càng chi tiết càng tốt, điều đó
              giúp bạn có cơ hội hiển thị nhiều hơn trong kết quả tìm kiếm
            </p>
            {dataExperiences.length > 0 &&
              dataExperiences.map((item, idx) => (
                <ExperienceComponent
                  key={"exp-" + idx + "-ri"}
                  isActived={false}
                  data={item}
                  onChangeRemove={(id) =>
                    handleChangeArrayValue(
                      dataExperiences,
                      setDataExperiences,
                      id
                    )
                  }
                  onChangeValue={(event) => {
                    handleChangeArrayValue(
                      dataExperiences,
                      setDataExperiences,
                      item.id,
                      {
                        key: event.target.name,
                        value: event.target.value,
                      }
                    );
                  }}
                />
              ))}
            <p
              className="text-[#0055FF] text-[12px] font-[600] my-[10px] cursor-pointer"
              onClick={() =>
                handleChangeArrayValue(dataExperiences, setDataExperiences)
              }
            >
              + Thêm kinh nghiệm làm việc
            </p>
          </div>

          <div className="my-[36px]">
            <p className="text-black font-[600] text-[18px] ">Học vấn</p>
            <p className="mb-[10px]">
              Mô tả toàn bộ quá trình học vấn của bạn, cũng như các bằng cấp bạn
              đã được và các khóa huấn luyện bạn đã tham gia
            </p>
            {dataEducations.length > 0 &&
              dataEducations.map((item, idx) => (
                <EducationComponent
                  key={"edu-" + idx + "-cation"}
                  isActived={false}
                  data={item}
                  onChangeRemove={(id) =>
                    handleChangeArrayValue(
                      dataEducations,
                      setDataEducations,
                      id
                    )
                  }
                  onChangeValue={(event) => {
                    handleChangeArrayValue(
                      dataEducations,
                      setDataEducations,
                      item.id,
                      {
                        key: event.target.name,
                        value: event.target.value,
                      }
                    );
                  }}
                />
              ))}
            <p
              className="text-[#0055FF] text-[12px] font-[600] my-[10px] cursor-pointer"
              onClick={() =>
                handleChangeArrayValue(dataEducations, setDataEducations)
              }
            >
              + Thêm học vấn
            </p>
          </div>

          <div className="my-[36px]">
            <p className="text-black font-[600] text-[18px] ">Kỹ năng</p>
            <p className="mb-[10px]">
              Trong phần này, bạn nên liệt kê các kỹ năng phù hợp với vị trí
              hoặc lĩnh vực nghề nghiệp mà bạn quan tâm.
            </p>
            <AddComponent
              name="skills"
              active={getFielAdd("skills")}
              value={personalProfileFormik.values.skills}
              onChangeInput={personalProfileFormik.handleChange}
            />
            <p
              className="text-[#0055FF] text-[12px] font-[600] my-[10px] cursor-pointer"
              onClick={() => handleAddComponent("skills")}
            >
              + Thêm kỹ năng
            </p>
          </div>

          <div className="my-[36px]">
            <p className="text-black font-[600] text-[18px] ">Ngoại ngữ</p>
            <p className="mb-[10px]">
              Cập nhật thông tin ngoại ngữ, điều này sẽ giúp tăng triển vọng tìm
              kiếm công việc.
            </p>
            <AddComponent
              name="languages"
              active={getFielAdd("languages")}
              value={personalProfileFormik.values.languages}
              onChangeInput={personalProfileFormik.handleChange}
            />
            <p
              className="text-[#0055FF] text-[12px] font-[600] my-[10px] cursor-pointer"
              onClick={() => handleAddComponent("languages")}
            >
              + Thêm ngoại ngữ
            </p>
          </div>

          <div className="my-[36px]">
            <p className="text-black font-[600] text-[18px] ">Chứng chỉ</p>
            <p className="mb-[10px]">Cập nhật thông tin các chứng chỉ</p>
            {dataCertificates.length > 0 &&
              dataCertificates.map((item, idx) =>
                item.isNew ? (
                  <CertificateComponent
                    key={"ceri-i-" + idx + "-tes"}
                    isActived={false}
                    data={item}
                    onChangeRemove={(id) =>
                      handleChangeArrayValue(
                        dataCertificates,
                        setDataCertificates,
                        id
                      )
                    }
                    onChangeValue={(event) => {
                      handleChangeArrayValue(
                        dataCertificates,
                        setDataCertificates,
                        item.id,
                        {
                          key: event.target.name,
                          value: event.target.value,
                        }
                      );
                    }}
                  />
                ) : (
                  <CertificateComponent
                    key={"ceri-i-" + idx + "-tes"}
                    isActived={true}
                    data={item}
                    onChangeRemove={(id) =>
                      handleChangeArrayValue(
                        dataCertificates,
                        setDataCertificates,
                        id
                      )
                    }
                    onChangeValue={() => {}}
                  />
                )
              )}
            <p
              className="text-[#0055FF] text-[12px] font-[600] my-[10px] cursor-pointer"
              onClick={() =>
                handleChangeArrayValue(dataCertificates, setDataCertificates)
              }
            >
              + Thêm chứng chỉ
            </p>
          </div>
        </div>

        {message && (
          <a
            href="#rollback"
            id="clickTags"
            className="flex justify-end text-[#ccc] cursor-default"
          >
            {message}
          </a>
        )}

        <div className="w-full flex justify-end items-center">
          <div
            className="px-5 py-2 rounded-[12px] border border-[#8D9092] text-[14px] cursor-pointer"
            onClick={() => {
              window.history.back();
            }}
          >
            Thoát
          </div>
          <button
            type="submit"
            className="px-5 py-2 rounded-[12px] bg-[#1495F3] text-white text-[14px] ml-4"
            onClick={() => {
              setIsClick(true);
              personalProfileFormik.handleSubmit();
            }}
          >
            <SaveOutlined className="mr-2" />
            Lưu thông tin
          </button>
        </div>
      </form>
    </div>
  );
};
