import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import Input from "../../components/Input";
import { useFormik } from "formik";
import { passwordChangeSchema } from "../../utils/validations";
import { useLocation } from "react-router-dom";
import UserService from "../../services/UserService";
import { changePassword } from "../../services/PORT";

export const ChangePassword = (props) => {
  const { onClickSend } = props;
  const location = useLocation();
  const passwordFormik = useFormik({
    initialValues: {
      password: "",
      rePassword: "",
    },
    validationSchema: passwordChangeSchema,
    onSubmit: (values) => {
      UserService.changePassword(changePassword, {
        email: location.state.email,
        role: location.state.role,
        password: values.password,
      }).then(() => {
        onClickSend();
      });
    },
  });
  const getErrorMessage = (field) => {
    return passwordFormik.errors[field] && passwordFormik.touched[field]
      ? passwordFormik.errors[field]
      : "";
  };

  return (
    <div className="w-full lg:w-[450px] bg-white rounded-[12px] p-5">
      <div>
        <p className="text-[30px] text-black font-[700]">Thay đổi mật khẩu</p>
        <p className="text-[12px] ">Vui lòng thay đổi mật khẩu mới!</p>
      </div>

      <form onSubmit={passwordFormik.handleSubmit}>
        <div className="w-full mt-[20px]">
          <label className="block mb-[10px]">Mật khẩu mới</label>
          <Input
            type="password"
            name="password"
            value={passwordFormik.values.password}
            onChangeInput={passwordFormik.handleChange}
            placeholder="Nhập mật khẩu của bạn"
            error={getErrorMessage("password")}
            isCheckPassword
          />
        </div>

        <div className="w-full mt-[20px]">
          <label className="block mb-[10px]">Nhập lại mật khẩu</label>
          <Input
            type="password"
            name="rePassword"
            value={passwordFormik.values.rePassword}
            onChangeInput={passwordFormik.handleChange}
            error={getErrorMessage("rePassword")}
            placeholder="Nhập mật khẩu của bạn"
            isCheckPassword
          />
        </div>
      </form>

      {passwordFormik.errors.password && passwordFormik.touched.password && (
        <div className="flex justify-start items-center mt-[20px]">
          <InfoCircleOutlined className="text-[#E60019] w-[14px] h-[14px]" />
          <p className="text-black ml-[10px] text-[12px]">
            {passwordFormik.errors.password}
          </p>
        </div>
      )}

      <div
        className="w-full mt-[20px] px-2 py-3 rounded-[12px] bg-[#0055FF] text-white text-center cursor-pointer"
        onClick={passwordFormik.handleSubmit}
      >
        Gửi
      </div>
      <p className="mt-3 text-[12px] text-center">
        Nếu bạn vẫn cần trợ giúp,
        <span className="text-[#1495F3]"> LDO Trợ giúp</span>
      </p>
    </div>
  );
};
