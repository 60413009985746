import React, { useState } from "react";
import SelectItem from "../../components/SelectItem";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { clearEmptyItem, debounce } from "../../utils";

function SearchUser(props) {
  const { onChangeValue } = props;
  const location = useLocation();
  const history = useHistory();
  const search = queryString.parse(location.search);
  const [value, setValue] = useState(search?.search || "");

  const statusData = [
    "Tất cả",
    "Theo thời gian mới nhất",
    "Theo thời gian lâu nhất",
    "Theo vai trò",
  ];

  const onChangeParams = (value) => {
    let params = queryString.parse(location.search);
    params = clearEmptyItem({ ...params, ...value });
    history.replace(location.pathname + "?" + queryString.stringify(params));
    onChangeValue(params);
  };

  return (
    <div className="w-full min-h-[34px] flex px-4 py-1 bg-white rounded-[12px]">
      <div className="w-[60%] flex justify-start items-center">
        <div className="w-[14px] h-[14px]">
          <img
            src={"/asserts/icons/search.png"}
            alt="icon search"
            className="object-cover"
          />
        </div>
        <input
          type="text"
          name="search"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="w-full focus:outline-none ml-[10px] text-[14px] border-r-[1px]"
          placeholder="Tìm kiếm"
        />
      </div>
      <div className="w-[30%]">
        <div className="block lg:hidden">
          <SelectItem
            data={statusData}
            name="orderBy"
            valueInput={search?.orderBy}
            placeholder="Sắp xếp"
            onChangeValue={(e) =>
              onChangeParams({ [e.target.name]: e.target.value })
            }
            isHidden={true}
          />
        </div>

        <div className="hidden lg:block">
          <SelectItem
            data={statusData}
            name="orderBy"
            valueInput={search?.orderBy}
            placeholder="Sắp xếp"
            onChangeValue={(e) =>
              onChangeParams({ [e.target.name]: e.target.value })
            }
          />
        </div>
      </div>
      <div className="w-[10%] flex justify-center items-center">
        <div
          className="w-full text-center bg-[#1495F3] rounded-[12px] px-3 py-2 text-white cursor-pointer hidden md:block"
          onClick={() => debounce(onChangeParams({ search: value }))}
        >
          Tìm kiếm
        </div>
        <div
          className="w-[14px] h-[14px] block md:hidden cursor-pointer"
          onClick={() => debounce(onChangeParams({ search: value }))}
        >
          <img
            src={"/asserts/icons/search.png"}
            alt="icon search"
            className="object-cover"
          />
        </div>
      </div>
    </div>
  );
}

export default SearchUser;
