import { EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";

function CardCvShow({ data, onHandleClickIcon, isMore, onHandleClick }) {
  const handleClickBlock = () => {
    if (onHandleClick) {
      onHandleClick(data);
    }
    return;
  };

  const handleClickIcon = () => {
    if (onHandleClickIcon) {
      onHandleClickIcon();
    }
    return;
  };
  return (
    <div
      className="p-2 bg-white mt-1 rounded-[12px] cursor-pointer grid grid-cols-12 shadow-md"
      onClick={handleClickBlock}
    >
      <div className="col-span-1 flex justify-center items-center">
        <img
          src="/asserts/pdfSVG.svg"
          className="w-[36px] h-[36px] object-cover"
          alt=""
        />
      </div>
      <div className="col-span-10 px-3">
        <Tooltip title={data?.name}>
          <h2 className="text-lg line-clamp-1 font-semibold cursor-pointer">
            {data?.name}
          </h2>
        </Tooltip>

        <p className="text-sm text-gray-500">{data?.createAt}</p>
      </div>
      <div className="col-span-1 flex justify-end items-center">
        {isMore ? (
          <MoreOutlined onClick={handleClickIcon} />
        ) : (
          <EyeOutlined
            className="cursor-pointer ml-auto"
            onClick={handleClickIcon}
          />
        )}
      </div>
    </div>
  );
}

export default CardCvShow;
