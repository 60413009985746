const LOCAL_STORAGE_KEY = "cv-normal-state";
const NAME_TEMPLATE_KEY = "cv-template-name";

export const getStateGlobal = () => {
  try {
    const stringifiedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (!stringifiedState) return undefined;
    return JSON.parse(stringifiedState);
  } catch (e) {
    return undefined;
  }
};

export const saveStateGlobal = (state) => {
  try {
    const stringifiedState = JSON.stringify(state);
    localStorage.setItem(LOCAL_STORAGE_KEY, stringifiedState);
  } catch (e) {
    // Ignore
  }
};

export const getNameTemplateCv = () => {
  try {
    const stringifiedState = localStorage.getItem(NAME_TEMPLATE_KEY);
    if (!stringifiedState) return undefined;
    return JSON.parse(stringifiedState);
  } catch (e) {
    return undefined;
  }
};

export const setNameTemplateCv = (state) => {
  try {
    const stringifiedState = JSON.stringify(state);
    localStorage.setItem(NAME_TEMPLATE_KEY, stringifiedState);
  } catch (e) {
    // Ignore
  }
};

export const getHasUsedAppBefore = () => Boolean(getStateGlobal());
