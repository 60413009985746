import React from "react";

function RecruiterContractModal({ data, active, onChangeActive }) {
  return (
    <div
      className={`overflow-x-auto md:overflow-hidden fixed inset-0 z-9999 h-screen outline-none focus:outline-none justify-center items-center c-modal bg-black bg-opacity-20 ${
        active ? "block" : "hidden"
      }`}
    >
      <div className="relative flex justify-center items-center mx-auto w-full px-[10px] md:px-0 h-[100vh] select-none">
        <div className="border-0 relative flex flex-col w-full bg-[#F2F7FF] outline-none focus:outline-none sm:w-[550px] rounded-[12px] p-[10px]">
          <p className="text-center font-[600] text-[20px]">
            Thông tin hợp đồng
          </p>
          <div className="w-full pb-[20px] min-h-[200px]">
            <p>Mã hợp đồng: {data?.code}</p>
            <p>Đại diện bên A: {data?.fromA}</p>
            <p>Đại diện bên B: {data?.fromB}</p>
            <p>Thời gian ký kết: {data?.startTime}</p>
            <p>Thời gian kết thúc: {data?.endTime}</p>
            <p>Ghi chú thêm: {data?.note}</p>
            <p>
              Trạng thái:{" "}
              {data?.status === "SUCCESS"
                ? "Thành công"
                : data?.status === "PROCESSING"
                ? "Đang xử lý"
                : data?.status === "DUED"
                ? "Hết hạn"
                : "Lỗi"}
            </p>
          </div>
          <div className="w-full flex justify-end items-center mt-4 space-x-2">
            <div
              className="px-9 py-2 bg-[#ccc] text-black rounded-[12px] text-[12px] cursor-pointer"
              onClick={onChangeActive}
            >
              Ok
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecruiterContractModal;
