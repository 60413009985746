import React, { useState } from "react";

import { notification } from "antd";
import UserService from "../../services/UserService";
import { login } from "../../services/PORT";
import { useLocation, useHistory } from "react-router-dom";
import { routes } from "../PathRouting";
import { SignUp3 } from "../SignUp/SignUp3";
import { otp as OTP } from "../../services/PORT";
import queryString from "query-string";
import Input from "../../components/Input";
import { useFormik } from "formik";
import { loginSchema } from "../../utils/validations";
import ScrollTop from "../../components/ScrollTop";
import { useLocalStorage } from "../../lib/useLocalStorage";
import { cx } from "../../lib/cx";
import { useTheme } from "../../lib/theme/useTheme";

export const Login = () => {
  const { theme } = useTheme();
  const [confirm, setConfirm] = useState(false);
  const [, setToken] = useLocalStorage("token", null);
  const [dataSignUp, setDataSignUp] = useState({});
  const [api, contextHolder] = notification.useNotification({ top: 80 });
  const [otp, setOTP] = useState(0);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const search = queryString.parse(location.search);
  const role = search?.role || "Candidate";

  const loginFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
      role: role,
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      const search = queryString.parse(location.search);
      const dataLogin = {
        ...values,
        role: search?.role || "Candidate",
      };
      UserService.checkUser(dataLogin, login)
        .then((res) => {
          const data = res.data.data;
          if (!data.isValidEmail) {
            setDataSignUp({
              email: dataLogin.email,
              role: dataLogin.role,
              isValidEmail: data.isValidEmail,
              isActivated: data.isActivated,
            });

            setLoading(true);
            setConfirm(true);
            return;
          }

          if (!data.isActivated) {
            api.error({
              message: `Thông báo`,
              description: "Tài khoản của bạn chưa được admin xác thực",
              placement: "topRight",
              style: { top: 74, padding: "10px", width: "300px" },
            });
            return;
          }

          localStorage.setItem("User", JSON.stringify(data));
          localStorage.setItem(
            "loginStatus",
            JSON.stringify({ status: "success", role: dataLogin.role })
          );
          setToken(data.authToken[0].accessToken);

          if (data.avatarImg && data.avatarImg.length > 0) {
            UserService.getImageByName(data.avatarImg).then((response) => {
              const image = response.data.data[0].fileUrl;
              localStorage.setItem(
                "User",
                JSON.stringify({
                  ...data,
                  avatar: image,
                })
              );
            });
          }

          const params = queryString.parse(location.search);
          let url = routes.home.path;

          if (params.redirect) {
            url = params.redirect;
          } else if (dataLogin.role === "Recruiter") {
            url = routes.recruiterDashboard.path;
          }

          history.push(url, {
            status: "success",
          });
        })
        .catch((err) => {
          console.log(err);
          openNotification();
        });
    },
  });

  const openNotification = () => {
    // thông báo thông tin đăng nhập sai
    return api.error({
      message: `Thông báo`,
      description: "Sai tên đăng nhập hoặc mật khẩu",
      placement: "topRight",
      style: { top: 74, padding: "10px", width: "300px" },
    });
  };

  const checkOTPNotification = () => {
    // thông báo thông tin mật khẩu và mật khẩu nhập lại không giống nhau
    api.error({
      message: "OTP không chính xác",
      placement: "topRight",
      style: { top: 74, padding: "10px", width: "300px" },
    });
  };

  const handleNewAccount = () => {
    let url =
      role === "Recuiter"
        ? routes.signupRecruiter.path
        : routes.signupCandidate.path;

    if (search?.redirect) {
      url = `${url}?redirect=${search.redirect}`;
    }
    history.push(url);
  };

  return (
    <div
      className={cx(
        "min-h-[73vh] grid grid-cols-1 md:grid-cols-2 pt-[80px] pb-[120px]",
        theme?.login?.background
      )}
    >
      {contextHolder}
      <ScrollTop />
      <div className="w-full h-full flex items-center justify-center px-[10px] sm:px-0">
        <div className="bg-white rounded-[10px] p-[20px]">
          <div>
            <h4 className="text-[26px] text-black font-[600]">
              Đăng nhập LDO 👋
            </h4>
            <p className="my-2">
              Tài khoản dành cho
              {role === "Recruiter" ? " nhà tuyển dụng" : " ứng viên"}
            </p>
          </div>
          <form onSubmit={loginFormik.handleSubmit}>
            <div className="my-2">
              <Input
                name="email"
                placeholder="Nhập email của bạn"
                label="Địa chỉ email"
                onChangeInput={loginFormik.handleChange}
                className="!mt-1"
                error={
                  loginFormik.errors.email && loginFormik.touched.email
                    ? loginFormik.errors.email
                    : ""
                }
              />
            </div>
            <div className="my-4">
              <Input
                name="password"
                type="password"
                placeholder="Nhập mật khẩu"
                label="Mật khẩu"
                className="!mt-1"
                onChangeInput={loginFormik.handleChange}
                error={
                  loginFormik.errors.password && loginFormik.touched.password
                    ? loginFormik.errors.password
                    : ""
                }
              />
            </div>

            <p
              className="text-blue-400 hover:underline hover:text-blue-500 cursor-pointer my-4"
              onClick={() =>
                history.push(routes.forgotPassword.path, { role: role })
              }
            >
              Quên mật khẩu?
            </p>
            <button
              type="submit"
              className={cx(
                "w-full text-center text-white rounded-[10px] py-2 cursor-pointer hover:bg-blue-500",
                theme?.button?.background
              )}
              onClick={loginFormik.handleSubmit}
            >
              {role === "Recruiter"
                ? "Đăng nhập nhà tuyển dụng"
                : "Đăng nhập ứng viên"}
            </button>
          </form>

          <div className="flex justify-center items-center mt-3">
            <p>Chưa đăng ký?</p>
            <p
              className="text-blue-500 hover:underline cursor-pointer ml-1"
              onClick={handleNewAccount}
            >
              Tạo tài khoản ngay
            </p>
          </div>
        </div>
        <SignUp3
          open={confirm}
          data={dataSignUp}
          onCickConfirm={() => {
            const dataOTP = {
              email: dataSignUp.email,
              otp: otp,
              role: dataSignUp.role,
            };
            UserService.createUser(dataOTP, OTP)
              .then((res) => {
                history.push(routes.home.path, { status: "success" });
              })
              .catch((err) => {
                checkOTPNotification();
              });
          }}
          onChange={(number) => setOTP(number)}
          onCancel={() => {
            setConfirm(!confirm);
            history.push(routes.home.path);
          }}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default Login;
