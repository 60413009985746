import React from "react";

function TagParam(props) {
  const { name, onRemoveItem, onChangeValue } = props;
  return (
    <div
      className="min-w-[30px] px-4 py-2 bg-[#B5C1FF] inline-block mt-1 mr-2 cursor-pointer rounded-lg text-center"
      onClick={() => onChangeValue(name)}
    >
      {name}
      <span
        className="cursor-pointer ml-2 text-white font-[500]"
        onClick={() => onRemoveItem(name)}
      >
        x
      </span>
    </div>
  );
}

export default TagParam;
