import React from "react";
import SettingBlock from "../Block/SettingBlock";
import { cx } from "../../../lib/cx";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectSetting } from "../../redux/slice/setting";
import InputCv from "../Input";
import { changeItemValue, selectBuilder } from "../../redux/slice/builder";
import SettingChildBock from "../Block/SettingChildBlock";

function PresenterComponent({
  form,
  listTitle,
  position,
  titleColor,
  other,
  border,
}) {
  const setting = useAppSelector(selectSetting);
  const { formHeader } = setting;
  const state = useAppSelector(selectBuilder);

  return (
    <div className="w-full">
      <SettingBlock form={form} position={position || "right"} isActive color>
        <div>
          <div
            className={cx(
              "w-full flex justify-start items-center",
              !titleColor && "border border-x-0 border-t-0 border-b-[black]"
            )}
          >
            <p
              className={cx(
                "font-[700]",
                position && "border border-x-0 border-t-0 border-b-[black]",
                titleColor && "border-none",
                border
              )}
              style={{
                fontFamily: `${setting.fontFamily}`,
                fontSize: `${Number(setting.fontSize) + (position ? 4 : 8)}px`,
                color: titleColor || "white",
              }}
            >
              {formHeader[form]}
            </p>
            {border && (
              <hr
                className="w-full"
                style={{ color: `${setting.themeColor}`, margin: 0 }}
              />
            )}
          </div>
          {state[form] &&
            state[form].map((item, index) => (
              <>
                <SettingChildBock form={form} index={index} isActived>
                  <PresenterItemComponent
                    data={item}
                    id={index}
                    listTitle={listTitle}
                    form={form}
                    position={position}
                    titleColor={titleColor}
                    other={other}
                  />
                </SettingChildBock>
              </>
            ))}
        </div>
      </SettingBlock>
    </div>
  );
}

const PresenterItemComponent = ({
  data,
  listTitle,
  id,
  form,
  titleColor,
  other,
}) => {
  const setting = useAppSelector(selectSetting);
  const dispatch = useAppDispatch();
  const handleChangeInput = (field, value) => {
    dispatch(changeItemValue({ id, form, field, value }));
  };
  return (
    <div className="mt-2">
      <InputCv
        id={`${form}_title_${id}`}
        name="name"
        placeholder={listTitle?.title || ""}
        value={data.name || ""}
        onChangeInput={handleChangeInput}
        className={cx("text-white placeholder:text-[#fffdfd]", other)}
        style={{
          color: titleColor ? "black" : "white",
        }}
      />
      {listTitle?.description && (
        <InputCv
          id={`${form}_desc_${id}`}
          name="description"
          value={data.description || ""}
          placeholder={listTitle.description || ""}
          onChangeInput={handleChangeInput}
          className={cx("text-white placeholder:text-[#fffdfd]", other)}
        />
      )}
      {listTitle?.email && (
        <div className="flex">
          <p
            className="w-[60px]"
            style={{
              fontFamily: `${setting.fontFamily}`,
              fontSize: `${Number(setting.fontSize)}px`,
              color: `${titleColor || "white"}`,
            }}
          >
            {listTitle?.email}:{" "}
          </p>
          <InputCv
            id={`${form}_email_${id}`}
            name="email"
            value={data.email || ""}
            placeholder={listTitle.email || ""}
            onChangeInput={handleChangeInput}
            className={cx("text-white placeholder:text-[#fffdfd] ml-1", other)}
          />
        </div>
      )}
      {listTitle?.phone && (
        <div className="flex">
          <p
            className="w-[100px]"
            style={{
              fontFamily: `${setting.fontFamily}`,
              fontSize: `${Number(setting.fontSize)}px`,
              color: `${titleColor || "white"}`,
            }}
          >
            {listTitle.phone}:{" "}
          </p>
          <InputCv
            id={`${form}_phone_${id}`}
            name="phone"
            value={data.phone || ""}
            placeholder={listTitle.phone || ""}
            onChangeInput={handleChangeInput}
            className={cx("text-white placeholder:text-[#fffdfd] ml-1", other)}
          />
        </div>
      )}
    </div>
  );
};

export default PresenterComponent;
