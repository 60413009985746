import React, { useEffect, useState } from "react";
import { cx } from "../../lib/cx";
import { useTheme } from "../../lib/theme/useTheme";
import { getId, getImageUrl } from "../../utils";
import { useLocation } from "react-router-dom";
import UserService from "../../services/UserService";
import {
  adminAcceptDeny,
  adminValidateRecruiter,
  getBusinessInfoByID,
} from "../../services/PORT";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import {
  ArrowLeftOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { marked } from "marked";
import RejectRecruiterModal from "../../components/Modals/RejectRecruiterModal";
import { useAuth } from "../Auth/useAuth";

function AdminRecruiterDetail() {
  const { theme } = useTheme();
  const { admin } = useAuth();
  const location = useLocation();
  const id = getId(location.pathname);

  const [recruiterDetail, setRecruiterDetail] = useState();
  const [isUpdate, setIsUpdate] = useState(false);

  const [rejectModal, setRejectModal] = useState(false);
  const [idReject, setIdReject] = useState();

  useEffect(() => {
    if (!recruiterDetail) {
      getRecruiterDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recruiterDetail]);

  const getRecruiterDetail = async () => {
    const response = await UserService.getRecruiterByEmail(
      getBusinessInfoByID,
      id
    );

    let recruiter = response.data.data;
    if (recruiter.newlyUpdateData.length > 0) {
      setIsUpdate(true);
      recruiter = recruiter.newlyUpdateData[0];
    }

    const listImage = [recruiter.avatarImg[0], recruiter.aboutUsImg[0]];

    recruiter.officeImg.forEach((item) => listImage.push(item));
    recruiter.storyImg.forEach((item) => listImage.push(item));

    let listImages = await UserService.getImageByName(listImage);
    listImages = listImages.data.data;
    const result = {
      ...recruiter,
      avatarImg: getImageUrl(listImages, recruiter.avatarImg[0]),
      aboutUsImg: getImageUrl(listImages, recruiter.aboutUsImg[0]),
      officeImg: recruiter.officeImg.map((item) =>
        getImageUrl(listImages, item)
      ),
      storyImg: recruiter.storyImg.map((item) => getImageUrl(listImages, item)),
    };

    setRecruiterDetail(result);
  };

  const handleAcceptInfo = async () => {
    const body = {
      id: id,
      accountStatus: "Active",
      role: admin.role,
    };
    try {
      const response = isUpdate
        ? await UserService.verifyAccount(adminValidateRecruiter, {
            ...body,
            isValidInfo: true,
          })
        : await UserService.verifyPosting(adminAcceptDeny, {
            ...body,
            isActivated: true,
          });
      if (response) window.history.back();
    } catch (error) {
      console.log("error", new Error(error));
    }
  };

  return (
    <div
      className={cx(
        "mx-auto min-h-[80vh] lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <div className="px-4 my-[80px]">
        <h2 className={cx("my-4 text-[24px]", theme?.background?.text)}>
          Hồ Sơ Doanh Nghiệp
        </h2>
        <div className="flex justify-between items-center my-[20px]">
          <div
            className="px-4 py-1 bg-gray text-white rounded-[12px] text-[14px] cursor-pointer"
            onClick={() => window.history.back()}
          >
            <ArrowLeftOutlined /> Quay lại
          </div>

          <div className="flex space-x-2 text-[14px]">
            <div
              className="px-4 py-1 bg-red-500 text-white rounded-[12px] cursor-pointer"
              onClick={() => {
                setIdReject({
                  id: id,
                  status: isUpdate ? "old" : "new",
                });
                setRejectModal(true);
              }}
            >
              Từ chối
            </div>
            <div
              className="px-4 py-1 bg-emerald-500 text-white rounded-[12px] cursor-pointer"
              onClick={handleAcceptInfo}
            >
              Phê duyệt
            </div>
          </div>
        </div>

        <div className="w-full bg-white rounded-[12px] p-[10px]">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div className="col-span-2 flex">
              <div className="w-[80px] h-[80px] flex justify-center items-center">
                <img
                  src={recruiterDetail?.avatarImg}
                  alt=""
                  className="max-w-full max-h-full object-cover"
                />
              </div>
              <div className="pt-[18px]">
                <p className="font-[600] text-[16px]">
                  {recruiterDetail?.enterprise}
                </p>
                <p className="text-gray">
                  {recruiterDetail?.enterpriseAddress &&
                    recruiterDetail.enterpriseAddress[0]}
                </p>
              </div>
            </div>
            <div className="col-span-1">
              <p>
                <LanguageOutlinedIcon fontSize="inherit" />{" "}
                {recruiterDetail?.website}
              </p>
              <p>
                <PhoneOutlined /> {recruiterDetail?.phoneNumber}
              </p>
              <p>
                <MailOutlined /> {recruiterDetail?.email}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-[10px] w-full min-h-[80px] bg-white rounded-[15px] p-[20px]">
          <p className="text-[24px] text-black font-[600]">
            Giới thiệu về chúng tôi
          </p>
          <hr className="my-2" />
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
            {recruiterDetail?.introduction && (
              <div
                dangerouslySetInnerHTML={{
                  __html: marked(recruiterDetail.introduction),
                }}
              />
            )}
            <div>
              {recruiterDetail?.aboutUsImg && (
                <div className="w-full h-[240px]">
                  <img
                    src={recruiterDetail.aboutUsImg}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-[10px] w-full min-h-[80px] bg-white rounded-[15px] p-[20px]">
          <p className="text-[24px] text-black font-[600]">Văn phòng</p>
          <hr className="my-2" />
          <div className="w-full">
            <div>
              <p className="text-black text-[14px]">
                Địa chỉ văn phòng của doanh nghiệp.
              </p>
              {recruiterDetail &&
                recruiterDetail.enterpriseAddress &&
                recruiterDetail.enterpriseAddress.length > 0 &&
                recruiterDetail.enterpriseAddress.map((address) => (
                  <p className="text-black text-[14px] py-3">- {address}</p>
                ))}
            </div>
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1">
              {recruiterDetail?.officeImg &&
                recruiterDetail.officeImg.length > 0 &&
                recruiterDetail.officeImg.map((office) => (
                  <div className="w-full h-[180px] rounded-[12px]">
                    <img
                      src={office}
                      alt=""
                      className="w-full h-full object-cover rounded-[12px]"
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="mt-[10px] w-full min-h-[80px] bg-white rounded-[15px] p-[20px]">
          <p className="text-[24px] text-black font-[600]">Câu chuyện</p>
          <hr className="my-[20px]" />
          <div className="w-full">
            <div>
              {recruiterDetail?.story && recruiterDetail.story.length > 0 && (
                <p className="text-black text-[14px] py-3">
                  {recruiterDetail.story.map((item) => (
                    <div dangerouslySetInnerHTML={{ __html: marked(item) }} />
                  ))}
                </p>
              )}
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-1">
              {recruiterDetail?.storyImg &&
                recruiterDetail.storyImg.map((item, index) => (
                  <div
                    key={"story_image" + index}
                    className="w-full h-[180px] rounded-[12px]"
                  >
                    <img
                      src={item}
                      alt=""
                      className="w-full h-full object-cover rounded-[12px]"
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="mt-[10px] w-full min-h-[80px] bg-white rounded-[15px] p-[20px]">
          <p className="text-[24px] text-black font-[600]">Phúc lợi</p>
          <hr className="my-[20px]" />
          <div className="w-full">
            <div>
              {recruiterDetail?.welfare &&
                recruiterDetail.welfare.length > 0 && (
                  <p className="text-black text-[14px] py-3">
                    {recruiterDetail.welfare.map((item) => (
                      <div dangerouslySetInnerHTML={{ __html: marked(item) }} />
                    ))}
                  </p>
                )}
            </div>
          </div>
        </div>
      </div>
      <RejectRecruiterModal
        userId={idReject}
        active={rejectModal}
        onChangeActive={() => {
          setRejectModal(!rejectModal);
        }}
      />
    </div>
  );
}

export default AdminRecruiterDetail;
