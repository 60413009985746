import React from "react";

function Page404() {
  return (
    <div className="w-full fixed top-0 left-0 z-[99999] h-[100vh] flex justify-center items-center bg-gradient-to-b from-[#00B2FF] to-[#002B6B]">
      <div className="px-2">
        <div className="w-full sm:w-[550px]">
          <img
            src="/images/404.png"
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        <div className="text-center">
          <p className="text-white text-[32px]">Not Found 404 Page!</p>
          <p className="text-white text-[18px]">Không tìm thấy url</p>
          <p className="text-center">
            Quay lại{" "}
            <span className="cursor-pointer text-white underline">
              <a href="/">trang chủ</a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Page404;
