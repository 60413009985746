import React from "react";
import CardShow from "../CardItem/CardShow";

function CompanyItem(props) {
  const { companyName, data, onHandleClick } = props;
  return (
    <div className="w-full min-h-[345px] rounded-[12px] bg-white my-[40px]">
      <div className="w-full h-[160px]">
        <img
          src="/asserts/Banner.jpg"
          alt=""
          className="w-full h-full object-cover rounded-tl-[12px] rounded-tr-[12px]"
        />
      </div>
      <div className="p-[10px]">
        <p className="text-[18px] text-black font-[700] pl-[20px] mt-4">
          {companyName}
        </p>
        <div className="w-full">
          {data &&
            data.map((item) => (
              <>
                <div className="my-[10px]">
                  <CardShow
                    id={item?.id}
                    title={item?.position}
                    salary={item?.salary}
                    location={item?.location}
                    date={item?.date}
                    tag={item?.tag}
                  />
                </div>
              </>
            ))}
        </div>

        <div className="w-full">
          <button
            className="w-full px-4 py-3 bg-[#1495F3] text-white rounded-[12px] my-[10px]"
            onClick={onHandleClick}
          >
            Xem thêm
          </button>
        </div>
      </div>
    </div>
  );
}

export default CompanyItem;
