import React from "react";
import { Tooltip } from "antd";
import { EnvironmentOutlined, FileTextOutlined } from "@ant-design/icons";
import { Bt } from "../BtForm";

export const CardHomepage = (props) => {
  const formatLocations = (s) => {
    const temp = s && s.split(",");
    if (s && temp.length <= 2) {
      return s;
    } else if (s) {
      return temp[temp.length - 2] + "," + temp[temp.length - 1];
    }
    return "";
  };

  const formatDateString = (s) => {
    const sDate = new Date(s);
    const year = sDate.getFullYear();
    const month = sDate.getMonth() + 1;
    const day = sDate.getDate();

    return `${day}-${month}-${year}`;
  };

  return (
    <div
      className="bg-white rounded-[22px] flex flex-col w-full min-h-[200px] p-4 cursor-pointer"
      onClick={props.onClick}
    >
      <div className="grid grid-cols-6">
        <div className="col-span-4">
          <div className="flex justify-start items-center">
            <Tooltip title={props.jobName}>
              <h4 className="text-black min-h-[60px] max-h-[60px] font-[500] text-[18px] line-clamp-2">
                {props.jobName}
              </h4>
            </Tooltip>
          </div>
        </div>
        <div className="col-span-2 flex justify-center items-center">
          <div className="flex flex-row space-x-2 items-center border border-gray rounded-[12px] px-2 py-1">
            {props.status && props.status === "ACTIVE" ? (
              <div className="bg-[#268D61] w-3 h-3 rounded-[40px]" />
            ) : (
              <div className="bg-red-500 w-3 h-3 rounded-[40px]" />
            )}

            <p className="text-black text-[12px]">
              {props.status && props.status === "ACTIVE"
                ? "Active"
                : "Inactive"}
            </p>
          </div>
        </div>
      </div>
      <div>
        <p className="text-gray text-[14px]">
          <EnvironmentOutlined /> {formatLocations(props.location)}
        </p>
      </div>
      <div>
        <p>Ngày đăng tuyển: {formatDateString(props.createAt)}</p>
        <br />
      </div>
      <div>
        <p className="font-[800]">Số ứng viên: {props.count}</p>
        <br />
      </div>
      <div className="w-full flex justify-end">
        <Bt
          title={
            <>
              <FileTextOutlined /> Xem chi tiết
            </>
          }
          className="bg-blue-500 rounded-[12px] h-[40px]"
        />
      </div>
    </div>
  );
};

export const CardHomepageAdding = (props) => {
  return (
    <div
      className="rounded-[22px] w-full min-h-[200px] cursor-pointer flex flex-row justify-center items-center border-[3px] border-[#B5C1FF] bg-[#c3cbf48d]"
      onClick={props.onClick}
    >
      <img
        src="/asserts/Rectangle 9571.svg"
        className="w-[50%] h-[50%]"
        alt=""
      />
    </div>
  );
};
