export const content = [
  {
    year: "2004",
    summary:
      "Hợp tác cùng KOTEF, KITECH: tổ chức hội thảo giới thiệu về nền công nghiệp Hàn Quốc và hệ thống thẻ vàng (Gold Card System)",
    detail: (
      <>
        <p className="text-black">
          Từ 2000, Việt Nam đã tham gia tích cực vào tiến trình hội nhập với nền
          kinh tế khu vực và thế giới. Với việc Việt Nam trở thành thành viên
          của nhiều tổ chức khu vực và thế giới, tham gia nhiều Hiệp định Thương
          mại tự do (FTA), mở rộng quan hệ kinh tế song phương với hàng loạt
          quốc gia... đã tạo ra cơ hội cho các doanh nghiệp Việt Nam tiếp cận
          các yếu tố đầu vào như vốn, công nghệ, kinh nghiệm quản lý..., thay
          đổi tư duy sản xuất, làm ăn mới, thúc đẩy chuyển dịch cơ cấu kinh tế,
          nâng cao hiệu quả sản xuất, kinh doanh.
        </p>
        <br />
        <br />
        <p className="text-black">
          Đây là lúc thị trường Việt Nam cấp bách đào tạo nguồn nhân sự chất
          lượng cao để nắm bắt kịp thời yêu cầu của thị trường quốc tế. Nắm bắt
          được nhu cầu của thị trường, Công ty cổ phần Trí Tuệ Việt được thành
          lập vào 24/5/2004 - tiền thân của Ngân Hàng Nhân Lực ngày nay.
        </p>
        <br />
        <br />
        <p className="text-black">
          Xác định trở thành cầu nối cho nguồn nhân lực công nghệ kỹ thuật cao
          tại Việt Nam với thị trường quốc tế. Tạo tiền đề cho sự phát triển về
          công nghệ tiên tiến cho doanh nghiệp Việt Nam qua hợp tác, trao đổi và
          học hỏi. Trên tinh thần đó, Trí Tuệ Việt đã được tin tưởng hợp tác
          cùng KOTEF, KITECH, MOLISA tổ chức hội thảo về nền công nghiệp Hàn
          Quốc và Goldcard System.
        </p>
      </>
    ),
    img: "/history-MPB/mpb-2004.png",
  },
  {
    year: "2005",
    summary:
      "Tổ chức hội chợ việc làm Hightech ở trung tâm tổ chức sự kiện Vân Hồ. Thu hút hàng trăm ứng viên xuất sắc và hàng chục doanh nghiệp Việt - Hàn khắp mọi miền.",
    detail: (
      <>
        <p className="text-black">
          Trí Tuệ Việt hợp tác cùng KITECH và Báo Lao động tổ chức thành công
          hội chợ việc làm Hightech đầu tiên tại trung tâm tổ chức sự kiện Vân
          Hồ với quy mô hàng trăm ứng viên và hàng chục doanh nghiệp Hàn - Việt
          tham gia tuyển chọn. Kết quả đã gây được tiếng vang, tạo lòng tin đối
          với các đối tác trong và ngoài nước không chỉ với Trí Tuệ Việt mà còn
          khẳng định được chất lượng và vị thế cạnh tranh của nguồn lao động
          Việt Nam trên thị trường quốc tế
        </p>
      </>
    ),
    img: "/history-MPB/mpb-2005.png",
  },
  {
    year: "2006",
    summary:
      "Hợp tác cùng FSB, bộ ngoại giao của Hàn Quốc: Tổ chức đào tạo cho các CEO thuộc các tập đoàn hàng đầu Hàn Quốc đang muốn đầu tư vào Việt Nam về chương trình” Văn hoá & con người Việt Nam”.",
    detail: (
      <>
        <p className="text-black">
          Với những thành công ban đầu, Trí Tuệ Việt tiếp tục hợp tác với HSB và
          Hàn Quốc tổ chức đào tạo cho các CEO, các chủ doanh nghiệp lớn của Hàn
          Quốc trong chương trình “ Văn hoá & con người Việt Nam ”
        </p>
        <br />
        <br />
        <p className="text-black">
          Song song với đó, Trí Tuệ Việt đã hợp tác với ICA, Hàn Quốc và VCCI
          chủ đề mở rộng cơ hội kinh doanh ICT Việt Nam - Hàn Quốc.
        </p>
        <br />
        <br />
        <p className="text-black">
          Các dự án hợp tác Trí Tuệ Việt - Hàn Quốc đều đạt được những kết quả
          tích cực. Thời điểm 2004 -2006, Trí Tuệ Việt là đối tác chính thống và
          ưu tiên lựa chọn làm việc có sự thừa nhận của hai chính phủ Hàn-Việt
          trong phân khúc lao động bậc cao, lao động tri thức đi học tập và làm
          việc tại Hàn Quốc.
        </p>
      </>
    ),
    img: "/history-MPB/mpb-2006.png",
  },
  {
    year: "2007",
    summary:
      "Trí Tuệ Việt tiếp tục mở rộng và củng cố mối quan hệ hợp tác sâu rộng với các đối tác tại Hàn Quốc thông qua việc tổ chức chung các sự kiện và hội thảo kết nối Việt Nam và Hàn Quốc.",
    detail: (
      <>
        <p className="text-black">
          Trí Tuệ Việt tiếp tục mở rộng và củng cố mối quan hệ hợp tác sâu rộng
          với các đối tác tại Hàn Quốc thông qua việc tổ chức chung các sự kiện
          và hội thảo kết nối Việt Nam và Hàn Quốc.
        </p>
        <br />
        <br />
        <p className="text-black">
          Thành tựu của chúng tôi đã nhận được sự công nhận và đánh giá cao trên
          cả tạp chí trong nước và quốc tế. Trí Tuệ Việt tự hào được xếp hạng
          trong top 50 doanh nghiệp triển vọng, thể hiện cam kết không ngừng
          phấn đấu và đóng góp tích cực cho sự phát triển của cộng đồng và nền
          kinh tế.
        </p>
      </>
    ),
    img: "/history-MPB/mpb-2007.png",
  },
  {
    year: "2008",
    summary:
      "Hợp tác cùng KOCEMA, VCCI Tổ chức “KOREAN CONSTRUCTION EQUIPMENT TRADE MISSION IN VIET NAM” tại khách sạn Horizon thu hút đông đảo các doanh nghiệp Việt Hàn tham dự.",
    detail: (
      <>
        <p className="text-black">
          Hội chợ việc làm tổ chức tại khách sạn Horizon một lần nữa khẳng định
          năng lực và vị thế của Trí Tuệ Việt khi kết nối thành công hàng trăm
          việc làm mới cho người lao động với các doanh nghiệp trong và ngoài
          nước.
        </p>
        <br />
        <br />
        <p className="text-black">
          Trí Tuệ Việt đã cùng tập đoàn FPT tổ chức chương trình đào tạo cao cấp
          cho các lãnh đạo, CEOs các công ty Hàn Quốc. Chương trình này tổ chức
          thành công đã nâng cao được chất lượng và vị thế cũng như tiềm năng
          của con người Việt Nam.
        </p>
        <br />
        <br />
        <p className="text-black">
          Với những kinh nghiệm liên kết , đào tạo và hỗ trợ về nhân lực mạnh
          mẽ, Trí Tuệ Việt đã sẵn sàng đẩy mạnh việc mở rộng thị trường, hợp tác
          với nhiều thị trường mới: Malaysia, Singapore, Nhật Bản, Pháp đều mang
          lại nguồn doanh thu tốt Cùng với đó, năng lực chuyên môn và chất lượng
          đội ngũ ngày càng được nâng cao. Quy mô nhân sự công ty lên tới gần
          300 người.
        </p>
      </>
    ),
    img: "/history-MPB/mpb-2008.png",
  },
  {
    year: "2009",
    summary:
      "Tổng giám đốc của Trí Tuệ Việt được Tập đoàn tài chính- công nghệ Tessi bổ nhiệm làm Tổng giám đốc khu vực Châu Á – Thái Bình Dương, mở ra thời cơ phát triển vàng thâm nhập thị trường công nghệ - tài chính cao cấp với sự hỗ trợ trực tiếp từ các chuyên gia công nghệ đến từ Pháp",
    detail: (
      <>
        <p className="text-black">
          Tổng giám đốc của Trí Tuệ Việt được Tập đoàn tài chính- công nghệ
          Tessi bổ nhiệm làm Tổng giám đốc khu vực Châu Á – Thái Bình Dương, mở
          ra thời cơ phát triển vàng thâm nhập thị trường công nghệ - tài chính
          cao cấp với sự hỗ trợ trực tiếp từ các chuyên gia công nghệ đến từ
          Pháp.
        </p>
        <br />
        <br />
        <p className="text-black">
          Ngày 31/12/2009 Trí Tuệ Việt kí kết, phụ trách phát triển Báo Lao Động
          điện tử. Đây có thể coi là một năm đánh dấu sư phát triển rực rỡ của
          Trí Tuệ Việt khi được các đối tác hàng đầu thế giới cũng như trong
          nước ghi nhận, hợp tác sâu rộng, cùng phát triển về nhân lực, công
          nghệ và thanh toán tài chính quốc tế.
        </p>
      </>
    ),
    img: "/history-MPB/mpb-2009-1.png",
  },
  {
    year: "2009",
    summary:
      "Trí Tuệ Việt kí kết, phụ trách phát triển Báo Lao Động điện tử. Trí Tuệ Việt khi được các đối tác hàng đầu thế giới cũng như trong nước ghi nhận, hợp tác sâu rộng, cùng phát triển về nhân lực, công nghệ và thanh toán tài chính quốc tế",
    detail: (
      <>
        <p className="text-black">
          Tổng giám đốc của Trí Tuệ Việt được Tập đoàn tài chính- công nghệ
          Tessi bổ nhiệm làm Tổng giám đốc khu vực Châu Á – Thái Bình Dương, mở
          ra thời cơ phát triển vàng thâm nhập thị trường công nghệ - tài chính
          cao cấp với sự hỗ trợ trực tiếp từ các chuyên gia công nghệ đến từ
          Pháp.
        </p>
        <br />
        <br />
        <p className="text-black">
          Ngày 31/12/2009 Trí Tuệ Việt kí kết, phụ trách phát triển Báo Lao Động
          điện tử. Đây có thể coi là một năm đánh dấu sư phát triển rực rỡ của
          Trí Tuệ Việt khi được các đối tác hàng đầu thế giới cũng như trong
          nước ghi nhận, hợp tác sâu rộng, cùng phát triển về nhân lực, công
          nghệ và thanh toán tài chính quốc tế.
        </p>
      </>
    ),
    img: "/history-MPB/mpb-2009-2.png",
  },
  {
    year: "2010",
    summary:
      "Vào ngày 08/06/2010, Công ty cổ phần giải pháp nguồn nhân lực Trí Tuệ Việt được thành lập, kế thừa toàn bộ những tinh hoa về kinh nghiệm, đội ngũ lãnh đạo và uy tín trong vào ngoài nước từ công ty mẹ Trí Tuệ Việt",
    detail: (
      <>
        <p className="text-black">
          Vào ngày 08/06/2010, Công ty cổ phần giải pháp nguồn nhân lực Trí Tuệ
          Việt được thành lập, kế thừa toàn bộ những tinh hoa về kinh nghiệm,
          đội ngũ lãnh đạo và uy tín trong vào ngoài nước từ công ty mẹ Trí Tuệ
          Việt Tập trung cung ứng nhân sự là các kỹ sư công nghệ, kĩ sư kĩ thuật
          cao cho các thị trường quốc tế truyền thống và các nhân sự cao cấp,
          nhân sự được đào tạo cho các doanh nghiệp FDI đầu tư tại Việt Nam.
        </p>
        <br />
        <br />
        <p className="text-black">
          Trong suốt giai đoạn 2004 - 2010, Trí Tuệ Việt đã trở thành doanh
          nghiệp tiên phong cung cấp nhân lực công nghệ thông tin và kỹ sư công
          nghệ cao đạt tiêu chuẩn thẻ vàng của Hàn Quốc. Trong thời gian 2006 -
          2016, Ngân Hàng Nhân Lực đã thành công cung ứng các kỹ sư IT cho công
          ty TNHH Tessi SA, nơi cung cấp các sản phẩm công nghệ thông tin cho
          các ngân hàng hàng đầu thế giới như: HSBC, BNP PariBas,...
        </p>
      </>
    ),
    img: "/history-MPB/mpb-2010.png",
  },
];
