import React from "react";
import NamePositionBlock from "../../components/Block/NamePositionBlock";
import { useAppSelector } from "../../redux/hooks";
import {
  selectFormOrder,
  selectFormToShow,
  selectSetting,
} from "../../redux/slice/setting";
import AvatarDefaultCv from "../../components/Avatar";
import ContactBlock from "../../components/Block/ContactBlock";
import DoubleComponent from "../../components/SubComponent/DoubleComponent";

function LeftComponent() {
  const { themeColor } = useAppSelector(selectSetting);
  const { left } = useAppSelector(selectFormOrder);
  const formToShow = useAppSelector(selectFormToShow);

  const formTypeToComponent = {
    skills: (
      <DoubleComponent
        form="skills"
        listTitle={{ title: "Tên kĩ năng" }}
        titleColor={themeColor}
        other="text-black"
      />
    ),
    interestes: (
      <DoubleComponent
        form="interestes"
        listTitle={{ title: "Tên sở thích" }}
        titleColor={themeColor}
        other="text-black placeholder:text-gray"
        position="!border-none"
      />
    ),
    addtional_informations: (
      <DoubleComponent
        form="addtional_informations"
        listTitle={{ title: "Thông tin thêm" }}
        other="text-black placeholder:text-gray"
        position="!border-none"
        titleColor={themeColor}
      />
    ),
  };

  return (
    <div className="pt-[30px]">
      <NamePositionBlock
        headCss={`text-center`}
        colorHeader={themeColor}
        childCss="text-center"
      />
      <div className="w-full flex justify-center items-center">
        <div className="w-[285px] h-[285px] p-4">
          <AvatarDefaultCv
            shape={`rounded-full`}
            styleCss={{ border: `4px solid ${themeColor}` }}
          />
        </div>
      </div>
      <div className="px-4">
        <ContactBlock titleColor={themeColor} />
        {left &&
          left.map((field) => {
            if (formToShow[field]) {
              const component = formTypeToComponent[field];
              return (
                <React.Fragment>
                  <div
                    style={{
                      border: `1px solid ${themeColor}`,
                      marginTop: "10px",
                    }}
                  ></div>
                  {component}
                </React.Fragment>
              );
            }
            return null;
          })}
      </div>
    </div>
  );
}

export default LeftComponent;
