import { CloseOutlined } from "@ant-design/icons";
import React, { useState } from "react";

function AddImage(props) {
  const { data, onChangeImage, onHandleRemove } = props;
  const [avatar, setAvatar] = useState();
  const [error, setError] = useState(false);

  const uploadAvatar = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const maxSize = 200 * 1024;
    if (file && file.size > maxSize) {
      setError(true);
    } else if (e.target.files && file) {
      setAvatar(file);
      onChangeImage({ ...data, file: file });
    }
  };
  return (
    <form
      className={`w-full h-[245px] rounded-[22px] ${
        error ? "border border-red-500" : "border border-[#D9D9D9]"
      } bg-[#F5F7FF] relative cursor-pointer select-none`}
      encType="multipart/form-data"
    >
      {(data?.image || avatar) && (
        <div
          className="z-[999] absolute top-2 right-4"
          onClick={() => onHandleRemove(data, data?.id)}
        >
          <CloseOutlined />
        </div>
      )}
      <div
        className="w-full z-10 h-full absolute left-0 top-0 flex flex-col justify-center items-center"
        onClick={() => document.getElementById(`${data.id}`).click()}
      >
        <input
          type="file"
          id={data.id}
          name={data.id}
          onChange={uploadAvatar}
          accept="image/*"
          hidden
        />
        {data?.image ? (
          <img
            src={data?.image ? data.image : ""}
            alt=""
            className="w-full h-full rounded-[22px] object-cover"
          />
        ) : !avatar || error ? (
          <>
            <img
              src="/images/upload-image.png"
              alt=""
              className="w-[100px] h-[100px]"
            />
            <p className="text-[18px] font-[600] text-[#1495F3] mt-2">
              Tải lên ảnh
            </p>
            <p className={error ? "text-red-400" : "text-[#1495F3]"}>
              Dung lượng không quá 200kb
            </p>
          </>
        ) : (
          <img
            src={avatar ? URL.createObjectURL(avatar) : ""}
            alt=""
            className="w-full h-full rounded-[22px] object-cover"
          />
        )}
      </div>
    </form>
  );
}

export default AddImage;
