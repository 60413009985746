import React, { useEffect, useState } from "react";
import { useTheme } from "../../lib/theme/useTheme";
import { useAuth } from "../Auth/useAuth";
import queryString from "query-string";
import UserService from "../../services/UserService";
import { getImageUrl, getLocation } from "../../utils";
import { cx } from "../../lib/cx";
import AllManageComponent from "./AllManageComponent";
import ShowCandidateCv from "../../components/Modals/ShowCandidateCv";
import dayjs from "dayjs";

function CVManagement() {
  const { theme } = useTheme();
  const { admin } = useAuth();
  const [listCvs, setListCvs] = useState();
  const [, setTotalPage] = useState(0);
  const [active, setActive] = useState();
  const [cvShow, setCvShow] = useState();

  const limit = 100;

  useEffect(() => {
    if (!listCvs) {
      getAllCvs(1);
    }
  }, [listCvs]);

  const getAllCvs = async (page) => {
    const params = queryString.stringify({
      offset: page - 1,
      limit: limit,
    });

    try {
      const response = await UserService.getAllCvMain(params);
      const data = response.data.data;

      let listPdfCv = data.items.map((item) => item.pdfImage);
      listPdfCv = await UserService.getImageByName(listPdfCv);

      const result = data.items.map((c) => {
        const profile = c.blockInfo[0].builder.profile;
        return {
          ...profile,
          original_image: c.pdfImage,
          cvName: c.cvName,
          image: getImageUrl(listPdfCv.data.data, c.pdfImage),
          status: c.status || "INPROGRESS",
          location: getLocation(profile.location),
          createdAt: dayjs(new Date(c.createdAt)).format("DD-MM-YYYY"),
        };
      });
      setListCvs(result);
      setTotalPage(data.count);
    } catch (error) {
      console.log(new Error(error));
    }
  };

  return (
    <div
      className={cx(
        "mx-auto min-h-[80vh] lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <div className={cx("px-4 mb-[120px]", theme?.background?.text)}>
        <div className="my-[60px]">
          <p className="text-[32px] font-[600] leading-none">Dashboad</p>
          <p className="text-[26px]">{admin?.nickname}</p>
        </div>
        <p className="font-[600] text-[20px]">Quản lý CV</p>

        <div>
          <AllManageComponent
            data={listCvs}
            onChangeCv={(cv) => {
              setCvShow(cv);
              setActive(true);
            }}
          />
        </div>
      </div>
      <ShowCandidateCv
        active={active}
        onChangeActive={() => {
          setCvShow();
          setActive(!active);
        }}
        data={cvShow}
      />
    </div>
  );
}

export default CVManagement;
