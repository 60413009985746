// Các endpoint
export const start = "start"; // thông tin mở đầu
export const login = "auth/login"; // đăng nhập
export const regist1 = "register/candidate"; // đăng ký ứng viên
export const regist2 = "recruiter/register"; // đăng ký nhà tuyển dụng
export const regist3 = "candidates/register"; // đăng ký ứng viên
export const otp = "auth/verify-otp/email"; // gửi xác thực OTP qua email
export const emailSentOtp = "mail"; //
export const resendOTP = "auth/send-otp/email"; // gủi lại OTP
export const posting = "recruiter/create-jd"; // đang tin tuyển
export const getJD = "job-description"; // lấy danh sách công việc
export const getJDbyID = "job-description/find"; // lấy thông tin của thông tin tuyển dụng qua ID tin tuyển
export const verifyJD = "admin/job-management/update"; // phê duyệt công việc
export const verifyJDChange = "admin/job-management/validate"; // phê duyệt công việc thay đổi dữ liệu
export const getAmountJD = "job-description/admin/count"; // lấy số lượng công việc
export const getAppliedCv = "job-description/cv-applied-pdf"; //danh sách các cv đã ứng tuyển vào công việc này.
export const getCS = "recruiter/active"; // lấy danh sách khách hàng
export const adminAcceptDeny = "admin/recruiter/activate"; // phê duyệt tài khoản khách hàng
export const getAmountCustomer = "recruiter/count/active"; // lấy số lượng khách hàng
export const getCustomerDescription = "recruiter/validate"; // lấy danh sách thông tin mô tả khách hàng cần phê duyệt
export const adminValidateRecruiter = "admin/recruiter/validate"; // phê duyệt thông tin nhà tuyển dụng cập nhật
export const getDescriptionBussiness = "recruiter"; // lấy thông tin doanh nghiệp
export const getBusinessInfoByID = "recruiter/find"; // lấy thông tin nhà tuyển dụng qua ID nhà tuyển dụng
export const listJDValidated = "job-description/recruiter"; // lấy các tin tuyển đã được đăng tải
export const getRecruiterByMail = "recruiter/email"; // lấy hồ sơ doanh nghiệp qua mail
export const getTotalJD = "job-description/count"; // lấy danh sách công việc đã đăng
export const uploadImage = "files/upload"; // đưa ảnh lên server
export const getOneJDUpdateByID = "admin/rerecruiter/validatecruiter/validate";
export const getImage = "files/retrieve"; // lấy ảnh theo tên
export const countJDbyCompanyName = "job-description/company/countjd";
export const countJDbyJobname = "job-description/jobname/countjd";
export const stopRecruit = "admin/job-management/stop"; // dừng tuyển JD
export const changePassword = "auth/change-password";
export const updateCandidate = "candidates/update"; // update candidate profile
export const candidates = "candidates";
export const getOptions = "admin/get-option"; // lấy lựa chọn
export const getCvByUserId = "candidates/candidate/list-cv"; // list cv by userId
export const applyCV = "candidates/candidate/apply-job";
export const getAllJdApplyByUserId = "candidates/jd-apply";
export const getRecruiterDetail = "recruiter/find";
export const uploadCvCandidate = "files/uploadcv";
export const adminCvManagement = "candidates/admin/list-cv-img";
export const updateStatusCv = "admin/candidate/validate-cv";
export const getAllCvApplied = "job-description/cv-applied-pdf";
export const adminRejectRecruiter  ="admin/reject-recruiter";
