import React, { useEffect, useState } from "react";
import CardCvShow from "../components/Card/CardCvShow";
import { Reorder, Segment } from "@mui/icons-material";
import { Tooltip, notification } from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import dayjs from "dayjs";
import { PlusCircleFilled } from "@ant-design/icons";
import AdminService from "../../services/AdminService";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";

const initialLists = {
  "list-1": {
    id: "list-1",
    title: "CV đã nhận",
    status: "In Progress",
    items: [],
  },
  "list-2": {
    id: "list-2",
    title: "CV đã xem",
    status: "READ",
    items: [],
  },
  "list-3": {
    id: "list-3",
    status: "CONTACTED",
    title: "CV đã liên hệ",
    items: [],
  },
  "list-4": {
    id: "list-4",
    status: "INREVIEWED",
    title: "CV đã phỏng vấn",
    items: [],
  },
  "list-5": {
    id: "list-5",
    status: "ACCEPTED",
    title: "CV đã đồng ý",
    items: [],
  },
  "list-6": {
    id: "list-6",
    status: "REJECTED",
    title: "CV đã từ chối",
    items: [],
  },
};

function AllCvComponent({ data, onChangeCv, jdId, jdName }) {
  const { theme } = useTheme();

  const [activeMenu, setActiveMenu] = useState("List");
  const [lists, setLists] = useState();
  const location = useLocation();
  const history = useHistory();

  const search = queryString.parse(location.search);

  notification.config({ top: 80, placement: "topRight" });

  useEffect(() => {
    if (!lists && data) {
      getListTask();
      setActiveMenu(search.type || "List");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lists, data]);

  const getListTask = () => {
    let updatedLists = { ...initialLists };

    const keys = Object.keys(updatedLists);
    data.forEach((item, index) => {
      keys.forEach((status) => {
        if (updatedLists[status].status === item.status) {
          const newUpdate = {
            ...updatedLists[status],
            items: [
              ...updatedLists[status].items,
              {
                id: `${status}-${index}`,
                ...item,
                name: item.cvName,
                createAt: dayjs(new Date(item.applyAt)).format("DD-MM-YYYY"),
              },
            ],
          };

          updatedLists = {
            ...updatedLists,
            [status]: newUpdate,
          };
        }
      });
    });
    setLists(updatedLists);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.source.droppableId === result.destination.droppableId) {
      const list = lists[result.source.droppableId];
      const reorderedItems = Array.from(list.items);
      const [movedItem] = reorderedItems.splice(result.source.index, 1);
      reorderedItems.splice(result.destination.index, 0, movedItem);

      const updatedLists = {
        ...lists,
        [result.source.droppableId]: {
          ...list,
          items: reorderedItems,
        },
      };

      setLists(updatedLists);
    } else {
      const sourceList = lists[result.source.droppableId];
      const destinationList = lists[result.destination.droppableId];

      const sourceItems = Array.from(sourceList.items);
      const destinationItems = Array.from(destinationList.items);

      const [movedItem] = sourceItems.splice(result.source.index, 1);
      destinationItems.splice(result.destination.index, 0, movedItem);

      AdminService.updateStatusCvInJd(
        {
          jdId: jdId,
          status: destinationList.status,
        },
        {
          cvImage: movedItem.cvImage,
          title: jdName,
          content: destinationList.title,
          type: "candidate",
        }
      )
        .then(() => {
          notification.success({
            message: "Thành công",
            description: "Cập nhật trạng thái CV thành công",
            duration: 4,
            style: { top: 50 },
          });
        })
        .catch((error) => console.log("error", error));

      const updatedLists = {
        ...lists,
        [result.source.droppableId]: {
          ...sourceList,
          items: sourceItems,
        },
        [result.destination.droppableId]: {
          ...destinationList,
          items: destinationItems,
        },
      };

      setLists(updatedLists);
    }
  };

  const listMenuIcon = [
    {
      name: "Dashboard",
      icon: (
        <Reorder style={{ fontSize: "18px", transform: "rotate(90deg)" }} />
      ),
      isActive: true,
    },
    {
      name: "List",
      icon: <Segment style={{ fontSize: "18px" }} />,
      isActive: true,
    },
    {
      name: "Add",
      icon: <PlusCircleFilled style={{ fontSize: "18px" }} />,
    },
  ];

  const onHandleChangeMenu = (name, active) => {
    if (active) {
      history.replace(
        location.pathname +
          "?" +
          queryString.stringify({ ...search, type: name })
      );
    }
    setActiveMenu(name);
  };

  return (
    <div className="w-full mb-[120px] min-h-[70vh]">
      <p
        className={cx(
          "text-[24px] font-[600] my-[20px]",
          theme?.background?.text
        )}
      >
        Các CV ứng viên đã nhận được
      </p>

      <div className="w-full bg-gray-1 px-2 py-2">
        <div className="w-full flex justify-between items-center">
          <div></div>
          <div className="flex">
            {listMenuIcon.map((item, idx) => {
              return (
                <div
                  key={"list-menu-" + idx + "-icon"}
                  className={`w-[22px] h-[22px] flex justify-center items-center mr-2 cursor-pointer ${
                    activeMenu === item.name ? "bg-white" : ""
                  }`}
                  onClick={() => onHandleChangeMenu(item.name, item.isActive)}
                >
                  <Tooltip title={item.name} placement="bottom">
                    {item.icon}
                  </Tooltip>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        {activeMenu === "Dashboard" ? (
          <div className="overflow-x-auto mt-4">
            <div className="flex gap-4 min-w-[max-content]">
              {lists &&
                Object.values(lists).map((list) => (
                  <Droppable key={list.id} droppableId={list.id}>
                    {(provided) => (
                      <div
                        className="w-[300px] min-h-[700px] bg-[#F4F5F7] rounded-[10px]"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <div className="px-4 py-3 uppercase border-b-[3px] border-b-[#fff]">
                          {list.title}
                        </div>
                        <div className="mt-2 px-2">
                          {list.items.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className="w-full"
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <CardCvShow
                                    data={item}
                                    onHandleClick={onChangeCv}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </div>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ))}
            </div>
          </div>
        ) : (
          <div className="mt-4">
            {lists &&
              Object.values(lists).map((list) => (
                <Droppable key={list.id} droppableId={list.id}>
                  {(provided) => (
                    <div
                      className="w-full min-h-[150px] bg-[#F4f5F7] px-2 pb-4 rounded-[10px] mb-2"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <div className="px-4 py-3 border-b-[1px] border-b-[#fff] uppercase">
                        {list.title}
                      </div>
                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 mt-2">
                        {list.items.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                className="w-full"
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                <CardCvShow
                                  data={item}
                                  onHandleClick={onChangeCv}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              ))}
          </div>
        )}
      </DragDropContext>
    </div>
  );
}

export default AllCvComponent;
