import React from "react";
import NamePositionBlock from "../../components/Block/NamePositionBlock";
import ObjectiveComponent from "../../components/SubComponent/ObjectiveComponent";
import { useAppSelector } from "../../redux/hooks";
import { selectFormOrder, selectFormToShow } from "../../redux/slice/setting";
import TrippleComponent from "../../components/SubComponent/TrippleComponent";

const formTypeToComponent = {
  objective: <ObjectiveComponent form="objective" />,
  educations: (
    <TrippleComponent
      form="educations"
      title="Học vấn"
      listTitle={{
        title: "Chuyên ngành",
        label: "Tên trường học",
        description: "Mô tả quá trình học tập hoặc thành tích cá nhân",
      }}
    />
  ),
  workExperiences: (
    <TrippleComponent
      form="workExperiences"
      title="Kinh nghiệm làm việc"
      listTitle={{
        title: "Vị trí công việc",
        label: "Tên công ty",
        description: "Mô tả kinh nghiệm của bạn",
      }}
    />
  ),
  projects: (
    <TrippleComponent
      form="projects"
      title="Dự án"
      listTitle={{
        title: "Tên dự án",
        label: "Số lượng người tham gia: ",
        sub_label: "Vị trí của bạn: ",
        description: "Mô tả vai trò và trách nhiệm của bạn trong dự án",
        isCheck: true,
      }}
    />
  ),
};

function RightComponent() {
  const { right } = useAppSelector(selectFormOrder);
  const formToShow = useAppSelector(selectFormToShow);
  return (
    <div>
      <NamePositionBlock />

      {right &&
        right.map((field) => {
          if (formToShow[field]) {
            const component = formTypeToComponent[field];
            return component;
          }
          return null;
        })}
    </div>
  );
}

export default RightComponent;
