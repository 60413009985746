import React, { useEffect, useState } from "react";
import { Reorder } from "@mui/icons-material";
import { Tooltip, notification } from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import dayjs from "dayjs";
import { PlusCircleFilled } from "@ant-design/icons";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import CardCvManage from "../components/Card/CardCvManage";
import UserService from "../../services/UserService";

const initialLists = {
  "list-1": {
    id: "list-1",
    title: "CV đã nhận",
    status: "INPROGRESS",
    items: [],
  },
  "list-2": {
    id: "list-2",
    title: "CV Tiềm năng",
    status: "POTENTIAL",
    items: [],
  },
  "list-3": {
    id: "list-3",
    status: "CVTYPE1",
    title: "CV loại 1",
    items: [],
  },
  "list-4": {
    id: "list-4",
    status: "CVTYPE2",
    title: "CV loại 2",
    items: [],
  },
  "list-5": {
    id: "list-5",
    status: "CVVIP",
    title: "CV cao cấp",
    items: [],
  },
  "list-6": {
    id: "list-6",
    status: "REJECTED",
    title: "CV không phù hợp",
    items: [],
  },
};

function AllManageComponent({ data, onChangeCv }) {
  const { theme } = useTheme();

  const [activeMenu, setActiveMenu] = useState("List");
  const [lists, setLists] = useState();
  const location = useLocation();
  const history = useHistory();

  const search = queryString.parse(location.search);

  notification.config({ top: 80, placement: "topRight" });

  useEffect(() => {
    if (!lists && data) {
      getListTask();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lists, data]);

  const getListTask = () => {
    let updatedLists = { ...initialLists };

    const keys = Object.keys(updatedLists);
    data.forEach((item, index) => {
      keys.forEach((status) => {
        if (updatedLists[status].status === item.status) {
          const newUpdate = {
            ...updatedLists[status],
            items: [
              ...updatedLists[status].items,
              {
                id: `${status}-${index}`,
                ...item,
                name: item.cvName,
                createAt: dayjs(new Date(item.applyAt)).format("DD-MM-YYYY"),
              },
            ],
          };

          updatedLists = {
            ...updatedLists,
            [status]: newUpdate,
          };
        }
      });
    });
    setLists(updatedLists);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.source.droppableId === result.destination.droppableId) {
      const list = lists[result.source.droppableId];
      const reorderedItems = Array.from(list.items);
      const [movedItem] = reorderedItems.splice(result.source.index, 1);
      reorderedItems.splice(result.destination.index, 0, movedItem);

      const updatedLists = {
        ...lists,
        [result.source.droppableId]: {
          ...list,
          items: reorderedItems,
        },
      };

      setLists(updatedLists);
    } else {
      const sourceList = lists[result.source.droppableId];
      const destinationList = lists[result.destination.droppableId];

      const sourceItems = Array.from(sourceList.items);
      const destinationItems = Array.from(destinationList.items);

      const [movedItem] = sourceItems.splice(result.source.index, 1);
      destinationItems.splice(result.destination.index, 0, movedItem);

      UserService.updateCvByImage({
        pdfImage: movedItem.original_image,
        status: destinationList.status,
      })
        .then(() => {
          notification.success({
            message: "Thành công",
            description: "Cập nhật trạng thái thành công",
            duration: 4,
            style: { top: 50 },
          });
        })
        .catch((error) => {
          console.log(new Error(error));
        });

      const updatedLists = {
        ...lists,
        [result.source.droppableId]: {
          ...sourceList,
          items: sourceItems,
        },
        [result.destination.droppableId]: {
          ...destinationList,
          items: destinationItems,
        },
      };

      setLists(updatedLists);
    }
  };

  const listMenuIcon = [
    {
      name: "Dashboard",
      icon: (
        <Reorder style={{ fontSize: "18px", transform: "rotate(90deg)" }} />
      ),
      isActive: true,
    },
    {
      name: "Add",
      icon: <PlusCircleFilled style={{ fontSize: "18px" }} />,
    },
  ];

  const onHandleChangeMenu = (name, active) => {
    if (active) {
      history.replace(
        location.pathname +
          "?" +
          queryString.stringify({ ...search, type: name })
      );
    }
    setActiveMenu(name);
  };

  return (
    <div className="w-full mb-[120px] min-h-[70vh]">
      <p
        className={cx(
          "text-[24px] font-[600] my-[20px]",
          theme?.background?.text
        )}
      >
        Các CV ứng viên đã nhận được
      </p>

      <div className="w-full bg-gray-1 px-2 py-2">
        <div className="w-full flex justify-between items-center">
          <div></div>
          <div className="flex">
            {listMenuIcon.map((item) => {
              return (
                <>
                  <div
                    className={`w-[22px] h-[22px] flex justify-center text-black items-center mr-2 cursor-pointer ${
                      activeMenu === item.name ? "bg-white" : ""
                    }`}
                    onClick={() => onHandleChangeMenu(item.name, item.isActive)}
                  >
                    <Tooltip title={item.name} placement="bottom">
                      {item.icon}
                    </Tooltip>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="overflow-x-auto pb-[20px] mt-4">
          <div className="flex gap-4 min-w-[max-content]">
            {lists &&
              Object.values(lists).map((list) => (
                <Droppable key={list.id} droppableId={list.id}>
                  {(provided) => (
                    <div
                      className="w-[300px] min-h-[700px] bg-[#F4F5F7] shadow-md rounded-[10px]"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <div className="px-4 py-3 uppercase border-b-[3px] text-black border-b-[#fff]">
                        {list.title}
                      </div>
                      <div className="mt-2 px-2">
                        {list.items.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                className="w-full"
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                <CardCvManage
                                  data={item}
                                  onChangeDetail={onChangeCv}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              ))}
          </div>
        </div>
      </DragDropContext>
    </div>
  );
}

export default AllManageComponent;
