import {
  DeleteOutlined,
  MoreOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Dropdown } from "antd";
import React from "react";
import { pathAdmin } from "../../../screens/PathRouting";
import { useHistory } from "react-router-dom";

function AvatarItem(props) {
  const { data, isLeader, disabled, level, onHandleChange } = props;
  const history = useHistory();
  const onHandleClick = (value) => {
    onHandleChange(data?.id);
  };

  const leader = {
    label: (
      <p
        className="text-[#009D06] flex justify-start items-center"
        onClick={() => onHandleClick("leader")}
      >
        <TeamOutlined style={{ color: "#009D06", marginRight: "4px" }} /> Đặt
        làm trưởng nhóm
      </p>
    ),
    key: "1",
  };

  const sub_leader = {
    label: (
      <p
        className="text-[#FF9900] flex justify-start items-center"
        onClick={() => onHandleClick("sub-leader")}
      >
        <TeamOutlined style={{ color: "#FF9900", marginRight: "4px" }} /> Đặt
        làm phó nhóm
      </p>
    ),
    key: "2",
  };

  const member = {
    label: (
      <p
        className="text-[#1495F3] flex justify-start items-center"
        onClick={() => onHandleClick("member")}
      >
        <UserOutlined style={{ color: "#1495F3", marginRight: "4px" }} />
        Đặt làm thành viên
      </p>
    ),
    key: "3",
  };

  const remove = {
    label: (
      <p className="text-[#E60019] flex justify-start items-center">
        <DeleteOutlined
          style={{ color: "#E60019", marginRight: "4px" }}
          onClick={() => onHandleClick("remove")}
        />
        Xóa khỏi nhóm
      </p>
    ),
    key: "4",
  };
  const divider = {
    type: "divider",
  };

  const getAction = () => {
    switch (level) {
      case 1: {
        return [leader, divider, sub_leader, divider, member, divider, remove];
      }
      case 2: {
        return [leader, divider, sub_leader, divider, remove];
      }
      case 3: {
        return [member, divider, remove];
      }
      case 4: {
        return [leader, divider, sub_leader, divider, member];
      }
      default: {
        return [];
      }
    }
  };
  return (
    <div className="w-full px-4 py-3 bg-white rounded-[12px]">
      <div className="w-full grid grid-cols-8 gap-4">
        <div className="col-span-1">
          <div className="w-[40px] h-[40px] flex justify-center items-center rounded-[50%] bg-gray-300">
            <img
              src={data?.avatar || "/asserts/avatar.png"}
              alt=""
              className="max-w-full max-h-full object-cover border-none"
            />
          </div>
        </div>
        <div className="col-span-6 ml-2">
          <p
            className="font-[600] text-black line-clamp-1 cursor-pointer hover:underline"
            onClick={() =>
              history.push(
                pathAdmin.adminPersonalDetail.parentPath + `/${data?.id}`
              )
            }
          >
            {data?.name}
          </p>
          {isLeader ? (
            <p className="text-black">{data?.position}</p>
          ) : (
            <p className="text-black">Ngày tham gia: {data?.joinDate}</p>
          )}
        </div>
        <div className="col-span-1 flex justify-center items-center">
          <Dropdown
            trigger={["click"]}
            menu={{
              items: getAction(),
            }}
            overlayStyle={{ zIndex: 99999 }}
            placement="bottomRight"
            arrow
            disabled={disabled || getAction().length === 0}
          >
            <MoreOutlined style={{ fontSize: "22px" }} />
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

export default AvatarItem;
