import React from "react";
import { Checkbox, Radio, Input } from "antd";

/*
    Thiết kế nút checkbox
    Đầu vào bao gồm sự kiện bấm thay đổi trạng thái value, tiêu đề cho nút
*/

export const BtCheck = (props) => {
  return (
    <>
      <div
        className="w-full p-[5px] border border-[#F3F3F3] cursor-pointer rounded-[10px] flex"
        onClick={props.onClick}
      >
        <Checkbox checked={props.value}></Checkbox>
        <p className="text-[14px] ml-2">{props.title}</p>
      </div>
    </>
  );
};

/*
    mẫu nút radio đầu vào bao gồm sự kiện khi bấm nút, giá trị cho nút radio nếu có,
    title1, title 2: nội dung nút
*/
export const BtRadio = ({ label, title, value }) => {
  return (
    <div className="w-full flex border border-[#F3F3F3] rounded-[10px] p-[5px] mt-2">
      <Radio value={value}>
        <div className="ml-2">
          <p className="font-[600]">{label}</p>
          <p className="text-gray text-[14px]">{title}</p>
        </div>
      </Radio>
    </div>
  );
};

export const BtField = (props) => {
  return (
    <div
      className="w-full flex justify-start items-center border border-[#F3F3F3] rounded-[10px] p-[5px] mt-2"
      onClick={props.onClick}
    >
      <Radio value={props.value}>
        <div>
          <Input
            onChange={props.onChange}
            suffix={props.suffix}
            value={props.valueInput}
          />
          <p>{props.suffixTitle}</p>
        </div>
      </Radio>
    </div>
  );
};
