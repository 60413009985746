import React, { useState } from "react";
import { cx } from "../../lib/cx";
import { useTheme } from "../../lib/theme/useTheme";
import { useAuth } from "../Auth/useAuth";
import Input from "../../components/Input";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import AdminService from "../../services/AdminService";
import UserService from "../../services/UserService";
import { getImageUrl } from "../../utils";
import CardCVView from "../components/Card/CardCVView";

function CVFind() {
  const { theme } = useTheme();
  const { admin } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const [listCvFilter, setListCvFilter] = useState();

  const handleOnChange = (e) => {
    history.replace(
      location.pathname +
        `?${queryString.stringify({
          ...queryString.parse(location.search),
          [e.target.name]: e.target.value,
        })}`
    );
  };

  const getListCvSearch = async () => {
    setListCvFilter([]);
    const search = queryString.parse(location.search);
    const result = { ...search };

    if (result["position"] || result["experience"]) {
      result["workExperience"] = [
        {
          position: result["position"] || "",
          experince: result["experience"] || "",
        },
      ];
    }
    delete result["experience"];
    delete result["position"];

    try {
      const response = await AdminService.findParsingCv(result);
      const data = response.data.data.items;
      if (data.length > 0) {
        let listImages = data.map((item) => item.cvFile);
        listImages = await UserService.getImageURLByName(listImages);

        const result = data.map((item) => ({
          ...item,
          urlPdf: getImageUrl(listImages.data.data, item.cvFile),
        }));
        setListCvFilter(result);
      }
    } catch (error) {
      console.error(new Error(error));
    }
  };

  return (
    <div
      className={cx(
        "mx-auto min-h-[80vh] lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <div className={cx("px-4 mb-[120px]", theme?.background?.text)}>
        <div className="my-[60px]">
          <p className="text-[32px] font-[600] leading-none">Dashboad</p>
          <p className="text-[26px]">{admin?.nickname}</p>
        </div>

        <div className="w-full h-auto">
          <form className="grid grid-cols-2 gap-4">
            <Input
              label="Vị trí yêu cầu kinh nghiệm"
              name="position"
              placeholder="Nhập vị trí yêu cầu"
              className="px-2"
              onChangeInput={(e) => handleOnChange(e)}
            />
            <Input
              label="Số năm yêu cầu kinh nghiệm ứng với vị trí"
              name="experience"
              placeholder="Kinh nghiệm yêu cầu. vd: 1 - 3 năm"
              className="px-2"
              onChangeInput={(e) => handleOnChange(e)}
            />
            <Input
              label="Vị trí tuyển dụng"
              name="jobPosition"
              placeholder="Nhập vị trí tuyển dụng"
              className="px-2"
              onChangeInput={(e) => handleOnChange(e)}
            />
            <Input
              label="Độ tuổi"
              name="dateOfBirth"
              placeholder="Nhập độ tuổi"
              className="px-2"
              onChangeInput={(e) => handleOnChange(e)}
            />
            <Input
              label="Tỉnh thành phố"
              name="address"
              placeholder="Nhập tỉnh thành phố"
              className="px-2"
              onChangeInput={(e) => handleOnChange(e)}
            />
          </form>
          <div
            className="bg-black text-white px-4 py-2 my-2 w-[120px] text-center cursor-pointer"
            onClick={getListCvSearch}
          >
            Tìm kiếm
          </div>

          <div className="mt-4 grid grid-cols-2 gap-4">
            {listCvFilter &&
              listCvFilter.length > 0 &&
              listCvFilter.map((item, idx) => (
                <CardCVView
                  key={"index-" + idx + "-cv-item"}
                  data={{
                    image: item.urlPdf,
                    name: item.name,
                  }}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CVFind;
