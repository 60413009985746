import { CloseOutlined } from "@ant-design/icons";
import React from "react";
import { cx } from "../../lib/cx";
import { useAppDispatch, useAppSelector } from "../../cv/redux/hooks";
import { changeActions, selectActions } from "../../cv/redux/slice/setting";
import UserService from "../../services/UserService";
import { selectProfile } from "../../cv/redux/slice/builder";
import { login } from "../../services/PORT";
import { useConfigCvFile } from "../../cv/builder/hook";
import { notification } from "antd";
import { formatPhoneNumber } from "../../utils";

function ChooseSaveCvNotLoginModal() {
  const { openSaveCv } = useAppSelector(selectActions);
  const { handleSaveCv } = useConfigCvFile();
  const profile = useAppSelector(selectProfile);

  const dispatch = useAppDispatch();

  notification.config({ top: 80, placement: "topRight" });

  const handleLoginAccount = () => {
    dispatch(changeActions({ field: "openSaveCv" }));
    dispatch(changeActions({ field: "openLogin" }));
  };

  const handleSigninAccount = async () => {
    const data = {
      fullName: profile.fullName,
      email: profile.email,
      phoneNumber: formatPhoneNumber(profile.phoneNumber),
    };
    notification.success({
      message: "Đang xử lý",
      description: `Vui lòng không đóng trình duyệt để tránh phát sinh lỗi`,
      duration: 4,
      style: { top: 50 },
    });
    try {
      const responsRegister = await UserService.autoRegisterAccount(data);
      const account = responsRegister.data.data;
      userLoginAfter({
        email: account.email,
        password: account.password,
        role: "Candidate",
      });
      dispatch(changeActions({ field: "openSaveCv" }));
    } catch (error) {
      console.log(new Error(error));
      dispatch(changeActions({ field: "openSaveCv" }));
      dispatch(changeActions({ field: "openRegister" }));
    }
  };

  const userLoginAfter = async (data) => {
    try {
      UserService.checkUser(data, login).then((response) => {
        localStorage.setItem("User", JSON.stringify(response.data.data));
        localStorage.setItem(
          "loginStatus",
          JSON.stringify({ status: "success", role: data.role })
        );
        handleSaveCv();
      });
    } catch (error) {
      notification.error({
        message: "Đăng nhập thất bại",
        description: `Tài khoản hoặc mật khẩu không chính xác`,
        duration: 4,
        style: { top: 50 },
      });
      console.log(new Error(error));
    }
  };

  return (
    <div
      className={cx(
        `overflow-x-hidden overflow-y-auto fixed inset-0 z-9999 h-screen outline-none focus:outline-none justify-center items-center c-modal bg-black bg-opacity-40`,
        openSaveCv ? "block" : "hidden"
      )}
    >
      <div className="w-full h-[100vh] flex justify-center items-center px-4">
        <div className="w-full sm:w-[650px] bg-white h-auto shadow-md rounded-[4px] pb-[20px]">
          <div
            className="w-full flex justify-end items-center cursor-pointer pt-[10px] pr-[15px]"
            onClick={() => dispatch(changeActions({ field: "openSaveCv" }))}
          >
            <CloseOutlined style={{ fontSize: "15px" }} />
          </div>

          <p className="text-center mt-3 font-[600]">
            Bạn chưa đăng nhập hệ thống trước đó
          </p>
          <p className="text-center mb-3 font-[600]">
            Bạn đã có tài khoản trên hệ thống ManpoweBank chưa?
          </p>

          <div className="w-full p-[10px] grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div
              className="w-full h-[140px] bg-gradient-to-bl from-[#2A2664] to-[#6F2F66] text-white rounded-[8px] p-[12px] shadow-md hover:shadow-lg cursor-pointer"
              onClick={handleLoginAccount}
            >
              <h5 className="text-center uppercase">Tôi đã có </h5>
              <div className="text-center mt-3">
                <p>Đăng nhập tài khoản </p>
                <p>và</p>
                <p> lưu CV vào tài khoản</p>
              </div>
            </div>
            <div
              className="w-full h-[140px] bg-gradient-to-br from-[#2A2664] to-[#6F2F66] text-white rounded-[8px] p-[12px] shadow-md hover:shadow-lg cursor-pointer"
              onClick={handleSigninAccount}
            >
              <h5 className="text-center uppercase">Tôi chưa có </h5>
              <div className="text-center mt-3">
                <p>Tạo tài khoản LDO nhanh </p>
                <p>và</p>
                <p> lưu CV vào tài khoản</p>
              </div>
            </div>
          </div>

          <p className="px-[10px] text-center mt-4">
            <strong>Lưu ý:</strong> Nếu bạn chọn đăng ký tài khoản nhanh, hệ
            thống sẽ tạo tài khoản từ thông tin bạn đã nhập trên CV. Đảm bảo
            rằng thông tin
            <b> (email, sđt)</b> bạn cung cấp là chính xác.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ChooseSaveCvNotLoginModal;
