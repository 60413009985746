import React, { Fragment } from "react";
import { Select } from "antd";

let data = [];

export const Selects = (props) => {
  data = props.data || [];
  return (
    <Select
      className={props.className}
      showSearch
      allowClear
      optionLabelProp="label"
      placeholder={
        <Fragment>
          <div className="flex justify-start items-center">
            {props.icon}
            {props.title}
          </div>
        </Fragment>
      }
      size={props.size}
      style={props.style}
      onChange={props.onChange}
      bordered={props.bordered}
    >
      {data.map((data) => (
        <Select.Option
          key={data}
          value={data}
          label={
            <Fragment>
              {props.icon}
              &nbsp;
              {data}
            </Fragment>
          }
        >
          {data}
        </Select.Option>
      ))}
    </Select>
  );
};
