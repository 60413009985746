import React from "react";

function CardLoading() {
  return (
    <div className="w-full lg:h-[100px] shadow-md bg-white p-[10px] rounded-[12px] grid grid-cols-1 lg:grid-cols-3 my-[10px] cursor-pointer">
      <div className="col-span-1 lg:col-span-2 animate-pulse">
        <div className="grid grid-cols-6">
          <div className="w-full h-full flex justify-center items-center rounded-[12px] col-span-2 sm:col-span-1">
            <div className="w-[80px] h-[80px] bg-[#D9D9D9] rounded-[14px]"></div>
          </div>
          <div className="w-full lg:border-r-[2px] border-r-[#D9D9D9] ml-2 md:ml-0 col-span-4 sm:col-span-5 pl-7 pr-[10px]">
            <div className="w-full h-[20px] bg-[#D9D9D9]"></div>
            <div className="w-[50%] h-[16px] bg-[#D9D9D9] mt-2"></div>
            <div className="w-[150px] h-[14px] bg-[#D9D9D9] rounded-[22px] mt-[20px]"></div>
          </div>
        </div>
      </div>

      <div className="col-span-1 mt-2 md:mt-0 px-2 animate-pulse">
        <div className="w-full flex mb-2">
          <div className="w-full flex justify-between gap-1">
            <div className="flex items-center">
              <div className="w-[100px] h-6 bg-[#D9D9D9]"></div>
            </div>
            <div className="flex items-center">
              <div className="w-[100px] h-6 bg-[#D9D9D9]"></div>
            </div>
          </div>
        </div>
        <div className="w-[70px] h-6 bg-[#D9D9D9] text-[10px] text-white inline float-left mb-1 mr-1 px-[12px] py-[8px] rounded-[12px]"></div>
        <div className="w-[100px] h-6 bg-[#D9D9D9] text-[10px] text-white inline float-left mb-1 mr-1 px-[12px] py-[8px] rounded-[12px]"></div>
        <div className="w-full flex justify-end items-end">
          <div className="w-[14px] h-[14px] bg-[#D9D9D9]"></div>
        </div>
      </div>
    </div>
  );
}

export default CardLoading;
