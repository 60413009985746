import React, { useEffect, useState } from "react";
import CardEmpty from "../components/Card/CardEmpty";
import CardCompany from "../components/Card/CardCompany";
import CardRecruiter from "../components/Card/CardRecruiter";
import { useHistory } from "react-router-dom";
import { pathAdmin } from "../../screens/PathRouting";
import CreateGroupModal from "../components/Modal/CreateGroupModal";
import CreateCompany from "../components/Modal/CreateCompany";
import AdminService from "../../services/AdminService";
import UserService from "../../services/UserService";
import queryString from "query-string";
import dayjs from "dayjs";
import { getImageUrl, getLocation } from "../../utils";
import { useAuth } from "../Auth/useAuth";
import { ADMIN_ROLE } from "../../utils/constants";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";

function Dashboard() {
  const { theme } = useTheme();
  const history = useHistory();
  const { admin } = useAuth();
  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [popupCreateCompany, setPopupCreateCompany] = useState();
  const [companies, setComapanies] = useState();
  const [listGroups, setListGroups] = useState();
  const [listJds, setListJds] = useState();

  useEffect(() => {
    if (admin.sub_role === ADMIN_ROLE.POST_MANAGER) {
      history.push(pathAdmin.adminManagerNews.path);
    }
    if (admin.sub_role === ADMIN_ROLE.ADS_MANAGER) {
      history.push(pathAdmin.adminAdsManagement.path);
    }
  }, [admin, history]);

  useEffect(() => {
    if (!companies) {
      getAllListCompany();
      getAllListGroup();
      getAllListJd();
    }
  }, [companies]);

  const getAllListCompany = async () => {
    const params = queryString.stringify({
      offset: 0,
      limit: 3,
    });
    const allCompany = await AdminService.getAllCompany(params);
    const data = allCompany.data.data.filter(
      (item, index) => index !== 0 && item
    );
    const images = data.map((item) => item.avatar);
    const listImage = await UserService.getImageByName(images);
    const result = data.map((item, index) => ({
      id: item.id,
      avatar: getImageUrl(listImage.data.data, item.avatar),
      companyName: item.name,
      countJds: item.countJds,
      countCandidate: item.countCVs,
    }));
    setComapanies(result);
  };

  const getAllListGroup = async () => {
    const listGroups = await AdminService.getAllGroup(
      queryString.stringify({
        offset: 0,
        limit: 3,
      })
    );

    const data = listGroups.data.data.filter(
      (item, index) => index > 0 && item
    );
    const result = data.map((item) => ({
      id: item.id,
      groupName: item.name,
      numberEmployee: item.countRecruiters,
      job: item?.projectCompany
        ? item.projectCompany.map((item) => item.name).join(", ")
        : "",
      countJds: item.countJds,
      countCandidate: item.countCVs,
    }));
    setListGroups(result);
  };

  const getAllListJd = async () => {
    const params = queryString.stringify({
      offset: 0,
      limit: 8,
    });
    const response = await AdminService.getAllJd(params);
    let data = response.data.data.filter((item, index) => index > 0 && item);
    data = data.map((item) => ({
      id: item.id,
      jobName: item.jobName,
      date: dayjs(new Date(item.startReceiveCV)).format("DD/MM/YYYY"),
      cvApplied: (item?.cvApplyList && item.cvApplyList.length) || 0,
      location: getLocation(item.location),
      status: item?.status ? item.status.toLowerCase() : "inactive",
    }));

    setListJds(data);
  };
  return (
    <div
      className={cx(
        "mx-auto h-auto lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <div className={cx("px-4", theme?.background?.text)}>
        <div className="my-[60px]">
          <p className="text-[32px] font-[600] leading-none">Dashboad</p>
          <p className="text-[26px]">{admin?.nickname}</p>
        </div>

        <div className="w-full flex justify-between items-center my-4">
          <p className="text-[20px] font-[600]">Quản lý danh mục công ty</p>
          <p
            className="hover:underline cursor-pointer"
            onClick={() => history.push(pathAdmin.adminManageCompany.path)}
          >
            Xem tất cả &gt;
          </p>
        </div>

        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-[60px]">
          <CardEmpty
            icon="/icons/elementplus.png"
            title="Thêm công ty"
            onClick={() => setPopupCreateCompany(!popupCreateCompany)}
          />
          {companies &&
            companies.length > 0 &&
            companies.map((company, index) => (
              <CardCompany
                key={"company" + index}
                index={index}
                data={company}
                onHandleClick={() =>
                  history.push(
                    pathAdmin.adminCompanyDetail.parentPath + `/${company.id}`
                  )
                }
              />
            ))}
        </div>

        <div className="w-full flex justify-between items-center my-4">
          <p className="text-[20px] font-[600]">Quản lý bài tuyển dụng</p>
          <p
            className=" hover:underline cursor-pointer"
            onClick={() => history.push(pathAdmin.adminPostManagement.path)}
          >
            Xem tất cả &gt;
          </p>
        </div>
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-[60px]">
          {listJds && listJds.length > 0 ? (
            listJds.map((item, index) => (
              <CardRecruiter
                key={"recruiter" + index}
                status={item.status}
                data={item}
                onHandleDetail={() =>
                  history.push(
                    pathAdmin.adminPostCandidate.parentPath + `/${item.id}`
                  )
                }
              />
            ))
          ) : (
            <p className="text-[14px] italic">
              Chưa có bài tuyển dụng được đăng tải
            </p>
          )}
        </div>

        <div className="w-full flex justify-between items-center my-4">
          <p className="text-[20px] font-[600]">Quản lý nhân sự</p>
          <p
            className="cursor-pointer hover:underline"
            onClick={() => history.push(pathAdmin.adminManagePersonal.path)}
          >
            Xem tất cả &gt;
          </p>
        </div>
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          <CardEmpty
            icon="/icons/folderadd.png"
            title="Thêm nhóm"
            onClick={() => setOpenCreatePopup(!openCreatePopup)}
          />
          {listGroups &&
            listGroups.length > 0 &&
            listGroups.map((item, index) => (
              <CardCompany
                key={"group" + index}
                index={index}
                type={1}
                data={item}
                onHandleClick={() =>
                  history.push(
                    pathAdmin.adminGroupDetail.paretPath + "/" + item.id
                  )
                }
              />
            ))}
        </div>
      </div>
      <CreateGroupModal
        active={openCreatePopup}
        onChangeActive={() => setOpenCreatePopup(!openCreatePopup)}
      />
      <CreateCompany
        active={popupCreateCompany}
        onChangeActive={() => {
          getAllListCompany();
          setPopupCreateCompany(!popupCreateCompany);
        }}
      />
    </div>
  );
}

export default Dashboard;
