import { useEffect, useState } from "react";

export const useGetLoginStorage = () => {
  const [status, setStatus] = useState(false);

  const user = JSON.parse(localStorage.getItem("User"));

  useEffect(() => {
    function handleStorageChange(event) {
      if (event.key === "loginStatus") {
        if (!event.newValue) {
          setStatus(false);
        } else {
          setStatus(true);
        }
      }
    }
    handleStorageChange({
      key: "loginStatus",
      newValue: JSON.parse(localStorage.getItem("loginStatus")),
    });
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return { status, setStatus };
};
