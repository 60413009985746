import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './index.css';

var toolbarOptions = [['bold', 'italic', 'underline', 'strike'],        // toggled buttons
['blockquote', 'code-block'],
[{ 'align': [] }],
];

export const TextEdit = (props) => {
    return (
        // <Editor
        //     editorState={props.editorState}
        //     handlePastedText={props.handlePastedText}
        //     onEditorStateChange={props.onEditorStateChange}
        //     wrapperClassName='border-[1px] rounded-[10px] min-h-[200px] w-full overflow-hidden'
        //     editorClassName="p-[1%] pt-0 leading-[2px]"
        //     // toolbarClassName="border-[1px] rounded-[40px] bg-[#F9F9F9]"
        //     toolbarStyle={{borderRadius: '10px 10px 0 0', backgroundColor: '#F9F9F9'}}
        //     toolbar={{
        //         options: ['inline', 'colorPicker'],
        //         colorPicker: {
        //             // icon: color,
        //             colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
        //                 'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
        //                 'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
        //                 'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
        //                 'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
        //                 'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
        //         },
        //     }}
        // />
        <ReactQuill
            className='w-full'
            theme="snow"
            value={props.value}
            onChange={props.onChange}
            modules={{
                toolbar: toolbarOptions
            }}
        />
    )
}