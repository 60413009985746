import React, { useEffect, useState } from "react";
import { cx } from "../../../lib/cx";
import { CameraOutlined, LoadingOutlined } from "@ant-design/icons";
import InputAdmin from "../Input";
import { useFormik } from "formik";
import { createCvSchema } from "../../../utils/validations";
import { useAuth } from "../../Auth/useAuth";
import UserService from "../../../services/UserService";
import { notification } from "antd";

function CreateCvModal({ data, active, onChangeActive }) {
  const { admin } = useAuth();
  const [avatar, setAvatar] = useState();
  const [checkAvatar, setCheckAvatar] = useState(false);
  const [loading, setLoading] = useState(false);

  notification.config({ top: 80, placement: "topRight" });

  const createCvFormik = useFormik({
    initialValues: {
      role: admin.sub_role,
      department: "",
      type: "",
      name: "",
      image: "",
      subName: "",
    },
    validationSchema: createCvSchema,
    onSubmit: (values) => {
      if (!avatar && !data) {
        setCheckAvatar(true);
        return;
      }
      setLoading(true);
      const dataUpdate = {
        ...values,
        type: values.type.split(","),
      };
      createCvManage(dataUpdate);
    },
  });

  useEffect(() => {
    if (data) getDataDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getDataDefault = () => {
    changeValueSelect("name", data.name);
    changeValueSelect("image", data.original_image);
    changeValueSelect("department", data.department);
    changeValueSelect("type", data.type.join(","));
    changeValueSelect("subName", data.subName);
  };

  const createCvManage = async (values) => {
    setCheckAvatar(false);
    try {
      let image = "";

      if (avatar) {
        const formData = new FormData();
        formData.append("files", avatar);
        const uploadImage = await UserService.uploadCvManage(formData);
        image = uploadImage.data.data.message[0];
      }

      const dataCreate = { ...values, image: image || values.image };
      if (data) {
        dataCreate.id = data.id;
        UserService.updateCVManage(dataCreate).then((result) => {
          afterHandleSuccess();
        });
      } else {
        UserService.createCVManage(dataCreate).then((result) => {
          afterHandleSuccess();
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(new Error(error));
    }
  };

  const afterHandleSuccess = () => {
    setLoading(false);
    notification.success({
      message: "Thành công",
      description: `${data ? "Cập nhật" : "Tạo mới"} cv mẫu thành công`,
      duration: 4,
      style: { top: 50 },
    });
    createCvFormik.resetForm();
    onChangeActive();
  };

  const changeValueSelect = (name, value) => {
    createCvFormik.handleChange({
      target: {
        name: name,
        value: value,
      },
    });
  };

  const uploadAvatar = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setAvatar(e.target.files[0]);
    }
    setCheckAvatar(false);
  };

  const getMessageError = (field) => {
    return createCvFormik.errors[field] && createCvFormik.touched[field]
      ? createCvFormik.errors[field]
      : "";
  };

  return (
    <div
      className={cx(
        `overflow-hidden fixed inset-0 z-9999 h-screen outline-none focus:outline-none justify-center items-center c-modal bg-black bg-opacity-20`,
        active ? "block" : "hidden"
      )}
    >
      <div className="relative flex justify-center items-center mx-auto w-full px-[10px] md:px-0 h-[100vh] select-none">
        <div className="border-0 relative flex flex-col w-full bg-white outline-none focus:outline-none sm:w-[560px] rounded-[12px]">
          <form
            className="relative flex-auto w-full sm:w-[560px]"
            encType="multipart/form-data"
          >
            <div
              className={`w-full h-[320px] rounded-tl-[12px] rounded-tr-[12px] relative cursor-pointer`}
            >
              <input
                type="file"
                id="cv-upload"
                name="image"
                onChange={uploadAvatar}
                accept="image/*"
                hidden
              />
              <div
                className={cx(
                  `w-full h-[320px] bg-[#F5F7FF] flex justify-center items-center absolute left-0 top-0 `,
                  checkAvatar && "border border-red-400"
                )}
                onClick={() => document.getElementById("cv-upload")?.click()}
              >
                {avatar ? (
                  <img
                    src={avatar && URL.createObjectURL(avatar)}
                    alt=""
                    className="max-w-full max-h-full object-cover border-none "
                  />
                ) : (
                  data?.image && (
                    <img
                      src={data.image}
                      alt=""
                      className="max-w-full max-h-full object-cover border-none "
                    />
                  )
                )}
              </div>

              <div
                className="w-full h-[320px] border-none  opacity-60 absolute left-0 top-0 flex justify-center items-center"
                onClick={() => document.getElementById("cv-upload")?.click()}
              >
                <div className="w-[48px] h-[48px] rounded-[50%] bg-[#B5C1FF] flex justify-center items-center">
                  <CameraOutlined className="text-[24px]" />
                </div>
              </div>
            </div>
            <div className="w-full bg-white rounded-bl-[12px] rounded-br-[12px] p-[20px]">
              <div className="w-full">
                <div className="my-3">
                  <InputAdmin
                    name="name"
                    label="Tên mẫu CV"
                    valueInput={createCvFormik.values.name}
                    placeholder="Tên mẫu CV"
                    onChange={createCvFormik.handleChange}
                    error={getMessageError("name")}
                    required
                  />
                </div>
                <div className="my-3">
                  <InputAdmin
                    name="subName"
                    label="Ký hiệu viết"
                    valueInput={createCvFormik.values.subName}
                    placeholder="ex: CV_001"
                    onChange={createCvFormik.handleChange}
                    error={getMessageError("subName")}
                    required
                  />
                </div>
                <div className="my-3">
                  <InputAdmin
                    name="department"
                    valueInput={createCvFormik.values.department}
                    label="Lĩnh vực"
                    placeholder="Nhập lĩnh vực"
                    onChange={createCvFormik.handleChange}
                    error={getMessageError("department")}
                    required
                  />
                </div>
                <div className="my-3">
                  <InputAdmin
                    name="type"
                    valueInput={createCvFormik.values.type}
                    label="Danh sách loại cv"
                    placeholder="ex: IT, Sale, Cơ bản,..."
                    onChange={createCvFormik.handleChange}
                    error={getMessageError("type")}
                    required
                  />
                </div>
              </div>
              <div className="w-full flex justify-end items-center">
                <div
                  className="px-9 py-2 border rounded-[12px] text-[12px] cursor-pointer mr-2"
                  onClick={onChangeActive}
                >
                  Hủy
                </div>
                <div
                  className="px-9 py-2 bg-[#1495F3] text-white rounded-[12px] text-[12px] cursor-pointer"
                  onClick={createCvFormik.handleSubmit}
                  aria-disabled={loading}
                >
                  {loading && (
                    <LoadingOutlined
                      style={{
                        color: "white",
                        fontSize: "12px",
                        marginRight: "5px",
                      }}
                    />
                  )}
                  Lưu
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateCvModal;
