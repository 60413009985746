import React, { useEffect, useState } from "react";

import CardItem from "../../components/CardItem";
import CardSlide from "../../components/CardItem/CardSlide";
import CardBox from "../../components/CardItem/CardBox";
import SelectItem from "../../components/SelectItem";
import SearchForm from "../../components/SearchForm";
import SearchWork from "./SearchWork";
import { Pagination } from "antd";
import UserService from "../../services/UserService";
import {
  // getImageUrl,
  getLocation,
  getSalary,
  getTimeCloseJob,
  renderDefaultItem,
} from "../../utils";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
// import { countJDbyCompanyName, getImage } from "../../services/PORT";
// import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import { routes } from "../PathRouting";
// import { Autoplay, Navigation, Pagination as pagination } from "swiper/modules";
import CardBoxLoading from "../../components/CardItem/CardBoxLoading";
import dayjs from "dayjs";
import CardSlideLoading from "../../components/CardItem/CardSlideLoading";
import ScrollTop from "../../components/ScrollTop";
import { cx } from "../../lib/cx";
import { useTheme } from "../../lib/theme/useTheme";
import { changePagination } from "../../lib/theme/config";
import HeaderSeo from "../../components/Header/HeaderSeo";
import { useLocalStorage } from "../../lib/useLocalStorage";

function WorkList() {
  const [experiences, setExperiences] = useState([]);
  const [expertises, setExpertises] = useState([]);
  const [salaries, setSalaries] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [level, setLevel] = useState([]);
  const [jobType, setJobTyle] = useState([]);
  const [isShowSearch, setIsShowSearch] = useState(false);
  // const [listCompany, setListCompany] = useState([]);

  // const swiperRef = useRef(null);

  const [listJD, setListJD] = useState();
  const [random3Job, setRandom3Job] = useState();
  const [totalItem, setTotalItem] = useState(0);

  const [searchProperties, setSearchProperties] = useState({});
  const [searchResult, setSearchResult] = useState([]);
  const [listDepartment, setListDeparment] = useState({});
  const [loading, setLoading] = useState(false);
  const [suggestCompany, setSuggestCompany] = useState([]);
  const [token] = useLocalStorage("token", null);

  const location = useLocation();
  const history = useHistory();
  const { theme } = useTheme();

  const search = queryString.parse(location.search);

  const limit = 12;

  useEffect(() => {
    if (!listJD) {
      getItemDefault();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listJD]);

  useEffect(() => {
    if (experiences.length < 1) getDefaultData();
  }, [experiences]);

  const getItemDefault = () => {
    const params = queryString.parse(location.search);
    const keys = Object.keys(params);

    getHotCompany();

    if (keys.length > 1) {
      getSearchResult("", params);
    } else {
      getNewListJD(params.page || 1);
    }
  };

  const getHotCompany = () => {
    // UserService.countJDbyCompanyName(countJDbyCompanyName)
    //   .then(async (res) => {
    //     const arrayData = res.data.data;
    //     const listImage = await UserService.getImage(getImage, {
    //       filename: arrayData.map((item) => item.avatar[0]),
    //     });
    //     const newData = arrayData.map((data) => ({
    //       companyName: data.companyName,
    //       count: data.count,
    //       image: getImageUrl(listImage.data.data, data.avatar[0]),
    //       recruiterId: data.recruiterId,
    //       location: data.location,
    //     }));
    //     setListCompany(newData);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  const getNewListJD = (page) => {
    setLoading(true);
    history.replace(
      location.pathname +
        `?${queryString.stringify(page > 1 ? { page: page } : {})}`
    );
    UserService.getNewJD(page - 1, limit)
      .then(async (response) => {
        setTotalItem(response.data.data.count);
        if (response.data.data.items.length > 1)
          await formatDataShow(
            response.data.data.items,
            setListJD,
            setRandom3Job
          );
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  const formatDataShow = async (response, args, random3Job) => {
    const listRecruiter = [];

    for (const items of response) {
      let recruiter = await UserService.getDescriptionRecuiter(
        items.recruiterId
      );
      recruiter = recruiter.data.data;
      const listImage = await UserService.getImageByName(recruiter.avatarImg);
      listRecruiter.push({
        ...recruiter,
        avatarImg: listImage.data.data[0].fileUrl,
      });
    }

    const newData = response.map((data, index) => ({
      id: data.id,
      image: listRecruiter[index].avatarImg,
      job: data.jobName,
      name_company: listRecruiter[index].enterprise,
      location: getLocation(data.location),
      salary: getSalary(data.minSalary, data.maxSalary, data.salary),
      jobType: data.jobTypes[0],
      experience:
        data.experience > 0
          ? data.experience + " năm kinh nghiệm"
          : "Không y/c kinh nghiệm",
      dueTime: getTimeCloseJob(data.endReceiveCV),
    }));

    if (response.length > 0) {
      getRandomCompanySuggestion(response, listRecruiter);
    }

    const newRandomData = response.map((data, index) => {
      const date = new Date(data.endReceiveCV);

      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();

      const formattedDate = `${day.toString().padStart(2, "0")}/${month
        .toString()
        .padStart(2, "0")}/${year}`;

      return {
        id: data.id,
        image: listRecruiter[index].avatarImg,
        name: listRecruiter[index].enterprise,
        job: data.jobName,
        location: getLocation(data.location),
        salary: getSalary(data.minSalary, data.maxSalary, data.salary),
        date: formattedDate,
        tag: {
          city: getLocation(data.location, true),
          type: data.jobTypes[0],
          experience:
            data.experience > 0
              ? data.experience + " năm kinh nghiệm"
              : "Không y/c kinh nghiệm",
        },
      };
    });

    function shuffleArray(array) {
      const shuffled = array.slice();
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      return shuffled;
    }

    const shuffledData = shuffleArray(newRandomData);
    const randomElements = shuffledData.slice(0, 3);

    args(newData);
    if (random3Job) {
      random3Job(randomElements);
    }
  };

  const getRandomCompanySuggestion = (response, recruiter) => {
    const result = [];
    let listRecruiterId = recruiter.map((item) => item.id);
    listRecruiterId = [...new Set(listRecruiterId)];

    const randomChooseId =
      listRecruiterId[Math.floor(Math.random() * listRecruiterId.length)];

    const listJobInCompany = response.filter(
      (item) => item.recruiterId === randomChooseId
    );

    const chooseRecruiter = recruiter.find(
      (item) => item.id === randomChooseId
    );

    const jd = [];
    for (let i = 0; i < 2; i++) {
      const chooseJob =
        listJobInCompany[Math.floor(Math.random() * listJobInCompany.length)];
      jd.push({
        id: chooseJob.id,
        position: chooseJob.jobName,
        salary: getSalary(
          chooseJob.minSalary,
          chooseJob.maxSalary,
          chooseJob.salary
        ),
        location: getLocation(chooseJob.location),
        date: dayjs(new Date(chooseJob.endReceiveCV)).format("DD/MM/YYYY"),
        tag: {
          city: getLocation(chooseJob.location, true),
          type: chooseJob.jobTypes[0],
          experience:
            chooseJob.experience > 0
              ? chooseJob.experience + " năm kinh nghiệm"
              : "Không y/c kinh nghiệm",
        },
      });
    }

    result.push({
      id: randomChooseId,
      companyName: chooseRecruiter.enterprise,
      jd: jd,
    });

    setSuggestCompany(result);
  };

  const getDefaultData = () => {
    UserService.getAllOption("experience").then((result) => {
      setExperiences(result.data.data);
    });
    UserService.getAllOption("salaryrange").then((result) => {
      setSalaries(result.data.data);
    });
    UserService.getAllOption("level").then((result) => {
      setLevel(result.data.data);
    });
    UserService.getAllOption("department").then((result) => {
      const data = result.data.data[0];
      const keys = Object.keys(data);
      setDepartments(keys);
      setListDeparment(data);
    });
    UserService.getAllOption("location").then((result) => {
      const cities = Object.keys(result.data.data[0]);
      setLocations(cities);
    });
    UserService.getAllOption("jobtype").then((result) => {
      setJobTyle(result.data.data);
    });
  };

  const getSearchResult = (event, params) => {
    setIsShowSearch(true);
    setLoading(true);

    if (event?.target?.name === "department" || (!event && params.field)) {
      setExpertises(listDepartment[event?.target?.value || params.field]);
    }

    let dataSearch = {};
    let page = 1;
    if (event) {
      dataSearch = {
        ...searchProperties,
        [event.target.name]: event.target.value,
      };
    }
    if (params) {
      page = params.page;
      delete params.page;
      dataSearch = { ...searchProperties, ...params };
    }

    history.replace(
      location.pathname +
        `?${queryString.stringify({ page: page, ...dataSearch })}`
    );

    UserService.getFilterJD(page - 1, limit, dataSearch).then(
      async (response) => {
        const data = response.data.data;
        setTotalItem(data[0].count);
        const newData = data.filter((item) => !item.count && item?.id && item);
        await formatDataShow(newData, setSearchResult);
        setLoading(false);
      }
    );
    setSearchProperties(dataSearch);
  };

  // const handleMouseEnter = () => {
  //   swiperRef.current.swiper.autoplay.stop();
  // };

  // const handleMouseLeave = () => {
  //   swiperRef.current.swiper.autoplay.start();
  // };

  return (
    <>
      <HeaderSeo
        title="Tìm việc làm phù hợp cùng chúng tôi"
        description="Lao động online - Cung cấp các công việc mới nhất thuộc nhiều ngành nghề thỏa sức lựa chọn"
      />
      <div
        className={cx(
          "mx-auto lg:max-w-[1175px] z-1",
          theme?.backround?.gradient
        )}
      >
        <ScrollTop />
        <div className="px-4" id="first">
          <div className="py-[36px] text-[12px] lg:text-[16px] flex">
            <a href="/" className="font-[700] text-[#8D9092]">
              Trang chủ
            </a>
            <span className="font-[700] text-[#8D9092] mx-3">/</span>
            <span className={cx("font-[700]", theme?.background?.text)}>
              Danh sách công việc
            </span>
          </div>

          <div className="w-full h-auto ">
            <div className="w-full block mb-2">
              <SearchForm
                dataLocation={locations}
                dataSalary={salaries}
                onChangeValue={getSearchResult}
                oldSelect={searchProperties}
                token={token}
              />
            </div>
            <div
              className={cx(
                "w-full h-auto grid grid-cols-1 gap-4",
                token ? "lg:grid-cols-3 xl2:grid-cols-5" : "lg:grid-cols-5"
              )}
            >
              <SelectItem
                data={departments}
                name="department"
                valueInput={searchProperties.department}
                placeholder="Lĩnh vực"
                onChangeValue={getSearchResult}
              />
              <SelectItem
                data={expertises}
                valueInput={searchProperties.field}
                name="field"
                placeholder="Ngành nghề"
                onChangeValue={getSearchResult}
              />
              <SelectItem
                data={jobType}
                name="type"
                valueInput={searchProperties.type}
                placeholder="Hình thức"
                onChangeValue={getSearchResult}
              />
              <SelectItem
                data={level}
                name="position"
                valueInput={searchProperties.position}
                placeholder="Vị trí"
                onChangeValue={getSearchResult}
              />
              <SelectItem
                data={experiences}
                name="experience"
                valueInput={searchProperties.experience}
                placeholder="Kinh nghiệm"
                onChangeValue={getSearchResult}
              />
            </div>
          </div>

          {isShowSearch && (
            <SearchWork
              data={searchResult}
              totalPage={totalItem}
              limit={limit}
              isLoading={loading}
              onChangePage={(page) => getSearchResult("", { page: page })}
              dataSuggest={suggestCompany}
              token={token}
            />
          )}

          {/* <div className="w-full mt-[40px]">
            <Swiper
              ref={swiperRef}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
              spaceBetween={10}
              modules={[Autoplay, pagination, Navigation]}
              style={{ width: "100%" }}
              slidesPerView={3}
              speed={1000}
            >
              {listCompany &&
                listCompany.map((item, index) => (
                  <SwiperSlide key={"slice" + index}>
                    <div
                      className="w-full"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <CardItem
                        logo={item.image}
                        number={item.count}
                        onClick={() => {
                          history.push(
                            routes.listAllJD.parentPath + `/${item.recruiterId}`
                          );
                        }}
                      />
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div> */}

          {!isShowSearch && (
            <>
              <div className="w-full mb-[20px] mt-[80px] md:flex md:justify-between">
                <div>
                  <p className="text-[#7F879E] text-[16px] font-[600]">
                    Có thể bạn quan tâm
                  </p>
                  <p
                    className={cx(
                      "text-[24px] font-[700]",
                      theme?.background?.text
                    )}
                  >
                    Các công việc phù hợp với bạn
                  </p>
                </div>
              </div>
              <div
                className={cx(
                  "w-full grid grid-cols-1 sm:grid-cols-2 gap-4 my-[51px]",
                  token ? "lg:grid-cols-2" : "lg:grid-cols-3"
                )}
              >
                {loading ? (
                  <>
                    {renderDefaultItem(3).map((item) => (
                      <CardSlideLoading key={"slider-" + item} />
                    ))}
                  </>
                ) : (
                  <>
                    {random3Job &&
                      random3Job.map((jd, index) => {
                        return <CardSlide key={"randomJd" + index} data={jd} />;
                      })}
                  </>
                )}
              </div>

              <div className="mb-[20px] mt-[80px]">
                <p className="text-[#7F879E] text-[16px] font-[600]">
                  Hiển thị kết quả
                </p>
                <p
                  className={cx(
                    "text-[24px] font-[700]",
                    theme?.background?.text
                  )}
                >
                  Các công việc mới đăng tải
                </p>
              </div>

              {loading ? (
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-[51px]">
                  {renderDefaultItem(limit).map((item) => (
                    <div key={"box-" + item + "loading"}>
                      <CardBoxLoading />
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  {listJD && listJD.length > 0 && (
                    <div>
                      <div
                        className={cx(
                          "w-full grid grid-cols-1 md:grid-cols-2 gap-4 my-[51px]",
                          token ? "lg:grid-cols-2" : "lg:grid-cols-3"
                        )}
                      >
                        {listJD.map((jd, index) => {
                          return <CardBox key={"jobd" + index} data={jd} />;
                        })}
                      </div>
                      <div className="w-full h-4 flex justify-center items-center">
                        <Pagination
                          defaultCurrent={Number(search?.page) || 1}
                          total={totalItem}
                          onChange={getNewListJD}
                          pageSize={limit}
                          itemRender={changePagination}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          <div className="mb-[20px] mt-[80px] sm:flex sm:justify-between sm:items-center">
            <div>
              <p
                className={cx(
                  "text-[22px] md:text-[28px] font-[600]",
                  theme?.background?.text
                )}
              >
                Các ngành nghề nổi bật
              </p>
              <p className="text-[#7F879E] text-[12px] md:text-[16px] font-[600]">
                Khám phá các ngành nghề nổi bật với chúng tôi
              </p>
            </div>
            <div className="flex justify-end items-center">
              <a
                href="/"
                className={cx("text-[16px]", theme?.background?.text)}
              >
                Xem tất cả &gt;
              </a>
            </div>
          </div>
          <div
            className={cx(
              "w-full h-auto grid grid-cols-1 sm:grid-cols-2 gap-4 my-[51px]",
              token ? "lg:grid-cols-3" : "lg:grid-cols-4"
            )}
          >
            {[
              "Thiết kê bất động sản",
              "Thiết kế đồ họa",
              "Lập trình viên",
              "Thiết kế đồ họa",
              "Lập trình viên",
              "Thiết kế đồ họa",
              "Lập trình viên",
              "Thiết kế đồ họa",
            ].map((item, index) => (
              <CardItem
                key={"abcswye" + index}
                title={item}
                logo={
                  index % 2 === 0 ? "/asserts/Shape.png" : "/asserts/design.png"
                }
                number={22 + Math.floor(Math.random() * 100)}
              />
            ))}
          </div>
          <div className="w-full h-[150px]"></div>
        </div>
      </div>
    </>
  );
}

export default WorkList;
