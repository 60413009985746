import React from "react";
import CardSearch from "../../components/CardItem/CardSearch";
// import SelectItem from "../../components/SelectItem";
import { Pagination } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { routes } from "../PathRouting";
import CompanyItem from "../../components/CompanyItem";
import CardLoading from "../../components/CardItem/CardLoading";
import { renderDefaultItem } from "../../utils";
import queryString from "query-string";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import { changePagination } from "../../lib/theme/config";

function SearchWork({
  isLoading,
  data,
  totalPage,
  limit,
  onChangePage,
  dataSuggest,
  token,
}) {
  const { theme } = useTheme();
  const history = useHistory();
  const location = useLocation();
  const search = queryString.parse(location.search);
  return (
    <div
      className={cx(
        "w-full py-[40px] min-h-[90vh] lg:gap-4",
        theme?.background?.text,
        !token && "lg:grid lg:grid-cols-3"
      )}
    >
      <div className="w-full lg:col-span-2">
        <div className="flex justify-between">
          <div>
            <p className="text-[#7F879E] text-[14px]">Hiển thị kết quả</p>
            <p className="text-[20px] font-[700]">Các công việc mới đăng tải</p>
          </div>
        </div>

        {isLoading ? (
          <div className="w-full my-[40px]">
            {renderDefaultItem(5).map((item) => (
              <div key={item}>
                <CardLoading />
              </div>
            ))}
          </div>
        ) : (
          <>
            {data && data.length > 0 ? (
              <div>
                <div className="w-full my-[40px]">
                  {data.map((item) => (
                    <CardSearch
                      item={item}
                      href={routes.workDetail.parentPath + "/" + item.id}
                    />
                  ))}
                </div>
                <div className="w-full h-4 flex justify-center items-center">
                  <Pagination
                    defaultCurrent={Number(search?.page) || 1}
                    total={totalPage}
                    pageSize={limit}
                    onChange={onChangePage}
                    itemRender={changePagination}
                  />
                </div>
              </div>
            ) : (
              <div className="w-full my-[40px]">
                <p>Không tìm thấy công việc phù hợp</p>
              </div>
            )}
          </>
        )}
      </div>

      <div className="w-full mt-[60px] md:mt-0">
        <div className="w-full">
          <p className="text-[#7F879E] text-[14px]">Có thể bạn quan tâm</p>
          <p className="text-[20px] font-[700]">
            Các công việc phù hợp với bạn
          </p>
        </div>

        {dataSuggest &&
          dataSuggest.map((item) => (
            <>
              <CompanyItem
                companyName={item.companyName}
                data={item.jd}
                onHandleClick={() =>
                  history.push(routes.listAllJD.parentPath + `/${item.id}`)
                }
              />
            </>
          ))}
      </div>
    </div>
  );
}

export default SearchWork;
