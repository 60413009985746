import WorkList from "./WorkList";
import { ListAllJD } from "./UnknownView/ListAllJDByRecruiter";
import User from "./User";
import { ManageJD } from "./Recruiter/ManageJD/ManageJD";
import { DetailJD } from "./Recruiter/DetailJD/DetailJD";
import { ManageBS } from "./Recruiter/ManageBSV2/ManageBS";

import WorkDetail from "./WorkList/WorkDetail";
import RecruiterDetail from "./WorkList/RecruiterDetail";
import AppliedJD from "./Recruiter/AppliedJD";
import Dashboard from "../admin/Dashboard";
import CompanyList from "../admin/Company/CompanyList";
import PersonalManagement from "../admin/Persional";
import GroupList from "../admin/Persional/GroupList";
import CompanyDetail from "../admin/Company/CompanyDetail";
import PostRecruitment from "../admin/Post/PostRecruitment";
import GroupDetail from "../admin/Persional/GroupDetail";
import PostCandidate from "../admin/Post/PostCandidate";
import PostManagement from "../admin/Post/PostManagement";
import PersionalDetail from "../admin/Persional/PersionalDetail";
import AddParameter from "../admin/Parameters";
import LoginAdmin from "../admin/Auth/LoginAdmin";
import Page404 from "./404";
import RecruiterDashboard from "./Recruiters";
import UpdateRecruiter from "./Recruiters/UpdateRecruiter";
import UpdateJd from "./Recruiters/UpdateJd";

import { ListPartnerMPB } from "./ListPartnerMPB/PartnerMPB";
import PostReviewUpdate from "../admin/Post/PostReviewUpdate";
import AboutUs from "./AboutUs";
import CreateNews from "../admin/News/CreateNews";
import ManagerNews from "../admin/News";
import NewsDetail from "../admin/News/NewsDetail";
import NewsPage from "./News";
import NewsDetailShow from "./News/NewsDetailShow";
import NewsUpdate from "../admin/News/NewsUpdate";
import PaymentCombo from "./Payment";
import PaymentDetail from "./Payment/PaymentDetail";
import CreateJd from "./Recruiters/CreateJd";
import PaymentHistory from "./Payment/PaymentHistory";
import CodeRefferall from "./SignUp/code";
import PaymentCandidate from "./Payment/PaymentCandidate";
import FirstPage from "./FirstPage";
import SignUp from "./SignUp/SignUp";
import Login from "./Login/Login";
import { ForgetPassword } from "./ForgetPassword";
import { PersonalProfile } from "./PersonalProfile";
import CvNomal from "../cv";
import CvAdvanced from "../cv/Advanced";
import AdsManagement from "../admin/Ads";
import ManageRecruiter from "../admin/Recruiter";
import AdminRecruiterDetail from "../admin/Recruiter/RecruiterDetail";
import RecruiterProfile from "../admin/Recruiter/RecruiterProfile";
import PaymentBoard from "../admin/Dashboard/PaymentBoard";
import JDManagement from "../admin/JobDescription";
import JdDetail from "../admin/JobDescription/JdDetail";
import CvTemplate from "../cv/CvTemplate";
import CVTemplate from "../admin/CV";
import CVManagement from "../admin/CV/CVManagement";
import CVParsing from "../admin/CV/CVParsing";
import CVFind from "../admin/CV/CVFind";

export const routes = {
  home: {
    name: "Landing Page",
    path: "/",
    component: FirstPage,
  },
  loginPage: {
    name: "Login",
    path: "/login",
    component: Login,
  },
  signupCandidate: {
    name: "Sign up Employee",
    path: "/register/candidate",
    component: SignUp,
  },
  signupRecruiter: {
    name: "Sign up Recruiter",
    path: "/register/recruiter",
    component: SignUp,
  },
  personalProfile: {
    name: "Personal Profile",
    path: "/user-profile",
    component: PersonalProfile,
  },
  forgotPassword: {
    name: "Forgot Password",
    path: "/forgot-password",
    component: ForgetPassword,
  },
  workList: {
    name: "Work list",
    path: "/work-list",
    component: WorkList,
  },
  workDetail: {
    name: "JD detail",
    parentPath: "/jd-detail",
    path: "/jd-detail/:id",
    component: WorkDetail,
  },
  userSetting: {
    name: "User detail",
    path: "/user-setting",
    component: User,
  },
  aboutUs: {
    name: "About us",
    path: "/about-us",
    component: AboutUs,
  },
  listPartnerMPB: {
    path: "/about-us/partner",
    component: ListPartnerMPB,
  },
  recruiterDetail: {
    name: "Recruiter detail",
    parentPath: "/recruiter-detail",
    path: "/recruiter-detail/:id",
    component: RecruiterDetail,
  },
  recruiterJDApply: {
    name: "Cv Applied",
    parentPath: "/recruiter/jd-apply",
    path: "/recruiter/jd-apply/:id",
    component: AppliedJD,
  },
  login: {
    name: "Login",
    path: "/admin/login",
    component: LoginAdmin,
  },
  notFound404: {
    name: "Not found 404",
    path: "/404",
    component: Page404,
  },
  templateCv: {
    name: "Template cv",
    path: "/cv-template",
    component: CvTemplate,
  },
  createCv: {
    name: "Create CV Normal",
    path: "/create/cv-normal",
    component: CvNomal,
  },
  createCvAdvanced: {
    name: "Create CV Advanced",
    path: "/create/cv-advanced",
    component: CvAdvanced,
  },
  recruiterDashboard: {
    path: "/recruiter",
    component: RecruiterDashboard,
  },
  recruiterUpdate: {
    path: "/recruiter/update",
    component: UpdateRecruiter,
  },
  detailJDs: {
    parentPath: "/recruiter/detail-jd",
    path: "/recruiter/detail-jd/:id",
    component: DetailJD,
  },
  listAllJD: {
    parentPath: "/list-jd",
    path: "/list-jd/:id",
    component: ListAllJD,
  },
  recruiterCreateJd: {
    path: "/recruiter/job-posting",
    component: CreateJd,
  },
  recuiterInfo: {
    path: "/recruiter/manage-bs",
    component: ManageBS,
  },
  recruiterUpdateJd: {
    parentPath: "/recruiter/update-jd",
    path: "/recruiter/update-jd/:id",
    component: UpdateJd,
  },
  recruiterManageJd: {
    path: "/recruiter/manage-jd",
    component: ManageJD,
  },
  newsList: {
    name: "News page",
    path: "/news",
    component: NewsPage,
  },
  paymentCombo: {
    name: "Payment Combo",
    path: "/payment-combo",
    component: PaymentCandidate,
  },
  newsDetail: {
    name: "News Detail",
    parentPath: "/news-detail",
    path: "/news-detail/:slug",
    component: NewsDetailShow,
  },
  refferallCode: {
    name: "RefferallCode",
    parentPath: "/code",
    path: "/code/:str/:code",
    component: CodeRefferall,
  },
};

export const paymentRoutes = {
  paymentComboRecuiter: {
    name: "Payment Combo",
    path: "/recruiter/payment-combo",
    component: PaymentCombo,
  },
  paymentDetail: {
    name: "Payment Detail",
    path: "/recruiter/payment-detail",
    component: PaymentDetail,
  },
  paymentHistory: {
    name: "History Payment",
    path: "/recruiter/payment-history",
    component: PaymentHistory,
  },
};

export const pathAdmin = {
  admin: {
    name: "Dashboard",
    path: "/admin",
    component: <Dashboard />,
  },
  adminPaymentBoard: {
    name: "Payment Board",
    path: "/admin/payment-board",
    component: <PaymentBoard />,
  },
  adminManageJD: {
    name: "Management Job Description",
    path: "/admin/manage-jd",
    component: <JDManagement />,
  },
  adminJdValidate: {
    name: "Management Job Validate",
    parentPath: "/admin/validate-jd",
    path: "/admin/validate-jd/:id",
    component: <JdDetail />,
  },
  adminManagemenCV: {
    name: "CV Management",
    path: "/admin/cv-management",
    component: <CVManagement />,
  },
  adminParsingCV: {
    name: "Parsing CV",
    path: "/admin/cv-parsing",
    component: <CVParsing />,
  },
  adminFindCv: {
    name: "Find CV",
    path: "/admin/cv-find",
    component: <CVFind />,
  },
  adminTemplateCV: {
    name: "CV Template",
    path: "/admin/cv-template",
    component: <CVTemplate />,
  },
  adminManageRecruiter: {
    name: "Manager Recuiter",
    path: "/admin/manage-recruiter",
    component: <ManageRecruiter />,
  },
  adminRecruiterProfile: {
    name: "Admin Get Recruiter profile",
    parentPath: "/admin/recruiter-profile",
    path: "/admin/recruiter-profile/:id",
    component: <RecruiterProfile />,
  },
  detailRecruiter: {
    name: "Admin Get Recruiter Detail",
    parentPath: "/admin/recruiter-detail",
    path: "/admin/recruiter-detail/:id",
    component: <AdminRecruiterDetail />,
  },
  adminManageCompany: {
    name: "Company Management",
    path: "/admin/company-management",
    component: <CompanyList />,
  },
  adminCompanyDetail: {
    name: "Company Detail",
    parentPath: "/admin/company-detail",
    path: "/admin/company-detail/:id",
    component: <CompanyDetail />,
  },
  adminManagePersonal: {
    name: "Persional Management",
    path: "/admin/personal-management",
    component: <PersonalManagement />,
  },
  adminPersonalDetail: {
    name: "Persional Detail",
    parentPath: "/admin/personal-detail",
    path: "/admin/personal-detail/:id",
    component: <PersionalDetail />,
  },
  adminGroupList: {
    name: "Group list",
    path: "/admin/group-list",
    component: <GroupList />,
  },
  adminGroupDetail: {
    name: "Group list",
    paretPath: "/admin/group-detail",
    path: "/admin/group-detail/:id",
    component: <GroupDetail />,
  },
  adminPostRecruitment: {
    name: "Post Recruitment Detail",
    parentPath: "/admin/recruitment-post",
    path: "/admin/recruitment-post/:id",
    component: <PostRecruitment />,
  },
  adminPostCandidate: {
    name: "Post Candiate Detail",
    parentPath: "/admin/candidate-post",
    path: "/admin/candidate-post/:id",
    component: <PostCandidate />,
  },
  adminPostManagement: {
    name: "Post Management",
    path: "/admin/post-management",
    component: <PostManagement />,
  },
  adminParameter: {
    name: "Add Parameter",
    path: "/admin/parameters",
    component: <AddParameter />,
  },
  adminPostReviewUpdate: {
    name: "Review update post",
    path: "/admin/post-review",
    component: <PostReviewUpdate />,
  },
  adminManagerNews: {
    name: "Manager news",
    path: "/admin/manager-news",
    component: <ManagerNews />,
  },
  adminCreateNews: {
    name: "Create news",
    path: "/admin/create-news",
    component: <CreateNews />,
  },
  adminNewsDetail: {
    name: "News Detail",
    parentPath: "/admin/news",
    path: "/admin/news/:slug",
    component: <NewsDetail />,
  },
  adminNewsUpdate: {
    name: "News Update",
    parentPath: "/admin/news-update",
    path: "/admin/news-update/:slug",
    component: <NewsUpdate />,
  },
  adminAdsManagement: {
    name: "Ads Manager",
    path: "/admin/manager-ads",
    component: <AdsManagement />,
  },
};
