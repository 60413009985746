import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function CKEditors(props) {
  const { label, name, value, required, error, onChangeValue, className } =
    props;

  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [editorData, setEditorData] = useState(value);

  useEffect(() => {
    if (!shouldUpdate) {
      changeValueEditor(value);
      setEditorData(value);
      if (editorData) setShouldUpdate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, shouldUpdate, editorData]);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
    changeValueEditor(data);
  };

  const changeValueEditor = (value) => {
    onChangeValue({
      target: {
        name: name,
        value: value,
      },
    });
  };

  return (
    <div className={`w-full mt-4 ${className}`}>
      {label && (
        <p className="text-[16px] text-black font-[600] mb-1">
          {label} {required && <span className="ml-1 text-red-500">*</span>}
        </p>
      )}
      <CKEditor
        editor={ClassicEditor}
        data={editorData}
        onChange={handleEditorChange}
        style={{ height: "500px" }}
      />
      {error && (
        <span className="ml-1 text-red-500 text-[14px] italic">* {error}</span>
      )}
    </div>
  );
}

export default CKEditors;
