import { createSlice } from "@reduxjs/toolkit";

export const DEFAULT_THEME_COLOR = "#2AABD0";
export const DEFAULT_FONT_FAMILY = "Arial";
export const DEFAULT_FONT_SIZE = "14";

export const initialSettings = {
  templateName: "",
  cvName: "",
  isUpdate: false,
  prevPdfImage: "",
  themeColor: DEFAULT_THEME_COLOR,
  fontFamily: DEFAULT_FONT_FAMILY,
  fontSize: DEFAULT_FONT_SIZE,
  formToShow: {
    skills: true,
    cetifications: true,
    activeties: true,
    interestes: true,
    addtional_informations: true,
    educations: true,
    workExperiences: true,
    projects: true,
    objective: true,
    honors_and_awards: true,
    presenter: true,
  },
  formHeader: {
    skills: "Kĩ năng",
    cetifications: "Chứng chỉ",
    activeties: "Hoạt động",
    interestes: "Sở thích",
    honors_and_awards: "Danh hiệu và giải thưởng",
    addtional_informations: "Thông tin thêm",
    educations: "Học vấn",
    workExperiences: "Kinh nghiệm làm việc",
    projects: "Dự án",
    objective: "Mục tiêu nghề nghiệp",
    presenter: "Người giới thiệu",
  },
  formOrder: {},
  actions: {
    openPreview: false,
    openAddMore: false,
    openSaveCv: false,
    openRegister: false,
    openLogin: false,
  },
  formToChange: {
    active: "",
    childActive: "",
  },
  validateForm: {
    cvName: "",
    fullName: "",
    email: "",
    phoneNumber: "",
    avatar: "",
    scrollTop: false,
  },
};

export const settingSlice = createSlice({
  name: "setting",
  initialState: initialSettings,
  reducers: {
    changeSetting: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    changeActions: (state, action) => {
      const { field } = action.payload;
      const value = state.actions[field];
      state.actions[field] = !value;
    },
    changeFormToShow: (state, action) => {
      const { field } = action.payload;
      const value = state.formToShow[field];
      state.formToShow[field] = !value;
    },
    changeFormToChange: (state, action) => {
      const { field, value } = action.payload;
      state.formToChange[field] = value;
    },
    changeValidateForm: (state, action) => {
      const { field, value } = action.payload;
      state.validateForm[field] = value;
    },
    changeFormOrder: (state, action) => {
      const { position, type, value } = action.payload;
      const oldState = state.formOrder[position];
      const lastIdx = oldState.length - 1;
      const pos = oldState.indexOf(value);
      const newPos = type === "up" ? pos - 1 : pos + 1;

      const swapFormOrder = (idx1, idx2) => {
        const temp = oldState[idx1];
        oldState[idx1] = oldState[idx2];
        oldState[idx2] = temp;
      };

      if (newPos >= 0 && newPos <= lastIdx) {
        swapFormOrder(pos, newPos);
      }
    },
    setSetting: (state, action) => {
      return action.payload;
    },
    resetSetting: (state, action) => {
      return initialSettings;
    },
  },
});

export const {
  changeSetting,
  changeActions,
  changeFormToShow,
  changeFormOrder,
  changeValidateForm,
  changeFormToChange,
  setSetting,
  resetSetting,
} = settingSlice.actions;

export const selectSetting = (state) => state.setting;
export const selectThemColor = (state) => state.setting.themeColor;
export const selectFontFamily = (state) => state.setting.fontFamily;
export const selectFontSize = (state) => state.setting.fontSize;
export const selectActions = (state) => state.setting.actions;
export const selectFormOrder = (state) => state.setting.formOrder;
export const selectFormHeader = (state) => state.setting.formHeader;
export const selectFormToShow = (state) => state.setting.formToShow;
export const selectFormToChange = (state) => state.setting.formToChange;
export const selectPopupModal = (state) => state.setting.popupModal;
export const selectValidateForm = (state) => state.setting.validateForm;

export default settingSlice.reducer;
