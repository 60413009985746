import eng from './i18n/en/translation.json'
import viVN from './i18n/vi/translation.json'
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
      translation: eng,
    },
    vi: {
      translation: viVN,
    },
  };
  
  //i18N Initialization
  
  i18n
    .use(initReactI18next)
    .init({
      resources,
      lng:"vi", //default language
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
    });
  
  export default i18n;