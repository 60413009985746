import React from "react";
import AddImage from "./AddImage";
import { DeleteFilled } from "@ant-design/icons";
import ReactQuill from "react-quill";

function AddStory(props) {
  const { data, hiddenIcon, onChangeValue, onChangeRemove } = props;
  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 my-2">
      <div className="col-span-1 sm:col-span-2">
        <div className="w-full min-h-[240px] bg-[#F5F7FF] rounded-[18px]">
          <div className="w-full">
            <ReactQuill
              className="w-full border-none text-black"
              theme="snow"
              defaultValue={data.value}
              onChange={(e) => {
                onChangeValue({ id: data?.id, value: e });
              }}
              modules={{
                toolbar: [["bold", "italic", "underline"]],
              }}
            />
          </div>
          {!hiddenIcon && (
            <div className="w-full flex justify-center items-center">
              <div
                className="px-4 py-1 w-[120px] flex justify-center items-center text-[14px] rounded-[22px] text-red-600 bg-[#F7AAB2] cursor-pointer"
                onClick={() => onChangeRemove(data.id)}
              >
                Xóa đoạn{" "}
                <DeleteFilled style={{ color: "red", marginLeft: "2px" }} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="col-span-1">
        <AddImage
          data={{ ...data, image: data?.image }}
          onChangeImage={(value) => onChangeValue(value)}
          onHandleRemove={(value, id) => onChangeValue(value, id)}
        />
      </div>
    </div>
  );
}

export default AddStory;
