import React from "react";
import { Input, Form, Select, InputNumber, ConfigProvider } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";

export function FormInput(props) {
  return (
    <Form.Item
      label={props.title}
      style={props.styles}
      className={props.className}
    >
      <div className="flex flex-col">
        <ConfigProvider theme={props.theme}>
          <Input
            placeholder={props.placeholder}
            suffix={props.suffix}
            prefix={props.prefix}
            onChange={props.onChange}
            size={props.size}
            style={props.style}
            bordered={props.bordered}
            disabled={props.disabled}
            name={props.name}
            value={props.value}
            defaultValue={props.defaultValue}
            autoComplete="off"
          />
        </ConfigProvider>
        <div className={props.classNameBottom} />
      </div>

      {props.error && props.error !== "Bắt buộc" && (
        <p className="text-red-500 text-[12px]">{props.error}</p>
      )}
    </Form.Item>
  );
}

export const FormInputNumber = (props) => {
  return (
    <Form.Item
      label={props.title}
      style={props.styles}
      className={props.className}
    >
      <ConfigProvider theme={props.theme}>
        <InputNumber
          placeholder={props.placeholder}
          prefix={props.prefix}
          onChange={props.onChange}
          size={props.size}
          style={props.style}
          bordered={props.bordered}
          disabled={props.disabled}
          controls={false}
          value={props.value}
          // formatter={(valuen) => `${valuen}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          formatter={props.formatter}
          parser={(valuen) => valuen.replace(/\$\s?|(,*)/g, "")}
          addonAfter={props.addonAfter}
          autoComplete="new-password"
        />
      </ConfigProvider>
    </Form.Item>
  );
};

export const PasswordInput = (props) => {
  return (
    <Form.Item label={props.title} style={props.styles}>
      <div>
        <ConfigProvider theme={props.theme}>
          <Input.Password
            placeholder={props.placeholder}
            iconRender={(visible) =>
              visible ? (
                <EyeTwoTone sx={{ fontSize: 30 }} />
              ) : (
                <EyeInvisibleOutlined sx={{ fontSize: 30 }} />
              )
            }
            name={props.name}
            onChange={props.onChange}
            style={props.style}
            onPressEnter={props.onPressEnter}
            className={props.className}
            bordered={props.bordered}
          />
        </ConfigProvider>
        <div className={props.classNameBottom} />
      </div>
      {props.error && props.error !== "Bắt buộc" && (
        <p className="text-red-500 text-sm">{props.error}</p>
      )}
    </Form.Item>
  );
};

export const FormSelect = (props) => {
  return (
    <Form.Item
      label={props.title}
      style={props.styles}
      className={props.className}
    >
      <div className="flex flex-col">
        <ConfigProvider theme={props.theme}>
          <Select
            mode={props.mode}
            showSearch={props.showSearch}
            value={props.value}
            bordered={props.bordered}
            placeholder={props.placeholder}
            options={props.options}
            onChange={props.onChange}
            defaultValue={props.defaultValue}
            style={props.style}
            dropdownStyle={{ zIndex: 9999 }}
          />
        </ConfigProvider>
        <div className={props.borderBottom} />
      </div>
    </Form.Item>
  );
};
