import React from "react";
import { useAppSelector } from "../../redux/hooks";
import { selectFormOrder, selectFormToShow } from "../../redux/slice/setting";
import DoubleComponent from "../../components/SubComponent/DoubleComponent";
import AvatarDefaultCv from "../../components/Avatar";
import ContactBlock from "../../components/Block/ContactBlock";

const formTypeToComponent = {
  skills: (
    <DoubleComponent
      form="skills"
      listTitle={{ title: "Tên kĩ năng", description: "Mô tả kĩ năng" }}
    />
  ),
  cetifications: (
    <DoubleComponent
      form="cetifications"
      listTitle={{ title: "Tên chứng chỉ", description: "Thời gian" }}
    />
  ),
  honors_and_awards: (
    <DoubleComponent
      form="honors_and_awards"
      listTitle={{ title: "Tên giải thưởng", description: "Thời gian" }}
    />
  ),
  interestes: (
    <DoubleComponent form="interestes" listTitle={{ title: "Tên sở thích" }} />
  ),
  addtional_informations: (
    <DoubleComponent
      form="addtional_informations"
      listTitle={{ title: "Thông tin thêm" }}
    />
  ),
};

function LeftComponent() {
  const { left } = useAppSelector(selectFormOrder);
  const formToShow = useAppSelector(selectFormToShow);
  return (
    <div className="w-full">
      <div className="w-full h-[285px] p-4">
        <AvatarDefaultCv />
      </div>
      <div className="px-4">
        <ContactBlock />
        {left &&
          left.map((field) => {
            if (formToShow[field]) {
              const component = formTypeToComponent[field];
              return component;
            }
            return null;
          })}
      </div>
    </div>
  );
}

export default LeftComponent;
