import "./index.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Header } from "../components/Header/index.jsx";
import { Footer } from "../components/Footer/index.jsx";
import { routes, pathAdmin, paymentRoutes } from "./PathRouting.js";
import { PrivateRoute } from "../admin/Auth/PrivateRoute.js";
import Content from "../components/Header/Content.jsx";
import { AuthProvider } from "../admin/Auth/AuthProvider.js";
import Page404 from "./404/index.jsx";
import ChatMessage from "../components/ChatMessage/ChatMessage";
import { ThemeProvider } from "../lib/theme/ThemeProvider";

function MainPage() {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <AuthProvider>
          <Header />
          <Content>
            <Switch>
              {Object.keys(pathAdmin).map((key, index) => (
                <PrivateRoute
                  key={key + index}
                  exact
                  path={pathAdmin[key].path}
                >
                  {pathAdmin[key].component}
                </PrivateRoute>
              ))}
              {Object.keys(routes).map((key, index) => (
                <Route
                  key={key + index}
                  exact
                  path={routes[key].path}
                  component={routes[key].component}
                />
              ))}
              {Object.keys(paymentRoutes).map((key, index) => (
                <Route
                  key={key + index}
                  exact
                  path={paymentRoutes[key].path}
                  component={paymentRoutes[key].component}
                />
              ))}
              <Route path="*" component={Page404} />
            </Switch>
          </Content>
          <ChatMessage />
          <Footer />
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default MainPage;
