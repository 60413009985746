import React, { useState, useEffect } from "react";
import { Select, Divider } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import { SidebarRCJD } from "../../../components/Sidebar/RecruiterJD";
import { getJDbyID } from "../../../services/PORT";
import UserService from "../../../services/UserService";
import { useLocation, useHistory } from "react-router-dom";
import { getImage } from "../../../services/PORT";
import { marked } from "marked";
import { routes } from "../../PathRouting";
import { notification } from "antd";
import { getId, getSalary } from "../../../utils";
import { EditOutlined } from "@mui/icons-material";
import Breadcrumb from "../../../components/Tag/Breadcrumb";
import dayjs from "dayjs";
import { mangle } from "marked-mangle";
import { gfmHeadingId } from "marked-gfm-heading-id";

marked.use(mangle());
marked.use(
  gfmHeadingId({
    prefix: "jd-prefix-",
  })
);

export const DetailJD = () => {
  const location = useLocation();
  const history = useHistory();

  const [logo, setLogo] = useState();
  const [jobDetail, setJobDetail] = useState();
  const [appliedJDs, setAppliedJDs] = useState([]);
  const idJD = getId(location.pathname);

  notification.config({ top: 80, placement: "topRight" });

  const IconStatus = ({ color }) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 8 8"
        fill="none"
      >
        <circle cx="4" cy="4" r="4" fill={color} />
      </svg>
    );
  };

  const options = [
    {
      value: "hoạt động",
      label: (
        <div className="flex flex-row justify-start items-center space-x-2">
          <IconStatus color="#268D61" />
          <p>Hoạt động</p>
        </div>
      ),
    },
    {
      value: "ngưng hoạt động",
      label: (
        <div className="flex flex-row justify-start items-center space-x-2">
          <IconStatus color="#E60019" />
          <p>Ngưng hoạt động</p>
        </div>
      ),
    },
  ];

  const changeJDStatus = (status) => {
    let changeStatus;
    if (status === "hoạt động") {
      changeStatus = "ACTIVE";
    } else {
      changeStatus = "INACTIVE";
    }
    UserService.updateJDStatus(idJD, {
      status: changeStatus,
    });

    notification.success({
      message: "Thành công",
      description: "Đã thay đổi trạng thái thành công.",
      duration: 5, // Duration in seconds (adjust as needed)
      style: { top: 50 },
    });
  };

  useEffect(() => {
    const dataUser = JSON.parse(localStorage.getItem("User"));
    UserService.getListAppliedCv(idJD, { offset: 0 })
      .then((res) => {
        setAppliedJDs(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    UserService.getImage(getImage, { filename: dataUser.avatarImg })
      .then((res1) => {
        // logo doanh nghiệp
        setLogo(res1.data.data[0] && res1.data.data[0].fileUrl);
        // console.log(res1)
      })
      .catch((err1) => {
        console.log(err1);
      });

    UserService.getOneRecruiterByID(getJDbyID, idJD)
      .then((res) => {
        const data = res.data.data;
        setJobDetail({
          ...data,
          salary: getSalary(data.minSalary, data.maxSalary, data.salary),
          startReceiveCV: dayjs(data.startReceiveCV).format("DD/MM/YYYY"),
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  return (
    <div className="mx-auto h-auto w-full lg:max-w-[1170px]">
      <div className="px-4 pb-[120px]">
        <div className="mb-[40px]">
          <Breadcrumb
            data={[
              {
                name: "Dashboard",
                path: routes.recruiterDashboard.path,
              },
              {
                name: "Quản lý bài tuyển dụng",
                path: routes.recruiterManageJd.path,
              },
              {
                name: jobDetail?.jobName,
              },
            ]}
            isCheck
          />
        </div>

        <div className="flex flex-col lg:flex-row">
          <div className="bg-white rounded-[12px] p-[2%] w-full xl:w-[80%]">
            <div className="w-full flex flex-col md:flex-row md:justify-between md:items-center">
              <div className="flex flex-row space-x-10">
                <div className="w-[100px] h-[100px] rounded-[50%] flex justify-center items-center">
                  <img
                    src={logo}
                    alt=""
                    className="max-w-full max-h-full object-cover"
                  />
                </div>
                <div className="mb-[10px]">
                  <h3 className="text-[24px] font-[600] text-black">
                    {jobDetail?.jobName}
                  </h3>
                  <h1 className="text-[#8D9092] text-[16px] font-[600]">
                    {jobDetail?.enterprise}
                  </h1>
                  <p className="text-[#8D9092]">
                    <EnvironmentOutlined /> {jobDetail?.locationJob}
                  </p>
                </div>
              </div>
              <div className="w-full flex justify-end items-start md:w-[150px]">
                <div className="w-[150px] space-y-2">
                  <Select
                    placeholder=""
                    className="rounded-[12px] min-w-[150px]"
                    defaultValue={
                      jobDetail?.status === "INACTIVE"
                        ? "ngưng hoạt động"
                        : "hoạt động"
                    }
                    options={options}
                    onChange={(value, option) => changeJDStatus(value)}
                  />
                  <div
                    className="px-4 py-1 bg-blue-400 cursor-pointer rounded-[22px] text-[14px] text-white flex justify-center items-center"
                    onClick={() =>
                      history.push(
                        routes.recruiterUpdateJd.parentPath + `/${idJD}`
                      )
                    }
                  >
                    <EditOutlined
                      style={{
                        color: "white",
                        fontSize: "14px",
                        marginRight: "5px",
                      }}
                    />{" "}
                    Chỉnh sửa
                  </div>
                </div>
              </div>
            </div>
            <Divider className="bg-[#B5C1FF] h-[2px]" />
            <div>
              <h3 className="font-[600] text-[28px] py-[20px] text-black">
                Chi tiết công việc
              </h3>
              <div className="flex flex-row space-x-2 items-baseline">
                <img src="/asserts/Ring.svg" alt="" />
                <div className="m-0">
                  <h1 className="font-[600] text-[18px] text-black">
                    Mức lương
                  </h1>
                  <p>{jobDetail?.salary}</p>
                </div>
              </div>

              <div className="flex flex-row space-x-2 items-baseline">
                <img src="/asserts/Ring.svg" alt="" />
                <div className="m-0">
                  <h1 className="font-[600] text-[18px] text-black">
                    Trình độ
                  </h1>
                  {jobDetail?.levels &&
                    jobDetail.levels.map((item, idx) => (
                      <p key={"levels-" + idx + "-item"}>{item}</p>
                    ))}
                </div>
              </div>

              <div className="flex flex-row space-x-2 items-baseline">
                <img src="/asserts/Ring.svg" alt="" />
                <div className="m-0">
                  <h1 className="font-[600] text-[18px] text-black">
                    Số lượng tuyển dụng cho vị trí
                  </h1>
                  <p>{jobDetail?.positions}</p>
                </div>
              </div>
            </div>

            <div>
              <h3 className="font-[600] text-[28px] text-black py-[20px]">
                Mô tả công việc
              </h3>

              {jobDetail?.jobDescription &&
                jobDetail.jobDescription.map((item, index) => {
                  const __html = marked.parse(item);
                  return item ? (
                    <div key={"job-" + index + "description"}>
                      <p className="text-[18px] font-[600] text-black">
                        {index + 1}.{" "}
                        {index === 0
                          ? "Nhiệm vụ"
                          : index === 1
                          ? "Yêu cầu công việc"
                          : index === 2
                          ? "Quyền lợi"
                          : ""}
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: __html,
                        }}
                      />
                    </div>
                  ) : null;
                })}
            </div>

            <Divider className="bg-[#B5C1FF] h-[2px]" />

            <div>
              <h3 className="font-[600] text-[28px] text-black pt-[10px]">
                Thiết lập ứng tuyển
              </h3>
              <p className="text-black pb-[20px]">
                Các thông tin sau sẽ không được chia sẻ trên tin tuyển của bạn.
              </p>
              <div className="flex flex-row space-x-2 items-baseline">
                <img src="/asserts/Ring.svg" alt="" />
                <div className="m-0">
                  <h1 className="font-[600] text-[18px] text-black">
                    Yêu cầu CV
                  </h1>
                  <p>{jobDetail?.cvRequire ? "Có" : "Không"}</p>
                </div>
              </div>
              <div className="flex flex-row space-x-2 items-baseline">
                <img src="/asserts/Ring.svg" alt="" />
                <div className="m-0">
                  <h1 className="font-[600] text-[18px] text-black">
                    Gửi thông tin cập nhật tới
                  </h1>
                  {jobDetail?.dailyUpdate &&
                    jobDetail.dailyUpdate.map((item, idx) => (
                      <p key={"daily-" + idx + "update"}>{item}</p>
                    ))}
                </div>
              </div>
              <div className="flex flex-row space-x-2 items-baseline">
                <img src="/asserts/Ring.svg" alt="" />
                <div className="m-0">
                  <h1 className="font-[600] text-[18px] text-black">
                    Thời hạn ứng tuyển
                  </h1>
                  <p>{jobDetail?.startReceiveCV}</p>
                </div>
              </div>
              <div className="flex flex-row space-x-2 items-baseline">
                <img src="/asserts/Ring.svg" alt="" />
                <div className="m-0">
                  <h1 className="font-[600] text-[18px] text-black">
                    Câu hỏi tùy chỉnh
                  </h1>
                  {jobDetail?.questions && jobDetail?.questions.length > 0 && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: marked(jobDetail.questions[0]),
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            <div>
              <h3 className="font-[600] text-[28px] text-black py-[10px]">
                Thiết lập Công việc
              </h3>
              <div className="flex flex-row space-x-2 items-baseline">
                <img src="/asserts/Ring.svg" alt="" />
                <div className="m-0">
                  <h1 className="font-[600] text-[18px] text-black">
                    Quốc gia và ngôn ngữ
                  </h1>
                  {jobDetail?.languages &&
                    jobDetail.languages.map((item, idx) => (
                      <p key={"languages-" + idx + "-item"}>- {item}</p>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <SidebarRCJD data={appliedJDs} jdApply={idJD} />
        </div>
      </div>
    </div>
  );
};
