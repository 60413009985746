import React, { useEffect, useState } from "react";
import BreadcrumbAdmin from "../components/Tag/Breadcrumb";
import { pathAdmin } from "../../screens/PathRouting";
import { Select } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import CardRecruiter from "../components/Card/CardRecruiter";
import AdminService from "../../services/AdminService";
import { getId, getLocation } from "../../utils";
import queryString from "query-string";
import UserService from "../../services/UserService";
import dayjs from "dayjs";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";

function PersionalDetail() {
  const { theme } = useTheme();
  const history = useHistory();
  const location = useLocation();
  const [userData, setUserData] = useState();
  const [countOption, setCountOption] = useState();
  const [postList, setPostList] = useState();

  const id = getId(location.pathname);

  useEffect(() => {
    if (!userData) {
      getUserDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const getUserDetail = async (params = {}) => {
    const response = await AdminService.getUserById(
      "?" + queryString.stringify({ id: id, status: "ACTIVE", ...params })
    );

    const data = response.data.data;
    const recruiter = data.recruiter[0];

    let avatar = "";
    if (recruiter?.avatarImg && recruiter.avatarImg.length > 0) {
      avatar = await UserService.getImageByName(recruiter.avatarImg);
      avatar = avatar.data.data[0].fileUrl;
    }

    let listJd = data?.jd || [];
    listJd = listJd.map((item) => ({
      id: item.id,
      jobName: item.jobName,
      date: dayjs(new Date(item.startReceiveCV)).format("DD/MM/YYYY"),
      cvApplied: item.cvApplyList.length,
      location: getLocation(item.location),
      status: item?.status ? item.status.toLowerCase() : "inactive",
    }));
    setUserData({ ...recruiter, avatarImg: avatar });
    setCountOption({ cv: data.countCVs, jd: data.countJds });
    setPostList(listJd);
  };

  const options = [
    {
      value: "Active",
      label: (
        <div className="flex flex-row justify-start items-center space-x-2">
          <div className="w-[8px] h-[8px] rounded-[50%] bg-[#268D61]"></div>
          <p>Active</p>
        </div>
      ),
    },
    {
      value: "Inactive",
      label: (
        <div className="flex flex-row justify-start items-center space-x-2">
          <div className="w-[8px] h-[8px] rounded-[50%] bg-[#E60019]"></div>
          <p>Inactive</p>
        </div>
      ),
    },
  ];
  return (
    <div
      className={cx(
        "mx-auto min-h-[80vh] lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <div className="px-4">
        <BreadcrumbAdmin
          data={[
            {
              name: "Quản lý nhân sự",
              path: pathAdmin.adminManagePersonal.path,
            },
            {
              name: "Danh sách nhân sự",
              path: pathAdmin.adminManagePersonal.path,
            },
            {
              name: "Hồ sơ nhân sự",
            },
          ]}
        />
      </div>
      <div className="w-full p-[10px] sm:p-[30px] rounded-[20px] bg-white grid grid-cols-1 sm:grid-cols-9">
        <div className="w-[100px] h-[100px] rounded-[50%] bg-gray-300 col-span-1">
          <img
            src={userData?.avatarImg}
            alt=""
            className="w-full h-full rounded-[50%] object-cover"
          />
        </div>
        <div className="sm:col-span-8 sm:ml-8 md:ml-0">
          <p className="text-[22px] font-[600] text-black mb-3">
            {userData?.fullName}
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
            <p>
              Ngày tham gia:{" "}
              {userData?.createAt &&
                dayjs(new Date(userData.createAt)).format("DD/MM/YYYY")}
            </p>
            <p>Số điện thoại: {userData?.phoneNumber}</p>
            <p>Số bài viết: {countOption?.jd || 0}</p>
            <p>Người thêm: </p>
            <p>Email: {userData?.email}</p>
            <p>Số ứng viên ứng tuyển: {countOption?.cv || 0}</p>
          </div>
        </div>
      </div>
      <div className="w-full md:flex md:justify-between md:items-center my-8">
        <div className="flex">
          <p className={cx("text-[20px] font-[600]", theme?.background?.text)}>
            Bài tuyển dụng đã đăng
          </p>
          <Select
            placeholder=""
            className="rounded-[12px] min-w-[80px] border-[1px] ml-4"
            defaultValue="Active"
            options={options}
            onChange={(value) => getUserDetail({ status: value.toUpperCase() })}
          />
        </div>
      </div>
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        {postList &&
          postList.length > 0 &&
          postList.map((item) => (
            <CardRecruiter
              status={item.status}
              data={item}
              onHandleDetail={() =>
                history.push(
                  pathAdmin.adminPostCandidate.parentPath + `/${item.id}`
                )
              }
            />
          ))}
      </div>
    </div>
  );
}

export default PersionalDetail;
