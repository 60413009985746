import {
  Email,
  Facebook,
  LinkedIn,
  LocationCity,
  Person,
  Phone,
} from "@mui/icons-material";
import React from "react";
import InputCv from "../Input";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  DEFAULT_FONT_FAMILY,
  DEFAULT_FONT_SIZE,
  changeValidateForm,
  selectSetting,
  selectValidateForm,
} from "../../redux/slice/setting";
import { changeProfile, selectProfile } from "../../redux/slice/builder";
import { cx } from "../../../lib/cx";

function ContactBlock({ titleColor, className }) {
  const setting = useAppSelector(selectSetting);
  const profile = useAppSelector(selectProfile);
  const validateForm = useAppSelector(selectValidateForm);
  const dispatch = useAppDispatch();

  const handleChangeInput = (field, value) => {
    dispatch(changeProfile({ field, value }));
    dispatch(changeValidateForm({ field, value: "" }));
  };

  const styleIcon = {
    fontSize: `${Number(setting.fontSize || DEFAULT_FONT_SIZE) + 2}px`,
    color: titleColor || "white",
    marginRight: "5px",
  };

  const listItems = [
    {
      icon: <Person style={styleIcon} />,
      key: "dateOfBirdth",
      placeholder: "Ngày sinh",
    },
    {
      icon: <Email style={styleIcon} />,
      key: "email",
      placeholder: "email",
    },
    {
      icon: <Facebook style={styleIcon} />,
      key: "facebook",
      placeholder: "facebook",
    },
    {
      icon: <LinkedIn style={styleIcon} />,
      key: "linkedin",
      placeholder: "linked in",
    },
    {
      icon: <Phone style={styleIcon} />,
      key: "phoneNumber",
      placeholder: "số điện thoại",
    },
    {
      icon: <LocationCity style={styleIcon} />,
      key: "location",
      placeholder: "Địa chỉ",
    },
  ];

  return (
    <div className="w-full mt-4">
      <p
        className={cx("font-[600]")}
        style={{
          fontFamily: `${setting.fontFamily || DEFAULT_FONT_FAMILY} `,
          fontSize: `${Number(setting.fontSize || DEFAULT_FONT_SIZE) + 8}px`,
          color: `${titleColor || "white"}`,
        }}
      >
        Thông tin cá nhân
      </p>
      {listItems.map((item) => (
        <>
          <div className="flex justify-start items-center">
            {item?.icon}
            <InputCv
              id={item.key}
              name={item.key}
              value={profile[item.key]}
              placeholder={item.placeholder}
              onChangeInput={handleChangeInput}
              className={cx("placeholder:italic placeholder:text-[#fffdfd] !-pt-1", className)}
              error={validateForm[item.key]}
              style={{
                color: `${titleColor ? "black" : "white"}`,
              }}
            />
          </div>
        </>
      ))}
    </div>
  );
}

export default ContactBlock;
