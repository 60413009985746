import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { pathAdmin, routes } from "../../screens/PathRouting";
import { LeftOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { LableItem } from "../components/Tag/IconCircle";
import CvAppliedPost from "./CvAppliedPost";
import BreadcrumbAdmin from "../components/Tag/Breadcrumb";
import AllCvComponent from "./AllCvComponent";
import queryString from "query-string";
import UserService from "../../services/UserService";
import AdminService from "../../services/AdminService";
import {
  firstLetter,
  getId,
  getImageUrl,
  getLocation,
  getSalary,
} from "../../utils";
import ShowCandidateCv from "../../components/Modals/ShowCandidateCv";
import dayjs from "dayjs";
import { notification } from "antd";
import { marked } from "marked";

function PostRecruitment() {
  const location = useLocation();
  const history = useHistory();

  const [postDetail, setPostDetail] = useState();
  const [listCv, setListCv] = useState();
  const [company, setCompany] = useState();

  const [active, setActive] = useState(false);
  const [cvShow, setCvShow] = useState();

  const [valueActive, setValueActive] = useState();

  const id = getId(location.pathname);

  notification.config({ top: 80, placement: "topRight" });

  useEffect(() => {
    if (!postDetail) {
      getPostDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postDetail]);

  const getPostDetail = async () => {
    const response = await AdminService.getJdById(id);
    if (!response?.data?.data?.jd) {
      history.push(routes.notFound404.path);
      return;
    }

    const data = response.data.data.jd;
    const dataRecruiter = response.data.data.recruiter[0];

    if (data.company) {
      const response = await AdminService.getCompanyById(data.company);
      const companyData = response.data.data[0];
      const listImage = await UserService.getImageByName([companyData.avatar]);
      setCompany({
        name: companyData.name,
        address: companyData.address,
        note: companyData.note,
        avatar: listImage.data.data[0].fileUrl,
      });
    }

    let listImage = [data.companyAvatar, dataRecruiter.avatarImg[0]];
    listImage = await UserService.getImageByName(listImage);
    listImage = listImage.data.data;

    let listCvApplied = [];
    if (data.cvApplyList && data.cvApplyList.length > 0) {
      const cvApplied = data.cvApplyList.map((item) => item.cvImage);
      listCvApplied = await UserService.getImageByName(cvApplied);
      listCvApplied = data.cvApplyList.map((item) => ({
        ...item,
        image: getImageUrl(listCvApplied.data.data, item.cvImage),
      }));
    }
    setListCv(listCvApplied);
    setPostDetail({
      ...data,
      companyAvatar: getImageUrl(listImage, data.companyAvatar),
    });
    setValueActive(firstLetter(data.status.toLowerCase()));
  };

  const changeStatus = async (value) => {
    await UserService.updateJDStatus(id, { status: value.toUpperCase() }).then(
      () => {
        notification.success({
          message: (
            <span
              style={{
                fontFamily: "Arial",
                fontSize: "24px",
                fontWeight: "bold",
                color: "#33AA33",
              }}
            >
              Thành công
            </span>
          ),
          description: (
            <span
              style={{
                fontFamily: "Helvetica",
                fontSize: "20px",
                color: "#444",
              }}
            >
              Chỉnh sửa trạng thái thành công!
            </span>
          ),
          duration: 4, // Duration in seconds (adjust as needed)
          style: {
            color: "#fff", // Custom text color
            borderRadius: "8px", // Rounded corners
            top: "90px", // Adjust the value to change the vertical position
            zIndex: "9999999",
          },
        });
      }
    );
  };

  const options = [
    {
      value: "Active",
      label: (
        <div className="flex flex-row justify-start items-center space-x-2">
          <div className="w-[8px] h-[8px] rounded-[50%] bg-[#268D61]"></div>
          <p>Active</p>
        </div>
      ),
    },
    {
      value: "Inactive",
      label: (
        <div className="flex flex-row justify-start items-center space-x-2">
          <div className="w-[8px] h-[8px] rounded-[50%] bg-[#E60019]"></div>
          <p>Inactive</p>
        </div>
      ),
    },
    // {
    //   value: "Delete",
    //   label: (
    //     <div className="flex flex-row justify-start items-center space-x-2">
    //       <div className="w-[8px] h-[8px] rounded-[50%] bg-[#ebe84f]"></div>
    //       <p>Delete</p>
    //     </div>
    //   ),
    // },
  ];

  const isShowAll = useCallback(() => {
    const search = queryString.parse(location.search);
    return search.show === "all";
  }, [location]);

  return (
    <div className="mx-auto h-auto lg:max-w-[1175px] bg-[#F2F7FF] z-1 select-none mb-[120px]">
      <div className="px-4">
        <BreadcrumbAdmin
          data={[
            {
              name: "Quản lý danh mục công ty",
              path: pathAdmin.adminManageCompany.path,
            },
            {
              name: company?.name,
              path:
                pathAdmin.adminCompanyDetail.parentPath +
                `/${postDetail?.company}`,
            },
            {
              name: postDetail?.jobName,
            },
          ]}
        />

        <div className="w-full bg-white rounded-[18px] relative">
          <div className="grid grid-cols-4 sm:grid-cols-10 px-6 py-4">
            <div className="col-span-1 flex justify-start items-center">
              <div className="w-[80px] h-[80px] flex justify-center items-center">
                <img
                  src={company?.avatar}
                  alt=""
                  className="max-w-full max-h-full object-cover"
                />
              </div>
            </div>
            <div className="col-span-3 pl-4 md:pl-0 md:col-span-8">
              <p className="font-[600]">Công ty</p>
              <p className="text-[18px] text-black font-[600]">
                {company?.name}
              </p>
              <div className="flex justify-start items-center">
                <img
                  src="/asserts/location.png"
                  alt=""
                  className="w-[12px] h-[12px]"
                />
                <p className="ml-1">
                  {company?.address && getLocation(company.address)}
                </p>
              </div>
              <div className="flex justify-start items-center">
                <img
                  src="/icons/notepad2.png"
                  alt=""
                  className="w-[12px] h-[12px]"
                />
                <p className="ml-1">{company?.note}</p>
              </div>
            </div>
          </div>
          <div className="w-[18px] h-full rounded-tr-[12px] rounded-br-[12px] bg-[#FF9900] absolute top-0 right-0"></div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 my-[40px]">
          <div className="col-span-1 md:col-span-3 flex justify-between items-center">
            <div
              className="border px-4 py-2 rounded-[12px] text-[14px] flex justify-center items-center cursor-pointer"
              onClick={() => window.history.back()}
            >
              <LeftOutlined style={{ font: "14px", marginRight: "4px" }} />
              Quay lại
            </div>
          </div>
        </div>

        {!isShowAll() ? (
          <div className="grid grid-cols-1 md:grid-cols-4 md:gap-4">
            <div className="col-span-3">
              <div className="p-[20px] bg-white rounded-[22px]">
                <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
                  <div className="col-span-1 md:col-span-5">
                    <div className="w-full grid grid-cols-6 gap-4">
                      <div className="col-span-2 md:col-span-1">
                        <div className="w-[80px] h-[80px] flex justify-end items-center">
                          <img
                            src={postDetail?.companyAvatar}
                            alt=""
                            className="max-w-full max-h-full object-cover"
                          />
                        </div>
                      </div>

                      <div className="col-span-4 md:col-span-5">
                        <p className="text-[18px] font-[600] text-black line-clamp-1">
                          {postDetail?.jobName}
                        </p>
                        <p className="text-[16px] line-clamp-1">
                          {postDetail?.companyName}
                        </p>
                        <div className="flex justify-start items-center">
                          <img
                            src="/asserts/location.png"
                            alt=""
                            className="w-[14px] h-[14px]"
                          />
                          <p className="ml-2">
                            {" "}
                            {getLocation(postDetail?.location)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 flex justify-end md:justify-center items-center">
                    <Select
                      placeholder=""
                      value={valueActive}
                      className="rounded-[12px] min-w-[80px]"
                      options={options}
                      onChange={(value) => {
                        setValueActive(value);
                        changeStatus(value);
                      }}
                    />
                  </div>
                </div>

                <hr className="my-6" />

                <div className="mt-[40px]">
                  <p className="text-black font-[700] text-[20px]">
                    Chi tiết công việc
                  </p>
                  <div className="grid grid-cols-1 md:grid-cols-2 mt-4">
                    <div>
                      <LableItem
                        label="Mức lương"
                        description={getSalary(
                          postDetail?.minSalary,
                          postDetail?.maxSalary,
                          postDetail?.salary,
                          "vnd"
                        )}
                      />
                      <LableItem
                        label="Vị trí"
                        description={
                          postDetail?.levels &&
                          postDetail?.levels.length > 0 &&
                          postDetail.levels[0]
                        }
                      />
                      <LableItem
                        label="Số lượng tuyển dụng cho vị trí"
                        description={postDetail?.positions}
                      />
                    </div>

                    <div>
                      <LableItem label="Hình thức" description="Fulltime" />
                      <LableItem
                        label="Kinh nghiệm"
                        description={
                          postDetail?.experience > 0
                            ? postDetail.experience + " năm kinh nghiệm"
                            : "Không yêu cầu kinh nghiệm"
                        }
                      />
                      <LableItem
                        label="Địa điểm làm việc"
                        description={"- " + postDetail?.location}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-[40px]">
                  <p className="text-black font-[700] text-[20px]">
                    Mô tả công việc
                  </p>
                  {postDetail?.jobDescription &&
                    postDetail.jobDescription.map((item, index) => {
                      if (item && index === 0) {
                        return (
                          <>
                            <p className="text-[18px] font-[600] text-black">
                              {index + 1}. Nhiệm vụ
                            </p>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: marked(item),
                              }}
                            />
                          </>
                        );
                      }
                      if (item && index === 1) {
                        return (
                          <>
                            <p className="text-[18px] font-[600] text-black">
                              {index + 1}. Yêu cầu công việc
                            </p>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: marked(item),
                              }}
                            />
                          </>
                        );
                      }
                      if (item && index === 2) {
                        return (
                          <>
                            <p className="text-[18px] font-[600] text-black">
                              {index + 1}. Quyền lợi
                            </p>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: marked(item),
                              }}
                            />
                          </>
                        );
                      }
                      return null;
                    })}
                </div>

                <hr className="my-6" />

                <div className="mt-[40px]">
                  <p className="text-black font-[700] text-[20px]">
                    Thiết lập ứng tuyển
                  </p>
                  <p>
                    Các thông tin sau sẽ không được chia sẻ trên tin tuyển của
                    bạn.
                  </p>

                  <LableItem
                    label="Cách thức ứng tuyển"
                    description="Qua Email"
                  />

                  <LableItem
                    label="Yêu cầu CV"
                    description={postDetail?.cvRequire ? "Có" : "Không"}
                  />

                  <LableItem
                    label="Gửi thông tin cập nhật tới"
                    description={
                      postDetail?.dailyUpdate &&
                      postDetail.dailyUpdate.length > 0
                        ? postDetail.dailyUpdate.join(", ")
                        : "Chưa cập nhật"
                    }
                  />

                  <LableItem
                    label="Cho phép nhắn tin"
                    description="Có, ứng viên có thể gửi tin nhắn về tin tuyển dụng"
                  />

                  <LableItem
                    label="Thời hạn ứng tuyển"
                    description={dayjs(
                      new Date(postDetail?.endReceiveCV)
                    ).format("DD/MM/YYYY")}
                  />

                  <LableItem
                    label="Câu hỏi tùy chỉnh"
                    description={
                      postDetail?.questions && postDetail.questions.length > 0
                        ? postDetail.questions[0]
                        : "Chưa cập nhật"
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <CvAppliedPost
                data={listCv}
                onChangeCv={(value) => {
                  setCvShow(value);
                  setActive(!active);
                }}
              />
            </div>
          </div>
        ) : (
          <AllCvComponent
            data={listCv}
            jdId={id}
            jdName={postDetail?.jobName}
            onChangeCv={(value) => {
              setCvShow(value);
              setActive(!active);
            }}
          />
        )}
      </div>
      <ShowCandidateCv
        active={active}
        onChangeActive={() => {
          setCvShow();
          setActive(!active);
        }}
        data={cvShow}
      />
    </div>
  );
}

export default PostRecruitment;
