import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { changeProfile, selectProfile } from "../../../lib/redux/resumeSlice";
import { BaseForm } from "./Form";
import { Input, Textarea } from "./Form/InputGroub";


export const ProfileForm = () => {
  const profile = useAppSelector(selectProfile);
  const dispatch = useAppDispatch();
  const { name, email, phone, url, summary, location } = profile;

  const handleProfileChange = (field, value) => {
    dispatch(changeProfile({ field, value }));
  };

  return (
    <BaseForm>
      <div className="grid grid-cols-6 gap-3">
        <Input
          label="Họ và tên"
          labelClassName="col-span-full"
          name="name"
          placeholder="Nhập họ và tên"
          value={name}
          onChange={handleProfileChange}
        />
        <Textarea
          label="Mục tiêu nghề nghiệp"
          labelClassName="col-span-full"
          name="summary"
          placeholder="Mục tiêu dài hạn và mục tiêu ngắn hạn của bạn"
          value={summary}
          onChange={handleProfileChange}
        />
        <Input
          label="Email"
          labelClassName="col-span-4"
          name="email"
          placeholder="Nhập email"
          value={email}
          onChange={handleProfileChange}
        />
        <Input
          label="Số điện thoại"
          labelClassName="col-span-2"
          name="phone"
          placeholder="Nhập số điện thoại"
          value={phone}
          onChange={handleProfileChange}
        />
        <Input
          label="Website"
          labelClassName="col-span-4"
          name="url"
          placeholder="Website cá nhân"
          value={url}
          onChange={handleProfileChange}
        />
        <Input
          label="Địa chỉ"
          labelClassName="col-span-2"
          name="location"
          placeholder="Nhập địa chỉ"
          value={location}
          onChange={handleProfileChange}
        />
      </div>
    </BaseForm>
  );
};
