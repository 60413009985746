import React from "react";
import { cx } from "../../lib/cx";
import { CloseOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import { routes } from "../../screens/PathRouting";

function ChooseCVNotLogin({ active, onChangeActive }) {
  const history = useHistory();
  const location = useLocation();

  const handleCreateNewCv = () => {
    history.push(routes.templateCv.path + `?redirect=${location.pathname}`);
  };

  const handleLogin = () => {
    history.push(
      routes.loginPage.path + `?role=Candidate&redirect=${location.pathname}`
    );
  };

  return (
    <div
      className={cx(
        `overflow-x-hidden overflow-y-auto fixed inset-0 z-9999 h-screen outline-none focus:outline-none justify-center items-center c-modal bg-black bg-opacity-40`,
        active ? "block" : "hidden"
      )}
    >
      <div className="w-full h-[100vh] flex justify-center items-center px-4">
        <div className="w-full sm:w-[650px] bg-white h-auto shadow-md rounded-[4px] pb-[20px]">
          <div
            className="w-full flex justify-end items-center cursor-pointer pt-[10px] pr-[15px]"
            onClick={onChangeActive}
          >
            <CloseOutlined style={{ fontSize: "15px" }} />
          </div>

          <p className="text-center mt-3 font-[600]">
            Bạn chưa đăng nhập hệ thống trước đó
          </p>
          <p className="text-center mb-3 font-[600]">
            Bạn đã có CV trên hệ thống LDO chưa?
          </p>

          <div className="w-full p-[10px] grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div
              className="w-full h-[140px] bg-gradient-to-br from-[#2A2664] to-[#6F2F66] text-white rounded-[8px] p-[12px] shadow-md hover:shadow-lg cursor-pointer"
              onClick={handleCreateNewCv}
            >
              <h5 className="text-center uppercase">Tôi chưa có </h5>
              <div className="text-center mt-3">
                <p>Tạo CV cùng LDO </p>
                <p>và</p>
                <p> ứng tuyển vị trí này</p>
              </div>
            </div>
            <div
              className="w-full h-[140px] bg-gradient-to-bl from-[#2A2664] to-[#6F2F66] text-white rounded-[8px] p-[12px] shadow-md hover:shadow-lg cursor-pointer"
              onClick={handleLogin}
            >
              <h5 className="text-center uppercase">Tôi đã có </h5>
              <div className="text-center mt-3">
                <p>Đăng nhập tài khoản </p>
                <p>và</p>
                <p> ứng tuyển vị trí này</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseCVNotLogin;
