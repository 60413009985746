import React, { useState } from "react";
import ScrollTop from "../../components/ScrollTop";
import { formatVND } from "../../utils";
import { EyeFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import PaymentDetailHistoryModal from "./components/PaymentDetailHistoryModal";
import { DATA_PAYMENT_FAKE } from "../../utils/constants";
import dayjs from "dayjs";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";

function PaymentHistory() {
  const { theme } = useTheme();
  const [activeModal, setActiveModal] = useState(false);

  const [dataShow, setDataShow] = useState({ data: {}, payment: {} });

  const data = [
    {
      customerName: "Nguyễn Văn A",
      comboCode: "R01",
      combo: "COMBO SUPER R01",
      amount: 5000000,
      status: "success",
      createdAt: "2023-09-10",
    },
    {
      customerName: "Nguyễn Văn A",
      comboCode: "R01",
      combo: "COMBO SUPER R01",
      amount: 1290000,
      status: "faild",
      createdAt: "2023-09-07",
    },
    {
      customerName: "Nguyễn Văn A",
      comboCode: "R03",
      combo: "COMBO SUPER R03",
      amount: 12900000,
      status: "pending",
      createdAt: "2023-08-10",
    },
    {
      customerName: "Nguyễn Văn A",
      comboCode: "R02",
      combo: "COMBO SUPER R02",
      amount: 7000000,
      status: "success",
      createdAt: "2023-08-10",
    },
    {
      customerName: "Nguyễn Văn A",
      comboCode: "R03",
      combo: "COMBO SUPER R03",
      amount: 9000000,
      status: "faild",
      createdAt: "2023-08-01",
    },
  ];

  const handleChangeDetail = (payment) => {
    const data = DATA_PAYMENT_FAKE.find(
      (item) => item.code === payment.comboCode
    );
    setDataShow({ payment, data });
    setActiveModal(!activeModal);
  };
  return (
    <div
      className={cx(
        "mx-auto min-h-[80vh] lg:max-w-[1175px] select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <ScrollTop />
      <div className="px-4">
        <div className="text-[20px] flex flex-col">
          <h3
            className={cx(
              "cursor-pointer hover:underline float-left inline my-[60px]",
              theme?.background?.text
            )}
          >
            Lịch sử giao dịch
          </h3>
        </div>
        <div className="mt-[10px] bg-[#F2F7FF]">
          <div className="overflow-x-auto py-[10px]">
            <table className="w-full min-w-[1175px] max-w-[5000px]">
              <thead>
                <tr>
                  {[
                    "STT",
                    "Combo code",
                    "Tên combo",
                    "Tên khách hàng",
                    "Thành tiền",
                    "Thời gian",
                    "Trạng thái",
                    "Hành động",
                  ].map((item, index) => (
                    <th
                      key={index}
                      className="py-1 text-[14px] uppercase text-center bg-[#B7E1FF] rounded-[12px] border-[1px] md:border-[4px] border-[#F4F9FF]"
                    >
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((payment, index) => (
                  <tr
                    key={index}
                    className="bg-white hover:bg-gray-1 cursor-pointer"
                    onClick={() => handleChangeDetail(payment)}
                  >
                    {[
                      `${index + 1}`,
                      payment.comboCode,
                      payment.combo,
                      payment.customerName,
                      formatVND(payment.amount),
                      dayjs(new Date(payment.createdAt)).format("DD/MM/YYYY"),
                    ].map((item, index) => (
                      <td
                        key={index}
                        className="py-2 px-4 rounded-[12px] border-[1px] md:border-[6px] border-[#F4F9FF] text-center"
                      >
                        {item}
                      </td>
                    ))}
                    <td className="py-2 px-4 rounded-[12px] border-[1px] md:border-[6px] border-[#F4F9FF] text-center">
                      {payment.status === "success" ? (
                        <div className="bg-green-400 text-white rounded-[20px]">
                          Thành công
                        </div>
                      ) : payment.status === "faild" ? (
                        <div className="bg-red-400 text-white rounded-[20px]">
                          Thất bại
                        </div>
                      ) : (
                        <div className="bg-blue-400 text-white rounded-[20px]">
                          Đang sử lý
                        </div>
                      )}
                    </td>
                    <td className="py-2 px-4 rounded-[12px] border-[1px] md:border-[6px] border-[#F4F9FF] text-center">
                      <Tooltip title="Xem chi tiết">
                        <EyeFilled />
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <PaymentDetailHistoryModal
        active={activeModal}
        paymentData={dataShow}
        onChangeActive={() => setActiveModal(!activeModal)}
      />
    </div>
  );
}

export default PaymentHistory;
