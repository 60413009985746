import React, { useEffect } from "react";
import { cx } from "../../lib/cx";
import Input from "../Input";
import { useFormik } from "formik";
import { registerCandidateSchema } from "../../utils/validations";
import { useTheme } from "../../lib/theme/useTheme";
import { useAppDispatch, useAppSelector } from "../../cv/redux/hooks";
import { selectProfile } from "../../cv/redux/slice/builder";
import { formatPhoneNumber } from "../../utils";
import { changeActions, selectActions } from "../../cv/redux/slice/setting";
import { WarningOutlined } from "@ant-design/icons";
import UserService from "../../services/UserService";
import { notification } from "antd";
import { login } from "../../services/PORT";
import { useConfigCvFile } from "../../cv/builder/hook";

function RegisterCandidateModal() {
  const { theme } = useTheme();
  const { handleSaveCv } = useConfigCvFile();
  const profile = useAppSelector(selectProfile);
  const { openRegister } = useAppSelector(selectActions);
  const dispatch = useAppDispatch();

  const createSignUpFormik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phone: "",
      password: "",
      rePassword: "",
    },
    validationSchema: registerCandidateSchema,
    onSubmit: (values) => {
      const dataSend = {
        ...values,
        phoneNumber: formatPhoneNumber(values.phone),
      };
      delete dataSend.rePassword;
      delete dataSend.phone;
      creatNewUser(dataSend);
    },
  });

  useEffect(() => {
    if (profile) {
      changeValueInput("email", profile.email);
      changeValueInput("fullName", profile.fullName);
      changeValueInput("phone", profile.phoneNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const creatNewUser = async (value) => {
    try {
      const responsRegister = await UserService.autoRegisterAccount(value);
      const account = responsRegister.data.data;
      userLoginAfter({
        email: account.email,
        password: value.password,
        role: "Candidate",
      });
      dispatch(changeActions({ field: "openRegister" }));
    } catch (error) {
      console.log(new Error(error));
      notification.error({
        message: "Thất bại",
        description: `Email đã được đăng ký. Vui lòng nhập email khác`,
        duration: 4,
        style: { top: 50 },
      });
    }
  };

  const userLoginAfter = async (data) => {
    try {
      UserService.checkUser(data, login).then((response) => {
        localStorage.setItem("User", JSON.stringify(response.data.data));
        localStorage.setItem(
          "loginStatus",
          JSON.stringify({ status: "success", role: data.role })
        );
        handleSaveCv();
      });
    } catch (error) {
      notification.error({
        message: "Đăng nhập thất bại",
        description: `Tài khoản hoặc mật khẩu không chính xác`,
        duration: 4,
        style: { top: 50 },
      });
      console.log(new Error(error));
    }
  };

  const changeValueInput = (field, value) => {
    createSignUpFormik.handleChange({
      target: {
        name: field,
        value: value,
      },
    });
  };

  const getErrorMessage = (field) => {
    return createSignUpFormik.errors[field] && createSignUpFormik.touched[field]
      ? createSignUpFormik.errors[field]
      : "";
  };

  return (
    <div
      className={cx(
        `overflow-x-hidden overflow-y-auto fixed inset-0 z-9999 h-screen outline-none focus:outline-none justify-center items-center c-modal bg-black bg-opacity-40`,
        openRegister ? "block" : "hidden"
      )}
    >
      <div className="w-full h-[100vh] flex justify-center items-center px-4">
        <div className="w-full sm:w-[450px] bg-white h-auto shadow-md rounded-[22px] p-[10px] md:px-[20px]">
          <h3 className="text-center text-[20px]">
            Đăng ký tài khoản LDO
          </h3>
          <form className="mt-[20px]">
            <div className="grid grid-cols-1 gap-4">
              <Input
                name="fullName"
                label="Họ và tên"
                value={createSignUpFormik.values.fullName}
                onChangeInput={createSignUpFormik.handleChange}
                error={getErrorMessage("fullName")}
                placeholder={"Họ và tên"}
                required
                className="!mt-1"
              />

              <Input
                name="email"
                label={"Email"}
                value={createSignUpFormik.values.email}
                onChangeInput={createSignUpFormik.handleChange}
                error={getErrorMessage("email")}
                placeholder={"Sử dụng email để xác nhận"}
                required
                className="!mt-1"
              />

              <Input
                name="phone"
                label={"Số điện thoại"}
                value={createSignUpFormik.values.phone}
                onChangeInput={createSignUpFormik.handleChange}
                error={getErrorMessage("phone")}
                placeholder={"Nhập SĐT"}
                required
                className="!mt-1"
              />
            </div>

            <div className="mt-2">
              <Input
                type="password"
                name="password"
                label={"Mật khẩu"}
                onChangeInput={createSignUpFormik.handleChange}
                error={getErrorMessage("password")}
                placeholder={
                  "Từ 6-50 ký tự, 1 hoa, 1 thường, 1 số, 1 ký tự đặc biệt."
                }
                required
                className="!mt-1"
                isCheckPassword
              />
            </div>

            <div className="mt-2">
              <Input
                type="password"
                name="rePassword"
                label={"Nhập lại mật khẩu"}
                onChangeInput={createSignUpFormik.handleChange}
                error={getErrorMessage("rePassword")}
                placeholder={
                  "Từ 6-50 ký tự, 1 hoa, 1 thường, 1 số, 1 ký tự đặc biệt."
                }
                required
                className="!mt-2"
                isCheckPassword
              />
            </div>
          </form>
          <div className="mt-[20px] italic">
            <WarningOutlined style={{ color: "red" }} /> Hệ thống: Email hoặc số
            điện thoại bạn nhập trên cv đã được đăng ký, vui lòng nhập tài khoản
            khác.
          </div>
          <div className="w-full flex justify-center items-center space-x-3 my-[15px]">
            <button
              className="px-3 py-1 rounded-[10px] bg-gray text-white"
              onClick={() => dispatch(changeActions({ field: "openRegister" }))}
            >
              Thoát
            </button>
            <button
              className={cx(
                "px-3 py-1 rounded-[10px] text-white",
                theme?.button?.background
              )}
              onClick={createSignUpFormik.handleSubmit}
            >
              Đăng ký
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterCandidateModal;
