import { useLocation, useHistory } from "react-router-dom";
import { getId } from "../../utils";
import { routes } from "../PathRouting";

function CodeRefferall() {
  const location = useLocation();
  const history = useHistory();

  const params1 = getId(location.pathname, 2);
  const params2 = getId(location.pathname, 1);

  if (!["c", "r"].includes(params1)) {
    history.push(routes.home.path);
    return;
  }

  if (params1 === "c") {
    history.push(routes.signupCandidate.path + `?code=${params2}`);
    return;
  }

  if (params1 === "r") {
    history.push(routes.signupRecruiter.path + `?code=${params2}`);
    return;
  }

  return null;
}

export default CodeRefferall;
