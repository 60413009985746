import { View } from "@react-pdf/renderer";
import { styles } from "./styles";
import { ResumePDFBulletList, ResumePDFSection } from "./common";
export const ResumePDFCustom = ({
  heading,
  custom,
  themeColor,
  showBulletPoints,
}) => {
  const { descriptions } = custom;

  return (
    <ResumePDFSection themeColor={themeColor} heading={heading}>
      <View style={{ ...styles.flexCol }}>
        <ResumePDFBulletList
          items={descriptions}
          showBulletPoints={showBulletPoints}
        />
      </View>
    </ResumePDFSection>
  );
};
