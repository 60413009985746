import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { pathAdmin, routes } from "../../screens/PathRouting";
import CvAppliedPost from "./CvAppliedPost";
import { LableItem } from "../components/Tag/IconCircle";
import { Select } from "antd";
import BreadcrumbAdmin from "../components/Tag/Breadcrumb";
import AvatarItem from "../components/Avatar/AvatarItem";
import queryString from "query-string";
import AllCvComponent from "./AllCvComponent";
import { LeftOutlined } from "@ant-design/icons";
import AdminService from "../../services/AdminService";
import { getId, getImageUrl, getLocation, getSalary } from "../../utils";
import dayjs from "dayjs";
import UserService from "../../services/UserService";
import ShowCandidateCv from "../../components/Modals/ShowCandidateCv";
import { marked } from "marked";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";

function PostCandidate() {
  const { theme } = useTheme();
  const history = useHistory();
  const location = useLocation();

  const [postDetail, setPostDetail] = useState();
  const [recruiter, setRecuiter] = useState();
  const [listCv, setListCv] = useState();

  const [active, setActive] = useState(false);
  const [cvShow, setCvShow] = useState();

  const id = getId(location.pathname);

  useEffect(() => {
    if (!postDetail) {
      getPostDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postDetail]);

  const getPostDetail = async () => {
    const response = await AdminService.getJdById(id);
    if (!response?.data?.data?.jd) {
      history.push(routes.notFound404.path);
      return;
    }

    const data = response.data.data.jd;
    const dataRecruiter = response.data.data.recruiter[0];

    let listImage = [data.companyAvatar, dataRecruiter.avatarImg[0]];
    listImage = await UserService.getImageByName(listImage);
    listImage = listImage.data.data;

    let listCvApplied = [];
    if (data.cvApplyList && data.cvApplyList.length > 0) {
      const cvApplied = data.cvApplyList.map((item) => item.cvImage);
      listCvApplied = await UserService.getImageByName(cvApplied);
      listCvApplied = data.cvApplyList.map((item) => ({
        ...item,
        image: getImageUrl(listCvApplied.data.data, item.cvImage),
      }));
    }
    setListCv(listCvApplied);
    setPostDetail({
      ...data,
      companyAvatar: getImageUrl(listImage, data.companyAvatar),
    });
    setRecuiter({
      ...dataRecruiter,
      avatarImg: getImageUrl(listImage, dataRecruiter.avatarImg[0]),
    });
  };

  const options = [
    {
      value: "ACTIVE",
      label: (
        <div className="flex flex-row justify-start items-center space-x-2">
          <div className="w-[8px] h-[8px] rounded-[50%] bg-[#268D61]"></div>
          <p>Active</p>
        </div>
      ),
    },
    {
      value: "INACTIVE",
      label: (
        <div className="flex flex-row justify-start items-center space-x-2">
          <div className="w-[8px] h-[8px] rounded-[50%] bg-[#E60019]"></div>
          <p>Inactive</p>
        </div>
      ),
    },
    {
      value: "DELETE",
      label: (
        <div className="flex flex-row justify-start items-center space-x-2">
          <div className="w-[8px] h-[8px] rounded-[50%] bg-[#ebe84f]"></div>
          <p>Delete</p>
        </div>
      ),
    },
  ];

  const isShowAll = useCallback(() => {
    const search = queryString.parse(location.search);
    return search.show === "all";
  }, [location]);

  return (
    <div
      className={cx(
        "mx-auto h-auto lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      {postDetail && (
        <div className="px-4">
          <BreadcrumbAdmin
            data={[
              {
                name: "Quản lý bài tuyển dụng",
                path: pathAdmin.adminPostManagement.path,
              },
              {
                name: postDetail?.jobName,
              },
            ]}
          />

          <div className="grid grid-cols-1 md:grid-cols-4 mt-[40px]">
            <div className="col-span-3 sm:flex sm:justify-between sm:items-start">
              <div
                className={cx(
                  "flex flex-col justify-start",
                  theme?.background?.text
                )}
              >
                <p className="text-[32px] font-[600]">Bài đăng bởi</p>
                <p className="mb-4">
                  {dayjs(new Date(postDetail?.createAt)).format(
                    "HH:MM DD/MM/YYYY"
                  )}
                </p>
                <AvatarItem
                  disabled
                  data={{
                    id: recruiter?.id,
                    avatar: recruiter?.avatarImg,
                    name: recruiter?.enterprise,
                    joinDate: dayjs(new Date(recruiter?.createAt)).format(
                      "DD/MM/YYYY"
                    ),
                  }}
                />
              </div>
            </div>
            <div></div>
          </div>

          {!isShowAll() ? (
            <div className="grid grid-cols-1 md:grid-cols-4 md:gap-4 mt-4">
              <div className="col-span-3">
                <div className="p-[20px] bg-white rounded-[22px]">
                  <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
                    <div className="col-span-1 md:col-span-5">
                      <div className="w-full grid grid-cols-6 gap-4">
                        <div className="col-span-2 sm:col-span-1">
                          <div className="w-[80px] h-[80px] flex justify-center items-center">
                            <img
                              src={postDetail?.companyAvatar}
                              alt=""
                              className="max-w-full max-h-full object-cover"
                            />
                          </div>
                        </div>

                        <div className="col-span-4 sm:col-span-5">
                          <p className="text-[18px] font-[600] text-black line-clamp-1">
                            {postDetail?.jobName}
                          </p>
                          <p className="text-[16px] line-clamp-1">
                            {postDetail?.companyName}
                          </p>
                          <div className="flex justify-start items-center">
                            <img
                              src="/asserts/location.png"
                              alt=""
                              className="w-[14px] h-[14px]"
                            />
                            <p className="ml-2">
                              {getLocation(postDetail?.location)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1 flex justify-end md:justify-center items-center">
                      <Select
                        placeholder=""
                        className="rounded-[12px] min-w-[80px]"
                        defaultValue={postDetail?.status}
                        options={options}
                      />
                    </div>
                  </div>

                  <hr className="my-6" />

                  <div className="">
                    <p className="text-black font-[700] text-[20px]">
                      Chi tiết công việc
                    </p>
                    <div className="mt-4">
                      <LableItem
                        label="Mức lương"
                        description={getSalary(
                          postDetail?.minSalary,
                          postDetail?.maxSalary,
                          postDetail?.salary,
                          "vnd"
                        )}
                      />
                      <LableItem
                        label="Trình độ"
                        description={
                          postDetail?.levels &&
                          postDetail?.levels.length > 0 &&
                          postDetail.levels[0]
                        }
                      />
                      <LableItem
                        label="Số lượng tuyển dụng cho vị trí"
                        description={postDetail?.positions}
                      />
                      <LableItem
                        label="Dự kiến ngày mở tuyển dụng"
                        description={dayjs(
                          new Date(postDetail?.startReceiveCV)
                        ).format("DD/MM/YYYY")}
                      />
                      <LableItem
                        label="Địa điểm làm việc"
                        description={"- " + postDetail?.location}
                      />
                    </div>
                  </div>

                  <div className="mt-[40px]">
                    <p className="text-black font-[700] text-[20px] mb-2">
                      Mô tả công việc
                    </p>
                    {postDetail?.jobDescription &&
                      postDetail.jobDescription.map((item, index) => {
                        if (item && index === 0) {
                          return (
                            <>
                              <p className="text-[18px] font-[600] text-black">
                                {index + 1}. Nhiệm vụ
                              </p>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: marked(item),
                                }}
                              />
                            </>
                          );
                        }
                        if (item && index === 1) {
                          return (
                            <>
                              <p className="text-[18px] font-[600] text-black">
                                {index + 1}. Yêu cầu công việc
                              </p>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: marked(item),
                                }}
                              />
                            </>
                          );
                        }
                        if (item && index === 2) {
                          return (
                            <>
                              <p className="text-[18px] font-[600] text-black">
                                {index + 1}. Quyền lợi
                              </p>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: marked(item),
                                }}
                              />
                            </>
                          );
                        }
                        return null;
                      })}
                  </div>

                  <hr className="my-6" />

                  <div className="mt-[40px]">
                    <p className="text-black font-[700] text-[20px]">
                      Thiết lập ứng tuyển
                    </p>
                    <p>
                      Các thông tin sau sẽ không được chia sẻ trên tin tuyển của
                      bạn.
                    </p>

                    <LableItem
                      label="Cách thức ứng tuyển"
                      description="Qua Email"
                    />

                    <LableItem
                      label="Yêu cầu CV"
                      description={postDetail?.cvRequire ? "Có" : "Không"}
                    />

                    <LableItem
                      label="Gửi thông tin cập nhật tới"
                      description={
                        postDetail?.dailyUpdate &&
                        postDetail.dailyUpdate.length > 0
                          ? postDetail.dailyUpdate.join(", ")
                          : "Chưa cập nhật"
                      }
                    />

                    <LableItem
                      label="Cho phép nhắn tin"
                      description={
                        "Có, ứng viên có thể gửi tin nhắn về tin tuyển dụng"
                      }
                    />

                    <LableItem
                      label="Thời hạn ứng tuyển"
                      description={dayjs(
                        new Date(postDetail?.endReceiveCV)
                      ).format("DD/MM/YYYY")}
                    />

                    <LableItem
                      label="Câu hỏi tùy chỉnh"
                      description={
                        postDetail?.questions && postDetail.questions.length > 0
                          ? postDetail.questions[0]
                          : "Chưa cập nhật"
                      }
                    />
                  </div>

                  <div className="mt-[40px]">
                    <p className="text-black font-[700] text-[20px]">
                      Thiết lập Công việc
                    </p>
                    <LableItem
                      label="Quốc gia và ngôn ngữ"
                      description={
                        postDetail?.languages && postDetail.languages.length > 0
                          ? postDetail.languages.join(", ")
                          : "Chưa cập nhật"
                      }
                    />
                    <LableItem
                      label="Dự kiến tuyển dụng trong"
                      description="3 đến 7 ngày"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-1 mt-4 md:mt-0">
                <CvAppliedPost
                  data={listCv}
                  onChangeCv={(value) => {
                    setCvShow(value);
                    setActive(!active);
                  }}
                />
              </div>
            </div>
          ) : (
            <div>
              <div className="my-[40px] w-[110px]">
                <div
                  className={cx(
                    "border px-4 py-2 rounded-[12px] text-[14px] flex justify-center items-center cursor-pointer",
                    theme?.background?.border,
                    theme?.background?.text
                  )}
                  onClick={() => history.push(location.pathname)}
                >
                  <LeftOutlined style={{ font: "14px", marginRight: "4px" }} />
                  Quay lại
                </div>
              </div>
              <AllCvComponent
                data={listCv}
                jdId={id}
                jdName={postDetail?.jobName}
                onChangeCv={(value) => {
                  setCvShow(value);
                  setActive(!active);
                }}
              />
            </div>
          )}
        </div>
      )}
      <ShowCandidateCv
        active={active}
        onChangeActive={() => {
          setCvShow();
          setActive(!active);
        }}
        data={cvShow}
      />
    </div>
  );
}

export default PostCandidate;
