import React, { useState } from "react";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import { useAuth } from "../Auth/useAuth";
import Dropbox from "../components/Dropbox";
import AdminService from "../../services/AdminService";
import CardCVView from "../components/Card/CardCVView";
import FormParsing from "../components/Dropbox/FormParsing";
import UserService from "../../services/UserService";
import { getImageUrl, renderDefaultItem } from "../../utils";

function CVParsing() {
  const { theme } = useTheme();
  const { admin } = useAuth();

  const [file, setFile] = useState();
  const [dataParsing, setDataParsing] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleParsingCv = async () => {
    if (file && !isLoading) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        setIsLoading(true);
        const response = await AdminService.parsingCv(formData);
        let data = response.data.data;
        data = await formatDataPasing(data);
        setDataParsing(data);
      } catch (error) {
        setIsLoading(false);
        console.error(new Error(error));
      }
    }
  };

  const formatDataPasing = async (data) => {
    const result = data;
    const image = await UserService.getImageURLByName([result.cv_url]);
    result["pdfLink"] = getImageUrl(image.data.data, result.cv_url);
    setIsLoading(false);
    if (typeof result.entities_langchain === "object") {
      return result;
    }
    return {
      ...data,
      entities_langchain: formatData(result.entities_langchain),
    };
  };

  const formatData = (data) => {
    const dataResult = {
      name: "",
      jobPosition: "",
      dateOfBirth: "",
      email: "",
      phoneNumber: "",
      summary: "",
      address: "",
      skills: [],
      certifications: [],
      education: [],
      workExperience: [],
    };

    const result = data
      .split("\n")
      .filter((c) => !(c === "" || c === "- ''"))
      .map((item) => {
        const k = item.split(":");
        return { [k[0].trim()]: k[1].trim() };
      });

    const key2 = Object.keys(dataResult);
    for (const item of result) {
      const key = Object.keys(item)[0];
      if (key2.find((c) => key === c)) dataResult[key] = item[key];
    }

    dataResult["skills"] = getListValue("skills", result);
    dataResult["certifications"] = getListValue("certifications", result);

    return dataResult;
  };

  const getListValue = (key, data) => {
    return data.reduce(
      (result, item) => {
        if (result.isSearching) {
          if ("- name" in item) {
            result[key].push({ name: item["- name"] });
          } else {
            result.isSearching = false;
          }
        } else if (key in item) {
          result.isSearching = true;
        }
        return result;
      },
      { [key]: [], isSearching: false }
    )[key];
  };

  return (
    <div
      className={cx(
        "mx-auto min-h-[80vh] lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <div className={cx("px-4 mb-[120px]", theme?.background?.text)}>
        <div className="my-[60px]">
          <p className="text-[32px] font-[600] leading-none">Dashboad</p>
          <p className="text-[26px]">{admin?.nickname}</p>
        </div>
        <p className="font-[600] text-[20px]">Parsing CV</p>
        <Dropbox
          id="parsing-cv"
          onChange={(file) => {
            setFile(file);
          }}
        />
        <div className={cx("grid grid-cols-1 md:grid-cols-2 gap-4")}>
          <div className="col-span-1">
            <div
              className="text-center text-white cursor-pointer py-1 bg-[#333] w-[120px] my-4 rounded-md"
              onClick={handleParsingCv}
              disabled={isLoading}
            >
              Parsing CV
            </div>
            {isLoading ? (
              <div className="w-full h-[460px] xl:h-[70vh] border bg-[rgba(0,0,0,0.4)] border-[#ccc] flex justify-center items-center">
                <div className="lds-roller">
                  {renderDefaultItem(8).map((c) => (
                    <div key={"icon-" + c} />
                  ))}
                </div>
              </div>
            ) : dataParsing?.pdfLink ? (
              <CardCVView
                data={{
                  image: dataParsing.pdfLink,
                  name: "CV - Parsing CV",
                }}
              />
            ) : (
              <div className="w-full h-[460px] xl:h-[70vh] border bg-[rgba(0,0,0,0.2)] border-[#ccc] flex justify-center items-center"></div>
            )}
          </div>
          <div className="col-span-1">
            <FormParsing
              dataParsing={dataParsing?.entities_langchain}
              cvFile={dataParsing?.cv_url}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CVParsing;
