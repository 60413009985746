import { Image, Modal } from "antd";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";

export const SuccessUpdate = (props) => {
  const [modalWidth, setModalWidth] = useState("w-[43%]");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setModalWidth("w-full");
      } else {
        setModalWidth("w-[43%]");
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Modal
      open={props.open}
      onCancel={props.onCancel}
      className={modalWidth}
      footer={[
        <div className="flex justify-end items-center ">
          <div
            className="px-4 py-2 bg-[#0055FF] text-white rounded-[20px] cursor-pointer"
            onClick={props.onCancel}
          >
            Tôi đã hiểu
          </div>
        </div>,
      ]}
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <p className="text-black font-[600] text-[24px]">Cập nhật thành công</p>
        <Image
          src={"/asserts/Preview.png"}
          style={{ height: "12vh" }}
          preview={false}
        />
      </Stack>
      <ul>
        <li>
          <hUserName>Thông tin doanh nghiệp thành công</hUserName>
          <br />
          <p className="my-1">
            Thông tin doanh nghiệp sẽ được phê duyệt sau vài giờ, hoặc có thể
            lâu hơn
          </p>
        </li>
        <li>
          <hUserName>
            Chúng tôi sẽ gửi email thông báo khi thông tin doanh nghiệp của bạn
            được đăng
          </hUserName>
          <br />
          <p className="my-1">
            Thêm manpowerbank@gmail.com vào danh sách liên hệ để email không bị
            nhầm là spam.
          </p>
        </li>
      </ul>
    </Modal>
  );
};
