import React, { useEffect, useState } from "react";
import { getId } from "../../utils";
import { useLocation, useHistory } from "react-router-dom";
import AdminService from "../../services/AdminService";
import BreadcrumbAdmin from "../components/Tag/Breadcrumb";
import { pathAdmin } from "../../screens/PathRouting";
import dayjs from "dayjs";
import { EditOutlined } from "@ant-design/icons";
import { useAuth } from "../Auth/useAuth";
import { ADMIN_ROLE } from "../../utils/constants";
import { notification } from "antd";
import ScrollTop from "../../components/ScrollTop";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";

function NewsDetail() {
  const { theme } = useTheme();
  const history = useHistory();
  const location = useLocation();
  const { admin } = useAuth();
  const slug = getId(location.pathname);
  const [isNewsUpdate, setIsNewsUpdate] = useState(false);
  const [oldStatus, setOldStatus] = useState("");

  const [newsDetail, setNewsDetail] = useState();

  notification.config({ top: 80, placement: "topRight" });

  useEffect(() => {
    if (!newsDetail) {
      getNewsDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsDetail]);

  const getNewsDetail = async () => {
    await AdminService.getNewsDetail(slug)
      .then((response) => {
        const data = response.data.data;
        setOldStatus(data.status);

        if (data.newlyUpdateData.length > 0) {
          setNewsDetail(data.newlyUpdateData[0]);
          setIsNewsUpdate(true);
        } else {
          setNewsDetail(data);
        }
      })
      .catch((error) => {});
  };

  const handleChangeActive = async (action) => {
    const data = {
      status: action.toUpperCase(),
      role: admin.sub_role,
      slug: slug,
    };
    if (isNewsUpdate) {
      await AdminService.changeValidateNews(data).then(() => {
        notifications();
      });
    } else {
      await AdminService.changeStatusNews(data).then(() => {
        notifications();
      });
    }
  };

  const notifications = () => {
    notification.success({
      message: "Thành công",
      description: "Chỉnh sửa trạng thái thành công!",
      duration: 4,
      style: { top: 50 },
    });
    history.push(pathAdmin.adminManagerNews.path);
  };
  return (
    <div
      className={cx(
        "mx-auto min-h-[80vh] lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <ScrollTop />
      <div className="px-4">
        <BreadcrumbAdmin
          data={[
            {
              name: "Quản lý bài viết",
              path: pathAdmin.adminManagerNews.path,
            },
            {
              name: "Bài viết",
            },
          ]}
        />

        {newsDetail && (
          <div className="full">
            <div
              className={cx("grid grid-cols-5 gap-4", theme?.background?.text)}
            >
              <div className="col-span-4">
                <p className="text-[32px] font-[600]">{newsDetail?.title}</p>

                <p className="text-[16px] mt-4">{newsDetail?.owner[0].name}</p>
                <p className="text-gray text-[16px] mb-4">
                  {dayjs(newsDetail?.createAt).format("HH:MM DD/MM/YYYY")} |{" "}
                  <span className="font-[600]">{newsDetail?.tag[0]}</span>
                </p>
                {newsDetail?.content && (
                  <div
                    dangerouslySetInnerHTML={{ __html: newsDetail.content }}
                  />
                )}
              </div>
              <div>
                <p className="">
                  Trạng thái:{" "}
                  <span className="font-[600]">
                    {oldStatus === "ACTIVE"
                      ? "Đã phê duyệt"
                      : oldStatus === "INPROGRESS"
                      ? "Chưa phê duyệt"
                      : "Từ chối phê duyệt"}
                  </span>
                </p>

                {isNewsUpdate && (
                  <div className="mt-4">
                    <p className="font-[600]">Đã chỉnh sửa: </p>
                    <p>
                      Thời gian:{" "}
                      {dayjs(newsDetail?.updateAt).format("HH:MM DD/MM/YYYY")}{" "}
                    </p>
                  </div>
                )}

                {admin && admin.sub_role === ADMIN_ROLE.POST_MANAGER && (
                  <div
                    className="px-4 py-2 bg-[#1495F3] mt-4 rounded-[15px] text-center text-white cursor-pointer"
                    onClick={() =>
                      history.push(
                        pathAdmin.adminNewsUpdate.parentPath + `/${slug}`
                      )
                    }
                  >
                    <EditOutlined /> Chỉnh sửa
                  </div>
                )}

                {admin && admin.sub_role === ADMIN_ROLE.SUPPER_ADMIN && (
                  <>
                    {oldStatus === "INPROGRESS" && (
                      <>
                        <div
                          className="px-4 py-2 bg-[#1495F3] mt-4 rounded-[15px] text-center text-white cursor-pointer"
                          onClick={() => handleChangeActive("active")}
                        >
                          Phê duyệt
                        </div>
                        <div
                          className="px-4 py-2 bg-red-500 mt-1 rounded-[15px] text-center text-white cursor-pointer"
                          onClick={() => handleChangeActive("reject")}
                        >
                          Từ chối
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default NewsDetail;
