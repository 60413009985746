import moment from "moment";
import Avatar from "../admin/components/Avatar/Avatar";

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function formatVND(amount) {
  return new Intl.NumberFormat("vi-VN").format(amount);
}

export const converSalary = (salary) => {
  const million = 1000000;
  return salary / million;
};

export const getSalary = (minValue, maxValue, salary, type) => {
  const min = converSalary(minValue);
  const max = converSalary(maxValue);

  if (!minValue && !maxValue && !salary) {
    return "Lương thỏa thuận";
  }

  if (salary) {
    return type !== "vnd"
      ? converSalary(salary) + " triệu"
      : formatVND(salary) + "VND";
  }
  return type !== "vnd"
    ? `${min} - ${max} triệu`
    : `${formatVND(minValue)} - ${formatVND(maxValue)} VND`;
};

export const getLocation = (location, option) => {
  if (!location) {
    return "";
  }
  const cutLocation = location.split(",");
  let result = location;

  if (option) {
    result = cutLocation[cutLocation.length - 1];
  } else if (cutLocation.length > 2) {
    result = `${
      (cutLocation[cutLocation.length - 2] &&
        cutLocation[cutLocation.length - 2] + ", ") ||
      ""
    }${cutLocation[cutLocation.length - 1]}`;
  }

  return result;
};

export const getLastPrexPhone = (phone) => {
  if (!phone) return "";
  return "*******" + phone.slice(-3);
};

export const getTimeCloseJob = (end) => {
  const dateEnd = moment(new Date(end)).format("YYYY-MM-DD");
  const now = moment().format("YYYY-MM-DD");

  const endDate = moment(dateEnd);
  const nowDate = moment(now);
  return endDate.diff(nowDate, "days");
};

export const getId = (pathname, option = 1) => {
  const cutString = pathname.split("/");
  return cutString[cutString.length - option];
};

export const getRootPath = (pathname, option = 1) => {
  const cutString = pathname.split("/");
  return cutString[option];
};

export const renderAvatarList = (data = [], limit = 0) => {
  const avatarList = [];
  if (data.length > 0) {
    const number = data.length < limit ? data.length : limit;
    for (let i = 0; i < number; i++) {
      const className = `translate(-${i * 6}px, 0)`;
      if (i < limit - 1) {
        avatarList.push(
          <Avatar
            avatar={data[i] || "/asserts/avatar.png"}
            style={{ transform: `${className}` }}
          />
        );
      } else {
        avatarList.push(
          <Avatar isMore style={{ transform: `${className}` }} />
        );
      }
    }
  }
  return avatarList;
};

export const clearEmptyItem = (obj, keyword = "") => {
  const keys = Object.keys(obj);
  for (const key of keys) {
    if (!obj[key] || obj[key] === keyword) {
      delete obj[key];
    }
  }
  return obj;
};

export function firstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getImageUrl = (data, originalUrl) => {
  const result = data.find((item) => item.fileName === originalUrl);
  return result ? result.fileUrl : "";
};

export const renderDefaultItem = (limit) => {
  const items = [];
  for (let i = 0; i < limit; i++) {
    items.push(i);
  }
  return items;
};

export function dataURLtoFile(dataUrl, fileName) {
  const arr = dataUrl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
}

export const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber.startsWith("0")) {
    return "+84" + phoneNumber.slice(1);
  }
  return phoneNumber;
};

export const lastRedirectUrl = (url) => {
  if (!url) return "";
  const listUrl = url.split("&");

  if (listUrl.length - 1 < 1) return url;

  const lastRedirect = listUrl[listUrl.length - 1];

  const continueUrl = listUrl.filter((item) => item !== lastRedirect);

  if (continueUrl.length - 1 > 0) {
    return lastRedirect + `?redirect=${continueUrl.join("&")}`;
  }
  return lastRedirect + `?redirect=${continueUrl[0]}`;
};

export const downloadFile = ({ url, filename }) => {
  if (url) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      });
  }
};

export const scrollSmooth = (id) => {
  const element = document.getElementById(id);
  element.scrollIntoView({ behavior: "smooth" });
};

export const getRandomListItem = (data = [], number = 5) => {
  const result = [];
  if (data.length < number) return data;
  while (result.length < number) {
    const index = Math.floor(Math.random() * data.length);
    if (!result.find((c) => c.id === data[index].id)) result.push(data[index]);
  }
  return result;
};
