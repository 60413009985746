import React, { useEffect, useState } from "react";
import { Bt } from "../../components/BtForm";
import { InfoCircleOutlined, RedoOutlined } from "@ant-design/icons";
import { ChangePassword } from "./ChangePassword";
import { ModalSuccess } from "./ModalSuccess";
import Input from "../../components/Input";
import UserService from "../../services/UserService";
import { resendOTP, otp as otpEndPoint } from "../../services/PORT";
import { useHistory, useLocation } from "react-router-dom";
import { debounce } from "../../utils";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";

export const ForgetPassword = (props) => {
  const { theme } = useTheme();
  const [otp, setOtp] = useState("");
  const [countSendOtp, setCountSendOtp] = useState(0);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [screen, setScreen] = useState(1);
  const [activeModal, setActiveModal] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setScreen(location.state.screen || 1);
  }, [location]);

  const reSendOtp = debounce(() => {
    if (isEmailError()) return;
    const data = {
      email: email,
      role: location.state.role || "",
    };
    UserService.sendData(data, resendOTP)
      .then((res) => {
        setCountSendOtp(countSendOtp + 1);
      })
      .catch((error) => {
        if (error.response.data.message === "user_not_found") {
          setEmailError("Email không tồn tại!");
        }
      });
  });

  const onClickSendOtp = debounce(() => {
    if (isEmailError()) return;
    setOtpError(false);
    if (email) {
      const dataOtp = {
        email: email,
        otp: Number(otp),
        role: location.state.role || "",
      };
      UserService.createUser(dataOtp, otpEndPoint)
        .then((res) => {
          history.replace(location.pathname, {
            ...location.state,
            email: email,
            screen: 2,
          });
        })
        .catch((error) => {
          setOtpError(true);
        });
    }
  });

  const isEmailError = () => {
    if (!email) {
      setEmailError("Vui lòng nhập email của bạn");
      return true;
    }
    if (
      // eslint-disable-next-line no-useless-escape
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setEmailError("Email không đúng định dạng");
      return true;
    }
    setEmailError("");
    return false;
  };

  return (
    <div
      className={cx(
        "w-full h-[90vh] flex justify-center items-center px-[10px] pt-12 lg:mx-auto",
        theme?.login?.background
      )}
    >
      {screen === 1 ? (
        <div className="w-full md:w-[450px] bg-white rounded-[12px] p-3 md:p-5">
          <div>
            <p className="text-[30px] text-black font-[700] mb-[10px]">
              Quên mật khẩu
            </p>
            <p className="text-[12px] ">
              Nhập địa chỉ Email hoặc SĐT bạn sử dụng để đăng ký tài khoản.
              Chúng tôi sẽ gửi một mã OTP xác thực.
            </p>
          </div>

          <div className="w-full mt-[20px]">
            <label className="block mb-[10px]">
              Nhập địa chỉ Email hoặc SĐT đăng nhập
            </label>
            <div className="w-full flex">
              <Input
                placeholder="Nhập Email hoặc SĐT bạn đăng ký"
                name="email"
                value={email}
                onChangeInput={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <Bt
                title={countSendOtp > 0 ? "Gửi lại mã" : "Gửi mã"}
                icon={<RedoOutlined />}
                className="text-[12px] bg-[#1495F3] px-2"
                onClick={reSendOtp}
              />
            </div>
          </div>

          <div className="w-full mt-[20px]">
            <label className="block mb-[10px]">Nhập mã OTP</label>
            <Input
              name="otp-code"
              placeholder="Nhập mã gửi tới Email hoặc SĐT"
              onChangeInput={(e) => setOtp(e.target.value)}
            />
          </div>

          {otpError && (
            <div className="flex justify-start items-center mt-[20px]">
              <InfoCircleOutlined className="text-[#E60019] w-[14px] h-[14px]" />
              <p className="text-black ml-[10px] ">Mã OTP không chính xác.</p>
            </div>
          )}

          {emailError && (
            <div className="flex justify-start items-center mt-[20px]">
              <InfoCircleOutlined className="text-[#E60019] w-[14px] h-[14px]" />
              <p className="text-black ml-[10px] ">{emailError}</p>
            </div>
          )}

          <div
            className="w-full mt-[20px] px-2 py-3 rounded-[12px] bg-[#1495F3] text-white text-center cursor-pointer"
            onClick={onClickSendOtp}
          >
            Gửi
          </div>
          <p className="mt-3 text-[12px] text-center">
            Nếu bạn vẫn cần trợ giúp.{" "}
            <span className="text-[#1495F3]">LDO</span> sẽ trợ giúp bạn
            tận tình.
          </p>
        </div>
      ) : (
        <ChangePassword onClickSend={() => setActiveModal(true)} />
      )}
      <ModalSuccess
        active={activeModal}
        onChangeActive={() => {
          history.push("/");
          setActiveModal(!activeModal);
        }}
      />
    </div>
  );
};

export const styleBt = {
  borderRadius: 10,
  background: "var(--blue-01, #05F)",
};

export const styleIcon = {
  color: "#05F",
};
