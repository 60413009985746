import { DeleteOutlined } from "@ant-design/icons";
import React from "react";
import Input from "../../components/Input";
import { debounce } from "../../utils";

function ExperienceComponent(props) {
  const { data, onChangeRemove, onChangeValue } = props;
  return (
    <div className="w-full min-h-[40px] border border-x-0 border-t-[#0055FF] border-b-0 py-[10px]">
      <div className="w-full grid grid-cols-1 md:grid-cols-3">
        <div className="col-span-1">
          <div className="grid grid-cols-3">
            <div className="col-span-3 pr-4">
              <Input
                name="company"
                value={data?.company || ""}
                onChangeInput={debounce(onChangeValue)}
                placeholder="Tên công ty"
                className="!border-b-[#ccc] text-[18px] font-[600] text-black"
              />
              <Input
                name="jobName"
                value={data?.jobName || ""}
                onChangeInput={debounce(onChangeValue)}
                placeholder="Vị trí đảm nhiệm"
                className="!border-b-[#ccc]"
              />
              <div className="w-[125px] flex justify-start items-center">
                <Input
                  name="time_start"
                  value={data?.time_start || ""}
                  type="date"
                  pattern="\d{2}-\d{2}\d{4}"
                  className="border-b-[#616161]"
                  onChangeInput={debounce(onChangeValue)}
                />
                <span className="mx-2">-</span>
                <Input
                  name="time_end"
                  value={data?.time_end || ""}
                  type="date"
                  pattern="\d{2}-\d{2}\d{4}"
                  className="border-b-[#616161]"
                  onChangeInput={debounce(onChangeValue)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-1 mt-3 md:mt-0 md:col-span-2">
          <div className="grid grid-cols-7">
            <div className="col-span-6">
              <textarea
                name="detail"
                cols="30"
                rows="4"
                defaultValue={data?.detail || ""}
                className="w-full border-[1px] border-[#ccc] focus:outline-none p-2 resize-x-none"
                onChange={debounce(onChangeValue)}
              />
            </div>
            <div className="col-span-1 flex justify-end items-center">
              <div
                className="w-[50px] h-[50px] rounded-[18px] bg-[#E60019] flex justify-center items-center cursor-pointer"
                onClick={() => onChangeRemove(data.id)}
              >
                <DeleteOutlined className="text-white" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExperienceComponent;
