import React, { useEffect, useState } from "react";
import StatusContact from "./StatusContact";
import UserService from "../../services/UserService";
import { Pagination, notification } from "antd";
import { routes } from "../PathRouting";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { getLocation, getSalary, getTimeCloseJob } from "../../utils";
import JDTag from "./JDTag";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import { changePagination } from "../../lib/theme/config";

function JobInterested({ profile, params, token }) {
  const { theme } = useTheme();
  const [jdList, setJdList] = useState();
  const [totalItem, setTotalItem] = useState(0);
  const history = useHistory();

  useEffect(() => {
    if (!jdList && profile) getListJd(params.page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jdList, profile]);

  const getListJd = async (page) => {
    const getSaveJD = await UserService.getInfoPersional(profile.id);

    const listJD = getSaveJD.data.data.listSavedJD;

    const params = queryString.stringify({
      offset: page || 0,
      limit: 10,
    });

    const getJD = await UserService.getAllJobDetail(params, { listJd: listJD });
    notification.config({ top: 80, placement: "topRight" });

    const data = getJD.data.data;
    const listJd = data.filter((item) => !item.hasOwnProperty("countTotal"));

    if (listJd.length > 0) {
      formatDataShow(listJd, setJdList);
    }
    setTotalItem(data.find((item) => item.countTotal)?.countTotal || 0);
  };

  const formatDataShow = (response, args) => {
    Promise.all(
      response.map(async (data) => {
        let avartar = "";
        const jd = data;
        if (jd?.companyAvatar) {
          avartar = await UserService.getImageByName([jd.companyAvatar]);
          avartar = avartar.data.data[0].fileUrl;
        }

        const recruiter = await UserService.getInfoRecruiter(jd.recruiterId);

        return {
          jd: {
            id: jd?.id,
            image: avartar,
            job: jd?.jobName,
            name_company: recruiter.data.data.enterprise,
            location: getLocation(jd?.location),
            salary: getSalary(jd?.minSalary, jd?.maxSalary, jd?.salary),
            jobType: jd?.jobTypes[0],
            experience:
              jd?.experience > 0
                ? jd?.experience + " năm kinh nghiệm"
                : "Không y/c kinh nghiệm",
            dueTime: getTimeCloseJob(jd?.endReceiveCV),
          },
        };
      })
    ).then((data) => {
      args(data);
    });
  };

  const handleChangePagination = (value) => {
    history.replace(
      routes.userSetting.path +
        queryString.stringify({ ...params, page: value })
    );
    getListJd(value);
  };

  const handleChangeSaveJd = (jdId) => {
    const data = {
      candidateId: profile.id,
      jdId: jdId,
    };
    UserService.updateSavedJd(data).then(() => {
      getListJd(params.page);
      notification.success({
        message: "Thành công",
        description: "Bỏ quan tâm thành công",
        duration: 4,
        style: { top: 50 },
      });
    });
  };

  return (
    <div
      className={cx(
        "w-full grid grid-cols-1 lg:grid-cols-8 gap-4",
        theme?.background?.text,
        token && "xl:grid-cols-1"
      )}
    >
      <div className="col-span-1 mt-4 block lg:hidden">
        <StatusContact profile={profile} />
      </div>
      <div className={cx("col-span-1 lg:col-span-6", token && "xl:col-span-1")}>
        {jdList && jdList.length > 0 ? (
          <div className="w-full min-h-[80px]">
            {jdList.map((jd) => (
              <JDTag
                isExtension
                data={jd.jd}
                handleChangeSaveJd={handleChangeSaveJd}
              />
            ))}
            <div className="w-full flex justify-center items-center">
              <Pagination
                defaultCurrent={params.page || 1}
                total={totalItem}
                onChange={handleChangePagination}
                itemRender={changePagination}
              />
            </div>
          </div>
        ) : (
          <div className="w-full mt-[20px]">
            Hiện tại không có công việc nào được quan tâm
          </div>
        )}
      </div>
      <div
        className={cx(
          "col-span-1 lg:col-span-2 hidden lg:block",
          token && "xl:hidden"
        )}
      >
        <StatusContact profile={profile} />
      </div>
    </div>
  );
}

export default JobInterested;
