import React, { useState } from "react";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { passwordChangeSchema } from "../../utils/validations";
import UserService from "../../services/UserService";
import { changePassword, resendOTP } from "../../services/PORT";
import { notification } from "antd";
import { SignUp3 } from "../SignUp/SignUp3";
import { otp as OTP } from "../../services/PORT";
import Input from "../../components/Input";
import { cx } from "../../lib/cx";

function ContactPersonal({ profile, addInfo, token }) {
  const { earnPoint, refarral } = addInfo;

  const [toggleChangePassword, setToggleChangePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOTP] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [api, contextHolder] = notification.useNotification({ top: 80 });;
  const [password, setPassword] = useState();

  const CANDIDATE = "Candidate";

  const passwordFormik = useFormik({
    initialValues: {
      password: "",
      rePassword: "",
    },
    validationSchema: passwordChangeSchema,
    onSubmit: (values) => {
      const dataSend = {
        email: profile.email,
        role: CANDIDATE,
      };
      UserService.sendData(dataSend, resendOTP)
        .then((res) => {
          setConfirm(!confirm);
          setPassword(values.password);
        })
        .catch((err) => {
          api.error({
            message: "Thất bại",
            description: "Gửi mã OTP thất bại!",
            style: { top: 50 },
          });
        });
    },
  });

  const onHandleChangeConfirm = () => {
    const dataOTP = {
      email: profile.email,
      otp: otp,
      role: CANDIDATE,
    };
    UserService.createUser(dataOTP, OTP)
      .then(async (res) => {
        setLoading(true);
        UserService.changePassword(changePassword, {
          email: profile.email,
          role: "Candidate",
          password: password,
        }).then(() => {
          setLoading(false);
          setConfirm(!confirm);
          setToggleChangePassword(!toggleChangePassword);
          api.success({
            message: "Thành công",
            description: "Thay đổi mật khẩu thành công!",
            style: { top: 50 },
          });
        });
      })
      .catch((err) => {
        api.error({
          message: "OTP không chính xác",
          placement: "topRight",
          style: { top: 44 },
        });
        console.log(err);
      });
  };

  const getErrorMessage = (field) => {
    return passwordFormik.errors[field] && passwordFormik.touched[field]
      ? passwordFormik.errors[field]
      : "";
  };

  return (
    <div className="w-full">
      {contextHolder}

      <div
        className={cx(
          "w-full bg-white min-h-[20px] rounded-tl-none rounded-lg p-[24px]",
          token && "xl:hidden"
        )}
      >
        <div className="flex justify-start items-center">
          <div className="w-[18px] h-[18px]">
            <img
              src="/icons/briefcase.png"
              alt="icon"
              className="object-cover"
            />
          </div>
          <span className="text-[18px] ml-2 font-[600]">Mã liên kết</span>
        </div>
        <hr className="mt-[10px]" />
        <div className="my-1">
          <p>
            Mã liên kết:{" "}
            <strong>{refarral ? refarral.code : "Chưa cập nhật"}</strong>
          </p>
          <p>
            Link liên kết:{" "}
            <strong>{refarral ? refarral.link : "Chưa cập nhật"}</strong>
          </p>
          <p>
            Điểm: <strong>{earnPoint ? earnPoint.point : 0}</strong>
          </p>
        </div>
      </div>

      <div className="w-full bg-white min-h-[20px] rounded-lg p-[24px] mt-[18px]">
        <div className="flex justify-start items-center">
          <div className="w-[18px] h-[18px]">
            <img
              src="/icons/briefcase.png"
              alt="icon"
              className="object-cover"
            />
          </div>
          <span className="text-[18px] ml-2 font-[600]">Kinh nghiệm</span>
        </div>
        <hr className="mt-[10px]" />
        {profile && profile.experiences && profile.experiences.length > 0 ? (
          profile.experiences.map((experience) => (
            <div className="w-full mt-[18px]">
              <p className="text-[16px] font-[600] text-black">
                {experience.company}
              </p>
              <p>{experience.jobName}</p>
              <p>
                {dayjs(new Date(experience.time_start)).format("DD/MM/YYYY")} -{" "}
                {dayjs(new Date(experience.time_end)).format("DD/MM/YYYY")}
              </p>
              <p className="text-[14px] text-black">{experience.detail}</p>
            </div>
          ))
        ) : (
          <p className="text-black text-[14px] mt-2 italic">
            Chưa cập nhật thông tin
          </p>
        )}
      </div>

      <div className="w-full bg-white min-h-[20px] rounded-lg p-[24px] mt-[18px]">
        <div className="flex justify-start items-center">
          <div className="w-[18px] h-[18px]">
            <img src="/icons/book1.png" alt="icon" className="object-cover" />
          </div>
          <span className="text-[18px] ml-2 font-[600]">Học vấn</span>
        </div>
        <hr className="mt-[10px]" />
        {profile && profile.educations && profile.educations.length > 0 ? (
          profile.educations.map((education) => (
            <div className="w-full mt-[18px]">
              <p className="text-[16px] font-[600] text-black">
                {education.schoolName}
              </p>
              <p>{education.department}</p>
              <p>
                {dayjs(new Date(education.time_start)).format("DD/MM/YYYY")} -{" "}
                {dayjs(new Date(education.time_end)).format("DD/MM/YYYY")}
              </p>
            </div>
          ))
        ) : (
          <p className="text-black text-[14px] mt-2 italic">
            Chưa cập nhật thông tin
          </p>
        )}
      </div>

      <div className="w-full bg-white min-h-[20px] rounded-lg p-[24px] mt-[18px]">
        <div className="flex justify-start items-center">
          <div className="w-[18px] h-[18px]">
            <img
              src="/icons/programmingarrow.png"
              alt="icon"
              className="object-cover"
            />
          </div>
          <span className="text-[18px] ml-2 font-[600]">Kĩ năng</span>
        </div>
        <hr className="mt-[10px]" />
        {profile && profile.skills && profile.skills.length > 0 ? (
          profile.skills.map((skill) => (
            <div className="w-full mt-[18px]">
              <p className="text-[14px] text-black">{skill}</p>
            </div>
          ))
        ) : (
          <p className="text-black text-[14px] mt-2 italic">
            Chưa cập nhật thông tin
          </p>
        )}
      </div>

      <div className="w-full bg-white min-h-[20px] rounded-lg p-[24px] mt-[18px]">
        <div className="flex justify-start items-center">
          <div className="w-[18px] h-[18px]">
            <img
              src="/icons/archivebook.png"
              alt="icon"
              className="object-cover"
            />
          </div>
          <span className="text-[18px] ml-2 font-[600]">Chứng chỉ</span>
        </div>
        <hr className="mt-[10px]" />
        {profile && profile.certificates && profile.certificates.length > 0 ? (
          profile.certificates.map((certificate) => (
            <div className="w-full mt-[18px]">
              <p className="text-[16px] font-[600] text-black">
                {certificate.name}
              </p>
              <p>
                {dayjs(new Date(certificate.time_graduate)).format(
                  "DD/MM/YYYY"
                )}
              </p>
            </div>
          ))
        ) : (
          <p className="text-black text-[14px] mt-2 italic">
            Chưa cập nhật thông tin
          </p>
        )}
      </div>

      <div className="w-full bg-white min-h-[20px] rounded-lg p-[24px] mt-[18px]">
        <div className="flex justify-start items-center">
          <div className="w-[18px] h-[18px]">
            <img
              src="/icons/archivebook.png"
              alt="icon"
              className="object-cover"
            />
          </div>
          <span className="text-[18px] ml-2 font-[600]">Thay đổi mật khẩu</span>
        </div>
        <hr className="mt-[10px]" />
        <div className="">
          <p
            className="text-blue-400 hover:underline cursor-pointer mt-2"
            onClick={() => setToggleChangePassword(!toggleChangePassword)}
          >
            + Thay đổi mật khẩu
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2">
            {toggleChangePassword && (
              <form onSubmit={passwordFormik.handleSubmit}>
                <div className="w-full mt-[20px]">
                  <label className="block mb-[10px]">Mật khẩu mới</label>
                  <Input
                    type="password"
                    name="password"
                    value={passwordFormik.values.password}
                    onChangeInput={passwordFormik.handleChange}
                    placeholder="Nhập mật khẩu của bạn"
                    error={getErrorMessage("password")}
                    isCheckPassword
                  />
                </div>

                <div className="w-full mt-[20px]">
                  <label className="block mb-[10px]">Nhập lại mật khẩu</label>
                  <Input
                    type="password"
                    name="rePassword"
                    value={passwordFormik.values.rePassword}
                    onChangeInput={passwordFormik.handleChange}
                    error={getErrorMessage("rePassword")}
                    placeholder="Nhập mật khẩu của bạn"
                    isCheckPassword
                  />
                </div>

                <div
                  className="w-full mt-[20px] px-2 py-3 rounded-[12px] bg-[#0055FF] text-white text-center cursor-pointer"
                  onClick={passwordFormik.handleSubmit}
                >
                  Gửi
                </div>
              </form>
            )}
          </div>
        </div>
      </div>

      <SignUp3
        loading={loading}
        open={confirm}
        data={{
          role: CANDIDATE,
          phoneNumber: profile?.phoneNumber,
          email: profile?.email,
        }}
        onCickConfirm={onHandleChangeConfirm}
        onCancel={() => {
          setConfirm(!confirm);
        }}
        onChange={(number) => setOTP(number)}
      />
    </div>
  );
}

export default ContactPersonal;
