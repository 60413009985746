import { Pagination } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import { CardJDByRecruiter } from "../../components/CardInfor";
import UserService from "../../services/UserService";
import { useLocation, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { listJDValidated } from "../../services/PORT";
import { routes } from "../PathRouting";
import { getId, getLocation, getSalary } from "../../utils";
import ScrollTop from "../../components/ScrollTop";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import { useLocalStorage } from "../../lib/useLocalStorage";

export const ListAllJD = (props) => {
  const { theme } = useTheme();
  const location = useLocation();
  const history = useHistory();

  const rcID = getId(location.pathname);
  const [recruiter, setRecruiter] = useState();
  const [listJD, setListJD] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalJD, setTotalJD] = useState(0);

  const [token] = useLocalStorage("token", null);

  useEffect(() => {
    if (rcID) {
      UserService.getListJDByRecruiterID(
        listJDValidated,
        rcID,
        true,
        currentPage - 1,
        21,
        "ACTIVE"
      )
        .then((res) => {
          const data = res.data.data.filter((item) => !item.count);
          const total = res.data.data.find((item) => item.count);
          setTotalJD(total.count || 0);
          formatData(data);
          setListJD(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, rcID]);

  const formatData = async (response) => {
    let recruiter = await UserService.getDescriptionRecuiter(
      response[0].recruiterId
    );
    recruiter = recruiter.data.data;
    const listImage = await UserService.getImageByName(recruiter.avatarImg);

    setRecruiter({
      ...recruiter,
      avatarImg: listImage?.data?.data[0]?.fileUrl,
    });

    const newData = response.map((data) => ({
      id: data.id,
      image: listImage.data.data[0].fileUrl,
      jobName: data.jobName,
      companyName: recruiter.enterprise,
      location: getLocation(data.location),
      salary: getSalary(data.minSalary, data.maxSalary, data.salary),
    }));

    setListJD(newData);
  };

  const handlePressDetail1 = (id) => {
    history.push(routes.workDetail.parentPath + "/" + id);
  };

  return (
    <div
      className={cx(
        "mx-auto lg:max-w-[1175px] pb-[120px]",
        theme?.background?.gradient
      )}
    >
      <ScrollTop />
      <div className="px-4">
        <div className="flex min-w-fit h-full rounded-[10px] bg-white p-[2%] mt-[60px]">
          <div className="w-[100px] h-[100px] rounded-[160px] p-[10px] bg-slate-200 flex justify-center items-center">
            <img
              src={recruiter?.avatarImg}
              className="max-w-full max-h-full object-cover"
              alt=""
            />
          </div>
          <div className="pl-10 w-[90%] h-full">
            <h1 className="text-[#8D9092] text-[13px] md:text-[20px] break-words">
              Công ty
            </h1>
            <h1
              className="text-[#1B2124] font-[600] text-[16px] md:text-[24px] break-words cursor-pointer hover:underline"
              onClick={() =>
                history.push(
                  routes.recruiterDetail.parentPath + `/${recruiter?.id}`
                )
              }
            >
              {recruiter?.enterprise}
            </h1>
            <h1 className="text-[#1B2124] text-[14px] break-words">
              {<EnvironmentOutlined />}{" "}
              {recruiter?.enterpriseAddress &&
                recruiter.enterpriseAddress.length > 0 &&
                recruiter.enterpriseAddress[0]}
            </h1>
          </div>
        </div>

        <div className="w-full flex items-center justify-between">
          <div>
            <h1
              className={cx(
                "text-[24px] md:text-[32px] font-[600] my-4",
                theme?.background?.text
              )}
            >
              Tuyển Dụng
            </h1>
            <h1 className="text-[#8D9092] text-[16px] my-1">
              Khám phá cơ hội việc làm mới nhất tại chúng tôi
            </h1>
          </div>
          <Pagination
            current={currentPage}
            pageSize={totalJD}
            onChange={(pageNumber) => {
              setCurrentPage(pageNumber);
            }}
          />
        </div>

        <div
          className={cx(
            "grid grid-cols-1 sm:grid-cols-2 gap-4",
            token ? "md:grid-cols-2 xl2:grid-cols-3" : "md:grid-cols-3"
          )}
        >
          {listJD &&
            listJD.length > 0 &&
            listJD.map((item, index) => {
              return (
                <CardJDByRecruiter
                  key={index}
                  avatar={item.image}
                  title={item.jobName}
                  label={item.companyName}
                  value={item.salary}
                  location={item.location}
                  onClick={() => handlePressDetail1(item.id)}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};
