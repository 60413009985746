import React from "react";
import HeaderSeo from "../components/Header/HeaderSeo";
import { Provider } from "react-redux";
import { storeBuilder } from "./redux/store";
import { getTemplateRender } from "./builder";
import { getNameTemplateCv } from "./redux/local-storage";
import { useHistory } from "react-router-dom";
import { routes } from "../screens/PathRouting";
import ChooseSaveCvNotLoginModal from "../components/Modals/ChooseSaveCvNotLoginModal";
import RegisterCandidateModal from "../components/Modals/RegisterCandidateModal";
import LoginCandidateModal from "../components/Modals/LoginCandidateModal";

function CvNomal() {
  const templateName = getNameTemplateCv();
  const history = useHistory();

  if (!templateName || !templateName?.name) {
    history.push(routes.notFound404.path);
    return;
  }

  return (
    <>
      <HeaderSeo
        title="Tạo CV và tìm công việc phù hợp với bản thân"
        description="Lao động online - Tạo cv và tìm công việc phù hợp với năng lực của bản thân"
      />
      <Provider store={storeBuilder}>
        <div className="w-full pb-[160px] px-4">
          {getTemplateRender(templateName.name)}
        </div>
        <ChooseSaveCvNotLoginModal />
        <RegisterCandidateModal />
        <LoginCandidateModal />
      </Provider>
    </>
  );
}

export default CvNomal;
