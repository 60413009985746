import React, { useEffect, useState } from "react";
import StatusContact from "./StatusContact";
import JDTag from "./JDTag";
import UserService from "../../services/UserService";
import { Pagination } from "antd";
import { routes } from "../PathRouting";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import {
  getImageUrl,
  getLocation,
  getSalary,
  getTimeCloseJob,
} from "../../utils";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import { changePagination } from "../../lib/theme/config";

function JobApplied({ profile, params, token }) {
  const { theme } = useTheme();
  const [jdList, setJdList] = useState();
  const [totalItem, setTotalItem] = useState(0);
  const history = useHistory();

  useEffect(() => {
    if (!jdList && profile) getListJd(params.page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jdList, profile]);

  const getListJd = (page) => {
    UserService.getAllJdApply({
      id: profile.id,
      offset: page || 0,
    }).then((response) => {
      formatDataShow(response.data.data, setJdList);
      setTotalItem(response.data.count);
    });
  };

  const formatDataShow = (response, args) => {
    Promise.all(
      response.map(async (data) => {
        let avartar = "";
        let cvImage = "";
        const jd = data.jd;
        const cv = data.cv_applied;
        if (jd?.companyAvatar) {
          avartar = await UserService.getImageByName([jd.companyAvatar]);
          avartar = getImageUrl(avartar.data.data, jd.companyAvatar);
        }

        if (cv?.cvImage) {
          cvImage = await UserService.getImageByName([cv.cvImage]);
          cvImage = getImageUrl(cvImage.data.data, cv.cvImage);
        }

        const recruiter = await UserService.getInfoRecruiter(jd.recruiterId);

        return {
          cv: {
            image: cvImage,
          },
          jd: {
            id: jd?.id,
            image: avartar,
            job: jd?.jobName,
            name_company: recruiter.data.data.enterprise,
            location: getLocation(jd?.location),
            salary: getSalary(jd?.minSalary, jd?.maxSalary, jd?.salary),
            jobType: jd?.jobTypes[0],
            experience:
              jd?.experience > 0
                ? jd?.experience + " năm kinh nghiệm"
                : "Không y/c kinh nghiệm",
            dueTime: getTimeCloseJob(jd?.endReceiveCV),
          },
        };
      })
    ).then((data) => {
      args(data);
    });
  };

  const handleChangePagination = (value) => {
    history.replace(
      routes.userSetting.path +
        queryString.stringify({ ...params, page: value })
    );
    getListJd(value);
  };

  return (
    <div
      className={cx(
        "w-full grid grid-cols-1 lg:grid-cols-8 gap-4",
        theme?.background?.text,
        token && "xl:grid-cols-1"
      )}
    >
      <div className="col-span-1 mt-4 block lg:hidden">
        <StatusContact profile={profile} />
      </div>
      <div className={cx("col-span-1 lg:col-span-6", token && "xl:col-span-1")}>
        {jdList && jdList.length > 0 ? (
          <div className="w-full min-h-[80px]">
            {jdList.map((jd) => (
              <JDTag data={jd.jd} cv={jd.cv} />
            ))}
            <div className="w-full flex justify-center items-center">
              <Pagination
                defaultCurrent={params.page || 1}
                total={totalItem}
                onChange={handleChangePagination}
                itemRender={changePagination}
              />
            </div>
          </div>
        ) : (
          <div className="w-full mt-[20px]">
            Hiện tại không có công việc nào được ứng tuyển
          </div>
        )}
      </div>
      <div
        className={cx(
          "col-span-1 lg:col-span-2 hidden lg:block",
          token && "xl:hidden"
        )}
      >
        <StatusContact profile={profile} />
      </div>
    </div>
  );
}

export default JobApplied;
