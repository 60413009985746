import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { resetBuilder, selectBuilder } from "../redux/slice/builder";
import {
  changeActions,
  changeFormToChange,
  changeValidateForm,
  resetSetting,
  selectSetting,
} from "../redux/slice/setting";
import html2pdf from "html2pdf.js";
import { routes } from "../../screens/PathRouting";
import UserService from "../../services/UserService";
import queryString from "query-string";
import { notification } from "antd";
import { lastRedirectUrl } from "../../utils";
import { AVATAR_REQUIED_IN_SAVE } from "../configs/data";

export const useConfigCvFile = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  notification.config({ top: 80, placement: "topRight" });

  const setting = useAppSelector(selectSetting);
  const builder = useAppSelector(selectBuilder);

  const handleChangeActive = () => {
    dispatch(changeFormToChange({ field: "active", value: "" }));
    dispatch(changeFormToChange({ field: "childActive", value: "" }));
  };

  const handleOpenReview = () => {
    dispatch(changeActions({ field: "openPreview" }));
  };

  const exportToPDF = () => {
    if (!missingName()) return;
    const { formElement, pdfOptions } = getElementForm(1.5, 1);
    html2pdf().from(formElement).set(pdfOptions).save();
  };

  const handleSaveCv = async () => {
    if (!missingName()) return;
    if (!loginOrSigin()) return;
    notificationMessage({
      message: "Vui lòng chờ trong giây lát!",
      description: "Đang trong quá trình xử lý",
    });

    const pdfImage = await getCvFileToUpload();
    const data = {
      cvName: setting.cvName,
      pdfImage: pdfImage,
      blockInfo: [
        {
          builder: builder,
          setting: setting,
        },
      ],
    };

    if (setting.isUpdate) {
      await UserService.updateCvCreator({
        ...data,
        prevPdfImage: setting.isUpdate ? setting.prevPdfImage : "",
      })
        .then(() => {
          changeSuccessNotification("Cập nhật");
        })
        .catch((error) => {
          notificationMessage({
            type: "error",
            message: "Thất bại",
            description: `Tạo cv thất bại!`,
          });
        });
    } else {
      const user = JSON.parse(localStorage.getItem("User"));
      await UserService.createCV({ ...data, candidateId: user?.id })
        .then(() => {
          changeSuccessNotification("Tạo");
        })
        .catch((error) => {
          notificationMessage({
            type: "error",
            message: "Thất bại",
            description: `Tạo cv thất bại!`,
          });
        });
    }
  };

  const changeSuccessNotification = (option) => {
    const search = queryString.parse(location.search);
    notificationMessage({
      message: "Thành công",
      description: `${option} cv thành công! Trang web sẽ được điều hướng`,
    });
    setTimeout(() => {
      dispatch(resetBuilder());
      dispatch(resetSetting());
      if (lastRedirectUrl(search.redirect)) {
        history.push(lastRedirectUrl(search.redirect));
      } else {
        history.push(routes.userSetting.path + "?action=cv-management");
      }
    }, 1000);
  };

  const getCvFileToUpload = async () => {
    const { formElement, pdfOptions } = getElementForm(1.5, 1);
    const user = JSON.parse(localStorage.getItem("User"));

    const pdfDataUrl = await html2pdf()
      .from(formElement)
      .set(pdfOptions)
      .output("datauristring");

    const pdfBlob = await fetch(pdfDataUrl).then((res) => res.blob());

    const params = queryString.stringify({
      role: "Candidate",
      email: user?.email,
      image_name: `${setting?.cvName}-cv.pdf`,
      type: true,
    });

    const formData = new FormData();
    formData.append("files", pdfBlob, `${setting?.cvName}-cv.pdf`);
    if (setting?.isUpdate) {
      formData.append("prevPdfImage", setting.prevPdfImage);
    }

    const fileUpload = await UserService.uploadCv(params, formData);
    return fileUpload.data.data[0];
  };

  const loginOrSigin = () => {
    const loginStatus = JSON.parse(localStorage.getItem("loginStatus"));

    if (!loginStatus || loginStatus.status !== "success") {
      dispatch(changeActions({ field: "openSaveCv" }));
      return false;
    }
    return true;
  };

  const missingName = () => {
    const profile = builder.profile;
    const regexPhoneNumber = /^(?:\+84|0)(?:\d{9,10})$/;
    const regexEmail =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;

    const dataCheck = [
      {
        name: "cvName",
        value: setting?.cvName,
        message: "Bắt buộc",
      },
      {
        name: "fullName",
        value: profile.fullName,
        message: "Bắt buộc",
      },
      {
        name: "email",
        value: regexEmail.test(profile.email),
        message: "Bắt buộc",
      },
      {
        name: "phoneNumber",
        value: regexPhoneNumber.test(profile.phoneNumber),
        message: "Bắt buộc",
      },
    ];

    if (AVATAR_REQUIED_IN_SAVE.includes(setting.templateName)) {
      dataCheck.push({
        name: "avatar",
        value: builder?.avatar?.url,
        message: "Bắt buộc",
      });
    }

    return !checkAllRequiredValue(dataCheck);
  };

  const checkAllRequiredValue = (data = []) => {
    let result = false;
    for (const item of data) {
      if (!item?.value) {
        dispatch(changeValidateForm({ field: item.name, value: item.message }));
        result = true;
      }
    }
    if (result) changeErrorForm();
    return result;
  };

  const changeErrorForm = () => {
    dispatch(changeValidateForm({ field: "scrollTop", value: true }));
    notificationMessage({
      type: "error",
      message: "Thất bại",
      description: `Vui lòng nhập đúng các thông tin báo đỏ`,
    });
  };

  const getElementForm = (quality = 2, scale = 1) => {
    const formElement = document.getElementById(setting?.templateName);
    let dataMargin = [1, 0, 2, 1];

    if (["CV_005"].includes(setting.templateName)) {
      dataMargin = [0, 0, 1, 0];
    }
    const pdfOptions = {
      margin: dataMargin,
      filename: `${setting?.cvName}-cv.pdf`,
      image: { type: "jpeg", quality: quality },
      html2canvas: { scale: scale, imageTimeout: 0 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    return { formElement, pdfOptions };
  };

  const notificationMessage = ({ type, message, description }) => {
    const data = {
      message: message,
      description: description,
      duration: 3,
    };
    switch (type) {
      case "error": {
        notification.error(data);
        break;
      }
      default: {
        notification.success(data);
      }
    }
  };

  return { handleSaveCv, exportToPDF, handleOpenReview, handleChangeActive };
};
