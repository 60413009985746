import {
  BarsOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
} from "@ant-design/icons";
import React from "react";
import { debounce } from "../../../utils";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  changeActions,
  changeFormOrder,
  changeFormToChange,
  selectFormToChange,
} from "../../redux/slice/setting";
import { cx } from "../../../lib/cx";

function SettingBlock(props) {
  const { children, color, isActive, form, position } = props;
  const { active } = useAppSelector(selectFormToChange);
  const dispatch = useAppDispatch();

  const handleChangePosition = (type) => {
    dispatch(changeFormOrder({ position: position, type: type, value: form }));
  };

  const handleChangeActive = () => {
    dispatch(changeFormToChange({ field: "active", value: form }));
  };

  return (
    <div className="relative">
      <div
        className={cx(
          "hover:border-[1px] hover:border-solid hover:cursor-pointer",
          color ? `hover:border-[#bbb]` : "hover:border-white",
          isActive &&
            form === active &&
            "border hover:border-solid border-[#bbb]"
        )}
        onMouseMove={debounce(handleChangeActive, 10)}
      >
        {children}
      </div>
      {isActive && form === active && (
        <div className="bg-[#ccc] p-[2px] shadow-md absolute -top-[20px] left-0 cursor-pointer">
          <div className="w-full flex justify-center items-center">
            <div
              className="p-1 bg-white flex justify-center items-center mr-[2px]"
              onClick={() => dispatch(changeActions({ field: "openAddMore" }))}
            >
              <BarsOutlined style={{ fontSize: "8px" }} />
            </div>
            <div
              className="p-1 bg-white flex justify-center items-center mr-[2px]"
              onClick={() => {
                handleChangePosition("up");
              }}
            >
              <CaretUpOutlined style={{ fontSize: "8px" }} />
            </div>
            <div
              className="p-1 bg-white flex justify-center items-center"
              onClick={() => {
                handleChangePosition("down");
              }}
            >
              <CaretDownOutlined style={{ fontSize: "8px" }} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SettingBlock;
