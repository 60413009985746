import CV_001 from "./CV_001";
import CV_002 from "./CV_002";
import CV_003 from "./CV_003";
import CV_004 from "./CV_004";
import CV_005 from "./CV_005";
import CV_006 from "./CV_006";

const dataTemplateCv = {
  CV_001: <CV_001 />,
  CV_002: <CV_002 />,
  CV_003: <CV_003 />,
  CV_004: <CV_004 />,
  CV_005: <CV_005 />,
  CV_006: <CV_006 />,
};

export const getTemplateRender = (name) => {
  const template = dataTemplateCv[name];
  if (template) return template;
  return null;
};
