import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { pathAdmin } from "../../screens/PathRouting";
import { SettingOutlined } from "@ant-design/icons";
import SearchForm from "./SearchForm";
import CardRecruiter from "../components/Card/CardRecruiter";
import CardEmpty from "../components/Card/CardEmpty";
import CreateCompany from "../components/Modal/CreateCompany";
import AddMember from "../components/Modal/AddMember";
import CardEmployee from "../components/Card/CardEmployee";
import BreadcrumbAdmin from "../components/Tag/Breadcrumb";
import AdminService from "../../services/AdminService";
import { getId, getLocation } from "../../utils";
import UserService from "../../services/UserService";
import dayjs from "dayjs";
import queryString from "query-string";
import RemoveGroupModal from "../components/Modal/RemoveGroupModal";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";

function CompanyDetail() {
  const { theme } = useTheme();
  const [popupCreate, setPopupCreate] = useState();
  const [popupAddMember, setPopupAddMember] = useState();
  const [company, setCompany] = useState();
  const [listJds, setListJds] = useState();
  const [listGroup, setListGroup] = useState();
  const history = useHistory();
  const location = useLocation();
  const [dataUpdate, setDataUpdate] = useState();
  const [popupRemove, setPopupRemove] = useState(false);
  const [valueRemove, setValueRemove] = useState();
  const [availableGroups, setAvailableGroups] = useState([]);

  useEffect(() => {
    if (!company) {
      getCompanyDetail();
      getAvailableGroups();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const getAvailableGroups = async () => {
    const getAvailableGroups = await AdminService.getAvailableGroups(
      getId(location.pathname)
    );

    const groupData = getAvailableGroups.data.data.map((data) => {
      return {
        id: data.id,
        groupName: data.name,
        numberEmployee: data.countRecruiters,
        countJds: data.countJds,
        countCandidate: data.countCVs,
      };
    });

    setAvailableGroups(groupData);
  };

  const getCompanyDetail = async (value = {}) => {
    const response = await AdminService.getCompanyById(
      getId(location.pathname),
      value
    );

    const data = response.data.data[0];
    let image = "";
    if (data?.avatar) {
      image = await UserService.getImageByName([data.avatar]);
      image = image.data.data[0].fileUrl;
    }

    let result = [];
    if (data?.group) {
      const groups = await AdminService.getGroupById(
        data.group,
        `?${queryString.stringify({ offset: 0, limit: 5 })}`,
        { status: "ACTIVE" }
      );
      result = groups.data.data.map((item) => ({
        id: item.id,
        groupName: item.name,
        groupMember: item.countRecruiters,
      }));
    }
    setListGroup(result);

    if (data?.listJD && data.listJD.length > 0) {
      const result = data.listJD.map((item) => {
        return {
          id: item.id,
          jobName: item.jobName,
          date: dayjs(new Date(item.startReceiveCV)).format("DD/MM/YYYY"),
          cvApplied: item.cvApplyList.length,
          location: getLocation(item.location),
          status: item?.status ? item.status.toLowerCase() : "inactive",
        };
      });
      setListJds(result);
    } else {
      setListJds([]);
    }

    setCompany({ ...data, avatar: image });
    setDataUpdate({
      id: getId(location.pathname),
      name: data?.name,
      address: data?.address,
      note: data?.note,
      avatar: image,
    });
  };

  return (
    <div
      className={cx(
        "mx-auto h-auto lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <div className="px-4">
        <BreadcrumbAdmin
          data={[
            {
              name: "Quản lý danh mục công ty",
              path: pathAdmin.adminManageCompany.path,
            },
            {
              name: company?.name,
            },
          ]}
        />

        <div
          className={cx(
            "w-full bg-white rounded-[18px] relative",
            theme?.background?.text
          )}
        >
          <div className="grid grid-cols-4 md:grid-cols-10 px-6 py-4">
            <div className="col-span-1 flex justify-start items-center">
              <div className="w-[80px] h-[80px] flex justify-center items-center rounded-[50%] ">
                <img
                  src={company?.avatar}
                  alt=""
                  className="max-w-full max-h-full object-cover"
                />
              </div>
            </div>
            <div className="col-span-3 pl-4 md:pl-0 md:col-span-8">
              <p className="font-[600]">Công ty</p>
              <p className="text-[18px] text-black font-[600]">
                {company?.name}
              </p>
              <div className="flex justify-start items-center">
                <img
                  src="/asserts/location.png"
                  alt=""
                  className="w-[12px] h-[12px]"
                />
                <p className="ml-1">
                  {company?.address && getLocation(company.address)}
                </p>
              </div>
              <div className="flex justify-start items-center">
                <img
                  src="/icons/notepad2.png"
                  alt=""
                  className="w-[12px] h-[12px]"
                />
                <p className="ml-1">{company?.note}</p>
              </div>
            </div>
            <div className="col-span-4 mt-2 md:mt-0 md:col-span-1 flex justify-end items-start">
              <div
                className="px-4 py-2 bg-[#B5C1FF] text-[12px] text-black rounded-md cursor-pointer hover:text-white flex justify-center items-center"
                onClick={() => setPopupCreate(true)}
              >
                <SettingOutlined style={{ marginRight: "5px" }} /> Setting
              </div>
            </div>
          </div>
          <div className="w-[18px] h-full rounded-tr-[12px] rounded-br-[12px] bg-[#FF9900] absolute top-0 right-0"></div>
        </div>

        <div className="w-full my-4">
          <p className={cx("text-[22px] font-[600]", theme?.background?.text)}>
            Các bài tuyển dụng đã đăng tải
          </p>
        </div>

        <div>
          <SearchForm onChangeValue={(value) => getCompanyDetail(value)} />
        </div>

        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 my-[40px]">
          <CardEmpty
            icon="/icons/folderadd.png"
            title="Thêm bài tuyển dụng"
            onClick={() => {}}
            className="!h-[200px]"
          />
          {listJds &&
            listJds.length > 0 &&
            listJds.map((item) => (
              <CardRecruiter
                status={item.status}
                data={item}
                onHandleDetail={() =>
                  history.push(
                    pathAdmin.adminPostRecruitment.parentPath + `/${item.id}`
                  )
                }
              />
            ))}
        </div>

        <div className="w-full my-4">
          <p className={cx("text-[22px] font-[600]", theme?.background?.text)}>
            Quản lý nhân sự dự án
          </p>
        </div>

        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 my-[40px]">
          {listGroup &&
            listGroup.length > 0 &&
            listGroup.map((item) => (
              <CardEmployee
                data={item}
                onHandleChange={() =>
                  history.push(
                    pathAdmin.adminGroupDetail.paretPath + `/${item.id}`
                  )
                }
                onHandleDetail={() =>
                  history.push(
                    pathAdmin.adminGroupDetail.paretPath + `/${item.id}`
                  )
                }
                onHandleDelete={(value) => {
                  setValueRemove(value);
                  setPopupRemove(!popupRemove);
                }}
              />
            ))}

          <CardEmpty
            icon="/icons/plus-circle.png"
            title="Thêm nhóm vào dự án"
            onClick={() => setPopupAddMember(true)}
            className="!h-[200px]"
          />
        </div>
      </div>

      {dataUpdate && (
        <CreateCompany
          active={popupCreate}
          onChangeActive={() => {
            getCompanyDetail();
            setPopupCreate(!popupCreate);
          }}
          dataUpdate={dataUpdate}
        />
      )}

      <AddMember
        active={popupAddMember}
        group={availableGroups}
        companyId={getId(location.pathname)}
        onChangeActive={() => setPopupAddMember(!popupAddMember)}
        onChangeAddGroup={() => getAvailableGroups()}
      />

      <RemoveGroupModal
        data={valueRemove}
        active={popupRemove}
        onChangeActive={() => setPopupRemove(!popupRemove)}
      />
    </div>
  );
}

export default CompanyDetail;
