import { useEffect, useRef } from "react";

export const useAutosizeTextareaHeight = ({ value }) => {
  const textareaRef = useRef(null);

  const resizeHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "0px";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  // Resize height when value changes
  useEffect(() => {
    resizeHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // Resize height when viewport resizes
  useEffect(() => {
    window.addEventListener("resize", resizeHeight);
    return () => window.removeEventListener("resize", resizeHeight);
  }, []);

  return textareaRef;
};
