import React from "react";
import ObjectiveComponent from "../../components/SubComponent/ObjectiveComponent";
import TrippleComponent from "../../components/SubComponent/TrippleComponent";
import { useAppSelector } from "../../redux/hooks";
import {
  selectFormOrder,
  selectFormToShow,
  selectSetting,
} from "../../redux/slice/setting";
import SubDoubleComponent from "../../components/SubComponent/SubDoubleComponent";

function RightComponent() {
  const { themeColor } = useAppSelector(selectSetting);
  const { right } = useAppSelector(selectFormOrder);
  const formToShow = useAppSelector(selectFormToShow);

  const formTypeToComponent = {
    objective: <ObjectiveComponent form="objective" titleColor={themeColor} />,
    educations: (
      <TrippleComponent
        form="educations"
        listTitle={{
          title: "Chuyên ngành",
          label: "Tên trường học",
          description: "Mô tả quá trình học tập hoặc thành tích cá nhân",
        }}
        titleColor={themeColor}
      />
    ),
    workExperiences: (
      <TrippleComponent
        form="workExperiences"
        listTitle={{
          title: "Vị trí công việc",
          label: "Tên công ty",
          description: "Mô tả kinh nghiệm của bạn",
        }}
        titleColor={themeColor}
      />
    ),
    activeties: (
      <TrippleComponent
        form="activeties"
        listTitle={{
          title: "Tên hoạt động",
          label: "Địa điểm",
          description: "Mô tả danh hiệu hoạt động",
        }}
        titleColor={themeColor}
      />
    ),
    honors_and_awards: (
      <SubDoubleComponent
        form="honors_and_awards"
        listTitle={{ title: "Giải thưởng", description: "Thời gian" }}
        other="text-black placeholder:text-gray"
        position="other"
        titleColor={themeColor}
      />
    ),
    cetifications: (
      <SubDoubleComponent
        form="cetifications"
        listTitle={{ title: "Tên chứng chỉ", description: "Thời gian" }}
        other="text-black placeholder:text-gray"
        position="other"
        titleColor={themeColor}
      />
    ),
  };
  return (
    <div>
      {right &&
        right.map((field) => {
          if (formToShow[field]) {
            const component = formTypeToComponent[field];
            return (
              <React.Fragment>
                {component}
                <div
                  style={{
                    border: `1px solid ${themeColor}`,
                    marginTop: "10px",
                  }}
                ></div>
              </React.Fragment>
            );
          }
          return null;
        })}
    </div>
  );
}

export default RightComponent;
