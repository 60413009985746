import React from "react";
import ObjectiveComponent from "../../components/SubComponent/ObjectiveComponent";
import TrippleComponent from "../../components/SubComponent/TrippleComponent";
import { useAppSelector } from "../../redux/hooks";
import { selectFormOrder, selectFormToShow } from "../../redux/slice/setting";
import DoubleComponent from "../../components/SubComponent/DoubleComponent";

const formTypeToComponent = {
  objective: <ObjectiveComponent form="objective" position="other" />,
  educations: (
    <TrippleComponent
      form="educations"
      title="Học vấn"
      listTitle={{
        title: "Chuyên ngành",
        label: "Tên trường học",
        description: "Mô tả quá trình học tập hoặc thành tích cá nhân",
      }}
      position="other"
    />
  ),
  workExperiences: (
    <TrippleComponent
      form="workExperiences"
      title="Kinh nghiệm làm việc"
      listTitle={{
        title: "Vị trí công việc",
        label: "Tên công ty",
        description: "Mô tả kinh nghiệm của bạn",
      }}
      position="other"
    />
  ),
  skills: (
    <DoubleComponent
      form="skills"
      title="Kĩ năng"
      listTitle={{ title: "Tên kĩ năng" }}
      other="!text-black placeholder:text-gray font-[600]"
      position="other"
    />
  ),
  cetifications: (
    <DoubleComponent
      form="cetifications"
      title="Chứng chỉ"
      listTitle={{ title: "Tên chứng chỉ", description: "Thời gian" }}
      other="!text-black placeholder:text-gray"
      position="other"
    />
  ),
  activeties: (
    <DoubleComponent
      form="activeties"
      title="Hoạt động"
      listTitle={{ title: "Tên hoạt động", description: "Thời gian" }}
      other="!text-black placeholder:text-gray"
      position="other"
    />
  ),
  interestes: (
    <DoubleComponent
      form="interestes"
      title="Sở thích"
      listTitle={{ title: "Tên sở thích" }}
      other="!text-black placeholder:text-gray"
      position="other"
    />
  ),
  addtional_informations: (
    <DoubleComponent
      form="addtional_informations"
      title="Thông tin thêm"
      listTitle={{ title: "Thông tin thêm" }}
      other="!text-black placeholder:text-gray"
      position="other"
    />
  ),
};

function LayerComponent() {
  const { other } = useAppSelector(selectFormOrder);
  const formToShow = useAppSelector(selectFormToShow);
  return (
    <div className="px-[20px] pt-[40px] pb-[20px]">
      {other &&
        other.map((field) => {
          if (formToShow[field]) {
            const component = formTypeToComponent[field];
            return component;
          }
          return null;
        })}
    </div>
  );
}

export default LayerComponent;
