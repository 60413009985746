import React from "react";
import TrippleComponent from "../../components/SubComponent/TrippleComponent";
import SubDoubleComponent from "../../components/SubComponent/SubDoubleComponent";
import { useAppSelector } from "../../redux/hooks";
import {
  selectFormOrder,
  selectFormToShow,
  selectSetting,
} from "../../redux/slice/setting";

function RightComponent() {
  const { themeColor } = useAppSelector(selectSetting);
  const { right } = useAppSelector(selectFormOrder);
  const formToShow = useAppSelector(selectFormToShow);

  const formTypeToComponent = {
    educations: (
      <TrippleComponent
        form="educations"
        listTitle={{
          title: "Chuyên ngành",
          label: "Tên trường học",
          description: "Mô tả quá trình học tập hoặc thành tích cá nhân",
        }}
        titleColor={themeColor}
        position
        border="w-[35%]"
        isFlex
      />
    ),
    workExperiences: (
      <TrippleComponent
        form="workExperiences"
        listTitle={{
          title: "Vị trí công việc",
          label: "Tên công ty",
          description: "Mô tả kinh nghiệm của bạn",
        }}
        titleColor={themeColor}
        position
        border="w-[110%]"
        isFlex
      />
    ),
    activeties: (
      <TrippleComponent
        form="activeties"
        listTitle={{
          title: "Tên hoạt động",
          label: "Địa điểm",
          description: "Mô tả danh hiệu hoạt động",
        }}
        titleColor={themeColor}
        position
        border="w-[35%]"
        isFlex
      />
    ),
    honors_and_awards: (
      <SubDoubleComponent
        form="honors_and_awards"
        listTitle={{ title: "Giải thưởng", description: "Thời gian" }}
        other="text-black placeholder:text-gray"
        position="other"
        titleColor={themeColor}
        border="w-[155%]"
      />
    ),
    cetifications: (
      <SubDoubleComponent
        form="cetifications"
        listTitle={{ title: "Tên chứng chỉ", description: "Thời gian" }}
        other="text-black placeholder:text-gray"
        position="other"
        titleColor={themeColor}
        border="w-[35%]"
      />
    ),
    addtional_informations: (
      <SubDoubleComponent
        form="addtional_informations"
        listTitle={{ title: "Thông tin thêm" }}
        titleColor={themeColor}
        other="text-black placeholder:text-gray"
        position="other"
        border="w-[70%]"
      />
    ),
  };
  return (
    <div>
      {right &&
        right.map((field) => {
          if (formToShow[field]) {
            const component = formTypeToComponent[field];
            return <React.Fragment>{component}</React.Fragment>;
          }
          return null;
        })}
    </div>
  );
}

export default RightComponent;
