import { DownOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import UserService from "../../../services/UserService";
import { notification } from "antd";
import AdminService from "../../../services/AdminService";
import { useAuth } from "../../Auth/useAuth";

function SelectActive({ active, id, isBlock, slug, recruiterId }) {
  const { admin } = useAuth();
  const [status, setStatus] = useState();
  const [show, setShow] = useState(false);

  notification.config({ top: 80, placement: "topRight" });

  useEffect(() => {
    setStatus(active);
  }, [active]);

  const handleClick = async (action) => {
    setStatus(action);
    setShow(false);

    if (id) {
      await UserService.updateJDStatus(id, {
        status: action.toUpperCase(),
      }).then(() => {
        notificationSuccess();
      });
    }

    if (slug) {
      await AdminService.changeStatusNews({
        status: action.toUpperCase(),
        role: admin.sub_role,
        slug: slug,
      }).then(() => {
        notificationSuccess();
      });
    }
  };

  const notificationSuccess = () => {
    notification.success({
      message: "Thành công",
      description: "Chỉnh sửa trạng thái thành công!",
      duration: 4,
      style: { top: 50 },
    });
  };

  return (
    <div className="relative">
      <div
        className="px-3 py-2 absolute top-0 left-0 border z-30 rounded-lg flex justify-center items-center cursor-pointer"
        onClick={() => !isBlock && setShow(!show)}
      >
        {status !== "active" ? (
          <div
            className={`w-[6px] h-[6px] rounded-[50%] mr-2 ${
              status === "inprogress" ? "bg-[#d7e600]" : "bg-[#E60019]"
            }`}
          ></div>
        ) : (
          <div className="w-[6px] h-[6px] rounded-[50%] bg-[#268D61] mr-2"></div>
        )}
        <DownOutlined style={{ fontSize: "10px" }} />
      </div>
      <div
        className={`w-[140px] shadow-xl border border-[#eee] absolute z-20 top-7 -right-12 bg-white cursor-pointer ${
          show ? "block" : "hidden"
        }`}
      >
        <div
          className="px-2 py-1 text-[#268D61] flex justify-start items-center hover:bg-[#618de4] hover:text-black"
          onClick={() => handleClick("active")}
        >
          <div className="w-[6px] h-[6px] rounded-[50%] bg-[#268D61] mr-2"></div>{" "}
          Active
        </div>
        {slug ? (
          <>
            <div
              className="px-2 py-1 text-[#d7e600] flex justify-start items-center hover:bg-[#618de4] hover:text-black"
              onClick={() => handleClick("inprogress")}
            >
              <div className="w-[6px] h-[6px] rounded-[50%] bg-[#d7e600] mr-2"></div>{" "}
              Inprogess
            </div>
            <div
              className="px-2 py-1 text-[#E60019] flex justify-start items-center hover:bg-[#618de4] hover:text-black"
              onClick={() => handleClick("reject")}
            >
              <div className="w-[6px] h-[6px] rounded-[50%] bg-[#E60019] mr-2"></div>{" "}
              Reject
            </div>
          </>
        ) : (
          <div
            className="px-2 py-1 text-[#E60019] flex justify-start items-center hover:bg-[#618de4] hover:text-black"
            onClick={() => handleClick("inactive")}
          >
            <div className="w-[6px] h-[6px] rounded-[50%] bg-[#E60019] mr-2"></div>{" "}
            Inactive
          </div>
        )}
      </div>
      <div
        className={`w-[160px] h-[90px] z-1 opacity-0 absolute top-5 -right-14`}
        onMouseMove={() => setShow(false)}
      ></div>
    </div>
  );
}

export default SelectActive;
