import React from "react";
import Link from "@mui/material/Link";
import { Button, ConfigProvider } from "antd";

export const Bf = (props) => {
  // mẫu nút kiểu liên kết
  return (
    <Link underline="none" color={props.color} onClick={props.onClick}
      disabled={props.disabled}
      className={props.className}
    >
      {props.title}
    </Link>
  );
};

export const Bt = (props) => {
  return (
    <ConfigProvider theme={props.theme}>
      <Button
        type="primary"
        style={props.style}
        onClick={props.onClick}
        size={props.size}
        icon={props.icon}
        className={props.className}
      >
        {props.title}
      </Button>
    </ConfigProvider>
  );
};

export const Bt1 = (props) => {
  return (
    <Button
      className={props.className}
      type={props.type}
      shape={props.shape}
      style={props.style}
      onClick={props.onClick}
      size={props.size}
      icon={props.icon}
      disabled={props.disabled}
    >
      {props.title}
    </Button>
  );
};
