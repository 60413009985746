import {
  Email,
  Facebook,
  LinkedIn,
  LocationCity,
  Person,
  Phone,
} from "@mui/icons-material";
import { useAppSelector } from "../redux/hooks";
import { selectProfile } from "../redux/slice/builder";
import { selectFontSize } from "../redux/slice/setting";

export const useProfileData = (color) => {
  const profile = useAppSelector(selectProfile);
  const fontSize = useAppSelector(selectFontSize);

  const styleIcon = {
    width: `${Number(fontSize) + 8}px`,
    height: `${Number(fontSize) + 8}px`,
    fontSize: `${Number(fontSize) + 4}px`,
    color: color || "white",
    marginTop: "3px",
    marginRight: "5px",
    padding: "0px",
    display: "inline-block",
  };

  const infoProfile = [
    {
      name: profile?.dateOfBirdth,
      icon: <Person style={styleIcon} />,
    },
    {
      name: profile?.email,
      icon: <Email style={styleIcon} />,
    },
    {
      name: profile?.facebook,
      icon: <Facebook style={styleIcon} />,
    },
    {
      name: profile?.linkedin,
      icon: <LinkedIn style={styleIcon} />,
    },
    {
      name: profile?.phoneNumber,
      icon: <Phone style={styleIcon} />,
    },
    {
      name: profile?.location,
      icon: <LocationCity style={styleIcon} />,
    },
  ];

  return { infoProfile };
};
