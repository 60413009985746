import React, { useMemo } from "react";
import { pathAdmin, routes } from "../../screens/PathRouting";
import { useHistory } from "react-router-dom";
import AuthContext from "./AuthContext";
import { ADMIN_ROLE } from "../../utils/constants";
import { useLocalStorage } from "../../lib/useLocalStorage";

export const AuthProvider = ({ children }) => {
  const [admin, setAdmin] = useLocalStorage("admin", null);
  const [token, setToken] = useLocalStorage("token", null);
  const history = useHistory();
  const login = async (data) => {
    setAdmin(data);
    setToken(data.token);

    switch (data.sub_role) {
      case ADMIN_ROLE.SUPPER_ADMIN: {
        history.push(pathAdmin.admin.path);
        break;
      }
      case ADMIN_ROLE.POST_MANAGER: {
        history.push(pathAdmin.adminManagerNews.path);
        break;
      }
      case ADMIN_ROLE.ADS_MANAGER: {
        history.push(pathAdmin.adminAdsManagement.path);
        break;
      }
      default:
    }
  };
  const logout = () => {
    setAdmin(null);
    setToken(null);
    history.push(routes.login.path);
  };

  const value = useMemo(
    () => ({ admin, token, login, logout }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [admin, token]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
