import React from "react";
import InputCv from "../Input";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { changeProfile, selectProfile } from "../../redux/slice/builder";
import {
  changeValidateForm,
  selectValidateForm,
} from "../../redux/slice/setting";
import { cx } from "../../../lib/cx";

function NamePositionBlock({ headCss, childCss, colorHeader }) {
  const profile = useAppSelector(selectProfile);
  const validateForm = useAppSelector(selectValidateForm);
  const dispatch = useAppDispatch();

  const handleChangeInput = (field, value) => {
    dispatch(changeProfile({ field, value }));
    dispatch(changeValidateForm({ field, value: "" }));
  };

  return (
    <div>
      <InputCv
        id="id_username"
        name="fullName"
        value={profile.fullName || ""}
        placeholder="Họ và tên"
        onChangeInput={handleChangeInput}
        className={cx("text-[32px] font-[700]", headCss)}
        size={20}
        error={validateForm["fullName"]}
        style={{ color: colorHeader }}
      />
      <InputCv
        id="id_applyPosition"
        name="applyPosition"
        value={profile.applyPosition || ""}
        placeholder="Vị trí ứng tuyển"
        className={cx("font-[600]", childCss)}
        onChangeInput={handleChangeInput}
        size={4}
      />
    </div>
  );
}

export default NamePositionBlock;
