import React, { useEffect, useState } from "react";

function InputNumber({ name, valueInput, label, onChangeInput }) {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(formattedValue(valueInput));
  }, [valueInput]);

  const onChangeValue = (e) => {
    const value = e.target.value;
    setValue(formattedValue(value));
    if (onChangeInput)
      onChangeInput({
        target: {
          name: e.target.name,
          value: value ? Number(value.split(",").join("")) : 0,
        },
      });
  };

  const formattedValue = (value) => {
    if (!value) return 0;
    return String(value)
      .replace(/[^\d]/g, "")
      .replace(/^0+/, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div className="w-[240px] h-auto mt-2">
      <label>{label}</label>
      <div className="flex border border-gray-1 rounded-[6px]">
        <input
          name={name}
          value={value}
          onChange={onChangeValue}
          className="focus:outline-none pl-2 rounded-l-[6px]"
          autoComplete="off"
        />
        <div className="bg-gray-1 px-3 py-1 rounded-r-[6px]">VND</div>
      </div>
    </div>
  );
}

export default InputNumber;
