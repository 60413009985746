import React, { useCallback, useEffect, useRef } from "react";
import * as pdfjs from "pdfjs-dist";
import { Download } from "@mui/icons-material";
import {
  DeleteOutlined,
  EditOutlined,
  EyeFilled,
  StarFilled,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { routes } from "../../screens/PathRouting";
import {
  saveStateGlobal,
  setNameTemplateCv,
} from "../../cv/redux/local-storage";
import UserService from "../../services/UserService";
import { downloadFile, getImageUrl } from "../../utils";
import { notification } from "antd";

function CardCvCreate({
  data,
  onChangeImage,
  onChangeDelete,
  showStatus,
  className,
  isUpload,
  reloadPage,
}) {
  const canvasRef = useRef();
  const history = useHistory();

  notification.config({ top: 80, placement: "topRight" });

  const renderPage = useCallback(async ({ pdfDoc, pageNum, scale }) => {
    const page = await pdfDoc.getPage(pageNum);
    const ctx = canvasRef.current.getContext("2d");
    const viewport = page.getViewport({ scale });

    canvasRef.current.width = viewport.width;
    canvasRef.current.height = viewport.height;

    page.render({ canvasContext: ctx, viewport: viewport });
  }, []);

  useEffect(() => {
    if (data?.image) {
      const { image } = data;
      const fetchPdf = async () => {
        const loadingTask = pdfjs.getDocument({
          src: image,
          url: image,
        });
        const pdfDoc = await loadingTask.promise;
        renderPage({ pdfDoc, pageNum: 1, scale: 1.2 });
      };
      fetchPdf();
    }
  }, [renderPage, data]);

  const handleEditCv = () => {
    const original_name = data?.original_image;
    UserService.getCvCreateByName({ pdfImage: original_name })
      .then(async (response) => {
        const result = response.data.data;
        if (result) {
          const data = result.blockInfo[0];
          const avatar = await UserService.getImageByName([
            data.builder.avatar.original,
          ]);
          data.builder.avatar.url = getImageUrl(
            avatar.data.data,
            data.builder.avatar.original
          );
          data.setting.isUpdate = true;
          data.setting.actions.openSaveCv = false;
          data.setting.prevPdfImage = original_name;
          saveStateGlobal(data);
          setNameTemplateCv({ name: data.setting.templateName || "CV_001" });
          history.push(routes.createCv.path);
        }
      })
      .catch((error) => console.log("error", new Error(error)));
  };

  const handleChangeMainCV = () => {
    const dataSend = {
      candidateId: data?.candidateId,
      pdfImage: data?.original_image,
    };
    UserService.updateMainCV(dataSend)
      .then(() => {
        notification.success({
          message: "Thành công",
          description: `Update cv chính thành công`,
          duration: 4,
          style: { top: 50 },
        });
        reloadPage();
      })
      .catch((error) => {
        console.log(new Error(error));
      });
  };

  return (
    <div className={`w-full h-[550px] bg-white relative ${className}`}>
      <div className="absolute top-0 left-0 w-full h-full ease-linear flex justify-end items-end">
        <div className="w-full h-full p-[15px] bg-gradient-to-b from-[#EDEEF0] to-[#283545] opacity-20"></div>
      </div>
      <div className="absolute top-0 left-0 w-full h-full ease-linear flex justify-end items-end">
        <div className="w-full p-[20px]">
          <div className="flex justify-start items-center space-x-4">
            <p className="text-white line-clamp-1 font-bold text-lg">
              {data?.name}
            </p>
            {!isUpload && (
              <div
                className="w-[30px] h-[30px] bg-gray cursor-pointer flex justify-center items-center rounded-full"
                onClick={handleEditCv}
              >
                <EditOutlined style={{ color: "white" }} />
              </div>
            )}
            {!isUpload && (
              <div
                className="flex justify-center items-center cursor-pointer"
                onClick={() => {
                  if (!data.isMain) handleChangeMainCV();
                }}
              >
                <div className="w-[30px] h-[30px] bg-gray cursor-pointer flex justify-center items-center rounded-full">
                  <StarFilled
                    style={{ color: `${data?.isMain ? "yellow" : "white"}` }}
                  />
                </div>
                <p className="ml-2 text-white">
                  {data?.isMain ? "CV chính" : "Đặt làm CV chính"}
                </p>
              </div>
            )}
          </div>
          <p className="text-white">
            {isUpload ? "Ngày upload: " : "Cập nhật lần cuối:"} {data?.createAt}
          </p>
          <div className="flex justify-between items-center mt-[40px]">
            <div className="flex space-x-2 cursor-pointer">
              <p
                className="px-4 py-1 bg-[#6F7883] text-white text-[12px] font-[600] rounded-[18px]"
                onClick={() => onChangeImage(data)}
              >
                <EyeFilled style={{ color: "white", fontSize: "14px" }} /> Xem
                CV
              </p>
              <p
                onClick={() =>
                  downloadFile({ url: data?.image, filename: data?.filename })
                }
                className="px-4 py-1 bg-[#6F7883] text-white text-[12px] font-[600] rounded-[18px]"
              >
                <Download style={{ color: "white", fontSize: "14px" }} /> Tải
                xuống
              </p>
            </div>
            <div className="cursor-pointer" onClick={onChangeDelete}>
              <DeleteOutlined style={{ color: "white" }} />
            </div>
          </div>
        </div>
      </div>

      <div
        className="w-full h-full p-1"
        onClick={() => showStatus && onChangeImage(data)}
      >
        <canvas ref={canvasRef} className="w-full h-full" />
      </div>
    </div>
  );
}

export default CardCvCreate;
