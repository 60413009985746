import React from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectSetting } from "../../redux/slice/setting";
import { changeItemValue, selectBuilder } from "../../redux/slice/builder";
import SettingBlock from "../Block/SettingBlock";
import { cx } from "../../../lib/cx";
import SettingChildBock from "../Block/SettingChildBlock";
import InputCv from "../Input";

function SubDoubleComponent({
  form,
  listTitle,
  other,
  position,
  titleColor,
  border,
}) {
  const setting = useAppSelector(selectSetting);
  const { formHeader } = setting;
  const state = useAppSelector(selectBuilder);
  return (
    <div className="w-full pt-4">
      <SettingBlock form={form} position={position || "left"} isActive>
        <div
          className={cx(
            "w-full flex justify-start items-center",
            !titleColor && "border border-x-0 border-t-0 border-b-[black]"
          )}
        >
          <p
            className={cx(
              "font-[700]",
              position && "border border-x-0 border-t-0 border-b-[black]",
              titleColor && "border-none",
              border
            )}
            style={{
              fontFamily: `${setting.fontFamily}`,
              fontSize: `${Number(setting.fontSize) + (position ? 4 : 8)}px`,
              color: titleColor || "white",
            }}
          >
            {formHeader[form]}
          </p>
          {border && (
            <hr
              className="w-full"
              style={{ color: `${setting.themeColor}`, margin: 0 }}
            />
          )}
        </div>
        {state[form] &&
          state[form].map((item, index) => (
            <>
              <SettingChildBock form={form} index={index} isActived>
                <SubDoubleItemComponent
                  data={item}
                  id={index}
                  listTitle={listTitle}
                  form={form}
                  other={other}
                  position={position}
                />
                {!titleColor && (
                  <div className="border-b border-b-gray-1"></div>
                )}
              </SettingChildBock>
            </>
          ))}
      </SettingBlock>
    </div>
  );
}

const SubDoubleItemComponent = ({
  data,
  id,
  form,
  listTitle,
  other,
  position,
}) => {
  const dispatch = useAppDispatch();
  const handleChangeInput = (field, value) => {
    dispatch(changeItemValue({ id, form, field, value }));
  };
  return (
    <div className={cx(position && "flex py-2")}>
      {listTitle?.description && (
        <InputCv
          id={`${form}_desc_${id}`}
          name="description"
          type="input"
          value={data.description || ""}
          placeholder={listTitle.description || ""}
          onChangeInput={handleChangeInput}
          className={cx(
            "!w-[35%]",
            !position && "font-[600]",
            other ? other : "text-white placeholder:text-[#fffdfd]"
          )}
        />
      )}
      <InputCv
        id={`${form}_title_${id}`}
        name="name"
        type="textarea"
        placeholder={listTitle?.title || ""}
        value={data.name || ""}
        onChangeInput={handleChangeInput}
        className={cx(
          !position && "font-[600]",
          other ? other : "text-white placeholder:text-[#fffdfd]"
        )}
      />
    </div>
  );
};

export default SubDoubleComponent;
