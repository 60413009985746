import { useEffect, useState } from "react";
import UserService from "../services/UserService";

function useGetCandidateInfo() {
  const user = JSON.parse(localStorage.getItem("User"));
  const [candidate, setCandidate] = useState();
  const [refarral, setRefarral] = useState();
  const [earnPoint, setEarnPoint] = useState();

  useEffect(() => {
    if (!candidate && user?.id) {
      getCandidateInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidate]);

  const getCandidateInfo = () => {
    UserService.getInfoPersional(user?.id, "?number=1")
      .then((res) => {
        const data = res.data.data;
        const user = data[0];
        setCandidate(user);
        setRefarral(data[1]);
        setEarnPoint(data[2]);
        localStorage.setItem("User", JSON.stringify({ ...user }));
        if (user.avatarImg && user.avatarImg.length > 0) {
          UserService.getImageByName(user.avatarImg).then((response) => {
            const image = response.data.data[0].fileUrl;
            setCandidate({ ...user, avatar: image });
            localStorage.setItem(
              "User",
              JSON.stringify({
                ...res.data.data[0],
                avatar: image,
              })
            );
          });
        }
      })
      .catch((error) => console.log(error));
  };

  return { candidate, refarral, earnPoint };
}

export default useGetCandidateInfo;
