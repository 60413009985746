import React from "react";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { marked } from "marked";

function RecruiterInfo({ data }) {
  return (
    <div>
      <div className="w-full bg-white rounded-[12px] p-[10px]">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <div className="col-span-2 flex">
            <div className="w-[80px] h-[80px] flex justify-center items-center">
              <img
                src={data?.avatarImg}
                alt=""
                className="max-w-full max-h-full object-cover"
              />
            </div>
            <div className="pt-[18px]">
              <p className="font-[600] text-[16px]">{data?.enterprise}</p>
              <p className="text-gray">
                {data?.enterpriseAddress && data.enterpriseAddress[0]}
              </p>
            </div>
          </div>
          <div className="col-span-1">
            <p>
              <LanguageOutlinedIcon fontSize="inherit" /> {data?.website}
            </p>
            <p>
              <PhoneOutlined /> {data?.phoneNumber}
            </p>
            <p>
              <MailOutlined /> {data?.email}
            </p>
          </div>
        </div>
      </div>

      <div className="mt-[10px] w-full min-h-[80px] bg-white rounded-[15px] p-[20px]">
        <p className="text-[24px] text-black font-[600]">
          Giới thiệu về chúng tôi
        </p>
        <hr className="my-2" />
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
          {data?.introduction && (
            <div
              dangerouslySetInnerHTML={{
                __html: marked(data.introduction),
              }}
            />
          )}
          <div>
            {data?.aboutUsImg && (
              <div className="w-full h-[240px]">
                <img
                  src={data.aboutUsImg}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mt-[10px] w-full min-h-[80px] bg-white rounded-[15px] p-[20px]">
        <p className="text-[24px] text-black font-[600]">Văn phòng</p>
        <hr className="my-2" />
        <div className="w-full">
          <div>
            <p className="text-black text-[14px]">
              Địa chỉ văn phòng của doanh nghiệp.
            </p>
            {data &&
              data.enterpriseAddress &&
              data.enterpriseAddress.length > 0 &&
              data.enterpriseAddress.map((address) => (
                <p className="text-black text-[14px] py-3">- {address}</p>
              ))}
          </div>
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1">
            {data?.officeImg &&
              data.officeImg.length > 0 &&
              data.officeImg.map((office) => (
                <div className="w-full h-[180px] rounded-[12px]">
                  <img
                    src={office}
                    alt=""
                    className="w-full h-full object-cover rounded-[12px]"
                  />
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="mt-[10px] w-full min-h-[80px] bg-white rounded-[15px] p-[20px]">
        <p className="text-[24px] text-black font-[600]">Câu chuyện</p>
        <hr className="my-[20px]" />
        <div className="w-full">
          <div>
            {data?.story && data.story.length > 0 && (
              <p className="text-black text-[14px] py-3">
                {data.story.map((item) => (
                  <div dangerouslySetInnerHTML={{ __html: marked(item) }} />
                ))}
              </p>
            )}
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-1">
            {data?.storyImg &&
              data.storyImg.map((item, index) => (
                <div
                  key={"story_image" + index}
                  className="w-full h-[180px] rounded-[12px]"
                >
                  <img
                    src={item}
                    alt=""
                    className="w-full h-full object-cover rounded-[12px]"
                  />
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="mt-[10px] w-full min-h-[80px] bg-white rounded-[15px] p-[20px]">
        <p className="text-[24px] text-black font-[600]">Phúc lợi</p>
        <hr className="my-[20px]" />
        <div className="w-full">
          <div>
            {data?.welfare && data.welfare.length > 0 && (
              <p className="text-black text-[14px] py-3">
                {data.welfare.map((item) => (
                  <div dangerouslySetInnerHTML={{ __html: marked(item) }} />
                ))}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecruiterInfo;
