import React from "react";
import InputCv from "../Input";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { changeItemValue, selectBuilder } from "../../redux/slice/builder";
import {
  changeFormToChange,
  selectFontFamily,
  selectFormHeader,
  selectSetting,
} from "../../redux/slice/setting";
import { cx } from "../../../lib/cx";
import SettingBlock from "../Block/SettingBlock";
import SettingChildBock from "../Block/SettingChildBlock";
import { debounce } from "../../../utils";

function TrippleComponent({
  form,
  listTitle,
  position,
  titleColor,
  border,
  isFlex,
}) {
  const state = useAppSelector(selectBuilder);
  const setting = useAppSelector(selectSetting);
  const { themeColor } = setting;
  const fontFamily = useAppSelector(selectFontFamily);
  const formHeader = useAppSelector(selectFormHeader);
  const dispatch = useAppDispatch();

  const handleChangeActive = () => {
    dispatch(changeFormToChange({ field: "childActive", value: "" }));
  };

  return (
    <div className="mt-2">
      <SettingBlock form={form} position={position || "right"} isActive color>
        <div
          className={cx(
            "w-full flex justify-start items-center",
            !titleColor && "border border-x-0 border-t-0 border-b-[black]"
          )}
        >
          <p
            className={cx("pb-1", border)}
            style={{
              fontSize: `${Number(setting.fontSize) + (position ? 4 : 8)}px`,
              fontFamily: `${fontFamily}`,
              color: titleColor || "black",
            }}
            onMouseMove={debounce(handleChangeActive, 10)}
          >
            <b>{formHeader[form]}</b>
          </p>
          {border && (
            <hr
              className="w-full"
              style={{ color: `${themeColor}`, margin: 0 }}
            />
          )}
        </div>
        {state[form] &&
          state[form].map((item, index) => (
            <>
              <SettingChildBock form={form} index={index} isActived color>
                <TrippleItemComponent
                  data={item}
                  listTitle={listTitle}
                  index={index}
                  form={form}
                  setting={setting}
                  isFlex={isFlex}
                />
              </SettingChildBock>
            </>
          ))}
      </SettingBlock>
    </div>
  );
}

const TrippleItemComponent = ({
  form,
  data,
  index,
  setting,
  listTitle,
  isFlex,
}) => {
  const dispatch = useAppDispatch();
  const handleChangeInput = (field, value) => {
    dispatch(changeItemValue({ id: index, form, field, value }));
  };
  return (
    <div className="w-full mt-2">
      <div className={cx(isFlex || "w-full flex justify-between items-center")}>
        <div className="w-[60%]">
          <InputCv
            id={form + `_title_${index}`}
            name="title"
            value={data?.title || ""}
            placeholder={listTitle?.title || ""}
            onChangeInput={handleChangeInput}
            className="!mb-0 text-black font-[600]"
            size={4}
          />
        </div>
        <div className="text-black text-[16px] font-[600]">
          <InputCv
            type="input"
            name="start_time"
            value={data?.start_time || ""}
            placeholder={"03/2023"}
            onChangeInput={handleChangeInput}
            className="!w-[70px] italic placeholder:italic"
          />
          <span> - </span>
          <InputCv
            type="input"
            name="end_time"
            value={data?.end_time || ""}
            placeholder={"03/2023"}
            onChangeInput={handleChangeInput}
            className="!w-[70px] italic placeholder:italic"
          />
        </div>
      </div>

      <div className="flex">
        {listTitle?.isCheck && (
          <p
            className="italic"
            style={{
              width: `${Number(setting.fontSize) * 25}px`,
              fontSize: `${Number(setting.fontSize) + 2}px`,
            }}
          >
            <b>{listTitle?.label}</b>
          </p>
        )}
        <InputCv
          id={form + `_label_${index}`}
          name="label"
          value={data?.label || ""}
          onChangeInput={handleChangeInput}
          type={listTitle?.isCheck ? "input" : "tetarea"}
          placeholder={!listTitle?.isCheck ? listTitle?.label : "1"}
          className={`${
            !listTitle?.isCheck ? "hover:border-x-0" : ""
          } font-[600] italic`}
        />
      </div>

      {listTitle?.sub_label && (
        <div className="flex justify-center items-center">
          {listTitle?.isCheck && (
            <p
              className="italic"
              style={{
                width: `${Number(setting.fontSize) * 11}px`,
                fontSize: `${Number(setting.fontSize) + 2}px`,
              }}
            >
              <b>{listTitle?.sub_label}</b>
            </p>
          )}
          <InputCv
            id={form + `_sub_label_${index}`}
            name="sub_label"
            value={data?.sub_label || ""}
            onChangeInput={handleChangeInput}
            type={listTitle?.isCheck ? "textarea" : "input"}
            placeholder={
              !listTitle?.isCheck ? listTitle?.sub_label : "Nhân viên"
            }
            className={cx(
              "font-[600]",
              !listTitle?.isCheck ? "hover:border-x-0" : ""
            )}
          />
        </div>
      )}
      <InputCv
        id={form + `_description_${index}`}
        name="description"
        value={data?.description || ""}
        onChangeInput={handleChangeInput}
        placeholder={listTitle?.description || ""}
        className="placeholder:italic hover:border-x-0"
      />
    </div>
  );
};

export default TrippleComponent;
