import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { pathAdmin } from "../../screens/PathRouting";
import AvatarItem from "../components/Avatar/AvatarItem";
import { UserAddOutlined } from "@ant-design/icons";
import TagCompany from "../components/Tag/TagCompany";
import CardRecruiter from "../components/Card/CardRecruiter";
import CardCompany from "../components/Card/CardCompany";
import { Pagination, Select } from "antd";
import AddUser from "../components/Modal/AddUser";
import BreadcrumbAdmin from "../components/Tag/Breadcrumb";
import AdminService from "../../services/AdminService";
import { getId, getImageUrl, getLocation } from "../../utils";
import dayjs from "dayjs";
import UserService from "../../services/UserService";
import queryString from "query-string";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import { changePagination } from "../../lib/theme/config";

function GroupDetail() {
  const { theme } = useTheme();
  const [openAddUser, setOpenAddUser] = useState(false);
  const [groupData, setGroupData] = useState();
  const [companies, setComapanies] = useState();
  const [listRecruiter, setListRecruiter] = useState();
  const [listJds, setListJds] = useState();
  const history = useHistory();
  const location = useLocation();

  const oldSearch = queryString.parse(location.search);

  const limit = 12;
  const id = getId(location.pathname);

  useEffect(() => {
    if (!groupData) {
      handleChange();
      getAllRecruiter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData]);

  const getGroupDetail = async (params = {}, option = {}) => {
    const groups = await AdminService.getGroupById([id], params, option);
    const data = groups.data.data[0];
    let listCompany = data?.projectCompany || [];
    if (listCompany.length > 0) {
      const listImage = await UserService.getImageByName(
        listCompany.map((item) => item.avatar)
      );
      listCompany = listCompany.map((item) => ({
        id: item.id,
        avatar: getImageUrl(listImage.data.data, item.avatar),
        companyName: item.name,
        countJds: item.countJds,
        countCandidate: item.countCVs,
      }));
    }

    let listJd = data?.listJDofGroup || [];
    listJd = listJd.map((item) => ({
      id: item.id,
      jobName: item.jobName,
      date: dayjs(new Date(item.startReceiveCV)).format("DD/MM/YYYY"),
      cvApplied: item.cvApplyList.length,
      location: getLocation(item.location),
      status: item?.status ? item.status.toLowerCase() : "inactive",
    }));
    setGroupData(data);
    setComapanies(listCompany);
    setListJds(listJd);
  };

  const getAllRecruiter = async () => {
    const params = queryString.stringify({
      id: id,
      offset: 0,
      limit: 12,
    });
    const response = await AdminService.getAllRecruiterInGroup(params);
    const data = response.data.data.filter((item, index) => index > 0 && item);
    const listImage = await UserService.getImageByName(
      data.map((item) => item.avatarImg[0])
    );

    const result = data.map((item) => ({
      id: item.id,
      name: item.fullName,
      joinDate: dayjs(new Date(item.joinDate)).format("DD/MM/YYYY"),
      avatar: getImageUrl(listImage.data.data, item.avatarImg[0]),
    }));
    setListRecruiter(result);
  };

  const options = [
    {
      value: "ACTIVE",
      label: (
        <div className="flex flex-row justify-start items-center space-x-2">
          <div className="w-[8px] h-[8px] rounded-[50%] bg-[#268D61]"></div>
          <p>Active</p>
        </div>
      ),
    },
    {
      value: "INACTIVE",
      label: (
        <div className="flex flex-row justify-start items-center space-x-2">
          <div className="w-[8px] h-[8px] rounded-[50%] bg-[#E60019]"></div>
          <p>Inactive</p>
        </div>
      ),
    },
  ];

  const handleChange = (value) => {
    const search = queryString.parse(location.search);
    const option = { status: "ACTIVE", ...search, ...value };
    const page = option?.page - 1 || 0;
    history.replace(location.pathname + `?${queryString.stringify(option)}`);
    delete option?.page;
    getGroupDetail(
      "?" + queryString.stringify({ offset: page, limit: limit }),
      option
    );
  };

  return (
    <div
      className={cx(
        "mx-auto min-h-[80vh] lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <div className="px-4">
        <BreadcrumbAdmin
          data={[
            {
              name: "Quản lý nhân sự",
              path: pathAdmin.adminManagePersonal.path,
            },
            {
              name: "Các nhóm nhân sự đã tạo",
              path: pathAdmin.adminGroupList.path,
            },
            {
              name: groupData?.name,
            },
          ]}
        />

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <div className="col-span-1">
            <div className="w-full relative p-[10px] bg-white rounded-[18px]">
              <div className="absolute left-0 top-0 w-[25px] h-full bg-[#0055FF] rounded-tl-[18px] rounded-bl-[18px]"></div>
              <div className="pl-8">
                <p className="font-[600] text-[26px] mb-2 text-black">
                  {groupData?.name}
                </p>
                <p className="text-black text-[12px] font-[600]">
                  Số lượng nhân sự: {groupData?.countRecruiters}
                </p>
                <p className="text-black text-[12px] font-[600]">
                  Dự án:{" "}
                  {groupData?.projectCompany &&
                    groupData.projectCompany
                      .map((item) => item.name)
                      .join(", ")}
                </p>
                <div className="space-x-2 flex mt-2">
                  <TagCompany
                    type={1}
                    title={`Số lượng JD: ${groupData?.countJds || 0}`}
                  />
                  <TagCompany
                    type={2}
                    title={`Số ứng viên: ${groupData?.countCVs || 0} `}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-2">
            <div className="grid grid-cols-2 gap-4">
              <div
                className="w-full px-4 py-5 bg-[#B5C1FF] rounded-[12px] flex justify-center items-center cursor-pointer"
                onClick={() => setOpenAddUser(!openAddUser)}
              >
                <UserAddOutlined />
                <p className="text-black ml-3">Thêm nhân sự</p>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
              {listRecruiter &&
                listRecruiter.length &&
                listRecruiter.map((item) => (
                  <AvatarItem data={item} level={1} />
                ))}
            </div>
          </div>
        </div>

        <div className="w-full my-8">
          <p className={cx("text-[20px] font-[600]", theme?.background?.text)}>
            Các công ty thuộc quản lý
          </p>
        </div>
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {companies &&
            companies.length > 0 &&
            companies.map((company, index) => (
              <CardCompany
                index={index}
                data={company}
                onHandleClick={() =>
                  history.push(
                    pathAdmin.adminCompanyDetail.parentPath + `/${company.id}`
                  )
                }
              />
            ))}
        </div>

        <div className="w-full flex justify-start items-center my-8">
          <div className="flex">
            <p
              className={cx("text-[20px] font-[600]", theme?.background?.text)}
            >
              Bài tuyển dụng đã đăng
            </p>
            <Select
              placeholder=""
              className="rounded-[12px] min-w-[80px] border-[1px] ml-4"
              defaultValue={oldSearch?.status || "ACTIVE"}
              options={options}
              onChange={(value) => handleChange({ status: value })}
            />
          </div>
        </div>
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {listJds &&
            listJds.length > 0 &&
            listJds.map((item) => (
              <CardRecruiter
                status={item.status}
                data={item}
                onHandleDetail={() =>
                  history.push(
                    pathAdmin.adminPostCandidate.parentPath + `/${item.id}`
                  )
                }
              />
            ))}
        </div>
        <div className="w-full flex justify-center items-center mt-8">
          <Pagination
            defaultCurrent={1}
            total={10}
            pageSize={limit}
            onChange={(value) => handleChange({ page: value })}
            itemRender={changePagination}
          />
        </div>
      </div>

      <AddUser
        active={openAddUser}
        onChangeActive={() => setOpenAddUser(!openAddUser)}
        onHandleUpdate={() => {
          handleChange();
          getAllRecruiter();
        }}
        groupId={id}
      />
    </div>
  );
}

export default GroupDetail;
