import React from "react";
import { cx } from "../../lib/cx";
import { EyeFilled } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { pathAdmin } from "../../screens/PathRouting";

function JdList({ data, search, limit }) {
  const history = useHistory();
  return (
    <table className="w-full min-w-[2175px] max-w-[5000px]">
      <thead>
        <tr>
          {[
            "STT",
            "Tên tin tuyển",
            "Tên công ty",
            "Địa chỉ",
            "Mức lương",
            "Thời gian đăng tuyển",
            "Thời gian ứng tuyển",
            "Số ứng viên",
            "",
          ].map((item, index) => (
            <th
              key={item + index + "title"}
              className="px-4 py-1 text-[14px] uppercase text-center bg-[#B7E1FF] rounded-[12px] border-[1px] md:border-[4px] border-[#F4F9FF]"
            >
              {item}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data &&
          data.length > 0 &&
          data.map((jd, idx) => (
            <tr
              key={"jd" + idx + "tab"}
              className="bg-white hover:bg-gray-1 cursor-pointer"
              onClick={() =>
                history.push(
                  pathAdmin.adminPostCandidate.parentPath + `/${jd.id}`
                )
              }
            >
              {[
                (Number(search?.page) || 1) * limit + idx - limit + 1,
                jd.jobName,
                jd.companyName,
                jd.location,
                jd.salary,
                jd.startDate,
                jd.endDate,
                jd.cvApplied,
              ].map((item, index) => (
                <td
                  key={"item" + idx + index + "tab"}
                  className={cx(
                    "py-2 px-4 rounded-[12px] border-[1px] md:border-[6px] border-[#F4F9FF] text-center",
                    index === 0 && "!w-[30px]",
                    index === 1 && "!text-left !w-[25%]",
                    index === 2 && "!text-right",
                    index === 3 && "!text-right w-[220px]",
                    index === 4 && "!text-right !w-[180px]"
                  )}
                >
                  {item}
                </td>
              ))}
              <td
                className={cx(
                  "py-2 px-4 rounded-[12px] border-[1px] md:border-[6px] border-[#F4F9FF] w-[100px]"
                )}
              >
                <div
                  className={cx(
                    "text-[12px] bg-blue-400 rounded-[12px] text-white text-center"
                  )}
                >
                  <EyeFilled /> Chi tiết
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export default JdList;
