import React from "react";
import { cx } from "../../lib/cx";
import { EyeFilled, UploadOutlined } from "@ant-design/icons";
import { getLastPrexPhone, getLocation } from "../../utils";
import { useHistory } from "react-router-dom";
import { pathAdmin } from "../../screens/PathRouting";

function RecruiterAccept({ data, search, limit, type }) {
  const history = useHistory();
  return (
    <table className="w-full min-w-[1117px]">
      <thead>
        <tr>
          {[
            "STT",
            "Tên khách hàng",
            "Email",
            "Số điện thoại",
            "Địa chỉ",
            "Trạng thái",
            "",
          ].map((item, index) => (
            <th
              key={item + index + "title"}
              className="px-4 py-1 text-[14px] uppercase text-center bg-[#B7E1FF] rounded-[12px] border-[1px] md:border-[4px] border-[#F4F9FF]"
            >
              {item}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data &&
          data.length > 0 &&
          data.map((recruiter, idx) => (
            <tr
              key={"recruiter" + idx + "tab"}
              className="bg-white hover:bg-gray-1 cursor-pointer"
              onClick={() =>
                history.push(
                  pathAdmin.detailRecruiter.parentPath + `/${recruiter.id}`
                )
              }
            >
              {[
                (Number(search?.page) || 1) * limit + idx - limit + 1,
                recruiter.enterprise,
                recruiter.email,
                getLastPrexPhone(recruiter.phoneNumber),
                getLocation(recruiter.enterpriseAddress[0]) || "Chưa cập nhật",
              ].map((item, index) => (
                <td
                  key={"item" + idx + index + "tab"}
                  className={cx(
                    "py-2 px-4 rounded-[12px] border-[1px] md:border-[6px] border-[#F4F9FF] text-center",
                    index === 1 && "!text-left",
                    index === 2 && "!text-right",
                    index === 3 && "!text-right",
                    index === 4 && "!text-right"
                  )}
                >
                  {item}
                </td>
              ))}
              <td
                className={cx(
                  "py-2 px-4 rounded-[12px] border-[1px] md:border-[6px] border-[#F4F9FF] w-[130px]"
                )}
              >
                <div
                  className={cx(
                    "text-[12px]  rounded-[12px] text-white text-center",
                    type ? "bg-blue-400" : "bg-green-400"
                  )}
                >
                  <UploadOutlined /> {type ? "Cập nhật" : "Mới"}
                </div>
              </td>
              <td
                className={cx(
                  "py-2 px-4 rounded-[12px] border-[1px] md:border-[6px] border-[#F4F9FF] w-[100px]"
                )}
              >
                <div
                  className={cx(
                    "text-[12px] bg-blue-400 rounded-[12px] text-white text-center"
                  )}
                >
                  <EyeFilled /> Chi tiết
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export default RecruiterAccept;
