import React from "react";

function Tag(props) {
  return (
    <div
      className={`min-w-4 min-h-8 text-[10px] text-white inline float-left mb-1 mr-1 px-[12px] py-[8px] rounded-[12px] ${props.className}`}
      style={{ background: `${props.color || "#B5C1FF"}` }}
    >
      {props.title}
    </div>
  );
}

export default Tag;
