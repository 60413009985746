import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BellOutlined,
  CloseOutlined,
  DownOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Bt } from "../BtForm";
import { SettingOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Tooltip } from "antd";
import { routes, pathAdmin, paymentRoutes } from "../../screens/PathRouting";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../admin/Auth/useAuth";
import { ADMIN_ROLE } from "../../utils/constants";
import { Circle, ModeNight } from "@mui/icons-material";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import { getRootPath } from "../../utils";
import UserService from "../../services/UserService";
import queryString from "query-string";
import dayjs from "dayjs";
import { useGetLoginStorage } from "../../hooks/useGetLoginStorage";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export function Header() {
  const { t } = useTranslation();
  const history = useHistory();
  const { theme } = useTheme();
  const { admin, logout } = useAuth();
  const loginStatus = JSON.parse(localStorage.getItem("loginStatus"));
  const user = JSON.parse(localStorage.getItem("User"));
  const [toggleNoti, setToggleNoti] = useState(false);
  const location = useLocation();
  const [activeMobile, setActiveMobile] = useState("");
  const [activeSubMenu, setActiveSubMenu] = useState("");
  const [notifications, setNotifications] = useState();
  const [unread, setUnread] = useState(0);

  const { status, setStatus } = useGetLoginStorage();

  const [toggleMenuMobile, setToggleMenuMobile] = useState(false);

  const items1 = [
    {
      label: (
        <p
          className="text-black"
          onClick={() =>
            history.push(
              routes.loginPage.path +
                `?role=Candidate&redirect=${
                  location.pathname !== routes.loginPage.path
                    ? location.pathname
                    : routes.home.path
                }`
            )
          }
        >
          Đăng nhập ứng viên
        </p>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <p
          className="text-black"
          onClick={() =>
            history.push(routes.loginPage.path + "?role=Recruiter")
          }
        >
          Đăng nhập nhà tuyển dụng
        </p>
      ),
      key: "3",
    },
  ];

  const items2 = [
    {
      label: (
        <p
          className="text-black"
          onClick={() => history.push(routes.signupCandidate.path)}
        >
          Đăng ký ứng viên
        </p>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <p
          className="text-black"
          onClick={() => history.push(routes.signupRecruiter.path)}
        >
          Đăng ký nhà tuyển dụng
        </p>
      ),
      key: "3",
    },
  ];

  const itemLoginAdmin = [
    {
      label: (
        <p className="text-black" onClick={logout}>
          Đăng Xuất
        </p>
      ),
      key: "0",
    },
  ];

  useEffect(() => {
    if (!notifications && loginStatus && loginStatus.status === "success") {
      getAllNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, loginStatus]);

  const getAllNotifications = () => {
    if (!user) {
      setNotifications([]);
      return;
    }
    const params = queryString.stringify({
      userId: user.id,
      type: loginStatus.role.toLowerCase(),
      offset: 0,
      limit: 15,
    });
    UserService.getNotification(params)
      .then((response) => {
        const data = response.data.data.items;
        setNotifications(data);
        const listUnread = data.filter((item) => item.isRead);
        setUnread(data.length - listUnread.length);
      })
      .catch((error) => {
        console.error(new Error(error));
      });
  };

  const handleClickLogout = async () => {
    setStatus(false);
    setToggleMenuMobile(!toggleMenuMobile);
    if (history) {
      history.push(routes.home.path);
    } else {
      window.location.href = "/";
    }
    localStorage.clear();
  };

  const handleChangeSetting = () => {
    if (loginStatus.role === "Candidate") {
      history.push(routes.userSetting.path);
    }
    if (loginStatus.role === "Recruiter") {
      history.push(routes.recruiterDashboard.path);
    }
  };

  const handleUnreadAll = () => {
    if (loginStatus && loginStatus.status === "success") {
      UserService.updateReadAllNotification({
        userId: user?.id,
        type: loginStatus.role.toLowerCase(),
      })
        .then(() => {
          getAllNotifications();
        })
        .catch((error) => console.error(new Error(error)));
    }
  };

  const handleReadNotification = (noti) => {
    if (noti.type === "recruiter") {
      history.push(routes.detailJDs.parentPath + `/${noti.jdId}`);
    }

    if (noti.type === "candidate") {
      history.push(routes.workDetail.parentPath + `/${noti.jdId}`);
    }

    if (loginStatus && loginStatus.status === "success") {
      UserService.updateReadOneNotification({
        notiId: noti.id,
      })
        .then(() => {
          getAllNotifications();
        })
        .catch((error) => console.error(new Error(error)));
    }

    setToggleNoti(false);
  };

  const Notification = () => {
    return (
      <div className="relative">
        <p
          className={cx(
            "absolute top-0 left-[10px] w-4 h-4 z-[50] rounded-full bg-red-500 flex justify-center items-center text-white text-[10px]",
            unread < 1 && "hidden"
          )}
        >
          {unread < 10 ? unread : "9+"}
        </p>
        <div
          className="text-white shadow-none cursor-pointer"
          onClick={() => setToggleNoti(!toggleNoti)}
          onMouseMove={() => setToggleNoti(true)}
        >
          <BellOutlined />
        </div>
        <div
          className={cx(
            "w-[350px] h-[420px] absolute top-[50px] bg-red-500 -right-[170px] z-[99999] opacity-0",
            !toggleNoti && "hidden"
          )}
          onMouseMove={() => setToggleNoti(false)}
        ></div>
        <div
          className={cx(
            "w-[390px] md:w-[300px] h-[95vh] sm:h-[400px] bg-white absolute top-[45px] md:top-[50px] -right-[120px] md:-right-[150px] z-[99999] shadow-md",
            !toggleNoti && "hidden"
          )}
        >
          <div
            className={cx("flex justify-between items-center px-4 pt-[10px]")}
          >
            <p className="font-bold">Thông báo</p>
            <p
              className="cursor-pointer hover:underline italic"
              onClick={handleUnreadAll}
            >
              Đánh dấu tất cả
            </p>
          </div>

          <div className="h-[95%] lg:h-[342px] scrollbar-thin scrollbar-thumb-dark scrollbar-track-gray-300 overflow-y-auto">
            {notifications && notifications.length > 0 ? (
              notifications.map((noti, idx) => {
                return (
                  <div
                    key={"noti-" + idx + "item"}
                    className="notification-single border border-l-0 border-b-gray border-r-0 border-t-0 flex px-4 py-3.5 items-center cursor-pointer hover:bg-gray-1"
                    onClick={() => handleReadNotification(noti)}
                  >
                    <div className={cx(noti.isRead && "text-gray")}>
                      Công việc <strong>{noti.title}</strong> {noti.content}
                      <p className="text-gray">
                        {dayjs(noti.createAt).fromNow()}
                      </p>
                    </div>
                    {!noti.isRead && (
                      <div className="w-7 text-center">
                        <span
                          className={`inline-block w-2.5 h-2.5 rounded-full bg-red-500`}
                        ></span>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="w-full h-full flex justify-center items-center">
                Thông báo trống
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const HeaderSuccess = () => {
    return (
      <div className="flex justify-center items-center mr-4 space-x-3">
        <Notification />
        <Bt
          icon={<SettingOutlined />}
          className="text-white shadow-none"
          onClick={handleChangeSetting}
        />
        <div className="w-[30px] h-[30px] bg-gray-1 rounded-full flex justify-center items-center">
          <img
            src={
              user?.avatar ||
              "https://imagekit.io/blog/content/images/2019/12/image-optimization.jpg"
            }
            alt=""
            className="max-w-full max-h-full object-cover rounded-full"
          />
        </div>
        <Bt
          title={"Đăng Xuất"}
          onClick={handleClickLogout}
          className="hidden md:block bg-[#F3F3F3] rounded-[10px] text-[#252A3F]"
        />
      </div>
    );
  };

  const AdminLoginSuccess = () => {
    return (
      <div className="flex justify-center items-center space-x-4 mr-[20px]">
        <Dropdown
          menu={{ items: itemLoginAdmin }}
          trigger={["click"]}
          overlayStyle={{ zIndex: 99999 }}
          arrow
          className="cursor-pointer"
        >
          <Avatar
            src="https://imagekit.io/blog/content/images/2019/12/image-optimization.jpg"
            size="default"
          />
        </Dropdown>
        <div className="w-[34px] h-[24px] rounded-md">
          <img
            src="/images/flag.png"
            alt=""
            className="w-full h-full object-cover rounded-md"
          />
        </div>
      </div>
    );
  };

  const getLinkHome = () => {
    const pathname = location.pathname.split("/");

    if (pathname[1] === "admin") {
      if (admin.sub_role === ADMIN_ROLE.SUPPER_ADMIN)
        return pathAdmin.admin.path;
      return null;
    }

    if (pathname[1] === "recruiter") {
      return routes.recruiterDashboard.path;
    }

    return routes.home.path;
  };

  const menu = [
    { name: t("findWork"), href: routes.workList.path },
    { name: "Tin tức", href: routes.newsList.path },
    { name: "Tạo CV", href: routes.templateCv.path },
    { name: "Dịch vụ", href: routes.paymentCombo.path },
    {
      name: t("about"),
      href: routes.aboutUs.path,
    },
    {
      name: "Diễn đàn",
      url: "https://forum.ldo.vn",
    },
  ];

  const menuRecruiter = [
    {
      name: "Bài tuyển dụng",
      href: routes.recruiterDashboard.path,
    },
    {
      name: "Về doanh nghiệp",
      href: routes.recuiterInfo.path,
    },
    {
      name: "Dịch vụ",
      href: paymentRoutes.paymentComboRecuiter.path,
    },
    {
      name: "Thanh toán",
      href: paymentRoutes.paymentHistory.path,
    },
  ];

  const menuAdmin = [
    {
      name: "Dashboard",
      href: pathAdmin.admin.path,
      role: [ADMIN_ROLE.SUPPER_ADMIN],
    },
    {
      name: "Quản lý",
      role: [ADMIN_ROLE.SUPPER_ADMIN],
      sub_data: [
        {
          name: "Quản lý tin tuyển",
          href: pathAdmin.adminManageJD.path,
          role: [ADMIN_ROLE.SUPPER_ADMIN],
        },
        {
          name: "Quản lý nhà tuyển dụng",
          href: pathAdmin.adminManageRecruiter.path,
          role: [ADMIN_ROLE.SUPPER_ADMIN],
        },
        {
          name: "Quản lý thanh toán",
          href: pathAdmin.adminPaymentBoard.path,
          role: [ADMIN_ROLE.SUPPER_ADMIN],
        },
        {
          name: "Quản lý công ty",
          href: pathAdmin.adminManageCompany.path,
          role: [ADMIN_ROLE.SUPPER_ADMIN],
        },
        {
          name: "Quản lý cv mẫu",
          href: pathAdmin.adminTemplateCV.path,
          role: [ADMIN_ROLE.SUPPER_ADMIN],
        },
      ],
    },
    {
      name: "CV",
      role: [ADMIN_ROLE.SUPPER_ADMIN],
      sub_data: [
        {
          name: "Quản lý CV",
          href: pathAdmin.adminManagemenCV.path,
          role: [ADMIN_ROLE.SUPPER_ADMIN],
        },
        {
          name: "Nhập CV",
          href: pathAdmin.adminParsingCV.path,
          role: [ADMIN_ROLE.SUPPER_ADMIN],
        },
        {
          name: "Tìm kiếm CV",
          href: pathAdmin.adminFindCv.path,
          role: [ADMIN_ROLE.SUPPER_ADMIN],
        },
      ],
    },
    {
      name: "Nhân sự",
      href: pathAdmin.adminManagePersonal.path,
      role: [ADMIN_ROLE.SUPPER_ADMIN],
    },
    {
      name: "Quảng cáo",
      href: pathAdmin.adminAdsManagement.path,
      role: [ADMIN_ROLE.SUPPER_ADMIN, ADMIN_ROLE.ADS_MANAGER],
    },
    {
      name: "Bài tuyển dụng",
      href: pathAdmin.adminPostManagement.path,
      role: [ADMIN_ROLE.POST_MANAGER],
    },
    {
      name: "Quản lý tin tức",
      href: pathAdmin.adminManagerNews.path,
      role: [ADMIN_ROLE.SUPPER_ADMIN, ADMIN_ROLE.POST_MANAGER],
    },
    {
      name: "Tham số",
      href: pathAdmin.adminParameter.path,
      role: [ADMIN_ROLE.SUPPER_ADMIN],
    },
  ];

  const isAdmin = () => {
    const pathname = location.pathname.split("/");
    return {
      admin: pathname[1] === "admin",
      data:
        pathname[1] === "admin"
          ? menuAdmin.filter((item) => item.role.includes(admin?.sub_role))
          : pathname[1] === "recruiter"
          ? menuRecruiter
          : menu,
    };
  };

  const showLoginMobile = () => {
    return (
      <div>
        <div className={`cursor-pointer`}>
          <p
            className="text-[14px] text-white px-3 py-2"
            onClick={() => setActiveMobile(!activeMobile ? "login" : "")}
          >
            {t("Login")} <DownOutlined style={{ fontSize: "11px" }} />
          </p>
          <div className={`${activeMobile === "login" ? "block" : "hidden"}`}>
            <p
              className="text-[14px] text-white px-3 py-2"
              onClick={() => {
                setActiveMobile("");
                history.push(routes.loginPage.path + "?role=Candidate");
                setToggleMenuMobile(false);
              }}
            >
              Đăng nhập ứng viên
            </p>
            <p
              className="text-[14px] text-white px-3 py-2"
              onClick={() => {
                setActiveMobile("");
                history.push(routes.loginPage.path + "?role=Recruiter");
                setToggleMenuMobile(false);
              }}
            >
              Đăng nhập nhà tuyển dụng
            </p>
          </div>
        </div>
        <div className={`cursor-pointer`}>
          <p
            className="text-[14px] text-white px-3 py-2"
            onClick={() => setActiveMobile(!activeMobile ? "register" : "")}
          >
            {t("signup")} <DownOutlined style={{ fontSize: "11px" }} />
          </p>
          <div
            className={`${activeMobile === "register" ? "block" : "hidden"}`}
          >
            <p
              className="text-[14px] text-white px-3 py-2"
              onClick={() => {
                setActiveMobile("");
                history.push(routes.signupCandidate.path, {
                  role: "Candidate",
                });
                setToggleMenuMobile(false);
              }}
            >
              Đăng ký ứng viên
            </p>
            <p
              className="text-[14px] text-white px-3 py-2"
              onClick={() => {
                setActiveMobile("");
                history.push(routes.signupRecruiter.path, {
                  role: "Recruiter",
                });
                setToggleMenuMobile(false);
              }}
            >
              Đăng nhập nhà tuyển dụng
            </p>
          </div>
        </div>
      </div>
    );
  };

  const getCheckActive = (item) => {
    const path = getRootPath(location.pathname);
    const path2 = getRootPath(location.pathname, 2);
    if (path === "admin" || path === "recruiter") {
      return `/${path}/${path2}` === item;
    }
    return `/${path}` === item;
  };

  return (
    <header
      className={cx(
        `w-full fixed top-0 left-0 z-9999`,
        theme?.menu?.gradient,
        theme?.menu?.border,
        location.pathname === routes.login.path && "hidden"
      )}
    >
      <nav className="grid relative grid-cols-4 md:grid-cols-10 px-4 py-4 mx-auto h-auto lg:max-w-[1366px]">
        <div
          className="col-span-3 md:col-span-2 w-[150px] md:w-[210px] h-[40px] flex justify-center items-center cursor-pointer"
          onClick={() => {
            getLinkHome() && history.push(getLinkHome());
            setToggleMenuMobile(false);
          }}
          onMouseMove={() => setActiveSubMenu("")}
        >
          <img
            src={"/asserts/logo.png"}
            className="object-cover max-w-full max-h-full"
            alt=""
          />
        </div>
        <div className="hidden md:col-span-8 md:flex md:justify-between md:items-center">
          <div className="mt-2 ml-3 flex space-x-4">
            {isAdmin()?.data.map((item, index) => (
              <div key={"menu" + index + "parent"} className="relative">
                {item?.url ? (
                  <a
                    href={item.url}
                    className={cx(
                      `font-[600] text-white border-b-2 border-transparent cursor-pointer px-3 py-1 rounded-[14px]`,
                      theme?.menu?.item,
                      getCheckActive(item.href) && theme?.menu?.active
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.name} {item.icon && item.icon}
                  </a>
                ) : (
                  <p
                    className={cx(
                      `font-[600] text-white border-b-2 border-transparent cursor-pointer px-3 py-1 rounded-[14px]`,
                      theme?.menu?.item,
                      getCheckActive(item.href) && theme?.menu?.active
                    )}
                    onClick={() => item.href && history.push(item.href)}
                    onMouseMove={() => setActiveSubMenu(item.name)}
                  >
                    {item.name} {item.icon && item.icon}
                  </p>
                )}

                {!item.href && item.name === activeSubMenu && (
                  <>
                    <div
                      className={cx(
                        "absolute top-[50px] -left-[80px] w-[450px] min-h-[250px] bg-white cursor-pointer opacity-0"
                      )}
                      onMouseMove={() => setActiveSubMenu("")}
                    ></div>
                    <div
                      className={cx(
                        "absolute top-[50px] left-0 w-[300px] min-h-[150px] bg-white cursor-pointer z-9999",
                        theme?.menu?.gradient
                      )}
                    >
                      {item.sub_data.map((sub, index) => (
                        <p
                          key={sub?.name + "menu_sub" + index}
                          className={cx(
                            "px-4 py-2 text-white border-b-[1px]",
                            theme?.menu?.sub_active
                          )}
                          onClick={() => {
                            sub.href && history.push(sub.href);
                            setActiveSubMenu("");
                          }}
                        >
                          {sub?.name}
                        </p>
                      ))}
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>

          <div className="w-auto h-full flex flex-row items-center space-x-2">
            <SwitchMode />
            {status && !isAdmin().admin ? (
              <HeaderSuccess />
            ) : (
              <>
                {!isAdmin().admin ? (
                  <>
                    {[
                      {
                        title: t("Login"),
                        items: items1,
                      },
                      {
                        title: t("signup"),
                        items: items2,
                      },
                    ].map((c, index) => (
                      <Dropdown
                        key={"dropdown" + index + "items"}
                        menu={{ items: c.items }}
                        trigger={["click"]}
                        overlayStyle={{ zIndex: 99999 }}
                      >
                        <Bt
                          title={c.title}
                          onClick={(e) => e.preventDefault()}
                          className="font-[500] text-black bg-white rounded-[10px]"
                        />
                      </Dropdown>
                    ))}
                  </>
                ) : (
                  <AdminLoginSuccess />
                )}
              </>
            )}
          </div>
        </div>
        <div className="col-span-1 flex justify-end items-center space-x-2 md:hidden">
          <SwitchMode />
          {status && (
            <div className="flex justify-center items-center space-x-2">
              <Notification />
              <Bt
                icon={<SettingOutlined />}
                className="text-white shadow-none"
                onClick={handleChangeSetting}
              />
              <Avatar
                src="https://imagekit.io/blog/content/images/2019/12/image-optimization.jpg"
                size="small"
              />
            </div>
          )}
          <div
            className="w-6 h-6"
            onClick={() => setToggleMenuMobile(!toggleMenuMobile)}
          >
            {!toggleMenuMobile ? (
              <MoreOutlined
                style={{ fontSize: "22px", fontWeight: 600, color: "white" }}
              />
            ) : (
              <CloseOutlined
                style={{ fontSize: "22px", fontWeight: 600, color: "white" }}
              />
            )}
          </div>
        </div>
      </nav>
      <div
        className={cx(
          `absolute left-0 top-[56px] md:hidden w-full h-[100vh]`,
          toggleMenuMobile ? "block" : "hidden"
        )}
        onClick={() => setToggleMenuMobile(!toggleMenuMobile)}
      ></div>
      <div
        className={cx(
          `absolute left-0 top-[56px] md:hidden w-full pb-8 bg-gradient-to-r`,
          theme?.menu?.from,
          theme?.menu?.to,
          toggleMenuMobile ? "block" : "hidden"
        )}
      >
        {isAdmin()?.data.map((item, index) => (
          <div
            key={item.name + "sub" + index}
            className={`cursor-pointer text-[14px] text-white px-3 py-2`}
            onClick={() => {
              item.href && history.push(item.href);
              setToggleMenuMobile(false);
            }}
          >
            {item.name} {item.icon && item.icon}
          </div>
        ))}
        {status ? (
          <div
            className={`cursor-pointer text-[14px] text-white px-3 py-2`}
            onClick={handleClickLogout}
          >
            Đăng Xuất
          </div>
        ) : (
          showLoginMobile()
        )}
      </div>
    </header>
  );
}

const SwitchMode = () => {
  const { setMode, mode } = useTheme();
  return (
    <div>
      <label className="space-x-2 cursor-pointer">
        <div className="relative">
          <input
            type="checkbox"
            className="hidden"
            checked={mode === "dark_mode"}
            onChange={() =>
              setMode(mode === "dark_mode" ? "light_mode" : "dark_mode")
            }
          />
          <div
            className={cx(
              "w-[40px] h-[24px] rounded-full shadow-inner",
              mode === "dark_mode" && "bg-white"
            )}
          ></div>
          <div
            className={cx(
              "absolute w-[40px] -top-[2px] left-0",
              mode === "dark_mode"
                ? "left-[19px]"
                : "left-0 bg-[#0b4d86] rounded-full"
            )}
          >
            {mode === "dark_mode" ? (
              <Tooltip title="Dark Mode" zIndex={99999}>
                <ModeNight style={{ fontSize: "24px" }} />
              </Tooltip>
            ) : (
              <Tooltip title="Light Mode" zIndex={99999} color="#6F2F66">
                <Circle style={{ color: "white", fontSize: "24px" }} />
              </Tooltip>
            )}
          </div>
        </div>
      </label>
    </div>
  );
};

export default Header;
