import React from "react";
import HelpDecriptionComponent from "../../components/SubComponent/HelpDecriptionComponent";
import LeftComponent from "./LeftComponent";
import RightComponent from "./RightComponent";
import { useAppSelector } from "../../redux/hooks";
import { selectSetting } from "../../redux/slice/setting";
import NamePositionBlock from "../../components/Block/NamePositionBlock";

function ContentComponent() {
  const setting = useAppSelector(selectSetting);
  return (
    <div className="px-[10px] md:px-0 flex space-x-4">
      <form
        style={{
          width: "793px",
          minHeight: "1112px",
          background: "white",
          position: "relative",
          fontFamily: `${setting.fontFamily}`,
          fontSize: `${setting.fontSize}`,
        }}
        className="shadow-md"
      >
        <div className="w-full h-[112px] bg-[#7C7A79] px-4 py-3">
          <NamePositionBlock
            headCss={`text-end text-white`}
            childCss="text-end text-white italic"
          />
        </div>
        <div className="flex">
          <div className="w-[528px] min-h-[1000px] p-3">
            <LeftComponent />
          </div>
          <div
            className="w-[265px] min-h-[1000px] pb-4"
            style={{
              background: `${setting.themeColor}`,
            }}
          >
            <RightComponent />
          </div>
        </div>
      </form>
      <HelpDecriptionComponent />
    </div>
  );
}

export default ContentComponent;
