import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { pathAdmin } from "../../screens/PathRouting";
import CardEmpty from "../components/Card/CardEmpty";
import CardCompany from "../components/Card/CardCompany";
import AvatarItem from "../components/Avatar/AvatarItem";
import CreateGroupModal from "../components/Modal/CreateGroupModal";
import BreadcrumbAdmin from "../components/Tag/Breadcrumb";
import AdminService from "../../services/AdminService";
import queryString from "query-string";
import { UserAddOutlined } from "@ant-design/icons";
import CreateUserModal from "../components/Modal/CreateUserModal";
import { Pagination } from "antd";
import SearchUser from "./SearchUser";
import UserService from "../../services/UserService";
import dayjs from "dayjs";
import { clearEmptyItem, getImageUrl } from "../../utils";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import { changePagination } from "../../lib/theme/config";

function PersonalManagement() {
  const { theme } = useTheme();
  const [listGroups, setListGroups] = useState();
  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [listMember, setListMember] = useState();
  const history = useHistory();
  const location = useLocation();
  const [totalPage, setTotalPage] = useState(1);

  const searchOld = queryString.parse(location.search);

  const limit = 23;

  useEffect(() => {
    if (!listGroups) {
      getAllListGroup();
      getListMember();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listGroups]);

  const getAllListGroup = async () => {
    const listGroups = await AdminService.getAllGroup(
      queryString.stringify({
        offset: 0,
        limit: 3,
      })
    );

    const data = listGroups.data.data.filter(
      (item, index) => index > 0 && item
    );
    const result = data.map((item) => ({
      id: item.id,
      groupName: item.name,
      numberEmployee: item.countRecruiters,
      job: item.projectCompany.map((item) => item.name).join(", "),
      countJds: item.countJds,
      countCandidate: item.countCVs,
    }));
    setListGroups(result);
  };

  const getListMember = async (value) => {
    const search = queryString.parse(location.search);
    const page = search?.page || 1;
    delete search?.page;
    const values = value || search;
    const params = clearEmptyItem(
      {
        ...values,
        offset: page - 1,
        limit: limit,
      },
      "Tất cả"
    );

    const response = await AdminService.getAllUser(
      `?${queryString.stringify(params)}`
    );
    const data = response.data.data.filter((item) => !item.countTotal);
    const listImage = await UserService.getImageByName(
      data.map((item) => item.avatarImg[0])
    );
    const result = data.map((item) => ({
      id: item.id,
      avatar: getImageUrl(listImage.data.data, item.avatarImg[0]),
      name: item.fullName,
      joinDate: dayjs(new Date(item.createAt)).format("DD/MM/YYYY"),
    }));
    setListMember(result);
    const total = response.data.data.find((item) => item.countTotal);
    setTotalPage(total?.countTotal || 10);
  };

  return (
    <div
      className={cx(
        "mx-auto min-h-[80vh] lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <div className="px-4">
        <BreadcrumbAdmin data={[{ name: "Quản lý nhân sự" }]} />

        <div
          className={cx(
            "w-full flex justify-between items-center my-4",
            theme?.background?.text
          )}
        >
          <p className="text-[20px] font-[600]">Các nhóm nhân sự đã tạo</p>
          <p
            className="cursor-pointer hover:underline"
            onClick={() => history.push(pathAdmin.adminGroupList.path)}
          >
            Xem tất cả &gt;
          </p>
        </div>
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          <CardEmpty
            icon="/icons/folderadd.png"
            title="Thêm nhóm"
            onClick={() => setOpenCreatePopup(!openCreatePopup)}
          />
          {listGroups &&
            listGroups.length > 0 &&
            listGroups.map((item, index) => (
              <CardCompany
                index={index}
                type={1}
                data={item}
                onHandleClick={() =>
                  history.push(
                    pathAdmin.adminGroupDetail.paretPath + `/${item.id}`
                  )
                }
              />
            ))}
        </div>

        <div className="w-full flex justify-start items-center my-8">
          <p className={cx("text-[20px] font-[600]", theme?.background?.text)}>
            Danh sách nhân sự
          </p>
        </div>

        <div className="mb-4">
          <SearchUser onChangeValue={(value) => getListMember(value)} />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <div
            className="w-full px-4 py-5 bg-[#B5C1FF] rounded-[12px] flex justify-center items-center cursor-pointer"
            onClick={() => setOpenCreateUser(!openCreateUser)}
          >
            <UserAddOutlined />
            <p className="text-black ml-3">Thêm nhân sự</p>
          </div>
          {listMember &&
            listMember.length > 0 &&
            listMember.map((item) => <AvatarItem data={item} />)}
        </div>
      </div>
      <div className="w-full flex justify-center items-center mt-[40px]">
        <Pagination
          defaultCurrent={searchOld?.page}
          total={totalPage}
          pageSize={limit}
          itemRender={changePagination}
        />
      </div>
      <CreateGroupModal
        active={openCreatePopup}
        onChangeActive={() => {
          getAllListGroup();
          setOpenCreatePopup(!openCreatePopup);
        }}
      />
      <CreateUserModal
        active={openCreateUser}
        onChangeActive={() => setOpenCreateUser(!openCreateUser)}
      />
    </div>
  );
}

export default PersonalManagement;
