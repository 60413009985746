import dayjs from "dayjs";
import React from "react";
import { useHistory } from "react-router-dom";
import { routes } from "../../PathRouting";
import { useTheme } from "../../../lib/theme/useTheme";
import { cx } from "../../../lib/cx";

function CardNewsFirst({ data }) {
  const { theme } = useTheme();
  const history = useHistory();
  return (
    <div className="w-full min-h-[100px] pb-[10px] border-b-[1px] border-b-gray">
      <div className="w-full h-[260px]">
        <img
          src={data?.image || "/images/background.png"}
          alt=""
          className="w-full h-full object-cover rounded-md"
        />
      </div>
      <div className="w-full">
        <p
          className={cx(
            "text-[22px] hover:underline cursor-pointer",
            theme?.background?.text
          )}
          onClick={() =>
            history.push(routes.newsDetail.parentPath + `/${data.slug}`)
          }
        >
          {data?.title}
        </p>
        <div className="md:flex md:justify-start md:items-center md:space-x-4 mt-2">
          <p className="text-[14px] text-gray font-[800]">{data?.tag[0]}</p>
          <p className="text-[14px] text-gray">|</p>
          <p className="text-[14px] text-gray">
            {data?.createAt && dayjs(data.createAt).format("DD-MM-YYYY HH:MM")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default CardNewsFirst;
