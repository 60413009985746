import { Welfare1, Welfare3, Welfare5 } from "./CardInfor";
import { Stack } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { useRef } from "react";

const manpowerbank1 = "/assets/Manpowerbank1.png";

export const Flick = (props) => {
  const swiperRef = useRef(null);

  const handleMouseEnter = () => {
    swiperRef.current.swiper.autoplay.stop();
  };

  const handleMouseLeave = () => {
    swiperRef.current.swiper.autoplay.start();
  };

  const shuffledListCompany = props.data;

  return (
    <Stack spacing={2} height={"100%"} style={{ cursor: "pointer" }}>
      <Swiper
        ref={swiperRef}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
          },
        }}
        spaceBetween={10}
        modules={[Autoplay, Pagination, Navigation]}
        style={{ width: "100%" }}
        speed={500}
      >
        {shuffledListCompany.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <Welfare1
                icon={item?.image || manpowerbank1}
                title={`${item.count} vị trí đang tuyển`}
                onClick={() => props.onClick(item && item.recruiterId, item)}
                onMouseEnter={() => {
                  handleMouseEnter();
                }}
                onMouseLeave={() => {
                  handleMouseLeave();
                }}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Stack>
  );
};

export const Flick1 = (props) => {
  const swiperRef = useRef(null);

  const handleMouseEnter = () => {
    swiperRef.current.swiper.autoplay.stop();
  };

  const handleMouseLeave = () => {
    swiperRef.current.swiper.autoplay.start();
  };

  return (
    <Stack spacing={2} height={"100%"} width={"100%"}>
      <Swiper
        ref={swiperRef}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        spaceBetween={20}
        modules={[Autoplay, Pagination, Navigation]}
        style={{ width: "100%" }}
        slidesPerView={3}
        speed={1000}
      >
        {props.data.map(
          (item, index) =>
            item?.image && (
              <SwiperSlide key={index}>
                <div className="w-full h-[240px]">
                  <Welfare3
                    icon={item.image}
                    onClick={() => props.onClick(item.recruiterId)}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  />
                </div>
              </SwiperSlide>
            )
        )}
      </Swiper>
    </Stack>
  );
};

export const Flick2 = () => {
  const data = [
    {
      icon: "/asserts/cv2.png",
    },
    {
      icon: "/asserts/cv1.png",
    },
    {
      icon: "/asserts/cv3.png",
    },
    {
      icon: "/asserts/cv4.png",
    },
  ];
  return (
    <Stack spacing={2} height={"100%"}>
      <Swiper
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 2,
          },
        }}
        spaceBetween={10}
        modules={[Autoplay, Pagination, Navigation]}
        style={{ width: "100%" }}
        slidesPerView={2}
        speed={1000}
      >
        {data.map((item, index) => (
          <SwiperSlide key={index}>
            <Welfare5 icon={item.icon} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Stack>
  );
};
