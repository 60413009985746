import React, { useEffect, useState } from "react";
import { cx } from "../../lib/cx";
import { useTheme } from "../../lib/theme/useTheme";
import { useAuth } from "../Auth/useAuth";
import UserService from "../../services/UserService";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { Pagination } from "antd";
import { changePagination } from "../../lib/theme/config";
import RecruiterList from "./RecruiterList";
import RecruiterAccept from "./RecruiterAccept";

function ManageRecruiter() {
  const { theme } = useTheme();
  const { admin } = useAuth();

  const location = useLocation();
  const history = useHistory();
  const search = queryString.parse(location.search);

  const [listRecruiter, setListRecruiter] = useState();
  const [listRecruiterNew, setListRecruiterNew] = useState();
  const [listRecruiterValidate, setListRecruiterValidate] = useState();

  const [totalPage, setTotalPage] = useState(0);
  const [totalNewPage, setTotalNewPage] = useState(0);
  const [totalValidatePage, setTotalValidatePage] = useState(0);

  const limit = 25;

  const listMenu = [
    {
      name: `Danh sách khách hàng (${totalPage})`,
      slug: "list-recruiter",
    },
    {
      name: `Phê duyệt tài khoản (${totalNewPage})`,
      slug: "accept-recruiter",
    },
    {
      name: `Phê duyệt nội dung (${totalValidatePage})`,
      slug: "validate-recruiter",
    },
  ];

  useEffect(() => {
    if (!listRecruiter) {
      getAllRecruiter(search?.page || 1);
      getAllRecruiterNew(search?.page || 1);
      getAllRecruiterValidate(search?.page || 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRecruiter]);

  const getAllRecruiter = async (page) => {
    try {
      const params = { offset: page - 1, limit: limit };
      const recruiterActive = await UserService.getListCS(true, params);

      const data = recruiterActive.data.data;
      setListRecruiter(data.items);
      setTotalPage(data.count);
    } catch (error) {
      console.log("error", new Error(error));
    }
  };

  const getAllRecruiterNew = async (page) => {
    try {
      const params = { offset: page - 1, limit: limit };
      const recruiterActive = await UserService.getListCS(false, params);

      const data = recruiterActive.data.data;
      setListRecruiterNew(data.items);
      setTotalNewPage(data.count);
    } catch (error) {
      console.log("error", new Error(error));
    }
  };

  const getAllRecruiterValidate = async (page) => {
    try {
      const params = { offset: page - 1, limit: limit };
      const recruiterActive = await UserService.getRecruiterValidate(
        false,
        params
      );

      const data = recruiterActive.data.data;
      setListRecruiterValidate(data);
      setTotalValidatePage(data.length);
    } catch (error) {
      console.log("error", new Error(error));
    }
  };

  const changeParams = (params) => {
    history.replace(
      location.pathname + `?${queryString.stringify({ ...search, ...params })}`
    );
  };

  const onChangePage = (page) => {
    changeParams({ page: page });
    getAllRecruiter(page);
  };

  return (
    <div
      className={cx(
        "mx-auto min-h-[80vh] lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <div className="px-4 mb-[120px]">
        <div className={cx("my-[50px]", theme?.background?.text)}>
          <p className="text-[32px] font-[600] leading-none">Dashboad</p>
          <p className="text-[26px]">{admin?.nickname}</p>
        </div>

        <div className="w-full flex justify-between items-center my-2">
          <p className={cx("text-[20px] font-[600]", theme?.background?.text)}>
            Quản lý danh mục nhà tuyển dụng
          </p>
          <p className="text-black hover:underline cursor-pointer"></p>
        </div>

        <div className="space-y-1 sm:space-y-0 sm:space-x-2 my-4">
          {listMenu.map((menu, index) => (
            <div
              key={menu.name + index + "menu"}
              className={cx(
                "px-4 py-2 text-white inline-block cursor-pointer rounded-[8px]",
                (search?.action || "list-recruiter") === menu.slug
                  ? "bg-[#333]"
                  : "bg-gray"
              )}
              onClick={() => changeParams({ action: menu.slug, page: 1 })}
            >
              {menu.name}
            </div>
          ))}
        </div>

        <div className="mt-[10px] bg-[#F2F7FF]">
          <div className="overflow-x-auto pb-[10px]">
            {(search?.action || "list-recruiter") === "list-recruiter" && (
              <RecruiterList
                data={listRecruiter}
                search={search}
                limit={limit}
              />
            )}
            {search?.action === "accept-recruiter" && (
              <RecruiterAccept
                data={listRecruiterNew}
                search={search}
                limit={limit}
              />
            )}
            {search?.action === "validate-recruiter" && (
              <RecruiterAccept
                data={listRecruiterValidate}
                search={search}
                limit={limit}
                type
              />
            )}
          </div>
        </div>
        <div className="w-full my-4 flex justify-center items-center">
          <Pagination
            defaultCurrent={search?.page ? Number(search?.page) : 1}
            total={totalPage}
            pageSize={limit}
            onChange={onChangePage}
            itemRender={changePagination}
          />
        </div>
      </div>
    </div>
  );
}

export default ManageRecruiter;
