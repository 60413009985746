import { cx } from "../../lib/cx";
import { Tooltip } from "./Tootip";

const isAnchor = (props) => {
  return "href" in props;
};

export const Button = (props) => {
  if (isAnchor(props)) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...props} />;
  } else {
    return <button type="button" {...props} />;
  }
};

export const PrimaryButton = ({ className, ...props }) => (
  <Button className={cx("btn-primary", className)} {...props} />
);

const IconButton = ({ className, size = "medium", tooltipText, ...props }) => (
  <Tooltip text={tooltipText}>
    <Button
      type="button"
      className={cx(
        "rounded-full outline-none hover:bg-gray-100 focus-visible:bg-gray-100",
        size === "medium" ? "p-1.5" : "p-1",
        className
      )}
      {...props}
    />
  </Tooltip>
);

export default IconButton;
