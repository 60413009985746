import { CameraOutlined } from "@ant-design/icons";
import { DatePicker, Select, notification } from "antd";
import React, { useEffect, useState } from "react";
import AdminService from "../../../services/AdminService";
import { createAdsSchema } from "../../../utils/validations";
import { useFormik } from "formik";
import { useAuth } from "../../Auth/useAuth";
import dayjs from "dayjs";
import { cx } from "../../../lib/cx";
import queryString from "query-string";
import { clearEmptyItem, getImageUrl, renderDefaultItem } from "../../../utils";
import UserService from "../../../services/UserService";

const { RangePicker } = DatePicker;

function CreateAdsModal({ id, active, onChangeActive }) {
  const { admin } = useAuth();
  const [avatar, setAvatar] = useState("");
  const [checkAvatar, setCheckAvatar] = useState(false);
  const [listRecruiter, setListRecruiter] = useState();
  const [adsUpdate, setAdsUpdate] = useState();

  const [api, contextHolder] = notification.useNotification({ top: 80 });;

  const createAdsFormik = useFormik({
    initialValues: {
      startTime: "",
      endTime: "",
      recruiterId: "",
      position: "",
    },
    validationSchema: createAdsSchema,
    onSubmit: (values) => {
      if (!avatar && !adsUpdate?.avatar) {
        setCheckAvatar(true);
        return;
      }
      const data = id
        ? values
        : {
            ...values,
            ownerId: admin?.id,
            role: admin?.sub_role,
          };
      if (id) {
        delete data.recruiterId;
      }
      createAds(data);
    },
  });

  useEffect(() => {
    if (id && !adsUpdate) {
      getAdsDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, adsUpdate]);

  useEffect(() => {
    if (!listRecruiter) {
      getListMember();
    }
  }, [listRecruiter]);

  const getAdsDetail = async () => {
    const response = await AdminService.getAdsById(id);
    let data = response.data.data;
    const newlyUpdateData = data.newlyUpdateData;

    if (newlyUpdateData.length > 0) {
      data = newlyUpdateData[0];
    }
    const image = await UserService.getImageByName([data.image]);
    const result = {
      ...data,
      avatar: getImageUrl(image.data.data, data.image),
    };
    getOldData(result);
    setAdsUpdate(result);
  };

  const getOldData = (data) => {
    changeValueSelect("startTime", data?.startTime || "");
    changeValueSelect("endTime", data?.endTime || "");
    changeValueSelect("position", data?.position || "");
    changeValueSelect("recruiterId", data?.recruiterId || "");
  };

  const createAds = async (values) => {
    try {
      let uploadImage = "";
      let newData = { ...values };

      if (id && adsUpdate?.avatar && !avatar) {
        newData = { ...newData, image: adsUpdate.image };
      }
      if (avatar) {
        const formData = new FormData();
        formData.append("files", avatar);
        uploadImage = await AdminService.uploadImageAds(admin.id, formData);
        newData = { ...newData, image: uploadImage.data.data.message[0] };
      }
      const result = id
        ? await AdminService.updateAds(id, newData)
        : await AdminService.createAds(newData);
      if (result) {
        api.success({
          message: "Thành công",
          description: `${
            id ? "Cập nhật" : "Thêm mới"
          } quảng cáo thành công! Vui lòng đợi admin phê duyệt`,
          style: { top: 50 },
        });
        onChangeActive();
      }
    } catch (error) {
      api.error({
        message: "Thất bại",
        description: `${id ? "Cập nhật" : "Thêm mới"} quảng cáo  thất bại`,
        style: { top: 50 },
      });
    }
  };

  const getListMember = async (value) => {
    const values = value;
    const params = clearEmptyItem(
      {
        ...values,
        offset: 0,
        limit: 20,
      },
      "Tất cả"
    );

    const response = await AdminService.getAllUser(
      `?${queryString.stringify(params)}`
    );
    const data = response.data.data.filter((item) => !item.countTotal);
    const result = data.map((item) => ({
      value: item.id,
      label: item.enterprise,
    }));
    setListRecruiter(result);
  };

  const changeValueSelect = (name, value) => {
    createAdsFormik.handleChange({
      target: {
        name: name,
        value: value,
      },
    });
  };

  const formatDate = (date) => {
    if (!date) return "";
    return dayjs(date, "YYYY-MM-DD");
  };

  const uploadAvatar = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setAvatar(e.target.files[0]);
    }
    setCheckAvatar(false);
  };

  const getMessageError = (field) => {
    return createAdsFormik.errors[field] && createAdsFormik.touched[field]
      ? createAdsFormik.errors[field]
      : "";
  };

  return (
    <div
      className={`overflow-x-auto md:overflow-hidden fixed inset-0 z-9999 h-screen outline-none focus:outline-none justify-center items-center c-modal bg-black bg-opacity-20 ${
        active ? "block" : "hidden"
      }`}
    >
      {contextHolder}
      <div className="relative flex justify-center items-center mx-auto w-full px-[10px] md:px-0 h-[100vh] select-none">
        <div className="border-0 relative flex flex-col w-full bg-[#F2F7FF] outline-none focus:outline-none sm:w-[600px] rounded-[12px] p-[10px]">
          <p className="text-black text-[22px] text-center font-[600]">
            {id ? "Cập nhật" : "Thêm mới"} quảng cáo
          </p>
          <div
            className={`w-full h-[120px] rounded-tl-[12px] rounded-tr-[12px] bg-[#1495F3] relative cursor-pointer`}
          >
            <input
              type="file"
              id="upload"
              name="upload"
              onChange={uploadAvatar}
              accept="image/*"
              hidden
            />
            <div
              className={`w-full sm:w-[500px] h-[240px] bg-[#F5F7FF] shadow-md absolute left-0 sm:left-10 -bottom-[120%] flex justify-center items-center ${
                checkAvatar && "border border-red-400"
              }`}
            >
              {avatar && (
                <img
                  src={URL.createObjectURL(avatar)}
                  alt=""
                  className="max-w-full max-h-full object-cover border-none"
                />
              )}
              {adsUpdate?.avatar && (
                <img
                  src={adsUpdate?.avatar}
                  alt=""
                  className="max-w-full max-h-full object-cover border-none"
                />
              )}
            </div>

            <div
              className="w-full sm:w-[500px] h-[240px] border-none opacity-60 absolute left-0 sm:left-10 -bottom-[120%] flex justify-center items-center"
              onClick={() => document.getElementById("upload")?.click()}
            >
              <div className="w-[48px] h-[48px] rounded-[50%] bg-[#B5C1FF] flex justify-center items-center">
                <CameraOutlined className="text-[24px]" />
              </div>
            </div>
          </div>
          <div className="w-full h-[380px] pt-[160px]">
            <p className="font-[600]">Chọn nhà tuyển dụng</p>
            <div>
              <Select
                className={cx("w-full mb-4")}
                showSearch
                allowClear
                value={createAdsFormik.values.recruiterId}
                optionLabelProp="label"
                placeholder="Chọn nhà tuyển dụng"
                popupClassName="z-[99999]"
                onChange={(value) => changeValueSelect("recruiterId", value)}
                rootClassName={cx(
                  getMessageError("recruiterId") &&
                    "border border-red-500 rounded-[6px]"
                )}
                disabled={id}
              >
                {listRecruiter &&
                  listRecruiter.map((recruiter, index) => (
                    <Select.Option
                      key={recruiter?.label + index}
                      value={recruiter?.value}
                      label={recruiter?.label}
                    >
                      {recruiter?.label}
                    </Select.Option>
                  ))}
              </Select>
            </div>
            <p className="font-[600]">Vị trí hiển thị</p>
            <div>
              <Select
                className={cx("w-full mb-4")}
                optionLabelProp="label"
                value={createAdsFormik.values.position}
                placeholder="Chọn vị trí hiển thị"
                popupClassName="z-[99999]"
                onChange={(value) => changeValueSelect("position", value)}
                rootClassName={cx(
                  getMessageError("position") &&
                    "border border-red-500 rounded-[6px]"
                )}
              >
                {renderDefaultItem(4)
                  .map((item) => ({
                    value: String(item + 1),
                    label: item + 1,
                  }))
                  .map((position, index) => (
                    <Select.Option
                      key={position?.label + index}
                      value={position?.value}
                      label={position?.label}
                    >
                      {position?.label}
                    </Select.Option>
                  ))}
              </Select>
            </div>
            <p className="font-[600]">Thời gian hiển thị</p>
            <div
              className={cx(
                getMessageError("startTime") || getMessageError("endTime")
                  ? "border-b-[1px] border-b-red-500"
                  : "border-b-[1px]"
              )}
            >
              <RangePicker
                style={{ width: "100%" }}
                bordered={false}
                onChange={(_, stday) => {
                  changeValueSelect("startTime", stday[0]);
                  changeValueSelect("endTime", stday[1]);
                }}
                className="mb-1 pb-0"
                format={"YYYY-MM-DD"}
                value={[
                  formatDate(createAdsFormik.values.startTime),
                  formatDate(createAdsFormik.values.endTime),
                ]}
                popupClassName="z-[99999]"
                placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
              />
            </div>
          </div>
          <div className="w-full flex justify-end items-center mt-4 space-x-2">
            <div
              className="px-9 py-2 bg-[#ccc] text-black rounded-[12px] text-[12px] cursor-pointer"
              onClick={onChangeActive}
            >
              Thoát
            </div>
            <div
              className="px-9 py-2 bg-[#1495F3] text-white rounded-[12px] text-[12px] cursor-pointer"
              onClick={() => {
                createAdsFormik.handleSubmit();
              }}
            >
              Lưu quảng cáo
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateAdsModal;
