import React, { useState } from "react";
import { content } from "./ContentAboutUs";
import { useHistory } from "react-router-dom";
import { routes } from "../PathRouting";
import { DetailHistoryModal } from "../../components/AboutUs";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import HeaderSeo from "../../components/Header/HeaderSeo";
import { useLocalStorage } from "../../lib/useLocalStorage";

export const partner = [
  {
    name: "Indec",
    website: "https://indec.vn/",
  },
  {
    name: "So' Natural",
    website: "https://sonatural.vn/",
  },
  {
    name: "Hưng Thịnh",
    website: "https://sieuthida.vn/",
  },
  {
    name: "APP",
    website: "http://app.com.vn/",
  },
  {
    name: "CS GROUP",
    website: "https://creativespace.vn/",
  },
  {
    name: "MRD",
    website: "https://mrdconnect.vn/ve-mrd/",
  },
  {
    name: "HP MED",
    website: "https://hpmed.vn/",
  },
  {
    name: "EG English",
    website: "https://eglanguage.com/",
  },
  {
    name: "Supitech",
    website: "http://www.supitec.vn/",
  },
  {
    name: "Tima",
    website: "https://tima.vn/",
  },
];

function AboutUs() {
  const { theme } = useTheme();
  const [open, setOpen] = useState(false);
  const [context, setContext] = useState();
  const [token] = useLocalStorage("token", null);
  const history = useHistory();
  return (
    <>
      <HeaderSeo
        title="Giới thiệu về LDO một phần trong hệ sinh thái Trí Tuệ Việt(VietClever Group)"
        description="Lao động online - Xây dựng đội ngũ tài năng cho tương lai của công ty"
      />
      <div
        className={cx(
          "w-full overflow-hidden",
          token && "mx-auto lg:!max-w-[1175px] z-1"
        )}
      >
        <section className="blur-0">
          <div className="mx-auto w-full lg:max-w-[1170px]">
            <div className="w-full lg:w-[700px] mt-[80px] my-[250px] px-4">
              <h4 className="font-[600] text-[#8D9092] text-[22px]">
                Giới thiệu
              </h4>
              <h3 className="text-[32px] lg:text-[50px] font-[600] text-[#1495F3]">
                LDO
              </h3>
              <h3
                className={cx(
                  "text-[32px] lg:text-[50px] font-[600]",
                  theme?.background?.text
                )}
              >
                Xây dựng đội ngũ tài năng cho tương lai của công ty
              </h3>
            </div>
          </div>
          <img
            src={"/GIF/1.gif"}
            className="w-full h-[1700px] absolute -top-[150%] -right-[40%] blur-[20px] -z-[1]"
            alt=""
          />
        </section>

        <section
          className="backdrop-blur-[90px] blur-0 py-[100px] z-9999 bg-[]"
          style={{
            background: "rgba(255, 255, 255, 0.36)",
            boxShadow:
              "-88px 88px 88px 0px rgba(255, 255, 255, 0.15) inset, 88px -88px 88px 0px rgba(194, 194, 194, 0.15) inset, 0px 36px 64px 0px rgba(51, 65, 85, 0.25)",
          }}
        >
          <div className="mx-auto w-full lg:max-w-[1170px] px-4">
            <div
              className={cx(
                "w-full grid grid-cols-1 lg:grid-cols-2 gap-4",
                theme?.background?.text
              )}
            >
              <div className={cx("", theme?.background?.text)}>
                <h2 className="font-[600] text-[36px] text-justify">
                  Về VietClever Group
                </h2>
                <p className="font-[400] text-[#8D9092] text-[20px]">
                  Lịnh sử hình thành
                </p>
                <p className="font-[400] text-[20px] text-justify">
                  Trong hệ sinh thái Trí Tuệ Việt (VietClever Group), LDO đứng
                  vị trí quan trọng như một phần không thể thiếu.
                </p>
                <br />
                <br />
                <p className=" ont-[400] text-[20px] text-justify">
                  Chúng tôi khởi đầu từ ngày 24/05/2004 và đã trờ thành một
                  trong những cái tên tuổi hàng đầu về cung ứng dịch vụ nhân sự
                  quốc tế uy tín tại Việt Nam.
                </p>
              </div>
              <div></div>

              <div></div>
              <div className="py-[30px]">
                <p className=" font-[400] text-[20px] text-justify">
                  Với thị trường chính là Hàn Quốc, Trí Tuệ Việt đã đưa hàng
                  ngàn kỹ sư công nghệ và kỹ thuật cao cấp sang Hàn Quốc để học
                  tập và làm việc, tạo nên nguồn lợi ngoại tệ hàng triệu đô la
                  mỗi năm cho đất nước. Điều này đã giúp nâng cao vị thế của
                  Việt Nam trên bản đồ công nghiệp quốc tế.
                </p>
              </div>

              <div>
                <p className="font-[400] text-[#8D9092] text-[20px] text-justify py-[20px]">
                  Quá trình phát triển
                </p>
                <p className=" font-[400] text-[20px] text-justify">
                  Ngày 11/6/2010, công ty cổ phần giải pháp nguồn nhân lực Trí
                  Tuệ Việt được chính thức được thành lập. Trải qua gần 10 năm
                  thành lập, Trí Tuệ Việt đã từng bước khẳng định vị thế và uy
                  tín của mình trong ngành dịch vụ nhân sự và phát triển nguồn
                  nhân lực chất lượng cao trên thị trường quốc tế như Pháp,
                  Nhật, Malaysia,...
                </p>
                <p className=" font-[400] text-[20px] text-justify py-[40px]">
                  Chúng tôi hoạt động trong 7 nhóm ngành chính, bao gồm Công
                  nghệ, Điện tử số, E-Business, Năng lượng Môi trường, Công nghệ
                  Nano, Vật liệu mới và Thiết bị vận tải. Những kỹ sư tài năng
                  được chúng tôi đào tạo và giới thiệu trở về sau này đã đóng
                  góp không nhỏ vào sự phát triển của nền công nghiệp và kỹ
                  thuật trong nước.
                </p>
              </div>
              <div></div>
            </div>
          </div>
          <img
            src={"/GIF/3.gif"}
            className="w-[800px] h-[800px] absolute top-[120%] -left-[10%] blur-[20px] -z-[1]"
            alt=""
          />
        </section>

        <section
          className="backdrop-blur-[90px] blur-0 py-[100px] relative z-50"
          style={{
            background: "rgba(255, 255, 255, 0.36)",
            boxShadow:
              "-88.76667022705078px 88.76667022705078px 88.76667022705078px 0px rgba(255, 255, 255, 0.15) inset, 88.76667022705078px -88.76667022705078px 88.76667022705078px 0px rgba(194, 194, 194, 0.15) inset, 0px 36px 64px 0px rgba(51, 65, 85, 0.25)",
          }}
        >
          <div className="mx-auto w-full lg:max-w-[1170px] px-4">
            <p className="text-[#8D9092] text-[20px] mb-[20px]">
              Thành tích nổi bật
            </p>
          </div>
          <div className="relative w-full">
            <div className="absolute h-[1px] w-full bg-black z-9995 top-[1%]" />
            <div className="overflow-x-scroll pr-[3%]">
              <div className="inline-flex ml-[15%] space-x-6">
                {content.map((item, idx) => {
                  return (
                    <BlockTimeLine
                      key={"content-about" + idx}
                      year={item.year}
                      summary={item.summary}
                      onClick={() => {
                        setContext(item);
                        setOpen(true);
                      }}
                      src={item.img}
                      theme={theme}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </section>

        <div className="mb-[180px] z-9999">
          <div className="backdrop-blur-[20px]">
            <div
              className={cx(
                "grid grid-cols-1 lg:grid-cols-2 mx-auto w-full lg:max-w-[1170px] px-4",
                theme?.background?.text
              )}
            >
              <div className="mt-[120px]">
                <div className="w-[250px] h-[40px]">
                  <img
                    src="/asserts/logo.png"
                    className="max-w-full max-h-full object-cover"
                    alt=""
                  />
                </div>
                <p className="font-[400]  text-[20px] text-justify">
                  Năm 2020, Công ty Cổ phần Giải pháp Nguồn nhân lực Trí Tuệ
                  Việt đã tái xuất trên thị trường lao động trong nước với một
                  bước đột phá. Chúng tôi đã đón đầu sự thay đổi bằng việc tiến
                  hành tái cấu trúc toàn diện hệ thống và bổ nhiệm một dàn lãnh
                  đạo mới, kết hợp sự kế thừa kinh nghiệm từ thế hệ tiền nhiệm
                  và sức mạnh, năng lượng của thế hệ trẻ.
                </p>
                <br />
                <br />
                <p className="font-[400]  text-[20px] text-justify">
                  Trong hành trình này, chúng tôi đã chọn thương hiệu "Lao động
                  online" để đánh dấu sự khởi đầu của một thời kỳ mới. Chúng tôi
                  đã xây dựng doanh nghiệp theo mô hình của một ngân hàng, với
                  sản phẩm chính là các giải pháp nhân sự.
                </p>
              </div>
            </div>

            <div
              className={cx(
                "mx-auto w-full lg:max-w-[1170px] px-4",
                theme?.background?.text
              )}
            >
              <div className="mt-[100px] mb-[20px]">
                <p className="font-[400] text-[#8D9092] text-[20px]">
                  Triết lý làm việc
                </p>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-5 lg:gap-4">
                <div className="col-span-3 my-4">
                  <p className="font-[400]  text-[20px] text-justify">
                    Với sứ mệnh là tạo ra môi trường kết nối nguồn nhân lực tốt
                    nhất với doanh nghiệp, đồng thời định hình tương lai cho
                    nhân sự và giúp họ phát triển toàn diện, LDO đã trở lại vào
                    năm 2020. Trải qua những biến động lớn của thị trường cùng
                    việc tái cấu trúc hệ thống, LDO xây dựng tầm nhìn, chiến
                    lược định hướng mới. <br /> <br />
                    Với niềm tin rằng con người là tài sản quý giá nhất của một
                    doanh nghiệp, chúng tôi hứa hẹn sẽ cung cấp nhân lực hoàn
                    hảo cho các doanh nghiệp cũng như việc làm tốt cho người lao
                    động không chỉ trong và ngoài nước.
                  </p>
                </div>
                <div className="col-span-1 lg:col-span-2">
                  <div className="w-full h-[300px]">
                    <img
                      src="/asserts/MPB Logo-05.jpg"
                      className="w-full max-h-full object-cover rounded-[12px]"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <p className="font-[400]  text-[20px] text-justify">
                  Dù doanh nghiệp có chiến lược hoành tráng, nguồn lực tài chính
                  tốt mà không có người thi hành thì sẽ trở nên vô nghĩa. Vì
                  thế, nếu bạn đang tìm kiếm đối tác đáng tin cậy để tối ưu hóa
                  nguồn nhân lực và nâng cao hiệu quả hoạt động kinh doanh, hãy
                  đến với LDO. Chúng tôi sẽ cùng bạn xây dựng thành công và vươn
                  tới tương lai viên mãn.
                </p>
              </div>
            </div>

            <div className="mx-auto w-full lg:max-w-[1170px] px-4">
              <div className="flex justify-between items-center mt-[100px]">
                <div className="">
                  <p className="font-[400] text-[#8D9092] text-[20px]">
                    Các đối tác tin cậy
                  </p>
                </div>
                <div
                  className={cx("cursor-pointer", theme?.background?.text)}
                  onClick={() => history.push(routes.listPartnerMPB.path)}
                >
                  Xem tất cả &gt;
                </div>
              </div>

              <div className="grid grid-cols-3 lg:grid-cols-5 gap-4 mt-2">
                {partner.map((item, idx) => {
                  return (
                    <a
                      key={"link" + idx + "item"}
                      href={item.website}
                      target="__blank"
                      className="w-full h-[150px] flex justify-center items-center bg-white p-4 shadow-black"
                    >
                      <img
                        src={`/partner_mpb/partner_${idx + 1}.png`}
                        className="max-w-full max-h-full object-cover"
                        alt=""
                      />
                    </a>
                  );
                })}
              </div>
              <img
                src={"/GIF/2.gif"}
                className="w-[1000px] h-[1000px] absolute -right-[10%] -bottom-[30%] blur-[20px] -z-[1]"
                alt=""
              />
            </div>
          </div>
          <DetailHistoryModal
            open={open}
            onCancel={() => setOpen(!open)}
            content={context}
          />
        </div>
      </div>
    </>
  );
}

const BlockTimeLine = (props) => {
  const { theme } = props;
  return (
    <div
      className="w-[300px] h-fit space-y-3 cursor-pointer"
      onClick={props.onClick}
    >
      <div className="h-[10px] w-[10px] rounded-[50%] bg-black" />

      <div className="">
        <div>
          <p className={cx("font-[400] text-[16px]", theme?.background?.text)}>
            {props.year}
          </p>
        </div>
        <img
          src={props.src}
          className="w-full h-[150px] rounded-[12px]"
          alt=""
        />
        <div>
          <p
            className={cx(
              "font-[400] text-[16px] text-justify",
              theme?.background?.text
            )}
          >
            {props.summary}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
