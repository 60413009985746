import React from "react";

function CardEmpty(props) {
  const { icon, title, onClick, className } = props;
  return (
    <div
      className={`w-full h-[260px] rounded-[26px] bg-[#B5C1FF] cursor-pointer ${className}`}
      onClick={onClick}
    >
      <div className="w-full h-full flex flex-col justify-center items-center">
        <div className="w-[40px] h-[40px] mb-2">
          <img src={icon} alt="" className="w-full h-full object-cover" />
        </div>
        <p className="text-black">{title}</p>
      </div>
    </div>
  );
}

export default CardEmpty;
