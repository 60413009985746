import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { selectSetting } from "../../redux/slice/setting";
import { cx } from "../../../lib/cx";

function InputCv({
  id,
  name,
  size,
  value,
  onChangeInput,
  className,
  placeholder,
  type,
  rows,
  error,
  style,
}) {
  const [content, setContent] = useState();
  const setting = useAppSelector(selectSetting);
  const textareaRef = useAutosizeTextareaHeight({ type, value, setting });

  const incrSize = size || 0;

  useEffect(() => {
    setContent(value);
  }, [value]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    onChangeInput(name, value);
    setContent(value);
  };

  return (
    <>
      {type === "input" ? (
        <input
          name={name}
          value={content}
          onChange={handleInputChange}
          placeholder={placeholder}
          className={cx(
            `focus:outline-none w-full m-0 p-0 hover:border hover:border-[#bbb] hover:border-dashed min-h-[10px] bg-inherit overflow-y-hidden resize-none`,
            error && "border border-dashed border-red-500",
            className
          )}
          autoComplete="off"
          style={{
            fontSize: `${Number(setting.fontSize) + incrSize}px`,
            fontFamily: `${setting.fontFamily}`,
            ...style,
          }}
        />
      ) : (
        <textarea
          ref={textareaRef}
          id={id}
          name={name}
          value={content}
          onChange={handleInputChange}
          placeholder={placeholder}
          rows={rows || 1}
          className={cx(
            `focus:outline-none w-full m-0 p-0 hover:border hover:border-[#bbb] hover:border-dashed min-h-[10px] line-[0] bg-inherit overflow-y-hidden`,
            error && "border border-dashed border-red-500",
            className
          )}
          autoComplete="off"
          style={{
            fontSize: `${Number(setting.fontSize) + incrSize}px`,
            fontFamily: `${setting.fontFamily}`,
            ...style,
            resize: "none",
          }}
        />
      )}
    </>
  );
}

const useAutosizeTextareaHeight = ({ type, value, setting }) => {
  const textareaRef = useRef(null);
  const resizing = useRef(false);

  useEffect(() => {
    if (type !== "input") {
      const textarea = textareaRef.current;
      if (textarea) {
        adjustTextareaHeight(textarea);

        try {
          const resizeObserver = new ResizeObserver(() => {
            if (!resizing.current) {
              adjustTextareaHeight(textarea);
            }
          });

          resizeObserver.observe(textarea);

          return () => {
            resizeObserver.disconnect();
          };
        } catch (error) {
          console.log(new Error(error));
        }
      }
    }
  }, [value, type, setting.fontSize, textareaRef]);

  const adjustTextareaHeight = (element) => {
    try {
      if (element) {
        resizing.current = true;
        element.style.height = "auto";
        if (
          element.scrollHeight > 0 &&
          element.scrollHeight !== element.clientHeight
        ) {
          element.style.height = element.scrollHeight + "px";
        }
        resizing.current = false;
      }
    } catch (error) {
      console.log(new Error(error));
    }
  };

  return textareaRef;
};

export default InputCv;
