import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deepMerge } from "../../lib/deep-merge";
import { initialBuilderState, setBuilder } from "./slice/builder";
import { initialSettings, setSetting } from "./slice/setting";
import { getStateGlobal, saveStateGlobal } from "./local-storage";
import { storeBuilder } from "./store";
import { getFormOrder, getListColor } from "../configs/data";

export const useAppDispatch = useDispatch;
export const useAppSelector = useSelector;

export const useSaveStateOnChange = () => {
  useEffect(() => {
    const unsubscribe = storeBuilder.subscribe(() => {
      saveStateGlobal(storeBuilder.getState());
    });
    return unsubscribe;
  }, []);
};

export const useSetInitialStore = (template) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const state = getStateGlobal();
    if (!state) {
      const mergeState = deepMerge(initialBuilderState, {});
      dispatch(setBuilder(mergeState));
      const data = {
        templateName: template,
        formOrder: getFormOrder(template),
        themeColor: getListColor(template)[0],
      };
      const mergeSetting = deepMerge(initialSettings, data);
      dispatch(setSetting(mergeSetting));
      return;
    }
    if (state.builder) {
      const mergeState = deepMerge(initialBuilderState, state.builder);
      dispatch(setBuilder(mergeState));
    }
    if (state.setting) {
      const data = {
        ...state.setting,
        templateName: template,
        formOrder: getFormOrder(template),
        themeColor: getListColor(template)[0],
      };
      const mergeState = deepMerge(initialSettings, data);
      dispatch(setSetting(mergeState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
