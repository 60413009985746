import React from "react";
import { DeleteFilled } from "@ant-design/icons";
import ReactQuill from "react-quill";

function AddWelfare(props) {
  const { data, onRemoveBlock, onChangeValue } = props;

  const onChangeEditor = (e) => {
    onChangeValue({ id: data.id, value: e });
  };
  return (
    <div className="w-full my-4">
      <div className="flex justify-between items-center">
        {/* <Input
          label="Tên Phúc lợi"
          name={data?.id}
          onChangeInput={(e) =>
            onChangeValue({ id: data.id, title: e.target.value })
          }
          placeholder={`Phúc lợi`}
        /> */}
        <p className="text-black my-2">Chi tiết phúc lợi</p>
        <div
          className="border border-red-500 py-1 px-2 flex justify-end items-center rounded-lg cursor-pointer"
          onClick={() => onRemoveBlock(data?.id)}
        >
          <DeleteFilled style={{ color: "red" }} />
        </div>
      </div>

      <div className="w-full relative">
        <ReactQuill
          className="w-full"
          theme="snow"
          defaultValue={data?.value || ""}
          onChange={onChangeEditor}
          modules={{
            toolbar: [
              ["bold", "italic", "underline"],
              [{ color: ["black", "#0055FF", "red"] }],
            ],
          }}
        />
      </div>
    </div>
  );
}

export default AddWelfare;
