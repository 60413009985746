import React from "react";
import { cx } from "../../../lib/cx";

function CardPaymentBoard({ title, type, label }) {
  return (
    <div
      className={cx(
        "w-full h-[240px] rounded-[15px] p-[18px] text-white bg-gradient-to-br shadow-lg",
        (!type || type === 1) && "from-[#4D5CEF] to-[#ED4776]",
        type === 2 && "from-[#EF0F71] to-[#FE6507]",
        type === 3 && "from-[#52BA4A] to-[#D4E15A]"
      )}
    >
      <p>{title}</p>
      <p className="text-[32px]">{label}</p>
    </div>
  );
}

export default CardPaymentBoard;
