import React from "react";
import { cx } from "../../../lib/cx";

function CvDetailModal({ active, onChangeActive }) {
  return (
    <div
      className={cx(
        `overflow-x-auto md:py-[180px] xl:py-0 fixed inset-0 z-9999 h-screen outline-none focus:outline-none`,
        active ? "block" : "hidden"
      )}
      onClick={onChangeActive}
    >
      <div className="relative w-full min-h-[100vh]">
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-20"></div>
        <div className="relative flex justify-center items-center mx-auto w-full px-[10px] py-[72px] min-h-[100vh] select-none">
          <div className="border-0 relative flex flex-col w-full h-full bg-white shadow-md  outline-none focus:outline-none sm:w-[712px] rounded-[12px]">
            <img
              src={active?.image}
              alt=""
              className="max-w-full max-h-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CvDetailModal;
