import React from "react";
import {
  useAppSelector,
  useSaveStateOnChange,
  useSetInitialStore,
} from "../../redux/hooks";
import { selectActions } from "../../redux/slice/setting";
import { useConfigCvFile } from "../hook";
import { debounce } from "../../../utils";
import SettingComponent from "../../header/SettingComponent";
import SaveAsBlock from "../../components/Block/SaveAsBlock";
import AddMoreBlock from "../../components/Block/AddMoreBlock";
import ContentComponent from "./ContentComponent";
import ReviewCV004Modal from "../../components/Modals/ReviewCV004Modal";
import AutoScrollTop from "../../../components/ScrollTop/AutoScrollTop";

function CV_004() {
  const idTemplate = "CV_004";
  useSetInitialStore(idTemplate);
  useSaveStateOnChange();

  const actions = useAppSelector(selectActions);
  const { handleOpenReview, exportToPDF, handleSaveCv, handleChangeActive } =
    useConfigCvFile();

  return (
    <div id="scroll-smooth"
      className="w-full z-[10] !overflow-x-auto md:overflow-x-hidden"
      onClick={debounce(handleChangeActive, 100)}
    >
      <AutoScrollTop />
      <SettingComponent
        onHandleReview={handleOpenReview}
        onHandleExportPDF={exportToPDF}
        onHandleSave={handleSaveCv}
      />
      <div className="flex justify-start items-start pt-[40px] mx-auto lg:max-w-[1175px] min-h-[100vh]">
        {!actions?.openAddMore ? <ContentComponent /> : <AddMoreBlock />}
      </div>
      <SaveAsBlock />
      <ReviewCV004Modal id={idTemplate} />
    </div>
  );
}

export default CV_004;
