import dayjs from "dayjs";
import React from "react";
import { routes } from "../../PathRouting";
import { useHistory } from "react-router-dom";
import { useTheme } from "../../../lib/theme/useTheme";
import { cx } from "../../../lib/cx";

function CardNewsShow({ data }) {
  const { theme } = useTheme();
  const history = useHistory();
  return (
    <div className="w-full min-h-[100px] py-[10px] border-b-[1px] border-b-gray">
      <div className="w-full h-full grid grid-cols-10 gap-4">
        <div className="col-span-4 md:col-span-2">
          <div className="w-full h-full">
            <img
              src={data?.image || "/images/background.png"}
              alt=""
              className="w-full h-full object-cover rounded-md"
            />
          </div>
        </div>
        <div className="col-span-6 md:col-span-8">
          <p
            className={cx(
              "text-[22px] hover:underline cursor-pointer",
              theme?.background?.text
            )}
            onClick={() =>
              history.push(routes.newsDetail.parentPath + `/${data.slug}`)
            }
          >
            {data?.title}
          </p>
          <div className="md:flex md:justify-start md:items-center md:space-x-4">
            <p
              className={cx(
                "text-[14px] font-[800] hidden md:block",
                theme?.background?.sub_text
              )}
            >
              Lao động online
            </p>
            <p className="text-[14px] text-gray hidden md:block">|</p>
            <p className="text-[14px] text-gray">
              {data?.createAt &&
                dayjs(data.createAt).format("DD-MM-YYYY HH:MM")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardNewsShow;
