import React, { useEffect, useState } from "react";
import AdminService from "../../services/AdminService";
import { useLocation } from "react-router-dom";
import { getId } from "../../utils";
import dayjs from "dayjs";
import { TagOutlined } from "@ant-design/icons";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import HeaderSeo from "../../components/Header/HeaderSeo";

function NewsDetailShow() {
  const { theme } = useTheme();
  const location = useLocation();
  const [newsDetail, setNewsDetail] = useState();

  useEffect(() => {
    if (!newsDetail) {
      getNewsDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsDetail]);

  const getNewsDetail = async () => {
    AdminService.getNewsDetail(getId(location.pathname))
      .then((response) => {
        setNewsDetail(response.data.data);
      })
      .catch(() => {});
  };

  return (
    <>
      <HeaderSeo
        title={`${newsDetail?.title}`}
        description={`${newsDetail?.title}`}
      />
      <div
        className={cx(
          "mx-auto min-h-[80vh] lg:max-w-[1175px] select-none mb-[120px]",
          theme?.background?.gradient
        )}
      >
        <div className="px-4">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 my-[120px]">
            <div className="col-span-3">
              <p
                className={cx(
                  "text-[32px] font-[600]",
                  theme?.background?.text
                )}
              >
                {newsDetail?.title}
              </p>

              <p className="text-gray text-[16px] mb-4">
                <span className="font-[600]">Lao động online </span>
                {dayjs(newsDetail?.createAt).format("HH:MM DD/MM/YYYY")}
              </p>
              {newsDetail?.content && (
                <div
                  dangerouslySetInnerHTML={{ __html: newsDetail.content }}
                  className={cx("text-justify", theme?.background?.text)}
                />
              )}

              <div className="w-full my-4">
                <p className="text-[20px] font-[600] text-gray mb-2">
                  <TagOutlined /> Tag
                </p>

                {newsDetail &&
                  newsDetail.tag.length > 0 &&
                  newsDetail.tag.map((item) => (
                    <div className="float-left inline bg-gray text-white mr-2 mb-2 rounded-[14px] px-4 py-2">
                      {item}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-span-1">
              <p
                className={cx(
                  "text-[32px] font-[600] mb-4",
                  theme?.background?.text
                )}
              >
                Tin tức liên quan
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsDetailShow;
