import React, { useState } from "react";
import { loginSchema } from "../../utils/validations";
import { useFormik } from "formik";
import Input from "../../components/Input";
import { LoadingOutlined } from "@ant-design/icons";
import { useAuth } from "./useAuth";
import AdminService from "../../services/AdminService";
import { cx } from "../../lib/cx";
import { useTheme } from "../../lib/theme/useTheme";

function LoginAdmin() {
  const { theme } = useTheme();
  const { login } = useAuth();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const loginFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      setLoading(true);
      loginAdmin(values);
    },
  });

  const loginAdmin = (values) => {
    try {
      AdminService.loginAdmin(values)
        .then((response) => {
          setTimeout(() => {
            const data = response.data.data;
            setLoading(false);
            const value = {
              id: data.id,
              email: values.email,
              nickname: data.fullName,
              login: true,
              role: data.role,
              sub_role: data.description,
              token: data.authToken[0].accessToken,
            };
            login(value);
          }, 1500);
        })
        .catch(() => {
          loginError();
        });
    } catch (error) {
      loginError();
    }
  };

  const loginError = () => {
    setError("Tài khoản hoặc mật khẩu không chính xác");
    setTimeout(() => {
      setError("");
    }, 2500);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <div
      className={cx(
        "w-full fixed top-0 left-0 z-[99999] h-[100vh] flex justify-center items-center",
        theme?.login?.background
      )}
    >
      <div className="w-full h-full flex items-center justify-center px-[10px] sm:px-0">
        <div
          className="bg-white rounded-[10px] p-[20px]"
          style={{ boxShadow: "15px 15px 10px 0px rgba(0,0,0,0.4)" }}
        >
          <div>
            <h2 className="text-[26px] font-[600]">
              Đăng nhập quản trị viên 👋
            </h2>
            <p className="my-4">Tài khoản dành cho quản trị viên</p>
          </div>
          <form onSubmit={loginFormik.handleSubmit}>
            <div className="my-2">
              <Input
                name="email"
                placeholder="Nhập email"
                label="Email"
                onChangeInput={loginFormik.handleChange}
                className="!mt-1"
                error={
                  loginFormik.errors.email && loginFormik.touched.email
                    ? loginFormik.errors.email
                    : ""
                }
              />
            </div>
            <div className="my-4">
              <Input
                name="password"
                type="password"
                placeholder="Nhập mật khẩu"
                label="Mật khẩu"
                className="!mt-1"
                onChangeInput={loginFormik.handleChange}
                error={
                  loginFormik.errors.password && loginFormik.touched.password
                    ? loginFormik.errors.password
                    : ""
                }
              />
            </div>

            {error && (
              <p className="text-red-500 text-[12px] text-center my-2">
                {error}
              </p>
            )}
            <button
              type="submit"
              className="w-full text-center bg-[#05F] text-white rounded-[10px] py-2 cursor-pointer hover:bg-blue-500 flex justify-center items-center"
              onClick={loginFormik.handleSubmit}
            >
              {loading && (
                <LoadingOutlined
                  style={{ color: "white", marginRight: "5px" }}
                />
              )}
              Đăng nhập
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginAdmin;
