import React, { useState } from "react";
import CardPayment from "./components/CardPayment";
import PaymentComboModal from "../../components/Modals/PaymentComboModal";
import { DATA_PAYMENT_FAKE } from "../../utils/constants";
import { paymentRoutes, routes } from "../PathRouting";
import { useHistory } from "react-router-dom";
import ScrollTop from "../../components/ScrollTop";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";

function PaymentCombo() {
  const { theme } = useTheme();
  const [activeDetail, setActiveDetail] = useState(false);
  const [showCombo, setShowCombo] = useState();
  const history = useHistory();

  const loginStatus = JSON.parse(localStorage.getItem("loginStatus"));

  const data = DATA_PAYMENT_FAKE;

  const handleBuyNow = (id) => {
    if (!loginStatus || loginStatus.role !== "Recruiter") {
      history.push(
        routes.loginPage.path +
          `?role=Recruiter&redirect=${paymentRoutes.paymentDetail.path}?combo=${id}`
      );
      return;
    }
    history.push(paymentRoutes.paymentDetail.path + `?combo=${id}`);
  };

  return (
    <div
      className={cx(
        "mx-auto min-h-[80vh] lg:max-w-[1175px] select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <ScrollTop />
      <div className="px-4">
        <h2
          className={cx(
            "text-[24px] md:text-[32px] font-[800] my-[80px]",
            theme?.background?.text
          )}
        >
          Các Gói Dịch Vụ Tuyển Dụng
        </h2>

        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {data.map((item, index) => (
            <CardPayment
              key={"payment-i" + index + "-items"}
              data={item}
              onChangeDetail={() => {
                setShowCombo({ ...item, combo: index + 1 });
                setActiveDetail(true);
              }}
              onChangeNow={() => handleBuyNow(index + 1)}
            />
          ))}
        </div>
      </div>

      <PaymentComboModal
        data={showCombo}
        active={activeDetail}
        onChangeActive={() => setActiveDetail(!activeDetail)}
        onChangeNow={() => handleBuyNow(showCombo.combo)}
      />
    </div>
  );
}

export default PaymentCombo;
