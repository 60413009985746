const DATA_COLOR = {
  CV_001: ["#2AABD0", "#00B14F", "#E40137", "#620DD5", "#222222"],
  CV_002: ["#222222"],
  CV_003: ["#222222"],
  CV_004: ["#0083B1", "#00B4D8", "#17AD30", "#FF3737", "#646D79"],
  CV_005: ["#6897AD", "#212F3F", "#646D79"],
  CV_006: ["#555555", "#118AB2", "#EF476F", "#139A6F"],
};

const DATA_SUB_COLOR = {
  CV_004: [
    {
      key: "#0083B1",
      value: "rgba(0, 131, 177, 0.25)",
    },
    {
      key: "#00B4D8",
      value: "rgba(0, 180, 216, 0.25)",
    },
    {
      key: "#17AD30",
      value: "rgba(23, 173, 48, 0.25)",
    },
    {
      key: "#FF3737",
      value: "rgba(255, 55, 55, 0.25)",
    },
    {
      key: "#646D79",
      value: "rgba(100, 109, 121, 0.25)",
    },
  ],
};

export const getListColor = (field) => DATA_COLOR[field];

export const getSubColor = (field, color) => {
  const data = DATA_SUB_COLOR[field];
  if (!data) return "";
  return data.find((c) => c.key === color)?.value || "";
};

const DATA_FORM_ORDER = {
  CV_001: {
    left: [
      "skills",
      "cetifications",
      "honors_and_awards",
      "interestes",
      "addtional_informations",
    ],
    right: ["objective", "educations", "workExperiences", "projects"],
  },
  CV_002: {
    other: [
      "objective",
      "educations",
      "workExperiences",
      "skills",
      "cetifications",
      "activeties",
      "interestes",
      "addtional_informations",
    ],
  },
  CV_003: {
    other: [
      "objective",
      "educations",
      "workExperiences",
      "skills",
      "cetifications",
      "activeties",
      "interestes",
      "addtional_informations",
    ],
  },
  CV_004: {
    left: ["skills", "interestes", "addtional_informations"],
    right: [
      "objective",
      "educations",
      "workExperiences",
      "activeties",
      "honors_and_awards",
      "cetifications",
    ],
  },
  CV_005: {
    left: [
      "objective",
      "educations",
      "workExperiences",
      "activeties",
      "honors_and_awards",
      "cetifications",
    ],
    right: ["skills", "interestes", "presenter", "addtional_informations"],
  },
  CV_006: {
    left: ["objective", "skills", "presenter"],
    right: [
      "educations",
      "workExperiences",
      "activeties",
      "cetifications",
      "honors_and_awards",
      "addtional_informations",
    ],
  },
};

export const getFormOrder = (template) => DATA_FORM_ORDER[template];

export const DATA_ARRAY_FONT = [
  "Arial",
  "Roboto",
  "sans-srif",
  "Tahoma",
  "Time New Roman",
];

export const DATA_FONT_SIZE = ["11", "14", "18"];

export const AVATAR_REQUIED_IN_SAVE = [
  "CV_001",
  "CV_003",
  "CV_004",
  "CV_005",
  "CV_006",
];
