import React from "react";
import ScrollTop from "../../components/ScrollTop";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import { partner } from "../AboutUs";

const CardPartner = (props) => {
  return (
    <div>
      <a
        href={props.href}
        className="bg-white rounded-[12px] flex justify-between p-[2%] cursor-pointer"
      >
        <div className="flex flex-row space-x-5">
          <div className="w-[100px] h-[100px] flex justify-center items-center">
            <img
              src={props.logo}
              className="max-w-full max-h-full object-cover rounded-[10px]"
              alt=""
            />
          </div>
          <div className="flex justify-start items-center">
            <p className="font-[600] text-black text-[22px]">{props.name}</p>
            <br />
            <p className="font-[500] text-black text-[20px]">
              {props.description}
            </p>
          </div>
        </div>
      </a>
      {/* <Bt
                title={'Xem chi tiết ->'}
                className='bg-blue-500 rounded-[12px]'
            /> */}
    </div>
  );
};

export const ListPartnerMPB = (props) => {
  const { theme } = useTheme();
  return (
    <div className="w-full mx-auto lg:max-w-[1170px] space-y-2 px-4 pt-[40px] pb-[120px]">
      <ScrollTop />
      <h2
        className={cx(
          "font-[600] text-[32px] md:text-[50px] my-[40px]",
          theme?.background?.text
        )}
      >
        Đối tác của LDO
      </h2>
      {partner.map((item, idx) => {
        return (
          <CardPartner
            key={"partner-" + idx + "-list"}
            name={item.name}
            href={item.website}
            logo={`/partner_mpb/partner_${idx + 1}.png`}
          />
        );
      })}
    </div>
  );
};
