import React, { useState } from "react";

function AddComponent(props) {
  const { name, value, active, onChangeInput } = props;
  const [valueInput, setValueInput] = useState(value);
  return (
    <div className={`w-full ${active === name ? "block" : "hidden"}`}>
      <textarea
        name={name}
        value={valueInput}
        cols="30"
        rows="2"
        className="w-full border-[1px] border-[#ccc] focus:outline-none p-2 resize-x-none"
        onChange={(e) => {
          setValueInput(e.target.value);
          onChangeInput(e);
        }}
      />
    </div>
  );
}

export default AddComponent;
