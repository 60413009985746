import React from "react";
import NamePositionBlock from "../../components/Block/NamePositionBlock";
import { Email, Link, LocationOnOutlined, Phone } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  changeValidateForm,
  selectSetting,
  selectValidateForm,
} from "../../redux/slice/setting";
import { changeProfile, selectProfile } from "../../redux/slice/builder";
import InputCv from "../../components/Input";

function ProfileComponent() {
  const setting = useAppSelector(selectSetting);
  const profile = useAppSelector(selectProfile);
  const validateForm = useAppSelector(selectValidateForm);
  const dispatch = useAppDispatch();

  const handleChangeInput = (field, value) => {
    dispatch(changeProfile({ field, value }));
    dispatch(changeValidateForm({ field, value: "" }));
  };

  const styleIcon = {
    fontSize: `${Number(setting.fontSize) + 2}px`,
    color: "black",
    marginRight: "5px",
  };
  const listItems = [
    {
      icon: <Phone style={styleIcon} />,
      key: "phoneNumber",
      placeholder: "số điện thoại",
    },
    {
      icon: <Email style={styleIcon} />,
      key: "email",
      placeholder: "email",
    },
    {
      icon: <Link style={styleIcon} />,
      key: "linkedin",
      placeholder: "website",
    },
    {
      icon: <LocationOnOutlined style={styleIcon} />,
      key: "location",
      placeholder: "Địa chỉ",
    },
  ];
  return (
    <div className="pt-[20px]">
      <NamePositionBlock headCss="text-center" childCss="text-center" />

      <div className="w-full flex justify-center items-center">
        {listItems.map((item) => (
          <>
            <div className="flex justify-start items-center">
              {item?.icon}
              <InputCv
                id={item.key}
                name={item.key}
                value={profile[item.key]}
                placeholder={item.placeholder}
                onChangeInput={handleChangeInput}
                className="!w-[120px] text-black placeholder:italic placeholder:text-gray !-pt-1"
                error={validateForm[item.key]}
              />
            </div>
          </>
        ))}
      </div>
    </div>
  );
}

export default ProfileComponent;
