import { EditOutlined } from "@ant-design/icons";
import React from "react";
import ContactPersonal from "./ContactPersonal";
import JobApplied from "./JobApplied";
import JobInterested from "./JobInterested";
import CvManagement from "./CvManagement";
import { routes } from "../../screens/PathRouting";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { cx } from "../../lib/cx";
import { useTheme } from "../../lib/theme/useTheme";
import useGetCandidateInfo from "../../hooks/useGetCandidateInfo";
import { useLocalStorage } from "../../lib/useLocalStorage";

function User() {
  const { theme } = useTheme();
  const history = useHistory();
  const location = useLocation();
  const [token] = useLocalStorage("token", null);

  const { candidate: profile, refarral, earnPoint } = useGetCandidateInfo();

  const query = queryString.parse(location.search);

  const listMenu = [
    {
      name: "Hồ sơ cá nhân",
      slug: "contact-personal",
      component: (
        <ContactPersonal
          profile={profile}
          addInfo={{ refarral, earnPoint }}
          token={token}
        />
      ),
    },
    {
      name: "Công việc đã ứng tuyển",
      slug: "job-applied",
      component: <JobApplied profile={profile} params={query} token={token} />,
    },
    {
      name: "Công việc quan tâm",
      slug: "job-interested",
      component: (
        <JobInterested profile={profile} params={query} token={token} />
      ),
    },
    {
      name: "Quản lý CV",
      slug: "cv-management",
      component: <CvManagement profile={profile} token={token} />,
    },
  ];

  const handleClickActive = (item) => {
    history.replace(routes.userSetting.path + `?action=${item}`);
  };

  return (
    <div
      className={cx(
        "container mx-auto lg:max-w-[1175px] min-h-[100vh] z-1",
        theme?.background?.gradient
      )}
    >
      <div className="px-4">
        <div
          className={cx(
            "w-full h-[120px] sm:h-[180px] relative",
            token ? "xl:h-[200px]" : "lg:h-[260px]"
          )}
        >
          <img
            src="/images/background.png"
            alt="background"
            className="object-cover"
          />

          <div className="w-[80px] h-[80px] md:w-[160px] md:h-[160px] rounded-[50%] bg-gray-400 absolute -bottom-[30%] md:-bottom-[120%] lg:-bottom-[80%] left-[3%] md:left-[6%] lg:left-[8%]">
            <img
              src={profile?.avatar || "/images/avatar.jpeg"}
              alt=""
              className="w-full h-full object-cover rounded-[50%]"
            />
          </div>
        </div>
        <div
          className={cx(
            "w-full min-h-[120px] md:mt-[120px]",
            token && "xl:mt-[80px]"
          )}
        >
          <div
            className={cx("w-full grid grid-cols-4", token && "xl:grid-cols-3")}
          >
            <div className={cx("col-span-1")}></div>
            <div className={cx("col-span-3", token && "xl:col-span-2")}>
              <p
                className={cx(
                  "text-[24px] lg:text-[32px] mt-4",
                  theme?.background?.text
                )}
              >
                {profile?.fullName}
              </p>
              <p
                className={cx(
                  "text-[14px] lg:text-[16px] mb-2",
                  theme?.background?.text
                )}
              >
                {profile?.jobTitle}
              </p>
              <p
                className={cx(
                  "px-4 py-2 w-[140px] bg-[#1495F3] text-[13px] text-white rounded-[24px] cursor-pointer",
                  token && "xl:hidden"
                )}
                onClick={() => history.push(routes.personalProfile.path)}
              >
                Chỉnh sửa hồ sơ <EditOutlined />
              </p>
            </div>
          </div>
        </div>
        <div className="sm:flex sm:justify-start sm:items-center mt-[20px] md:mt-[40px] xl:hidden">
          {listMenu.map((menu, idx) => (
            <div
              key={"menu" + idx + "sub"}
              className={`px-4 py-3 sm:rounded-tl-[12px] hover:bg-white hover:text-black sm:rounded-tr-[12px] ${
                (query?.action || "contact-personal") === menu.slug
                  ? "bg-white text-black"
                  : "bg-[#DDE4FF] text-[#8D9092]"
              } cursor-pointer`}
              onClick={() => handleClickActive(menu.slug)}
            >
              {menu.name}
            </div>
          ))}
        </div>
        <div className="w-full min-h-[100vh] pb-[180px]">
          {listMenu.map((menu, index) =>
            menu.slug === (query?.action || "contact-personal") ? (
              <div key={"fragment" + index}>{menu.component}</div>
            ) : null
          )}
        </div>
      </div>
    </div>
  );
}

export default User;
