import React, { useEffect, useState } from "react";
import LeftContent from "./LeftContent";
import RightContent from "./RightContent";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import { routes } from "../../screens/PathRouting";
import { useLocation } from "react-router-dom";
import { getRootPath } from "../../utils";

function LoginCandidate({ children }) {
  const { theme } = useTheme();
  const location = useLocation();

  const [isShow, setIsShow] = useState(false);

  const listOther = [
    routes.home.path,
    routes.loginPage.path,
    routes.login.path,
    routes.signupCandidate.path,
    routes.signupRecruiter.path,
  ];

  useEffect(() => {
    isShowContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const isShowContent = () => {
    let result = false;
    const user = JSON.parse(localStorage.getItem("User"));
    const token = JSON.parse(localStorage.getItem("token"));
    if (user && token) {
      result = true;
    }
    setIsShow(result);
  };

  return (
    <>
      {listOther.includes(location.pathname) ||
      ["recruiter", "admin"].includes(getRootPath(location.pathname, 1)) ? (
        <>{children}</>
      ) : isShow ? (
        <div
          className={cx("w-full xl:h-[95vh] flex mx-auto xxl:max-w-[2137px]")}
        >
          <div
            className={cx(
              "w-[22%] mt-[18px] pt-[10px] shadow-md hidden xl:block",
              theme?.loginCandidateContent?.background
            )}
          >
            <LeftContent />
          </div>

          <div
            className={cx(
              "mx-auto lg:max-w-[1024px] xl:max-w-[1175px] xl:overflow-y-scroll xl:overflow-x-hidden"
            )}
          >
            {children}
          </div>
          <div
            className={cx(
              "w-[22%] mt-[18px] pt-[10px] shadow-md hidden xl:block",
              theme?.loginCandidateContent?.background
            )}
          >
            <RightContent />
          </div>
        </div>
      ) : (
        <div className={cx("w-full mx-auto")}>{children}</div>
      )}
    </>
  );
}

export default LoginCandidate;
