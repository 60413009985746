import React, { useEffect, useState } from "react";
import { useTheme } from "../../lib/theme/useTheme";
import { useAuth } from "../Auth/useAuth";
import { cx } from "../../lib/cx";
import CardCV from "../components/Card/CardCV";
import CardEmpty from "../components/Card/CardEmpty";
import queryString from "query-string";
import UserService from "../../services/UserService";
import { Pagination } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { changePagination } from "../../lib/theme/config";
import CreateCvModal from "../components/Modal/CreateCvModal";
import { getImageUrl } from "../../utils";
import CvDetailModal from "../components/Modal/CvDetailModal";
import SearchCv from "./SearchCv";

function CVTemplate() {
  const { theme } = useTheme();
  const { admin } = useAuth();
  const location = useLocation();
  const history = useHistory();

  const search = queryString.parse(location.search);

  const [listCvs, setListCvs] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const [toggleCreate, setToggleCreate] = useState(false);
  const [toggleDetail, setToggleDetail] = useState();
  const [updateCv, setUpdateCv] = useState();

  const limit = 11;

  useEffect(() => {
    if (!listCvs) {
      getAllCv();
    }
  }, [listCvs]);

  const getAllCv = async (page, options = {}) => {
    const params = queryString.stringify({
      ...options,
      offset: page - 1,
      limit: limit,
    });

    const response = await UserService.getAllCvManage(params);
    const data = response.data.data;

    let listImgCv = data.items.map((item) => item.image);
    listImgCv = await UserService.getImageByName(listImgCv);

    const result = data.items.map((c) => ({
      ...c,
      original_image: c.image,
      image: getImageUrl(listImgCv.data.data, c.image),
    }));

    setListCvs(result);
    setTotalPage(data.count);
  };

  const onChangePage = (page) => {
    history.replace(
      location.pathname + `?${queryString.stringify({ ...search, page: page })}`
    );
    getAllCv(page, search);
  };

  return (
    <div
      className={cx(
        "mx-auto min-h-[80vh] lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <div className={cx("px-4 mb-[120px]", theme?.background?.text)}>
        <div className="my-[60px]">
          <p className="text-[32px] font-[600] leading-none">Dashboad</p>
          <p className="text-[26px]">{admin?.nickname}</p>
        </div>
        <p className="font-[600] text-[20px]">Quản lý CV Template</p>

        <div className="mt-[40px]">
          <SearchCv onChangeValue={(params) => getAllCv(1, params)} />
        </div>
        <div className="my-[40px] grid grid-rows-1 md:grid-cols-3 gap-4">
          <CardEmpty
            icon="/icons/elementplus.png"
            title="Thêm CV quản lý"
            onClick={() => {
              setToggleCreate(true);
            }}
          />

          {listCvs &&
            listCvs.length > 0 &&
            listCvs.map((item, index) => (
              <CardCV
                key={item.name + index}
                data={item}
                onEdit={() => {
                  setUpdateCv(item);
                  setToggleCreate(true);
                }}
                onDetail={() => setToggleDetail(item)}
              />
            ))}
        </div>
        <div className="w-full my-4 flex justify-center items-center">
          <Pagination
            defaultCurrent={search?.page ? Number(search?.page) : 1}
            total={totalPage}
            pageSize={limit}
            onChange={onChangePage}
            itemRender={changePagination}
          />
        </div>
      </div>
      <CreateCvModal
        data={updateCv}
        active={toggleCreate}
        onChangeActive={() => {
          getAllCv(1, search);
          setUpdateCv(null);
          setToggleCreate(!toggleCreate);
        }}
      />
      <CvDetailModal
        active={toggleDetail}
        onChangeActive={() => setToggleDetail(null)}
      />
    </div>
  );
}

export default CVTemplate;
