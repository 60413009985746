import { CloseOutlined } from "@ant-design/icons";
import React from "react";
import { formatVND } from "../../../utils";
import dayjs from "dayjs";
import { cx } from "../../../lib/cx";

function PaymentDetailHistoryModal({ paymentData, active, onChangeActive }) {
  const { data, payment } = paymentData;

  const renderStatus = () => {
    return (
      <React.Fragment>
        <div className="flex justify-start items-center space-x-2">
          {payment?.status === "success" ? (
            <div className="bg-green-400 px-8 py-1 text-white rounded-[22px]">
              Thành công
            </div>
          ) : payment.status === "pending" ? (
            <div className="bg-blue-400 px-8 py-1 text-white rounded-[22px]">
              Đang xử lý
            </div>
          ) : (
            <div className="bg-red-400 px-8 py-1 text-white rounded-[22px]">
              Thất bại
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  return (
    <div
      className={`overflow-y-scroll md:overflow-y-hidden overflow-x-hidden fixed inset-0 z-9999 h-screen outline-none focus:outline-none justify-center items-center c-modal bg-black bg-opacity-40 ${
        !active && "hidden"
      }`}
      onClick={onChangeActive}
    >
      <div className="relative flex justify-center items-center mx-auto w-full px-[10px] md:px-0 h-[100vh] select-none my-[120px] md:my-0">
        <div className="border-0 relative flex flex-col w-full bg-white outline-none focus:outline-none sm:w-[980px] rounded-[12px]">
          <div className="flex justify-end items-center pr-2 pt-2 cursor-pointer">
            <CloseOutlined />
          </div>
          <div className="p-[10px] md:p-[24px] md:pt-2 w-full">
            <div className="w-full flex justify-between items-center">
              <div className="text-[22px] font-[500] mb-4">{data?.name}</div>
              <div className="col-span-1 hidden md:block">{renderStatus()}</div>
            </div>

            <div className="mb-[20px]">
              <p>
                Họ tên khách hàng:{" "}
                <span className="font-[600]">{payment?.customerName}</span>
              </p>
              <p>
                Tổng tiền: <span className="font-[600]">{payment?.amount}</span>
              </p>
              <p>
                Thời gian giao dịch:{" "}
                <span className="font-[600]">
                  {payment?.createdAt &&
                    dayjs(new Date(payment.createdAt)).format("DD/MM/YYYY")}
                </span>
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="col-span-1 overflow-x-auto">
                <table className="w-full min-w-[450px] max-w-[1000px]">
                  <thead>
                    <tr>
                      {["Dịch vụ", "Thời gian", "Sl", "Đơn giá gốc"].map(
                        (item, index) => (
                          <th
                            key={(item, index)}
                            className={cx(
                              "py-1 text-[14px] text-center bg-[#B7E1FF] rounded-[12px] uppercase",
                              index === 0 &&
                                "w-[50%] border-[1px] border-white",
                              index === 1 &&
                                "w-[19%] border-[1px] border-white",
                              index === 2 && "w-[9%] border-[1px] border-white",
                              index === 3 && "w-[20%]"
                            )}
                          >
                            {item}
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.detail &&
                      data?.detail.map((item, index) => (
                        <>
                          <tr key={item.title + index}>
                            {item?.number ? (
                              <>
                                {item.number === 1 && (
                                  <td
                                    className="bg-[#F4F9FF] py-1 px-2 rounded-[12px] border-[1px] border-white"
                                    rowSpan="4"
                                  >
                                    {item.title}
                                  </td>
                                )}
                              </>
                            ) : (
                              <td className="bg-[#F4F9FF] py-1 px-2 rounded-[12px] border-[1px] border-white">
                                {item.title}
                              </td>
                            )}
                            {[
                              item.date,
                              item.amount,
                              formatVND(item.price),
                            ].map((sub, idx) => (
                              <td
                                key={item.title + index + idx}
                                className="bg-[#F4F9FF] text-center py-1 px-2 rounded-[12px] border-[1px] border-white"
                              >
                                {sub}
                              </td>
                            ))}
                          </tr>
                        </>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="col-span-1">
                <p className="text-[18px] font-[600]">Mô tả dịch vụ:</p>
                <ul className="pl-[20px]">
                  {data?.description &&
                    data.description.map((item) => (
                      <>
                        <li className="list-disc text-gray text-justify">
                          {item}
                        </li>
                      </>
                    ))}
                </ul>
              </div>
            </div>

            <div className="block mt-[20px] md:hidden">
              <div className="flex justify-center items-center space-x-2">
                {renderStatus()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentDetailHistoryModal;
