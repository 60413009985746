import React from "react";
import AvatarDefaultCv from "../../components/Avatar";
import NamePositionBlock from "../../components/Block/NamePositionBlock";
import InputCv from "../../components/Input";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  changeValidateForm,
  selectSetting,
  selectValidateForm,
} from "../../redux/slice/setting";
import { changeProfile, selectProfile } from "../../redux/slice/builder";

function ProfileComponent() {
  const setting = useAppSelector(selectSetting);
  const profile = useAppSelector(selectProfile);
  const validateForm = useAppSelector(selectValidateForm);
  const dispatch = useAppDispatch();

  const handleChangeInput = (field, value) => {
    dispatch(changeProfile({ field, value }));
    dispatch(changeValidateForm({ field, value: "" }));
  };

  const styleTitle = {
    fontSize: `${Number(setting.fontSize) + 1}px`,
    color: "black",
    marginRight: "5px",
    fontWeight: 600,
    width: "110px",
  };

  const listItems = [
    {
      title: "Ngày sinh:",
      key: "dateOfBirdth",
      placeholder: "Ngày sinh",
    },
    {
      title: "Số điện thoại:",
      key: "phoneNumber",
      placeholder: "Số điện thoại",
    },
    {
      title: "Email:",
      key: "email",
      placeholder: "Email",
    },
    {
      title: "Website:",
      key: "website",
      placeholder: "Website",
    },
    {
      title: "Địa chỉ:",
      key: "location",
      placeholder: "Địa chỉ",
    },
  ];

  return (
    <div className="w-full flex justify-start items-start">
      <div className="w-[240px] h-[250px] p-5">
        <AvatarDefaultCv shape="rounded-none" className="!bg-gray" />
      </div>
      <div className="pt-[15px]">
        <NamePositionBlock />

        <div className="w-full">
          {listItems.map((item) => (
            <>
              <div className="flex justify-start items-center">
                <p style={styleTitle}>{item?.title}</p>
                <InputCv
                  id={item.key}
                  name={item.key}
                  value={profile[item.key]}
                  placeholder={item.placeholder}
                  onChangeInput={handleChangeInput}
                  className="!w-[320px] text-black placeholder:italic placeholder:text-gray !-pt-1"
                  error={validateForm[item.key]}
                />
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProfileComponent;
