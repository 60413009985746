import React from "react";
import { pathAdmin } from "../../../screens/PathRouting";
import { useHistory } from "react-router-dom";
import { useTheme } from "../../../lib/theme/useTheme";
import { cx } from "../../../lib/cx";

function BreadcrumbAdmin(props) {
  const { theme } = useTheme();
  const { data } = props;
  const history = useHistory();
  const menu = [{ name: "Dashboard", path: pathAdmin.admin.path }, ...data];
  return (
    <div className="w-full my-[60px]">
      {menu.map((item, index) => {
        if (index < menu.length - 1) {
          return (
            <p
              key={item.name + index + "tab"}
              className={cx(
                "text-[16px] text-[#bbb] cursor-pointer font-[600] inline",
                theme?.background?.text_hover
              )}
              onClick={() => history.push(item.path)}
            >
              {item.name}{" "}
              <span className={cx("mx-2 inline", theme?.background?.text)}>
                /
              </span>
            </p>
          );
        } else {
          return (
            <p
              key={item.name + index + "tab"}
              className={cx(
                "text-[16px] font-[600] inline",
                theme?.background?.text
              )}
            >
              {item.name}
            </p>
          );
        }
      })}
    </div>
  );
}

export default BreadcrumbAdmin;
