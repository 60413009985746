import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import {
  EditOutlined,
  SolutionOutlined,
  SwitcherOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { routes } from "../../screens/PathRouting";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import queryString from "query-string";

function LeftContent() {
  const user = JSON.parse(localStorage.getItem("User"));

  const styleIcon = {
    color: "black",
    fontSize: "24px",
  };

  const dataMenu = [
    {
      title: user?.fullName,
      slug: "fullName",
      url: routes.userSetting.path,
      icon: (
        <div className="w-[40px] h-[40px] bg-white rounded-full">
          <img
            src={
              user?.avatar ||
              "https://imagekit.io/blog/content/images/2019/12/image-optimization.jpg"
            }
            alt=""
            className="w-full h-full object-cover rounded-full"
          />
        </div>
      ),
    },
    {
      title: "Hồ sơ cá nhân",
      url: routes.userSetting.path,
      icon: (
        <Icon>
          <UserCircleIcon style={styleIcon} />
        </Icon>
      ),
    },
    {
      title: "Công việc ứng tuyển",
      slug: "job-applied",
      url: routes.userSetting.path + "?action=job-applied",
      icon: (
        <Icon>
          <SolutionOutlined style={styleIcon} />
        </Icon>
      ),
    },
    {
      title: "Công việc quan tâm",
      slug: "job-interested",
      url: routes.userSetting.path + "?action=job-interested",
      icon: (
        <Icon>
          <TagOutlined style={styleIcon} />
        </Icon>
      ),
    },
    {
      title: "Quản lý CV",
      slug: "cv-management",
      url: routes.userSetting.path + "?action=cv-management",
      icon: (
        <Icon>
          <SwitcherOutlined style={styleIcon} />
        </Icon>
      ),
    },
    {
      title: "Chỉnh sửa hồ sơ cá nhân",
      slug: "update-profile",
      url: routes.personalProfile.path + `/`,
      icon: (
        <Icon>
          <EditOutlined style={styleIcon} />
        </Icon>
      ),
    },
  ];

  return (
    <div className="px-[4px]">
      {dataMenu.map((menu, idx) => (
        <LeftItemContent
          key={"candidate-" + idx + "-item-menu"}
          icon={menu.icon}
          title={menu.title}
          url={menu?.url}
          slug={menu?.slug}
        />
      ))}
    </div>
  );
}

const LeftItemContent = ({ icon, title, url, slug }) => {
  const { theme } = useTheme();
  const history = useHistory();
  const location = useLocation();
  const { action } = queryString.parse(location.search);
  return (
    <div
      className="relative cursor-pointer flex justify-start items-center space-x-2 py-2 z-1"
      onClick={() => url && history.push(url)}
    >
      <div
        className={cx(
          "absolute top-0 left-0 w-full h-full -z-1 hover:bg-opacity-10 rounded-[10px]",
          theme?.loginCandidateContent?.hover,
          ((action === slug && location.pathname === routes.userSetting.path) ||
            (location.pathname === routes.personalProfile.path &&
              slug === "update-profile")) &&
            theme?.loginCandidateContent?.active
        )}
      ></div>
      <div>{icon}</div>
      <div className={cx(theme?.landingPage?.heading?.title)}>{title}</div>
    </div>
  );
};

const Icon = ({ children }) => {
  return (
    <div className="w-[40px] h-[40px] relative bg-white rounded-full flex justify-center items-center">
      <div
        className={cx(
          "absolute top-0 left-0 w-full h-full -z-1 bg-black bg-opacity-10 rounded-full"
        )}
      ></div>
      {children}
    </div>
  );
};

export default LeftContent;
