import { notification } from "antd";
import React from "react";
import { useAuth } from "../Auth/useAuth";
import AdminService from "../../services/AdminService";
import { ADMIN_ROLE } from "../../utils/constants";

function AdsDetail({ data, active, onChangeActive, onChangeStatus }) {
  const { admin } = useAuth();
  const [api, contextHolder] = notification.useNotification({ top: 80 });;
  const ads = data;

  const handleChangeValidate = (status) => {
    const body = {
      id: ads?.id,
      role: admin?.sub_role,
      status: status,
    };

    if (data?.newlyUpdateData.length > 0) {
      AdminService.validateAds(body)
        .then((response) => {
          if (response) notificationAction("success");
          onChangeStatus();
        })
        .catch((error) => {
          notificationAction("error");
        });
    } else {
      AdminService.activeAds(body)
        .then((response) => {
          if (response) notificationAction("success");
          onChangeStatus();
        })
        .catch((error) => {
          notificationAction("error");
        });
    }
  };

  const notificationAction = (type) => {
    if (type === "success") {
      api.success({
        message: "Thành công",
        description: "Phê duyệt thành công",
        style: { top: 50 },
      });
    } else {
      api.success({
        message: "Thất bại",
        description: "Phê duyệt thất bại",
        style: { top: 50 },
      });
    }
  };

  return (
    <div
      className={`overflow-x-auto md:overflow-hidden fixed inset-0 z-9999 h-screen outline-none focus:outline-none justify-center items-center c-modal bg-black bg-opacity-20 ${
        active ? "block" : "hidden"
      }`}
    >
      {contextHolder}
      <div className="relative flex justify-center items-center mx-auto w-full px-[10px] md:px-0 h-[100vh] select-none">
        <div className="border-0 relative flex flex-col w-full bg-[#F2F7FF] outline-none focus:outline-none sm:w-[600px] rounded-[12px] p-[10px]">
          <p className="text-black text-[22px] text-center font-[600]">
            Chi tiết quảng cáo
          </p>
          <div
            className={`w-full h-[120px] rounded-tl-[12px] rounded-tr-[12px] bg-[#1495F3] relative cursor-pointer`}
          >
            <div
              className={`w-full sm:w-[500px] h-[240px] bg-[#F5F7FF] shadow-md absolute left-0 sm:left-10 -bottom-[120%] flex justify-center items-center`}
            >
              {ads?.image && (
                <img
                  src={ads.image}
                  alt=""
                  className="max-w-full max-h-full object-cover border-none"
                />
              )}
            </div>
          </div>
          <div className="w-full h-[380px] pt-[160px]">
            <p className="font-[600]">Nhà tuyển dụng: </p>
            <p className="font-[600]">Vị trí hiển thị: {ads?.position}</p>
            <p className="font-[600]">Thời gian hiển thị:</p>
            <p>Ngày bắt đầu: {data?.startTime}</p>
            <p>Ngày kết thúc: {data?.endTime}</p>

            <p className="font-[600] mt-1">
              Trạng thái:{" "}
              <span className="font-[500] italic">
                {ads?.status === "INPROGRESS"
                  ? "Chưa phê duyệt"
                  : ads?.status === "ACTIVE"
                  ? "Đã phê duyệt"
                  : "Đã từ chối"}
              </span>
            </p>
          </div>
          <div className="w-full flex justify-between items-center mt-4 space-x-2">
            <div className="flex justify-start items-center space-x-2">
              {admin?.sub_role === ADMIN_ROLE.SUPPER_ADMIN &&
                ads?.status === "INPROGRESS" && (
                  <>
                    <div
                      className="px-9 py-2 bg-red-400 text-white rounded-[12px] text-[12px] cursor-pointer"
                      onClick={() => handleChangeValidate("INACTIVE")}
                    >
                      Từ chối
                    </div>
                    <div
                      className="px-9 py-2 bg-[#1495F3] text-white rounded-[12px] text-[12px] cursor-pointer"
                      onClick={() => handleChangeValidate("ACTIVE")}
                    >
                      Phê duyệt
                    </div>
                  </>
                )}
            </div>
            <div
              className="px-9 py-2 bg-[#ccc] text-black rounded-[12px] text-[12px] cursor-pointer"
              onClick={onChangeActive}
            >
              Thoát
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdsDetail;
