import { useEffect, useMemo, useState } from "react";

import ThemeContext from "./ThemeContext";
import { getConfigMode } from "./config";
import { useLocalStorage } from "../useLocalStorage";

export const ThemeProvider = ({ children }) => {
  const [mode, setMode] = useLocalStorage("theme", "light_mode");
  const [theme, setTheme] = useState();

  useEffect(() => {
    setTheme(getConfigMode(mode));
  }, [mode]);

  const value = useMemo(
    () => ({
      mode,
      theme,
      setMode,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme]
  );
  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};
