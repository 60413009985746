import { Tooltip } from "antd";
import React from "react";
import { routes } from "../../screens/PathRouting";

const CardBox = (props) => {
  const { data } = props;
  return (
    <a
      href={routes.workDetail.parentPath + "/" + data.id}
      className="w-full h-[138px] bg-white rounded-[22px] p-[12px] cursor-pointer shadow-md"
    >
      <div className="w-full h-[80px] flex justify-start items-center">
        <div className="w-[80px] h-[80px] rounded-[9px] flex justify-center items-center">
          <img
            src={data?.image || ""}
            alt=""
            className="max-w-full max-h-full object-cover"
          />
        </div>
        <div className="ml-[10px]">
          <p className="text-[16px] font-[700] text-black line-clamp-1 select-none">
            <Tooltip title={data?.job}>{data?.job}</Tooltip>
          </p>
          <p className="text-[14px] text-[#8D9092] mt-[4px]">
            {data?.name_company}
          </p>
        </div>
      </div>
      <div className="w-full h-[30px] grid grid-cols-10 gap-1 my-[10px]">
        <div className="col-span-4 flex">
          <div className="w-[16px] h-[16px]">
            <img
              src={"/asserts/cardpos.png"}
              alt="dolar icon"
              className="object-cover"
            />
          </div>
          <p className="text-[12px] text-[#1B2124] ml-[5px]">{data?.salary}</p>
        </div>
        <div className="col-span-5 flex">
          <div className="w-[16px] h-[16px]">
            <img
              src={"/asserts/location.png"}
              alt="location icon"
              className="object-cover"
            />
          </div>
          <p className="text-[12px] text-[#1B2124] ml-[5px]">
            {data?.location}
          </p>
        </div>
        <div className="col-span-1 flex justify-end">
          <div className="w-[16px] h-[16px]">
            <img
              src={"/asserts/vector.png"}
              alt="clock icon"
              className="object-cover"
            />
          </div>
        </div>
      </div>
    </a>
  );
};

export default CardBox;
