import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  changeActions,
  selectActions,
  selectFormHeader,
  selectFormOrder,
  selectFormToShow,
  selectSetting,
} from "../../redux/slice/setting";
import { selectBuilder } from "../../redux/slice/builder";
import { cx } from "../../../lib/cx";
import { convertImageToBase64, formatContentCv } from "../../configs";
import CopyRightComponent from "../SubComponent/CopyRightComponent";

function ReviewCV003Modal({ id }) {
  const { openPreview } = useAppSelector(selectActions);
  const setting = useAppSelector(selectSetting);
  const builder = useAppSelector(selectBuilder);
  const { profile, objective, avatar } = builder;
  const { other } = useAppSelector(selectFormOrder);
  const formToShow = useAppSelector(selectFormToShow);
  const formHeader = useAppSelector(selectFormHeader);

  const dispatch = useAppDispatch();

  useEffect(() => {
    convertImageToBase64(avatar.url, (base64Image) => {
      const imgElement = document.getElementById("avatar-img");
      if (imgElement) {
        imgElement.src = base64Image;
      }
    });
  }, [avatar.url]);

  const styleText = {
    fontFamily: `${setting.fontFamily}`,
    fontSize: `${Number(setting.fontSize)}px`,
  };

  const styleTitle = {
    fontSize: `${Number(setting.fontSize) + 1}px`,
    color: "black",
    marginRight: "5px",
    fontWeight: 600,
    width: "110px",
    display: "inline",
  };

  const infoProfile = [
    {
      name: profile?.dateOfBirdth,
      title: "Ngày sinh:",
    },
    {
      name: profile?.phoneNumber,
      title: "Số điện thoại:",
    },
    {
      name: profile?.email,
      title: "Email:",
    },
    {
      name: profile?.website,
      title: "Website:",
    },
    {
      name: profile?.location,
      title: "Địa chỉ:",
    },
  ];

  return (
    <div
      className={cx(
        `overflow-x-auto md:py-[180px] xl:py-0 fixed inset-0 z-9999 h-screen outline-none focus:outline-none`,
        openPreview ? "block" : "hidden"
      )}
      onClick={() => dispatch(changeActions({ field: "openPreview" }))}
    >
      <div className="relative w-full min-h-[100vh]">
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-20"></div>
        <div className="relative flex justify-center items-center mx-auto w-full px-[10px] py-[72px] min-h-[100vh] select-none">
          <div
            className="relative w-[793px] min-h-[1112px] bg-white"
            style={{
              fontFamily: `${setting.fontFamily}`,
            }}
            id={id}
          >
            <div className="py-[10px]">
              <div className="flex">
                <div className="w-[210px] h-[200px] p-5">
                  <div className="w-[180px] h-[200px] bg-gray">
                    <img
                      id="avatar-img"
                      src={avatar.url}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <p
                    className="font-[700]"
                    style={{
                      fontFamily: `${setting.fontFamily}`,
                      fontSize: `${Number(setting.fontSize) + 20}px`,
                    }}
                  >
                    {profile?.fullName}
                  </p>
                  <p
                    className="font-[600]"
                    style={{
                      fontFamily: `${setting.fontFamily}`,
                      fontSize: `${Number(setting.fontSize) + 4}px`,
                    }}
                  >
                    {profile?.applyPosition}
                  </p>
                  <div className="w-full">
                    {infoProfile.map((item, index) => {
                      if (item.name) {
                        return (
                          <div key={"icon" + index + "item"} className="flex">
                            <p style={styleTitle}>{item.title}</p>
                            <span style={styleText}>{item.name}</span>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
              </div>

              <div className="px-[20px] pt-[30px] pb-[20px]">
                {other &&
                  other.map((item) => {
                    if (formToShow[item]) {
                      if (item === "objective") {
                        return (
                          <>
                            <div className="w-full border border-x-0 border-t-0 border-b-[black] pb-2 my-2">
                              <p
                                className="text-black pb-1"
                                style={{
                                  fontFamily: `${setting.fontFamily}`,
                                  fontSize: `${
                                    Number(setting?.fontSize) + 4
                                  }px`,
                                }}
                              >
                                <b>Mục tiêu nghề nghiệp</b>
                              </p>
                            </div>
                            <p className="mt-2" style={styleText}>
                              {formatContentCv(objective, "objective")}
                            </p>
                          </>
                        );
                      }
                      if (
                        [
                          "skills",
                          "cetifications",
                          "activeties",
                          "interestes",
                          "addtional_informations",
                        ].includes(item)
                      ) {
                        return (
                          <div className="mt-1">
                            <div className="w-full border border-x-0 border-t-0 border-b-[black] pb-2 my-2">
                              <p
                                className="text-black"
                                style={{
                                  fontFamily: `${setting.fontFamily}`,
                                  fontSize: `${Number(setting.fontSize) + 4}px`,
                                }}
                              >
                                <b>{formHeader[item]}</b>
                              </p>
                            </div>
                            {builder[item] &&
                              builder[item].map((c) => (
                                <div className="flex space-x-2 border border-x-0 border-t-0 border-b-gray-1 pb-2">
                                  {c?.description && (
                                    <p
                                      className={cx(
                                        "italic w-[25%]",
                                        item === "skills" && "font-[600]"
                                      )}
                                      style={styleText}
                                    >
                                      {c.description}
                                    </p>
                                  )}
                                  <p
                                    className={cx(
                                      item === "skills" && "font-[600]"
                                    )}
                                    style={styleText}
                                  >
                                    {c?.name}
                                  </p>
                                </div>
                              ))}
                          </div>
                        );
                      }
                      if (item === "projects") return null;
                      return (
                        <>
                          <div className="mt-2">
                            <div className="w-full border border-x-0 border-t-0 border-b-[black] pb-2">
                              <p
                                className="text-black"
                                style={{
                                  fontFamily: `${setting.fontFamily}`,
                                  fontSize: `${Number(setting.fontSize) + 4}px`,
                                }}
                              >
                                <b>{formHeader[item]}</b>
                              </p>
                            </div>
                            {builder[item] &&
                              builder[item].map((c) => (
                                <>
                                  <div className="w-full mt-1">
                                    <div className="w-full flex">
                                      <div className="w-[25%]">
                                        {(c?.start_time || c?.end_time) && (
                                          <div
                                            className="text-black italic font-[600]"
                                            style={styleText}
                                          >
                                            {c?.start_time}
                                            <span> - </span>
                                            {c?.end_time}
                                          </div>
                                        )}
                                      </div>
                                      <div className="w-[75%]">
                                        <p
                                          className="text-black font-[600]"
                                          style={{
                                            fontFamily: `${setting.fontFamily}`,
                                            fontSize: `${
                                              Number(setting.fontSize) + 4
                                            }px`,
                                          }}
                                        >
                                          {c?.title}
                                        </p>

                                        <div className="">
                                          {item === "projects" && (
                                            <span
                                              className="mr-1 italic"
                                              style={styleText}
                                            >
                                              <b>Số lượng người tham gia: </b>
                                            </span>
                                          )}
                                          <span
                                            className="italic"
                                            style={styleText}
                                          >
                                            <b>{c?.label}</b>
                                          </span>
                                        </div>

                                        {c?.sub_label && (
                                          <div className="">
                                            {item === "projects" && (
                                              <span
                                                className="mr-1 italic"
                                                style={styleText}
                                              >
                                                <b>Vị trí của bạn: </b>
                                              </span>
                                            )}
                                            <span
                                              className="italic"
                                              style={styleText}
                                            >
                                              <b>{c?.sub_label}</b>
                                            </span>
                                          </div>
                                        )}
                                        <p style={styleText}>
                                          {formatContentCv(
                                            c?.description,
                                            item
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                          </div>
                        </>
                      );
                    }
                    return null;
                  })}
              </div>
            </div>
            <CopyRightComponent />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewCV003Modal;
