import React from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { changeItemValue, selectBuilder } from "../../redux/slice/builder";
import InputCv from "../Input";
import {
  DEFAULT_FONT_SIZE,
  changeFormToChange,
  selectFontFamily,
  selectSetting,
} from "../../redux/slice/setting";
import { cx } from "../../../lib/cx";
import SettingBlock from "../Block/SettingBlock";
import { debounce } from "../../../utils";
import SettingChildBock from "../Block/SettingChildBlock";

function SubTrippleComponent({ form, listTitle, position }) {
  const state = useAppSelector(selectBuilder);
  const setting = useAppSelector(selectSetting);
  const { formHeader } = setting;
  const fontFamily = useAppSelector(selectFontFamily);
  const dispatch = useAppDispatch();

  const handleChangeActive = () => {
    dispatch(changeFormToChange({ field: "childActive", value: "" }));
  };

  return (
    <div className="mt-2">
      <SettingBlock form={form} position={position || "right"} isActive color>
        <div className="w-full border border-x-0 border-t-0 border-b-[black]">
          <p
            className="text-black pb-1"
            style={{
              fontSize: `${
                Number(setting.fontSize || DEFAULT_FONT_SIZE) +
                (position ? 4 : 8)
              }px`,
              fontFamily: `${fontFamily}`,
            }}
            onMouseMove={debounce(handleChangeActive, 10)}
          >
            <b>{formHeader[form]}</b>
          </p>
        </div>
        {state[form] &&
          state[form].map((item, index) => (
            <>
              <SettingChildBock form={form} index={index} isActived color>
                <SubTrippleItemComponent
                  data={item}
                  listTitle={listTitle}
                  index={index}
                  form={form}
                  setting={setting}
                />
                <div className="border-b border-b-gray-1"></div>
              </SettingChildBock>
            </>
          ))}
      </SettingBlock>
    </div>
  );
}

const SubTrippleItemComponent = ({ form, data, index, setting, listTitle }) => {
  const dispatch = useAppDispatch();
  const handleChangeInput = (field, value) => {
    dispatch(changeItemValue({ id: index, form, field, value }));
  };
  return (
    <div className="w-full mt-2">
      <div className="w-full flex">
        <div className="w-[25%] flex justify-start items-start">
          <div className="text-black text-[16px] font-[600]">
            <InputCv
              type="input"
              name="start_time"
              value={data?.start_time || ""}
              placeholder={"03/2023"}
              onChangeInput={handleChangeInput}
              className="!w-[70px] italic placeholder:italic"
            />
            <span> - </span>
            <InputCv
              type="input"
              name="end_time"
              value={data?.end_time || ""}
              placeholder={"03/2023"}
              onChangeInput={handleChangeInput}
              className="!w-[70px] italic placeholder:italic"
            />
          </div>
        </div>
        <div className="w-[75%]">
          <InputCv
            id={form + `_title_${index}`}
            name="title"
            value={data?.title || ""}
            placeholder={listTitle?.title || ""}
            onChangeInput={handleChangeInput}
            className="!mb-0 text-black font-[600]"
            size={4}
          />

          <div className="flex">
            {listTitle?.isCheck && (
              <p
                className="italic"
                style={{
                  width: `${
                    Number(setting.fontSize || DEFAULT_FONT_SIZE) * 25
                  }px`,
                  fontSize: `${
                    Number(setting.fontSize || DEFAULT_FONT_SIZE) + 2
                  }px`,
                }}
              >
                <b>{listTitle?.label}</b>
              </p>
            )}
            <InputCv
              id={form + `_label_${index}`}
              name="label"
              value={data?.label || ""}
              onChangeInput={handleChangeInput}
              type={listTitle?.isCheck ? "input" : "tetarea"}
              placeholder={!listTitle?.isCheck ? listTitle?.label : "1"}
              className={`${
                !listTitle?.isCheck ? "hover:border-x-0" : ""
              } font-[600] italic`}
            />
          </div>

          {listTitle?.sub_label && (
            <div className="flex justify-center items-center">
              {listTitle?.isCheck && (
                <p
                  className="italic"
                  style={{
                    width: `${
                      Number(setting.fontSize || DEFAULT_FONT_SIZE) * 11
                    }px`,
                    fontSize: `${
                      Number(setting.fontSize || DEFAULT_FONT_SIZE) + 2
                    }px`,
                  }}
                >
                  <b>{listTitle?.sub_label}</b>
                </p>
              )}
              <InputCv
                id={form + `_sub_label_${index}`}
                name="sub_label"
                value={data?.sub_label || ""}
                onChangeInput={handleChangeInput}
                type={listTitle?.isCheck ? "textarea" : "input"}
                placeholder={
                  !listTitle?.isCheck ? listTitle?.sub_label : "Nhân viên"
                }
                className={cx(
                  "font-[600]",
                  !listTitle?.isCheck ? "hover:border-x-0" : ""
                )}
              />
            </div>
          )}
          <InputCv
            id={form + `_description_${index}`}
            name="description"
            value={data?.description || ""}
            onChangeInput={handleChangeInput}
            placeholder={listTitle?.description || ""}
            className="placeholder:italic hover:border-x-0"
          />
        </div>
      </div>
    </div>
  );
};

export default SubTrippleComponent;
