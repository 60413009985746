import React from "react";
import CardCompany from "../Card/CardCompany";
import AdminService from "../../../services/AdminService";

function AddMember(props) {
  const { active, onChangeActive, group, companyId, onChangeAddGroup } = props;

  const onChangeAdd = async (id) => {
    await AdminService.addGroupToCompany(companyId, {
      groupId: [id],
      currentRole: "SUPER_ADMIN",
    });

    onChangeAddGroup();
  };

  return (
    <div
      className={`overflow-x-auto md:overflow-hidden fixed inset-0 z-9999 h-screen outline-none focus:outline-none justify-center items-center c-modal bg-black bg-opacity-20 ${
        active ? "block" : "hidden"
      }`}
    >
      <div className="relative flex justify-center items-center mx-auto w-full px-[10px] md:px-0 h-[100vh] select-none">
        <div className="border-0 relative flex flex-col w-full bg-[#F2F7FF] outline-none focus:outline-none sm:w-[1170px] rounded-[12px] p-[10px]">
          <p className="text-black text-[18px] font-[600]">
            Thêm nhóm vào dự án này:
          </p>
          <hr className="my-2" />
          <div className="w-full min-h-[320px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            {group &&
              group.length > 0 &&
              group.map((item, index) => (
                <CardCompany
                  index={index}
                  type={1}
                  data={item}
                  isAdd
                  onChangeAdd={(id) => {
                    onChangeAdd(id);
                  }}
                />
              ))}
          </div>
          <div className="w-full flex justify-end items-center mt-4">
            <div
              className="px-9 py-2 bg-[#ccc] text-black rounded-[12px] text-[12px] cursor-pointer"
              onClick={onChangeActive}
            >
              Thoát
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddMember;
