import React from "react";
import { useHistory } from "react-router-dom";
import { routes } from "../../screens/PathRouting";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";

function Breadcrumb(props) {
  const { data, isCheck } = props;
  const { theme } = useTheme();
  const history = useHistory();
  const menu = [
    !isCheck ? { name: "Trang chủ", path: routes.home.path } : {},
    ...data,
  ];
  return (
    <div className="w-full my-[60px]">
      {menu.map((item, index) =>
        index < menu.length - 1 && item?.name ? (
          <div key={"break-item-" + index + "-item"} className="inline">
            <p
              className={cx(
                "text-[16px] text-[#bbb] cursor-pointer font-[600] inline",
                theme?.background?.hover
              )}
              onClick={() => history.push(item.path)}
            >
              {item.name}
            </p>
            <span className={cx("mx-2 inline", theme?.background?.text)}>
              /
            </span>
          </div>
        ) : (
          <p
            key={"break-item-" + index + "-item"}
            className={cx(
              "text-[16px] font-[600] inline",
              theme?.background?.text
            )}
          >
            {item.name}
          </p>
        )
      )}
    </div>
  );
}

export default Breadcrumb;
