import React from "react";
import InputCv from "../Input";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { changeItemValue, selectBuilder } from "../../redux/slice/builder";
import { selectSetting } from "../../redux/slice/setting";
import SettingBlock from "../Block/SettingBlock";
import SettingChildBock from "../Block/SettingChildBlock";
import { cx } from "../../../lib/cx";

function DoubleComponent({ form, listTitle, other, position, titleColor }) {
  const setting = useAppSelector(selectSetting);
  const { formHeader } = setting;
  const state = useAppSelector(selectBuilder);
  return (
    <div className="w-full pt-4">
      <SettingBlock form={form} position={position || "left"} isActive>
        <div>
          <p
            className={cx(
              "font-[700]",
              other ? other : "text-white",
              position &&
                `border border-x-0 border-t-0 border-b-[black] ${position}`
            )}
            style={{
              fontFamily: `${setting.fontFamily}`,
              fontSize: `${Number(setting.fontSize) + 4}px`,
              color: `${titleColor || "white"}`,
            }}
          >
            {formHeader[form]}
          </p>
          {state[form] &&
            state[form].map((item, index) => (
              <>
                <SettingChildBock form={form} index={index} isActived>
                  <DoubleItemComponent
                    data={item}
                    id={index}
                    listTitle={listTitle}
                    form={form}
                    other={other}
                    position={position}
                    titleColor={titleColor}
                  />
                </SettingChildBock>
              </>
            ))}
        </div>
      </SettingBlock>
    </div>
  );
}

const DoubleItemComponent = ({
  data,
  id,
  form,
  listTitle,
  other,
  position,
  titleColor,
}) => {
  const dispatch = useAppDispatch();
  const handleChangeInput = (field, value) => {
    dispatch(changeItemValue({ id, form, field, value }));
  };
  return (
    <div
      className={cx(
        position &&
          `border border-x-0 border-t-0 border-b-gray flex py-2 ${position}`
      )}
    >
      <InputCv
        id={`${form}_title_${id}`}
        name="name"
        placeholder={listTitle?.title || ""}
        value={data.name || ""}
        onChangeInput={handleChangeInput}
        className={cx(
          !position && "font-[600]",
          other ? other : "text-white placeholder:text-[#fffdfd]"
        )}
        style={{
          color: titleColor ? "black" : "white",
        }}
      />
      {listTitle?.description && (
        <InputCv
          id={`${form}_desc_${id}`}
          name="description"
          value={data.description || ""}
          placeholder={listTitle.description || ""}
          onChangeInput={handleChangeInput}
          className={cx(
            !position && "font-[600]",
            other ? other : "text-white placeholder:text-[#fffdfd]"
          )}
        />
      )}
    </div>
  );
};

export default DoubleComponent;
