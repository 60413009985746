import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import {
  changeEducations,
  selectEducations,
} from "../../../lib/redux/resumeSlice";
import {
  changeShowBulletPoints,
  selectShowBulletPoints,
} from "../../../lib/redux/settingsSlice";
import { Form, FormSection } from "./Form";
import { BulletListIconButton } from "./Form/IconButton";
import { BulletListTextarea, Input } from "./Form/InputGroub";

export const EducationsForm = () => {
  const educations = useAppSelector(selectEducations);
  const dispatch = useAppDispatch();
  const showDelete = educations.length > 1;
  const form = "educations";
  const showBulletPoints = useAppSelector(selectShowBulletPoints(form));

  return (
    <Form form={form} addButtonText="Thêm mới">
      {educations.map(({ school, degree, gpa, date, descriptions }, idx) => {
        const handleEducationChange = (...[field, value]) => {
          dispatch(changeEducations({ idx, field, value }));
        };

        const handleShowBulletPoints = (value) => {
          dispatch(changeShowBulletPoints({ field: form, value }));
        };

        const showMoveUp = idx !== 0;
        const showMoveDown = idx !== educations.length - 1;

        return (
          <FormSection
            key={idx}
            form="educations"
            idx={idx}
            showMoveUp={showMoveUp}
            showMoveDown={showMoveDown}
            showDelete={showDelete}
            deleteButtonTooltipText="Xóa trường"
          >
            <Input
              label="Tên trường"
              labelClassName="col-span-4"
              name="school"
              placeholder="Nhập tên trường"
              value={school}
              onChange={handleEducationChange}
            />
            <Input
              label="Thời gian tốt nghiệp"
              labelClassName="col-span-2"
              name="date"
              placeholder="03/2023"
              value={date}
              onChange={handleEducationChange}
            />
            <Input
              label="Ngành học"
              labelClassName="col-span-4"
              name="degree"
              placeholder="Nhập ngành học"
              value={degree}
              onChange={handleEducationChange}
            />
            <Input
              label="GPA"
              labelClassName="col-span-2"
              name="gpa"
              placeholder="3.81"
              value={gpa}
              onChange={handleEducationChange}
            />
            <div className="relative col-span-full">
              <BulletListTextarea
                label="Thông tin thêm"
                labelClassName="col-span-full"
                name="descriptions"
                placeholder="Nhập các phần thưởng, hoạt động, v.v.."
                value={descriptions}
                onChange={handleEducationChange}
                showBulletPoints={showBulletPoints}
              />
              <div className="absolute left-[7.6rem] top-[0.07rem]">
                <BulletListIconButton
                  showBulletPoints={showBulletPoints}
                  onClick={handleShowBulletPoints}
                />
              </div>
            </div>
          </FormSection>
        );
      })}
    </Form>
  );
};
