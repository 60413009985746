import React, { useEffect, useState } from "react";
import { cx } from "../../lib/cx";
import { useTheme } from "../../lib/theme/useTheme";
import { useAuth } from "../Auth/useAuth";
import CardEmpty from "../components/Card/CardEmpty";
import CreateAdsModal from "../components/Modal/CreateAdsModal";
import AdminService from "../../services/AdminService";
import queryString from "query-string";
import CardAds from "../components/Card/CardAds";
import UserService from "../../services/UserService";
import { getImageUrl } from "../../utils";
import dayjs from "dayjs";
import { ADMIN_ROLE } from "../../utils/constants";
import { Pagination } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { changePagination } from "../../lib/theme/config";
import AdsDetail from "./AdsDetail";

function AdsManagement() {
  const { theme } = useTheme();
  const { admin } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const search = queryString.parse(location.search);

  const [listAds, setListAds] = useState();
  const [totalPage, setTotaPage] = useState(0);

  const [adsDetail, setAdsDetail] = useState();

  const [toggleCreateModal, setToggleCreateModal] = useState(false);
  const [toggleDetail, setToggleDetail] = useState(false);

  const limit = 11;

  useEffect(() => {
    if (!listAds) {
      getAllAds(search?.page || 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listAds]);

  const getAllAds = async (page) => {
    const params = {
      offset: page - 1,
      limit: limit,
      status: "",
    };
    const response = await AdminService.getAllAds(
      queryString.stringify(params)
    );
    const data = response.data.data;

    let listImage = data.items.map((item) => item.image);
    listImage = await UserService.getImageByName(listImage);

    const result = data.items.map((item) => ({
      ...item,
      origin_image: item.image,
      image: getImageUrl(listImage.data.data, item.image),
      startTime: dayjs(new Date(item.startTime)).format("DD-MM-YYYY"),
      endTime: dayjs(new Date(item.endTime)).format("DD-MM-YYYY"),
    }));

    setListAds(result);
    setTotaPage(data.count);
  };

  const onChangePage = (page) => {
    history.replace(location.pathname + queryString({ page: page }));
    getAllAds(page);
  };

  return (
    <div
      className={cx(
        "mx-auto min-h-[80vh] lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <div className="px-4">
        <div className={cx("my-[60px]", theme?.background?.text)}>
          <p className="text-[32px] font-[600] leading-none">Dashboad</p>
          <p className="text-[26px]">{admin?.nickname}</p>
        </div>

        <div className="w-full flex justify-between items-center my-4">
          <p className={cx("text-[20px] font-[600]", theme?.background?.text)}>
            Quản lý danh mục quảng cáo
          </p>
          <p className="text-black hover:underline cursor-pointer"></p>
        </div>

        <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 mb-[60px]">
          <CardEmpty
            icon="/icons/elementplus.png"
            title="Thêm quảng cáo"
            onClick={() => setToggleCreateModal(true)}
          />
          {listAds &&
            listAds.map((item) => (
              <>
                <CardAds
                  data={item}
                  status={item.status}
                  isBlock={admin.sub_role === ADMIN_ROLE.ADS_MANAGER}
                  onHandleDetail={() => {
                    setAdsDetail(item);
                    setToggleDetail(true);
                  }}
                  onHandleEdit={() => {
                    setAdsDetail(item);
                    setToggleCreateModal(true);
                  }}
                />
              </>
            ))}
        </div>
        <div className="w-full flex justify-center items-center">
          {totalPage > 0 && (
            <Pagination
              defaultCurrent={search?.page || 1}
              total={totalPage}
              pageSize={limit}
              onChange={onChangePage}
              itemRender={changePagination}
            />
          )}
        </div>
      </div>
      <CreateAdsModal
        id={adsDetail?.id}
        active={toggleCreateModal}
        onChangeActive={() => {
          getAllAds(search?.page || 1);
          setToggleCreateModal(!toggleCreateModal);
        }}
      />
      <AdsDetail
        data={adsDetail}
        active={toggleDetail}
        onChangeActive={() => setToggleDetail(!toggleDetail)}
        onChangeStatus={() => {
          getAllAds(search?.page || 1);
          setToggleDetail(!toggleDetail);
        }}
      />
    </div>
  );
}

export default AdsManagement;
