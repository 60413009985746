import React, { useState } from "react";
import { cx } from "../../../lib/cx";
import { EyeFilled } from "@ant-design/icons";
import PaymentDetailModal from "../Modal/PaymentDetailModal";

function TablePayment({ data }) {
  const [toggleActive, setToggleActive] = useState(false);
  const [paymentData, setPaymentData] = useState();
  return (
    <div>
      <div className="overflow-x-auto">
        <table className="w-full min-w-[1320px] max-w-[5000px]">
          <thead>
            <tr className="w-full bg-[#333333] rounded-[10px]">
              {[
                "STT",
                "Mã",
                "Tên khách hàng",
                "Loại",
                "Ngày đăng ký",
                "Ngày hết hạn",
                "Tổng tiền",
                "Trạng thái",
                "",
              ].map((item, idx) => (
                <th key={"title" + item + idx} className="px-8 py-2 text-white">
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.length > 0 &&
              data.map((c, idx) => (
                <tr
                  key={"data" + idx}
                  className={cx(
                    "cursor-pointer opacity-80 hover:opacity-100",
                    idx % 2 === 0 && "bg-[#ccc]"
                  )}
                  onClick={() => {
                    setPaymentData(c);
                    setToggleActive(true);
                  }}
                >
                  {[
                    idx + 1,
                    c.code,
                    c.customerName,
                    c.type,
                    c.startTime,
                    c.endTime,
                    c.amount,
                  ].map((sub, ids) => (
                    <td
                      key={"sub" + idx + ids}
                      className={cx("text-center", ids === 6 && "text-right")}
                    >
                      {sub}
                    </td>
                  ))}
                  <td className="w-full min-h-[26px] flex justify-center items-center">
                    <div
                      className={cx(
                        "px-4 text-white rounded-[20px] text-[12px]",
                        c.status === "SUCCESS" && "bg-green-600",
                        c.status === "ERROR" && "bg-red-500",
                        c.status === "INPROGRESS" && "bg-blue-500",
                        c.status === "EXPIRED" && "bg-orange-500",
                        c.status === "TOEXPIRE" && "bg-violet-500"
                      )}
                    >
                      {c.status === "SUCCESS" && "Đã thanh toán"}
                      {c.status === "ERROR" && "Lỗi"}
                      {c.status === "INPROGRESS" && "Đang xử lý"}
                      {c.status === "EXPIRED" && "Hết hạn"}
                      {c.status === "TOEXPIRE" && "Sắp hết hạn"}
                    </div>
                  </td>
                  <td>
                    <div className="w-[90px] px-4 text-white rounded-[20px] text-[12px] bg-blue-400 ">
                      <EyeFilled /> Chi tiết
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <PaymentDetailModal
        data={paymentData}
        active={toggleActive}
        onChangeActive={() => {
          setToggleActive(!toggleActive);
        }}
      />
    </div>
  );
}

export default TablePayment;
