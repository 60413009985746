import { Tooltip } from "antd";
import React from "react";
import SelectActive from "../Select/SelectActive";
import { FileTextOutlined } from "@ant-design/icons";

function CardNews(props) {
  const { data, status, isBlock, onHandleDetail } = props;
  return (
    <div className="w-full p-[15px] bg-white rounded-[26px]">
      <div className="grid grid-cols-5 gap-1">
        <div className="col-span-4">
          <Tooltip title={data?.title}>
            <div className="min-h-[41px] max-h-[41px] line-clamp-2 text-black text-[20px] font-[600] leading-none">
              {data?.title}
            </div>
          </Tooltip>

          <div className="flex justify-start items-center mt-2 text-gray">
            <div className="w-[12px] h-[13px] mr-2">
              <img
                src="/asserts/location.png"
                alt=""
                className="w-full h-full object-cover"
              />
            </div>
            <p className="line-clamp-1">{data?.tag[0]}</p>
          </div>
        </div>
        <div className="col-span-1 flex justify-start">
          <SelectActive
            active={status}
            slug={data?.slug}
            isBlock={isBlock}
          />
        </div>
      </div>
      <div className="mt-4">
        <p>Người đăng bài: ldo</p>
        <p>Thời gian: {data?.createAt}</p>
      </div>
      <div className="grid grid-cols-2 gap-4 pt-8">
        <div></div>
        <div className="flex justify-end items-center">
          <div
            className="px-3 py-1 bg-[#1495F3] flex justify-center items-center rounded-[20px] cursor-pointer"
            onClick={onHandleDetail}
          >
            <FileTextOutlined style={{ color: "white", fontSize: "12px" }} />{" "}
            <span className="text-white text-[12px] ml-2">Xem chi tiết</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardNews;
