import React, { useEffect, useState } from "react";

function InputAdmin({
  label,
  name,
  valueInput,
  onChange,
  placeholder,
  error,
  required,
  className,
}) {
  const [value, setValue] = useState(valueInput);

  useEffect(() => {
    setValue(valueInput);
  }, [valueInput]);

  return (
    <div className={className}>
      <label className="font-[600]">
        {label} {required && <span className="ml-1 text-red-500">*</span>}
      </label>
      <input
        name={name}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e);
        }}
        placeholder={placeholder}
        className={`w-full border-b-[1px] focus:outline-none px-3 py-2 bg-[#F4F9FF] text-[14px] text-black rounded-md ${
          error ? "border-b-[#E60019]" : "border-b-gray "
        }`}
      />
    </div>
  );
}

export default InputAdmin;
