import { EyeFilled } from "@ant-design/icons";
import React from "react";

function CardCV({ data, onEdit, onDetail }) {
  return (
    <div className="w-full h-[260px] rounded-[12px] bg-gray-1 shadow-md relative">
      <div className="w-full h-full flex justify-center items-center">
        <img
          src={data?.image}
          alt=""
          className="max-w-full h-full object-cover"
        />
      </div>
      <div className="absolute w-full h-full top-0 left-0 opacity-10 rounded-[12px]"></div>
      <div className="absolute w-full h-full top-0 left-0 flex flex-col justify-end items-end rounded-[12px] p-[10px]">
        <div className="w-full flex flex-col justify-start text-black">
          <b>{data?.name}</b>
          <p>{data?.department}</p>
          <p>{data?.type && data.type[0]}</p>
        </div>
        <div className="flex space-x-3">
          <div
            className="px-3 py-1 bg-blue-400 rounded-[18px] text-[14px] text-white cursor-pointer"
            onClick={onDetail}
          >
            <EyeFilled /> Chi tiết
          </div>
          <div
            className="px-3 py-1 bg-violet-400 rounded-[18px] text-[14px] text-white cursor-pointer"
            onClick={onEdit}
          >
            Cập nhật
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardCV;
