import React from "react";
import FacebookCustomerChat from "react-facebook-customer-chat";

function ChatMessage() {
  return (
    <FacebookCustomerChat
      pageId="1624396397804564"
      appId="215341391514838"
      loggedInGreeting="Xin chào! Chúng tôi có thể giúp gì cho bạn?"
      loggedOutGreeting="Xin chào! Hãy đăng nhập để trò chuyện với chúng tôi."
    />
  );
}

export default ChatMessage;
