import { ArrowRightOutlined, DownOutlined } from "@ant-design/icons";
import { DeleteOutline } from "@mui/icons-material";
import React from "react";
import { renderAvatarList } from "../../../utils";

function CardEmployee(props) {
  const { index, onHandleChange, onHandleDetail, onHandleDelete, data } = props;
  const colors = [
    "#0055FF",
    "#FF9900",
    "#5E2E86",
    "#5E2E86",
    "#FFF500",
    "#EF0032",
    "#00FF1A",
  ];

  return (
    <div className="w-full h-[200px] rounded-[26px] bg-white cursor-default">
      <div
        className="w-full h-[30px] rounded-tl-[26px] rounded-tr-[26px]"
        style={{ background: `${colors[index % 7] || "#0055FF"}` }}
      ></div>
      <div className="p-[10px] w-full">
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-2">
            <p className="text-[18px] font-[600] text-black">
              Nhóm: {data?.groupName}
            </p>
            <p className="text-[14px] font-[600] text-black">
              Số lượng nhân sự: {data?.groupMember}
            </p>
          </div>
          <div>
            <div
              className="cursor-pointer px-3 py-1 flex justify-center items-center rounded-[32px] text-[12px] text-white bg-[#E60019] mb-1"
              onClick={() =>
                onHandleDelete({ id: data?.id, name: data?.groupName })
              }
            >
              Xóa nhóm{" "}
              <DeleteOutline
                style={{ color: "white", fontSize: "12px", marginLeft: "5px" }}
              />
            </div>
            <div
              className="px-3 py-1 text-white bg-[#1495F3] flex justify-center items-center rounded-[32px] cursor-pointer text-[12px]"
              onClick={onHandleDetail}
            >
              Chi tiết{" "}
              <ArrowRightOutlined
                style={{ color: "white", fontSize: "12px", marginLeft: "5px" }}
              />
            </div>
          </div>
        </div>
        <div className="w-full h-[40px] flex my-2">
          {data?.listRecruiterImg &&
            renderAvatarList(data.listRecruiterImg, 8).map((item) => item)}
        </div>
        <div
          className="w-full flex flex-col justify-center items-center cursor-pointer"
          onClick={onHandleChange}
        >
          <p className="text-[#1495F3]">Xem thêm</p>
          <DownOutlined style={{ color: "#1495F3" }} />
        </div>
      </div>
    </div>
  );
}

export default CardEmployee;
