import React from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  changeActions,
  changeFormToShow,
  selectFormHeader,
  selectFormOrder,
  selectFormToShow,
} from "../../redux/slice/setting";
import { MoreOutlined } from "@ant-design/icons";
import { cx } from "../../../lib/cx";
import { ArrowBack } from "@mui/icons-material";

function AddMoreBlock() {
  const { left, right, other } = useAppSelector(selectFormOrder);
  const formHeader = useAppSelector(selectFormHeader);
  const formToShow = useAppSelector(selectFormToShow);

  const dispatch = useAppDispatch();

  const handleChangeActive = (field) => {
    dispatch(changeFormToShow({ field }));
  };

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className="w-[350px] min-h-[150px] bg-white p-[20px]">
        <p className="mb-[20px] text-center">
          Click vào item để ẩn hoặc hiện thẻ{" "}
        </p>
        {other && (
          <div>
            <strong>Các thành phần</strong>
            {other.map((item) => (
              <>
                <AddMoreItemComponent
                  title={formHeader[item]}
                  isActive={formToShow[item]}
                  onChangeActive={() => handleChangeActive(item)}
                />
              </>
            ))}
          </div>
        )}
        {right && (
          <div>
            <strong>Các thành phần bên phải</strong>
            {right.map((item) => (
              <>
                <AddMoreItemComponent
                  title={formHeader[item]}
                  isActive={formToShow[item]}
                  onChangeActive={() => handleChangeActive(item)}
                />
              </>
            ))}
          </div>
        )}
        {left && (
          <div className="mt-8">
            <strong>Các thành phần bên trái</strong>
            {left.map((item) => (
              <>
                <AddMoreItemComponent
                  title={formHeader[item]}
                  isActive={formToShow[item]}
                  onChangeActive={() => handleChangeActive(item)}
                />
              </>
            ))}
          </div>
        )}
      </div>

      <div className="flex justify-center items-center">
        <div
          className="mt-4 bg-gray text-white px-4 py-1 text-[14px] rounded-[22px] cursor-pointer"
          onClick={() => {
            dispatch(changeActions({ field: "openAddMore" }));
          }}
        >
          <ArrowBack /> Quay lại
        </div>
      </div>
    </div>
  );
}

const AddMoreItemComponent = ({ title, isActive, onChangeActive }) => {
  return (
    <div
      className={cx(
        "w-full flex justify-between items-center  border  px-4 py-2 mb-2 cursor-pointer",
        isActive ? "bg-[#555] border-[#555]" : "bg-[#ccc] border-[#ccc]"
      )}
      onClick={onChangeActive}
    >
      <p className="text-white">{title}</p>
      <MoreOutlined style={{ color: "white" }} />
    </div>
  );
};

export default AddMoreBlock;
