import React, { useEffect, useState } from "react";

import CardCV from "../../components/CardItem/CardCV";
import UserService from "../../services/UserService";
import dayjs from "dayjs";
import { routes } from "../PathRouting";
import { useHistory, useLocation } from "react-router-dom";
import { Modal, notification } from "antd";
import { getImageUrl } from "../../utils";
import { cx } from "../../lib/cx";
import { useTheme } from "../../lib/theme/useTheme";

export const ApplyCV = ({
  open,
  onCancel,
  onChangeActive,
  recruiterId,
  jdId,
  jdName,
}) => {
  const { theme } = useTheme();
  const profile = JSON.parse(localStorage.getItem("User"));
  const [listCvUserUpload, setListCvUserUpload] = useState();
  const [itemSelected, setItemSelected] = useState();

  const history = useHistory();
  const location = useLocation();

  notification.config({ top: 80, placement: "topRight" });

  useEffect(() => {
    if (!listCvUserUpload && profile) getAllCvByUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCvUserUpload, profile]);

  const handleAppliedCv = () => {
    if (itemSelected) {
      const dataSend = {
        candidateId: profile?.id,
        jdApplyList: [
          {
            recruiterId: recruiterId,
            jdId: jdId,
          },
        ],
        jdId: jdId,
        cvApplyList: [
          {
            candidateId: profile?.id,
            cvImage: itemSelected?.img,
            cvName: itemSelected?.name,
            status: "In Progress",
            applyAt: dayjs(new Date()).format("YYYY-MM-DD"),
          },
        ],
        title: jdName,
        content: "có ứng viên mới",
        type: "recruiter",
        cvName: itemSelected?.img,
      };
      UserService.applyCvInJob(dataSend)
        .then(() => {
          onChangeActive();
          notification.success({
            message: "Thành công",
            description:
              "Đã ứng tuyển thành công. Nhà tuyển dụng sẽ phản hồi với bạn trong thời gian sớm nhất!",
            duration: 4,
            style: { top: 50 },
          });
        })
        .catch((error) => {
          console.log(error);
          notification.error({
            message: "Thất bại",
            description: " Ứng tuyển thất bại. Vui lòng thử lại sau!",
            duration: 4,
            style: { top: 50 },
          });
        });
    }
  };

  const getAllCvByUser = () => {
    const loginStatus = JSON.parse(localStorage.getItem("loginStatus"));
    if (loginStatus?.role && loginStatus.role === "Recruiter") {
      history.push(routes.recruiterDashboard.path);
    } else {
      UserService.getAllCvByUserId(profile.id).then((response) => {
        const listCvImg = response.data.data.listCvImg;
        const listCvCreate = response.data.data.listCvPdf;
        const listImg = listCvImg.map((item) => item.img[0]);
        const listCreate = listCvCreate.map((item) => item.img[0]);

        UserService.getImageByName(listImg.concat(listCreate)).then(
          (result) => {
            const data = result.data.data;
            setListCvUserUpload(
              formatCvShow(listCvCreate, data).concat(
                formatCvShow(listCvImg, data)
              )
            );
          }
        );
      });
    }
  };

  const formatCvShow = (listCv, listImage) => {
    return listCv.map((cv) => ({
      img: cv.img[0],
      name: cv.name.split(".pdf")[0],
      image: getImageUrl(listImage, cv.img[0]),
      createAt: dayjs(new Date(cv.createAt)).format("DD-MM-YYYY"),
    }));
  };

  return (
    <Modal
      open={open}
      footer={
        <div className="w-full flex justify-end items-center space-x-3">
          <button
            onClick={() =>
              history.push(
                routes.templateCv.path + `?redirect=${location.pathname}`
              )
            }
            className={cx(
              "px-3 py-2 rounded-[12px] text-white",
              theme?.button?.background
            )}
          >
            Tạo CV nhanh
          </button>
          <div
            className="bg-[#0055FF] px-6 py-2 text-white rounded-[12px] cursor-pointer"
            onClick={handleAppliedCv}
          >
            OK
          </div>
        </div>
      }
      onCancel={onCancel}
      bodyStyle={{ padding: "10px" }}
      width={800}
    >
      <div className="col-span-1 lg:col-span-6 mt-[10px]">
        <p className="text-[24px] font-[700] text-black text-center">
          Chọn CV ứng tuyển
        </p>
        <div className="w-full my-[10px]">
          <p className="text-black text-[14px] font-[600]">
            Chọn một cv và ứng tuyển vào vị trí công việc
          </p>
        </div>
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {listCvUserUpload && listCvUserUpload.length > 0 ? (
            listCvUserUpload.map((data) => (
              <div className="mb-4 flex items-center justify-center">
                <CardCV
                  key={data.cvImage}
                  isChoose={itemSelected?.img}
                  data={data}
                  onChangeImage={(value) => {
                    setItemSelected(
                      itemSelected?.img !== value?.img ? value : null
                    );
                  }}
                />
              </div>
            ))
          ) : (
            <p>Bạn chưa có cv tạo trên hệ thống</p>
          )}
        </div>
      </div>
    </Modal>
  );
};
