import React, { useEffect, useState } from "react";
import { routes } from "../PathRouting";
import { useLocation } from "react-router-dom";
import UserService from "../../services/UserService";
import { marked } from "marked";
import { getImageUrl } from "../../utils";
import ScrollTop from "../../components/ScrollTop";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import Breadcrumb from "../../components/Tag/Breadcrumb";

function RecruiterDetail() {
  const { theme } = useTheme();
  const location = useLocation();
  const [recruiterData, setRecruiterData] = useState();

  useEffect(() => {
    if (!recruiterData) {
      UserService.getDescriptionRecuiter(getId())
        .then((result) => {
          const recruiter = result.data.data;
          let listImage = [
            recruiter.storyImg[0],
            recruiter.avatarImg[0],
            recruiter.aboutUsImg[0],
            ...recruiter.officeImg,
          ];
          UserService.getImageByName(listImage)
            .then((image) => {
              const img = image.data.data;
              const response = {
                ...recruiter,
                avatarImg: getImageUrl(img, recruiter.avatarImg[0]),
                aboutUsImg: getImageUrl(img, recruiter.aboutUsImg[0]),
                storyImg: getImageUrl(img, recruiter.storyImg[0]),
                officeImg: recruiter.officeImg.map((item) =>
                  getImageUrl(img, item)
                ),
              };
              setRecruiterData(response);
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recruiterData]);

  const getId = () => {
    const cutString = location.pathname.split("/");
    return cutString[cutString.length - 1];
  };

  return (
    <div
      className={cx(
        "container mx-auto h-auto lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <ScrollTop />
      <div className="px-4">
        <Breadcrumb
          data={[
            {
              name: "Danh sách công việc",
              path: routes.workList.path,
            },
            {
              name: location?.state?.job,
              path: routes.workDetail.parentPath + `/${location?.state?.id}`,
            },
            {
              name: recruiterData?.enterprise,
            },
          ]}
        />

        <div className="w-full min-h-[120px] bg-white rounded-[15px] p-[24px]">
          <div className="w-full grid grid-col-1 lg:grid-cols-10">
            <div className="col-span-1 lg:col-span-8 flex">
              <div className="w-[80px] h-[80px] rounded-[50%] flex justify-center items-center p-2 bg-[#eee]">
                {recruiterData?.avatarImg && (
                  <img
                    src={recruiterData.avatarImg}
                    alt=""
                    className="max-w-full max-h-full object-cover"
                  />
                )}
              </div>

              <div className="ml-2">
                <p>Công ty</p>
                <p className="text-black font-[600] text-[18px]">
                  {recruiterData?.enterprise}
                </p>
                <div className="flex justify-start items-center">
                  <div className="w-[12px] h-[12px]">
                    <img
                      src={"/asserts/location.png"}
                      alt="location icon"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <p className="text-[14px] ml-2">
                    {recruiterData?.enterpriseAddress &&
                      recruiterData.enterpriseAddress[0]}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[10px] w-full min-h-[80px] bg-white rounded-[15px] p-[20px]">
          <p className="text-[24px] text-black font-[600]">Về Chúng Tôi</p>
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
            {recruiterData?.introduction && (
              <div
                dangerouslySetInnerHTML={{
                  __html: marked(recruiterData.introduction),
                }}
              />
            )}
            <div>
              {recruiterData?.aboutUsImg && (
                <div className="w-full h-[240px]">
                  <img
                    src={recruiterData.aboutUsImg}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-[10px] w-full min-h-[80px] bg-white rounded-[15px] p-[20px]">
          <p className="text-[24px] text-black font-[600]">
            Văn phòng {recruiterData?.enterprise}
          </p>
          <div className="w-full">
            <div>
              <p className="text-black text-[14px]">
                Địa chỉ văn phòng của doanh nghiệp.
              </p>
              {recruiterData &&
                recruiterData.enterpriseAddress &&
                recruiterData.enterpriseAddress.length > 0 &&
                recruiterData.enterpriseAddress.map((address) => (
                  <p className="text-black text-[14px] py-3">- {address}</p>
                ))}
            </div>
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1">
              {recruiterData?.officeImg &&
                recruiterData.officeImg.map((office) => (
                  <div className="w-full h-[180px] rounded-[12px]">
                    <img
                      src={office}
                      alt=""
                      className="w-full h-full object-cover rounded-[12px]"
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="mt-[10px] w-full min-h-[80px] bg-white rounded-[15px] p-[20px]">
          <p className="text-[24px] text-black font-[600]">
            Câu chuyện của {recruiterData?.enterprise}
          </p>
          <hr className="my-[20px]" />
          <div className="w-full">
            <div>
              {recruiterData?.story && recruiterData.story.length > 0 && (
                <p className="text-black text-[14px] py-3">
                  {recruiterData.story.map((item) => (
                    <div dangerouslySetInnerHTML={{ __html: marked(item) }} />
                  ))}
                </p>
              )}
            </div>
            <div className="w-full grid grid-cols-3 gap-1">
              {recruiterData?.storyImg && (
                <div className="w-full h-[180px] rounded-[12px]">
                  <img
                    src={recruiterData.storyImg}
                    alt=""
                    className="w-full h-full object-cover rounded-[12px]"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecruiterDetail;
