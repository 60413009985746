import React from "react";
import AdminService from "../../../services/AdminService";

function RemoveGroupModal(props) {
  const { data, active, onChangeActive } = props;
  const onHandleDelete = () => {
    AdminService.deleteGroupById(data?.id).then((res) => {
      if (res) onChangeActive();
    });
  };
  return (
    <div
      className={`overflow-x-auto md:overflow-hidden fixed inset-0 z-9999 h-screen outline-none focus:outline-none justify-center items-center c-modal bg-black bg-opacity-20 ${
        active ? "block" : "hidden"
      }`}
    >
      <div className="relative flex justify-center items-center mx-auto w-full px-[10px] md:px-0 h-[100vh] select-none">
        <div className="border-0 relative flex flex-col w-full bg-[#F2F7FF] outline-none focus:outline-none sm:w-[350px] rounded-[12px] p-[10px]">
          <p className="text-black text-[18px] font-[600]">
            Xóa nhóm <span className="font-[600]">{data?.name}</span>{" "}
          </p>
          <hr className="my-2" />
          <div className="w-full min-h-[80px]">
            <p>
              Bạn có chắc chắn xóa nhóm{" "}
              <span className="underline font-[600]">{data?.name}</span> khỏi danh sách
            </p>
          </div>
          <div className="w-full flex justify-end items-center mt-4 space-x-2">
            <div
              className="px-9 py-2 bg-[#ccc] text-black rounded-[12px] text-[12px] cursor-pointer"
              onClick={onChangeActive}
            >
              Hủy
            </div>
            <div
              className="px-9 py-2 bg-blue-500 text-white rounded-[12px] text-[12px] cursor-pointer"
              onClick={onHandleDelete}
            >
              Xác nhận
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RemoveGroupModal;
