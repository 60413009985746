import { configureStore } from "@reduxjs/toolkit";
import buiderReducer from "./slice/builder";
import settingReducer from "./slice/setting";

export const storeBuilder = configureStore({
  reducer: {
    builder: buiderReducer,
    setting: settingReducer,
  },
});
