import React, { useEffect, useState } from "react";
import AvatarItem from "../Avatar/AvatarItem";
import AdminService from "../../../services/AdminService";
import queryString from "query-string";
import UserService from "../../../services/UserService";
import dayjs from "dayjs";

function AddUser(props) {
  const { groupId, active, onHandleUpdate, onChangeActive } = props;
  const [inputSearch, setInputSearch] = useState("");
  const [listMember, setListMember] = useState();

  useEffect(() => {
    if (!listMember) {
      getListMember();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listMember]);

  const getListMember = async (search = "") => {
    const params = queryString.stringify(
      search ? { groupId: groupId, search: search } : { groupId: groupId }
    );
    const response = await AdminService.getAllMember(`?${params}`);
    const data = response.data.data;
    const listImage = await UserService.getImageByName(
      data.map((item) => item.avatarImg[0])
    );
    const result = data.map((item, index) => ({
      id: item.id,
      name: item.fullName,
      joinDate: dayjs(new Date(item.createAt)).format("DD/MM/YYYY"),
      avatar: listImage.data.data[index],
    }));
    setListMember(result);
  };

  const onChangeAction = (userId) => {
    const data = {
      currentRole: "SUPER_ADMIN",
      listRecruiter: [userId],
    };
    AdminService.updateUserInGroup(groupId, data).then(() => {
      getListMember();
      onHandleUpdate();
    });
  };

  return (
    <div
      className={`overflow-x-auto md:overflow-hidden fixed inset-0 z-9999 h-screen outline-none focus:outline-none justify-center items-center c-modal bg-black bg-opacity-20 ${
        active ? "block" : "hidden"
      }`}
    >
      <div className="relative flex justify-center items-center mx-auto w-full px-[10px] md:px-0 h-[100vh] select-none">
        <div className="border-0 relative flex flex-col w-full bg-[#F2F7FF] outline-none focus:outline-none sm:w-[960px] rounded-[12px] p-[10px]">
          <p className="text-black text-[22px] text-center font-[600]">
            Thêm nhân sự vào nhóm
          </p>
          <div className="w-full min-h-[34px] flex px-4 py-1 bg-white rounded-[12px] my-4">
            <div className="w-[80%] flex justify-start items-center">
              <div className="w-[14px] h-[14px]">
                <img
                  src={"/asserts/icons/search.png"}
                  alt="icon search"
                  className="object-cover"
                />
              </div>
              <input
                type="text"
                name="search"
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
                className="w-full focus:outline-none ml-[10px] text-[14px] border-r-[1px]"
                placeholder="Tìm kiếm"
              />
            </div>

            <div className="w-[20%] flex justify-center items-center">
              <div
                className="w-full text-center text-[14px] bg-[#1495F3] rounded-[12px] px-3 py-2 text-white cursor-pointer hidden md:block"
                onClick={() => getListMember(inputSearch)}
              >
                Tìm kiếm
              </div>
              <div
                className="w-[14px] h-[14px] block md:hidden cursor-pointer"
                onClick={() => getListMember(inputSearch)}
              >
                <img
                  src={"/asserts/icons/search.png"}
                  alt="icon search"
                  className="object-cover"
                />
              </div>
            </div>
          </div>
          <div className="w-full min-h-[240px]">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {listMember &&
                listMember.length > 0 &&
                listMember.map((item) => (
                  <AvatarItem
                    data={item}
                    level={4}
                    onHandleChange={onChangeAction}
                  />
                ))}
            </div>
          </div>
          <div className="w-full flex justify-end items-center mt-4">
            <div
              className="px-9 py-2 bg-[#ccc] text-black rounded-[12px] text-[12px] cursor-pointer"
              onClick={onChangeActive}
            >
              Thoát
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUser;
