import { CheckOutlined } from "@ant-design/icons";
import React from "react";

function TagColor(props) {
  const { name, color, activeColor, onChangeColor } = props;
  return (
    <div
      className="w-[24px] h-[24px] rounded-[50%] flex justify-center items-center cursor-pointer"
      style={{ backgroundColor: `${color}` }}
      onClick={() => onChangeColor(name, color)}
    >
      {activeColor === color && (
        <div>
          <CheckOutlined
            style={{ color: "white", fontSize: "12px", fontWeight: "800" }}
          />
        </div>
      )}
    </div>
  );
}

export default TagColor;
