import React from "react";
import "./index.css";
import "./i18n";
import { createRoot } from "react-dom/client";
import MainPage from "./screens";

const container = document.getElementById("root");

const root = createRoot(container);

root.render(<MainPage />);
