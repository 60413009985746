import React, { useEffect, useState } from "react";
import InputAdmin from "../Input";
import { useFormik } from "formik";
import Textarea from "../../../cv/components/Input/Textarea";
import { Delete } from "@mui/icons-material";
import AdminService from "../../../services/AdminService";
import { notification } from "antd";

function FormParsing({ dataParsing, cvFile }) {
  useEffect(() => {
    if (dataParsing) updateForm(dataParsing);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataParsing]);

  const formParsingFormik = useFormik({
    initialValues: {
      name: "",
      jobPosition: "",
      dateOfBirth: "",
      email: "",
      phoneNumber: "",
      summary: "",
      address: "",
      skills: [{ name: "" }],
      certifications: [{ name: "" }],
      education: [
        {
          school: "",
          degree: "",
          fieldOfStudy: "",
          startDate: "",
          endDate: "",
        },
      ],
      workExperience: [
        {
          company: "",
          position: "",
          startDate: "",
          endDate: "",
          experience: "",
        },
      ],
    },
    onSubmit: (values) => {
      try {
        const data = {
          cvFile: cvFile,
          ...values,
          workExperience: values.workExperience.map((c) => ({
            ...c,
            experience: Number(c.experience),
          })),
        };
        AdminService.saveParsingCv(data)
          .then(() => {
            notification.success({
              message: "Thành công",
              description: `Lưu CV thành công`,
              duration: 4,
              style: { top: 90 },
            });
          })
          .catch((error) => {
            notification.error({
              message: "Thất bại",
              description: `Lưu CV thất bại`,
              duration: 4,
              style: { top: 90 },
            });
            console.error(new Error(error));
          });
      } catch (error) {
        console.error(new Error(error));
      }
    },
  });

  const getMessageError = (field) => {
    return formParsingFormik.errors[field] && formParsingFormik.touched[field]
      ? formParsingFormik.errors[field]
      : "";
  };

  const updateForm = (data) => {
    const keys = Object.keys(data);
    for (const key of keys) {
      let result = data[key];
      if (key === "workExperience" && data[key] && data[key].length > 0) {
        result = data[key].map((c) => ({ ...c, experience: "" }));
      }
      changeValueSelect(key, result);
    }
  };

  const changeValueSelect = (key, value) => {
    formParsingFormik.handleChange({
      target: {
        name: key,
        value: value,
      },
    });
  };

  return (
    <div>
      <div className="flex justify-start md:justify-end items-center">
        <div
          className="text-center text-white cursor-pointer py-1 bg-[#333] w-[120px] my-4 rounded-md"
          onClick={formParsingFormik.handleSubmit}
        >
          Lưu CV
        </div>
      </div>
      <form className="w-full h-[460px] xl:h-[70vh] p-[8px] border border-[#ccc] overflow-y-auto">
        <p className="my-2 font-[600] text-[20px]">Thông tin cá nhân</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <InputAdmin
            name="name"
            label="Họ tên"
            valueInput={formParsingFormik.values.name}
            onChange={formParsingFormik.handleChange}
            error={getMessageError("name")}
            required
          />
          <InputAdmin
            name="dateOfBirth"
            label="Ngày sinh"
            valueInput={formParsingFormik.values.dateOfBirth}
            onChange={formParsingFormik.handleChange}
            error={getMessageError("dateOfBirth")}
            required
          />
        </div>
        <div className="grid grid-cols-1 mt-2">
          <InputAdmin
            name="jobPosition"
            label="Vị trí ứng tuyển"
            valueInput={formParsingFormik.values.jobPosition}
            onChange={formParsingFormik.handleChange}
            error={getMessageError("jobPosition")}
            required
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-2">
          <InputAdmin
            name="email"
            label="Email"
            valueInput={formParsingFormik.values.email}
            onChange={formParsingFormik.handleChange}
            error={getMessageError("email")}
            required
          />
          <InputAdmin
            name="phoneNumber"
            label="Số điện thoại"
            valueInput={formParsingFormik.values.phoneNumber}
            onChange={formParsingFormik.handleChange}
            error={getMessageError("phoneNumber")}
            required
          />
        </div>
        <div className="grid grid-cols-1 mt-2">
          <InputAdmin
            name="address"
            label="Địa chỉ"
            valueInput={formParsingFormik.values.address}
            onChange={formParsingFormik.handleChange}
            error={getMessageError("address")}
            required
          />
        </div>
        <p className="my-2 font-[600] text-[20px]">Mục tiêu nghề nghiệp</p>
        <Textarea
          id="id_summary"
          name="summary"
          value={formParsingFormik.values.summary}
          onChangeInput={formParsingFormik.handleChange}
          placeholder="Mục tiêu nghề nghiệp"
        />
        <SchoolForm
          id="education"
          label="Học vấn"
          data={formParsingFormik.values.education}
          onChangeValue={formParsingFormik.handleChange}
        />
        <WorkExperienceForm
          id="workExperience"
          label="Kinh nghiệm làm việc"
          data={formParsingFormik.values.workExperience}
          onChangeValue={formParsingFormik.handleChange}
        />
        <SingleForm
          id="skills"
          label="Kĩ năng"
          data={formParsingFormik.values.skills}
          onChangeValue={formParsingFormik.handleChange}
          placeholder="Tên kĩ năng"
        />
        <SingleForm
          id="certifications"
          label="Chứng chỉ"
          data={formParsingFormik.values.certifications}
          onChangeValue={formParsingFormik.handleChange}
          placeholder="Tên chứng chỉ"
        />
      </form>
    </div>
  );
}

const SingleForm = ({ data, id, label, onChangeValue, placeholder }) => {
  const [listItem, setListItem] = useState([]);

  useEffect(() => {
    if (data) {
      setListItem(data);
    }
  }, [data]);

  const onChangeInput = (e, idx) => {
    const newData = listItem.map((item, index) => {
      if (index === idx) {
        return { ...item, [e.target.name]: e.target.value };
      }
      return item;
    });
    setListItem(newData);
    handleChangeValue(newData);
  };

  const addItem = () => {
    const newData = [...listItem, { name: "" }];
    setListItem(newData);
    handleChangeValue(newData);
  };

  const deleteItem = (id) => {
    const newData = [...listItem];
    newData.splice(id, 1);
    setListItem(newData);
    handleChangeValue(newData);
  };

  const handleChangeValue = (value) => {
    onChangeValue({
      target: {
        name: id,
        value: value,
      },
    });
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="my-2 font-[600] text-[20px]">{label}</p>
        <div
          className="text-red-500 w-[20px] h-[20px] rounded-full border border-[red] flex justify-center items-center font-[600] cursor-pointer"
          onClick={addItem}
        >
          +
        </div>
      </div>
      {listItem.map((item, index) => (
        <div
          key={"id-" + id + "-" + index}
          className="w-full flex justify-start items-center"
        >
          <InputAdmin
            name="name"
            valueInput={item.name}
            onChange={(e) => onChangeInput(e, index)}
            className="!w-[96%]"
            placeholder={placeholder}
          />
          <div
            className="w-[20px] flex justify-center items-center cursor-pointer"
            onClick={() => {
              deleteItem(index);
            }}
          >
            <Delete style={{ color: "red", fontSize: "18px" }} />
          </div>
        </div>
      ))}
    </div>
  );
};

const WorkExperienceForm = ({ data, id, label, onChangeValue }) => {
  const [listItem, setListItem] = useState([]);

  useEffect(() => {
    if (data) {
      setListItem(data);
    }
  }, [data]);

  const onChangeInput = (e, idx) => {
    const newData = listItem.map((item, index) => {
      if (index === idx) {
        return { ...item, [e.target.name]: e.target.value };
      }
      return item;
    });
    setListItem(newData);
    handleChangeValue(newData);
  };

  const addItem = () => {
    const newData = [
      ...listItem,
      { company: "", position: "", startDate: "", endDate: "", experience: "" },
    ];
    setListItem(newData);
    handleChangeValue(newData);
  };

  const deleteItem = (id) => {
    const newData = [...listItem];
    newData.splice(id, 1);
    setListItem(newData);
    handleChangeValue(newData);
  };

  const handleChangeValue = (value) => {
    onChangeValue({
      target: {
        name: id,
        value: value,
      },
    });
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="my-2 font-[600] text-[20px]">{label}</p>
        <div
          className="text-red-500 w-[20px] h-[20px] rounded-full border border-[red] flex justify-center items-center font-[600] cursor-pointer"
          onClick={addItem}
        >
          +
        </div>
      </div>
      {listItem.map((item, index) => (
        <div key={"id-" + id + "-" + index}>
          <div className="w-full flex justify-start items-center">
            <div className="w-[96%]">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <InputAdmin
                  name="company"
                  valueInput={item.company}
                  onChange={(e) => onChangeInput(e, index)}
                  placeholder="Công ty"
                />
                <div className="grid grid-cols-2 gap-3">
                  <InputAdmin
                    name="startDate"
                    valueInput={item.startDate}
                    onChange={(e) => onChangeInput(e, index)}
                    placeholder="Bắt đầu"
                  />
                  <InputAdmin
                    name="endDate"
                    valueInput={item.endDate}
                    onChange={(e) => onChangeInput(e, index)}
                    placeholder="Kết thúc"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                <div className="col-span-2">
                  <InputAdmin
                    name="position"
                    valueInput={item.position}
                    onChange={(e) => onChangeInput(e, index)}
                    placeholder="Vị trí làm việc"
                  />
                </div>
                <InputAdmin
                  name="experience"
                  valueInput={item.experience}
                  onChange={(e) => onChangeInput(e, index)}
                  placeholder="Số năm kinh nghiệm"
                />
              </div>
            </div>
            <div
              className="w-[20px] flex justify-center items-center cursor-pointer"
              onClick={() => {
                deleteItem(index);
              }}
            >
              <Delete style={{ color: "red", fontSize: "18px" }} />
            </div>
          </div>
          <div className="w-full border-t my-4"></div>
        </div>
      ))}
    </div>
  );
};

const SchoolForm = ({ data, id, label, onChangeValue }) => {
  const [listItem, setListItem] = useState([]);

  useEffect(() => {
    if (data) {
      setListItem(data);
    }
  }, [data]);

  const onChangeInput = (e, idx) => {
    console.log("----e", e);
    const newData = listItem.map((item, index) => {
      if (index === idx) {
        return { ...item, [e.target.name]: e.target.value };
      }
      return item;
    });
    setListItem(newData);
    handleChangeValue(newData);
  };

  const addItem = () => {
    const newData = [
      ...listItem,
      { school: "", degree: "", fieldOfStudy: "", startDate: "", endDate: "" },
    ];
    setListItem(newData);
    handleChangeValue(newData);
  };

  const deleteItem = (id) => {
    const newData = [...listItem];
    newData.splice(id, 1);
    setListItem(newData);
    handleChangeValue(newData);
  };

  const handleChangeValue = (value) => {
    onChangeValue({
      target: {
        name: id,
        value: value,
      },
    });
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="my-2 font-[600] text-[20px]">{label}</p>
        <div
          className="text-red-500 w-[20px] h-[20px] rounded-full border border-[red] flex justify-center items-center font-[600] cursor-pointer"
          onClick={addItem}
        >
          +
        </div>
      </div>
      {listItem.map((item, index) => (
        <div
          key={"id-" + id + "-" + index}
          className="w-full flex justify-start items-center"
        >
          <div className="w-[96%]">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <InputAdmin
                name="school"
                valueInput={item.school}
                onChange={(e) => onChangeInput(e, index)}
                placeholder="Tên trường"
              />
              <div className="grid grid-cols-2 gap-3">
                <InputAdmin
                  name="startDate"
                  valueInput={item.startDate}
                  onChange={(e) => onChangeInput(e, index)}
                  placeholder="Bắt đầu"
                />
                <InputAdmin
                  name="endDate"
                  valueInput={item.endDate}
                  onChange={(e) => onChangeInput(e, index)}
                  placeholder="Kết thúc"
                />
              </div>
            </div>
            <InputAdmin
              name="fieldOfStudy"
              value={item.fieldOfStudy}
              onChange={(e) => onChangeInput(e, index)}
              placeholder="Chuyên ngành"
            />
            <InputAdmin
              name="degree"
              valueInput={item.degree}
              onChange={(e) => onChangeInput(e, index)}
              placeholder="Loại bằng tốt nghiệp"
            />
          </div>
          <div
            className="w-[20px] flex justify-center items-center cursor-pointer"
            onClick={() => {
              deleteItem(index);
            }}
          >
            <Delete style={{ color: "red", fontSize: "18px" }} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default FormParsing;
