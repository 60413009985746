import React from "react";
import useGetCandidateInfo from "../../hooks/useGetCandidateInfo";
import { cx } from "../../lib/cx";
import { useTheme } from "../../lib/theme/useTheme";
import { ModeNight } from "@mui/icons-material";
import { Radio } from "antd";

function RightContent() {
  const { theme } = useTheme();
  const { setMode, mode } = useTheme();
  const { refarral, earnPoint } = useGetCandidateInfo();

  return (
    <div className={cx("w-full px-[10px]", theme?.landingPage?.heading?.title)}>
      <div className="w-full my-1">
        <p>
          Mã liên kết:{" "}
          <strong>{refarral ? refarral.code : "Chưa cập nhật"}</strong>
        </p>
        <p className="break-words">
          Link liên kết:{" "}
          <strong className="">
            {refarral ? refarral.link : "Chưa cập nhật"}
          </strong>
        </p>
        <p>
          Điểm: <strong>{earnPoint ? earnPoint.point : 0}</strong>
        </p>
      </div>
      <div className="flex space-x-2 mt-[20px]">
        <div className="mt-[10px]">
          <div className="bg-white w-[30px] h-[30px] flex justify-center items-center rounded-full">
            <ModeNight style={{ fontSize: "24px", color: "black" }} />
          </div>
        </div>
        <div>
          <p className="text-[18px] font-[600]">Chế độ tối</p>
          <p>
            Điều chỉnh giao diện của LDO để giảm độ chói và cho đôi mắt
            được nghỉ ngơi
          </p>
          <div className="flex justify-between items-center my-[10px]">
            <div className="space-y-2">
              <p>Đang tắt</p>
              <p>Bật</p>
            </div>
            <Radio.Group
              onChange={(e) => {
                setMode(e.target.value);
              }}
              value={mode}
            >
              <div className="flex flex-col space-y-2">
                <Radio value="light_mode" />
                <Radio value="dark_mode" />
              </div>
            </Radio.Group>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RightContent;
