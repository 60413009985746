import React from "react";
import Tag from "../Tag";
import { Tooltip } from "antd";

function CardSearch(props) {
  const { item, href } = props;
  return (
    <a
      href={href}
      className="w-full lg:min-h-[100px] shadow-md bg-white p-[10px] rounded-[12px] grid grid-cols-1 lg:grid-cols-3 my-[10px] cursor-pointer"
      target="__blank"
    >
      <div className="col-span-1 lg:col-span-2">
        <div className="grid grid-cols-6">
          <div className="w-[80px] h-[80px] flex justify-center items-center rounded-[12px] col-span-1">
            {item.image ? (
              <img
                src={item.image}
                alt=""
                className="max-w-full max-h-full object-cover"
              />
            ) : (
              <div className="w-full h-full bg-[#D9D9D9] rounded-[12px]"></div>
            )}
          </div>

          <div className="w-full lg:border-r-[2px] border-r-[#D9D9D9] ml-2 md:ml-0 col-span-5 pl-7 pr-[10px]">
            <p className="text-[18px] font-[700] text-black line-clamp-1 select-none">
              <Tooltip title={item.job}>{item.job}</Tooltip>
            </p>
            <p className="text-[14px] text-black">{item.name_company}</p>
            <Tag
              color="#DDE4FF40"
              title={
                item.dueTime > 0
                  ? `Còn ${item.dueTime} ngày để ứng tuyển`
                  : "Hết thời gian ứng tuyển"
              }
              className="!text-[10px] !py-[2px] !px-[8px] !text-black mt-4"
            />
          </div>
        </div>
      </div>

      <div className="col-span-1 px-2">
        <div className="w-full flex mb-2">
          <div className="w-full flex justify-between gap-1">
            <div className="flex items-center">
              <img
                src={"/asserts/location.png"}
                alt="icon"
                className="w-[12px] h-[12px]"
              />
              <span className="text-[12px] ml-1 text-black">{item.location}</span>
            </div>
            <div className="flex items-center">
              <img
                src={"/asserts/dollar-circle.png"}
                alt="icon"
                className="w-[12px] h-[12px]"
              />
              <span className="text-[12px] ml-1 text-black">{item.salary}</span>
            </div>
          </div>
        </div>
        {item?.jobType && (
          <Tag
            title={item.jobType}
            color={"#78C58C"}
            className="py-1 h-auto !px-1"
          />
        )}
        {item?.experience && <Tag title={item.experience} color={"#B5C1FF"} />}
        <div className="w-full flex justify-end items-end">
          <img
            src={"/asserts/vector.png"}
            alt=""
            className="w-[14px] h-[14px]"
          />
        </div>
      </div>
    </a>
  );
}

export default CardSearch;
