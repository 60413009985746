import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { pathAdmin } from "../../screens/PathRouting";
import CardEmpty from "../components/Card/CardEmpty";
import CardCompany from "../components/Card/CardCompany";
import { Pagination } from "antd";
import CreateGroupModal from "../components/Modal/CreateGroupModal";
import AdminService from "../../services/AdminService";
import queryString from "query-string";
import BreadcrumbAdmin from "../components/Tag/Breadcrumb";
import { cx } from "../../lib/cx";
import { useTheme } from "../../lib/theme/useTheme";

function GroupList() {
  const { theme } = useTheme();
  const [listGroups, setListGroups] = useState();
  const [openCreatePopup, setOpenCreatePopup] = useState(false);

  const [totalItem, setTotalItem] = useState(1);

  const history = useHistory();
  const location = useLocation();

  const search = queryString.parse(location.search);

  useEffect(() => {
    if (!listGroups) {
      getAllListGroup(search?.page || 1);
    }
  }, [listGroups, search]);

  const getAllListGroup = async (page) => {
    const listGroups = await AdminService.getAllGroup(
      queryString.stringify({
        offset: page - 1 || 0,
        limit: 11,
      })
    );

    const data = listGroups.data.data.filter(
      (item, index) => index > 0 && item
    );
    const result = data.map((item) => ({
      id: item.id,
      groupName: item.name,
      numberEmployee: item.countRecruiters,
      job: item.projectCompany.map((item) => item.name).join(", "),
      countJds: item.countJds,
      countCandidate: item.countCVs,
    }));
    setListGroups(result);
    const pages = listGroups.data.data.find((item) => item.countTotal);
    setTotalItem(pages?.countTotal || 10);
  };

  const handleClickPage = (values) => {
    history.replace(
      location.pathname + "?" + queryString.stringify({ page: values })
    );
    getAllListGroup(values);
  };

  return (
    <div
      className={cx(
        "mx-auto min-h-[80vh] lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <div className="px-4">
        <BreadcrumbAdmin
          data={[
            {
              name: "Quản lý nhân sự",
              path: pathAdmin.adminManagePersonal.path,
            },
            { name: "Các nhóm nhân sự đã tạo" },
          ]}
        />

        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 my-[60px]">
          <CardEmpty
            icon="/icons/folderadd.png"
            title="Thêm nhóm"
            onClick={() => setOpenCreatePopup(!openCreatePopup)}
          />
          {listGroups &&
            listGroups.length > 0 &&
            listGroups.map((item, index) => (
              <CardCompany
                index={index}
                type={1}
                data={item}
                onHandleClick={() =>
                  history.push(
                    pathAdmin.adminGroupDetail.paretPath + `/${item.id}`
                  )
                }
              />
            ))}
        </div>

        <div className="w-full flex justify-center items-center">
          <Pagination
            defaultCurrent={search?.page || 1}
            total={totalItem}
            onClick={handleClickPage}
          />
        </div>
      </div>
      <CreateGroupModal
        active={openCreatePopup}
        onChangeActive={() => {
          getAllListGroup(search?.page || 1);
          setOpenCreatePopup(!openCreatePopup);
        }}
      />
    </div>
  );
}

export default GroupList;
