export function convertImageToBase64(url, callback) {
  var img = new Image();
  img.crossOrigin = "Anonymous";
  img.onload = function () {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    canvas.width = this.width;
    canvas.height = this.height;
    ctx.drawImage(this, 0, 0);
    var dataURL = canvas.toDataURL("image/jpeg");
    callback(dataURL);
    canvas = null;
  };
  img.src = url;
}

export function formatContentCv(str, key) {
  if (!str) return "";
  const cutString = str.split("\n");
  return cutString.map((item, idx) => <p key={key + idx}>{item}</p>);
}
