import React from "react";
import { debounce } from "../../utils";
import { DeleteOutlined } from "@ant-design/icons";
import Input from "../../components/Input";

function CertificateComponent(props) {
  const { data, onChangeRemove, onChangeValue } = props;
  return (
    <div className="w-full min-h-[40px] border border-x-0 border-t-[#0055FF] border-b-0 py-[10px]">
      <div className="w-full grid grid-cols-9">
        <div className="col-span-8 pr-4">
          <Input
            name="name"
            value={data?.name || ""}
            onChangeInput={debounce(onChangeValue)}
            placeholder="Tên chứng chỉ"
            className="!border-b-[#ccc] text-[18px] font-[600] text-black"
          />
          <div className="max-w-[140px] flex justify-start items-center">
            <Input
              name="time_graduate"
              value={data?.time_graduate}
              type="date"
              pattern="\d{2}-\d{2}\d{4}"
              className="border-b-[#616161]"
              onChangeInput={debounce(onChangeValue)}
            />
          </div>
        </div>
        <div className="col-span-1 mt-3 md:mt-0 flex justify-end items-center">
          <div
            className="w-[50px] h-[50px] rounded-[18px] bg-[#E60019] flex justify-center items-center cursor-pointer"
            onClick={() => onChangeRemove(data.id)}
          >
            <DeleteOutlined className="text-white" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CertificateComponent;
