import React from "react";
import { formatVND } from "../../utils";
import { CloseOutlined } from "@ant-design/icons";

function PaymentComboModal({ data, active, onChangeActive, onChangeNow }) {
  return (
    <div
      className={`overflow-y-scroll md:overflow-y-hidden overflow-x-hidden fixed inset-0 z-9999 h-screen outline-none focus:outline-none justify-center items-center c-modal bg-black bg-opacity-40 ${
        active ? "" : "hidden"
      }`}
      onClick={onChangeActive}
    >
      <div className="relative flex justify-center items-center mx-auto w-full px-[10px] md:px-0 h-[100vh] select-none my-[120px] md:my-0">
        <div className="border-0 relative flex flex-col w-full bg-white outline-none focus:outline-none sm:w-[980px] rounded-[12px]">
          <div className="flex justify-end items-center pr-2 pt-2 cursor-pointer">
            <CloseOutlined />
          </div>
          <div className="p-[10px] md:p-[24px] md:pt-2 w-full">
            <div className="w-full flex justify-between items-center">
              <div className="text-[22px] font-[500] mb-4">{data?.name}</div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="col-span-1">
                <table>
                  <thead>
                    <tr>
                      <th className="w-[50%] py-1 text-[14px] text-center bg-[#B7E1FF] rounded-[12px] border-[1px] border-white">
                        DỊCH VỤ
                      </th>
                      <th className="w-[19%] py-1 text-[12px] text-center bg-[#B7E1FF] rounded-[12px] border-[1px] border-white">
                        THỜI GIAN
                      </th>
                      <th className="w-[9%] py-1 text-[12px] text-center bg-[#B7E1FF] rounded-[12px] border-[1px] border-white">
                        SL
                      </th>
                      <th className="w-[20%] py-1 text-[12px] text-center bg-[#B7E1FF] rounded-[12px]">
                        ĐƠN GIÁ GỐC
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.detail &&
                      data?.detail.map((item, idx) => (
                        <tr key={"payment-d-" + idx + "-detail"}>
                          {item?.number ? (
                            <>
                              {item.number === 1 && (
                                <td
                                  className="bg-[#F4F9FF] py-1 px-2 rounded-[12px] border-[1px] border-white"
                                  rowSpan="4"
                                >
                                  {item.title}
                                </td>
                              )}
                            </>
                          ) : (
                            <td className="bg-[#F4F9FF] py-1 px-2 rounded-[12px] border-[1px] border-white">
                              {item.title}
                            </td>
                          )}
                          <td className="text-center py-1 px-2 bg-[#F4F9FF] rounded-[12px] border-[1px] border-white">
                            {item.date}
                          </td>
                          <td className="text-center py-1 px-2 bg-[#F4F9FF] rounded-[12px] border-[1px] border-white">
                            {item.amount}
                          </td>
                          <td className="text-center py-1 px-2 bg-[#F4F9FF] rounded-[12px] border-[1px] border-white">
                            {formatVND(item.price)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="col-span-1">
                <p className="text-[18px] font-[600]">Mô tả dịch vụ:</p>
                <ul className="pl-[20px]">
                  {data?.description &&
                    data.description.map((item, idx) => (
                      <li
                        key={"desc-d-" + idx + "-i"}
                        className="list-disc text-gray text-justify"
                      >
                        {item}
                      </li>
                    ))}
                </ul>
              </div>
            </div>

            <div className="block mt-[20px] md:hidden">
              <div className="flex justify-center items-center space-x-2">
                <div
                  className="w-auto px-6 py-2 flex justify-center items-center bg-gray rounded-[12px] text-center text-white cursor-pointer"
                  onClick={() => {
                    onChangeActive();
                  }}
                >
                  Thoát
                </div>
                <div
                  className="w-auto px-6 py-2 flex justify-center items-center bg-[#0055FF] rounded-[12px] text-center text-white cursor-pointer"
                  onClick={() => {
                    onChangeNow();
                    onChangeActive();
                  }}
                >
                  Đăng kí ngay
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentComboModal;
