import React, { useState, useEffect } from "react";
import { Divider, Card } from "antd";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { MailOutlined, EditOutlined } from "@ant-design/icons";
import { Bt } from "../../../components/BtForm";
import UserService from "../../../services/UserService";
import { getRecruiterByMail, getImage } from "../../../services/PORT";
import { marked } from "marked";
import { useHistory } from "react-router-dom";
import { routes } from "../../PathRouting";
import { getImageUrl } from "../../../utils";
import Breadcrumb from "../../../components/Tag/Breadcrumb";

const { Grid } = Card;

export const ManageBS = (props) => {
  const history = useHistory();

  // Phần chứa nội dung của màn
  const [businessesName, setBusinessesName] = useState(""); // Tên doanh nghiệp
  const [addressBS, setAdressBS] = useState([]); // địa chỉ doanh nghiệp
  const [addressWebBS, setAdressWebBS] = useState(""); // địa chỉ web của doanh nghiệp
  const [phoneNumBS, setPhoneNumBS] = useState(""); // số điện thoại liên hệ doanh nghiệp
  const [mailAdress, setMailAdress] = useState(""); // địa chỉ mail doanh nghiệp
  const [aboutUs, setAboutUs] = useState(""); // giới thiệu doanh nghiệp
  const [story, setStory] = useState([]); // câu chuyện trong doanh nghiệp
  const [welfareList, setWelfareList] = useState([]); // danh sách phúc lợi công ty

  // Phần ảnh tương ứng nội dung
  const [logo, setLogo] = useState();
  const [aboutUsImage, setAboutUsImage] = useState("");
  const [officeImage, setOfficeImage] = useState([]);
  const [storyImage, setStoryImage] = useState([]);

  const data = JSON.parse(localStorage.getItem("User"));

  //
  useEffect(() => {
    if (!logo) {
      UserService.getDescriptionBusiness(getRecruiterByMail, data.email)
        .then(async (res) => {
          const temp = res.data.data;
          localStorage.setItem("User", JSON.stringify(temp));
          setBusinessesName(temp?.enterprise || "");
          setAdressBS(temp?.enterpriseAddress || "");
          setPhoneNumBS(temp?.phoneNumber || "");
          setMailAdress(temp?.email || "");
          setAboutUs(temp?.introduction || "");
          setStory(temp?.story || "");
          setWelfareList(temp?.welfare || "");
          setAdressWebBS(temp?.website || "");

          const listImage = [
            data.avatarImg[0],
            temp.aboutUsImg[0],
            ...temp.officeImg,
            ...temp.storyImg,
          ];

          const response = await UserService.getImage(getImage, {
            filename: listImage,
          });

          setLogo(getImageUrl(response.data.data, data.avatarImg[0]));
          setAboutUsImage(getImageUrl(response.data.data, data.aboutUsImg[0]));
          setOfficeImage(
            temp.officeImg.map((item) => getImageUrl(response.data.data, item))
          );
          setStoryImage(
            temp.storyImg.map((item) => getImageUrl(response.data.data, item))
          );
        })
        .catch((err) => {
          console.log("error >>>>>>", err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logo]);

  const handleEdit = () => {
    history.push(routes.recruiterUpdate.path, {
      businessesName: businessesName,
      addressBS: addressBS,
      addressWebBS: addressWebBS,
      phoneNumBS: phoneNumBS,
      mailAdress: mailAdress,
      aboutUs: aboutUs,
      story: story,
      welfareList: welfareList,
      logo: logo,
      aboutUsImage: aboutUsImage,
      officeImage: officeImage,
      storyImage: storyImage,
    });
  };

  return (
    <div className="px-[10%] py-[2%] space-y-3">
      <Breadcrumb
        data={[
          {
            name: "Dashboard",
            path: routes.recruiterDashboard.path,
          },
          {
            name: "Quản lý hồ sơ doanh nghiệp",
          },
        ]}
        isCheck
      />
      <div className="flex flex-col md:flex-row justify-between p-[2%] bg-white rounded-[12px]">
        <div className="flex flex-col md:flex-row md:space-x-10">
          <div className="w-[100px] h-[100px] flex justify-center items-center">
            <img
              src={logo}
              alt=""
              className="max-w-full max-h-full object-cover"
            />
          </div>

          <div>
            <h3 className="font-[600] text-[20px] text-[#8D9092]">Công ty</h3>
            <h4 className="font-[600] text-[20px] text-[#1B2124]">
              {businessesName}
            </h4>
            <p>
              <LanguageOutlinedIcon fontSize="inherit" /> {addressWebBS}
            </p>
            <p>
              <MailOutlined /> {mailAdress}
            </p>
          </div>
        </div>
        <Bt
          title="Chỉnh sửa"
          className="bg-blue-500 rounded-[12px] h-[40px]"
          icon={<EditOutlined />}
          onClick={handleEdit}
        />
      </div>

      {/* Thẻ đầu tiên giới thiệu doanh nghiệp */}
      <div className="bg-white p-[2%] rounded-[12px]">
        <h3 className="text-black font-[600] text-[28px]">Về Chúng Tôi</h3>
        <Divider className="h-[2px] bg-[#B5C1FF]" />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <div dangerouslySetInnerHTML={{ __html: marked(aboutUs) }} />
          <img
            src={aboutUsImage}
            className="rounded-[12px] w-full h-[400px]"
            loading="lazy"
            alt=""
          />
        </div>
      </div>

      {/* giới thiệu văn phòng */}
      <div className="bg-white p-[2%] rounded-[12px] space-y-3">
        <h3 className="text-black font-[600] text-[28px]">
          Văn Phòng {businessesName}
        </h3>
        <Divider className="h-[2px] bg-[#B5C1FF]" />
        <h3 className="font-[600] text-[20px]">Địa Chỉ</h3>
        {addressBS.map((item, idx) => (
          <p key={"addressbs-" + idx} className="text-black">
            - {item}
          </p>
        ))}
        <div className="grid grid-cols-2 xl:grid-cols-3 gap-3">
          {officeImage.map((item, idx) => (
            <img
              key={"office-" + idx + "-item"}
              src={item}
              className="rounded-[12px]"
              alt=""
            />
          ))}
        </div>
      </div>

      {/* Câu chuyện */}
      <div className="bg-white p-[2%] rounded-[12px] space-y-3">
        <h3 className="text-black font-[600] text-[28px]">
          Câu Chuyện Của {businessesName}
        </h3>
        <Divider className="h-[2px] bg-[#B5C1FF]" />
        <img src={storyImage[0]} className="rounded-[12px]" alt="" />

        <div
          dangerouslySetInnerHTML={{ __html: story[0] && marked(story[0]) }}
          className="whitespace-pre-line"
        />
        {story.map(
          (item, index) =>
            index > 0 && (
              <div
                key={"story-" + index + "-item"}
                className="grid grid-cols-2 gap-3"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: story[index] && marked(item),
                  }}
                  className="whitespace-pre-line"
                />
                <img
                  src={storyImage[index]}
                  className="rounded-[12px]"
                  alt=""
                />
              </div>
            )
        )}
      </div>

      <Card>
        <Grid style={styleLayout4} hoverable={false}>
          <div style={styleLayout2}>
            <h3 className="text-black font-[600] text-[28px]">
              Phúc Lợi {businessesName}
            </h3>
          </div>
        </Grid>
        <div style={styleDevider} />
        <div className="w-full">
          {Array.isArray(welfareList) &&
            welfareList.map((item) => {
              return (
                <div
                  dangerouslySetInnerHTML={{
                    __html: marked(item),
                  }}
                  className="whitespace-pre-line p-3"
                />
              );
            })}
        </div>
      </Card>
    </div>
  );
};

const styleBt1 = {
  borderRadius: 24,
  border: "1px solid var(--lgrey-01, #F3F3F3)",
  backgroundColor: "white",
  color: "#7F879E",
};

const styleDevider = {
  width: "100%",
  height: 1,
  backgroundColor: styleBt1.color,
  margin: "0 2% 0% 2%",
};

const styleLayout2 = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
};

const styleLayout4 = {
  width: "100%",
  padding: "1% 2% 2% 2%",
  borderRadius: 10,
};
