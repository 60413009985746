import React, { useEffect, useState } from "react";
import { cx } from "../../lib/cx";
import { Check } from "@mui/icons-material";

function RecruiterConfirmSign({ data }) {
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (data && !data.isActivated) {
      const loginStatus = JSON.parse(localStorage.getItem("loginStatus"));
      if (!loginStatus.isConfirm) {
        localStorage.setItem(
          "loginStatus",
          JSON.stringify({ ...loginStatus, isConfirm: true })
        );
        setActive(true);
      }
    }
  }, [data]);

  const onChangeActive = () => {
    setActive(false);
  };

  return (
    <div
      className={cx(
        `overflow-x-auto md:overflow-hidden fixed inset-0 z-9999 h-screen outline-none focus:outline-none`,
        active ? "block" : "hidden"
      )}
    >
      <div className="bg-black absolute top-0 left-0 w-full h-[100vh] bg-opacity-20"></div>
      <div className="absolute top-0 left-0 w-full h-[100vh] flex justify-center items-center select-none px-[10px]">
        <div className="w-full md:w-[590px] bg-white p-[10px] rounded-[12px]">
          <p className="font-[600] text-[22px]">Xác thực tài khoản</p>
          <p className="text-gray">
            Quý khách có 2 giờ thử nghiệm dịch vụ của hệ thống.
          </p>

          <div className="my-4">
            <div className="flex border border-[#1495F3] p-2 justify-center items-center rounded-[8px]">
              <div className="flex justify-center items-center">
                <div className="w-[20px] h-[20px] rounded-full border border-[#1495F3] flex justify-center items-center">
                  <Check style={{ color: "#1495F3", fontSize: "12px" }} />
                </div>
              </div>

              <div className="ml-2 text-[#1495F3]">
                <p>Email của bạn đã được xác thực.</p>
                <p>
                  Tài khoản của bạn sẽ được kích hoạt sau khi quản trị viên phê
                  duyệt.
                </p>
                <p>
                  Chúng tôi sẽ liên hệ với bạn qua email khi tài khoản của bạn
                  sẵn sàng
                </p>
              </div>
            </div>
          </div>
          <div className="w-full h-[300px] flex justify-center items-center">
            <img
              src="/asserts/register.png"
              alt=""
              className="max-w-full max-h-full object-cover"
            />
          </div>
          <div className="flex justify-center items-center mt-4">
            <button
              className="px-5 py-2 bg-[#1495F3] text-white rounded-lg"
              onClick={onChangeActive}
            >
              Tôi đã hiểu
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecruiterConfirmSign;
