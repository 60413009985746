import React, { useEffect, useState } from "react";
import SelectItem from "../SelectItem";
import { cx } from "../../lib/cx";

function SearchForm({
  dataLocation,
  dataSalary,
  onChangeValue,
  oldSelect,
  token,
}) {
  const [value, setValue] = useState("");
  useEffect(() => {
    if (oldSelect.jobName) {
      setValue(oldSelect.jobName);
    }
  }, [oldSelect]);
  return (
    <div className="w-full min-h-[34px] flex px-4 py-1 bg-white rounded-[12px]">
      <div
        className={cx(
          "flex justify-start items-center",
          token ? "w-[50%]" : "w-[55%]"
        )}
      >
        <div className="w-[14px] h-[14px]">
          <img
            src={"/asserts/icons/search.png"}
            alt="icon search"
            className="object-cover"
          />
        </div>
        <input
          type="text"
          name="jobName"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="w-full focus:outline-none ml-[10px] text-[14px] border-r-[1px] border-r-[#D9D9D9]"
          placeholder="Tìm kiếm"
        />
      </div>
      <div className={cx("w-[15%]", token && "w-[20%]")}>
        <div className="block lg:hidden">
          <SelectItem
            data={dataLocation}
            placeholder="Địa điểm"
            name="location"
            valueInput={oldSelect.location}
            onChangeValue={onChangeValue}
            isHidden={true}
            parentClass="w-full"
          />
        </div>

        <div className="hidden lg:block">
          <SelectItem
            data={dataLocation}
            placeholder="Địa điểm"
            name="location"
            valueInput={oldSelect.location}
            onChangeValue={onChangeValue}
            parentClass="w-full"
          />
        </div>
      </div>
      <div className="w-[1px] h-[20px] border-r-[1px] border-r-[#D9D9D9] mt-3"></div>
      <div className={cx("w-[20%]", token && "lg:w-[25%]")}>
        <div className="block lg:hidden">
          <SelectItem
            data={dataSalary}
            name="salary"
            valueInput={oldSelect.salary}
            placeholder="Mức thu nhập"
            onChangeValue={onChangeValue}
            isHidden={true}
          />
        </div>

        <div className="hidden lg:block">
          <SelectItem
            data={dataSalary}
            name="salary"
            valueInput={oldSelect.salary}
            placeholder="Mức thu nhập"
            onChangeValue={onChangeValue}
          />
        </div>
      </div>
      <div
        className={cx(
          "flex justify-center items-center",
          token ? "w-[15%]" : "w-[10%]"
        )}
      >
        <div
          className="w-full text-center bg-[#1495F3] rounded-[12px] px-3 py-2 text-white cursor-pointer hidden md:block"
          onClick={() =>
            onChangeValue({
              target: {
                name: "jobName",
                value: value,
              },
            })
          }
        >
          Tìm kiếm
        </div>
        <div
          className="w-[14px] h-[14px] block md:hidden"
          onClick={() =>
            onChangeValue({
              target: {
                name: "jobName",
                value: value,
              },
            })
          }
        >
          <img
            src={"/asserts/icons/search.png"}
            alt="icon search"
            className="object-cover"
          />
        </div>
      </div>
    </div>
  );
}

export default SearchForm;
