import React, { useState, useEffect } from "react";
import {
  Modal,
  Card,
  Image,
  Avatar,
  Radio,
  Form,
  Tooltip,
  Select,
  InputNumber,
} from "antd";
import Stack from "@mui/material/Stack";
import { Bt, Bf } from "./BtForm";
import {
  LinkOutlined,
  FileTextOutlined,
  FormOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Divider from "@mui/material/Divider";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { FormInput, FormSelect } from "./LoginInput";
import { BlockImg } from "./BlockImgOffice";
import UserService from "../services/UserService";
import { getDescriptionBussiness, uploadImage } from "../services/PORT";
import { TextEdit } from "./TextEditor/TextEdit";

const { Meta } = Card;

export const Preview = (props) => {
  const [, setJobName] = useState("");
  const [, setCompanyName] = useState("");
  const [experient, setExperient] = useState(0);
  const [jobTypes, setJobTypes] = useState([]);
  const [levels, setLevels] = useState([]);
  const [salary, setSalary] = useState("");
  const [jobDes, setJobDes] = useState([]);
  const data = JSON.parse(localStorage.getItem("Job-Posting"));

  useEffect(() => {
    setJobName(data["jobName"]);
    setCompanyName(data["companyName"]);
    setExperient(data["experience"]);
    setLevels(data["levels"]);
    setJobTypes(data["jobTypes"]);
    if (data["salaryDisplay"] === "Salary_Range") {
      setSalary(data["minSalary"] + " -> " + data["maxSalary"] + " VND");
    } else {
      setSalary(data["salary"] + " VND");
    }
    setJobDes(data["jobDescription"]);
  }, [data]);

  return (
    <Modal
      footer={[
        <Bt
          title={"Đóng Bản xem trước"}
          onClick={props.onCancel}
          style={bt2}
        />,
      ]}
      open={props.open}
      onCancel={props.onCancel}
      // width={'43%'}
      className="md:px-[25%] pt-[2%] bg-[#F5F7FF] min-w-[500px]"
    >
      <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
        <div>
          <pTitle>
            Đây là bản xem trước của những gì ứng viên có thể thấy
          </pTitle>
          <p>Tin tuyển của bạn có thể khác một chút khi đăng trực tuyến.</p>
        </div>
        <Image
          src={"/asserts/Preview.png"}
          preview={false}
          style={styleImagePreview}
        />
      </Stack>
      <Card>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Meta title={"UX Designer"} description={"ManpowerBank - Hanoi"} />
          <Stack direction={"row"} spacing={1}>
            <Bt icon={<LinkOutlined />} style={bt1} />
            <Bt icon={<BookmarkBorderOutlinedIcon />} style={bt1} />
            <Bt icon={<FileTextOutlined />} title={"Ứng tuyển"} style={bt2} />
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          divider={<Divider orientation="vertical" flexItem />}
          spacing={3}
          justifyContent={"center"}
        >
          <div>
            <p>Kinh nghiệm</p>
            <br />
            <pStatusWork>{experient}</pStatusWork>
          </div>
          <div>
            <p>Trình độ</p>
            <br />
            {/* <pStatusWork>Senior Level</pStatusWork> */}
            {levels.map((item) => {
              return (
                <>
                  <pStatusWork>{item}</pStatusWork>
                  <br />
                </>
              );
            })}
          </div>
          <div>
            <p>Loại việc làm</p>
            <br />
            {/* <pStatusWork>Fulltime Job</pStatusWork> */}
            {jobTypes.map((item) => {
              return (
                <>
                  <pStatusWork>{item}</pStatusWork>
                  <br />
                </>
              );
            })}
          </div>
          <div>
            <p>Mức lương</p>
            <br />
            <pStatusWork>{salary}</pStatusWork>
          </div>
        </Stack>
        <hUserName>Mô tả công việc</hUserName>
        <div style={{ overflow: "auto", height: "25vh" }}>
          <p>{jobDes[0]}</p>
        </div>
      </Card>
    </Modal>
  );
};

export const SuccessPost = (props) => {
  return (
    <Modal
      open={props.open}
      onCancel={props.onCancel}
      width={"43%"}
      footer={[<Bt title="Tôi đã hiểu" style={bt2} onClick={props.onCancel} />]}
    >
      <Stack direction={"row"} justifyContent={"space-around"}>
        <htitleMNCV>
          {props.updated
            ? "Cập nhật bài đăng tuyển thành công!"
            : "Bạn đã hoàn thành Bài đăng tin tuyển!"}
        </htitleMNCV>
        <Image
          src={"/asserts/Preview.png"}
          style={{ height: "12vh" }}
          preview={false}
        />
      </Stack>
      <hUserName>Đây là những gì xảy ra tiếp theo:</hUserName>
      <ul>
        <li>
          <hUserName>
            Tin tuyển của bạn sẽ xuất hiện trong kết quả tìm kiếm - nhưng không
            phải ngay bây giờ
          </hUserName>
          <br />
          <p>
            Tin tuyển thường sẽ được đăng tải sau vài giờ, nhưng đôi khi là lâu
            hơn.
          </p>
        </li>
        <li>
          <hUserName>
            Chúng tôi sẽ gửi email thông báo khi tin tuyển của bạn được đăng
          </hUserName>
          <br />
          <p>
            Thêm manpowerbank@gmail,con vào danh sách liên hệ để email không bị
            nhầm là spam.
          </p>
        </li>
        <li>
          <hUserName>Xem và quản lý ứng viên ngay tại đây</hUserName>
          <br />
          <p>
            Khi tin tuyển được đăng, Manpowerbank sẽ giúp bạn đánh giá và trò
            chuyện với ứng viên.
          </p>
        </li>
      </ul>
    </Modal>
  );
};

export const InfoBS = (props) => {
  // const [fileList, setFileList] = useState([]); // list câu truyện ABC
  const [enterprise, setEnterprise] = useState(""); // tên doanh nghiệp
  const [fieldText, setFieldText] = useState([]); // đoạn text story
  const [introduction, setIntroduction] = useState(""); //giới thiệu về công ty
  const [fieldWelfare, setFieldWelfare] = useState([]); // danh sách các phúc lợi
  const [fieldWelfareDetail, setFieldWelfareDetail] = useState([]); // danh sách mô tả chi tiết các phúc lợi
  const [locationBS, setLocationBS] = useState([]); // danh sách địa chỉ văn phòng
  const [website, setWebsite] = useState("");
  const [mail, setMail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [national, setNational] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");

  const send = props.send;

  // danh sách ảnh giới thiệu văn phòng công ty
  const [logo, setLogo] = useState(); // logo doanh nghiệp
  const [listImage, setImage] = useState([]); // danh sách hình ảnh văn phòng
  const [aboutUsImage, setAboutUsImage] = useState(); // giới thiệu công ty
  const [imageFieldText, setImageFieldText] = useState([]); // list đoạn ảnh về câu truyện ABC

  useEffect(() => {
    const dataLocal = JSON.parse(localStorage.getItem("User"));
    setMail(dataLocal.email);
    const address = {
      city: "",
      district: "",
      nationality: "",
    };
    const data = {};
    if (introduction !== "") {
      data["introduction"] = introduction;
    }
    if (enterprise !== "") {
      data["enterprise"] = enterprise;
    }
    if (locationBS.length !== 0) {
      data["enterpriseAddress"] = locationBS;
    }
    if (fieldText.length !== 0) {
      data["story"] = fieldText;
    }
    if (fieldWelfare.length !== 0) {
      data["welfare"] = fieldWelfare;
    }
    if (website !== "") {
      data["website"] = website;
    }
    if (phoneNumber) {
      data["phoneNumber"] = "+84" + phoneNumber;
    }
    if (district || city || national) {
      address["district"] = district;
      address["city"] = city;
      address["nationality"] = national;
      data["address"] = address;
    }

    if (props.send) {
      UserService.updateBusinessInfo(
        getDescriptionBussiness,
        dataLocal.email,
        data
      )
        .then((res) => {
          const formData1 = new FormData(); // logo
          formData1.append("files", logo);
          dataLocal &&
            UserService.uploadImage(
              uploadImage,
              {
                role: "Recruiter",
                email: dataLocal.email,
                type: "Avatar",
              },
              formData1
            )
              .then((res) => {
                console.log("Successfully updated Avatar >>>>>>");
              })
              .catch((err) => {
                console.log("Error updating Avatar >>>>>>", err);
              });

          const formData2 = new FormData(); // list các ảnh
          formData2.append("files", aboutUsImage);
          UserService.uploadImage(
            uploadImage,
            {
              role: "Recruiter",
              email: dataLocal.email,
              type: "AboutUs",
            },
            formData2
          )
            .then((res) => {
              console.log("Successfully updated AboutUs >>>>>>");
            })
            .catch((err) => {
              console.log("Error updating AboutUs >>>>>>", err);
            });

          const formData3 = new FormData();
          listImage.map((item) => formData3.append("files", item));
          UserService.uploadImage(
            uploadImage,
            {
              role: "Recruiter",
              email: dataLocal.email,
              type: "Office",
            },
            formData3
          )
            .then((res) => {
              console.log("Successfully updated Office >>>>>>");
            })
            .catch((err) => {
              console.log("Error updating Office >>>>>>", err);
            });

          const formData4 = new FormData();
          imageFieldText.map((item) => formData4.append("files", item));
          UserService.uploadImage(
            uploadImage,
            {
              role: "Recruiter",
              email: dataLocal.email,
              type: "Story",
            },
            formData4
          )
            .then((res) => {
              console.log("Successfully updated Story >>>>>>");
            })
            .catch((err) => {
              console.log("Error updating Story >>>>>>", err);
            });
        })
        .catch((err) => {
          console.log("Error updating >>>>>>", err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [send]);

  const handleChange1 = (e) => {
    // sử lý thay đổi logo doanh nghiệp
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setLogo(e.target.files[0]);
    }
  };
  const handleChange2 = (file) => {
    const dataImage = [...listImage, file];
    setImage(dataImage);
    // console.log('image info', file)
    // console.log(dataImage)
    return false;
  };

  const handleChange3 = (file) => {
    setAboutUsImage(file);
    return false;
  };

  const addingSegment = () => {
    setFieldText([...fieldText, ""]);
    setImageFieldText([...imageFieldText, ""]);
  };

  const addingSegmentWelfare = () => {
    setFieldWelfare([...fieldWelfare, { name: "", content: "" }]);
    // setFieldWelfareDetail([...fieldWelfareDetail, ''])
  };

  const deleteSegment = (index) => {
    const newFieldText = [...fieldText];
    const newImageFieldText = [...imageFieldText];
    newFieldText.splice(index, 1);
    newImageFieldText.splice(index, 1);
    setFieldText(newFieldText);
    setImageFieldText(newImageFieldText);
  };

  const deleteSegmentWelfare = (index) => {
    const newWelfare = [...fieldWelfare];
    const newWelfareDetail = [...fieldWelfareDetail];
    newWelfare.splice(index, 1);
    newWelfareDetail.splice(index, 1);
    setFieldWelfare(newWelfare);
    setFieldWelfareDetail(newWelfareDetail);
  };

  const handleImageURL = (src) => {
    return src && URL.createObjectURL(src);
  };

  return (
    <Modal
      title={<h4>Thông Tin Doanh Nghiệp</h4>}
      open={props.open}
      footer={
        <>
          <Bt title={"Thoát"} style={bt3} onClick={props.cancel} />
          <Bt title={"Cập nhật"} style={bt2} onClick={props.update} />
        </>
      }
      closable={false}
      style={styleModal}
    >
      <Divider sx={styleDevider} />
      <Form layout="vertical">
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Tooltip placement="top" title={"Edit"}>
            <label htmlFor="avatar-input">
              <Avatar
                size={80}
                style={{ backgroundColor: "green" }}
                icon={<FormOutlined />}
                src={handleImageURL(logo)}
              />
              <input
                type="file"
                onChange={handleChange1}
                id="avatar-input"
                accept="image/png, image/jpeg"
                style={{ width: 0, height: 0, opacity: 0 }}
              />
            </label>
          </Tooltip>
          <FormInput
            title={"Tên doanh nghiệp"}
            placeholder={"Nhập tên doanh nghiệp"}
            style={{ width: "15vw" }}
            onChange={(e) => setEnterprise(e.target.value)}
          />
          <FormInput
            title={"Website"}
            placeholder={"Đường dẫn tới website doanh nghiệp"}
            style={{ width: "15vw" }}
            onChange={(e) => {
              setWebsite(e.target.value);
              // console.log(e.target.value)
            }}
          />
        </Stack>
        <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
          <Stack
            direction={"column"}
            // spacing={1}
            sx={{ width: "50%" }}
          >
            <FormInput
              title={"Email"}
              placeholder={mail}
              // onChange={(e) => setMail(e.target.value)}
              disabled={true}
            />
            {/* <FormInput title={'Ngày sinh'} placeholder={'Nhập ngày sinh'} /> */}
            <FormInput
              title={"Quân/Huyện"}
              placeholder={"Nhập quận/huyện"}
              onChange={(e) => setDistrict(e.target.value)}
            />
            <FormInput
              title={"Tỉnh/Thành phố"}
              placeholder={"Nhập tỉnh/thành phố"}
              onChange={(e) => setCity(e.target.value)}
            />
          </Stack>
          <Stack direction={"column"} sx={{ width: "50%" }}>
            <Form.Item label={"Điện thoại liên hệ"}>
              <div style={{ display: "flex" }}>
                <Select defaultValue={"+84"} style={{ width: "30%" }} />
                <InputNumber
                  placeholder="Nhập số điện thoại"
                  controls={false}
                  style={{ marginLeft: "1%", width: "100%" }}
                  onChange={(e) => setPhoneNumber(e)}
                />
              </div>
            </Form.Item>
            <FormInput
              title={"Quốc gia"}
              placeholder={"Nhập quốc gia"}
              onChange={(e) => setNational(e.target.value)}
            />
          </Stack>
        </Stack>
      </Form>
      <Divider style={styleDevider} />
      <h4>Về chúng tôi</h4>
      <pRadio>Mô tả tổng quan về doanh nghiệp của bạn</pRadio>
      <FieldStory
        beforeUpload={handleChange3}
        src={handleImageURL(aboutUsImage)}
        onChange={(e) => setIntroduction(e.target.value)}
        onClickClose={() => {
          setAboutUsImage("");
        }}
      />

      <h4>Văn phòng ABC</h4>
      <Divider style={styleDevider} />
      <pRadio>Địa chỉ</pRadio>
      <br />
      {locationBS.map((item, index) => {
        return (
          <Stack direction={"row"} spacing={1}>
            <FormInput
              onChange={(e) => {
                const temp = locationBS;
                temp[index] = e.target.value;
                setLocationBS(temp);
              }}
            />
            <Bt
              icon={<DeleteOutlined />}
              style={bt1}
              onClick={() => {
                let temp = [...locationBS];
                temp.splice(index, 1);
                setLocationBS(temp);
                console.log(temp, index);
              }}
            />
          </Stack>
        );
      })}
      <Bf
        title={"+ Thêm địa chỉ"}
        onClick={() => {
          setLocationBS([...locationBS, ""]);
        }}
      />
      <Divider style={styleDevider} />

      <Stack
        direction={"row"}
        sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}
      >
        {listImage.map((item, index) => {
          return (
            <BlockImg
              key={index}
              style={styleBlockImg}
              src={handleImageURL(item)}
              onClickClose={() => {
                const newListItem = [...listImage];
                newListItem.splice(index, 1);
                setImage(newListItem);
              }}
            />
          );
        })}
        {listImage.length < 8 && (
          <BlockImg
            style={styleBlockImg}
            beforeUpload={handleChange2}
            onChange={() => <></>}
          />
        )}
      </Stack>

      <Divider style={styleDevider} />

      <h4>Câu chuyện ABC</h4>
      {/* <FieldStory /> */}
      {fieldText.map((item, index) => {
        return (
          <FieldStory
            beforeUpload={(file) => {
              const temp = [...imageFieldText];
              temp[index] = file;
              setImageFieldText(temp);
            }}
            value={item}
            src={handleImageURL(imageFieldText[index])}
            deleteBt={() => {
              deleteSegment(index);
              // console.log(index, fieldText, fieldText[index])
            }}
            onChange={(e) => {
              const newText = [...fieldText];
              newText[index] = e.target.value;
              setFieldText(newText);
            }}
            onClickClose={() => {
              const temp = [...imageFieldText];
              temp.splice(index, 1);
              setImageFieldText(temp);
            }}
          />
        );
      })}
      <Bf title="+ thêm đoạn" onClick={addingSegment} />
      <Divider style={styleDevider} />
      {/* <Welfare /> */}
      {fieldWelfare.map((item, index) => {
        return (
          <Welfare
            value={item}
            onClickDeleteWelfare={() => {
              deleteSegmentWelfare(index);
            }}
            onChange={(e) => {
              const newDetail = [...fieldWelfare];
              newDetail[index].content = e.target.value;
              setFieldWelfare(newDetail);
            }}
            nameWelfare={(e) => {
              const newDetail = [...fieldWelfare];
              newDetail[index].name = e.target.value;
              setFieldWelfare(newDetail);
            }}
          />
        );
      })}
      <Bf title="+ thêm phúc lợi" onClick={addingSegmentWelfare} />
      <Divider style={styleDevider} />
    </Modal>
  );
};

export const FieldStory = (props) => {
  return (
    <div className="flex flex-row space-x-4">
      {/* <TextField hasDeleteField={true} deleteBt={props.deleteBt} onChange={props.onChange}
                value={props.value}
            /> */}
      <div className="w-[80%]">
        <TextEdit
          handlePastedText={props.handlePastedText}
          value={props.value}
          onChange={props.onChange}
        />
      </div>

      <BlockImg
        style={styleBlockImg}
        beforeUpload={props.beforeUpload}
        src={props.src}
        onClickClose={props.onClickClose}
      />
    </div>
  );
};

export const Welfare = (props) => {
  const value = props.value;
  return (
    <Stack direction={"column"} spacing={1}>
      <h4>Phúc Lợi Của ABC</h4>
      <Stack direction={"row"} spacing={1}>
        <div style={styleLayoutLogoWelfare}>
          <pDes>Chọn{<br />} Biểu Tượng</pDes>
        </div>
        <Form layout="vertical" style={{ width: "100%" }}>
          <FormInput
            title={<pRadio>Tên phúc lợi 01</pRadio>}
            placeholder={"Nhập tên phúc lợi"}
            value={value?.name}
            onChange={props.nameWelfare}
          />
        </Form>
        <Stack direction={"column"} justifyContent={"flex-start"}>
          <Bt
            icon={<DeleteOutlined />}
            style={bt1}
            onClick={props.onClickDeleteWelfare}
          />
        </Stack>
      </Stack>
      <pRadio>Chi tiết phúc lợi</pRadio>
      <TextEdit
        onChange={props.onChange}
        value={value?.content}
        defaultValue={props.defaultValue}
      />
    </Stack>
  );
};

export const UpdateSuccess = (props) => {
  return (
    <Modal
      open={props.open}
      footer={
        <>
          <Bt title={"Tôi đã hiểu"} style={bt2} onClick={props.accept} />
        </>
      }
      closable={false}
      style={styleModal}
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <h4>Bạn Đã Cập Nhật {<br />} Thông Tin Thành Công</h4>
        <Image
          src={"/asserts/Preview.png"}
          preview={false}
          style={styleImagePreview}
        />
      </Stack>
      <hUserName>Đây Là Những Gì Xảy Ra Tiếp Theo:</hUserName>
      <ul>
        <li>
          <pRadio>
            Thông tin của bạn sẽ xuất hiện trên hồ sơ doanh nghiệp sau khi được
            kiểm duyệt
          </pRadio>
          <br />
          <p>
            Quá trình kiểm duyệt sẽ hoàn tất sau vài giờ, nhưng đôi khi là lâu
            hơn.
          </p>
        </li>
        <li>
          <pRadio>
            Chúng tôi sẽ gửi email thông báo khi thông tin của bạn được hiển thị
          </pRadio>
          <br />
          <p>
            Thêm manpowerbank@gmail.com vào danh sách liên hệ để email không bị
            nhầm là spam.
          </p>
        </li>
      </ul>
    </Modal>
  );
};

// export const StoryAboutUs = (props) => {
//     return (
//         <Modal title={'Câu chuyện ABC'}
//             open={props.open}
//             footer={<>
//                 <Bt title={'Thoát'} style={bt3} onClick={props.cancel} />
//                 <Bt title={'Cập nhật'} style={bt2} onClick={props.update} />
//             </>}
//             closable={false}
//             style={{ minWidth: '50vw' }}
//         >
//             <Divider style={styleDevider} />
//             <FieldStory />
//             <FieldStory />
//             <Bf title='+ thêm đoạn' />
//             <Divider style={styleDevider} />
//         </Modal>
//     )
// }

export const CreateAdminAccount = (props) => {
  const options1 = [
    {
      value: "Người kiểm duyệt",
      label: "Người kiểm duyệt",
    },
    {
      value: "Quản trị Ứng viên",
      label: "Quản trị Ứng viên",
    },
    {
      value: "Quản trị Doanh nghiệp",
      label: "Quản trị Doanh nghiệp",
    },
    {
      value: "Quản trị viên",
      label: "Quản trị viên",
    },
  ];
  return (
    <Modal
      title={props.title}
      open={props.open}
      footer={
        <>
          <Bt title={"Thoát"} style={bt3} onClick={props.cancel} />
          <Bt title={"Cập nhật"} style={bt2} onClick={props.update} />
        </>
      }
      closable={false}
      style={styleModal}
    >
      <Divider style={styleDevider} />
      <Form layout="vertical">
        <Stack direction={"row"} justifyContent={"space-around"} spacing={1}>
          <Stack direction={"column"} sx={styleBlockPreview}>
            <FormInput
              title={"Tên tài khoản"}
              placeholder="Nhập tên tài khoản"
              style={inputStyle}
            />
            <FormSelect
              title={"Vai trò"}
              placeholder="Chọn vai trò"
              options={options1}
              style={inputStyle}
              theme={themeSelectForm}
            />
            {props.title && props.title !== "Tạo Tài Khoản Quản Trị" && (
              <FormInput
                title={"Mô tả tài khoản"}
                placeholder="Nhập mô tả tài khoản"
                style={inputStyle}
              />
            )}
          </Stack>
          <Stack direction={"column"} sx={styleBlockPreview}>
            <FormInput
              title={"Mật khẩu"}
              placeholder="Nhập mật khẩu"
              style={inputStyle}
            />
            <FormSelect
              title={"Bộ phận"}
              placeholder="Chọn bộ phận"
              mode="tags"
              theme={themeSelectForm}
            />
            {props.title && props.title !== "Tạo Tài Khoản Quản Trị" && (
              <Form.Item label={<pRadio>Trạng thái tài khoản</pRadio>}>
                <Radio.Group>
                  <Radio value={1}>{<pRadio>Kích hoạt</pRadio>}</Radio>
                  <Radio value={2}>{<pRadio>Ngưng kích hoạt</pRadio>}</Radio>
                </Radio.Group>
              </Form.Item>
            )}
          </Stack>
        </Stack>
        {props.title && props.title === "Tạo Tài Khoản Quản Trị" && (
          <FormInput
            title={"Mô tả tài khoản"}
            placeholder="Nhập mô tả tài khoản"
            style={inputStyle}
          />
        )}
      </Form>
      <Divider style={styleDevider} />
    </Modal>
  );
};

const bt1 = {
  border: "1px solid #F3F3F3",
  borderRadius: 24,
  backgroundColor: "#FFFFFF",
  color: "#7F879E",
};

const bt2 = {
  background: "#0055FF",
  borderRadius: 10,
};

const bt3 = {
  borderRadius: 12,
  border: "1px solid var(--grey-03, #D4D7DF)",
  background: "var(--screen-color-white, #FFF)",
  color: "#7F879E",
};

const inputStyle = {
  background: "#F3F3F3",
  borderRadius: 10,
  margin: "1%",
  width: "100%",
};

const styleLayoutLogoWelfare = {
  borderRadius: 10,
  background: "var(--lgrey-01, #F3F3F3)",
  padding: "1%",
};

const styleDevider = {
  marginBottom: "1%",
  marginTop: "1%",
};
const styleBlockImg = {
  width: "30%",
  // height: '30%',
  backgroundColor: "#F5F7FF",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 10,
  padding: "1%",
  margin: 3,
};

const styleBlockPreview = { width: "50%" };

const themeSelectForm = {
  token: {
    colorBgContainer: inputStyle.background,
    borderRadius: inputStyle.borderRadius,
  },
};

const styleModal = {
  minWidth: "50vw",
};

const styleImagePreview = { height: "12vh" };
