import React from "react";

function TagCompany(props) {
  const { type, title } = props;
  return (
    <div className="w-full px-2 py-1 rounded-[32px] bg-[#F5F7FF] flex justify-start items-center my-[2px]">
      <div className="w-[12px] h-[12px] mr-1">
        <img
          src={type === 1 ? "/icons/documentcopy.png" : "/icons/Vector.png"}
          alt=""
          className="w-full h-full object-cover"
        />
      </div>
      <span className="text-[13px] text-black">{title}</span>
    </div>
  );
}

export default TagCompany;
