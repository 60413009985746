import { createSlice } from "@reduxjs/toolkit";

export const initalProfile = {
  fullName: "",
  applyPosition: "",
  email: "",
  location: "",
  phoneNumber: "",
  facebook: "",
  linkedin: "",
};

export const initialTrippleItem = {
  title: "",
  start_time: "",
  end_time: "",
  label: "",
  sub_label: "",
  description: "",
};

export const initialDoubleItem = {
  name: "",
  description: "",
};

export const initialSingleItem = {
  name: "",
};

export const initialAvatar = {
  original: "",
  url: "",
};

export const initialPresenter = {
  name: "",
  description: "",
  email: "",
  phone: "",
};

export const initialBuilderState = {
  avatar: initialAvatar,
  profile: initalProfile,
  objective: "",
  skills: [initialDoubleItem],
  cetifications: [initialDoubleItem],
  activeties: [initialDoubleItem],
  honors_and_awards: [initialDoubleItem],
  presenter: [initialPresenter],
  interestes: [initialSingleItem],
  addtional_informations: [initialSingleItem],
  educations: [initialTrippleItem],
  workExperiences: [initialTrippleItem],
  projects: [initialTrippleItem],
};

export const buiderSlice = createSlice({
  name: "builder",
  initialState: initialBuilderState,
  reducers: {
    changeAvatar: (state, action) => {
      const { original, url } = action.payload;
      state.avatar = { original, url };
    },
    changeProfile: (state, action) => {
      const { field, value } = action.payload;
      state.profile[field] = value;
    },
    changeItemValue: (state, action) => {
      const { id, form, field, value } = action.payload;
      const itemChange = state[form][id];
      itemChange[field] = value;
    },
    moveItemSelect: (state, action) => {
      const { id, form, type } = action.payload;
      const oldState = state[form];
      const lastIdx = oldState.length - 1;
      const pos = id;
      const newPos = type === "up" ? pos - 1 : pos + 1;

      const swapPostion = (idx1, idx2) => {
        const temp = oldState[idx1];
        oldState[idx1] = oldState[idx2];
        oldState[idx2] = temp;
      };

      if (newPos >= 0 && newPos <= lastIdx) {
        swapPostion(pos, newPos);
      }
    },
    addNewItem: (state, action) => {
      const { id, field } = action.payload;
      const value = state[field][id];
      state[field].splice(id, 0, value);
    },
    deleteItem: (state, action) => {
      const { id, field } = action.payload;
      if (state[field].length - 1 > 0) {
        state[field].splice(id, 1);
      }
    },
    changeObjective: (state, action) => {
      const { value } = action.payload;
      state.objective = value;
    },
    setBuilder: (state, action) => {
      return action.payload;
    },
    resetBuilder: (state, action) => {
      return initialBuilderState;
    },
  },
});

export const {
  changeAvatar,
  changeProfile,
  changeObjective,
  moveItemSelect,
  addNewItem,
  deleteItem,
  changeItemValue,
  setBuilder,
  resetBuilder,
} = buiderSlice.actions;

export const selectBuilder = (state) => state.builder;
export const selectProfile = (state) => state.builder.profile;
export const selectSkills = (state) => state.builder.skills;
export const selectObjective = (state) => state.builder.objective;
export const selectAvatar = (state) => state.builder.avatar;

export default buiderSlice.reducer;
