import React from "react";

function CardPayment({ data, onChangeDetail, onChangeNow }) {
  return (
    <div className="w-full min-h-[680px] bg-gradient-to-b from-[#08EFFECC] to-[#3F8BFECC] rounded-[20px] p-[20px] relative">
      <div className="w-full flex justify-center items-center my-[30px]">
        <div className="w-[130px] h-[130px] flex justify-center items-center p-[35px] shadow-lg bg-gradient-to-r from-[#246FE194] to-[#21E7F494] rounded-[50%]">
          <img
            src="/images/rocket.png"
            alt=""
            className="max-w-full max-h-full object-cover"
          />
        </div>
      </div>
      <div className="w-full">
        <p className="text-white uppercase text-[32px] text-center">
          {data?.name}
        </p>
        <ul className="my-[20px] px-[10px]">
          {data?.description &&
            data.description.map((item, idx) => (
              <li
                key={"des-i-" + idx + "-item"}
                className="text-white list-disc text-justify mb-1 text-[14px]"
              >
                {item}
              </li>
            ))}
        </ul>
      </div>
      <div className="w-full absolute left-0">
        <div className="flex space-x-2 p-[20px]">
          <div
            className="w-full py-2 text-center bg-white text-black rounded-[20px] cursor-pointer"
            onClick={() => onChangeDetail()}
          >
            Chi tiết
          </div>
          <div
            className="w-full py-2 text-center bg-white text-black rounded-[20px] cursor-pointer"
            onClick={() => onChangeNow()}
          >
            Đăng kí ngay
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardPayment;
