import React, { useState } from "react";
import {
  useAppSelector,
  useSaveStateToLocalStorageOnChange,
  useSetInitialStore,
} from "../../../lib/redux/hooks";
import { selectFormsOrder } from "../../../lib/redux/settingsSlice";
import { WorkExperiencesForm } from "./WorkExperiencesForm.jsx";
import { ProfileForm } from "./ProfileForm";
import { ThemeForm } from "./ThemeForm";
import { FlexboxSpacer } from "../FlexboxSpacer";
import { EducationsForm } from "./EducationsForm";
import { ProjectsForm } from "./ProjectsForm";
import { SkillsForm } from "./SkillsForm";
import { CustomForm } from "./CustomForm";
import { cx } from "../../../lib/cx";

const formTypeToComponent = {
  workExperiences: <WorkExperiencesForm key={"workExperiences"} />,
  educations: <EducationsForm key={"educations"} />,
  projects: <ProjectsForm key={"projects"} />,
  skills: <SkillsForm key={"skills"} />,
  custom: <CustomForm key={"custom"} />,
};

const ResumeForm = () => {
  useSetInitialStore();
  useSaveStateToLocalStorageOnChange();

  const formsOrder = useAppSelector(selectFormsOrder);
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className={cx(
        "flex justify-center scrollbar scrollbar-track-gray-100 scrollbar-w-3 md:h-[calc(100vh-var(--top-nav-bar-height))] md:justify-end md:overflow-y-scroll",
        isHover && "scrollbar-thumb-gray-200"
      )}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <section className="flex max-w-2xl flex-col gap-8 p-[var(--resume-padding)]">
        <ProfileForm />
        {formsOrder.map((form) => {
          const Component = formTypeToComponent[form];
          return Component;
        })}
        <ThemeForm />
        <br />
      </section>
      <FlexboxSpacer maxWidth={50} className="hidden md:block" />
    </div>
  );
};

export default ResumeForm;
