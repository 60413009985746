import * as Yup from "yup";

export const registerInfoSchema = Yup.object().shape({
  fullName: Yup.string().required("Vui lòng nhập họ và tên"),
  email: Yup.string().required("Bắt buộc").email("Vui lòng nhập email của bạn"),
  phone: Yup.string()
    .matches(/^(?:\+84|0)([3|5|7|8|9])+([0-9]{8})+$/, "Số điện thoại không chính xác")
    .required("Bắt buộc"),
  password: Yup.string()
    .required("Bắt buộc")
    .min(6, "Mật khẩu phải ít nhất 6 kí tự")
    .max(50, "Mật khẩu tối đa 50 kí tự")
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.!@#=?\$%\^&\*])/,
      "Độ dài từ 6 đến 50 ký tự, ít nhất 1 chữ hoa, 1 chữ thường, 1 chữ số, 1 ký tự đặc biệt."
    )
    .oneOf([Yup.ref("rePassword"), null], "Mật khẩu không khớp"),
  rePassword: Yup.string()
    .required("Bắt buộc")
    .min(6, "Mật khẩu phải ít nhất 6 kí tự")
    .max(50, "Mật khẩu tối đa 50 kí tự")
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.!@#=?\$%\^&\*])/,
      "Độ dài từ 6 đến 50 ký tự, ít nhất 1 chữ hoa, 1 chữ thường, 1 chữ số, 1 ký tự đặc biệt."
    )
    .oneOf([Yup.ref("password"), null], "Mật khẩu không khớp"),
});

export const registerEnterpriseSchema = Yup.object().shape({
  email: Yup.string().required("Bắt buộc").email("Vui lòng nhập email của bạn"),
  phone: Yup.string()
    .matches(/^(?:\+84|0)([3|5|7|8|9])+([0-9]{8})+$/, "Số điện thoại không chính xác")
    .required("Bắt buộc"),
  password: Yup.string()
    .required("Bắt buộc")
    .min(6, "Mật khẩu phải ít nhất 6 kí tự")
    .max(50, "Mật khẩu tối đa 50 kí tự")
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.!@#=?\$%\^&\*])/,
      "Độ dài từ 6 đến 50 ký tự, ít nhất 1 chữ hoa, 1 chữ thường, 1 chữ số, 1 ký tự đặc biệt."
    )
    .oneOf([Yup.ref("rePassword"), null], "Mật khẩu không khớp"),
  rePassword: Yup.string()
    .required("Bắt buộc")
    .min(6, "Mật khẩu phải ít nhất 6 kí tự")
    .max(50, "Mật khẩu tối đa 50 kí tự")
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.!@#=?\$%\^&\*])/,
      "Độ dài từ 6 đến 50 ký tự, ít nhất 1 chữ hoa, 1 chữ thường, 1 chữ số, 1 ký tự đặc biệt."
    )
    .oneOf([Yup.ref("password"), null], "Mật khẩu không khớp"),
  enterprise: Yup.string().required("Vui lòng nhập tên doanh nghiệp"),
  taxCode: Yup.string().required("Vui lòng nhập mã số thuế"),
});

export const passwordChangeSchema = Yup.object().shape({
  password: Yup.string()
    .required("Vui lòng nhập mật khẩu của bạn")
    .min(6, "Mật khẩu phải ít nhất 6 kí tự")
    .max(50, "Mật khẩu tối đa 50 kí tự")
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.!@#=?\$%\^&\*])/,
      "Độ dài từ 6 đến 50 ký tự, ít nhất 1 chữ hoa, 1 chữ thường, 1 chữ số, 1 ký tự đặc biệt."
    )
    .oneOf([Yup.ref("rePassword"), null], "Mật khẩu không khớp"),
  rePassword: Yup.string()
    .required("Vui lòng nhập mật khẩu của bạn")
    .min(6, "Mật khẩu phải ít nhất 6 kí tự")
    .max(50, "Mật khẩu tối đa 50 kí tự")
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.!@#=?\$%\^&\*])/,
      "Độ dài từ 6 đến 50 ký tự, ít nhất 1 chữ hoa, 1 chữ thường, 1 chữ số, 1 ký tự đặc biệt."
    )
    .oneOf([Yup.ref("password"), null], "Mật khẩu không khớp"),
});

export const personalProfileSchema = Yup.object().shape({
  fullName: Yup.string().required("Bắt buộc"),
  email: Yup.string().required("Bắt buộc").email("Email không đúng định dạng"),
  jobTitle: Yup.string().required("Bắt buộc"),
  phoneNumber: Yup.string()
    .matches(/^(?:\+84|0)([3|5|7|8|9])+([0-9]{8})+$/, "Số điện thoại không chính xác")
    .required("Bắt buộc"),
  dateOfBirth: Yup.string().required("Bắt buộc"),
  location: Yup.string().required("Bắt buộc"),
  district: Yup.string().required("Bắt buộc"),
  city: Yup.string().required("Bắt buộc"),
});

export const createPersonalSchema = Yup.object().shape({
  fullName: Yup.string().required("Bắt buộc"),
  email: Yup.string().required("Bắt buộc").email("Email không đúng định dạng"),
  phoneNumber: Yup.string()
    .matches(/^(?:\+84|0)([3|5|7|8|9])+([0-9]{8})+$/, "Số điện thoại không chính xác")
    .required("Bắt buộc"),
});

export const registerCandidateSchema = Yup.object().shape({
  fullName: Yup.string().required("Bắt buộc"),
  email: Yup.string().required("Bắt buộc").email("Email không đúng định dạng"),
  phone: Yup.string()
    .matches(/^(?:\+84|0)([3|5|7|8|9])+([0-9]{8})+$/, "Số điện thoại không chính xác")
    .required("Bắt buộc"),
  password: Yup.string()
    .required("Vui lòng nhập mật khẩu của bạn")
    .min(6, "Mật khẩu phải ít nhất 6 kí tự")
    .max(50, "Mật khẩu tối đa 50 kí tự")
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.!@#=?\$%\^&\*])/,
      "Độ dài từ 6 đến 50 ký tự, ít nhất 1 chữ hoa, 1 chữ thường, 1 chữ số, 1 ký tự đặc biệt."
    )
    .oneOf([Yup.ref("rePassword"), null], "Mật khẩu không khớp"),
  rePassword: Yup.string()
    .required("Vui lòng nhập mật khẩu của bạn")
    .min(6, "Mật khẩu phải ít nhất 6 kí tự")
    .max(50, "Mật khẩu tối đa 50 kí tự")
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.!@#=?\$%\^&\*])/,
      "Độ dài từ 6 đến 50 ký tự, ít nhất 1 chữ hoa, 1 chữ thường, 1 chữ số, 1 ký tự đặc biệt."
    )
    .oneOf([Yup.ref("password"), null], "Mật khẩu không khớp"),
});

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .required("Nhập email của bạn!")
    .email("Email không đúng định dạng"),
  password: Yup.string().required("Vui lòng nhập mật khẩu của bạn"),
});

export const createCompanySchema = Yup.object().shape({
  name: Yup.string().required("Bắt buộc"),
  address: Yup.string().required("Bắt buộc"),
});

export const createGroupSchema = Yup.object().shape({
  name: Yup.string().required("Bắt buộc"),
});

export const createNewsSchema = Yup.object().shape({
  title: Yup.string().required("Bắt buộc"),
  tag: Yup.string().required("Bắt buộc"),
  content: Yup.string().required("Phần này không được bỏ trống!"),
});

export const createAdsSchema = Yup.object().shape({
  startTime: Yup.string().required("Bắt buộc"),
  endTime: Yup.string().required("Bắt buộc"),
  recruiterId: Yup.string().required("Bắt buộc"),
  position: Yup.string().required("Bắt buộc"),
});

export const createCvSchema = Yup.object().shape({
  department: Yup.string().required("Bắt buộc"),
  type: Yup.string().required("Bắt buộc"),
  name: Yup.string().required("Bắt buộc"),
  subName: Yup.string().required("Bắt buộc"),
});

export const createJdSchema = Yup.object().shape({
  department: Yup.string().required("Bắt buộc"),
  major: Yup.string().required("Bắt buộc"),
  companyName: Yup.string().required("Bắt buộc"),
  location: Yup.string().required("Bắt buộc"),
  positions: Yup.string().required("Bắt buộc"),
  startReceiveCV: Yup.string().required("Bắt buộc"),
  endReceiveCV: Yup.string().required("Bắt buộc"),
  description: Yup.string().required("Vui lòng nhập mô tả công việc"),
  requirement: Yup.string().required("Vui lòng nhập yêu cầu công việc"),
  dailyUpdate: Yup.array().min(1, "Vui lòng nhập danh email nhận cập nhật"),
  jobTypes: Yup.array().min(1, "Chọn ít nhất một lọai công việc"),
  levels: Yup.array().min(1, "Chọn ít nhất một vị trí cần tuyển"),
  isDurationSubmit: Yup.boolean(),
  submitEndDate: Yup.string().when("isDurationSubmit", (item) => {
    return item[0]
      ? Yup.string().required("Vui lòng nhập hạn nộp hồ sơ")
      : Yup.string().notRequired();
  }),
  salaryDisplay: Yup.string(),
  minSalary: Yup.number().when("salaryDisplay", (item) => {
    return item[0] === "Salary_Range"
      ? Yup.number().min(1, "Vui lòng nhập khoảng lương")
      : Yup.number().notRequired();
  }),
  maxSalary: Yup.number().when("salaryDisplay", (item) => {
    return item[0] === "Salary_Range"
      ? Yup.number().min(1, "Vui lòng nhập khoảng lương")
      : Yup.number().notRequired();
  }),
  salary: Yup.number().when("salaryDisplay", (item) => {
    return item[0] === "Final_Salary" || item[0] === "Starting_Salary"
      ? Yup.number().min(1, "Vui lòng nhập mức lương")
      : Yup.number().notRequired();
  }),
});
