import React, { useEffect, useState } from "react";
import { useAuth } from "../Auth/useAuth";
import CardEmpty from "../components/Card/CardEmpty";
import { useHistory, useLocation } from "react-router-dom";
import { pathAdmin } from "../../screens/PathRouting";
import CardNews from "../components/Card/CardNews";
import AdminService from "../../services/AdminService";
import queryString from "query-string";
import dayjs from "dayjs";
import { Pagination } from "antd";
import SearchForm from "../Company/SearchForm";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import { changePagination } from "../../lib/theme/config";

function ManagerNews() {
  const { theme } = useTheme();
  const { admin } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const [listNews, setListNews] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const search = queryString.parse(location.pathname);

  const limit = 15;

  useEffect(() => {
    if (!listNews) {
      getAllListNews(search?.page || 1, { status: "" });
    }
  }, [listNews, search]);

  const getAllListNews = async (page, value = {}) => {
    const params = {
      offset: page - 1,
      limit: limit,
      ...value,
    };
    await AdminService.getAllNews(queryString.stringify(params))
      .then((response) => {
        const data = response.data.data;
        setTotalPage(data.count);
        const result = data.items.map((item) => ({
          ...item,
          status: item.status.toLowerCase(),
          createAt: dayjs(item.createAt).format("HH:MM DD/MM/YYYY"),
        }));
        setListNews(result);
      })
      .catch((error) => {});
  };

  const onChangePage = (page) => {
    history.replace(
      location.pathname + "?" + queryString.stringify({ page: page })
    );
    getAllListNews(page);
  };

  return (
    <div
      className={cx(
        "mx-auto min-h-[80vh] lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <div className="px-4">
        <div className={cx("my-[60px]", theme?.background?.text)}>
          <p className="text-[32px] font-[600] leading-none">Dashboad</p>
          <p className="text-[26px]">{admin?.nickname}</p>
        </div>

        <div className="w-full flex justify-between items-center my-4">
          <p className={cx("text-[20px] font-[600]", theme?.background?.text)}>
            Quản lý danh mục tin tức
          </p>
          <p className="text-black hover:underline cursor-pointer"></p>
        </div>

        <div className="my-[40px]">
          <SearchForm
            onChangeValue={(value) => getAllListNews(search?.page || 1, value)}
            isHiddenDate
          />
        </div>

        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          <CardEmpty
            icon="/icons/elementplus.png"
            title="Thêm tin tức"
            onClick={() => history.push(pathAdmin.adminCreateNews.path)}
          />
          {listNews &&
            listNews.length > 0 &&
            listNews.map((item) => (
              <CardNews
                data={item}
                status={item.status}
                isBlock={true}
                onHandleDetail={() =>
                  history.push(
                    pathAdmin.adminNewsDetail.parentPath + `/${item.slug}`
                  )
                }
              />
            ))}
        </div>

        <div className="w-full flex justify-center items-center my-[60px]">
          {totalPage > 0 && (
            <Pagination
              defaultCurrent={search?.page || 1}
              total={totalPage}
              pageSize={limit}
              onChange={onChangePage}
              itemRender={changePagination}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ManagerNews;
