import React from "react";
import { useAppSelector } from "../../redux/hooks";
import { selectSetting } from "../../redux/slice/setting";
import ProfileComponent from "./ProfileComponent";
import LayerComponent from "./LayerComponent";
import HelpDecriptionComponent from "../../components/SubComponent/HelpDecriptionComponent";

function ContentComponent() {
  const setting = useAppSelector(selectSetting);
  return (
    <div className="px-[10px] md:px-0 flex space-x-4">
      <form
        style={{
          width: "793px",
          minHeight: "1112px",
          background: "white",
          position: "relative",
          fontFamily: `${setting.fontFamily}`,
          fontSize: `${setting.fontSize}`,
        }}
        className="shadow-md"
      >
        <ProfileComponent />
        <LayerComponent />
      </form>
      <HelpDecriptionComponent />
    </div>
  );
}

export default ContentComponent;
