import React from "react";
import CardShow from "./CardShow";
import { useHistory } from "react-router-dom";
import { routes } from "../../screens/PathRouting";

function CardSlide(props) {
  const { data } = props;
  const history = useHistory();
  const handleRedirectLink = () => {
    history.push(routes.workDetail.parentPath + "/" + data.id);
  };
  return (
    <div className="w-full h-[379px] bg-white rounded-[12px] p-[16px]">
      <div className="w-full h-[87px] flex">
        <div className="w-[87px] h-[87px] flex justify-center items-center">
          <img
            src={data.image || "/asserts/avatar.png"}
            alt=""
            className="max-w-full max-h-full object-cover"
          />
        </div>
        <div className="w-full flex justify-start items-center">
          <p className="text-black text-[18px] font-[700] ml-[10px] inline">
            {data.name}
          </p>
        </div>
      </div>
      <div className="my-[15px]">
        <CardShow
          title={data.job}
          salary={data.salary}
          location={data.location}
          date={data.date}
          tag={data.tag}
        />
      </div>
      {/* <div className="w-full h-[12px] flex justify-center items-center">
        <div className="w-[12px] h-[12px] rounded-[50%] bg-[#B5C1FF] mx-[8px]"></div>
        <div className="w-[12px] h-[12px] rounded-[50%] bg-[#1495F3]"></div>
      </div> */}
      <div className="w-full h-[72px] flex justify-center items-center cursor-pointer">
        <div className="w-full h-[51px] bg-[#1495F3] rounded-[12px] flex justify-center items-center ">
          <p
            className="text-[16px] text-white font-[700] "
            onClick={handleRedirectLink}
          >
            Xem thêm
          </p>
        </div>
      </div>
    </div>
  );
}

export default CardSlide;
