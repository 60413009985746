import React, { useEffect, useState } from "react";
import CKEditors from "../../components/CKEditor/CKEditors";
import InputAdmin from "../components/Input";
import { useFormik } from "formik";
import BreadcrumbAdmin from "../components/Tag/Breadcrumb";
import { createNewsSchema } from "../../utils/validations";
import { CameraOutlined } from "@ant-design/icons";
import { notification } from "antd";
import AdminService from "../../services/AdminService";
import { useHistory, useLocation } from "react-router-dom";
import { pathAdmin } from "../../screens/PathRouting";
import { getId } from "../../utils";
import { useAuth } from "../Auth/useAuth";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";

function NewsUpdate() {
  const { theme } = useTheme();
  const history = useHistory();
  const location = useLocation();
  const { admin } = useAuth();
  const [newsDetail, setNewsDetail] = useState();
  const [avatar, setAvatar] = useState("");
  const [checkAvatar, setCheckAvatar] = useState(false);

  const [api, contextHolder] = notification.useNotification({ top: 80 });;
  const slug = getId(location.pathname);

  const updateNewsFormik = useFormik({
    initialValues: {
      title: "",
      tag: "",
      content: "",
    },
    validationSchema: createNewsSchema,
    onSubmit: (values) => {
      const data = {
        ...values,
        owner: [
          {
            id: admin?.id,
            name: admin?.nickname,
          },
        ],
        tag: values.tag.split(",").map((item) => item.trim()),
      };
      updateNews(data);
    },
  });

  useEffect(() => {
    if (!newsDetail) {
      getNewsDetail();
    } else {
      updateNewsFormik.setFieldValue("title", newsDetail.title);
      updateNewsFormik.setFieldValue("tag", newsDetail.tag.join(", "));
      updateNewsFormik.setFieldValue("content", newsDetail.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsDetail]);

  const getNewsDetail = async () => {
    if (slug)
      await AdminService.getNewsDetail(slug)
        .then((response) => {
          const data = response.data.data;
          if (data.newlyUpdateData.length > 0) {
            setNewsDetail(data.newlyUpdateData[0]);
          } else {
            setNewsDetail(data);
          }
        })
        .catch((error) => {});
  };

  const updateNews = async (values) => {
    try {
      let uploadImage = "";
      let newData = { ...values };
      if (avatar) {
        const formData = new FormData();
        formData.append("files", avatar);
        uploadImage = await AdminService.uploadImageNews(admin.email, formData);
        newData = { ...newData, image: uploadImage.data.data.message[0] };
      }

      const result = await AdminService.updateNews(slug, newData);
      if (result) {
        api.success({
          message: "Thành công",
          description:
            "Cập nhật bài viết thành công! Vui lòng đợi admin phê duyệt bài viết",
          style: { top: 50 },
        });
        history.push(pathAdmin.adminManagerNews.path);
      }
    } catch (error) {
      api.error({
        message: "Thất bại",
        description: "Cập nhật bài viết thất bại",
        style: { top: 50 },
      });
    }
  };

  const uploadAvatar = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setAvatar(e.target.files[0]);
    }
    setCheckAvatar(false);
  };

  const getMessageError = (field) => {
    return updateNewsFormik.errors[field] && updateNewsFormik.touched[field]
      ? updateNewsFormik.errors[field]
      : "";
  };

  return (
    <div
      className={cx(
        "mx-auto min-h-[80vh] lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      {contextHolder}
      <div className="px-4">
        <BreadcrumbAdmin
          data={[
            {
              name: "Cập nhật bài viết",
            },
          ]}
        />
        <form
          onSubmit={updateNewsFormik.handleSubmit}
          className="rounded-[12px] bg-white"
        >
          <div
            className={`w-full h-[120px] rounded-tl-[12px] rounded-tr-[12px] bg-[#1495F3] relative cursor-pointer`}
          >
            <input
              type="file"
              id="upload"
              name="upload"
              onChange={uploadAvatar}
              accept="image/*"
              hidden
            />
            <div
              className={`w-[120px] h-[120px] bg-[#F5F7FF] absolute left-10 -bottom-[50%] flex justify-center items-center ${
                checkAvatar && "border border-red-400"
              }`}
            >
              {avatar && (
                <img
                  src={URL.createObjectURL(avatar)}
                  alt=""
                  className="max-w-full max-h-full object-cover border-none"
                />
              )}
            </div>

            <div
              className="w-[120px] h-[120px] border-none opacity-60 absolute left-10 -bottom-[50%] flex justify-center items-center"
              onClick={() => document.getElementById("upload")?.click()}
            >
              <div className="w-[48px] h-[48px] rounded-[50%] bg-[#B5C1FF] flex justify-center items-center">
                <CameraOutlined className="text-[24px]" />
              </div>
            </div>
          </div>

          <div className="px-[10px] pt-[90px] pb-[20px]">
            <div className="">
              <InputAdmin
                name="title"
                label="Tiêu đề bài viết"
                placeholder="Nhập vào tiêu đề bài viết"
                valueInput={updateNewsFormik.values.title}
                onChange={updateNewsFormik.handleChange}
                error={getMessageError("title")}
                required
              />
            </div>

            <div className="mt-4">
              <InputAdmin
                name="tag"
                label="Tag"
                placeholder="Nhập vào tag"
                valueInput={updateNewsFormik.values.tag}
                onChange={updateNewsFormik.handleChange}
                error={getMessageError("tag")}
                required
              />
            </div>

            <CKEditors
              name="content"
              label="Nội dung bài viết"
              value={updateNewsFormik.values.content}
              onChangeValue={updateNewsFormik.handleChange}
              error={getMessageError("content")}
              required
            />

            <div className="w-full flex justify-end items-center my-4">
              <button
                type="submit"
                onClick={updateNewsFormik.handleSubmit}
                className="px-4 py-3 bg-[#0D91F4] rounded-[12px] text-white"
              >
                Cập nhật
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NewsUpdate;
