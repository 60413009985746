import React from "react";
import { useHistory } from "react-router-dom";
import { routes } from "../../screens/PathRouting";

export default function CardNewsFit({ data }) {
  const history = useHistory();
  return (
    <div
      className="w-full h-[120px] shadow-md bg-white rounded-md flex cursor-pointer"
      onClick={() => history.push(routes.newsDetail.path + `/${data?.slug}`)}
    >
      <div className="w-[120px] h-full rounded-tl-md rounded-bl-md">
        <img
          src={data?.image}
          alt="news alt"
          className="rounded-tl-md rounded-bl-md w-full h-full object-cover"
        />
      </div>
      <div className="p-2 w-[80%] flex justify-center items-center ">
        <p className="font-bold hover:underline">{data?.title}</p>
      </div>
    </div>
  );
}

export const CardNewsFitLoading = () => {
  return (
    <div className="w-full h-[120px] shadow-md bg-white rounded-md flex cursor-pointer">
      <div className="w-[120px] h-full rounded-tl-md rounded-bl-md animate-pulse">
        <div className="w-full h-full rounded-tl-md rounded-bl-md bg-[#ccc]"></div>
      </div>
      <div className="p-2 w-[80%] flex justify-center items-center animate-pulse">
        <div className="w-full h-[38px] bg-[#ccc]"></div>
      </div>
    </div>
  );
};
