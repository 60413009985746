import React from "react";
import PresenterComponent from "../../components/SubComponent/PresenterComponent";
import { useAppSelector } from "../../redux/hooks";
import { selectFormOrder, selectFormToShow } from "../../redux/slice/setting";
import AvatarDefaultCv from "../../components/Avatar";
import DoubleComponent from "../../components/SubComponent/DoubleComponent";
import ContactBlock from "../../components/Block/ContactBlock";

function RightComponent() {
  const { right } = useAppSelector(selectFormOrder);
  const formToShow = useAppSelector(selectFormToShow);

  const formTypeToComponent = {
    skills: (
      <DoubleComponent form="skills" listTitle={{ title: "Tên kĩ năng" }} />
    ),
    interestes: (
      <DoubleComponent
        form="interestes"
        listTitle={{ title: "Tên sở thích" }}
      />
    ),
    presenter: (
      <PresenterComponent
        form="presenter"
        listTitle={{
          title: "Ông/Bà Họ tên người giới thiệu",
          description: "Chức vụ",
          email: "Email",
          phone: "Điện thoại",
        }}
      />
    ),
    addtional_informations: (
      <DoubleComponent
        form="addtional_informations"
        listTitle={{ title: "Thông tin thêm" }}
      />
    ),
  };
  return (
    <div>
      <div className="w-full flex justify-center items-center">
        <div className="w-[265px] h-[265px] p-4">
          <AvatarDefaultCv shape={`rounded-full`} />
        </div>
      </div>
      <div className="px-4">
        <ContactBlock />
        {right &&
          right.map((field) => {
            if (formToShow[field]) {
              const component = formTypeToComponent[field];
              return <React.Fragment>{component}</React.Fragment>;
            }
            return null;
          })}
      </div>
    </div>
  );
}

export default RightComponent;
