import {
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import React, { useCallback, useEffect, useRef, useState } from "react";
import * as pdfjs from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function CardCVView({ data }) {
  const [pdf, setPDF] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [loaded, setLoaded] = useState(true);
  const canvasRef = useRef();

  const renderPage = useCallback(async ({ pdfDoc, pageNum, scale }) => {
    const page = await pdfDoc.getPage(pageNum);
    const ctx = canvasRef.current.getContext("2d");

    const viewport = page.getViewport({ scale });

    canvasRef.current.width = viewport.width;
    canvasRef.current.height = viewport.height;

    page.render({ canvasContext: ctx, viewport: viewport });
  }, []);

  const prevPage = () => {
    if (currentPage > 1) {
      renderPage({ pdfDoc: pdf, pageNum: currentPage - 1, scale });
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < numPages) {
      renderPage({ pdfDoc: pdf, pageNum: currentPage + 1, scale });
      setCurrentPage(currentPage + 1);
    }
  };

  const zoomOut = () => {
    const zoom = scale - 0.1 > 0.5 ? scale - 0.1 : 0.5;
    renderPage({ pdfDoc: pdf, pageNum: currentPage, scale: zoom });
    setScale(zoom);
  };

  const zoomIn = () => {
    const zoom = scale + 0.1 < 2 ? scale + 0.1 : 2;
    renderPage({ pdfDoc: pdf, pageNum: currentPage, scale: zoom });
    setScale(zoom);
  };

  useEffect(() => {
    if (data) {
      const { image } = data;
      const fetchPdf = async () => {
        const loadingTask = pdfjs.getDocument({
          src: image,
          url: image,
        });
        const pdfDoc = await loadingTask.promise;
        setPDF(pdfDoc);
        setNumPages(pdfDoc._pdfInfo.numPages);
        renderPage({ pdfDoc, pageNum: 1, scale: 0.9 });
        setLoaded(true);
      };

      fetchPdf();
    }
  }, [renderPage, data]);

  return (
    <div className="w-full h-[460px] xl:h-[70vh] border border-[#ccc] relative overflow-y-auto">
      <div className="w-full h-[60px] text-black sticky bottom-0 sm:top-0 left-0 bg-white opacity-80 grid grid-cols-2 px-2 sm:px-4 z-[999]">
        <div className="flex justify-start items-center line-clamp-1">
          {data?.name}
        </div>
        <div className="flex justify-center items-center">
          <div className="flex justify-start items-center">
            <div onClick={prevPage} className="cursor-pointer">
              <LeftOutlined style={{ fontSize: "20px" }} />
            </div>
            <div className="mx-2 sm:mx-8">
              {currentPage} / {numPages}
            </div>
            <div onClick={nextPage} className="cursor-pointer">
              <RightOutlined style={{ fontSize: "20px" }} />
            </div>
          </div>
          <div className="flex justify-start items-center ml-4 sm:ml-6">
            <div onClick={zoomIn} className="mr-4 sm:mr-8 cursor-pointer">
              <ZoomInOutlined style={{ fontSize: "20px" }} />
            </div>
            <div onClick={zoomOut} className="cursor-pointer">
              <ZoomOutOutlined style={{ fontSize: "20px" }} />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center mx-auto w-full px-[10px] md:px-0 py-[20px] select-none">
        {!loaded && (
          <LoadingOutlined style={{ color: "white", fontSize: "40px" }} />
        )}
        <canvas ref={canvasRef} className="z-[20]" />
      </div>
    </div>
  );
}

export default CardCVView;
