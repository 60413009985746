import React from "react";
import {
  changeWorkExperiences,
  selectWorkExperiences,
} from "../../../lib/redux/resumeSlice";
import { Form, FormSection } from "./Form";
import { BulletListTextarea, Input } from "./Form/InputGroub";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";

export const WorkExperiencesForm = () => {
  const workExperiences = useAppSelector(selectWorkExperiences);
  const dispatch = useAppDispatch();

  const showDelete = workExperiences.length > 1;

  return (
    <Form form="workExperiences" addButtonText="Thêm mới">
      {workExperiences.map(({ company, jobTitle, date, descriptions }, idx) => {
        const handleWorkExperienceChange = (...[field, value]) => {
          dispatch(changeWorkExperiences({ idx, field, value }));
        };
        const showMoveUp = idx !== 0;
        const showMoveDown = idx !== workExperiences.length - 1;

        return (
          <FormSection
            key={idx}
            form="workExperiences"
            idx={idx}
            showMoveUp={showMoveUp}
            showMoveDown={showMoveDown}
            showDelete={showDelete}
            deleteButtonTooltipText="Xóa"
          >
            <Input
              label="Công ty"
              labelClassName="col-span-full"
              name="company"
              placeholder="Nhập tên công ty"
              value={company}
              onChange={handleWorkExperienceChange}
            />
            <Input
              label="Vị trí"
              labelClassName="col-span-4"
              name="jobTitle"
              placeholder="Nhập vị trí"
              value={jobTitle}
              onChange={handleWorkExperienceChange}
            />
            <Input
              label="Thời gian"
              labelClassName="col-span-2"
              name="date"
              placeholder="03/2023 - Hiện tại"
              value={date}
              onChange={handleWorkExperienceChange}
            />
            <BulletListTextarea
              label="Mô tả công việc"
              labelClassName="col-span-full"
              name="descriptions"
              placeholder="Mô tả công việc"
              value={descriptions}
              onChange={handleWorkExperienceChange}
            />
          </FormSection>
        );
      })}
    </Form>
  );
};
