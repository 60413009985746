import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { pathAdmin } from "../../screens/PathRouting";
import CardEmpty from "../components/Card/CardEmpty";
import CardCompany from "../components/Card/CardCompany";
import { Pagination } from "antd";
import CreateCompany from "../components/Modal/CreateCompany";
import BreadcrumbAdmin from "../components/Tag/Breadcrumb";
import AdminService from "../../services/AdminService";
import queryString from "query-string";
import UserService from "../../services/UserService";
import { getImageUrl } from "../../utils";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import { changePagination } from "../../lib/theme/config";

function CompanyList() {
  const { theme } = useTheme();
  const [popupCreate, setPopupCreate] = useState();
  const [companies, setComapanies] = useState();
  const [totalItem, setTotalItem] = useState(1);
  const location = useLocation();

  const search = queryString.parse(location.search);

  const history = useHistory();

  useEffect(() => {
    if (!companies) {
      getAllListCompany(search?.page || 1);
    }
  }, [companies, search]);

  const getAllListCompany = async (page) => {
    const params = queryString.stringify({
      offset: page - 1,
      limit: 11,
    });
    const allCompany = await AdminService.getAllCompany(params);
    const data = allCompany.data.data.filter(
      (item, index) => index > 0 && item
    );
    const images = data.map((item) => item.avatar);
    const listImage = await UserService.getImageByName(images);
    const result = data.map((item, index) => ({
      id: item.id,
      avatar: getImageUrl(listImage.data.data, item.avatar),
      companyName: item.name,
      countJds: item.countJds,
      countCandidate: item.countCVs,
    }));
    setComapanies(result);
    const pages = allCompany.data.data.find((item) => item.countTotal);
    setTotalItem(pages?.countTotal || 10);
  };

  return (
    <div
      className={cx(
        "mx-auto min-h-[87vh] lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <div className="px-4">
        <BreadcrumbAdmin
          data={[
            {
              name: "Quản lý danh mục công ty",
            },
          ]}
        />
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-[60px]">
          <CardEmpty
            icon="/icons/elementplus.png"
            title="Thêm công ty"
            onClick={() => setPopupCreate(true)}
          />
          {companies &&
            companies.length > 0 &&
            companies.map((company, index) => (
              <CardCompany
                key={"company" + index + "list"}
                index={index}
                data={company}
                onHandleClick={() =>
                  history.push(
                    pathAdmin.adminCompanyDetail.parentPath + `/${company.id}`
                  )
                }
              />
            ))}
        </div>
        <div className="w-full flex justify-center items-center">
          <Pagination
            defaultCurrent={search.page || 1}
            total={totalItem}
            onChange={(value) => {
              getAllListCompany(value);
              history.replace(
                location.pathname + "?" + queryString.stringify({ page: value })
              );
            }}
            pageSize={11}
            itemRender={changePagination}
          />
        </div>
      </div>
      <CreateCompany
        active={popupCreate}
        onChangeActive={() => {
          getAllListCompany(search?.page || 1);
          setPopupCreate(!popupCreate);
        }}
      />
    </div>
  );
}

export default CompanyList;
