import { Image } from "antd";
import React from "react";
import Tag from "../Tag";
import { useHistory } from "react-router-dom";
import { routes } from "../../screens/PathRouting";

function CardShow(props) {
  const { tag } = props;
  const history = useHistory()
  return (
    <div className="w-full h-[145px] bg-[#F4F9FF] rounded-[22px] p-[16px]">
      <div className="w-full h-[30px]">
        <p className={`text-[16px] font-[600] text-[#1B2124] ${props?.id && "hover:underline cursor-pointer"}`} onClick={() =>
            props?.id &&
            history.push(routes.workDetail.parentPath + `/${props.id}`)
          }>{props.title}</p>
      </div>
      <div className="w-full h-[20px] grid grid-cols-4 my-[10px]">
        <div className="flex col-span-2">
          <div className="w-[14px] h-[14px]">
            <Image
              src={"/asserts/location.png"}
              alt="location icon"
              width={12}
              height={12}
              className="object-cover"
            />
          </div>
          <div className="w-full flex items-center">
            <p className="text-[10px] text-[#1B2124] ml-[5px]">
              {props.location}
            </p>
          </div>
        </div>
        <div className="flex">
          <div className="w-[14px] h-[14px]">
            <Image
              src={"/asserts/dollar-circle.png"}
              alt="dolar icon"
              width={12}
              height={12}
              className="object-cover"
            />
          </div>
          <div className="w-full flex items-center">
            <p className="text-[10px] text-[#1B2124] ml-[5px]">
              {props.salary}
            </p>
          </div>
        </div>
        <div className="flex">
          <div className="w-[14px] h-[14px]">
            <Image
              src={"/asserts/clock.png"}
              alt="clock icon"
              width={14}
              height={14}
              className="object-cover"
            />
          </div>
          <div className="w-full flex items-center">
            <p className="text-[10px] text-[#1B2124] ml-[5px]">{props.date}</p>
          </div>
        </div>
      </div>
      <div className="w-full h-auto my-[10px]">
        <Tag title={tag?.city} color={"#BBE2C6"} />
        <Tag title={tag?.type} color={"#78C58C"} />
        <Tag title={tag?.experience} color={"#B5C1FF"} />
      </div>
    </div>
  );
}

export default CardShow;
