import React, { useEffect, useState } from "react";
import StatusContact from "./StatusContact";
import UserService from "../../services/UserService";
import ShowCandidateCv from "../../components/Modals/ShowCandidateCv";
import dayjs from "dayjs";
import queryString from "query-string";
import ChooseCvCreate from "../../components/Modals/ChooseCvCreate";
import { getImageUrl } from "../../utils";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import CardCvCreate from "../../components/Card/CardCvCreate";
import DeleteCvModal from "../../components/Modals/DeleteCvModal";

function CvManagement({ profile, token }) {
  const { theme } = useTheme();
  const [listCvUserUpload, setListCvUserUpload] = useState();
  const [listCvUserCreated, setListCvUserCreated] = useState();
  const [active, setActive] = useState(false);
  const [cvShow, setCvShow] = useState();
  const [activeCvCreate, setActiveCvCreate] = useState(false);
  const [toggleDelete, setToggleDelete] = useState(false);

  const [dataRemove, setDataRemove] = useState();

  const [uploadCv, setUploadCv] = useState();

  useEffect(() => {
    if (!listCvUserUpload && profile) getAllCvByUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCvUserUpload, profile]);

  useEffect(() => {
    if (uploadCv) {
      const params = queryString.stringify({
        role: "Candidate",
        email: profile.email,
        image_name: uploadCv.name,
      });

      const formData = new FormData();
      formData.append("files", uploadCv);

      UserService.uploadCv(params, formData)
        .then((result) => {
          if (result) {
            getAllCvByUser();
            setUploadCv();
          }
        })
        .catch((error) => console.log(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadCv]);

  const getAllCvByUser = async () => {
    const listCv = await UserService.getAllCvByUserId(profile.id);
    const listCvImg = listCv.data.data.listCvImg;
    const listCvPdf = listCv.data.data.listCvPdf;
    let listImg = listCvImg
      .map((c) => c.img[0])
      .concat(listCvPdf.map((c) => c.img[0]));
    listImg = await UserService.getImageByName(listImg);

    const result = listCvImg.map((cv) => ({
      candidateId: profile?.id,
      filename: cv.name,
      name: cv.name.split(".pdf")[0],
      original_image: cv.img[0],
      image: getImageUrl(listImg.data.data, cv.img[0]),
      createAt: dayjs(new Date(cv.createAt)).format("DD-MM-YYYY"),
    }));

    const resultCreate = listCvPdf.map((cv) => ({
      candidateId: profile?.id,
      filename: cv.name,
      name: cv.name.split(".pdf")[0],
      original_image: cv.img[0],
      image: getImageUrl(listImg.data.data, cv.img[0]),
      createAt: dayjs(new Date(cv.createAt)).format("DD-MM-YYYY"),
      isMain: cv?.isMain,
    }));

    setListCvUserUpload(result);
    setListCvUserCreated(resultCreate);
  };

  const uploadFile = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setUploadCv(e.target.files[0]);
    }
  };

  const handleCreateCv = () => {
    setActiveCvCreate(true);
  };

  const handleUploadCv = () => {
    document.getElementById("uploadCv").click();
  };

  return (
    <div
      className={cx(
        "w-full grid grid-cols-1 lg:grid-cols-8 gap-4",
        active && "overflow-hidden",
        theme?.background?.text,
        token && "xl:grid-cols-1"
      )}
    >
      <div className="col-span-1 mt-4 block lg:hidden">
        <StatusContact profile={profile} />
      </div>
      <div
        className={cx(
          "col-span-1 lg:col-span-6 mt-[10px]",
          token && "col-span-1"
        )}
      >
        <p className="text-[32px] font-[700]">Quản lý CV</p>
        <p>Thêm hoặc sửa CV của bạn</p>
        <div className="w-full sm:flex sm:justify-between sm:items-center my-[30px]">
          <p className="text-[18px] font-[600]">
            Các CV đã tạo trên Manpower Bank
          </p>
          <div className="flex justify-end items-center">
            <div
              className="px-4 py-2 bg-[#0055FF] text-white text-[12px] rounded-md cursor-pointer"
              onClick={handleCreateCv}
            >
              + Tạo CV mới
            </div>
          </div>
        </div>
        <div className="w-full">
          {listCvUserCreated && listCvUserCreated.length > 0 ? (
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
              {listCvUserCreated.map((data) => (
                <CardCvCreate
                  data={data}
                  onChangeImage={(value) => {
                    setActive(!active);
                    setCvShow(value);
                  }}
                  onChangeDelete={() => {
                    setDataRemove({ data: data, isUpload: false });
                    setToggleDelete(true);
                  }}
                  reloadPage={getAllCvByUser}
                />
              ))}
            </div>
          ) : (
            <p>Bạn chưa có CV tạo trên hệ thống.</p>
          )}
        </div>
        <div className="w-full sm:flex sm:justify-between sm:items-center my-[30px]">
          <p className="text-[18px] font-[600]">
            Các CV đã tải lên Manpower Bank
          </p>
          <div className="flex justify-end items-center">
            <input
              type="file"
              id="uploadCv"
              name="imageAvatar"
              onChange={uploadFile}
              accept="application/pdf"
              hidden
            />
            <div
              className="px-4 py-2 bg-[#0055FF] text-white text-[12px] rounded-md cursor-pointer"
              onClick={handleUploadCv}
            >
              + Tải lên CV
            </div>
          </div>
        </div>
        <div className="w-full">
          {listCvUserUpload && listCvUserUpload.length > 0 ? (
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
              {listCvUserUpload.map((data) => (
                <CardCvCreate
                  data={data}
                  isUpload
                  onChangeImage={(value) => {
                    setActive(!active);
                    setCvShow(value);
                  }}
                  onChangeDelete={() => {
                    setDataRemove({ data: data, isUpload: true });
                    setToggleDelete(true);
                  }}
                  reloadPage={getAllCvByUser}
                />
              ))}
            </div>
          ) : (
            <p>Bạn chưa có CV tải lên trên hệ thống.</p>
          )}
        </div>
      </div>
      <div
        className={cx(
          "col-span-1 lg:col-span-2 hidden lg:block",
          token && "xl:hidden"
        )}
      >
        <StatusContact profile={profile} />
      </div>
      <ShowCandidateCv
        active={active}
        onChangeActive={() => setActive(!active)}
        data={cvShow}
      />

      <ChooseCvCreate
        active={activeCvCreate}
        onChangeActive={() => setActiveCvCreate(!activeCvCreate)}
      />

      <DeleteCvModal
        data={dataRemove}
        active={toggleDelete}
        onChangeActive={() => {
          setToggleDelete(!toggleDelete);
        }}
        onReloadPage={() => {
          getAllCvByUser();
          setToggleDelete(!toggleDelete);
        }}
      />
    </div>
  );
}

export default CvManagement;
