import React from "react";
import InputCv from "../Input";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { changeObjective, selectObjective } from "../../redux/slice/builder";
import { changeFormToChange, selectSetting } from "../../redux/slice/setting";
import SettingBlock from "../Block/SettingBlock";
import { debounce } from "../../../utils";
import { cx } from "../../../lib/cx";

function ObjectiveComponent({ form, position, titleColor, border }) {
  const objective = useAppSelector(selectObjective);
  const setting = useAppSelector(selectSetting);
  const { fontSize, fontFamily, themeColor } = setting;
  const dispatch = useAppDispatch();

  const handleChangeInput = (field, value) => {
    dispatch(changeObjective({ field, value }));
  };

  const handleChangeActive = () => {
    dispatch(changeFormToChange({ field: "childActive", value: "" }));
  };

  return (
    <div className="w-full">
      <SettingBlock form={form} position={position || "right"} isActive color>
        <div
          className={cx(
            "w-full mb-2 flex justify-between items-center",
            !titleColor && "border border-x-0 border-t-0 border-b-[black]"
          )}
        >
          <p
            className="pb-1"
            style={{
              fontSize: `${Number(fontSize) + (position ? 4 : 8)}px`,
              fontFamily: `${fontFamily}`,
              color: titleColor || "black",
            }}
            onMouseMove={debounce(handleChangeActive, 10)}
          >
            <b>Mục tiêu nghề nghiệp</b>
          </p>
          {border && (
            <hr
              className={cx("w-[53%]", border)}
              style={{ color: `${themeColor}` }}
            />
          )}
        </div>
        <InputCv
          id="objective"
          name="objective"
          value={objective || ""}
          onChangeInput={handleChangeInput}
          placeholder="Mục tiêu nghề nghiệp của bạn, bao gồm mục tiêu ngắn hạn và mục tiêu dài hạn"
        />
      </SettingBlock>
    </div>
  );
}

export default ObjectiveComponent;
