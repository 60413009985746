import { Checkbox, Switch } from "antd";
import React from "react";

function StatusContact({ profile }) {
  return (
    <div className="w-full sm:w-[50%] lg:w-full bg-white p-3 rounded-2xl">
      <div className="w-full flex justify-start items-center">
        <div className="w-[60px] h-[60px] rounded-[50%] bg-[#B08282]">
          <img
            src={profile?.avatar || "/images/avatar.jpeg"}
            alt=""
            className="w-full h-full object-cover rounded-[50%]"
          />
        </div>
        <div className="w-auto ml-2">
          <p className="font-[600] text-[14px] text-black">
            {profile?.fullName || ""}
          </p>
          <p className="text-[12px]">{profile?.jobTitle || ""}</p>
        </div>
      </div>
      <div className="w-full mt-4">
        <p className="text-md text-black font-[500]">Trạng thái tìm việc</p>
      </div>
      <div className="w-full grid grid-cols-4">
        <div className="px-3 py-1 bg-[#268D61] text-[14px] text-white col-span-3 rounded-md">
          <img
            src="/icons/briefcases.png"
            alt=""
            className="w-[14px] h-[14px] object-cover inline text-white mr-2"
          />
          Sẵn sàng làm việc
        </div>
        <div className="col-span-1 flex justify-end items-center">
          <Switch defaultChecked onChange={() => {}} />
        </div>
      </div>
      <div className="w-full grid grid-cols-4 mt-3">
        <div className="text-[14px] text-black font-[500] col-span-3 rounded-md">
          Cho phép NTD liên hệ qua
        </div>
        <div className="col-span-1 flex justify-end items-center">
          <Switch defaultChecked onChange={() => {}} />
        </div>
      </div>
      <div className="w-full flex justify-start items-center">
        <div>
          <Checkbox onChange={() => {}}>CV Online</Checkbox>
        </div>
        <div>
          <Checkbox onChange={() => {}}>Hồ sơ cá nhân</Checkbox>
        </div>
      </div>
    </div>
  );
}

export default StatusContact;
