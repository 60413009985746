import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../cv/redux/hooks";
import {
  changeValidateForm,
  selectValidateForm,
} from "../../cv/redux/slice/setting";
import { scrollSmooth } from "../../utils";

function AutoScrollTop() {
  const { scrollTop } = useAppSelector(selectValidateForm);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (scrollTop) {
      scrollSmooth("scroll-smooth");
      dispatch(changeValidateForm({ field: "scrollTop", value: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTop]);

  return null;
}

export default AutoScrollTop;
