import React from "react";
import { DATA_PAYMENT_CANDIDATE } from "../../utils/constants";
import ScrollTop from "../../components/ScrollTop";
import { Check } from "@mui/icons-material";
import { formatVND } from "../../utils";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import HeaderSeo from "../../components/Header/HeaderSeo";

function PaymentCandidate() {
  const { theme } = useTheme();
  const data = DATA_PAYMENT_CANDIDATE;
  return (
    <>
      <HeaderSeo
        title="Dịch vụ tư vấn CV và định hướng tìm việc"
        description="Lao động online - Cung cấp dịch vụ tư vấn CV và định hướng tìm việc rẻ nhất thị trường"
      />
      <div
        className={cx(
          "mx-auto min-h-[80vh] lg:max-w-[1175px] select-none my-[100px]",
          theme?.background?.gradient
        )}
      >
        <ScrollTop />
        <div className="px-4">
          <div className="grid grid-cols-1 sm:grid-cols-3 sm:gap-4">
            <div className="col-span-2">
              <h1 className={cx("text-[32px] mb-4", theme?.paymentPage?.text)}>
                Dịch Vụ <span className="text-[#3BD8E2]">Tư Vấn CV</span> Và{" "}
                <span className="text-[#3BD8E2]">Định Hướng Tìm Việc</span>
              </h1>
              {[
                "Sở hữu CV ghi điểm với nhà tuyển dụng trong 3s đầu tiên",
                "CV lọt top 20% được gọi mời phỏng vấn, chinh phục công việc mơ ước",
                "Nhận bản CV hoàn chỉnh, chuyên nghiệp trong thời gian nhanh nhất",
              ].map((item) => (
                <>
                  <div className="flex justify-start items-center space-x-2 mb-2">
                    <div className="w-[24px] h-[24px] rounded-[50%] flex justify-center items-center bg-[#3BD8E2] border">
                      <Check style={{ fontSize: "14px" }} />
                    </div>
                    <p className={cx("text-[18px]", theme?.paymentPage?.text)}>
                      {item}
                    </p>
                  </div>
                </>
              ))}
            </div>
            <div className="col-span-1">
              <div className="w-full h-[190px] rounded-[22px]">
                <img
                  src="/sale/services.png"
                  alt=""
                  className="w-full h-full object-cover rounded-[22px]"
                />
              </div>
            </div>
          </div>
          <h2
            className={cx(
              "text-center text-[32px] my-[40px]",
              theme?.paymentPage?.text
            )}
          >
            Lựa chọn <span className="text-[#3BD8E2]">giải pháp </span>
            phù hợp <span className="text-[#3BD8E2]">dành cho bạn</span>
          </h2>

          <div className="mt-[40px]">
            {data.map((item) => (
              <>
                <div className="w-full grid grid-cols-1 sm:grid-cols-3 shadow-sm mb-[80px]">
                  <div className="w-full h-full block sm:hidden">
                    <img
                      src={item.image}
                      alt=""
                      className="w-full h-full object-cover rounded-tr-[12px] rounded-tl-[12px]"
                    />
                  </div>
                  <div
                    className={cx(
                      "p-[20px] font-[600] sm:pr-[100px] text-white col-span-2 rounded-br-[12px] sm:rounded-br-[0px] sm:rounded-tl-[12px] rounded-bl-[12px]",
                      theme?.paymentPage?.background
                    )}
                  >
                    <p className="text-[24px]">{item.name}</p>
                    <p className="text-[16px]">{item.sub_name}</p>

                    <div className="flex space-x-4 my-2">
                      <p className="line-through text-[16px]">
                        {formatVND(item.sub_price)} VND
                      </p>
                      <p className="text-[16px] text-[#08EFFE]">
                        {formatVND(item.price)} VND / Lần
                      </p>
                    </div>

                    <hr className="w-full" />

                    <div className="mt-[15px]">
                      {item.description.map((item) => (
                        <>
                          <div className="flex mb-2">
                            <div className="w-[24px] h-[24px]">
                              <Check style={{ fontSize: "18px" }} />
                            </div>
                            <p className="text-justify ml-2">{item}</p>
                          </div>
                        </>
                      ))}
                    </div>

                    <div className="w-[180px] bg-[#28C1F1] shadow-md text-center text-white py-2 rounded-[18px] mt-[20px] cursor-pointer">
                      Đăng ký ngay
                    </div>
                  </div>
                  <div className="w-full h-full hidden sm:block">
                    <img
                      src={item.image}
                      alt=""
                      className="w-full h-full object-cover rounded-tr-[12px] rounded-br-[12px]"
                    />
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentCandidate;
