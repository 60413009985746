import { configureStore } from "@reduxjs/toolkit";
import resumeReducer from "../redux/resumeSlice";
import settingsReducer from "../redux/settingsSlice";

export const store = configureStore({
  reducer: {
    resume: resumeReducer,
    settings: settingsReducer,
  },
});
