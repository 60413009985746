import { EyeOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import Tag from "../../components/Tag";
import { CardCV3 } from "../../components/CardItem/CardCV";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "../PathRouting";

function JDTag({ data, isExtension, cv, handleChangeSaveJd }) {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const history = useHistory();

  const handleClosePopup = () => {
    setPopupVisible(false); // Close the popup when the Close button is clicked
  };

  return (
    <div className="w-full lg:h-[120px] bg-white p-[10px] rounded-[12px] grid grid-cols-1 lg:grid-cols-3 my-[10px]">
      {isPopupVisible && (
        <CardCV3 data={{ image: cv?.image }} onChangeImage={handleClosePopup} />
      )}
      <div className="col-span-1 lg:col-span-2">
        <div className="grid grid-cols-6">
          <div className="w-[80px] h-[80px] rounded-[12px] col-span-1 flex justify-start items-center">
            {data?.image ? (
              <img src={data.image} alt="avatar" className="object-cover" />
            ) : (
              <div className="w-full h-full bg-[#D9D9D9] rounded-[12px]"></div>
            )}
          </div>
          <div
            className="w-full lg:border-r-[2px] lg:border-r-[#D9D9D9] ml-2 md:ml-0 col-span-5 pl-7 pr-[10px]"
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push(routes.workDetail.parentPath + "/" + data.id)
            }
          >
            <p className="text-[18px] font-[700] text-black line-clamp-1 select-none">
              <Tooltip title={data?.job}>{data?.job}</Tooltip>
            </p>
            <p className="text-[14px] text-black">{data?.name_company}</p>
            <Tag
              color="#DDE4FF40"
              title={
                data?.dueTime > 0
                  ? `Còn ${data.dueTime} ngày để ứng tuyển`
                  : "Hết thời gian ứng tuyển"
              }
              className="!text-[10px] !py-[2px] !px-[8px] !text-black mt-4"
            />
          </div>
        </div>
      </div>

      <div className="col-span-1 px-2">
        <div className="w-full flex mb-2">
          <div className="w-full flex justify-between gap-1">
            <div className="flex items-center">
              <img
                src={"/asserts/location.png"}
                alt="icon"
                className="w-[12px] h-[12px]"
              />
              <span className="text-[12px] ml-1 text-black">
                {data?.location}
              </span>
            </div>
            <div className="flex items-center">
              <img
                src={"/asserts/dollar-circle.png"}
                alt="icon"
                className="w-[12px] h-[12px]"
              />
              <span className="text-[12px] ml-1 text-black">
                {data?.salary}
              </span>
            </div>
          </div>
        </div>
        {data?.jobType && (
          <Tag
            title={data.jobType}
            color={"#78C58C"}
            className="py-1 h-auto !px-1"
          />
        )}
        {data?.experience && <Tag title={data?.experience} color={"#B5C1FF"} />}
        <div className="w-full flex justify-end items-end">
          <div className="bg-[#1495F3] px-3 py-1 rounded-[44px]">
            {isExtension ? (
              <div
                className="flex justify-center items-center cursor-pointer"
                onClick={() => handleChangeSaveJd(data?.id)}
              >
                <img
                  src={"/icons/save.png"}
                  alt=""
                  className="w-[12px] h-[12px]"
                />
                <span className="pl-2 text-sm text-white">Bỏ quan tâm</span>
              </div>
            ) : (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setPopupVisible(true);
                }}
              >
                <EyeOutlined style={{ color: "white", cursor: "pointer" }} />
                <span className="pl-2 text-sm text-white">Xem lại CV </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default JDTag;
