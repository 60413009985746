import React, { useEffect, useState } from "react";
import { Divider, Select, Pagination, Input } from "antd";
import {
  SearchOutlined,
  CalendarOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { Bt } from "../../../components/BtForm";
import {
  CardHomepage,
  CardHomepageAdding,
} from "../../../components/Recruiter/CardHomepage";
import UserService from "../../../services/UserService";
import { listJDValidated } from "../../../services/PORT";
import { routes } from "../../PathRouting";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import Breadcrumb from "../../../components/Tag/Breadcrumb";

export const ManageJD = (props) => {
  const history = useHistory();
  const location = useLocation();

  const search = queryString.parse(location.search);

  const [listJD, setListJD] = useState();
  const [totalJD, setTotalJD] = useState(0);
  const [status, setStatus] = useState();
  const [searchInput, setSearchInput] = useState();

  const limit = 15;

  useEffect(() => {
    if (!listJD) {
      setSearchInput(search?.jobName || "");
      getJD(
        search?.status || "ACTIVE",
        search?.jobName || "",
        search?.page || 1
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listJD]);

  const getJD = (status = "ACTIVE", jobName = "", page = 1) => {
    setStatus(status);
    const dataUser = JSON.parse(localStorage.getItem("User"));
    UserService.getListJDByRecruiterID(
      listJDValidated,
      dataUser.id,
      true,
      page - 1,
      limit,
      status,
      jobName
    )
      .then((res) => {
        const data = res.data.data;
        setTotalJD(data.find((item) => item.count)?.count || 0);
        if (data.length < 2) setListJD([]);
        else setListJD(data.filter((item) => !item.count));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onHandleSearch = () => {
    const params = {
      ...search,
      jobName: searchInput,
    };

    if (!searchInput) delete params?.jobName;

    history.replace(location.pathname + `?${queryString.stringify(params)}`);
    getJD(status, searchInput);
  };

  return (
    <div className="px-[7%] py-[3%] space-y-3">
      <Breadcrumb
        data={[
          {
            path: routes.recruiterDashboard.path,
            name: "Dashboard",
          },
          {
            name: "Quản lý bài tuyển dụng",
          },
        ]}
        isCheck
      />

      <div className="flex flex-col md:flex-row w-full min-h-[50px] bg-white rounded-[12px] justify-between items-center p-1">
        <div className="flex flex-row items-center w-full md:w-[80%] h-[50px] pl-2">
          <SearchOutlined />
          <Input
            name="jobName"
            value={searchInput}
            placeholder="Trạng thái"
            onChange={(e) => setSearchInput(e.target.value)}
            className="focus:outline-none border-none focus:border-none ml-2"
          />
        </div>

        <div className="flex flex-row w-full md:w-[50%] h-[50px] items-center justify-between md:space-x-2">
          <Divider type="vertical" className="border-[1px] h-0 md:h-[25px]" />
          <div className="flex flex-row items-center w-[30%]">
            <CalendarOutlined />
            <Select
              className="w-full"
              bordered={false}
              placeholder="Trạng thái"
              suffixIcon={<CaretDownOutlined />}
              defaultValue={"ACTIVE"}
              options={[
                {
                  value: "ACTIVE",
                  label: "Active",
                },
                {
                  value: "INACTIVE",
                  label: "Inactive",
                },
              ]}
              onChange={(value) => {
                history.replace(
                  location.pathname +
                    `?${queryString.stringify({ ...search, status: value })}`
                );
                getJD(value);
              }}
            />
          </div>
          <Bt
            className="bg-blue-500 rounded-[10px] h-[90%] w-[120px]"
            title="Tìm kiếm"
            onClick={onHandleSearch}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl2:grid-cols-4 gap-3">
        <CardHomepageAdding
          onClick={() => history.push(routes.recruiterCreateJd.path)}
        />
        {listJD &&
          listJD.length > 0 &&
          listJD.map((item) => {
            return (
              <CardHomepage
                jobName={item.jobName}
                location={item.jobName}
                createAt={item.createAt}
                count={item.countCVs}
                onClick={() =>
                  history.push(routes.detailJDs.parentPath + `/${item.id}`)
                }
                status={item.status}
              />
            );
          })}
      </div>
      <div className="w-full flex flex-col items-center">
        {totalJD > 0 && (
          <Pagination
            defaultCurrent={search?.page || 1}
            total={totalJD}
            onChange={(page) => {
              history.replace(
                location.pathname +
                  `?${queryString.stringify({ ...search, page: page })}`
              );
              getJD(search?.status || "ACTIVE", "", page);
            }}
            pageSize={limit}
          />
        )}
      </div>
    </div>
  );
};
