import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Avatar,
  Form,
  Radio,
  DatePicker,
  Checkbox,
  ConfigProvider,
} from "antd";
import { useHistory } from "react-router-dom";
import { getOptions, posting } from "../../services/PORT";
import UserService from "../../services/UserService";
import AdminService from "../../services/AdminService";
import { routes } from "../PathRouting";
import { FormInput, FormSelect } from "../../components/LoginInput";
import dayjs from "dayjs";
import { BtCheck, BtField, BtRadio } from "../../components/BtCheck";
import { CardSalary } from "../../components/CardInfor";
import { Stack } from "@mui/material";
import { Bf, Bt } from "../../components/BtForm";
import { DeleteOutlined } from "@ant-design/icons";
import { SuccessPost } from "../../components/Preview";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  OPTION_EDUCATION,
  OPTION_MONTH,
  OPTION_SALARY,
} from "../../utils/constants";
import CKEditors from "../../components/CKEditor/CKEditors";
import { useFormik } from "formik";
import { createJdSchema } from "../../utils/validations";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";

dayjs.extend(customParseFormat);

const logo = "/asserts/Manpowerbank1.png";
const { RangePicker } = DatePicker;

const CreateJd = () => {
  const { theme } = useTheme();
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem("User"));

  const item = [
    {
      title: "Dashboard",
    },
    {
      title: "Quản lý bài tuyển dụng",
    },
    {
      title: "Thêm bài tuyển dụng",
    },
  ];

  const [optionsCompany, setOptionsCompany] = useState([]);
  const [department, setDepartment] = useState([]); // danh sách option lĩnh vực muốn tuyển
  const [fieldRecruite, setFieldRecruite] = useState([]); // danh sách option ngành nghề cần tuyển
  const [optionLangague, setOptionLangague] = useState([]); // danh sách option ngôn ngữ
  const [listLanguage, setListLanguage] = useState([]);
  const [listCertificates, setListCertificates] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [departments, setDepartments] = useState();
  const [listJobTypes, setListJobTypes] = useState();
  const [listLevel, setListLevel] = useState();

  const createJdFormik = useFormik({
    initialValues: {
      recruiterId: userData.id,
      jobName: "",
      department: "",
      major: "",
      company: "",
      companyName: "",
      location: "",
      jobTypes: ["Toàn thời gian"],
      levels: ["Nhân viên"],
      startReceiveCV: "",
      endReceiveCV: "",
      positions: "",
      jobDescription: "",
      cvRequire: true,
      salaryDisplay: "Salary_Range",
      salaryCountFollow: "Month",
      isDurationSubmit: true,
      submitEndDate: "",
      dailyUpdate: [],
      setupMessage: true,
      educationalLevel: 0,
      education: "Vocational",
      experience: 0,
      languages: [],
      questions: "",
      description: "",
      requirement: "",
      benefits: "",
      companyAvatar: userData.avatarImg[0],
      minSalary: 0,
      maxSalary: 0,
      salary: 0,
    },
    validationSchema: createJdSchema,
    onSubmit: (values) => {
      const dataUpdate = {
        ...values,
        jobDescription: [
          values.description,
          values.requirement,
          values.benefits,
        ],
        maxSalary:
          values.salaryDisplay === "Salary_Range" ? values.maxSalary : 0,
        minSalary:
          values.salaryDisplay === "Salary_Range" ? values.minSalary : 0,
        salary:
          values.salaryDisplay === "Final_Salary" ||
          values.salaryDisplay === "Starting_Salary"
            ? values.salary
            : 0,
        questions: [values.questions],
      };
      delete dataUpdate.description;
      delete dataUpdate.requirement;
      delete dataUpdate.benefits;

      UserService.sendData(dataUpdate, posting)
        .then((res) => {
          setConfirm(!confirm);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  useEffect(() => {
    if (!departments) {
      UserService.getOption(getOptions, "department")
        .then((res) => {
          const result = res.data.data[0];
          const keys = Object.keys(result);
          setDepartments(result);
          setDepartment(keys.map((item) => ({ value: item, label: item })));
        })
        .catch((err) => {
          console.log(err);
        });

      UserService.getOption(getOptions, "language")
        .then((res) => {
          setOptionLangague(
            res.data.data.map((item) => {
              return {
                value: item,
                label: item,
              };
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });

      AdminService.getAllCompany({
        params: { offset: 0, limit: 100 },
      })
        .then((res) => {
          const temp = res.data.data;
          temp.splice(0, 1);
          setOptionsCompany(
            temp.map((item) => {
              return { value: item.name, label: item.name, id: item.id };
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });

      getAllOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departments]);

  const getAllOptions = () => {
    UserService.getOption(getOptions, "jobtype")
      .then((res) => {
        const data = res.data.data;
        setListJobTypes(data.filter((item) => item !== "Tất cả"));
      })
      .catch(() => {});
    UserService.getOption(getOptions, "level")
      .then((res) => {
        const data = res.data.data;
        setListLevel(data.filter((item) => item !== "Tất cả"));
      })
      .catch(() => {});
  };

  const changeValueField = (value, option) => {
    const data = departments[value].map((item) => ({
      value: item,
      label: item,
    }));
    setFieldRecruite(data);
  };

  const formatDate = (date) => {
    if (!date) return "";
    return dayjs(date, "YYYY-MM-DD");
  };

  const handleAddLanguage = () => {
    if (listLanguage[listLanguage.length - 1] !== "") {
      const data = [...listLanguage, ""];
      setListLanguage(data);
      changeValueSelect(data);
    }
  };

  const handleAddCertificate = () => {
    if (listCertificates[listCertificates.length - 1] !== "") {
      const data = [...listCertificates, ""];
      setListCertificates(data);
      changeValueSelect("certificates", data);
    }
  };

  const handleDeleteLanguage = (index) => {
    const newList = [...listLanguage];
    newList.splice(index, 1);
    setListLanguage(newList);
    changeValueSelect("languages", newList);
  };

  const handleDeleteCertificate = (index) => {
    const newList = [...listCertificates];
    newList.splice(index, 1);
    setListCertificates(newList);
    changeValueSelect("certificates", newList);
  };

  const onClickCancel = () => {
    setConfirm(!confirm);
    history.push(routes.recruiterDashboard.path);
  };

  const changeValueSelect = (name, value) => {
    createJdFormik.handleChange({
      target: {
        name: name,
        value: value,
      },
    });
  };

  const changeCheckbox = (name, value) => {
    let data = createJdFormik.values[name];
    const isCheck = data.find((c) => c === value);
    if (!isCheck) {
      data.push(value);
    } else {
      data = data.filter((c) => c !== value);
    }
    changeValueSelect(name, data);
  };

  const getMessageError = (field) => {
    return createJdFormik.errors[field] && createJdFormik.touched[field]
      ? createJdFormik.errors[field]
      : "";
  };

  return (
    <div className="md:px-[15%] py-[2%]">
      <Breadcrumb items={item} className={cx(theme?.background?.text)} />
      <h1
        className={cx(
          "text-[35px] font-[600] my-[40px]",
          theme?.background?.text
        )}
      >
        Bài tuyển dụng mới
      </h1>
      <div className="w-full rounded-[10px] bg-white p-[1%] px-[2%] space-y-4">
        <div className="flex flex-col xl:flex-row gap-3">
          <Avatar className="min-w-fit" shape="square" src={logo} size={150} />
          <Form className="min-w-max xl:w-full space-y-1" layout="vertical">
            <FormSelect
              name="department"
              options={department}
              showSearch={true}
              bordered={false}
              value={createJdFormik.values.department}
              borderBottom={`w-full h-[1px] ${
                getMessageError("department") ? "bg-red-500" : "bg-[#1495F3]"
              }`}
              styles={{ margin: 0, padding: 0 }}
              title={
                <p className="text-black text-[18px] font-[600]">
                  Bạn đang tuyển dụng lĩnh vực{" "}
                  <span className="text-[#ff0000]">*</span>
                </p>
              }
              placeholder="Chọn lĩnh vực bạn muốn tuyển dụng"
              onChange={(value) => {
                changeValueSelect("department", value);
                changeValueField(value);
              }}
            />
            <FormSelect
              name="companyName"
              options={optionsCompany}
              showSearch={true}
              bordered={false}
              value={createJdFormik.values.companyName}
              borderBottom={`w-full h-[1px] mt-[2px] ${
                getMessageError("companyName") ? "bg-red-500" : "bg-[#1495F3]"
              }`}
              styles={{ margin: 0, padding: 0 }}
              title={
                <p className="text-black text-[18px] font-[600]">
                  Bạn tuyển dụng cho doanh nghiệp nào{" "}
                  <span className="text-[#ff0000]">*</span>
                </p>
              }
              placeholder="Chọn tên doanh nghiệp"
              onChange={(_, option) => {
                changeValueSelect("company", option.id);
                changeValueSelect("companyName", option.value);
              }}
            />
            <FormInput
              title={
                <p className="text-black text-[18px] font-[600]">
                  Địa điểm làm việc <span className="text-[#ff0000]">*</span>
                </p>
              }
              name="location"
              value={createJdFormik.values.location}
              placeholder="Nhập địa điểm làm việc"
              bordered={false}
              classNameBottom={`w-full h-[1px] mt-[3px] ${
                getMessageError("location") ? "bg-red-500" : "bg-[#1495F3]"
              }`}
              onChange={createJdFormik.handleChange}
            />
          </Form>
          <Form className="min-w-max xl:w-full space-y-1" layout="vertical">
            <FormSelect
              name="major"
              showSearch={true}
              options={fieldRecruite}
              value={createJdFormik.values.major}
              bordered={false}
              borderBottom={`w-full h-[1px] ${
                getMessageError("major") ? "bg-red-500" : "bg-[#1495F3]"
              }`}
              styles={{ margin: 0, padding: 0 }}
              title={
                <p className="text-black text-[18px] font-[600]">
                  Vị trí cần tuyển dụng
                  <span className="text-[#ff0000]">*</span>
                </p>
              }
              placeholder="Chọn ngành nghề bạn muốn tuyển dụng"
              onChange={(value) => {
                changeValueSelect("major", value);
                changeValueSelect("jobName", value);
              }}
            />
            <FormInput
              name="positions"
              value={createJdFormik.values.positions}
              title={
                <p className="text-black text-[18px] font-[600]">
                  Số vị trí cần tuyển dụng{" "}
                  <span className="text-[#ff0000]">*</span>
                </p>
              }
              placeholder="Nhập số vị trí cần tuyển"
              bordered={false}
              classNameBottom={`w-full h-[1px] ${
                getMessageError("positions") ? "bg-red-500" : "bg-[#1495F3]"
              }`}
              onChange={(e) => {
                changeValueSelect(
                  "positions",
                  !isNaN(e.target.value) ? Number(e.target.value) : 0
                );
              }}
            />
            <Form.Item
              label={
                <p className="text-black text-[18px] font-[600]">
                  Thời gian tuyển dụng <span className="text-[#ff0000]">*</span>
                </p>
              }
            >
              <div className="flex flex-col">
                <ConfigProvider>
                  <RangePicker
                    style={{ width: "100%" }}
                    bordered={false}
                    onChange={(_, stday) => {
                      changeValueSelect("startReceiveCV", stday[0]);
                      changeValueSelect("endReceiveCV", stday[1]);
                    }}
                    className="mb-1 pb-0"
                    format={"YYYY-MM-DD"}
                    value={[
                      formatDate(createJdFormik.values.startReceiveCV),
                      formatDate(createJdFormik.values.endReceiveCV),
                    ]}
                    placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
                  />
                </ConfigProvider>
                <div
                  className={`w-full h-[1px] ${
                    getMessageError("startReceiveCV") &&
                    getMessageError("endReceiveCV")
                      ? "bg-red-500"
                      : "bg-[#1495F3]"
                  }`}
                />
              </div>
            </Form.Item>
          </Form>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-2 xl:space-x-2 mt-4">
          <div>
            <p className="text-black text-[18px] font-[600]">
              Đây là loại công việc <span className="text-[#ff0000]">*</span>
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-2">
              {listJobTypes &&
                listJobTypes.map((item, idx) => (
                  <BtCheck
                    key={"list-jd-" + idx + "-i"}
                    title={item}
                    value={createJdFormik.values.jobTypes.includes(item)}
                    onClick={() => {
                      changeCheckbox("jobTypes", item);
                    }}
                  />
                ))}
            </div>
            {getMessageError("jobTypes") && (
              <p className="text-red-500 text-[14px] mt-2 italic">
                * {getMessageError("jobTypes")}
              </p>
            )}
          </div>
          <div>
            <p className="text-black text-[18px] font-[600]">
              Bạn muốn tuyển dụng vị trí nào ?{" "}
              <span className="text-[#ff0000]">*</span>
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-2">
              {listLevel &&
                listLevel.map((item, idx) => (
                  <BtCheck
                    key={"level-l-" + idx + "-i"}
                    title={item}
                    value={createJdFormik.values.levels.includes(item)}
                    onClick={() => {
                      changeCheckbox("levels", item);
                    }}
                  />
                ))}
            </div>
            {getMessageError("levels") && (
              <p className="text-red-500 text-[14px] mt-2 italic">
                * {getMessageError("levels")}
              </p>
            )}
          </div>
        </div>
        <div>
          <p className="text-black text-[18px] font-[600]">
            Mức lương của vị trí này là ?{" "}
            <span className="text-[#ff0000]">*</span>
          </p>
          <Form layout="vertical">
            <CardSalary
              optionSalary={OPTION_SALARY}
              optionMonth={OPTION_MONTH}
              currentValue={createJdFormik.values.salaryDisplay}
              onChangeDisplay={(value) =>
                changeValueSelect("salaryDisplay", value)
              }
              valueMonth={createJdFormik.values.salaryCountFollow}
              onChangeM={(value) =>
                changeValueSelect("salaryCountFollow", value)
              }
              minSalary={createJdFormik.values.minSalary}
              maxSalary={createJdFormik.values.maxSalary}
              valueSalary={createJdFormik.values.salary}
              onChangeInput={createJdFormik.handleChange}
            />
          </Form>
          {getMessageError("minSalary") ? (
            <p className="text-red-500 text-[14px] mt-2 italic">
              * {getMessageError("minSalary")}
            </p>
          ) : (
            getMessageError("maxSalary") && (
              <p className="text-red-500 text-[14px] mt-2 italic">
                * {getMessageError("maxSalary")}
              </p>
            )
          )}
          {getMessageError("salary") && (
            <p className="text-red-500 text-[14px] mt-2 italic">
              * {getMessageError("salary")}
            </p>
          )}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 md:space-x-4">
          <div>
            <p className="text-black text-[18px] font-[600]">
              Thiết Lập Cập Nhật <span className="text-[#ff0000]">*</span>
            </p>
            <p className="text-black text-[14px] font-[600] mt-2">
              Gửi cập nhật hàng ngày về vị trí và ứng viên tới
            </p>
            <div>
              <FormSelect
                styles={{ margin: 0 }}
                mode="tags"
                value={createJdFormik.values.dailyUpdate}
                showSearch={false}
                onChange={(e) => changeValueSelect("dailyUpdate", e)}
              />
              {getMessageError("dailyUpdate") && (
                <p className="text-[14px] text-red-500 italic">
                  * {getMessageError("dailyUpdate")}
                </p>
              )}
              <Checkbox
                defaultChecked={
                  createJdFormik.values.dailyUpdate.length > 1 ? true : false
                }
              >
                Gửi email cập nhật mỗi khi có đơn ứng tuyển.
              </Checkbox>
            </div>
          </div>
          <div>
            <p className="text-black text-[18px] font-[600]">
              Ứng viên cần phải nộp sơ yếu lý lịch không ?{" "}
              <span className="text-[#ff0000]">*</span>
            </p>
            <div className="flex flex-col space-y-1">
              <Radio.Group
                name="cvRequire"
                value={createJdFormik.values.cvRequire}
                onChange={createJdFormik.handleChange}
              >
                {[
                  {
                    value: true,
                    label: "Có",
                    title: "Ứng viên yêu cầu phải có sơ yếu lý lịch.",
                  },
                  {
                    value: false,
                    label: "Không",
                    title: "Ứng viên không yêu cầu phải có sơ yếu lý lịch.",
                  },
                ].map((item, idx) => (
                  <BtRadio
                    key={"radio-i-" + idx + "-v"}
                    value={item.value}
                    label={item.label}
                    title={item.title}
                  />
                ))}
              </Radio.Group>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 md:space-x-4">
          <div>
            <p className="text-black text-[18px] font-[600]">
              Thiết lập Nhắn tin <span className="text-[#ff0000]">*</span>
            </p>
            <p className="text-black text-[14px] font-[600]">
              Bạn có muốn để những ứng viên bắt đầu cuộc trò chuyện không ?
            </p>
            <Radio.Group
              name="setupMessage"
              value={createJdFormik.values.setupMessage}
              onChange={createJdFormik.handleChange}
            >
              {[
                {
                  value: true,
                  label: "Có",
                },
                {
                  value: false,
                  label: "Không",
                },
              ].map((item, idx) => (
                <BtRadio
                  key={"r-" + idx + "-o"}
                  value={item.value}
                  label={item.label}
                />
              ))}
            </Radio.Group>
          </div>
          <div>
            <p className="text-black text-[18px] font-[600]">
              Vị Trí Này Có Thời Hạn Nộp Đơn Không?
              <span className="text-[#ff0000]">*</span>
            </p>
            <Radio.Group
              name="isDurationSubmit"
              value={createJdFormik.values.isDurationSubmit}
              onChange={createJdFormik.handleChange}
            >
              {[
                {
                  value: true,
                  label: "Có",
                },
                {
                  value: false,
                  label: "Không",
                },
              ].map((item, idx) => (
                <BtRadio
                  key={"bt-" + idx + "-0"}
                  value={item.value}
                  label={item.label}
                />
              ))}
            </Radio.Group>
            {createJdFormik.values.isDurationSubmit && (
              <div className="space-x-1 space-y-1 mt-2">
                <p className="text-[14px] text-black">Hạn hồ sơ:</p>
                <DatePicker
                  placeholder="YYYY-MM-DD"
                  value={formatDate(createJdFormik.values.submitEndDate)}
                  onChange={(_, stDay) => {
                    changeValueSelect("submitEndDate", stDay);
                  }}
                />
              </div>
            )}
            {getMessageError("submitEndDate") && (
              <p className="text-[14px] text-red-500 mt-2 italic">
                * {getMessageError("submitEndDate")}
              </p>
            )}
          </div>
        </div>
        <h1 className="text-[28px] text-black font-[600]">
          Thiết Lập Thêm Tiêu Chuẩn
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
          <div className="col-span-1">
            <p className="text-black text-[18px] font-[600]">
              Yêu Cầu: Trình độ văn hóa của ứng viên ?{" "}
              <span className="text-[#ff0000]">*</span>
            </p>
            <Radio.Group
              name="educationalLevel"
              value={createJdFormik.values.educationalLevel}
              onChange={createJdFormik.handleChange}
            >
              <BtField
                value={
                  createJdFormik.values.educationalLevel > 1
                    ? createJdFormik.values.educationalLevel
                    : 1
                }
                suffix={<p>/ 12</p>}
                valueInput={createJdFormik.values.educationalLevel}
                onChange={(e) => {
                  changeValueSelect(
                    "educationalLevel",
                    !isNaN(e.target.value) && Number(e.target.value) <= 12
                      ? Number(e.target.value)
                      : 1
                  );
                }}
              />
              <BtRadio value={0} label={"Không yêu cầu"} />
            </Radio.Group>
          </div>
          <div className="col-span-1">
            <p className="text-black text-[18px] font-[600]">
              Yêu Cầu: Số năm kinh nghiệm của ứng viên
            </p>
            <div className="flex justify-start items-start">
              <p className="mr-2">Số năm kinh nghiệm tối thiểu: </p>
              <FormInput
                name="experience"
                className="w-[80px]"
                placeholder={"năm"}
                value={createJdFormik.values.experience}
                onChange={(e) => {
                  changeValueSelect(
                    "experience",
                    !isNaN(e.target.value) ? Number(e.target.value) : 0
                  );
                }}
              />
            </div>
          </div>
        </div>

        <div className="w-full">
          <div>
            <p className="text-black text-[18px] font-[600]">
              Yêu Cầu: Trình độ học vấn tối thiểu của ứng viên ?{" "}
              <span className="text-[#ff0000]">*</span>
            </p>
            <Radio.Group
              name="education"
              value={createJdFormik.values.education}
              className="w-full"
              onChange={createJdFormik.handleChange}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
                {OPTION_EDUCATION.map((item, idx) => (
                  <BtRadio
                    key={"e-" + idx + "-tio"}
                    value={item.value}
                    label={item.label}
                  />
                ))}
              </div>
            </Radio.Group>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 md:space-x-4">
          <div>
            <p className="text-black text-[18px] font-[600] cursor-pointer">
              Yêu cầu: Ứng viên sử dụng được ngôn ngữ
            </p>
            <div>
              {listLanguage.map((item, index) => (
                <Stack
                  key={"lang-" + index + "-e"}
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                  sx={{ marginBottom: "1%" }}
                >
                  <p>Ngôn ngữ:</p>
                  <FormSelect
                    options={optionLangague}
                    value={item}
                    className="min-w-[200px]"
                    onChange={(value) => {
                      const temp = [...listLanguage];
                      temp[index] = value;
                      setListLanguage(temp);
                      changeValueSelect("languages", temp);
                    }}
                  />
                  <Bt
                    icon={<DeleteOutlined />}
                    className="rounded-[12px] border-solid border-[#D4D7DF] text-[#7F879E] bg-white"
                    onClick={() => handleDeleteLanguage(index)}
                  />
                </Stack>
              ))}
              <Bf
                title={"+ Thêm Ngôn ngữ"}
                onClick={handleAddLanguage}
                className="cursor-pointer"
                color="#1495F3"
              />
            </div>
          </div>
          <div>
            <p className="text-black text-[18px] font-[600]">
              Yêu Cầu: Ứng viên cần sở hữu chứng chỉ:
            </p>
            <div>
              {listCertificates.map((item, index) => (
                <Stack
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                  key={"cer-" + index + "-ates"}
                  sx={{ marginBottom: "1%" }}
                >
                  <p>Chứng chỉ:</p>
                  <FormInput
                    value={item}
                    className="min-w-[200px]"
                    onChange={(e) => {
                      const temp = [...listCertificates];
                      temp[index] = e.target.value;
                      setListCertificates(temp);
                      changeValueSelect("certificates", temp);
                    }}
                  />
                  <Bt
                    icon={<DeleteOutlined />}
                    className="rounded-[12px] border-solid border-[#D4D7DF] text-[#7F879E] bg-white"
                    onClick={() => handleDeleteCertificate(index)}
                  />
                </Stack>
              ))}
              <Bf
                title={<>+ Thêm Chứng chỉ</>}
                className="cursor-pointer"
                onClick={handleAddCertificate}
                color="#1495F3"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <h4>
            Mô tả công việc <span className="text-[#ff0000]">*</span>
          </h4>
          <p className="font-[600] italic">
            Mô tả trách nhiệm của công việc này, kinh nghiệm làm việc, kỹ năng
            hoặc trình độ học vấn cần thiết.
          </p>
          <CKEditors
            name="description"
            value={createJdFormik.values.description}
            onChangeValue={createJdFormik.handleChange}
            error={getMessageError("description")}
          />
        </div>
        <div className="flex flex-col w-full">
          <h4>
            Yêu cầu công việc <span className="text-[#ff0000]">*</span>
          </h4>
          <p className="font-[600] italic">
            Mô tả yêu cầu dành cho vị trí cần tuyển
          </p>
          <CKEditors
            name="requirement"
            value={createJdFormik.values.requirement}
            onChangeValue={createJdFormik.handleChange}
            error={getMessageError("requirement")}
          />
        </div>

        <div className="flex flex-col w-full">
          <h4>Quyền lợi</h4>
          <p className="font-[600] italic">
            Mô tả các quyền lợi mà ứng viên được hưởng nếu ứng tuyển thành công
            vị trí
          </p>
          <CKEditors
            name="benefits"
            value={createJdFormik.values.benefits}
            onChangeValue={createJdFormik.handleChange}
          />
        </div>
        <div>
          <h4 className="leading-1">
            Câu hỏi: Đây là câu hỏi do nhà tuyển dụng viết ra. Bạn có thể báo
            cáo các câu hỏi không phù hợp thông qua liên kết "Báo cáo công việc"
            ở cuối phần mô tả công việc.
          </h4>
          <p className="font-[600] italic">
            Viết câu hỏi của riêng bạn để hỏi ứng viên. Không đặt câu hỏi mang
            tính phân biệt đối xử, bất hợp pháp hoặc vi phạm các quy tắc của
            LDO. Các câu hỏi được nhận biết bởi các gạch đầu dòng.
          </p>
          <CKEditors
            name="questions"
            value={createJdFormik.values.questions}
            onChangeValue={createJdFormik.handleChange}
          />
        </div>
        <div className="w-full flex justify-between">
          <Bt
            title={<>Hủy và xóa {<DeleteOutlined />}</>}
            className="bg-[#E60019] rounded-[10px]"
            onClick={() => history.goBack()}
          />

          <Bt
            title={<>Lưu và tiếp tục {">"}</>}
            className="bg-[#1495F3] rounded-[10px]"
            onClick={createJdFormik.handleSubmit}
          />
        </div>
      </div>
      <SuccessPost open={confirm} onCancel={onClickCancel} />
    </div>
  );
};

export default CreateJd;
