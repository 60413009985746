import { cx } from "../../lib/cx";
import { useTheme } from "../../lib/theme/useTheme";
import { pathAdmin, routes } from "../../screens/PathRouting";
import { useHistory, useLocation } from "react-router-dom";

export function Footer() {
  const history = useHistory();
  const location = useLocation();
  const { theme } = useTheme();

  const handleMenuClick = () => {
    // bắt sự kiện bấm quay trở lại màn hình chính
    const data = JSON.parse(localStorage.getItem("loginStatus"));
    return data && data.role === "Admin"
      ? history.push(pathAdmin.adminManageJD.path)
      : data && data.role === "Recruiter"
      ? history.push(routes.recruiterDashboard.path)
      : data && data.role === "Candidate"
      ? history.push(routes.personalProfile.path)
      : history.push(routes.home.path);
  };
  const isAdmin = () => {
    const pathname = location.pathname.split("/");
    return pathname[1] === "admin";
  };

  const listFooter = [
    {
      name: "Việc làm",
      children: [
        {
          name: "Việc làm hot",
          href: "",
        },
        {
          name: "Việc làm mới nhất",
          href: "",
        },
        {
          name: "Việc làm theo chuyên môn",
          href: "",
        },
      ],
    },
    {
      name: "Nhà tuyển dụng",
      children: [
        {
          name: "Nhóm ngành hot",
          href: "",
        },
        {
          name: "Công ty thuộc VNR 500",
          href: "",
          url: "https://vnr500.com.vn/",
        },
        {
          name: "Công ty có môi trường tốt",
          href: "",
        },
      ],
    },
    {
      name: "CV",
      children: [
        {
          name: "Tạo CV cơ bản",
          href: routes.templateCv.path,
        },
        {
          name: "Tạo CV theo chuyên ngành",
          href: "",
        },
        {
          name: "Tạo CV & tư vấn",
          href: "",
        },
      ],
    },
  ];

  return (
    <div
      className={cx(
        "min-h-[23vh] py-10 pl-10",
        isAdmin() && "hidden",
        theme?.footer?.background,
        theme?.footer?.border
      )}
    >
      <div className="mx-auto h-auto lg:max-w-[1240px] select-none">
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          <div className="col-span-1">
            <div className="w-[210px] h-[32px]" onClick={handleMenuClick}>
              <img
                src={"/asserts/logo.png"}
                className="max-w-full max-h-full object-cover"
                alt=""
              />
            </div>
            <p className="mt-3 text-[#7F879E] cursor-default">
              tuyendung@ldo.vn
              <br />
              (+84) 868.587.868
            </p>
            <p className="mt-2 text-[#7F879E] cursor-default">
              Tầng 4, Liễu Giai Tower
              <br />
              26 Phố Liễu Giai, Cống Vị, Ba Đình, Hà Nội
            </p>
            <div className="flex space-x-3 mt-[3%]">
              <a href="https://www.facebook.com/groups/nganhangnhanlucvietnam">
                <img
                  src="/Contact/Facebook.svg"
                  className="cursor-pointer"
                  alt=""
                />
              </a>
              <a href="https://www.linkedin.com/company/ng%C3%A2n-h%C3%A0ng-nh%C3%A2n-l%E1%BB%B1c-manpower-bank/">
                <img
                  src="/Contact/LinkedIn.svg"
                  className="cursor-pointer"
                  alt=""
                />
              </a>
              <a href="https://www.youtube.com/@nhanlucnganhang-manpowerba4453">
                <img
                  src="/Contact/Youtube.svg"
                  className="cursor-pointer"
                  alt=""
                />
              </a>
            </div>
          </div>
          {listFooter.map((item, index) => (
            <div key={item.name + index} className="col-span-1">
              <p className="text-white text-[16px] uppercase font-[600]">
                {item.name}
              </p>
              <div className="mt-2">
                {item.children.map((c, index) => {
                  if (c?.url) {
                    return (
                      <a
                        key={"footer" + c.name + index}
                        href={c.url}
                        target="__blank"
                        className="py-1 text-[#7F879E] hover:text-white cursor-pointer"
                      >
                        {c.name}
                      </a>
                    );
                  }
                  return (
                    <p
                      key={"footer" + c.name + index}
                      className="py-1 text-[#7F879E] hover:text-white cursor-pointer"
                      onClick={() => c.href && history.push(c.href)}
                    >
                      {c.name}
                    </p>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
