import React, { useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { dataURLtoFile } from "../../../utils";

function CroppedAvatarModal({ image, actived, changeActived, onOkClick }) {
  const cropperRef = useRef(null);

  const handleOkClick = () => {
    const cropper = cropperRef.current?.cropper;
    const croppedImageData = cropper.getCroppedCanvas().toDataURL("image/jpeg");
    if (onOkClick) {
      const file = dataURLtoFile(
        croppedImageData,
        `image-cv-${Date.now()}.jpeg`
      );
      onOkClick(file);
    }
  };

  return (
    <div
      className={`overflow-x-hidden py-[120px] fixed inset-0 z-9999 h-screen outline-none focus:outline-none justify-center items-center c-modal bg-black bg-opacity-40 ${
        actived ? "block" : "hidden"
      }`}
    >
      <div className="relative flex justify-center items-center mx-auto w-full px-[10px] md:px-0 h-[70vh] select-none">
        <div className="w-[350px] min-h-[150px] bg-white p-[10px] rounded-[12px]">
          <div>
            <p className="text-24px text-center font-[600] uppercase">
              Chỉnh sửa
            </p>
            <p className="mt-2">Chọn vào vùng ảnh để chọn avatar</p>
            <p className="italic">Kéo để thay đổi kích thước</p>
          </div>
          <div className="w-full min-h-[100px] py-4">
            {image && (
              <Cropper
                ref={cropperRef}
                src={image}
                style={{ height: "240px", width: "100%" }}
                aspectRatio={3 / 4}
                viewMode={1}
                dragMode="none"
                guides={true}
              />
            )}
          </div>
          <div className="flex justify-end items-center space-x-2">
            <div
              className="px-4 py-1 bg-gray text-[14px] rounded-[14px] text-white cursor-pointer"
              onClick={changeActived}
            >
              Cancel
            </div>
            <div
              className="px-4 py-1 bg-blue-500 text-[14px] rounded-[14px] text-white cursor-pointer"
              onClick={handleOkClick}
            >
              OK
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CroppedAvatarModal;
