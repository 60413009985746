import { CameraOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import InputAdmin from "../Input";
import { useFormik } from "formik";
import { createGroupSchema } from "../../../utils/validations";
import AdminService from "../../../services/AdminService";

function CreateGroupModal(props) {
  const { active, onChangeActive } = props;
  const [avatar, setAvatar] = useState("");

  const createGroupFormik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: createGroupSchema,
    onSubmit: (values) => {
      createNewGroup(values);
    },
  });

  const createNewGroup = async (values) => {
    const data = {
      ...values,
      createdBy: "64ba5ca769833a3ae8b75bb8",
      currentRole: "SUPER_ADMIN",
    };
    AdminService.createNewGroup(data).then(() => {
      onChangeActive();
    });
  };

  const uploadAvatar = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setAvatar(e.target.files[0]);
    }
  };
  return (
    <div
      className={`overflow-x-auto md:overflow-hidden fixed inset-0 z-9999 h-screen outline-none focus:outline-none justify-center items-center c-modal bg-black bg-opacity-20 ${
        active ? "block" : "hidden"
      }`}
    >
      <div className="relative flex justify-center items-center mx-auto w-full px-[10px] md:px-0 h-[100vh] select-none">
        <div className="border-0  relative flex flex-col w-full bg-white outline-none focus:outline-none sm:w-[420px] rounded-[12px]">
          <form
            className="relative flex-auto w-full sm:w-[420px]"
            encType="multipart/form-data"
          >
            <div className="w-full h-[120px] rounded-tl-[12px] rounded-tr-[12px] bg-[#1495F3] relative cursor-pointer">
              <input
                type="file"
                id="avatarGroup"
                name="avatarGroup"
                onChange={uploadAvatar}
                accept="image/*"
                hidden
              />
              <div
                className="w-[120px] h-[120px] rounded-[50%] border-none  bg-[#F5F7FF] absolute left-10 -bottom-[50%]"
                onClick={() => document.getElementById("avatarGroup")?.click()}
              >
                <img
                  src={avatar ? URL.createObjectURL(avatar) : ""}
                  alt=""
                  className="w-full h-full object-cover border-none rounded-[50%]"
                />
              </div>

              <div
                className="w-[120px] h-[120px] border-none rounded-[50%] opacity-60 absolute left-10 -bottom-[50%] flex justify-center items-center"
                onClick={() => document.getElementById("avatarGroup")?.click()}
              >
                <div className="w-[48px] h-[48px] rounded-[50%] bg-[#B5C1FF] flex justify-center items-center">
                  <CameraOutlined className="text-[24px]" />
                </div>
              </div>
            </div>
            <div className="w-full bg-white rounded-bl-[12px] rounded-br-[12px] p-[20px]">
              <div className="w-full">
                <div className="ml-[40%]">
                  <InputAdmin
                    name="name"
                    label="Tên của nhóm"
                    placeholder="Nhóm kinh doanh"
                    onChange={createGroupFormik.handleChange}
                  />
                </div>
              </div>
              <div className="w-full flex justify-end items-center mt-[20px]">
                <div
                  className="px-9 py-2 border rounded-[12px] text-[12px] cursor-pointer mr-2"
                  onClick={onChangeActive}
                >
                  Hủy
                </div>
                <div
                  className="px-9 py-2 bg-[#1495F3] text-white rounded-[12px] text-[12px] cursor-pointer"
                  onClick={createGroupFormik.handleSubmit}
                >
                  Lưu
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateGroupModal;
