import React from "react";
import { cx } from "../../lib/cx";
import { EyeFilled } from "@ant-design/icons";
import { getLastPrexPhone, getLocation } from "../../utils";
import { pathAdmin } from "../../screens/PathRouting";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function RecruiterList({ data, search, limit }) {
  const history = useHistory();
  return (
    <table className="w-full min-w-[2175px] max-w-[50000px]">
      <thead>
        <tr>
          {[
            "STT",
            "Tên khách hàng",
            "Quảng cáo",
            "Hợp đồng",
            "Tiền cọc",
            "Doanh thu",
            "Địa chỉ",
            "Số điện thoại",
            "Công việc đăng tuyển",
            "Số ứng viên",
            "",
          ].map((item, index) => (
            <th
              key={item + index + "title"}
              className="px-4 py-1 text-[14px] uppercase text-center bg-[#B7E1FF] rounded-[12px] border-[1px] md:border-[4px] border-[#F4F9FF]"
            >
              {item}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data &&
          data.length > 0 &&
          data.map((recruiter, idx) => (
            <tr
              key={"recruiter" + idx + "tab"}
              className="bg-white hover:bg-gray-1 cursor-pointer"
              onClick={() =>
                history.push(
                  pathAdmin.adminRecruiterProfile.parentPath +
                    `/${recruiter.id}`
                )
              }
            >
              {[
                (Number(search?.page) || 1) * limit + idx - limit + 1,
                recruiter.enterprise.toUpperCase(),
                "Không",
                "Không",
                0,
                0,
                getLocation(recruiter.enterpriseAddress[0]) || "Chưa cập nhật",
                getLastPrexPhone(recruiter.phoneNumber),
                0,
                0,
              ].map((item, index) => (
                <td
                  key={"item" + idx + index + "tab"}
                  className={cx(
                    "py-2 px-4 rounded-[12px] border-[1px] md:border-[6px] border-[#F4F9FF] text-center",
                    index === 0 && "!w-[30px]",
                    index === 1 && "!text-left !w-[25%]",
                    index === 4 && "!text-end",
                    index === 5 && "!text-end w-[220px]",
                    index === 6 && "!w-[150px]"
                  )}
                >
                  {item}
                </td>
              ))}
              <td
                className={cx(
                  "py-2 px-4 rounded-[12px] border-[1px] md:border-[6px] border-[#F4F9FF] w-[100px]"
                )}
              >
                <div
                  className={cx(
                    "text-[12px] bg-blue-400 rounded-[12px] text-white text-center"
                  )}
                >
                  <EyeFilled /> Chi tiết
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export default RecruiterList;
