import { useEffect } from "react";
import { loadStateFromLocalStorage, saveStateToLocalStorage } from "./local-storage";
import { useDispatch, useSelector } from "react-redux";
import { store } from "./store";
import { initialResumeState, setResume } from "./resumeSlice";
import { initialSettings, setSettings } from "./settingsSlice";
import { deepMerge } from "../deep-merge";

export const useAppDispatch=useDispatch;
export const useAppSelector = useSelector;

/**
 * Hook to save store to local storage on store change
 */
export const useSaveStateToLocalStorageOnChange = () => {
  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      saveStateToLocalStorage(store.getState());
    });
    return unsubscribe;
  }, []);
};

export const useSetInitialStore = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const state = loadStateFromLocalStorage();
    if (!state) return;
    if (state.resume) {
      const mergedResumeState = deepMerge(
        initialResumeState,
        state.resume
      );
      dispatch(setResume(mergedResumeState));
    }
    if (state.settings) {
      const mergedSettingsState = deepMerge(
        initialSettings,
        state.settings
      );
      dispatch(setSettings(mergedSettingsState));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};