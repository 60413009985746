import React, { useState } from "react";
import ScrollTop from "../../components/ScrollTop";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { paymentRoutes } from "../PathRouting";
import { DATA_PAYMENT_FAKE } from "../../utils/constants";
import { formatVND } from "../../utils";
import { Checkbox } from "antd";
import Input from "../../components/Input";
import { useFormik } from "formik";
import queryString from "query-string";

function PaymentDetail() {
  const history = useHistory();
  const location = useLocation();
  const [cardType, setCardType] = useState("qr");
  const [amount, setAmount] = useState(0);
  const search = queryString.parse(location.search);
  const combo = DATA_PAYMENT_FAKE[search?.combo ? search.combo - 1 : 0];

  const visaFormFormik = useFormik({
    initialValues: {
      card_number: "",
      date_exprid: "",
      cvv: "",
    },
    validationSchema: "",
    onSubmit: (values) => {
      console.log("--------value", values);
    },
  });

  return (
    <div className="mx-auto min-h-[80vh] lg:max-w-[1175px] bg-[#F2F7FF] select-none mb-[120px]">
      <ScrollTop />
      <div className="px-4">
        <div className="my-[80px] text-[20px]">
          <p
            className="text-gray cursor-pointer hover:underline float-left inline"
            onClick={() =>
              history.push(paymentRoutes.paymentComboRecuiter.path)
            }
          >
            Các gói tuyển dụng
          </p>
          <span className="text-gray mx-2 float-left inline">/</span>
          <p className="uppercase text-black font-[600]">{combo?.name}</p>
        </div>

        <h4 className="">Đăng kí gói {combo?.name}</h4>
        <div className="w-full mt-4">
          <table className="w-full">
            <tr>
              <th className="w-[50%] py-1 text-[14px] uppercase text-center bg-[#B7E1FF] rounded-[12px] border-[1px] md:border-[4px] border-[#F4F9FF]">
                DỊCH VỤ
              </th>
              <th className="w-[19%] py-1 text-[14px] uppercase text-center bg-[#B7E1FF] rounded-[12px] border-[1px] md:border-[4px] border-[#F4F9FF]">
                THỜI GIAN
              </th>
              <th className="w-[9%] py-1 text-[14px] uppercase text-center bg-[#B7E1FF] rounded-[12px] border-[1px] md:border-[4px] border-[#F4F9FF]">
                SL
              </th>
              <th className="w-[18%] py-1 text-[14px] uppercase text-center bg-[#B7E1FF] rounded-[12px]">
                ĐƠN GIÁ GỐC
              </th>
              <th></th>
            </tr>
            {combo &&
              combo?.detail &&
              combo.detail.map((item) => (
                <>
                  <tr>
                    {item?.number ? (
                      <>
                        {item.number === 1 && (
                          <td
                            className="py-2 px-4 font-[700] bg-white rounded-[12px] md:rounded-[29px] border-[1px] md:border-[6px] border-[#F4F9FF]"
                            rowSpan="4"
                          >
                            {item.title}
                          </td>
                        )}
                      </>
                    ) : (
                      <td className="py-1 px-4 font-[700] bg-white rounded-[12px] md:rounded-[29px] border-[1px] md:border-[6px] border-[#F4F9FF]">
                        {item.title}
                      </td>
                    )}
                    <td className="text-center py-1 px-2 bg-white rounded-[12px] md:rounded-[29px] border-[1px] md:border-[6px] border-[#F4F9FF]">
                      {item.date}
                    </td>
                    <td className="text-center py-1 px-2 bg-white rounded-[12px] md:rounded-[29px] border-[1px] md:border-[6px] border-[#F4F9FF]">
                      {item.amount}
                    </td>
                    <td className="text-center py-1 px-2 bg-white rounded-[12px] md:rounded-[29px] border-[1px] md:border-[6px] border-[#F4F9FF]">
                      {formatVND(item.price)}
                    </td>
                    <td>
                      <Checkbox
                        onChange={(e) =>
                          setAmount(
                            e.target.checked
                              ? amount + item.price
                              : amount - item.price
                          )
                        }
                      />
                    </td>
                  </tr>
                </>
              ))}

            <tr>
              <th
                className="py-1 pr-4 text-[14px] uppercase text-right bg-[#B7E1FF] rounded-[12px] border-[1px] md:border-[4px] border-[#F4F9FF]"
                colSpan={3}
              >
                Tổng (đã bao gồm 10% VAT)
              </th>
              <th className="w-[19%] py-1 text-[14px] uppercase text-center bg-[#B7E1FF] rounded-[12px] border-[1px] md:border-[4px] border-[#F4F9FF]">
                {formatVND(amount)}
              </th>
            </tr>
          </table>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
          <div className="col-span-1">
            <p className="text-gray text-[20px] font-bold mb-3">
              Mô tả dịch vụ:
            </p>
            <ul className="pl-[20px]">
              {combo &&
                combo.description.map((item) => (
                  <>
                    <li className="list-disc text-justify">{item}</li>
                  </>
                ))}
            </ul>
          </div>
          <div className="col-span-1">
            <p className="text-gray text-[20px] font-bold mb-3">
              Chăm sóc khách hàng
            </p>
            <div className="w-[210px] h-[34px]">
              <img
                src="/asserts/logo.png"
                alt="logo"
                className="max-w-full max-h-full object-cover"
              />
            </div>
            <ul className="pl-[20px]">
              <li className="list-disc text-justify">
                Address: Toà nhà Liễu Giai, số 26 phố Liễu Giai, Quận Ba Đình,
                Thành phố Hà Nội
              </li>
              <li className="list-disc text-justify">Hotline: 0868 587 868</li>
              <li className="list-disc text-justify">
                Website: https://ldo.vn
              </li>
            </ul>
          </div>
        </div>

        <div className="my-[40px]">
          <div className="w-full p-[20px] bg-white rounded-[20px]">
            <p className="text-gray text-[18px] font-[600]">
              Chọn phương thức thanh toán
            </p>
            <div className="flex space-x-4 my-3">
              {[
                { title: "QR Code", type: "qr" },
                { title: "Thẻ Visa", type: "visa" },
              ].map((item) => (
                <>
                  <div
                    className="cursor-pointer"
                    onClick={() => setCardType(item.type)}
                  >
                    <p
                      className={`${
                        item.type === cardType ? "text-black" : "text-gray"
                      } font-bold`}
                      onClick={() => setCardType(item.type)}
                    >
                      {item.title}
                    </p>
                    {item.type === cardType && (
                      <div className="border-[1px] border-[#1495F3]"></div>
                    )}
                  </div>
                </>
              ))}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {cardType === "visa" ? (
                <>
                  <div className="col-span-1">
                    <div className="flex justify-center items-center">
                      <img
                        src="/images/card-visa.png"
                        alt=""
                        className="object-cover"
                      />
                    </div>
                  </div>
                  <div className="col-span-1">
                    <form>
                      <Input
                        label="Số thẻ"
                        name="card_number"
                        valueInput={visaFormFormik.values.card_number}
                        placeholder="NGUYEN VAN A"
                      />
                      <div className="my-3">
                        <Input
                          label="Ngày hết hạn"
                          name="date_exprid"
                          placeholder="MM/YY"
                          valueInput={visaFormFormik.values.date_exprid}
                        />
                      </div>
                      <Input
                        label="CVV"
                        name="cvv"
                        placeholder="cvv"
                        valueInput={visaFormFormik.values.cvv}
                      />
                    </form>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-span-1">
                    <div className="flex justify-center items-center">
                      <div className="p-[20px]">
                        <p className="text-center uppercase font-bold text-blue-600 my-3">
                          Quét QR Code
                        </p>
                        <div className="border">
                          <div className="w-[200px] h-[200px]">
                            <img
                              src="/images/qr-pay.jpg"
                              alt=""
                              className="w-full h-full object-cover"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <p>Quét QR Code để tiếp tục</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-end items-center mt-[40px]">
          <div
            className="bg-[#1495F3] text-white px-[120px] py-2 rounded-[22px]"
            onClick={() =>
              cardType === "visa" ? visaFormFormik.handleSubmit() : null
            }
          >
            Tiếp theo
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentDetail;
