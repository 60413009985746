import { LoadingOutlined } from "@ant-design/icons";
import React, { useRef, useState } from "react";
import { cx } from "../../../lib/cx";
import "cropperjs/dist/cropper.css";
import CroppedAvatarModal from "../Modals/CroppedAvatarModal";
import UserService from "../../../services/UserService";
import { getImage } from "../../../services/PORT";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { changeAvatar, selectAvatar } from "../../redux/slice/builder";
import {
  changeValidateForm,
  selectValidateForm,
} from "../../redux/slice/setting";

function AvatarDefaultCv({ shape, className, styleCss }) {
  const avatar = useAppSelector(selectAvatar);
  const validateForm = useAppSelector(selectValidateForm);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [toggleModal, setToggleModal] = useState(false);

  const inputRef = useRef(null);

  const dispatch = useAppDispatch();

  const uploadAvatar = async (file) => {
    inputRef.current.value = null;
    setToggleModal(!toggleModal);
    dispatch(changeValidateForm({ field: "avatar", value: "" }));
    if (file) {
      setLoading(true);
      const formData = new FormData();
      formData.append("files", file);
      UserService.uploadAvatarCv(formData).then((res) => {
        const data = res.data.data;
        UserService.getImage(getImage, { filename: data })
          .then((image) => {
            const newAvatar = {
              original: data[0],
              url: image.data.data[0].fileUrl,
            };
            dispatch(changeAvatar(newAvatar));
            setLoading(false);
          })
          .catch((err1) => {
            console.log(err1);
          });
      });
    }
  };

  const uploadAvatarToCopper = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      setToggleModal(true);
    }
  };

  const handleChangeActive = () => {
    inputRef.current.value = null;
    setImage(null);
    setToggleModal(!toggleModal);
  };

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        id="imageAvatar"
        name="imageAvatar"
        onChange={uploadAvatarToCopper}
        accept="image/*"
        hidden
      />
      <div
        className={cx(
          `w-full h-full bg-white cursor-pointer relative`,
          shape || "rounded-[14px]",
          validateForm.avatar && "border !border-red-500",
          className
        )}
        onClick={() => document.getElementById("imageAvatar").click()}
        style={styleCss}
      >
        {avatar?.url ? (
          <img
            src={avatar.url}
            alt=""
            className={cx(
              `w-full h-full object-cover`,
              shape || "rounded-[14px]"
            )}
          />
        ) : (
          <div
            className={cx(
              `absolute top-0 left-0 w-full h-full flex justify-center items-center`,
              shape || "rounded-[14px]"
            )}
          >
            <p className={cx("text-black")}>Chọn Avatar</p>
          </div>
        )}
        {loading && (
          <div
            className={cx(
              `absolute top-0 left-0 w-full h-full bg-black opacity-20 flex justify-center items-center`,
              shape || "rounded-[14px]"
            )}
          >
            <LoadingOutlined style={{ color: "white", fontSize: "40px" }} />
          </div>
        )}
      </div>
      <CroppedAvatarModal
        image={image}
        actived={toggleModal}
        changeActived={handleChangeActive}
        onOkClick={uploadAvatar}
      />
    </>
  );
}

export default AvatarDefaultCv;
