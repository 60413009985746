import React from "react";
import { cx } from "../../lib/cx";
import { useAppDispatch, useAppSelector } from "../../cv/redux/hooks";
import { changeActions, selectActions } from "../../cv/redux/slice/setting";
import Input from "../Input";
import { login } from "../../services/PORT";
import queryString from "query-string";
import { loginSchema } from "../../utils/validations";
import { useFormik } from "formik";
import { useLocation, useHistory } from "react-router-dom";
import UserService from "../../services/UserService";
import { useLocalStorage } from "../../lib/useLocalStorage";
import { routes } from "../../screens/PathRouting";
import { useTheme } from "../../lib/theme/useTheme";
import { notification } from "antd";
import { useConfigCvFile } from "../../cv/builder/hook";
import { CloseOutlined } from "@ant-design/icons";

function LoginCandidateModal() {
  const { theme } = useTheme();
  const { handleSaveCv } = useConfigCvFile();
  const { openLogin } = useAppSelector(selectActions);
  const [, setToken] = useLocalStorage("token", null);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  notification.config({ top: 80, placement: "topRight" });

  const loginFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
      role: "Candidate",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      const dataLogin = {
        ...values,
        role: "Candidate",
      };
      UserService.checkUser(dataLogin, login)
        .then((res) => {
          const data = res.data.data;

          localStorage.setItem("User", JSON.stringify(data));
          localStorage.setItem(
            "loginStatus",
            JSON.stringify({ status: "success", role: dataLogin.role })
          );
          setToken(data.authToken[0].accessToken);
          handleSaveCv();
        })
        .catch((err) => {
          console.log(err);
          openNotification();
        });
    },
  });

  const openNotification = () => {
    return notification.error({
      message: `Thông báo`,
      description: "Sai tên đăng nhập hoặc mật khẩu",
      placement: "topRight",
      style: { top: 74, padding: "10px", width: "300px" },
    });
  };

  const handleNewAccount = () => {
    const search = queryString.parse(location.search);
    let url = routes.signupCandidate.path;
    if (search?.redirect) {
      url = `${url}?redirect=${search.redirect}`;
    }
    history.push(url);
  };

  return (
    <div
      className={cx(
        `overflow-x-hidden overflow-y-auto fixed inset-0 z-9999 h-screen outline-none focus:outline-none justify-center items-center c-modal bg-black bg-opacity-40`,
        openLogin ? "block" : "hidden"
      )}
    >
      <div className="w-full h-[100vh] flex justify-center items-center px-4">
        <div className="w-full sm:w-[450px] bg-white h-auto shadow-md rounded-[4px] p-[10px] md:px-[20px]">
          <div
            className="w-full flex justify-end items-center cursor-pointer"
            onClick={() => dispatch(changeActions({ field: "openLogin" }))}
          >
            <CloseOutlined />
          </div>
          <div>
            <h4 className="text-[26px] text-black font-[600]">
              Đăng nhập LDO 👋
            </h4>
            <p className="my-2">Tài khoản dành cho ứng viên</p>
          </div>
          <form onSubmit={loginFormik.handleSubmit}>
            <div className="my-2">
              <Input
                name="email"
                placeholder="Nhập email của bạn"
                label="Địa chỉ email"
                onChangeInput={loginFormik.handleChange}
                className="!mt-1"
                error={
                  loginFormik.errors.email && loginFormik.touched.email
                    ? loginFormik.errors.email
                    : ""
                }
              />
            </div>
            <div className="my-4">
              <Input
                name="password"
                type="password"
                placeholder="Nhập mật khẩu"
                label="Mật khẩu"
                className="!mt-1"
                onChangeInput={loginFormik.handleChange}
                error={
                  loginFormik.errors.password && loginFormik.touched.password
                    ? loginFormik.errors.password
                    : ""
                }
              />
            </div>

            <p
              className="text-blue-400 hover:underline hover:text-blue-500 cursor-pointer my-4"
              onClick={() =>
                history.push(routes.forgotPassword.path, { role: "Candidate" })
              }
            >
              Quên mật khẩu?
            </p>
            <button
              type="submit"
              className={cx(
                "w-full text-center text-white rounded-[10px] py-2 cursor-pointer hover:bg-blue-500",
                theme?.button?.background
              )}
              onClick={loginFormik.handleSubmit}
            >
              Đăng nhập ứng viên
            </button>
          </form>

          <div className="flex justify-center items-center mt-3">
            <p>Chưa đăng ký?</p>
            <p
              className="text-blue-500 hover:underline cursor-pointer ml-1"
              onClick={handleNewAccount}
            >
              Tạo tài khoản ngay
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginCandidateModal;
