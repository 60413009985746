import React, { useState } from "react";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { CardCV3 } from "../CardItem/CardCV";
import { getImage } from "../../services/PORT";
import UserService from "../../services/UserService";
import { useHistory } from "react-router-dom";
import { routes } from "../../screens/PathRouting";

export const SidebarRCJD = (props) => {
  const { data, jdApply } = props;
  const history = useHistory();
  const handleClickBtn = () => {
    history.push(routes.recruiterJDApply.parentPath + "/" + jdApply);
  };
  return (
    <div className="w-full md:w-[300px] space-y-3 md:ml-4 mt-4 md:mt-0">
      <div className="flex flex-col items-center bg-white rounded-[12px] p-[10px]">
        <h3 className="text-black text-[16px]">Các CV đã nhận được</h3>
        <div
          className="w-full bg-blue-500 rounded-[12px] text-white px-3 py-2 flex justify-center items-center mt-2 cursor-pointer"
          onClick={handleClickBtn}
        >
          <EditOutlined />{" "}
          <span className="text-[12px] ml-2">Xem tất cả CV ứng viên</span>
        </div>
      </div>
      <CardPDF data={data} />
    </div>
  );
};

const CardPDF = (props) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleEyeIconClick = (item) => {
    UserService.getImage(getImage, { filename: [item.cvImage] })
      .then((res1) => {
        setSelectedItem({ image: res1.data.data[0].fileUrl });
        setPopupVisible(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClosePopup = () => {
    setSelectedItem(null);
    setPopupVisible(false);
  };

  return (
    <div>
      {isPopupVisible && selectedItem && (
        <CardCV3
          data={{
            image: selectedItem.image,
          }}
          onChangeImage={handleClosePopup}
        />
      )}
      <div className="rounded-[12px] w-full">
        <div className="grid grid-cols-1 gap-3 sm:flex sm:flex-col">
          {props.data.map((item, index) => {
            return (
              <div
                key={index}
                className="flex items-center p-2 bg-white mb-1 rounded-[12px] cursor-pointer"
                onClick={() => handleEyeIconClick(item)}
              >
                <img
                  src="/asserts/pdfSVG.svg"
                  className="w-12 sm:w-16"
                  alt=""
                />
                <div className="w-[50px] flex flex-col flex-grow">
                  <h2 className="text-lg font-semibold line-clamp-1">
                    {item.cvName}
                  </h2>
                  <p className="text-sm text-gray-500">{item.applyAt}</p>
                </div>
                <div className="flex items-center">
                  <EyeOutlined className="cursor-pointer ml-auto" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
