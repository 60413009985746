import React, { useEffect, useState } from "react";
import HeaderSeo from "../components/Header/HeaderSeo";
import { useTheme } from "../lib/theme/useTheme";
import { cx } from "../lib/cx";
import ScrollTop from "../components/ScrollTop";
import { Search } from "@mui/icons-material";
import UserService from "../services/UserService";
import queryString from "query-string";
import { getImageUrl } from "../utils";
import CardTemplate from "./components/Card/CardTemplate";
import CvDetailModal from "../admin/components/Modal/CvDetailModal";
import { useLocation, useHistory } from "react-router-dom";
import { setNameTemplateCv } from "./redux/local-storage";
import { routes } from "../screens/PathRouting";

function CvTemplate() {
  const { theme } = useTheme();
  const [listTemplate, setListTemplate] = useState();
  const [toggleDetail, setToggleDetail] = useState();
  const [inputSearch, setInputSearch] = useState();

  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(location.search);

  const user = JSON.parse(localStorage.getItem("User"));

  useEffect(() => {
    if (!listTemplate) {
      getAllTemplate(params);
      setInputSearch({ search: params?.search });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listTemplate]);

  const getAllTemplate = async (options = {}) => {
    const params = queryString.stringify({
      ...options,
      offset: 0,
      limit: 50,
    });
    const response = await UserService.getAllCvManage(params);
    const data = response.data.data.items;
    const listCv = data.map((item) => item.image);
    const listPdf = await UserService.getImageByName(listCv);
    const result = data.map((item) => ({
      ...item,
      original_img: item.image,
      image: getImageUrl(listPdf.data.data, item.image),
    }));

    setListTemplate(result);
  };

  const handleChangeSearch = (options = {}) => {
    const data = { ...params, ...options };
    history.replace(location.pathname + `?${queryString.stringify(data)}`);
    getAllTemplate(data);
  };

  const handleChangeCreate = (templateName) => {
    setNameTemplateCv({ name: templateName });
    let url = routes.createCv.path;
    if (params?.redirect) {
      url += `?redirect=${params?.redirect}`;
    }
    history.push(url);
  };

  return (
    <>
      <HeaderSeo
        title="Công cụ viết CV chuyên nghiệp dành cho sinh viên và người đi làm"
        description="Lao động online - Công cụ viết CV chuyên nghiệp dành cho bạn"
      />
      <div
        className={cx(
          "w-full mx-auto lg:max-w-[1175px] z-1",
          theme?.backround?.gradient
        )}
      >
        <ScrollTop />
        <div className="px-4 my-[60px] select-none">
          <h1
            className={cx(
              "text-[18px] md:text-[24px]",
              theme?.background?.text
            )}
          >
            Danh sách mẫu CV xin việc chuẩn {new Date().getFullYear()}
          </h1>
          <h2 className={cx("text-[14px] md:text-[16px] text-gray")}>
            Các mẫu CV được thiết kế phù hợp với sinh viên, người đi làm theo
            chuẩn và các ngành nghề
          </h2>

          <form
            className={cx(
              "w-full px-4 py-2 shadow-md bg-white my-[20px] rounded-[4px] flex justify-between items-center"
            )}
            onSubmit={async (e) => {
              e.preventDefault();
              await handleChangeSearch(inputSearch);
            }}
          >
            <input
              type="text"
              className={cx(
                "focus:outline-none border-b border-b-[gray] w-[65%] sm:w-[90%] md:w-[85%]"
              )}
              value={inputSearch?.search}
              onChange={(e) => {
                setInputSearch({ ...inputSearch, search: e.target.value });
              }}
              placeholder="Tìm kiếm cv theo nhu cầu của bạn"
            />
            <button
              type="submit"
              className={cx(
                "px-4 py-1 text-white text-[14px] rounded-[8px]",
                theme?.button?.background
              )}
            >
              <Search style={{ fontSize: "16px" }} />
              Tìm kiếm
            </button>
          </form>

          <div className={"w-full bg-white rounded-[4px] p-[10px] shadow-lg"}>
            <div className="w-full my-[10px]">
              <p className="text-[14px] md:text-[18px]">
                Chọn và trải nghiệm công cụ tạo CV của <b>LDO</b> - với
                các mẫu CV được thiết kế mới nhất tại đây
              </p>
            </div>
            <div
              className={cx(
                "w-full px-4 py-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4",
                user ? "lg:grid-cols-2 xl2:grid-cols-3" : "lg:grid-cols-4"
              )}
            >
              {listTemplate &&
                listTemplate.length > 0 &&
                listTemplate.map((item, index) => (
                  <CardTemplate
                    key={"template-cv-" + index}
                    data={item}
                    onDetail={() => setToggleDetail(item)}
                    onEdit={handleChangeCreate}
                  />
                ))}
              {/* <CardTemplate
                key={"template-cv-"}
                data={{ image: "", name: "Test", subName: "CV_007" }}
                onDetail={() => {}}
                onEdit={handleChangeCreate}
              /> */}
            </div>
            {(!listTemplate || listTemplate.length < 1) && (
              <p>
                Hệ thống không tìm thấy mẫu CV phù hợp với các yêu cầu của bạn
              </p>
            )}
          </div>
        </div>
      </div>
      <CvDetailModal
        active={toggleDetail}
        onChangeActive={() => setToggleDetail(null)}
      />
    </>
  );
}

export default CvTemplate;
