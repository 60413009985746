export const getConfigMode = (mode) => {
  return config[mode];
};

const config = {
  light_mode: {
    menu: {
      gradient: "bg-gradient-to-r from-[#0b4d86] to-[#3babe1]",
      item: "hover:border-b-[#3babe1]",
      border: "border-b border-b-[#3babe1]",
      active: "border-b-[#3babe1]",
      sub_active: "hover:bg-[#6F2F66] hover:text-white",
    },
    login: {
      background: "bg-gradient-to-b from-[#2B245E] to-[#8C428D]",
    },
    footer: {
      background: "bg-[#0b4d86]",
      border: "border-t border-t-[#3babe1]",
    },
    button: {
      background: "bg-[#3babe1]",
    },
    landingPage: {
      banner: {
        from: "from-[#0b4d86]",
        to: "to-[#3babe1]",
      },
      heading: {
        title: "text-white",
        subTitle: "text-[#ddd]",
      },
      sectionTop: {
        gradient: "bg-gradient-to-b",
        from: "from-[#0b4d86]",
        to: "to-[#3babe1]",
      },
      sectionBottom: {
        gradient: "bg-gradient-to-t",
        from: "from-[#0b4d86]",
        to: "to-[#3babe1]",
      },
      cv: {
        button: "bg-[#3babe1]",
        text: "text-white",
      },
    },
    paymentPage: {
      background: "bg-gradient-to-tl from-[#08EFFE] to-[#145AC5]",
      text: "text-black",
    },
    background: {
      gradient: "bg-[#F2F7FF]",
      text: "text-[#1B2124]",
      sub_text: "text-black",
      hover: "hover:text-black",
      border: "",
    },
    loginCandidateContent: {
      background: "bg-gradient-to-tl from-[#2B245E] to-[#8C428D]",
      hover: "hover:bg-black",
      active: "bg-black bg-opacity-10",
    },
  },
  dark_mode: {
    menu: {
      gradient: "bg-gradient-to-r from-[#0A192F] to-[#0A192F]",
      item: "hover:border-b-[#67B1FB]",
      border: "border-b-2 border-b-[#1E2429]",
      active: "border-b-[#67B1FB]",
      sub_active: "hover:bg-[#aaa] hover:text-white",
    },
    login: {
      background: "bg-gradient-to-b from-[#0A192F] to-[#0A192F]",
    },
    footer: {
      background: "bg-[#0A192F]",
      border: "border-t border-t-[white]",
    },
    button: {
      background: "bg-[#0A192F]",
    },
    landingPage: {
      banner: {
        from: "from-[#0A192F]",
        to: "to-[#0A192F]",
      },
      heading: {
        title: "text-white",
        subTitle: "text-gray",
      },
      sectionTop: {
        gradient: "bg-gradient-to-b",
        from: "from-[#0A192F]",
        to: "to-[#0A192F]",
      },
      sectionBottom: {
        gradient: "bg-gradient-to-t",
        from: "from-[#0A192F]",
        to: "to-[#0A192F]",
      },
      cv: {
        button: "bg-[#087DFA]",
        text: "text-white",
      },
    },
    paymentPage: {
      background: "bg-gradient-to-tl from-[#08EFFE] to-[#145AC5]",
      text: "text-white",
    },
    background: {
      gradient: "bg-gradient-to-r from-[#0A192F] to-[#0A192F]",
      text: "text-white",
      sub_text: "text-[#ddd]",
      text_hover: "hover:text-white",
      border: "border-white",
    },
    loginCandidateContent: {
      background: "bg-[#0A192F]",
      hover: "hover:bg-white",
      active: "bg-white bg-opacity-10",
    },
  },
};

export const changePagination = (current, type, originalElement) => {
  if (type) {
    return (
      <p className="bg-white hover:bg-[#ccc] text-black rounded">
        {type === "prev" ? (
          <>&lt;</>
        ) : type === "next" ? (
          <>&gt;</>
        ) : (
          type === "page" && current
        )}
      </p>
    );
  }
  return originalElement;
};
