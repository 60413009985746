import React, { useEffect, useState } from "react";
import BreadcrumbAdmin from "../components/Tag/Breadcrumb";
import CardEmpty from "../components/Card/CardEmpty";
import CardRecruiter from "../components/Card/CardRecruiter";
import { pathAdmin } from "../../screens/PathRouting";
import { useHistory, useLocation } from "react-router-dom";
import { Pagination } from "antd";
import queryString from "query-string";
import AdminService from "../../services/AdminService";
import dayjs from "dayjs";
import { getLocation } from "../../utils";
import SearchForm from "../Company/SearchForm";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import { changePagination } from "../../lib/theme/config";

function PostManagement() {
  const { theme } = useTheme();
  const history = useHistory();
  const [listJds, setListJds] = useState();
  const [totalItem, setTotalItem] = useState(1);
  const location = useLocation();
  const limit = 15;

  const search = queryString.parse(location.search);

  useEffect(() => {
    if (!listJds) {
      getAllListJd(search?.page || 1, { status: search.status });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listJds, search]);

  const getAllListJd = async (page, values = {}) => {
    if (values?.page) delete values.page;
    const params = queryString.stringify({
      offset: page - 1 || 0,
      limit: limit,
    });
    const response = await AdminService.getAllJd(params, values);
    const data = response.data.data.filter((item, index) => index > 0 && item);
    const result = data.map((item) => ({
      id: item.id,
      jobName: item.jobName,
      date: dayjs(new Date(item.startReceiveCV)).format("DD/MM/YYYY"),
      cvApplied: (item?.cvApplyList && item.cvApplyList.length) || 0,
      location: getLocation(item.location),
      status: item?.status ? item.status.toLowerCase() : "inactive",
    }));
    setListJds(result);
    const total = response.data.data.find((item) => item.countTotal);
    setTotalItem(total?.countTotal || 10);
  };

  return (
    <div
      className={cx(
        "mx-auto min-h-[80vh] lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <div className="px-4">
        <BreadcrumbAdmin data={[{ name: "Quản lý bài tuyển dụng" }]} />
        <SearchForm
          onChangeValue={(value) => getAllListJd(search?.page || 1, value)}
        />
        <div className="w-full my-[40px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <CardEmpty
            icon="/icons/folderadd.png"
            title="Thêm bài viết"
            onClick={() => {}}
            className="!h-[200px]"
          />
          {listJds &&
            listJds.length > 0 &&
            listJds.map((item) => (
              <CardRecruiter
                status={item.status}
                data={item}
                onHandleDetail={() =>
                  history.push(
                    pathAdmin.adminPostCandidate.parentPath + "/" + item.id
                  )
                }
              />
            ))}
        </div>
        <div className="w-full flex justify-center items-center">
          <Pagination
            defaultCurrent={Number(search?.page) || 1}
            total={totalItem}
            pageSize={limit}
            onChange={(value) => {
              getAllListJd(value, { status: search.status });
              history.replace(
                location.pathname +
                  "?" +
                  queryString.stringify({ page: value, status: search.status })
              );
            }}
            itemRender={changePagination}
          />
        </div>
      </div>
    </div>
  );
}

export default PostManagement;
