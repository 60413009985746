import React from "react";
import { FileTextOutlined } from "@ant-design/icons";
import SelectActive from "../Select/SelectActive";
import { Tooltip } from "antd";
import { renderAvatarList } from "../../../utils";

function CardRecruiter(props) {
  const { data, status, onHandleDetail } = props;
  return (
    <div className="w-full h-[200px] p-[15px] bg-white rounded-[26px]">
      <div className="grid grid-cols-5 gap-1">
        <div className="col-span-4">
          <Tooltip title={data?.jobName}>
            <div className="min-h-[42px] max-h-[42px] line-clamp-2 text-black text-[20px] font-[600] leading-none">
              {data?.jobName}
            </div>
          </Tooltip>

          <div className="flex justify-start items-center mt-2 text-gray">
            <div className="w-[12px] h-[13px] mr-2">
              <img
                src="/asserts/location.png"
                alt=""
                className="w-full h-full object-cover"
              />
            </div>
            <p className="line-clamp-1">{data?.location}</p>
          </div>
        </div>
        <div className="col-span-1 flex justify-start">
          <SelectActive active={status} id={data?.id} />
        </div>
      </div>
      <div className="mt-4">
        <p className="text-black">Ngày đăng tuyển: {data?.date}</p>
        <p className="text-black">Người ứng tuyển: {data?.cvApplied}</p>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="w-full h-[40px] flex my-2">
          {data?.candidateAvatar && renderAvatarList(data.candidateAvatar, 5)}
        </div>
        <div className="flex justify-end items-center mb-4">
          <div
            className="px-3 py-1 bg-[#1495F3] flex justify-center items-center rounded-[20px] cursor-pointer"
            onClick={onHandleDetail}
          >
            <FileTextOutlined style={{ color: "white", fontSize: "12px" }} />
            <span className="text-white text-[12px] ml-2">Xem chi tiết</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardRecruiter;
