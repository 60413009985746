import { adminCvManagement, updateStatusCv } from "./PORT";
import {
  addUserInGroup,
  allUser,
  company,
  createCompany,
  createGroup,
  deleteGroup,
  getJDbyID,
  getMemberOfGroup,
  getUserDetail,
  group,
  groupDetail,
  jdList,
  listAllCompany,
  updateCompany,
  uploadAvataCompany,
} from "./AdminPort";
import api from "./api";
import apiParsing from "./apiParsing";

/* eslint-disable import/no-anonymous-default-export */
const ADMIN_API_BASE_URL = "admin/";

class AdminService {
  constructor() {
    this.api = api;
    this.apiParsing = apiParsing;
  }
  getAllCvManagement(offset, isChecked = false, limit = 12) {
    return this.api.get(
      adminCvManagement + `/${isChecked}?offset=${offset}&limit=${limit}`
    );
  }

  getTotalItemCvUpload(isChecked = false) {
    return this.api.get(adminCvManagement + "/" + isChecked + "/total");
  }

  updateStatusCVByAdmin(data) {
    return this.api.put(updateStatusCv, data);
  }

  getAllCompany(params) {
    return this.api.get(ADMIN_API_BASE_URL + listAllCompany + "?" + params);
  }

  getCompanyById(id, data) {
    return this.api.post(ADMIN_API_BASE_URL + company + `?id=${id}`, data);
  }

  updateCompany(id, data) {
    return this.api.put(ADMIN_API_BASE_URL + updateCompany + `?id=${id}`, data);
  }

  deleteCompany(data) {
    return this.api.put(ADMIN_API_BASE_URL + "delete-company", data);
  }

  getAllGroup(params) {
    return this.api.get(ADMIN_API_BASE_URL + group + "?" + params);
  }

  getGroupById(data, params, option = {}) {
    return this.api.post(ADMIN_API_BASE_URL + groupDetail + params, {
      id: data,
      ...option,
    });
  }

  getAllRecruiterInGroup(params) {
    return this.api.get(
      ADMIN_API_BASE_URL + groupDetail + `/recruiters?` + params
    );
  }

  createNewCompany(data) {
    return this.api.post(ADMIN_API_BASE_URL + createCompany, data);
  }

  uploadImageCompany(params, data) {
    return this.api.post(uploadAvataCompany + `?${params}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  createNewGroup(data) {
    return this.api.post(ADMIN_API_BASE_URL + createGroup, data);
  }

  getAllJd(params, data) {
    return this.api.post(ADMIN_API_BASE_URL + jdList + "?" + params, data);
  }

  getJdById(id) {
    return this.api.get(ADMIN_API_BASE_URL + getJDbyID + "/" + id);
  }

  getAllMember(params) {
    return this.api.get(ADMIN_API_BASE_URL + getMemberOfGroup + params);
  }

  getAllUser(params) {
    return this.api.post(ADMIN_API_BASE_URL + allUser + params);
  }

  getUserById(params) {
    return this.api.get(ADMIN_API_BASE_URL + getUserDetail + params);
  }

  updateUserInGroup(id, data) {
    return this.api.put(ADMIN_API_BASE_URL + addUserInGroup + "/" + id, data);
  }

  deleteGroupById(id) {
    return this.api.delete(ADMIN_API_BASE_URL + deleteGroup + `/${id}`);
  }

  getAvailableGroups(companyId) {
    return this.api.get(
      ADMIN_API_BASE_URL + `group/available/?companyId=${companyId}`
    );
  }

  addGroupToCompany(companyId, data) {
    return this.api.put(ADMIN_API_BASE_URL + `add-group/${companyId}`, data);
  }

  addNewOption(data) {
    return this.api.post(ADMIN_API_BASE_URL + "add-option", data);
  }

  deleteOption(data) {
    return this.api.put(ADMIN_API_BASE_URL + "remove-params", data);
  }

  updateStatusCvInJd(params, data) {
    return this.api.put(
      `job-description/cv-applied-pdf/status/${params.jdId}/${params.status}`,
      data
    );
  }

  loginAdmin(data) {
    return this.api.post("auth/login-admin", data);
  }

  createNews(data) {
    return this.api.post("news/create-news", data);
  }

  getAllNews(params) {
    return this.api.get(`news/get-all-news?${params}`);
  }

  getNewsDetail(slug) {
    return this.api.get(`news/get-news?slug=${slug}`);
  }

  changeValidateNews(data) {
    return this.api.put(ADMIN_API_BASE_URL + "news/validate", data);
  }

  changeStatusNews(data) {
    return this.api.put(ADMIN_API_BASE_URL + "news/active", data);
  }

  updateNews(slug, data) {
    return this.api.put(`news/update-news/${slug}`, data);
  }

  uploadImageNews(email, file) {
    return this.api.post(`files/uploadnews-image?email=${email}`, file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  createAds(data) {
    return this.api.post(ADMIN_API_BASE_URL + "advertising/create", data);
  }

  uploadImageAds(id, file) {
    return this.api.post(`files/upload-advertising-image?id=${id}`, file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getAllAds(params) {
    return this.api.get(
      ADMIN_API_BASE_URL + `advertising/get-all-advertising?${params}`
    );
  }

  getAdsById(id) {
    return this.api.get(
      ADMIN_API_BASE_URL + `advertising/get-advertising?id=${id}`
    );
  }

  validateAds(data) {
    return this.api.put(ADMIN_API_BASE_URL + "advertising/validate", data);
  }

  activeAds(data) {
    return this.api.put(ADMIN_API_BASE_URL + "advertising/activate", data);
  }

  updateAds(id, data) {
    return this.api.put(ADMIN_API_BASE_URL + `advertising/update/${id}`, data);
  }

  getAdsShowHome() {
    return this.api.get(ADMIN_API_BASE_URL + "advertising/get-priority-ads");
  }

  parsingCv(data) {
    return this.apiParsing.post("curriculum-vitae/parsing-cv", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  saveParsingCv(data) {
    return this.api.post("curriculum-vitae/parsing-cv/create", data);
  }

  findParsingCv(data) {
    return this.api.post("curriculum-vitae/filter-cv", data);
  }
}

export default new AdminService();
