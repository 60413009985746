import React, { useState } from "react";
import CKEditors from "../../components/CKEditor/CKEditors";
import InputAdmin from "../components/Input";
import { useFormik } from "formik";
import BreadcrumbAdmin from "../components/Tag/Breadcrumb";
import { createNewsSchema } from "../../utils/validations";
import { CameraOutlined } from "@ant-design/icons";
import { useAuth } from "../Auth/useAuth";
import { notification } from "antd";
import AdminService from "../../services/AdminService";
import { useHistory } from "react-router-dom";
import { pathAdmin } from "../../screens/PathRouting";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";

function CreateNews() {
  const { theme } = useTheme();
  const { admin } = useAuth();
  const history = useHistory();
  const [avatar, setAvatar] = useState("");
  const [checkAvatar, setCheckAvatar] = useState(false);

  const [api, contextHolder] = notification.useNotification({ top: 80 });;

  const createNewsFormik = useFormik({
    initialValues: {
      title: "",
      tag: "",
      content: "",
    },
    validationSchema: createNewsSchema,
    onSubmit: (values) => {
      if (!avatar) {
        setCheckAvatar(true);
        return;
      }
      const data = {
        ...values,
        tag: values.tag.split(",").map((item) => item.trim()),
        owner: [
          {
            id: admin?.id,
            name: admin?.nickname,
          },
        ],
        discription: admin?.sub_role,
      };
      createNews(data);
    },
  });

  const createNews = async (values) => {
    try {
      let uploadImage = "";
      let newData = { ...values };
      if (avatar) {
        const formData = new FormData();
        formData.append("files", avatar);
        uploadImage = await AdminService.uploadImageNews(admin.email, formData);
        newData = { ...newData, image: uploadImage.data.data.message[0] };
      }
      const result = await AdminService.createNews(newData);
      if (result) {
        api.success({
          message: "Thành công",
          description:
            "Thêm mới bài viết thành công! Vui lòng đợi admin phê duyệt bài viết",
          style: { top: 50 },
        });
        history.push(pathAdmin.adminManagerNews.path);
      }
    } catch (error) {
      api.error({
        message: "Thất bại",
        description: "Thêm bài viết thất bại",
        style: { top: 50 },
      });
    }
  };

  const uploadAvatar = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setAvatar(e.target.files[0]);
    }
    setCheckAvatar(false);
  };

  const getMessageError = (field) => {
    return createNewsFormik.errors[field] && createNewsFormik.touched[field]
      ? createNewsFormik.errors[field]
      : "";
  };

  return (
    <div
      className={cx(
        "mx-auto min-h-[80vh] lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      {contextHolder}
      <div className="px-4">
        <BreadcrumbAdmin
          data={[
            {
              name: "Quản lý tin tức",
              path: pathAdmin.adminManagerNews.path,
            },
            {
              name: "Tạo bài viết mới",
            },
          ]}
        />
        <form
          onSubmit={createNewsFormik.handleSubmit}
          className="rounded-[12px] bg-white"
        >
          <div
            className={`w-full h-[120px] rounded-tl-[12px] rounded-tr-[12px] bg-[#1495F3] relative cursor-pointer`}
          >
            <input
              type="file"
              id="upload"
              name="upload"
              onChange={uploadAvatar}
              accept="image/*"
              hidden
            />
            <div
              className={`w-[120px] h-[120px] bg-[#F5F7FF] absolute left-10 -bottom-[50%] flex justify-center items-center ${
                checkAvatar && "border border-red-400"
              }`}
            >
              {avatar && (
                <img
                  src={URL.createObjectURL(avatar)}
                  alt=""
                  className="max-w-full max-h-full object-cover border-none"
                />
              )}
            </div>

            <div
              className="w-[120px] h-[120px] border-none opacity-60 absolute left-10 -bottom-[50%] flex justify-center items-center"
              onClick={() => document.getElementById("upload")?.click()}
            >
              <div className="w-[48px] h-[48px] rounded-[50%] bg-[#B5C1FF] flex justify-center items-center">
                <CameraOutlined className="text-[24px]" />
              </div>
            </div>
          </div>

          <div className="px-[10px] pt-[90px] pb-[20px]">
            <div className="">
              <InputAdmin
                name="title"
                label="Tiêu đề bài viết"
                placeholder="Nhập vào tiêu đề bài viết"
                valueInput={createNewsFormik.values.title}
                onChange={createNewsFormik.handleChange}
                error={getMessageError("title")}
                required
              />
            </div>

            <div className="mt-4">
              <InputAdmin
                name="tag"
                label="Tag"
                placeholder="Nhập vào tag"
                valueInput={createNewsFormik.values.tag}
                onChange={createNewsFormik.handleChange}
                error={getMessageError("tag")}
                required
              />
            </div>

            <CKEditors
              name="content"
              label="Nội dung bài viết"
              value={createNewsFormik.values.content}
              onChangeValue={createNewsFormik.handleChange}
              error={getMessageError("content")}
              required
            />

            <div className="w-full flex justify-end items-center my-4">
              <button
                type="submit"
                onClick={createNewsFormik.handleSubmit}
                className="px-4 py-3 bg-[#0D91F4] rounded-[12px] text-white"
              >
                Lưu bài viết
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateNews;
