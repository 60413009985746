import React from "react";

function CardBoxLoading() {
  return (
    <div className="w-full h-[138px] bg-white rounded-[22px] p-[12px] cursor-pointer">
      <div className="w-full h-[80px] flex justify-start items-center animate-pulse">
        <div className="w-[80px] h-[80px] rounded-[9px] flex justify-center items-center">
          <div className="w-[80px] h-[80px] rounded-[9px] bg-gray-1"></div>
        </div>
        <div className="w-full h-auto ml-[10px]">
          <div className="w-full h-[20px] bg-gray-1"></div>
          <div className="w-[50%] h-[14px] bg-gray-1 mt-2"></div>
        </div>
      </div>
      <div className="w-full h-[30px] grid grid-cols-10 gap-1 my-[10px] animate-pulse">
        <div className="col-span-4 flex">
          <div className="w-full h-[16px] bg-gray-1"></div>
        </div>
        <div className="col-span-5 flex">
          <div className="w-full h-[16px] bg-gray-1"></div>
        </div>
        <div className="col-span-1 flex justify-end">
          <div className="w-[16px] h-[16px] bg-gray-1"></div>
        </div>
      </div>
    </div>
  );
}

export default CardBoxLoading;
