import React, { useEffect, useState } from "react";
import BreadcrumbAdmin from "../components/Tag/Breadcrumb";
import AddParamBlock from "./AddParamBlock";
import UserService from "../../services/UserService";
import AdminService from "../../services/AdminService";
import { notification } from "antd";
import { cx } from "../../lib/cx";
import { useTheme } from "../../lib/theme/useTheme";

function AddParameter() {
  const { theme } = useTheme();
  const [inputSearch, setInputSearch] = useState();
  const [options, setOptions] = useState();
  const [oldOptions, setOldOptions] = useState();
  const [childrenOption, setChildrenOption] = useState([]);
  const [open, setOpen] = useState();

  const [api, contextHolder] = notification.useNotification({ top: 80 });;

  useEffect(() => {
    if (!options) {
      getParameter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const getParameter = async () => {
    const response = await UserService.adminGetAllOption();
    const data = response.data.data;
    setOptions(data);
    setOldOptions(data);
    if (open?.type && open?.value) {
      const dataValue = data.find((item) => item.type === open.type);
      if (dataValue && typeof dataValue.option[0] === "object") {
        getValueChildren(dataValue.option[0], open.value);
      }
    }
  };

  const list = [
    {
      type: "location",
      value: "Địa điểm làm việc",
    },
    {
      type: "level",
      value: "Vị trí công việc",
    },
    {
      type: "experience",
      value: "Kinh nghiệm",
    },
    {
      type: "salaryrange",
      value: "Mức lương",
    },
    {
      type: "field",
      value: "Lĩnh vực",
    },
    {
      type: "department",
      value: "Ngành nghề",
    },
    {
      type: "language",
      value: "Yêu cầu ngôn ngữ",
    },
    {
      type: "jobtype",
      value: "Loại công việc",
    },
  ];

  const getLabel = (type) => {
    return list.find((item) => item.type === type)?.value || type;
  };

  const addNewItem = async (type, option, key, isObject) => {
    const data = {
      type: type,
      option: key ? { [key]: option } : isObject ? { [option]: [] } : [option],
    };

    await AdminService.addNewOption(data)
      .then(() => {
        getParameter();
        api.success({
          message: "Thành công",
          description: "Thêm mới tham số thành công!",
          style: { top: 50 },
        });
      })
      .catch((error) => {
        api.error({
          message: "Thất bại",
          description: `Thêm mới thất bại! ${error}`,
          style: { top: 50 },
        });
      });
  };

  const handleChangeRemove = async (type, option, key) => {
    const dataRemove = {
      type: type,
      key: key ? key : option,
      valueToRemove: key ? option : "",
    };
    await AdminService.deleteOption(dataRemove)
      .then(() => {
        getParameter();
        api.success({
          message: "Thành công",
          description: "Xóa tham số thành công!",
          style: { top: 50 },
        });
      })
      .catch((error) => {
        api.error({
          message: "Thất bại",
          description: `Thêm mới thất bại! ${error}`,
          style: { top: 50 },
        });
      });
  };

  const getDataShow = (option) => {
    if (typeof option[0] === "object") {
      return Object.keys(option[0]);
    }
    return option;
  };

  const isItemObject = (option) => {
    return typeof option[0] === "object";
  };

  const getValueChildren = (option, key) => {
    setChildrenOption(option[key]);
  };

  const isObject = (option) => {
    return typeof option[0] === "object";
  };

  const handleSearch = () => {
    let result = oldOptions;
    if (inputSearch) {
      const lowercaseInput = inputSearch.toLowerCase();
      const searchResults = list.filter((item) =>
        item.value.toLowerCase().includes(lowercaseInput)
      );
      result = oldOptions.filter(
        (item) => searchResults.find((c) => c.type === item.type) && item
      );
    }
    setOptions(result);
  };

  return (
    <div
      className={cx(
        "mx-auto min-h-[80vh] lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      {contextHolder}
      <div className="px-4">
        <BreadcrumbAdmin data={[{ name: "Chỉnh sửa tham số" }]} />

        <div className="w-full min-h-[34px] flex px-4 py-1 bg-white rounded-[12px] my-4">
          <div className="w-[80%] flex justify-start items-center">
            <div className="w-[14px] h-[14px]">
              <img
                src={"/asserts/icons/search.png"}
                alt="icon search"
                className="object-cover"
              />
            </div>
            <input
              type="text"
              name="parameter"
              value={inputSearch}
              onChange={(e) => setInputSearch(e.target.value)}
              className="w-full focus:outline-none ml-[10px] text-[14px]"
              placeholder="Tìm kiếm tham số"
            />
          </div>

          <div className="w-[20%] flex justify-center items-center">
            <div
              className="w-full text-center text-[14px] bg-[#1495F3] rounded-[12px] px-3 py-2 text-white cursor-pointer hidden md:block"
              onClick={handleSearch}
            >
              Tìm kiếm
            </div>
            <div
              className="w-[14px] h-[14px] block md:hidden"
              onClick={handleSearch}
            >
              <img
                src={"/asserts/icons/search.png"}
                alt="icon search"
                className="object-cover"
              />
            </div>
          </div>
        </div>

        {options &&
          options.length > 0 &&
          options.map((item) => {
            return (
              <>
                <AddParamBlock
                  name={item.type}
                  title={getLabel(item.type)}
                  label={"Thêm " + getLabel(item.type).toLowerCase()}
                  placeholder={getLabel(item.type)}
                  data={getDataShow(item.option)}
                  isCheck={isItemObject(item.option)}
                  onChange={(value) => {
                    setOpen({ type: item.type, value: value });
                    getValueChildren(item.option[0], value);
                  }}
                  onChangeAdd={addNewItem}
                  onChangeRemoveItem={handleChangeRemove}
                />
                {isObject(item.option) && (
                  <div
                    className={`${
                      open?.type === item.type ? "block" : "hidden"
                    }`}
                  >
                    <AddParamBlock
                      name={item.type}
                      title={getLabel(item.type)}
                      label={"Thêm " + getLabel(item.type).toLowerCase()}
                      placeholder={getLabel(item.type)}
                      parent={open?.value}
                      data={childrenOption}
                      onChange={() => {}}
                      onChangeAdd={addNewItem}
                      onChangeRemoveItem={handleChangeRemove}
                    />
                  </div>
                )}
              </>
            );
          })}

        {/* <div className="mt-[20px]">
          {addNew && (
            <AddNewParam onChangeAdd={(value) => addNewItem(value, "")} />
          )}
          <p
            className="text-blue-500 text-[16px] hover:underline"
            onClick={() => setAddNew(!addNew)}
          >
            + Thêm mới
          </p>
        </div> */}
      </div>
    </div>
  );
}

export default AddParameter;
