import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { Form, Checkbox, notification } from "antd";
import { Bf } from "../../components/BtForm";
import UserService from "../../services/UserService";
import { login, regist2, regist3 } from "../../services/PORT";
import { CaptCha } from "../../components/CaptCha";
import { useLocation, useHistory } from "react-router-dom";
import { routes } from "../PathRouting";
import { useFormik } from "formik";
import {
  registerEnterpriseSchema,
  registerInfoSchema,
} from "../../utils/validations";
import { SignUp3 } from "./SignUp3";
import { otp as OTP } from "../../services/PORT";
import Input from "../../components/Input";
import queryString from "query-string";
import ScrollTop from "../../components/ScrollTop";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import { getStateGlobal } from "../../cv/redux/local-storage";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const SignUp = () => {
  const { theme } = useTheme();
  const history = useHistory();
  const location = useLocation();

  const infoCv = getStateGlobal();

  const params = queryString.parse(location.search);

  notification.config({ top: 80, placement: "topRight" });

  const [checkPhoneNumber, setCheckPhoneNumber] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [captcha, setCapcha] = useState("");
  const [otp, setOTP] = useState(0);
  const [loading, setLoading] = useState(false);

  const [isCheckValue, setIsCheckValue] = useState(false);

  const [pathname, setPathName] = useState("");

  const initialValues = {
    email: "",
    phone: "",
    password: "",
    rePassword: "",
    captCha: "",
    referralCode: params?.code || "",
  };

  const [data, setData] = useState({});
  const [api, contextHolder] = notification.useNotification({ top: 80 }); // định nghĩa thông báo

  const createSignUpFormik = useFormik({
    initialValues:
      pathname !== routes.signupRecruiter.path
        ? { ...initialValues, fullName: "" }
        : { ...initialValues, enterprise: "", taxCode: "" },
    validationSchema:
      pathname !== routes.signupRecruiter.path
        ? registerInfoSchema
        : registerEnterpriseSchema,
    onSubmit: (values) => {
      if (!values.captCha) {
        api.error({
          message: "Thất bại",
          description: `Vui lòng nhập captcha!`,
          style: { top: 50 },
        });
        return;
      }
      if (captcha !== values.captCha) {
        api.error({
          message: "Thất bại",
          description: `Captcha không khớp!`,
          style: { top: 50 },
        });
        return;
      }
      const dataSend = {
        ...values,
        phoneNumber: "+84" + values.phone.slice(1, values.phone.length),
      };
      delete dataSend.rePassword;
      delete dataSend.phone;
      delete dataSend.captCha;

      if (pathname === routes.signupRecruiter.path) {
        delete dataSend.fullName;
      }
      creatNewUser(dataSend);
    },
  });

  useEffect(() => {
    createSignUpFormik.setValues({ referralCode: params?.code || "" });
    setPathName(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!isCheckValue && infoCv && pathname === routes.signupCandidate.path) {
      getInfoCvRender(infoCv.builder.profile);
      setIsCheckValue(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckValue, infoCv]);

  const getInfoCvRender = (data) => {
    handleChangeValue("email", data.email);
    handleChangeValue("phone", data.phoneNumber);
    handleChangeValue("fullName", data.fullName);
  };

  const handleChangeValue = (field, value) => {
    createSignUpFormik.handleChange({
      target: {
        name: field,
        value: value,
      },
    });
  };

  const creatNewUser = async (dataSend) => {
    try {
      const response = await UserService.createUser(
        dataSend,
        pathname === routes.signupCandidate.path ? regist3 : regist2
      );

      if (response) {
        setData({
          ...dataSend,
          role:
            pathname === routes.signupRecruiter.path
              ? "Recruiter"
              : "Candidate",
        });
        setConfirm(!confirm);
      }
    } catch (error) {
      api.error({
        message: "Thất bại",
        description: `${error.response.data.message}`,
        style: { top: 50 },
      });
    }
  };

  const getErrorMessage = (field) => {
    return createSignUpFormik.errors[field] && createSignUpFormik.touched[field]
      ? createSignUpFormik.errors[field]
      : "";
  };

  const checkOTPNotification = () => {
    // thông báo thông tin mật khẩu và mật khẩu nhập lại không giống nhau
    api.error({
      message: "OTP không chính xác",
      placement: "topRight",
      style: { top: 44 },
    });
  };

  const onHandleChangeConfirm = () => {
    const dataOTP = {
      email: data.email,
      otp: otp,
      role: data.role,
    };
    UserService.createUser(dataOTP, OTP)
      .then(async (res) => {
        setLoading(true);
        notification.success({
          message: "Thành công",
          description:
            " Xác thực tài khoản thành công. Đang điều hướng về trang chủ...",
          duration: 4, // Duration in seconds (adjust as needed)
          style: { top: 50 },
        });

        await sleep(2000);
        userLoginAfter();
      })
      .catch((err) => {
        checkOTPNotification();
        console.log(err);
      });
  };

  const userLoginAfter = () => {
    const values = {
      email: data.email,
      password: data.password,
      role: data.role,
    };
    UserService.checkUser(values, login)
      .then((res) => {
        localStorage.setItem("User", JSON.stringify(res.data.data));
        localStorage.setItem(
          "loginStatus",
          JSON.stringify({ status: "success", role: data.role })
        );

        if (data.role === "Candidate") {
          let url = routes.home.path;
          if (params?.redirect) {
            url = params.redirect;
          }

          history.push(url, { status: "success" });
        }
        if (data.role === "Recruiter") {
          history.push(routes.recruiterDashboard.path, { status: "success" });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      className={cx(
        "w-full min-h-[75vh] flex justify-center px-[10px] sm:px-0 py-[90px]",
        theme?.login?.background
      )}
    >
      <ScrollTop />
      {contextHolder}
      <div className="rounded-[22px] bg-white p-[24px] w-[600px]">
        <div>
          <p className="text-[28px] text-black font-[600]">Đăng ký 👋</p>
          <p className="text-[18px] py-2 text-[#1495F3]">
            Dành cho{" "}
            {pathname === routes.signupRecruiter.path
              ? "nhà tuyển dụng"
              : "ứng viên"}
          </p>
        </div>
        <form>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Input
              name={
                pathname === routes.signupRecruiter.path
                  ? "enterprise"
                  : "fullName"
              }
              label={
                pathname === routes.signupRecruiter.path
                  ? "Tên doanh nghiệp"
                  : "Họ và tên"
              }
              value={
                pathname === routes.signupRecruiter.path
                  ? createSignUpFormik.values.enterprise
                  : createSignUpFormik.values.fullName
              }
              onChangeInput={createSignUpFormik.handleChange}
              error={getErrorMessage(
                pathname === routes.signupRecruiter.path
                  ? "enterprise"
                  : "fullName"
              )}
              placeholder={
                pathname === routes.signupRecruiter.path
                  ? "Tên doanh nghiệp"
                  : "Họ và tên"
              }
              required
              className="!mt-1"
            />

            {pathname === routes.signupRecruiter.path && (
              <Input
                name="taxCode"
                label={"Mã số thuế"}
                onChangeInput={createSignUpFormik.handleChange}
                error={getErrorMessage("taxCode")}
                placeholder={"Nhập MST"}
                required
                className="!mt-1"
              />
            )}

            <Input
              name="email"
              label={"Email"}
              value={createSignUpFormik.values.email}
              onChangeInput={createSignUpFormik.handleChange}
              error={getErrorMessage("email")}
              placeholder={"Sử dụng email để xác nhận"}
              required
              className="!mt-1"
            />

            <Input
              name="phone"
              label={"Số điện thoại"}
              value={createSignUpFormik.values.phone}
              onChangeInput={createSignUpFormik.handleChange}
              error={getErrorMessage("phone")}
              placeholder={"Nhập SĐT"}
              required
              className="!mt-1"
            />
          </div>

          <div className="mt-2">
            <Input
              type="password"
              name="password"
              label={"Mật khẩu"}
              onChangeInput={createSignUpFormik.handleChange}
              error={getErrorMessage("password")}
              placeholder={
                "Từ 6-50 ký tự, 1 hoa, 1 thường, 1 số, 1 ký tự đặc biệt."
              }
              required
              className="!mt-1"
              isCheckPassword
            />
          </div>

          <div className="mt-2">
            <Input
              type="password"
              name="rePassword"
              label={"Nhập lại mật khẩu"}
              onChangeInput={createSignUpFormik.handleChange}
              error={getErrorMessage("rePassword")}
              placeholder={
                "Từ 6-50 ký tự, 1 hoa, 1 thường, 1 số, 1 ký tự đặc biệt."
              }
              required
              className="!mt-2"
              isCheckPassword
            />
          </div>
          <div className="mt-2">
            <Input
              type="text"
              name="referralCode"
              label={"Nhập mã giới thiệu"}
              value={createSignUpFormik.values.referralCode}
              onChangeInput={createSignUpFormik.handleChange}
              placeholder={"Nhập mã giới thiệu mà bạn nhận được (nếu có)"}
              className="!mt-2"
            />
          </div>
        </form>

        <div className="my-4 space-x-4">
          <b>Gửi mã xác thực tới</b>
          {/* <Checkbox
            checked={checkPhoneNumber}
            // onChange={(e) => {
            //   setCheckPhoneNumber(!checkPhoneNumber);
            // }}
          >
            Số điện thoại
          </Checkbox> */}
          <Checkbox
            checked={!checkPhoneNumber}
            onChange={(e) => {
              setCheckPhoneNumber(!checkPhoneNumber);
            }}
          >
            Email
          </Checkbox>
        </div>

        <Form layout="vertical">
          <CaptCha
            title={<b>Mã bảo mật CAPTCHA</b>}
            onChangeCaptcha={(cap) => setCapcha(cap)}
            oldCaptcha={captcha}
          />
        </Form>

        <div className="mb-8">
          <Input
            name="captCha"
            onChangeInput={createSignUpFormik.handleChange}
            error={getErrorMessage("captCha")}
            placeholder={"Nhập mã CAPTCHA"}
            required
            className="!mt-1"
          />
        </div>

        <Stack direction="column" alignItems="center" spacing={1}>
          <p>
            Bằng cách bấm vào nút “Đăng ký, tôi đồng ý với{" "}
            {<Bf title={"Thỏa thuận sử dụng"} />} và{" "}
            {<Bf title={"Quy định bảo mật"} />} của LDO
          </p>
          <div
            className="text-center px-6 py-3 bg-[#0055FF] cursor-pointer mt-4 text-white w-full rounded-[16px]"
            onClick={createSignUpFormik.handleSubmit}
          >
            Tạo tài khoản
          </div>
        </Stack>
      </div>
      <SignUp3
        loading={loading}
        open={confirm}
        data={{
          role:
            pathname === routes.signupCandidate.path
              ? "Candidate"
              : "Recruiter",
          phoneNumber: data.phoneNumber,
          email: data.email,
        }}
        onCickConfirm={onHandleChangeConfirm}
        onCancel={() => {
          setConfirm(!confirm);
          history.push(routes.home.path);
        }}
        onChange={(number) => setOTP(number)}
      />
    </div>
  );
};

export default SignUp;
