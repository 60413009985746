import React, { useEffect, useState } from "react";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import { useAuth } from "../Auth/useAuth";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import UserService from "../../services/UserService";
import { getBusinessInfoByID } from "../../services/PORT";
import { getId, getImageUrl } from "../../utils";
import RecruiterInfo from "./components/RecruiterInfo";
import RecruiterHistory from "./components/RecruiterHistory";
import RecruiterContract from "./components/RecruiterContract";

function RecruiterProfile() {
  const { theme } = useTheme();
  const { admin } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const id = getId(location.pathname);
  const search = queryString.parse(location.search);

  const [recruiterData, setRecruiterData] = useState();

  useEffect(() => {
    if (!recruiterData) {
      getRecruiterDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recruiterData]);

  const getRecruiterDetail = async () => {
    const response = await UserService.getRecruiterByEmail(
      getBusinessInfoByID,
      id
    );

    const recruiter = response.data.data;

    const listImage = [recruiter.avatarImg[0], recruiter.aboutUsImg[0]];

    recruiter.officeImg.forEach((item) => listImage.push(item));
    recruiter.storyImg.forEach((item) => listImage.push(item));

    let listImages = await UserService.getImageByName(listImage);
    listImages = listImages.data.data;
    const result = {
      ...recruiter,
      avatarImg: getImageUrl(listImages, recruiter.avatarImg[0]),
      aboutUsImg: getImageUrl(listImages, recruiter.aboutUsImg[0]),
      officeImg: recruiter.officeImg.map((item) =>
        getImageUrl(listImages, item)
      ),
      storyImg: recruiter.storyImg.map((item) => getImageUrl(listImages, item)),
    };

    setRecruiterData(result);
  };

  const listMenu = [
    {
      name: "Thông tin NTD",
      slug: "info",
    },
    {
      name: "Lịch sử giao dịch",
      slug: "history",
    },
    {
      name: "Hợp đồng",
      slug: "contract",
    },
  ];

  return (
    <div
      className={cx(
        "mx-auto min-h-[80vh] lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <div className="px-4 my-[80px]">
        <div className={cx("my-[50px]", theme?.background?.text)}>
          <p className="text-[32px] font-[600] leading-none">
            Dashboad Recruiter
          </p>
          <p className="text-[26px]">{admin?.nickname}</p>
        </div>
        <h2 className={cx("my-4 text-[24px]", theme?.background?.text)}>
          Hồ Sơ Nhà Tuyển Dụng
        </h2>
        <div className={"space-y-1 sm:space-y-1 space-x-1"}>
          {listMenu.map((menu, index) => (
            <div
              key={"menu" + index + menu.slug}
              className={cx(
                "px-4 py-1 inline-block cursor-pointer rounded-[12px] text-white",
                (search?.type || "info") === menu.slug ? "bg-[#333]" : "bg-gray"
              )}
              onClick={() =>
                history.replace(
                  location.pathname +
                    `?${queryString.stringify({ ...search, type: menu.slug })}`
                )
              }
            >
              {menu.name}
            </div>
          ))}
        </div>

        <div className="mt-[40px]">
          {(search?.type || "info") === "info" && (
            <RecruiterInfo data={recruiterData} />
          )}
          {search?.type === "history" && <RecruiterHistory />}
          {search?.type === "contract" && <RecruiterContract />}
        </div>
      </div>
    </div>
  );
}

export default RecruiterProfile;
