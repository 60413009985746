import { CameraOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import React, { useState } from "react";
import InputAdmin from "../Input";
import { createPersonalSchema } from "../../../utils/validations";

function CreateUserModal(props) {
  const { active, onChangeActive } = props;
  const [avatar, setAvatar] = useState("");

  const createUserFormik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phoneNumber: "",
    },
    validationSchema: createPersonalSchema,
    onSubmit: (values) => {
      console.log("----------values", values);
      onChangeActive();
    },
  });

  const uploadAvatar = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setAvatar(e.target.files[0]);
    }
  };

  return (
    <div
      className={`overflow-hidden fixed inset-0 z-9999 h-screen outline-none focus:outline-none justify-center items-center c-modal bg-black bg-opacity-20 ${
        active ? "block" : "hidden"
      }`}
    >
      <div className="relative flex justify-center items-center mx-auto w-full px-[10px] md:px-0 h-[100vh] select-none">
        <div className="border-0  relative flex flex-col w-full bg-white outline-none focus:outline-none sm:w-[420px] rounded-[12px]">
          <form
            className="relative flex-auto w-full sm:w-[420px]"
            encType="multipart/form-data"
          >
            <div className="w-full h-[120px] rounded-tl-[12px] rounded-tr-[12px] bg-[#1495F3] relative cursor-pointer">
              <input
                type="file"
                id="avatarCompany"
                name="avatarCompany"
                onChange={uploadAvatar}
                accept="image/*"
                hidden
              />
              <div
                className="w-[120px] h-[120px] rounded-[50%] border-none  bg-[#F5F7FF] absolute left-10 -bottom-[50%]"
                onClick={() =>
                  document.getElementById("avatarCompany")?.click()
                }
              >
                <img
                  src={avatar ? URL.createObjectURL(avatar) : ""}
                  alt=""
                  className="w-full h-full object-cover border-none rounded-[50%]"
                />
              </div>

              <div
                className="w-[120px] h-[120px] border-none rounded-[50%] opacity-60 absolute left-10 -bottom-[50%] flex justify-center items-center"
                onClick={() =>
                  document.getElementById("avatarCompany")?.click()
                }
              >
                <div className="w-[48px] h-[48px] rounded-[50%] bg-[#B5C1FF] flex justify-center items-center">
                  <CameraOutlined className="text-[24px]" />
                </div>
              </div>
            </div>
            <div className="w-full bg-white rounded-bl-[12px] rounded-br-[12px] p-[20px]">
              <div className="w-full">
                <div className="ml-[40%]">
                  <InputAdmin
                    name="fullName"
                    label="Họ và tên"
                    placeholder="Họ và tên nhân sự"
                    onChange={createUserFormik.handleChange}
                    error={
                      createUserFormik.errors.fullName &&
                      createUserFormik.touched.fullName
                        ? createUserFormik.errors.fullName
                        : ""
                    }
                  />
                </div>
                <div className="my-3">
                  <InputAdmin
                    name="email"
                    label="Email"
                    placeholder="Email nhân sự"
                    onChange={createUserFormik.handleChange}
                    error={
                      createUserFormik.errors.email &&
                      createUserFormik.touched.email
                        ? createUserFormik.errors.email
                        : ""
                    }
                  />
                </div>
                <div className="my-3">
                  <InputAdmin
                    name="phoneNumber"
                    label="Số điện thoại"
                    placeholder="Số điện thoại của nhân sự"
                    onChange={createUserFormik.handleChange}
                    error={
                      createUserFormik.errors.phoneNumber &&
                      createUserFormik.touched.phoneNumber
                        ? createUserFormik.errors.phoneNumber
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="w-full flex justify-end items-center">
                <div
                  className="px-9 py-2 border rounded-[12px] text-[12px] cursor-pointer mr-2"
                  onClick={onChangeActive}
                >
                  Hủy
                </div>
                <div
                  className="px-9 py-2 bg-[#1495F3] text-white rounded-[12px] text-[12px] cursor-pointer"
                  onClick={createUserFormik.handleSubmit}
                >
                  Lưu
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateUserModal;
