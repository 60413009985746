import React, { useState } from "react";
import Input from "../../../components/Input";
import { DeleteFilled } from "@ant-design/icons";

function InputAdd(props) {
  const { data, onChangeAddress, onRemoveAddress } = props;
  const [valueInput, setValueInput] = useState(data.value);
  return (
    <div className="w-full flex mb-2">
      <Input
        name={data.id}
        value={valueInput}
        placeholder="Địa chỉ văn phòng"
        onChangeInput={(e) => {
          onChangeAddress({
            id: e.target.name,
            value: e.target.value,
          });
          setValueInput(e.target.value);
        }}
      />
      <div
        className="border border-red-500 px-2 flex justify-center items-center rounded-lg cursor-pointer"
        onClick={() => onRemoveAddress(data.id)}
      >
        <DeleteFilled style={{ color: "red" }} />
      </div>
    </div>
  );
}

export default InputAdd;
