import React, { useState } from "react";
import InputAdmin from "../components/Input";
import TagParam from "../components/Tag/TagParam";

function AddParamBlock({
  name,
  title,
  label,
  parent,
  isCheck,
  placeholder,
  data,
  onChange,
  onChangeAdd,
  onChangeRemoveItem,
}) {
  const [value, setValue] = useState();
  return (
    <div className="w-full bg-white p-[15px] rounded-[12px] mb-4">
      <p className="text-black text-[20px] font-[600]">{title}</p>
      <div className="w-full h-[1px] bg-[#1495F3] my-3"></div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="col-span-1">
          <InputAdmin
            name={name}
            value={value}
            label={label}
            placeholder={placeholder}
            onChange={(e) => setValue(e.target.value)}
          />
          <div
            className="w-[100px] px-4 py-2 mt-3 text-center bg-[#1495F3] rounded-[20px] text-white cursor-pointer"
            onClick={() => onChangeAdd(name, value, parent, isCheck)}
          >
            Thêm
          </div>
        </div>
        <div className="col-span-3 bg-[#F2F7FF] w-full min-h-[80px] p-[10px] border border-gray-1 rounded-lg">
          {data &&
            data.map((item) => (
              <TagParam
                name={item}
                onChangeValue={(value) => onChange(value)}
                onRemoveItem={(value) =>
                  onChangeRemoveItem(name, value, parent)
                }
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default AddParamBlock;
