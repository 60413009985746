import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { changeSkills, selectSkills } from "../../../lib/redux/resumeSlice";
import {
  changeShowBulletPoints,
  selectShowBulletPoints,
  selectThemeColor,
} from "../../../lib/redux/settingsSlice";
import { Form } from "./Form";
import { FeaturedSkillInput } from "./Form/FeaturedSkillInput";
import { BulletListIconButton } from "./Form/IconButton";
import { BulletListTextarea, InputGroupWrapper } from "./Form/InputGroub";

export const SkillsForm = () => {
  const skills = useAppSelector(selectSkills);
  const dispatch = useAppDispatch();
  const { featuredSkills, descriptions } = skills;
  const form = "skills";
  const showBulletPoints = useAppSelector(selectShowBulletPoints(form));
  const themeColor = useAppSelector(selectThemeColor) || "#38bdf8";

  const handleSkillsChange = (field, value) => {
    dispatch(changeSkills({ field, value }));
  };
  const handleFeaturedSkillsChange = (idx, skill, rating) => {
    dispatch(changeSkills({ field: "featuredSkills", idx, skill, rating }));
  };
  const handleShowBulletPoints = (value) => {
    dispatch(changeShowBulletPoints({ field: form, value }));
  };

  return (
    <Form form={form}>
      <div className="col-span-full grid grid-cols-6 gap-3">
        <div className="relative col-span-full">
          <BulletListTextarea
            label="Danh sách kĩ năng"
            labelClassName="col-span-full"
            name="descriptions"
            placeholder="Word, PowerPoint"
            value={descriptions}
            onChange={handleSkillsChange}
            showBulletPoints={showBulletPoints}
          />
          <div className="absolute left-[8.5rem] top-[0.07rem]">
            <BulletListIconButton
              showBulletPoints={showBulletPoints}
              onClick={handleShowBulletPoints}
            />
          </div>
        </div>
        <div className="col-span-full mb-4 mt-6 border-t-2 border-dotted border-gray-200" />
        <InputGroupWrapper
          label="Các kĩ năng (nếu có)"
          className="col-span-full"
        >
          <p className="mt-2 text-sm font-normal text-gray-600">
            Liệt kê các kĩ năng của bản thân
          </p>
        </InputGroupWrapper>

        {featuredSkills.map(({ skill, rating }, idx) => (
          <FeaturedSkillInput
            key={idx}
            className="col-span-3"
            skill={skill}
            rating={rating}
            setSkillRating={(newSkill, newRating) => {
              handleFeaturedSkillsChange(idx, newSkill, newRating);
            }}
            placeholder={`Kĩ năng ${idx + 1}`}
            circleColor={themeColor}
          />
        ))}
      </div>
    </Form>
  );
};
