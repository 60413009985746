import React, { useEffect, useRef, useState } from "react";
import { cx } from "../../../lib/cx";

function Textarea({
  id,
  name,
  size,
  value,
  onChangeInput,
  className,
  placeholder,
  type,
  rows,
  error,
  style,
}) {
  const [content, setContent] = useState();
  const textareaRef = useAutosizeTextareaHeight({ type, value });

  const incrSize = size || 0;

  useEffect(() => {
    setContent(value);
  }, [value]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    onChangeInput(name, value);
    setContent(value);
  };
  return (
    <textarea
      ref={textareaRef}
      id={id}
      name={name}
      value={content}
      onChange={handleInputChange}
      placeholder={placeholder}
      rows={rows || 1}
      className={cx(
        `w-full border-b-[1px] focus:outline-none px-3 py-2 bg-[#F4F9FF] text-[14px] text-black rounded-md`,
        error && "border border-dashed border-red-500",
        className
      )}
      autoComplete="off"
      style={{
        fontSize: `${14 + incrSize}px`,
        ...style,
        resize: "none",
      }}
    />
  );
}

const useAutosizeTextareaHeight = ({ type, value }) => {
  const textareaRef = useRef(null);
  const resizing = useRef(false);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      adjustTextareaHeight(textarea);

      try {
        const resizeObserver = new ResizeObserver(() => {
          if (!resizing.current) {
            adjustTextareaHeight(textarea);
          }
        });

        resizeObserver.observe(textarea);

        return () => {
          resizeObserver.disconnect();
        };
      } catch (error) {
        console.log(new Error(error));
      }
    }
  }, [value, type, textareaRef]);

  const adjustTextareaHeight = (element) => {
    try {
      if (element) {
        resizing.current = true;
        element.style.height = "auto";
        if (
          element.scrollHeight > 0 &&
          element.scrollHeight !== element.clientHeight
        ) {
          element.style.height = element.scrollHeight + "px";
        }
        resizing.current = false;
      }
    } catch (error) {
      console.log(new Error(error));
    }
  };

  return textareaRef;
};

export default Textarea;
