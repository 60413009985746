import React, { useState } from "react";
import { cx } from "../../../lib/cx";
import { EyeFilled } from "@ant-design/icons";
import { Pagination } from "antd";
import { changePagination } from "../../../lib/theme/config";
import { formatVND } from "../../../utils";
import RecruiterHistoryDetailModal from "../../components/Modal/RecruiterHistoryDetailModal";
import { DATA_PAYMENT_FAKE } from "../../../utils/constants";

function RecruiterHistory({
  search,
  limit = 10,
  onChangePage,
  totalPage = 10,
}) {
  const [toggleDetail, setToggleDetail] = useState(false);
  const [payementData, setPaymentData] = useState();
  const fakeData = DATA_PAYMENT_FAKE;

  const data = [
    {
      id: 1,
      name: "Nguyễn Văn A",
      combo: "R01",
      createdAt: "22-09-2023",
      successAt: "24-09-2023",
      total: formatVND(10000000),
      note: "",
      status: "PROCESSING",
      detail: fakeData.find((c) => c.code === "R01"),
    },
    {
      id: 2,
      name: "Nguyễn Văn A",
      combo: "R02",
      createdAt: "22-09-2023",
      successAt: "24-09-2023",
      total: formatVND(7000000),
      note: "",
      status: "ERROR",
      detail: fakeData.find((c) => c.code === "R02"),
    },
    {
      id: 3,
      name: "Nguyễn Văn A",
      combo: "R03",
      createdAt: "22-09-2023",
      successAt: "24-09-2023",
      total: formatVND(9500000),
      note: "",
      status: "SUCCESS",
      detail: fakeData.find((c) => c.code === "R03"),
    },
  ];

  return (
    <>
      {data.length > 0 ? (
        <>
          <div className="overflow-x-auto pb-[10px]">
            <table className="w-full min-w-[1517px]">
              <thead>
                <tr>
                  {[
                    "STT",
                    "Tên khách hàng",
                    "Gói dịch vụ",
                    "Ngày đăng ký",
                    "Ngày thanh toán",
                    "Số tiền",
                    "Ghi chú",
                    "Trạng thái",
                    "",
                  ].map((item, index) => (
                    <th
                      key={item + index + "title"}
                      className="px-4 py-1 text-[14px] uppercase text-center bg-[#B7E1FF] rounded-[12px] border-[1px] md:border-[4px] border-[#F4F9FF]"
                    >
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.length > 0 &&
                  data.map((payment, idx) => (
                    <tr
                      key={"recruiter" + idx + "tab"}
                      className="bg-white hover:bg-gray-1 cursor-pointer"
                      onClick={() => {
                        setPaymentData(payment);
                        setToggleDetail(true);
                      }}
                    >
                      {[
                        (Number(search?.page) || 1) * limit + idx - limit + 1,
                        payment.name,
                        payment.combo,
                        payment.createdAt,
                        payment.successAt,
                        payment.total,
                        payment.note,
                      ].map((item, index) => (
                        <td
                          key={"item" + idx + index + "tab"}
                          className={cx(
                            "py-2 px-4 rounded-[12px] border-[1px] md:border-[6px] border-[#F4F9FF] text-center",
                            index === 1 && "!text-left",
                            index === 2 && "!text-right",
                            index === 3 && "!text-right",
                            index === 4 && "!text-right"
                          )}
                        >
                          {item}
                        </td>
                      ))}
                      <td
                        className={cx(
                          "py-2 px-4 rounded-[12px] border-[1px] md:border-[6px] border-[#F4F9FF] w-[130px]"
                        )}
                      >
                        <div
                          className={cx(
                            "text-[12px] rounded-[12px] text-white text-center",
                            payment.status === "SUCCESS" && "bg-green-500",
                            payment.status === "ERROR" && "bg-red-500",
                            payment.status === "PROCESSING" && "bg-blue-500"
                          )}
                        >
                          {payment.status === "SUCCESS"
                            ? "Thành công"
                            : payment.status === "PROCESSING"
                            ? "Đang xử lý"
                            : "Lỗi"}
                        </div>
                      </td>
                      <td
                        className={cx(
                          "py-2 px-4 rounded-[12px] border-[1px] md:border-[6px] border-[#F4F9FF] w-[100px]"
                        )}
                      >
                        <div
                          className={cx(
                            "text-[12px] bg-blue-400 rounded-[12px] text-white text-center"
                          )}
                        >
                          <EyeFilled /> Chi tiết
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="w-full my-4 flex justify-center items-center">
            {totalPage > 0 && (
              <Pagination
                defaultCurrent={search?.page ? Number(search?.page) : 1}
                total={totalPage}
                pageSize={limit}
                onChange={onChangePage}
                itemRender={changePagination}
              />
            )}
          </div>
        </>
      ) : (
        <p>Khách hàng chưa đăng ký dịch vụ trên hệ thống</p>
      )}
      <RecruiterHistoryDetailModal
        data={payementData}
        active={toggleDetail}
        onChangeActive={() => setToggleDetail(!toggleDetail)}
      />
    </>
  );
}

export default RecruiterHistory;
