import React from "react";
import { cx } from "../../../lib/cx";

function TagFontSize({ name, data, active, onChangeValue }) {
  return (
    <div className="flex justify-end items-center space-x-2">
      {data &&
        data.map((item, idx) => (
          <div
            key={"tag-" + idx + "-font"}
            className={cx(
              "rounded-[50%] text-white flex justify-center items-center cursor-pointer",
              active === item ? "bg-[#333]" : "bg-gray"
            )}
            style={{
              width: `${Number(item) + 24}px`,
              height: `${Number(item) + 24}px`,
              fontSize: `${Number(item) + 10}px`,
            }}
            onClick={() => {
              onChangeValue(name, item);
            }}
          >
            A
          </div>
        ))}
    </div>
  );
}

export default TagFontSize;
