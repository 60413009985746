import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import {
  changeProjects,
  selectProjects,
} from "../../../lib/redux/resumeSlice";
import { Form, FormSection } from "./Form";
import { BulletListTextarea, Input } from "./Form/InputGroub";

export const ProjectsForm = () => {
  const projects = useAppSelector(selectProjects);
  const dispatch = useAppDispatch();
  const showDelete = projects.length > 1;

  return (
    <Form form="projects" addButtonText="Thêm mới">
      {projects.map(({ project, date, descriptions }, idx) => {
        const handleProjectChange = (...[field, value]) => {
          dispatch(changeProjects({ idx, field, value }));
        };
        const showMoveUp = idx !== 0;
        const showMoveDown = idx !== projects.length - 1;

        return (
          <FormSection
            key={idx}
            form="projects"
            idx={idx}
            showMoveUp={showMoveUp}
            showMoveDown={showMoveDown}
            showDelete={showDelete}
            deleteButtonTooltipText={"Xóa"}
          >
            <Input
              name="project"
              label="Tên dự án"
              placeholder="Nhập tên dự án"
              value={project}
              onChange={handleProjectChange}
              labelClassName="col-span-4"
            />
            <Input
              name="date"
              label="Thời gian"
              placeholder="03/2023"
              value={date}
              onChange={handleProjectChange}
              labelClassName="col-span-2"
            />
            <BulletListTextarea
              name="descriptions"
              label="Mô tả dự án"
              placeholder="Mô tả dự án"
              value={descriptions}
              onChange={handleProjectChange}
              labelClassName="col-span-full"
            />
          </FormSection>
        );
      })}
    </Form>
  );
};
