import React from "react";
import CardShowLoading from "./CardShowLoading";

function CardSlideLoading() {
  return (
    <div className="w-full h-[379px] bg-white rounded-[12px] p-[16px]">
      <div className="w-full h-[87px] flex animate-pulse">
        <div className="w-[87px] h-[87px] mr-2">
          <div className="w-full h-full rounded-[12px] bg-gray-1"></div>
        </div>
        <div className="w-[240px] flex justify-start items-center">
          <div className="w-full h-[24px] bg-gray-1"></div>
        </div>
      </div>
      <div className="my-[15px] animate-pulse">
        <CardShowLoading />
      </div>
      {/* <div className="w-full h-[12px] flex justify-center items-center">
        <div className="w-[12px] h-[12px] rounded-[50%] bg-[#B5C1FF] mx-[8px]"></div>
        <div className="w-[12px] h-[12px] rounded-[50%] bg-[#1495F3]"></div>
      </div> */}
      <div className="w-full h-[72px] flex justify-center items-center cursor-pointer animate-pulse">
        <div className="w-full h-[51px] bg-gray-1 rounded-[12px] flex justify-center items-center "></div>
      </div>
    </div>
  );
}

export default CardSlideLoading;
