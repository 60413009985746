import React from "react";
import { EditOutlined, EyeFilled } from "@ant-design/icons";

function CardAds({ data, status, onHandleDetail, isBlock, onHandleEdit }) {
  return (
    <div className="w-full h-[260px] bg-white rounded-[26px] relative">
      <div className="w-full h-full">
        <img
          src={data?.image}
          alt=""
          className="w-full max-h-full object-cover rounded-[26px]"
        />
      </div>
      <div className="absolute top-0 left-0">
        <div className="w-full h-full relative">
          <div className="w-full h-[250px] flex justify-between items-end sm:space-x-2 pt-8 pl-2 opacity-80">
            <div className="px-3 py-1 bg-[#1495F3] rounded-[20px] cursor-pointer text-[14px] text-white">
              <p>Thứ tự hiển thị: {data?.position}</p>
              <p>Ngày bắt đầu: {data?.startTime}</p>
              <p>Ngày kết thúc: {data?.endTime}</p>
              <p>
                Trạng thái:{" "}
                <span className="font-[500] italic">
                  {status === "INPROGRESS"
                    ? "Chưa phê duyệt"
                    : status === "ACTIVE"
                    ? "Đã phê duyệt"
                    : "Đã từ chối"}
                </span>
              </p>
            </div>
            <div className="space-y-1">
              {isBlock && (
                <div
                  className="w-[100px] px-3 py-1 bg-[#1495F3] flex justify-center space-x-1 rounded-[20px] cursor-pointer text-white"
                  onClick={onHandleEdit}
                >
                  <EditOutlined />
                  <p className="text-[12px]">Chỉnh sửa</p>
                </div>
              )}
              <div
                className="w-[100px] px-3 py-1 bg-[#1495F3] flex justify-center space-x-1 rounded-[20px] cursor-pointer text-white"
                onClick={onHandleDetail}
              >
                <EyeFilled />
                <p className="text-[12px]">Chi tiết</p>
              </div>
            </div>
            <div className="sm:w-[200px]"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardAds;
