/* eslint-disable import/no-anonymous-default-export */
import queryString from "query-string";
import {
  applyCV,
  candidates,
  getAllJdApplyByUserId,
  getCvByUserId,
  getImage,
  getJD,
  getOptions,
  getRecruiterDetail,
  updateCandidate,
  uploadCvCandidate,
  getAppliedCv,
} from "./PORT";
import api from "./api";

class UserService {
  constructor() {
    this.api = api;
  }

  checkUser(user, page) {
    return this.api.post(page, user);
  }

  getUsers(page) {
    return this.api.get(page);
  }

  createUser(user, page) {
    return this.api.post(page, user);
  }

  sendData(user, page) {
    return this.api.post(page, user);
  }

  getUserById(userId, page) {
    return this.api.get(page + "/" + userId);
  }

  updateUser(user, userId, page) {
    return this.api.put(page + "/" + userId, user);
  }

  deleteUser(userId, page) {
    return this.api.delete(page + "/" + userId);
  }

  getListJD(validate, params, endpoint) {
    //  lấy danh sách công việc
    return this.api.get(endpoint + "/" + validate, {
      params: params,
    });
  }

  getRecruiterValidate(validate, params) {
    return this.api.get("recruiter/validate/" + validate, {
      params: params,
    });
  }

  getInfoRecruiter(id) {
    return this.api.get(`recruiter/find/${id}`);
  }

  getListCS(activate, params) {
    // lấy danh sách khách hàng
    return this.api.get("recruiter/active/" + activate, {
      params: params,
    });
  }

  verifyJD(endpoint, data) {
    return this.api.put(endpoint, data);
  }

  getAmountJD(validate, endpoint) {
    return this.api.get(endpoint + "/" + validate);
  }

  verifyAccount(endpoint, data) {
    return this.api.put(endpoint, data);
  }

  verifyPosting(endpoint, data) {
    return this.api.put(endpoint, data);
  }

  getAmountCustomers(endpoint, activate) {
    return this.api.get(endpoint + "/" + activate);
  }

  getDescriptionBusiness(endpoint, email) {
    // lấy thông tin doanh nghiệp bằng email
    return this.api.get(endpoint + "/" + email);
  }

  getListLatestJD(validate, endpoint) {
    //  lấy danh sách 5 công việc mới nhất
    return this.api.get(endpoint + "/" + validate, {
      params: { offset: 0, limit: 18 },
    });
  }

  updateBusinessInfo(endpoint, mail, data) {
    // cập nhật thông tin nhà tuyển dụng
    return this.api.put(endpoint + "/" + mail, data);
  }

  getOneJDByID(endpoint, id) {
    //lấy thông tin tuyển dụng qua ID
    return this.api.get(endpoint + "/" + id);
  }

  getOneRCUpdateByMail(endpoint, email) {
    //lấy thông tin cập nhật nhà tuyển tuyển dụng qua email
    return this.api.get(endpoint + "/" + email);
  }

  getOneRecruiterByID(endpoint, id) {
    //lấy thông tin tuyển dụng qua ID
    return this.api.get(endpoint + "/" + id);
  }

  getListJDByRecruiterID(
    endpoint,
    recruiterID,
    check,
    offset,
    limit,
    status,
    jobName
  ) {
    //lấy danh sách tin tuyển đăng
    return this.api.get(
      endpoint + "/" + recruiterID + "/" + check + "/" + status,
      {
        params: { offset: offset, limit: limit, jobName: jobName },
      }
    );
  }

  getTotalJDByRecruiterID(endpoint, recruiterID, validate) {
    //
    return this.api.get(endpoint + "/" + validate + "/" + recruiterID);
  }

  getRecruiterByEmail(endpoint, recruiterID) {
    // lấy thông tin doanh nghiệp bằng ID
    return this.api.get(endpoint + "/" + recruiterID);
  }

  uploadImage(endpoint, dataInfo, file) {
    // đẩy ảnh lên server
    return this.api.post(
      `${endpoint}?${queryString.stringify(dataInfo)}`,
      file,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  getImage(endpoint, data) {
    return this.api.post(endpoint, data);
  }

  countJDbyCompanyName(endpoint, data) {
    return this.api.get(endpoint);
  }

  countJDbyJobname(endpoint, data) {
    return this.api.get(endpoint);
  }

  stopRecruit(endpoint, data) {
    return this.api.put(endpoint, data);
  }

  changePassword(endpoint, data) {
    return this.api.put(endpoint, data);
  }

  updateCandidate(data) {
    return this.api.put(`${updateCandidate}`, data);
  }

  getAllOption(field) {
    return this.api.post(getOptions, {
      type: field,
    });
  }

  getInfoPersional(id, params) {
    return this.api.get(candidates + `/${id}${params || ""}`);
  }
  getOption(endpoint, type) {
    return this.api.post(endpoint, { type: type });
  }

  getNewJD(offset, limit) {
    return this.api.get(getJD + `/true?offset=${offset}&limit=${limit}`);
  }

  getImageByName(data) {
    return this.api.post(getImage, { filename: data });
  }

  getImageURLByName(data) {
    return this.api.post("files/get-file", { filename: data });
  }

  getCountJDVerifid() {
    return this.api.get(getJD + "/admin/count/true");
  }

  getFilterJD(offset, limit, data) {
    return this.api.post(
      getJD + `/filter/jd?offset=${offset}&limit=${limit}`,
      data
    );
  }

  getAllCvByUserId(id) {
    return this.api.get(getCvByUserId + `/${id}`);
  }

  applyCvInJob(data) {
    return this.api.put(applyCV, data);
  }

  getAllJdApply(params) {
    return this.api.get(
      getAllJdApplyByUserId + `/${params.id}?offset=${params.offset}&limit=10`
    );
  }

  uploadCv(params, data) {
    return this.api.post(uploadCvCandidate + `?${params}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getDescriptionRecuiter(id) {
    return this.api.get(getRecruiterDetail + `/${id}`);
  }

  getListAppliedCv(jdId, params) {
    return this.api.get(
      getAppliedCv + `/${jdId}?offset=${params.offset}&limit=5`
    );
  }

  getAllJobDetail(params, data) {
    return this.api.post(`job-description/find/list-jd?${params}`, data);
  }

  updateJDStatus(jdId, data) {
    return this.api.put(getJD + `/${jdId}`, data);
  }

  adminGetAllOption() {
    return this.api.post("admin/all-option");
  }

  createCV(data) {
    return this.api.post("curriculum-vitae/create", data);
  }

  saveJD(candidateId, jdId) {
    return this.api.post(`candidates/save-jd?id=${candidateId}`, {
      jdId: jdId,
    });
  }

  saveCV(recruiterId, cvName) {
    return this.api.post(`recruiter/save-jd?id=${recruiterId}`, {
      cvName: cvName,
    });
  }

  uploadAvatarCv(data) {
    return this.api.post("files/uploadcv-avatar", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  updateJdById(id, data) {
    return this.api.put(`job-description/${id}`, data);
  }

  candidateRemoveCv(data) {
    return this.api.put("candidates/delete-cv", data);
  }

  getCvCreateByName(data) {
    return this.api.post(`curriculum-vitae/findCv`, data);
  }

  updateCvCreator(data) {
    return this.api.put("curriculum-vitae/update", data);
  }

  createCVManage(data) {
    return this.api.post("curriculum-vitae/create-cv-management", data);
  }

  updateCVManage(data) {
    return this.api.put("curriculum-vitae/update-cv-management", data);
  }

  getAllCvManage(params) {
    return this.api.get(`curriculum-vitae/filter-cv?${params}`);
  }

  getAllCvMain(params) {
    return this.api.get(`curriculum-vitae?${params}`);
  }

  getAllCvShow(params) {
    return this.api.get(`curriculum-vitae/get-by-status?${params}`);
  }

  updateCvByImage(data) {
    return this.api.put(`curriculum-vitae`, data);
  }

  uploadCvManage(data) {
    return this.api.post("files/upload-cv-image", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  autoRegisterAccount(data) {
    return this.api.post("candidates/auto/register", data);
  }

  updateMainCV(data) {
    return this.api.put("candidates/change-main-cv", data);
  }

  updateSavedJd(data) {
    return this.api.post("candidates/remove-saved-jd", data);
  }

  getNotification(params) {
    return this.api.get(`notification/get-all-noti?${params}`);
  }

  updateReadOneNotification(data) {
    return this.api.put("notification/read-one-noti", data);
  }

  updateReadAllNotification(data) {
    return this.api.put("notification/read-all-noti", data);
  }
}

export default new UserService();
