import { Image } from "antd";
import React from "react";

const CardItem = (props) => {
  return (
    <div
      className={`w-full h-[264px] rounded-[22px] p-[18px] cursor-pointer`}
      style={{ background: `${props.title ? "white" : "#DDE4FF"}` }}
      onClick={props.onClick}
    >
      {props.title ? (
        <div className="w-full h-[120px] flex justify-center items-center">
          <div className="w-[90px] h-[90px] rounded-[9px] flex justify-center items-center">
            <img
              src={props.logo}
              alt=""
              className="max-w-full max-h-full object-cover"
            />
          </div>
        </div>
      ) : (
        <div className="w-full h-[120px] rounded-[9px]  flex justify-center items-center">
          <img
            src={props.logo}
            alt=""
            className="max-w-full max-h-full object-cover rounded-[9px]"
          />
        </div>
      )}

      <div className="w-full">
        <p className="text-[18px] font-[600] text-center text-black">
          {props.title}
        </p>
        <div className="w-full h-[24px] flex justify-center mt-[18px]">
          <div className="w-[24px] h-[24px]">
            <img src={"/asserts/bag.png"} alt="" className="object-cover" />
          </div>

          <div className="flex justify-start items-center">
            <p
              className="ml-[10px]"
              style={{
                fontSize: `${props.title ? 14 : 16}px`,
                color: `${props.title ? "#8D9092" : "black"}`,
              }}
            >
              {props.number} vị trí đang tuyển
            </p>
          </div>
        </div>
      </div>
      <a
        href={props.link || ""}
        className="w-full flex flex-col justify-center items-center"
        style={{ marginTop: `${props.title ? "10px" : "20px"}` }}
      >
        <p className="ml-[10px] text-[#8D9092] text-[14px]">Xem thêm</p>
        <Image
          src={"/asserts/arrow.png"}
          alt="Image default"
          preview={false}
          width={18}
          height={18}
          className="object-cover"
        />
      </a>
    </div>
  );
};

export default CardItem;
