import React from "react";
import { useAppSelector } from "../../redux/hooks";
import { selectSetting } from "../../redux/slice/setting";
import LeftComponent from "./LeftComponent";
import RightComponent from "./RightComponent";
import HelpDecriptionComponent from "../../components/SubComponent/HelpDecriptionComponent";

function ContentComponent() {
  const setting = useAppSelector(selectSetting);
  return (
    <div className="px-[10px] md:px-0 flex space-x-4">
      <form
        style={{
          width: "793px",
          minHeight: "1112px",
          background: "white",
          position: "relative",
          fontFamily: `${setting.fontFamily}`,
          fontSize: `${setting.fontSize}`,
        }}
        className="shadow-md"
      >
        <div className="flex">
          <div
            className="w-[260px] min-h-[1112px] pb-4"
            style={{
              background: `${setting.themeColor}`,
            }}
          >
            <LeftComponent />
          </div>
          <div className="w-[533px] h-full p-3">
            <RightComponent />
          </div>
        </div>
      </form>
      <HelpDecriptionComponent />
    </div>
  );
}

export default ContentComponent;
