import { EllipsisOutlined } from "@ant-design/icons";
import React from "react";

function Avatar(props) {
  const { avatar, isMore, className, style } = props;
  return (
    <div
      className={`w-[30px] h-[30px] rounded-[50%] ${className}`}
      style={style}
    >
      {!avatar ? (
        <div className="w-full h-full rounded-[50%] bg-[#ccc] text-[24px] text-white flex justify-center items-center">
          {isMore ? <EllipsisOutlined /> : <>+</>}
        </div>
      ) : (
        <img
          src={avatar}
          alt=""
          className="w-full h-full rounded-[50%] object-cover"
        />
      )}
    </div>
  );
}

export default Avatar;
