import React from "react";
import { Provider } from "react-redux";
import ResumeForm from "./ResumeForm";
import Resume  from "./Resume";
import { store } from "../../lib/redux/store";

function CvAdvanced() {
  return (
    <Provider store={store}>
      <main className="w-full mx-auto lg:max-w-[1336px] overflow-hidden bg-gray-50 my-[80px]">
        <div className="relative">
          <h2 className="text-[28px] mb-[40px]">Tạo cv nâng cao</h2>
          <div className="grid grid-cols-3 md:grid-cols-6">
            <div className="col-span-3">
              <ResumeForm />
            </div>
            <div className="col-span-3">
              <Resume />
            </div>
          </div>
        </div>
      </main>
    </Provider>
  );
}

export default CvAdvanced;
