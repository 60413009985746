import React, { useState } from "react";

function Dropbox({ id, onChange }) {
  const [file, setFile] = useState();
  const [error, setError] = useState("");

  const onChangeInput = (e) => {
    const data = e.target.files[0];
    if (data) {
      const type = data.type.split("/")[1];
      if (type !== "pdf") {
        setError("* Lỗi file! Vui lòng chọn file có định dạng .pdf");
        return;
      }
      setFile(data);
      onChange(data);
    }
  };

  const handleClick = () => {
    setError("");
    setFile();
    onChange();
    document.getElementById(id)?.click();
  };

  return (
    <form>
      <input
        id={id}
        type="file"
        accept="application/pdf"
        onChange={onChangeInput}
        hidden
      />
      <div className="flex justify-start items-center">
        <div
          className="w-[100px] px-4 py-1 bg-[#ccc] border rounded-md cursor-pointer"
          onClick={handleClick}
        >
          Chọn file
        </div>
        <p className="ml-4">{file?.name}</p>
      </div>
      {error && <p className="text-[red] italic text-[14px] my-2">{error}</p>}
    </form>
  );
}

export default Dropbox;
