import React, { useState } from "react";
import SelectItem from "../../components/SelectItem";
import { DatePicker } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { clearEmptyItem } from "../../utils";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

function SearchForm(props) {
  const { onChangeValue, isHiddenDate } = props;
  const location = useLocation();
  const history = useHistory();
  const search = queryString.parse(location.search);
  const [value, setValue] = useState(search?.jobName || "");

  const statusData = isHiddenDate
    ? ["Inprogress", "Active"]
    : ["Active", "Inactive"];

  const onChangeParams = (value) => {
    let params = queryString.parse(location.search);
    params = clearEmptyItem({ ...params, ...value });
    history.replace(location.pathname + "?" + queryString.stringify(params));
    onChangeValue(params);
  };

  return (
    <div className="w-full min-h-[34px] flex px-4 py-1 bg-white rounded-[12px]">
      <div
        className={` ${
          isHiddenDate ? "w-[70%]" : "w-[45%]"
        } flex justify-start items-center`}
      >
        <div className="w-[14px] h-[14px]">
          <img
            src={"/asserts/icons/search.png"}
            alt="icon search"
            className="object-cover"
          />
        </div>
        <input
          type="text"
          name="jobName"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="w-full focus:outline-none ml-[10px] text-[14px] border-r-[1px] border-r-gray"
          placeholder="Tìm kiếm"
        />
      </div>

      {!isHiddenDate && (
        <div className="w-[25%] flex justify-center items-center">
          <div className="w-full h-full">
            <RangePicker
              style={{ width: "100%" }}
              bordered={false}
              onChange={(_, stday) => {
                onChangeParams({
                  startDate: stday[0],
                  endDate: stday[1],
                });
              }}
              defaultValue={
                search["startDate"] &&
                search["endDate"] && [
                  dayjs(search["startDate"], "YYYY-MM-DD"),
                  dayjs(search["endDate"], "YYYY-MM-DD"),
                ]
              }
              //
              className="text-black mt-2"
              format={"YYYY-MM-DD"}
              placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
            />
          </div>
          <div className="w-[1px] h-[20px] border-r-[1px] border-r-gray"></div>
        </div>
      )}

      <div className="w-[20%]">
        <div className="block lg:hidden">
          <SelectItem
            data={statusData}
            name="status"
            valueInput={search?.status}
            placeholder="Trạng thái"
            onChangeValue={(e) =>
              onChangeParams({ [e.target.name]: e.target.value.toUpperCase() })
            }
            isHidden={true}
          />
        </div>

        <div className="hidden lg:block">
          <SelectItem
            data={statusData}
            name="status"
            valueInput={search?.status}
            placeholder="Trạng thái"
            onChangeValue={(e) =>
              onChangeParams({ [e.target.name]: e.target.value.toUpperCase() })
            }
          />
        </div>
      </div>
      <div className="w-[10%] flex justify-center items-center">
        <div
          className="w-full text-center bg-[#1495F3] rounded-[12px] px-3 py-2 text-white cursor-pointer hidden md:block"
          onClick={() => onChangeParams({ jobName: value })}
        >
          Tìm kiếm
        </div>
        <div
          className="w-[14px] h-[14px] block md:hidden cursor-pointer"
          onClick={() => onChangeParams({ jobName: value })}
        >
          <img
            src={"/asserts/icons/search.png"}
            alt="icon search"
            className="object-cover"
          />
        </div>
      </div>
    </div>
  );
}

export default SearchForm;
