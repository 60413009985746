import React from "react";
import { cx } from "../../../lib/cx";

function CopyRightComponent({ className }) {
  return (
    <p
      className={cx(
        "absolute bottom-1 right-[14px] text-gray  text-[12px]",
        className
      )}
    >
      ©nganhangnhanluc.com
    </p>
  );
}

export default CopyRightComponent;
