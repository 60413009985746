import React, { useEffect, useState } from "react";
import { routes } from "../../PathRouting";
import { useLocation } from "react-router-dom";
import { getId, getImageUrl } from "../../../utils";
import ShowCandidateCv from "../../../components/Modals/ShowCandidateCv";
import UserService from "../../../services/UserService";
import dayjs from "dayjs";
import Breadcrumb from "../../../components/Tag/Breadcrumb";
import { useTheme } from "../../../lib/theme/useTheme";
import { cx } from "../../../lib/cx";
import AllCvComponent from "../../../admin/Post/AllCvComponent";
import { getJDbyID } from "../../../services/PORT";

function AppliedJD() {
  const { theme } = useTheme();
  const [listCVApplied, setListCvApplied] = useState();
  const [jobName, setJobName] = useState("");
  const [active, setActive] = useState(false);
  const [cvShow, setCvShow] = useState();

  const location = useLocation();

  useEffect(() => {
    if (!listCVApplied) getListCvData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCVApplied]);

  const getListCvData = () => {
    const jdId = getId(location.pathname);
    UserService.getOneRecruiterByID(getJDbyID, jdId)
      .then(async (res) => {
        const data = res.data.data;
        setJobName(data.jobName);
        const listCvAppled = data.cvApplyList;
        let listImg = listCvAppled.map((item) => item.cvImage);
        listImg = await UserService.getImageByName(listImg);
        setListCvApplied(
          listCvAppled.map((cv) => ({
            ...cv,
            name: cv.cvName,
            image: getImageUrl(listImg.data.data, cv.cvImage),
            createAt: dayjs(new Date(cv.applyAt)).format("DD-MM-YYYY"),
          }))
        );
      })
      .catch((error) => console.error(error));
  };

  return (
    <div
      className={cx(
        "mx-auto lg:max-w-[1175px] min-h-[100vh]",
        theme?.background?.gradient
      )}
    >
      <div className="px-4">
        <Breadcrumb
          data={[
            { name: "Dashboard", path: routes.recruiterDashboard.path },
            {
              name: "Quản lý bài tuyển dụng",
              path: routes.recruiterManageJd.path,
            },
            {
              name: jobName,
              path:
                routes.detailJDs.parentPath + `/${getId(location.pathname)}`,
            },
            {
              name: "Xem tất cả CV",
            },
          ]}
          isCheck
        />

        <div className="w-full bg-white rounded-[20px] p-[20px] mb-[80px]">
          {listCVApplied && listCVApplied.length > 0 ? (
            <AllCvComponent
              data={listCVApplied}
              jdId={getId(location.pathname)}
              jdName={jobName}
              onChangeCv={(value) => {
                setCvShow(value);
                setActive(!active);
              }}
            />
          ) : (
            <p className="text-black py-[40px]">Không có cv ứng tuyển</p>
          )}
        </div>
      </div>
      <ShowCandidateCv
        active={active}
        onChangeActive={() => setActive(!active)}
        data={cvShow}
      />
    </div>
  );
}

export default AppliedJD;
