import React from "react";
import { useAppSelector } from "../../redux/hooks";
import {
  selectFormOrder,
  selectFormToShow,
  selectSetting,
} from "../../redux/slice/setting";
import ObjectiveComponent from "../../components/SubComponent/ObjectiveComponent";
import TrippleComponent from "../../components/SubComponent/TrippleComponent";
import SubDoubleComponent from "../../components/SubComponent/SubDoubleComponent";

function LeftComponent() {
  const { themeColor } = useAppSelector(selectSetting);
  const { left } = useAppSelector(selectFormOrder);
  const formToShow = useAppSelector(selectFormToShow);

  const formTypeToComponent = {
    objective: (
      <ObjectiveComponent form="objective" titleColor={themeColor} border />
    ),
    educations: (
      <TrippleComponent
        form="educations"
        listTitle={{
          title: "Chuyên ngành",
          label: "Tên trường học",
          description: "Mô tả quá trình học tập hoặc thành tích cá nhân",
        }}
        titleColor={themeColor}
        border="w-[25%]"
      />
    ),
    workExperiences: (
      <TrippleComponent
        form="workExperiences"
        listTitle={{
          title: "Vị trí công việc",
          label: "Tên công ty",
          description: "Mô tả kinh nghiệm của bạn",
        }}
        titleColor={themeColor}
        border="w-[85%]"
      />
    ),
    activeties: (
      <TrippleComponent
        form="activeties"
        listTitle={{
          title: "Tên hoạt động",
          label: "Địa điểm",
          description: "Mô tả danh hiệu hoạt động",
        }}
        titleColor={themeColor}
        border="w-[30%]"
      />
    ),
    honors_and_awards: (
      <SubDoubleComponent
        form="honors_and_awards"
        listTitle={{ title: "Giải thưởng", description: "Thời gian" }}
        other="text-black placeholder:text-gray"
        position="other"
        titleColor={themeColor}
        border="w-[80%]"
      />
    ),
    cetifications: (
      <SubDoubleComponent
        form="cetifications"
        listTitle={{ title: "Tên chứng chỉ", description: "Thời gian" }}
        other="text-black placeholder:text-gray"
        position="other"
        titleColor={themeColor}
        border="w-[25%]"
      />
    ),
  };
  return (
    <div>
      {left &&
        left.map((field) => {
          if (formToShow[field]) {
            const component = formTypeToComponent[field];
            return <React.Fragment>{component}</React.Fragment>;
          }
          return null;
        })}
    </div>
  );
}

export default LeftComponent;
