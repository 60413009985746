import { CameraOutlined, LoadingOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import InputAdmin from "../Input";
import { useFormik } from "formik";
import AdminService from "../../../services/AdminService";
import queryString from "query-string";
import dayjs from "dayjs";
import { createCompanySchema } from "../../../utils/validations";
import { useAuth } from "../../Auth/useAuth";
import DeleteCompanyModal from "./DeleteCompanyModal";

function CreateCompany(props) {
  const { admin } = useAuth();
  const { active, onChangeActive, dataUpdate } = props;
  const [avatar, setAvatar] = useState("");
  const [checkAvatar, setCheckAvatar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeDelete, setActiveDelete] = useState(false);

  const createCompanyFormik = useFormik({
    initialValues: {
      name: dataUpdate?.name || "",
      address: dataUpdate?.address || "",
      note: dataUpdate?.note || "",
    },
    validationSchema: createCompanySchema,
    onSubmit: (values) => {
      if (!avatar && !dataUpdate) {
        setCheckAvatar(true);
        return;
      }
      setLoading(true);
      if (dataUpdate) {
        updateCompany(values);
      } else {
        createComapany(values);
      }
    },
  });

  const createComapany = async (values) => {
    setCheckAvatar(false);
    const formData = new FormData();
    formData.append("files", avatar);
    AdminService.uploadImageCompany(
      queryString.stringify({
        company_name: values.name,
      }),
      formData
    ).then((upload) => {
      const data = {
        ...values,
        avatar: upload.data.data.message[0] || "",
        createdAt: dayjs(new Date()).format("YYYY-MM-DD"),
        currentRole: "SUPER_ADMIN",
        createdBy: admin?.id,
        countJDs: 0,
        countCVs: 0,
      };
      AdminService.createNewCompany(data).then(() => {
        setLoading(false);
        onChangeActive();
      });
    });
  };

  const updateCompany = async (values) => {
    setCheckAvatar(false);
    const newValues = { ...values, currentRole: "SUPER_ADMIN" };
    let newAvatar = "";
    if (avatar) {
      const formData = new FormData();
      formData.append("files", avatar);
      newAvatar = await AdminService.uploadImageCompany(
        queryString.stringify({
          company_name: values.name,
          role: "Admin",
        }),
        formData
      );
      newAvatar = newAvatar.data.data.message[0];
    }
    AdminService.updateCompany(
      dataUpdate.id,
      newAvatar ? { ...newValues, avatar: newAvatar } : newValues
    ).then(() => {
      onChangeActive();
    });
  };

  const uploadAvatar = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setAvatar(e.target.files[0]);
    }
    setCheckAvatar(false);
  };

  return (
    <div
      className={`overflow-hidden fixed inset-0 z-9999 h-screen outline-none focus:outline-none justify-center items-center c-modal bg-black bg-opacity-20 ${
        active ? "block" : "hidden"
      }`}
    >
      <div className="relative flex justify-center items-center mx-auto w-full px-[10px] md:px-0 h-[100vh] select-none">
        <div className="border-0  relative flex flex-col w-full bg-white outline-none focus:outline-none sm:w-[420px] rounded-[12px]">
          <form
            className="relative flex-auto w-full sm:w-[420px]"
            encType="multipart/form-data"
          >
            <div
              className={`w-full h-[120px] rounded-tl-[12px] rounded-tr-[12px] bg-[#1495F3] relative cursor-pointer`}
            >
              <input
                type="file"
                id="avatarCompany"
                name="avatarCompany"
                onChange={uploadAvatar}
                accept="image/*"
                hidden
              />
              <div
                className={`w-[120px] h-[120px] rounded-[50%] border border-gray bg-[#F5F7FF] absolute left-10 -bottom-[50%]  ${
                  checkAvatar && "border-red-400"
                }`}
                onClick={() =>
                  document.getElementById("avatarCompany")?.click()
                }
              >
                <img
                  src={
                    avatar
                      ? URL.createObjectURL(avatar)
                      : dataUpdate?.avatar
                      ? dataUpdate.avatar
                      : ""
                  }
                  alt=""
                  className="w-full h-full object-cover border-none rounded-[50%]"
                />
              </div>

              <div
                className="w-[120px] h-[120px] border-none rounded-[50%] opacity-60 absolute left-10 -bottom-[50%] flex justify-center items-center"
                onClick={() =>
                  document.getElementById("avatarCompany")?.click()
                }
              >
                <div className="w-[48px] h-[48px] rounded-[50%] bg-[#B5C1FF] flex justify-center items-center">
                  <CameraOutlined className="text-[24px]" />
                </div>
              </div>
            </div>
            <div className="w-full bg-white rounded-bl-[12px] rounded-br-[12px] p-[20px]">
              <div className="w-full">
                <div className="ml-[40%]">
                  <InputAdmin
                    name="name"
                    label="Tên doanh nghiệp"
                    valueInput={createCompanyFormik.values.name}
                    placeholder="Tên doanh nghiệp"
                    onChange={createCompanyFormik.handleChange}
                    error={
                      createCompanyFormik.errors.name &&
                      createCompanyFormik.touched.name
                        ? createCompanyFormik.errors.name
                        : ""
                    }
                    required
                  />
                </div>
                <div className="my-3">
                  <InputAdmin
                    name="address"
                    valueInput={createCompanyFormik.values.address}
                    label="Địa chỉ"
                    placeholder="Địa chỉ của doanh nghiệp"
                    onChange={createCompanyFormik.handleChange}
                    error={
                      createCompanyFormik.errors.address &&
                      createCompanyFormik.touched.address
                        ? createCompanyFormik.errors.address
                        : ""
                    }
                    required
                  />
                </div>
                <div className="my-3">
                  <InputAdmin
                    name="note"
                    valueInput={createCompanyFormik.values.note}
                    label="Ghi chú"
                    placeholder="Ghi chú về doanh nghiệp"
                    onChange={createCompanyFormik.handleChange}
                  />
                </div>
              </div>
              <div className="w-full flex justify-end items-center">
                {dataUpdate && (
                  <div
                    className="px-9 py-2 bg-[#E60019] text-white rounded-[12px] text-[12px] cursor-pointer mr-6"
                    onClick={() => setActiveDelete(true)}
                  >
                    Xóa công ty
                  </div>
                )}
                <div
                  className="px-9 py-2 border rounded-[12px] text-[12px] cursor-pointer mr-2"
                  onClick={onChangeActive}
                >
                  Hủy
                </div>
                <div
                  className="px-9 py-2 bg-[#1495F3] text-white rounded-[12px] text-[12px] cursor-pointer"
                  onClick={createCompanyFormik.handleSubmit}
                  aria-disabled={loading}
                >
                  {loading && (
                    <LoadingOutlined
                      style={{
                        color: "white",
                        fontSize: "12px",
                        marginRight: "5px",
                      }}
                    />
                  )}
                  Lưu
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <DeleteCompanyModal
        data={dataUpdate}
        active={activeDelete}
        onChangeActive={() => {
          setActiveDelete(!activeDelete);
        }}
      />
    </div>
  );
}

export default CreateCompany;
