import React, { useEffect } from "react";
import { cx } from "../../lib/cx";
import { useTheme } from "../../lib/theme/useTheme";
import { useAuth } from "../Auth/useAuth";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useState } from "react";
import AdminService from "../../services/AdminService";
import { getLocation, getSalary, getTimeCloseJob } from "../../utils";
import dayjs from "dayjs";
import JdList from "./JdList";
import { Pagination } from "antd";
import { changePagination } from "../../lib/theme/config";
import JdNew from "./JdNew";
import UserService from "../../services/UserService";
import { getJD } from "../../services/PORT";

function JDManagement() {
  const { theme } = useTheme();
  const { admin } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const search = queryString.parse(location.search);

  const changeParams = (params) => {
    history.replace(
      location.pathname + `?${queryString.stringify({ ...search, ...params })}`
    );
  };

  const [listAllJd, setListAllJd] = useState();
  const [listNewJd, setListNewJd] = useState();
  const [listUpdateJd, setListUpdateJd] = useState();

  const [totalPage, setTotalPage] = useState(0);
  const [totalNewPage, setTotalNewPage] = useState(0);
  const [totalValidatePage, setTotalValidatePage] = useState(0);

  const limit = 25;

  const listMenu = [
    {
      name: `Danh sách tin tuyển (${totalPage})`,
      slug: "list-jd",
    },
    {
      name: `Phê duyệt tin tuyển (${totalNewPage})`,
      slug: "accept-jd",
    },
    {
      name: `Phê duyệt nội dung tin tuyển (${totalValidatePage})`,
      slug: "validate-jd",
    },
  ];

  useEffect(() => {
    if (!listAllJd) {
      getAllJd(search?.page || 1);
      getListNewJd(search?.page || 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listAllJd]);

  const getAllJd = async (page) => {
    try {
      const params = queryString.stringify({
        offset: page - 1 || 0,
        limit: limit,
      });
      const response = await AdminService.getAllJd(params, {});
      const data = response.data.data.filter(
        (item, index) => index > 0 && item
      );
      const result = data.map((item) => ({
        id: item.id,
        jobName: item.jobName,
        companyName: item.companyName,
        startDate: dayjs(new Date(item.startReceiveCV)).format("DD/MM/YYYY"),
        endDate:
          getTimeCloseJob(item.endReceiveCV) > 0
            ? getTimeCloseJob(item.endReceiveCV) + " ngày"
            : "Hết thời gian",
        salary: getSalary(item.minSalary, item.maxSalary, item.salary),
        cvApplied: (item?.cvApplyList && item.cvApplyList.length) || 0,
        location: getLocation(item.location),
      }));
      setListAllJd(result);
      const total = response.data.data.find((item) => item.countTotal);
      setTotalPage(total?.countTotal || 10);
    } catch (error) {
      console.log("error", new Error(error));
    }
  };

  const getListNewJd = async (page) => {
    try {
      const params = {
        offset: page - 1 || 0,
        limit: limit,
      };
      const response = await UserService.getListJD(
        `admin/${false} `,
        params,
        getJD
      );
      const data = response.data.data.items;
      const resultNew = data
        .filter((item) => item.newlyUpdateData.length < 0)
        .map((item) => formatData(item));
      setListNewJd(resultNew);
      const resultUpdate = data
        .filter((item) => item.newlyUpdateData.length > 0)
        .map((c) => formatData(c.newlyUpdateData[0]));
      setListUpdateJd(resultUpdate);
      setTotalNewPage(data.length - resultUpdate.length);
      setTotalValidatePage(resultUpdate.length);
    } catch (error) {
      console.log(new Error(error));
    }
  };

  const formatData = (item) => {
    return {
      id: item.id,
      jobName: item.jobName,
      companyName: item.companyName,
      startDate: dayjs(new Date(item.startReceiveCV)).format("DD/MM/YYYY"),
      endDate: dayjs(new Date(item.endReceiveCV)).format("DD/MM/YYYY"),
      salary: getSalary(item.minSalary, item.maxSalary, item.salary),
      location: getLocation(item.location),
    };
  };

  const onChangePage = (page) => {
    changeParams({ ...search, page: page });

    if (search?.action === "accept-jd" || search?.action === "validate-jd") {
      getListNewJd(page);
      return;
    }
    getAllJd(page);
  };

  const getTotalPage = () => {
    if (search?.action === "accept-jd") return totalNewPage;
    if (search?.action === "validate-jd") return totalValidatePage;
    return totalPage;
  };

  return (
    <div
      className={cx(
        "mx-auto min-h-[80vh] lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <div className="px-4 mb-[120px]">
        <div className={cx("my-[50px]", theme?.background?.text)}>
          <p className="text-[32px] font-[600] leading-none">Dashboad</p>
          <p className="text-[26px]">{admin?.nickname}</p>
        </div>

        <div className="w-full flex justify-between items-center my-2">
          <p className={cx("text-[20px] font-[600]", theme?.background?.text)}>
            Quản lý danh mục tin tuyển
          </p>
          <p className="text-black hover:underline cursor-pointer"></p>
        </div>

        <div className="space-y-1 sm:space-y-0 sm:space-x-2 my-4">
          {listMenu.map((menu, index) => (
            <div
              key={menu.name + index + "menu"}
              className={cx(
                "px-4 py-2 text-white inline-block cursor-pointer rounded-[8px]",
                (search?.action || "list-recruiter") === menu.slug
                  ? "bg-[#333]"
                  : "bg-gray"
              )}
              onClick={() => changeParams({ action: menu.slug, page: 1 })}
            >
              {menu.name}
            </div>
          ))}
        </div>

        <div className="mt-[10px] bg-[#F2F7FF]">
          <div className="overflow-x-auto pb-[10px]">
            {(search?.action || "list-jd") === "list-jd" && (
              <JdList data={listAllJd} search={search} limit={limit} />
            )}
            {search?.action === "accept-jd" && (
              <JdNew data={listNewJd} search={search} limit={limit} />
            )}
            {search?.action === "validate-jd" && (
              <JdNew data={listUpdateJd} search={search} limit={limit} />
            )}
          </div>
        </div>
        <div className="w-full my-4 flex justify-center items-center">
          <Pagination
            defaultCurrent={search?.page ? Number(search?.page) : 1}
            total={getTotalPage()}
            pageSize={limit}
            onChange={onChangePage}
            itemRender={changePagination}
          />
        </div>
      </div>
    </div>
  );
}

export default JDManagement;
