import { CloseOutlined } from "@ant-design/icons";
import React from "react";
import { useHistory } from "react-router-dom";
import { routes } from "../../screens/PathRouting";

function ChooseCvCreate({ active, onChangeActive }) {
  const history = useHistory();

  const loginStatus = JSON.parse(localStorage.getItem("loginStatus"));

  const handleClickNomal = () => {
    history.push(routes.templateCv.path);
    onChangeActive();
  };

  const handleClickAvand = () => {
    if (!loginStatus || loginStatus.status !== "success") {
      history.push(
        routes.loginPage.path +
          `?role=Candidate&redirect=${routes.createCvAdvanced.path}`
      );
      return;
    }
    history.push(routes.createCvAdvanced.path);
    onChangeActive();
  };

  return (
    <div
      className={`overflow-x-hidden overflow-y-auto fixed inset-0 z-9999 h-screen outline-none focus:outline-none justify-center items-center c-modal bg-black bg-opacity-40 ${
        active ? "block" : "hidden"
      }`}
    >
      <div className="w-full h-[100vh] flex justify-center items-center px-4">
        <div className="w-full sm:w-[650px] h-auto bg-[#ccc] rounded-[22px]">
          <div
            className="w-full flex justify-end items-center cursor-pointer pt-[10px] pr-[15px]"
            onClick={onChangeActive}
          >
            <CloseOutlined style={{ fontSize: "15px" }} />
          </div>
          <div className="w-full px-[40px] pt-[20px] pb-[40px]">
            <p className="text-[#1495F3] text-[24px] font-[700] text-center">
              Chọn loại CV
            </p>
            <div className="w-full h-auto flex-row sm:flex justify-center items-center mt-[20px]">
              <div
                className="w-full sm:w-[240px] h-auto bg-white rounded-[22px] cursor-pointer"
                onClick={handleClickNomal}
              >
                <img src="/images/cv-banner1.png" alt="" className="" />
                <div className="p-[15px]">
                  <p className="text-black text-[24px] text-center font-[700]">
                    CV cơ bản
                  </p>
                  <p className="text-[12px] text-black text-center">
                    Bắt đầu sự nghiệp với một CV chuyên nghiệp và dễ dàng.
                  </p>
                </div>
              </div>
              <div
                className="w-full sm:w-[240px] h-auto bg-white rounded-[22px] mt-[20px] sm:mt-0 sm:ml-[20px] cursor-pointer"
                onClick={handleClickAvand}
              >
                <img src="/images/cv-banner2.png" alt="" className="" />
                <div className="p-[15px]">
                  <p className="text-[#1495F3] text-[24px] text-center font-[700]">
                    CV nâng cao
                  </p>
                  <p className="text-[12px] text-black text-center">
                    Tạo ấn tượng vượt trội với CV độc đáo và chuyên sâu.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseCvCreate;
