import React, { useEffect, useState } from "react";
import CardNewsShow from "./components/CardNewsShow";
import AdminService from "../../services/AdminService";
import CardNewsFirst from "./components/CardNewsFirst";
import UserService from "../../services/UserService";
import {
  getImageUrl,
  getLocation,
  getRandomListItem,
  getSalary,
} from "../../utils";
import { cx } from "../../lib/cx";
import { useTheme } from "../../lib/theme/useTheme";
import HeaderSeo from "../../components/Header/HeaderSeo";
import { useLocalStorage } from "../../lib/useLocalStorage";
import queryString from "query-string";
import CardBox from "../../components/CardItem/CardBox";
import { Pagination } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { changePagination } from "../../lib/theme/config";

function NewsPage() {
  const { theme } = useTheme();
  const [listNews, setListNews] = useState();
  const [token] = useLocalStorage("token", null);
  const [listJds, setListJds] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const history = useHistory();
  const location = useLocation();

  const search = queryString.parse(location.pathname);

  const limit = 10;

  useEffect(() => {
    if (!listNews) {
      getListNews(search?.page || 1);
      getListJds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listNews]);

  const getListNews = async (page = 1) => {
    const params = queryString.stringify({
      offset: page - 1,
      limit: limit,
      status: "ACTIVE",
    });
    const response = await AdminService.getAllNews(params);

    const data = response.data.data;

    let items = data.items;
    const image = items.map((item) => item.image);
    const listImage = await UserService.getImageByName(image);
    items = items.map((item) => ({
      ...item,
      image: getImageUrl(listImage.data.data, item.image),
    }));
    setTotalPage(data.count);
    setListNews(items);
  };

  const getListJds = () => {
    UserService.getNewJD(0, 15)
      .then(async (response) => {
        if (response.data.data.items.length > 1)
          await formatDataShow(response.data.data.items, setListJds);
      })
      .catch((error) => console.log("error", error));
  };

  const formatDataShow = async (response, args) => {
    const listRecruiter = [];

    for (const items of response) {
      let recruiter = await UserService.getDescriptionRecuiter(
        items.recruiterId
      );
      recruiter = recruiter.data.data;
      const listImage = await UserService.getImageByName(recruiter.avatarImg);
      listRecruiter.push({
        ...recruiter,
        avatarImg: listImage.data.data[0].fileUrl,
      });
    }

    const newData = response.map((data, index) => ({
      id: data.id,
      image: listRecruiter[index].avatarImg,
      job: data.jobName,
      name_company: listRecruiter[index].enterprise,
      location: getLocation(data.location),
      salary: getSalary(data.minSalary, data.maxSalary, data.salary),
    }));

    args(getRandomListItem(newData, 5));
  };

  const onChangePage = (page) => {
    history.replace(
      location.pathname + "?" + queryString.stringify({ page: page })
    );
    getListNews(page);
  };

  return (
    <>
      <HeaderSeo
        title="Tin tức tuyển dụng"
        description="Lao động online - Cập nhật xu hướng và các tin tức tuyển dụng mới nhất"
      />
      <div
        className={cx(
          "mx-auto min-h-[80vh] lg:max-w-[1175px] select-none mb-[120px]",
          theme?.background?.gradient
        )}
      >
        <div className="px-4">
          <h3
            className={cx(
              "text-[28px] font-[700] my-[40px]",
              theme?.background?.text
            )}
          >
            Tin tức tuyển dụng
          </h3>

          <div
            className={cx(
              "w-full grid grid-cols-1 gap-4",
              token ? "xl2:grid-cols-3" : "md:grid-cols-3"
            )}
          >
            <div className="col-span-2">
              <div className="w-full mb-2">
                <p
                  className={cx(
                    "text-[20px] font-[600]",
                    theme?.background?.text
                  )}
                >
                  Tin tức mới nhất
                </p>
              </div>
              {listNews && listNews.length > 0 && (
                <CardNewsFirst data={listNews[0]} />
              )}
              {listNews &&
                listNews.length > 0 &&
                listNews.map((item, idx) => (
                  <CardNewsShow key={"news" + idx} data={item} />
                ))}
            </div>
            <div className="col-span-1">
              <p
                className={cx(
                  "text-[20px] font-[600]",
                  theme?.background?.text
                )}
              >
                Công việc đề xuất
              </p>
              {listJds &&
                listJds.length > 0 &&
                listJds.map((item, idx) => (
                  <CardBox key={"new-job" + idx} data={item} />
                ))}
            </div>
          </div>
          <div className="w-full mt-[40px] flex justify-center items-center">
            {totalPage > 0 && (
              <Pagination
                defaultCurrent={search?.page || 1}
                total={totalPage}
                pageSize={limit}
                onChange={onChangePage}
                itemRender={changePagination}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsPage;
