import React from "react";
import { Modal } from "antd";

export const DetailHistoryModal = (props) => {
  const content = props.content;
  return (
    <Modal open={props.open} onCancel={props.onCancel} footer={<></>}>
      <div className="mt-[10px]">
        <div>
          <p className="text-black font-[600] text-[36px]">{content?.year}</p>
        </div>
        <div className="w-full h-[180px]">
          <img
            src={content?.img}
            alt=""
            className="w-full h-full object-cover rounded-[12px]"
          />
        </div>

        <div className="mt-[10px]">{content?.detail}</div>
      </div>
    </Modal>
  );
};
