import React from "react";
import { Tooltip } from "../../../cv/Advanced/Tootip";
import { Download } from "@mui/icons-material";
import { downloadFile } from "../../../utils";
import { EyeOutlined } from "@ant-design/icons";
import { cx } from "../../../lib/cx";
import { useTheme } from "../../../lib/theme/useTheme";

function CardCvManage({ data, onChangeDetail }) {
  const { theme } = useTheme();
  return (
    <div className="p-2 bg-white mt-1 rounded-[12px] cursor-pointer grid grid-cols-12 shadow-md">
      <div className="col-span-1 flex justify-center items-center">
        <img
          src="/asserts/pdfSVG.svg"
          className="w-[36px] h-[36px] object-cover"
          alt=""
        />
      </div>
      <div className="col-span-11 px-3">
        <Tooltip title={data?.fullName}>
          <h2 className="text-lg line-clamp-1 font-semibold cursor-pointer">
            {data?.fullName}
          </h2>
        </Tooltip>
        {data?.dateOfBirdth && (
          <p className="text-sm text-gray">Ngày sinh: {data?.dateOfBirdth}</p>
        )}
        <p className="text-sm text-gray">Ứng tuyển: {data?.applyPosition}</p>
        <p className="text-sm text-gray">Email: {data?.email}</p>
        <p className="text-sm text-gray">Số điện thoại: {data?.phoneNumber}</p>
        <p className="text-sm text-gray">Địa chỉ: {data?.location}</p>
        <div className="flex justify-end items-center mt-[10px] space-x-2">
          <div
            className="bg-gray w-[100px] rounded-[12px] px-2 py-1 text-white cursor-pointer text-[14px]"
            onClick={() =>
              downloadFile({
                url: data?.image,
                filename: data?.cvName + `-cv.pdf`,
              })
            }
          >
            <Download fontSize="14px" /> Tải xuống
          </div>
          <div
            className={cx(
              "w-[100px] rounded-[12px] px-2 py-1 text-white cursor-pointer flex justify-center items-center text-[14px]",
              theme?.button?.background
            )}
            onClick={() => {
              onChangeDetail({
                image: data?.image,
                name: data.cvName,
                createdAt: data?.createdAt,
              });
            }}
          >
            <EyeOutlined style={{ fontSize: "14px" }} />
            <span className="ml-1">Chi tiết</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardCvManage;
