export function IconCircle() {
  return (
    <div className="w-3 h-3 bg-[#1495F3] rounded-[50%] relative before:w-1 before:h-1 before:bg-white before:absolute before:top-1 before:left-1 before:rounded-[50%] mr-2"></div>
  );
}

export function LableItem(props) {
  return (
    <div>
      <div className="text-black flex justify-start items-center text-[14px] font-[600] mt-[12px]">
        <IconCircle />
        {props.label}
      </div>
      <p>{props.description}</p>
    </div>
  );
}
