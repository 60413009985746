import { Image } from "antd";
import React, { useState, useEffect } from "react";

function SelectItem(props) {
  const {
    name,
    data,
    placeholder,
    isHidden,
    icon,
    search,
    valueInput,
    className,
    title,
    parentClass,
    childrenClass,
    onChangeValue,
  } = props;
  const [isShow, setIsShow] = useState(false);
  const [searchResult, setSearchResult] = useState(data);
  const [value, setValue] = useState();

  useEffect(() => {
    if (data) setSearchResult(data);
  }, [data]);

  useEffect(() => {
    if (valueInput) setValue(valueInput);
  }, [valueInput]);

  const handleChangeSearch = (e) => {
    const valueInput = e.target.value;
    setValue(valueInput);
    if (!valueInput) setSearchResult(data);
    if (valueInput) {
      const resultSearch = data.filter((item) =>
        item.toLowerCase().includes(valueInput.toLowerCase())
      );
      setSearchResult(resultSearch);
    }
  };
  return (
    <div className={`relative select-none ${parentClass}`}>
      <div
        className={`w-full z-50 min-h-[48px] cursor-pointer`}
        onClick={() => setIsShow(!isShow)}
      >
        <div
          className={`w-full h-full absolute top-0 left-0 px-3 py-2 rounded-[12px] flex z-50 bg-white ${className}`}
        >
          {!title && (
            <div
              className="w-[12px] flex justify-center items-center"
              onMouseDown={() => setIsShow(false)}
            >
              <Image
                src={icon || "/asserts/icons/colorfilter.png"}
                alt="icon"
                preview={false}
                width={12}
                height={12}
                className="object-cover"
              />
            </div>
          )}
          <div
            className={`flex justify-start items-center ${
              title ? "w-full" : "w-[94%] ml-[10px]"
            } z-50`}
          >
            {search ? (
              <input
                value={value}
                className="w-full focus:outline-none"
                onChange={handleChangeSearch}
                placeholder={placeholder}
              />
            ) : (
              !isHidden && (
                <p className={`${title ? "text-black" : ""}`}>
                  {value ? value : placeholder}
                </p>
              )
            )}
          </div>
          <div className="w-[12px] flex justify-center items-center">
            <Image
              src={"/asserts/icons/arrow-down.png"}
              alt="icon"
              preview={false}
              width={12}
              height={12}
              className="object-cover"
            />
          </div>
        </div>
      </div>
      <div
        className={`w-full min-h-[20px] max-h-[180px] overflow-y-auto overflow-x-hidden bg-white z-10 ${
          isShow ? "block" : "hidden"
        } absolute top-[40px] left-0 z-[9999] ${childrenClass}`}
      >
        {searchResult &&
          searchResult.map((item, index) => {
            return (
              <p
                key={index + item}
                className="cursor-pointer hover:text-white hover:bg-[#ccc] px-3 py-1"
                onClick={() => {
                  setValue(item);
                  onChangeValue({
                    target: {
                      name: name,
                      value: item,
                    },
                  });
                  setIsShow(false);
                }}
              >
                {item}
              </p>
            );
          })}
      </div>
      <div
        className={`w-[140%] h-[280px] absolute -top-10 -left-10 ${
          isShow ? "block" : "hidden"
        } z-0`}
        onMouseMove={() => setIsShow(!isShow)}
      ></div>
    </div>
  );
}

export default SelectItem;
