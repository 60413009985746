import React from "react";
import { formatVND } from "../../../utils";
import { cx } from "../../../lib/cx";

function RecruiterHistoryDetailModal({ data, active, onChangeActive }) {
  return (
    <div
      className={`overflow-x-auto md:overflow-hidden fixed inset-0 z-9999 h-screen outline-none focus:outline-none justify-center items-center c-modal bg-black bg-opacity-20 ${
        active ? "block" : "hidden"
      }`}
    >
      <div className="relative flex justify-center items-center mx-auto w-full px-[10px] md:px-0 h-[100vh] select-none">
        <div className="border-0 relative flex flex-col w-full bg-[#F2F7FF] outline-none focus:outline-none sm:w-[550px] rounded-[12px] p-[10px]">
          <p className="text-center font-[600] text-[20px]">
            Chi tiết lịch sử giao dịch
          </p>
          <div className="w-full pb-[20px] min-h-[200px]">
            <p>Dịch vụ: {data?.combo}</p>
            <p>Tên khách hàng: {data?.name}</p>
            <p>Thời gian đăng ký: {data?.createdAt}</p>
            <p>Thời gian thanh toán: {data?.successAt}</p>
            <p>Tổng tiền: {data?.total}</p>
            <p>
              Trạng thái:{" "}
              {data?.status === "SUCCESS"
                ? "Thành công"
                : data?.status === "PROCESSING"
                ? "Đang xử lý"
                : "Lỗi"}
            </p>

            <div className="pt-[10px]">
              <p className="font-[600]">Chi tiết gói dịch vụ: </p>
              <div className="col-span-1 overflow-x-auto">
                <table className="w-full min-w-full max-w-[1000px]">
                  <thead>
                    <tr>
                      {["Dịch vụ", "Thời gian", "Sl", "Đơn giá gốc"].map(
                        (item, index) => (
                          <th
                            key={(item, index)}
                            className={cx(
                              "py-1 text-[14px] text-center bg-[#B7E1FF] rounded-[12px] uppercase",
                              index === 0 &&
                                "w-[50%] border-[1px] border-white",
                              index === 1 &&
                                "w-[19%] border-[1px] border-white",
                              index === 2 && "w-[9%] border-[1px] border-white",
                              index === 3 && "w-[20%]"
                            )}
                          >
                            {item}
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.detail &&
                      data?.detail?.detail &&
                      data.detail.detail.map((item, index) => (
                        <>
                          <tr key={item.title + index}>
                            {item?.number ? (
                              <>
                                {item.number === 1 && (
                                  <td
                                    className="bg-[#F4F9FF] py-1 px-2 rounded-[12px] border-[1px] border-white"
                                    rowSpan="4"
                                  >
                                    {item.title}
                                  </td>
                                )}
                              </>
                            ) : (
                              <td className="bg-[#F4F9FF] py-1 px-2 rounded-[12px] border-[1px] border-white">
                                {item.title}
                              </td>
                            )}
                            {[
                              item.date,
                              item.amount,
                              formatVND(item.price),
                            ].map((sub, idx) => (
                              <td
                                key={item.title + index + idx}
                                className="bg-[#F4F9FF] text-center py-1 px-2 rounded-[12px] border-[1px] border-white"
                              >
                                {sub}
                              </td>
                            ))}
                          </tr>
                        </>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end items-center mt-4 space-x-2">
            <div
              className="px-9 py-2 bg-[#ccc] text-black rounded-[12px] text-[12px] cursor-pointer"
              onClick={onChangeActive}
            >
              Ok
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecruiterHistoryDetailModal;
