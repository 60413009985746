import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "../PathRouting";
import Input from "../../components/Input";
import { useFormik } from "formik";
import SelectItem from "../../components/SelectItem";
import UserService from "../../services/UserService";
import { CameraOutlined } from "@ant-design/icons";
import InputAdd from "./components/InputAdd";
import AddImage from "./components/AddImage";
import AddStory from "./components/AddStory";
import AddWelfare from "./components/AddWelfare";
import {
  getBusinessInfoByID,
  getDescriptionBussiness,
  uploadImage,
} from "../../services/PORT";
import { SuccessUpdate } from "./components/SuccessUpdate";
import { getImageUrl } from "../../utils";
import Breadcrumb from "../../components/Tag/Breadcrumb";

function UpdateRecruiter() {
  const [district, setDistrict] = useState([]);
  const [cities, setCities] = useState([]);
  const [avatar, setAvatar] = useState("");
  const [confirm, setConfirm] = useState(false);

  const [listImageRoom, setListImageRoom] = useState([]);
  const [listAddress, setListAddress] = useState([]);
  const [listWelfare, setListWelfare] = useState([]);
  const [aboutUs, setAboutUs] = useState({});
  const [listStories, setListStories] = useState([]);

  const [recruiters, setRecruiters] = useState();

  const [oldAvatar, setOldAvatar] = useState();

  const history = useHistory();
  const recruiter = JSON.parse(localStorage.getItem("User"));

  const getPhoneNumber = (phone) => {
    return phone.slice(3, phone.length);
  };

  const recruiterInfoFormik = useFormik({
    initialValues: {
      enterprise: recruiter?.enterprise || "",
      email: recruiter?.email || "",
      website: recruiter?.website || "",
      introduction: recruiter?.introduction || "",
      prefixPhone: "+84",
      phoneNumber:
        (recruiter?.phoneNumber && getPhoneNumber(recruiter?.phoneNumber)) ||
        "",
      city: recruiter.address?.city || "",
      district: recruiter.address?.district || "",
    },
    validationSchema: "",
    onSubmit: (values) => {
      updateRecruiterInfo(values);
    },
  });

  useEffect(() => {
    if (cities.length < 1) {
      getListImageRoom();
      UserService.getAllOption("location").then((result) => {
        setCities(result.data.data[0]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cities]);

  useEffect(() => {
    if (!recruiters && recruiter.id) {
      getRecruiterData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recruiters, recruiter]);

  const getRecruiterData = async () => {
    const response = await UserService.getOneRecruiterByID(
      getBusinessInfoByID,
      recruiter.id
    );
    const data = response.data.data;
    setRecruiters(data);
    getDefaulValue(data);
  };

  const getDefaulValue = async (oldData) => {
    const listImage = [
      ...oldData?.avatarImg,
      ...oldData.aboutUsImg,
      ...oldData.storyImg,
    ];
    const response = await UserService.getImageByName(listImage);
    setOldAvatar(getImageUrl(response.data.data, oldData?.avatarImg[0]));
    setAboutUs({
      image: getImageUrl(response.data.data, oldData.aboutUsImg[0]),
    });

    if (oldData?.story && oldData.story.length > 0) {
      for (let i = 0; i < oldData.story.length; i++) {
        onChangeValueDetail(listStories, setListStories, "stories_", {
          value: oldData?.story[i],
          image: getImageUrl(response.data.data, oldData.storyImg[i]),
        });
      }
    }

    if (oldData?.enterpriseAddress && oldData.enterpriseAddress.length > 0) {
      for (let i = 0; i < oldData.enterpriseAddress.length; i++) {
        onChangeValueDetail(listAddress, setListAddress, "address_", {
          value: oldData.enterpriseAddress[i],
        });
      }
    }

    if (oldData?.welfare && oldData.welfare.length > 0) {
      for (const welfare of oldData.welfare) {
        onChangeValueDetail(listWelfare, setListWelfare, "welfare_", {
          value: welfare,
        });
      }
    }
  };

  const updateRecruiterInfo = async (values) => {
    const dataSend = {
      ...values,
      ...values,
      phoneNumber: `${values.prefixPhone}${values.phoneNumber}`,
      address: {
        city: values.city,
        district: values.district,
        nationality: "Việt Nam",
      },
      introduction: aboutUs?.value ? aboutUs.value : recruiter.introduction,
      enterpriseAddress:
        listAddress.length > 0 ? listAddress.map((item) => item.value) : [],
      welfare:
        listWelfare.length > 0 ? listWelfare.map((item) => item.value) : [],
      story:
        listStories.length > 0 ? listStories.map((item) => item.value) : [],
    };

    delete dataSend.prefixPhone;
    delete dataSend.city;
    delete dataSend.district;
    delete dataSend.email;

    if (recruiter && recruiter.email) {
      const body = { role: "Recruiter", email: recruiter.email };
      const response = await UserService.updateBusinessInfo(
        getDescriptionBussiness,
        recruiter.email,
        dataSend
      );

      if (avatar) {
        const formAvatar = new FormData();
        formAvatar.append("files", avatar);
        await UserService.uploadImage(
          uploadImage,
          { ...body, type: "Avatar" },
          formAvatar
        );
      }

      if (aboutUs?.file) {
        const aboutImage = new FormData();
        aboutImage.append("files", aboutUs.file);
        UserService.uploadImage(
          uploadImage,
          {
            ...body,
            type: "AboutUs",
          },
          aboutImage
        );
      }

      if (listImageRoom.length > 0) {
        const formOffice = new FormData();
        const isCheck = listImageRoom.filter((item) => item.file);
        if (isCheck.length > 0) {
          listImageRoom
            .map((item) => item.file)
            .map((item) => formOffice.append("files", item));
          await UserService.uploadImage(
            uploadImage,
            {
              ...body,
              type: "Office",
            },
            formOffice
          );
        }
      }

      if (listStories.length > 0) {
        const images = listStories.map((item) => item.file);

        for (let item in images) {
          const formStory = new FormData();
          formStory.append("files", item);
          await UserService.uploadImage(
            uploadImage,
            {
              ...body,
              type: "Story",
            },
            formStory
          );
        }
      }

      if (response.data.data) {
        setConfirm(!confirm);
      }
    }
  };

  const getListImageRoom = async () => {
    const result = [];
    const listImage = await UserService.getImageByName(recruiter.officeImg);
    for (let i = 0; i < 6; i++) {
      result.push({
        id: "image_" + i,
        image: getImageUrl(listImage.data.data, recruiter.officeImg[i]),
        original: recruiter.officeImg[i] || "",
      });
    }
    setListImageRoom(result);
  };

  const onChangeValueDetail = (
    state,
    setState,
    name,
    value = {},
    option = ""
  ) => {
    let result = [];
    if (option === "remove") {
      result = state.filter((item) => item.id !== value && item);
    } else if (value && value?.id) {
      result = state.map((item) => {
        if (item.id === value.id) return { ...item, ...value };
        return item;
      });
    } else {
      result = [
        ...state,
        {
          id: name + state.length,
          ...value,
        },
      ];
    }
    setState(result);
  };

  const onHandleChangeImage = (value, id) => {
    const images = listImageRoom.map((item) => {
      if (item.id === value.id)
        return id ? { id: item.id } : { ...item, ...value };
      return item;
    });
    setListImageRoom(images);
  };

  const handleChangeDistrict = (value) => {
    recruiterInfoFormik.values.district = "";
    setDistrict(cities[value]);
  };

  const uploadAvatar = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setAvatar(e.target.files[0]);
    }
  };

  const getErrorMessage = (fiel) => {
    return recruiterInfoFormik.errors[fiel] && recruiterInfoFormik.touched[fiel]
      ? recruiterInfoFormik.errors[fiel]
      : "";
  };

  return (
    <div className="mx-auto lg:max-w-[1175px] min-h-[100vh]">
      <Breadcrumb
        data={[
          { name: "Dashboard", path: routes.recruiterDashboard.path },
          { name: "Quản lý hồ sơ doanh nghiệp" },
        ]}
        isCheck
      />

      <div className="w-full bg-white rounded-[22px] p-[20px] mb-[120px]">
        <p className="text-black text-[24px] md:text-[32px] font-[600]">
          Thông tin Doanh Nghiệp
        </p>
        <p className="text-black">Các thông tin cơ bản của doanh nghiệp bạn.</p>

        <form
          className="mt-6"
          onSubmit={recruiterInfoFormik.handleSubmit}
          encType="multipart/form-data"
        >
          <div className="grid grid-cols-1 md:grid-cols-10 gap-4 ">
            <div className="col-span-2 flex justify-start md:justify-center items-start">
              <div className="w-[80px] h-[80px] sm:w-[100px] sm:h-[100px] lg:w-[160px] lg:h-[160px] rounded-[50%] bg-gray-400 relative">
                <img
                  src={
                    avatar
                      ? URL.createObjectURL(avatar)
                      : oldAvatar
                      ? oldAvatar
                      : ""
                  }
                  alt=""
                  className="w-full h-full object-cover rounded-[50%]"
                />
                <input
                  type="file"
                  id="imageAvatar"
                  name="imageAvatar"
                  onChange={uploadAvatar}
                  accept="image/*"
                  hidden
                />
                <div
                  className="w-full h-full pl-[5px] pt-[1px] lg:pt-[2px] rounded-[50%] opacity-10 bg-white cursor-pointer absolute right-0 bottom-0 flex justify-center items-center"
                  onClick={() => document.getElementById("imageAvatar").click()}
                >
                  <CameraOutlined className="text-[16px]" />
                </div>
              </div>
            </div>
            <div className="col-span-8">
              <div className="w-full mt-3">
                <Input
                  label="Tên doanh nghiệp"
                  name="enterprise"
                  placeholder={"Nhập tên doanh nghiệp"}
                  className="mt-0"
                  value={
                    recruiterInfoFormik.values.enterprise ||
                    recruiters?.enterprise
                  }
                  onChangeInput={recruiterInfoFormik.handleChange}
                />
              </div>
              <div className="w-full mt-3">
                <Input
                  label="Website"
                  name="website"
                  placeholder={"Đường dẫn tới website doanh nghiệp"}
                  className="!mt-0"
                  value={
                    recruiterInfoFormik.values.website || recruiters?.website
                  }
                  onChangeInput={recruiterInfoFormik.handleChange}
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
                <Input
                  label="Email"
                  name="email"
                  value={recruiterInfoFormik.values.email || recruiters?.email}
                  className="!mt-5"
                  disabled
                />
                <div className="w-full">
                  <label className="text-[16px] font-[600]">
                    Điện thoại <span className="text-red-500">*</span>
                  </label>
                  <div className="flex justify-center items-center mt-[12px]">
                    <SelectItem
                      data={["+84"]}
                      title="+"
                      name="prefixPhone"
                      placeholder="+84"
                      className="!min-h-0 !pr-1"
                      parentClass="border-[1px] !rounded-[8px] w-[60px] mr-[12px]"
                      childrenClass="bg-gray-200 !top-[30px]"
                      valueInput={recruiterInfoFormik.values.prefixPhone}
                      onChangeValue={recruiterInfoFormik.handleChange}
                    />
                    <Input
                      name="phoneNumber"
                      value={recruiterInfoFormik.values.phoneNumber}
                      placeholder="Nhập số điện thoại của bạn"
                      className="border-b-[#1495F3]"
                      onChangeInput={recruiterInfoFormik.handleChange}
                      error={getErrorMessage("phoneNumber")}
                    />
                  </div>
                </div>

                <div className="w-full">
                  <label className="text-[16px] font-[600]">
                    Thành phố /Tỉnh <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-4">
                    <SelectItem
                      data={Object.keys(cities)}
                      title="+"
                      name="city"
                      valueInput={recruiterInfoFormik.values.city}
                      placeholder="Chọn tỉnh/ thành phố"
                      className="!min-h-0 !p-0 !pr-1"
                      parentClass={`w-full mr-[12px] border-x-0 border-t-0 border-b-[1px] ${
                        getErrorMessage("city")
                          ? "border-b-[red]"
                          : "border-b-[#1495F3]"
                      }`}
                      childrenClass="shadow-lg !bg-[#eee] !top-[30px]"
                      onChangeValue={(event) => {
                        recruiterInfoFormik.handleChange(event);
                        handleChangeDistrict(event.target.value);
                      }}
                      search={true}
                    />
                  </div>
                </div>

                <div className="w-full">
                  <label className="text-[16px] font-[600]">
                    Quận/Huyện <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-4">
                    <SelectItem
                      data={district}
                      title="+"
                      name="district"
                      valueInput={recruiterInfoFormik.values.district}
                      placeholder="Chọn quận/huyện"
                      className="!min-h-0 !p-0 !pr-1"
                      parentClass={`w-full mr-[12px] border-[#0055FF] border-x-0 border-t-0 border-b-[1px] ${
                        getErrorMessage("district")
                          ? "border-b-[red]"
                          : "border-b-[#1495F3]"
                      }`}
                      childrenClass="shadow-lg !bg-[#eee] !top-[30px]"
                      onChangeValue={recruiterInfoFormik.handleChange}
                      search={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr className="w-full my-[40px]" />
          <div className="w-full">
            <p className="text-[24px] text-black font-[600]">Về Chúng Tôi</p>
            <p className="text-black">
              Mô tả tổng quan về doanh nghiệp của bạn
            </p>
          </div>
          <AddStory
            data={{
              id: "aboutus",
              value: recruiter.introduction,
              image: aboutUs.image,
            }}
            hiddenIcon
            onChangeValue={(value, id) =>
              setAboutUs(
                !id
                  ? { ...aboutUs, ...value }
                  : { id: id, value: aboutUs.value }
              )
            }
          />

          <hr className="w-full my-[40px]" />
          <div className="w-full">
            <p className="text-[24px] text-black font-[600]">
              Văn Phòng {recruiter?.enterprise}
            </p>
            <p className="text-black">Địa chỉ văn phòng của doanh nghiệp.</p>
          </div>
          {listAddress &&
            listAddress.length > 0 &&
            listAddress.map((address) => (
              <InputAdd
                data={address}
                onChangeAddress={(value) =>
                  onChangeValueDetail(
                    listAddress,
                    setListAddress,
                    "address_",
                    value
                  )
                }
                onRemoveAddress={(id) =>
                  onChangeValueDetail(
                    listAddress,
                    setListAddress,
                    "address_",
                    id,
                    "remove"
                  )
                }
              />
            ))}
          <p
            className="text-blue-500 cursor-pointer hover:underline"
            onClick={() =>
              onChangeValueDetail(listAddress, setListAddress, "address_")
            }
          >
            + Thêm địa chỉ
          </p>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 my-[20px]">
            {listImageRoom &&
              listImageRoom.length > 0 &&
              listImageRoom.map((image) => (
                <AddImage
                  data={image}
                  onChangeImage={onHandleChangeImage}
                  onHandleRemove={(value, id) => onHandleChangeImage(value, id)}
                />
              ))}
          </div>

          <hr className="w-full my-[40px]" />
          <div className="w-full">
            <p className="text-[24px] text-black font-[600]">
              Câu Chuyện Của {recruiter?.enterprise}
            </p>
            <p className="text-black">
              Chia sẻ những câu chuyện của doanh nghiệp.
            </p>
          </div>
          {listStories.length > 0 &&
            listStories.map((item) => (
              <AddStory
                data={item}
                onChangeValue={(value) =>
                  onChangeValueDetail(
                    listStories,
                    setListStories,
                    "stories_",
                    value
                  )
                }
                onChangeRemove={(id) =>
                  onChangeValueDetail(
                    listStories,
                    setListStories,
                    "stories_",
                    id,
                    "remove"
                  )
                }
              />
            ))}

          <p
            className="text-blue-500 cursor-pointer hover:underline"
            onClick={() =>
              onChangeValueDetail(listStories, setListStories, "stories_")
            }
          >
            + Thêm Đoạn
          </p>

          <hr className="w-full my-[40px]" />
          <div className="w-full">
            <p className="text-[24px] text-black font-[600]">
              Phúc Lợi Của {recruiter?.enterprise}
            </p>
            <p className="text-black">
              Những phúc lợi của doanh nghiệp dành cho nhân viên.
            </p>
          </div>
          {listWelfare.length > 0 &&
            listWelfare.map((item) => (
              <AddWelfare
                data={item}
                onRemoveBlock={(value) =>
                  onChangeValueDetail(
                    listWelfare,
                    setListWelfare,
                    "welfare_",
                    value,
                    "remove"
                  )
                }
                onChangeValue={(value) =>
                  onChangeValueDetail(
                    listWelfare,
                    setListWelfare,
                    "welfare_",
                    value
                  )
                }
              />
            ))}

          <p
            className="text-blue-500 cursor-pointer hover:underline"
            onClick={() =>
              onChangeValueDetail(listWelfare, setListWelfare, "welfare_")
            }
          >
            + Thêm Phúc Lợi
          </p>

          <div className="w-full flex justify-end items-end space-x-2 my-4">
            <button
              className="px-5 py-1 border-[1px] border-[#7F879E] bg-white text-[#7F879E] text-[14px] rounded-[10px] cursor-pointer"
              onClick={() => history.goBack()}
            >
              Thoát
            </button>

            <button
              type="submit"
              className="px-5 py-1 bg-blue-500 text-white rounded-[10px] text-[14px] cursor-pointer"
              onClick={recruiterInfoFormik.handleSubmit}
            >
              Cập nhật
            </button>
          </div>
        </form>
      </div>
      <SuccessUpdate
        open={confirm}
        onCancel={() => {
          setConfirm(false);
          history.push(routes.recuiterInfo.path);
        }}
      />
    </div>
  );
}

export default UpdateRecruiter;
