import React, { useState } from "react";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import { useAuth } from "../Auth/useAuth";
import CardPaymentBoard from "../components/Card/CardPaymentBoard";
import TablePayment from "../components/Sub_component/TablePayment";
import { formatVND } from "../../utils";
import { DownloadOutlined } from "@ant-design/icons";
import { Pagination } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { changePagination } from "../../lib/theme/config";

function PaymentBoard() {
  const { theme } = useTheme();
  const { admin } = useAuth();

  const location = useLocation();
  const history = useHistory();
  const limit = 30;

  const search = queryString.parse(location.search);

  const [totalItem] = useState(20);

  const handleChangePage = (page) => {
    history.replace(
      location.pathname + `?${queryString.stringify({ ...search, page: page })}`
    );
  };

  const data = [
    {
      code: "00001",
      customerName: "Trần Văn An",
      type: "Ứng viên",
      startTime: "22-02-2023",
      endTime: "22-03-2023",
      amount: formatVND(500000),
      status: "SUCCESS",
    },
    {
      code: "00002",
      customerName: "Nguyễn Văn Đạt",
      type: "Nhà tuyển dụng",
      startTime: "22-02-2023",
      endTime: "22-03-2023",
      amount: formatVND(12900000),
      status: "INPROGRESS",
    },
    {
      code: "00003",
      customerName: "Trần Văn An",
      type: "Ứng viên",
      startTime: "22-02-2023",
      endTime: "22-03-2023",
      amount: formatVND(500000),
      status: "ERROR",
    },
    {
      code: "00004",
      customerName: "Trần Văn An",
      type: "Ứng viên",
      startTime: "22-02-2023",
      endTime: "22-03-2023",
      amount: formatVND(500000),
      status: "EXPIRED",
    },
    {
      code: "00005",
      customerName: "Trần Văn An",
      type: "Ứng viên",
      startTime: "22-02-2023",
      endTime: "22-03-2023",
      amount: formatVND(500000),
      status: "TOEXPIRE",
    },
    {
      code: "00006",
      customerName: "Trần Văn An",
      type: "Ứng viên",
      startTime: "22-02-2023",
      endTime: "22-03-2023",
      amount: formatVND(500000),
      status: "SUCCESS",
    },
    {
      code: "00007",
      customerName: "Trần Văn An",
      type: "Ứng viên",
      startTime: "22-02-2023",
      endTime: "22-03-2023",
      amount: formatVND(500000),
      status: "EXPIRED",
    },
    {
      code: "00008",
      customerName: "Trần Văn An",
      type: "Ứng viên",
      startTime: "22-02-2023",
      endTime: "22-03-2023",
      amount: formatVND(500000),
      status: "SUCCESS",
    },
    {
      code: "00009",
      customerName: "Trần Văn An",
      type: "Nhà tuyển dụng",
      startTime: "22-02-2023",
      endTime: "22-03-2023",
      amount: formatVND(500000),
      status: "SUCCESS",
    },
    {
      code: "00010",
      customerName: "Trần Văn An",
      type: "Ứng viên",
      startTime: "22-02-2023",
      endTime: "22-03-2023",
      amount: formatVND(500000),
      status: "INPROGRESS",
    },
    {
      code: "00011",
      customerName: "Trần Văn An",
      type: "Ứng viên",
      startTime: "22-02-2023",
      endTime: "22-03-2023",
      amount: formatVND(500000),
      status: "ERROR",
    },
    {
      code: "000012",
      customerName: "Trần Văn An",
      type: "Nhà tuyển dụng",
      startTime: "22-02-2023",
      endTime: "22-03-2023",
      amount: formatVND(500000),
      status: "SUCCESS",
    },
  ];
  return (
    <div
      className={cx(
        "mx-auto min-h-[80vh] lg:max-w-[1175px] z-1 select-none mb-[120px]",
        theme?.background?.gradient
      )}
    >
      <div className={cx("px-4 mb-[120px]", theme?.background?.text)}>
        <div className="my-[60px]">
          <p className="text-[32px] font-[600] leading-none">Dashboad</p>
          <p className="text-[26px]">{admin?.nickname}</p>
        </div>

        <p className="font-[600] text-[20px]">Quản lý thanh toán</p>

        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 my-[40px]">
          <CardPaymentBoard title="Doanh thu" label="500.000.000 đ" />
          <CardPaymentBoard title="Dịch vụ" label="1234" type={2} />
          <CardPaymentBoard title="Hợp đồng" label="120" type={3} />
        </div>

        <div className="w-full">
          <div className="flex justify-between items-center my-4">
            <p className="font-[600] text-[20px]">Thông tin chi tiết</p>
            <div className="px-4 py-1 bg-[#2A4AFF] rounded-[12px] text-[14px] text-white cursor-pointer">
              <DownloadOutlined />
              Xuất Excel
            </div>
          </div>
          <TablePayment data={data} />
          <div className="w-full flex justify-center items-center my-4">
            <Pagination
              defaultCurrent={Number(search?.page) || 1}
              total={totalItem}
              onChange={handleChangePage}
              pageSize={limit}
              itemRender={changePagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentBoard;
