import { Form } from "antd";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { Bt } from "./BtForm";
import { RedoOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

const randomNumber = (min = 1, max = 14) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const getRandomCaptCha = (number = 5) => {
  const character =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const color = ["#aa332d", "black"];
  let captcha = [];
  for (let i = 0; i < number; i++) {
    const char = character.charAt(Math.floor(Math.random() * character.length));
    captcha.push({
      size: 32,
      margin: randomNumber(1, 24),
      char: char,
      color: color[randomNumber(0, 1)],
      left: randomNumber(5, 13),
    });
  }
  return captcha;
};

export const CaptCha = (props) => {
  const { onChangeCaptcha, oldCaptcha } = props;
  const [captcha, setCapcha] = useState(oldCaptcha);
  const CAPTCHA_SIZE = 6;

  useEffect(() => {
    if (!captcha) handleChangeCaptcha();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captcha]);

  const handleChangeCaptcha = () => {
    const newCaptcha = getRandomCaptCha(CAPTCHA_SIZE);
    setCapcha(newCaptcha);
    if (onChangeCaptcha)
      onChangeCaptcha(newCaptcha.map((i) => i.char).join(""));
  };

  return (
    <Form.Item label={props.title}>
      <div className="w-full sm:w-[60%] bg-[#eee] p-[16px] rounded-[12px]">
        <Stack
          direction={"row"}
          divider={<Divider orientation="vertical" flexItem />}
          spacing={1}
        >
          <div
            className="w-[80%] rounded-[12px] flex justify-center items-center"
            style={{ backgroundImage: "url('/images/captcha.jpg')" }}
          >
            {captcha &&
              captcha.map((item, index) => (
                <div
                  key={"captcha-item" + index}
                  style={{ marginTop: `${item.margin}px`, display: "inline" }}
                >
                  <span
                    style={{
                      fontFamily: "Time New Roman",
                      fontSize: `${item.size}px`,
                      color: `${item.color}`,
                      marginLeft: `${item.left}px`,
                    }}
                  >
                    {item.char}
                  </span>
                </div>
              ))}
          </div>
          <Stack direction={"column"} divider={<Divider />} spacing={1}>
            <Bt
              icon={<RedoOutlined />}
              onClick={handleChangeCaptcha}
              className="bg-white text-[#7F879E]"
            />
            <Bt
              icon={<InfoCircleOutlined />}
              className="bg-white text-[#7F879E]"
            />
          </Stack>
        </Stack>
      </div>
    </Form.Item>
  );
};
