import React, { useEffect, useState } from "react";
import { LableItem } from "../components/Tag/IconCircle";
import dayjs from "dayjs";
import { getImageUrl, getLocation, getSalary } from "../../utils";
import { marked } from "marked";
import UserService from "../../services/UserService";
import { getJDbyID } from "../../services/PORT";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { ArrowLeftOutlined } from "@mui/icons-material";

function PostReviewUpdate() {
  const location = useLocation();
  const [postDetail, setPostDetail] = useState();
  const search = queryString.parse(location.search);

  useEffect(() => {
    if (!postDetail && search.jd) {
      getPostDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postDetail, search]);

  const getPostDetail = async () => {
    const response = await UserService.getOneJDByID(getJDbyID, search.jd);

    let data = response.data.data;

    if (data.newlyUpdateData.length > 0) {
      data = data.newlyUpdateData[0];
    }

    let listImage = [data.companyAvatar];
    listImage = await UserService.getImageByName(listImage);
    listImage = listImage.data.data;

    setPostDetail({
      ...data,
      companyAvatar: getImageUrl(listImage, data.companyAvatar),
    });
  };

  return (
    <div className="mx-auto h-auto lg:max-w-[1175px] bg-[#F2F7FF] z-1 select-none mb-[120px]">
      <div className="grid grid-cols-1 md:grid-cols-5 md:gap-4 mt-10">
        <div></div>
        <div className="col-span-3">
          <div
            className="w-[120px] rounded-[12px] px-4 cursor-pointer py-3 my-[20px] bg-surface text-black"
            onClick={() => window.history.back()}
          >
            <ArrowLeftOutlined /> Quay lại
          </div>
          <div className="p-[20px] bg-white rounded-[22px]">
            <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
              <div className="col-span-1 md:col-span-5">
                <div className="w-full grid grid-cols-6 gap-4">
                  <div className="col-span-2 sm:col-span-1">
                    <div className="w-[80px] h-[80px] flex justify-center items-center p-[10px] rounded-[50%]">
                      <img
                        src={postDetail?.companyAvatar}
                        alt=""
                        className="max-w-full max-h-full object-cover"
                      />
                    </div>
                  </div>

                  <div className="col-span-4 sm:col-span-5">
                    <p className="text-[18px] font-[600] text-black line-clamp-1">
                      {postDetail?.jobName}
                    </p>
                    <p className="text-[16px] line-clamp-1">
                      {postDetail?.companyName}
                    </p>
                    <div className="flex justify-start items-center">
                      <img
                        src="/asserts/location.png"
                        alt=""
                        className="w-[14px] h-[14px]"
                      />
                      <p className="ml-2">
                        {getLocation(postDetail?.location)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr className="my-6" />

            <div className="">
              <p className="text-black font-[700] text-[20px]">
                Chi tiết công việc
              </p>
              <div className="mt-4">
                <LableItem
                  label="Mức lương"
                  description={getSalary(
                    postDetail?.minSalary,
                    postDetail?.maxSalary,
                    postDetail?.salary,
                    "vnd"
                  )}
                />
                <LableItem
                  label="Trình độ"
                  description={
                    postDetail?.levels &&
                    postDetail?.levels.length > 0 &&
                    postDetail.levels[0]
                  }
                />
                <LableItem
                  label="Số lượng tuyển dụng cho vị trí"
                  description={postDetail?.positions}
                />
                <LableItem
                  label="Dự kiến ngày mở tuyển dụng"
                  description={dayjs(
                    new Date(postDetail?.startReceiveCV)
                  ).format("DD/MM/YYYY")}
                />
              </div>
            </div>

            <div className="mt-[40px]">
              <p className="text-black font-[700] text-[20px]">
                Mô tả công việc
              </p>
              {postDetail?.jobDescription &&
                postDetail.jobDescription.map((item, index) => {
                  if (item && index === 0) {
                    return (
                      <>
                        <p className="text-[18px] font-[600] text-black">
                          {index + 1}. Nhiệm vụ
                        </p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: marked(item),
                          }}
                        />
                      </>
                    );
                  }
                  if (item && index === 1) {
                    return (
                      <>
                        <p className="text-[18px] font-[600] text-black">
                          {index + 1}. Yêu cầu công việc
                        </p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: marked(item),
                          }}
                        />
                      </>
                    );
                  }
                  if (item && index === 2) {
                    return (
                      <>
                        <p className="text-[18px] font-[600] text-black">
                          {index + 1}. Quyền lợi
                        </p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: marked(item),
                          }}
                        />
                      </>
                    );
                  }
                  return null;
                })}

              <LableItem
                label="Địa điểm làm việc"
                description={"- " + postDetail?.location}
              />
            </div>

            <hr className="my-6" />

            <div className="mt-[40px]">
              <p className="text-black font-[700] text-[20px]">
                Thiết lập ứng tuyển
              </p>
              <p>
                Các thông tin sau sẽ không được chia sẻ trên tin tuyển của bạn.
              </p>

              <LableItem label="Cách thức ứng tuyển" description="Qua Email" />

              <LableItem
                label="Yêu cầu CV"
                description={postDetail?.cvRequire ? "Có" : "Không"}
              />

              <LableItem
                label="Gửi thông tin cập nhật tới"
                description={
                  postDetail?.dailyUpdate && postDetail.dailyUpdate.length > 0
                    ? postDetail.dailyUpdate.join(", ")
                    : "Chưa cập nhật"
                }
              />

              <LableItem
                label="Cho phép nhắn tin"
                description={
                  "Có, ứng viên có thể gửi tin nhắn về tin tuyển dụng"
                }
              />

              <LableItem
                label="Thời hạn ứng tuyển"
                description={dayjs(new Date(postDetail?.endReceiveCV)).format(
                  "DD/MM/YYYY"
                )}
              />

              <LableItem
                label="Câu hỏi tùy chỉnh"
                description={
                  postDetail?.questions && postDetail.questions.length > 0 ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: marked(postDetail.questions[0]),
                      }}
                    />
                  ) : (
                    "Chưa cập nhật"
                  )
                }
              />
            </div>

            <div className="mt-[40px]">
              <p className="text-black font-[700] text-[20px]">
                Thiết lập Công việc
              </p>
              <LableItem
                label="Quốc gia và ngôn ngữ"
                description={
                  postDetail?.languages && postDetail.languages.length > 0
                    ? postDetail.languages.join(", ")
                    : "Chưa cập nhật"
                }
              />
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default PostReviewUpdate;
