import React from "react";
import { useLocation } from "react-router-dom";
import { routes } from "../../screens/PathRouting";
import { cx } from "../../lib/cx";
import { useTheme } from "../../lib/theme/useTheme";
import LoginCandidate from "../LoginCandidate";

function Content({ children }) {
  const { theme } = useTheme();
  const location = useLocation();
  return (
    <div
      className={cx(
        "min-h-[73vh] bg-[#F5F7FF] overflow-hidden",
        location.pathname !== routes.login.path && "mt-[55px]",
        theme?.background?.gradient
      )}
    >
      <LoginCandidate>{children}</LoginCandidate>
    </div>
  );
}

export default Content;
