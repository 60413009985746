import React from "react";

function CardShowLoading() {
  return (
    <div className="w-full h-[145px] bg-[#F4F9FF] rounded-[22px] p-[16px]">
      <div className="w-full h-[30px] animate-pulse">
        <div className="w-full h-[20px] bg-gray-1"></div>
      </div>
      <div className="w-full h-[20px] grid grid-cols-4 gap-4 my-[10px] animate-pulse">
        <div className="flex col-span-2">
          <div className="w-full h-[14px] bg-gray-1"></div>
        </div>
        <div className="flex">
          <div className="w-full h-[14px] bg-gray-1"></div>
        </div>
        <div className="flex">
          <div className="w-full h-[14px] bg-gray-1"></div>
        </div>
      </div>
      <div className="w-full h-auto my-[10px] animate-pulse">
        <div className="w-[50px] h-[30px] bg-gray-1 rounded-[12px] inline float-left mb-1 mr-1"></div>
        <div className="w-[90px] h-[30px] bg-gray-1 rounded-[12px] inline float-left mb-1 mr-1"></div>
        <div className="w-[120px] h-[30px] bg-gray-1 rounded-[12px] inline float-left mb-1 mr-1"></div>
      </div>
    </div>
  );
}

export default CardShowLoading;
