import React from "react";

export const ModalSuccess = (props) => {
  const { active, onChangeActive } = props;
  return (
    <div
      className={`overflow-hidden fixed inset-0 z-9999 h-screen outline-none focus:outline-none justify-center items-center c-modal bg-black bg-opacity-40 ${
        active ? "" : "hidden"
      }`}
    >
      <div className="relative flex justify-center items-center mx-auto w-full px-[10px] md:px-0 h-[100vh] select-none">
        <div className="border-0  relative flex flex-col w-full bg-white outline-none focus:outline-none sm:w-[420px] rounded-[12px]">
          <div className="relative flex-auto p-4 md:p-[24px] w-full sm:w-[420px]">
            <div>
              <p className="text-[22px] md:text-[28px] font-[700] text-[#1B2124]">
                Mật khẩu đã được cập nhật
              </p>
              <p className="text-[10px] md:text-[12px] font-[600] text-[#1B2124]">
                Mật khẩu của bạn đã được làm mới và vui lòng đăng nhập lại.
              </p>
            </div>
            <div className="w-[308px] h-[308px] md:w-[372px] md:h-[372px]">
              <img
                src="/asserts/SuccessUpdatePassword.png"
                alt=""
                className="object-cover"
              />
            </div>
            <div
              className="w-full px-4 py-3 bg-[#0055FF] rounded-[12px] text-center text-white cursor-pointer"
              onClick={() => {
                onChangeActive();
              }}
            >
              Xác nhận
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
