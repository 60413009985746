import React, { useEffect } from "react";
import { cx } from "../../../lib/cx";
import {
  changeActions,
  selectActions,
  selectFormHeader,
  selectFormOrder,
  selectFormToShow,
  selectSetting,
} from "../../redux/slice/setting";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  Email,
  Facebook,
  LinkedIn,
  LocationCity,
  Person,
  Phone,
} from "@mui/icons-material";
import { convertImageToBase64, formatContentCv } from "../../configs";
import { selectBuilder } from "../../redux/slice/builder";
import CopyRightComponent from "../SubComponent/CopyRightComponent";

function ReviewCV005Modal({ id }) {
  const { openPreview } = useAppSelector(selectActions);
  const setting = useAppSelector(selectSetting);
  const builder = useAppSelector(selectBuilder);
  const { profile, objective, avatar } = builder;
  const { left, right } = useAppSelector(selectFormOrder);
  const formToShow = useAppSelector(selectFormToShow);
  const formHeader = useAppSelector(selectFormHeader);

  const dispatch = useAppDispatch();

  useEffect(() => {
    convertImageToBase64(avatar.url, (base64Image) => {
      const imgElement = document.getElementById("avatar-img");
      if (imgElement) {
        imgElement.src = base64Image;
      }
    });
  }, [avatar.url]);

  const styleTitle = {
    fontFamily: `${setting.fontFamily}`,
    fontSize: `${Number(setting.fontSize) + 6}px`,
    color: `${setting.themeColor}`,
  };

  const styleText = {
    fontFamily: `${setting.fontFamily}`,
    fontSize: `${Number(setting.fontSize)}px`,
  };

  const styleIcon = {
    width: `${Number(setting.fontSize) + 8}px`,
    height: `${Number(setting.fontSize) + 8}px`,
    fontSize: `${Number(setting.fontSize) + 4}px`,
    color: "white",
    marginTop: "3px",
    marginRight: "5px",
    padding: "0px",
    display: "inline-block",
  };

  const infoProfile = [
    {
      name: profile?.dateOfBirdth,
      icon: <Person style={styleIcon} />,
    },
    {
      name: profile?.email,
      icon: <Email style={styleIcon} />,
    },
    {
      name: profile?.facebook,
      icon: <Facebook style={styleIcon} />,
    },
    {
      name: profile?.linkedin,
      icon: <LinkedIn style={styleIcon} />,
    },
    {
      name: profile?.phoneNumber,
      icon: <Phone style={styleIcon} />,
    },
    {
      name: profile?.location,
      icon: <LocationCity style={styleIcon} />,
    },
  ];
  return (
    <div
      className={cx(
        `overflow-x-auto md:py-[180px] xl:py-0 fixed inset-0 z-9999 h-screen outline-none focus:outline-none`,
        openPreview ? "block" : "hidden"
      )}
      onClick={() => dispatch(changeActions({ field: "openPreview" }))}
    >
      <div className="relative w-full min-h-[100vh]">
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-20"></div>
        <div className="flex justify-center items-center mx-auto w-full px-[10px] py-[72px] min-h-[100vh] select-none">
          <div
            className="w-[793px] min-h-[1112px] bg-white relative"
            style={{
              fontFamily: `${setting.fontFamily}`,
            }}
            id={id}
          >
            <div className="w-full h-[112px] bg-[#7C7A79] px-4 py-3">
              <p
                className="font-[700] text-end"
                style={{
                  fontFamily: `${setting.fontFamily}`,
                  fontSize: `${Number(setting.fontSize) + 20}px`,
                  color: `white`,
                }}
              >
                {profile?.fullName}
              </p>
              <p
                className="font-[600] text-end italic"
                style={{
                  fontFamily: `${setting.fontFamily}`,
                  fontSize: `${Number(setting.fontSize) + 4}px`,
                  color: `white`,
                }}
              >
                {profile?.applyPosition}
              </p>
            </div>
            <div className="flex">
              <div className="w-[528px] min-h-[1005px] p-3">
                {left &&
                  left.map((item) => {
                    if (formToShow[item]) {
                      if (item === "objective") {
                        return (
                          <>
                            <div className="w-full">
                              <p className="text-black pb-1" style={styleTitle}>
                                <b>Mục tiêu nghề nghiệp</b>
                              </p>
                            </div>
                            <p style={styleText}>
                              {formatContentCv(objective, "objective")}
                            </p>
                          </>
                        );
                      }
                      if (
                        [
                          "cetifications",
                          "honors_and_awards",
                          "interestes",
                        ].includes(item)
                      ) {
                        return (
                          <div className="mt-1">
                            <p style={styleTitle}>
                              <b>{formHeader[item]}</b>
                            </p>
                            {builder[item] &&
                              builder[item].map((c) => (
                                <div className="flex space-x-2 pb-2">
                                  {c?.description && (
                                    <p
                                      className={cx(
                                        "italic w-[25%]",
                                        item === "skills" && "font-[600]"
                                      )}
                                      style={styleText}
                                    >
                                      {c.description}
                                    </p>
                                  )}
                                  <p
                                    className={cx(
                                      item === "skills" && "font-[600]"
                                    )}
                                    style={styleText}
                                  >
                                    {formatContentCv(c?.name, item)}
                                  </p>
                                </div>
                              ))}
                          </div>
                        );
                      }
                      return (
                        <>
                          <div className="mt-2">
                            <div className="w-full">
                              <p style={styleTitle}>
                                <b>{formHeader[item]}</b>
                              </p>
                            </div>
                            {builder[item] &&
                              builder[item].map((c) => (
                                <>
                                  <div className="w-full mt-1">
                                    <div className="w-full flex justify-between items-center">
                                      <div className="w-[60%]">
                                        <p
                                          className="text-black font-[600]"
                                          style={{
                                            fontFamily: `${setting.fontFamily}`,
                                            fontSize: `${
                                              Number(setting.fontSize) + 4
                                            }px`,
                                          }}
                                        >
                                          {c?.title}
                                        </p>
                                      </div>
                                      {(c?.start_time || c?.end_time) && (
                                        <div
                                          className="text-black italic font-[600]"
                                          style={styleText}
                                        >
                                          {c?.start_time}
                                          <span> - </span>
                                          {c?.end_time}
                                        </div>
                                      )}
                                    </div>

                                    <div className="">
                                      {item === "projects" && (
                                        <span
                                          className="mr-1 italic"
                                          style={styleText}
                                        >
                                          <b>Số lượng người tham gia: </b>
                                        </span>
                                      )}
                                      <span
                                        className="italic"
                                        style={styleText}
                                      >
                                        <b>{c?.label}</b>
                                      </span>
                                    </div>

                                    {c?.sub_label && (
                                      <div className="">
                                        {item === "projects" && (
                                          <span
                                            className="mr-1 italic"
                                            style={styleText}
                                          >
                                            <b>Vị trí của bạn: </b>
                                          </span>
                                        )}
                                        <span
                                          className="italic"
                                          style={styleText}
                                        >
                                          <b>{c?.sub_label}</b>
                                        </span>
                                      </div>
                                    )}
                                    <p style={styleText}>
                                      {formatContentCv(c?.description, item)}
                                    </p>
                                  </div>
                                </>
                              ))}
                          </div>
                        </>
                      );
                    }
                    return null;
                  })}
              </div>
              <div
                className="w-[265px] min-h-[1005px] pb-4"
                style={{
                  background: `${setting.themeColor}`,
                }}
              >
                <div className="flex justify-center items-center">
                  <div className="w-[265px] h-[265px] p-4">
                    <div className="w-full h-full bg-white cursor-pointer rounded-full ">
                      <img
                        id="avatar-img"
                        src={avatar.url}
                        alt=""
                        className="w-full h-full object-cover rounded-full"
                      />
                    </div>
                  </div>
                </div>
                <p
                  className="text-white font-[600] px-4"
                  style={{
                    fontFamily: `${setting.fontFamily}`,
                    fontSize: `${Number(setting.fontSize) + 8}px`,
                  }}
                >
                  Thông tin cá nhân
                </p>
                {infoProfile.map((item) => {
                  if (item.name) {
                    return (
                      <>
                        <p className="px-4">
                          <span>{item.icon}</span>
                          <span className="text-white mb-4" style={styleText}>
                            {item.name}
                          </span>
                        </p>
                      </>
                    );
                  }
                  return null;
                })}
                <div className="px-4 pt-2">
                  {right &&
                    right.map((item) => {
                      if (["presenter"].includes(item)) {
                        return (
                          <>
                            <p
                              className="font-[700] !text-white mt-2"
                              style={styleTitle}
                            >
                              {formHeader[item]}
                            </p>
                            {builder[item] &&
                              builder[item].map((c) => (
                                <>
                                  <p
                                    className={cx("text-white")}
                                    style={styleText}
                                  >
                                    {c?.name}
                                  </p>
                                  {c?.description && (
                                    <p className="text-white" style={styleText}>
                                      {c.description}
                                    </p>
                                  )}
                                  {c?.email && (
                                    <p
                                      className=" text-white"
                                      style={styleText}
                                    >
                                      Email: {c.email}
                                    </p>
                                  )}
                                  {c?.phone && (
                                    <p
                                      className=" text-white"
                                      style={styleText}
                                    >
                                      Điện thoại: {c.phone}
                                    </p>
                                  )}
                                </>
                              ))}
                          </>
                        );
                      }
                      if (formToShow[item]) {
                        return (
                          <>
                            <p
                              className="font-[700] !text-white mt-2"
                              style={styleTitle}
                            >
                              {formHeader[item]}
                            </p>
                            {builder[item] &&
                              builder[item].map((c) => (
                                <>
                                  <p
                                    className={cx("my-1 font-[600] text-white")}
                                    style={styleText}
                                  >
                                    {c?.name}
                                  </p>
                                  {c?.description && (
                                    <p
                                      className="italic text-white"
                                      style={styleText}
                                    >
                                      {c.description}
                                    </p>
                                  )}
                                </>
                              ))}
                          </>
                        );
                      }
                      return null;
                    })}
                </div>
              </div>
            </div>
            <CopyRightComponent className="!text-black" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewCV005Modal;
