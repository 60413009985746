import { getAllCvApplied } from "./PORT";
import api from "./api";

/* eslint-disable import/no-anonymous-default-export */

class RecruiterService {
  constructor() {
    this.api = api;
  }
  getAllCvApplied(jdID, page) {
    return this.api.get(getAllCvApplied + `/${jdID}?offset=${page}&limit=12`);
  }

  getCountCvApply(jdID) {
    return this.api.get(getAllCvApplied + `/${jdID}/total`);
  }

  getRecruiterInfo(params) {
    return this.api.get(`recruiter/detail-info?${params}`);
  }
}

export default new RecruiterService();
