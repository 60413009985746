import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { routes } from "../PathRouting";
import UserService from "../../services/UserService";
import { getJDbyID } from "../../services/PORT";
import { getId, getLocation, getSalary, getTimeCloseJob } from "../../utils";
import { SendOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { ApplyCV } from "../User/ApplyCV";
import { marked } from "marked";
import { notification } from "antd";
import ScrollTop from "../../components/ScrollTop";
import CardBox from "../../components/CardItem/CardBox";
import { cx } from "../../lib/cx";
import { useTheme } from "../../lib/theme/useTheme";
import Breadcrumb from "../../components/Tag/Breadcrumb";
import HeaderSeo from "../../components/Header/HeaderSeo";
import ChooseCVNotLogin from "../../components/Modals/ChooseCVNotLogin";

function WorkDetail() {
  const { theme } = useTheme();
  const [jdData, setJdData] = useState();
  const [openPopup, setOpenPopup] = useState(false);
  const [listSuggesstion, setListSuggesstion] = useState();
  const [openNotLogin, setOpenNotLogin] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const jdId = getId(location.pathname);

  const user = JSON.parse(localStorage.getItem("User"));

  notification.config({ top: 80, placement: "topRight" });

  useEffect(() => {
    if (!jdData) getJdDescription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jdData]);

  const getJdDescription = () => {
    UserService.getOneJDByID(getJDbyID, jdId)
      .then(async (res) => {
        const result = res.data.data;

        let recruiter = await UserService.getDescriptionRecuiter(
          result.recruiterId
        );
        recruiter = recruiter.data.data;
        UserService.getImageByName(recruiter.avatarImg).then((response) => {
          setJdData({
            ...result,
            companyName: recruiter.enterprise,
            companyAvatar: response.data.data[0].fileUrl,
          });
        });

        UserService.getFilterJD(0, 12, { department: result.department }).then(
          async (response) => {
            const data = response.data.data;
            const newData = data.filter(
              (item) => !item.count && item?.id && item
            );
            await formatDataShow(newData, setListSuggesstion);
          }
        );
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const formatDataShow = async (response, args) => {
    const listRecruiter = [];

    for (const items of response) {
      let recruiter = await UserService.getDescriptionRecuiter(
        items.recruiterId
      );
      recruiter = recruiter.data.data;
      const listImage = await UserService.getImageByName(recruiter.avatarImg);
      listRecruiter.push({
        ...recruiter,
        avatarImg: listImage.data.data[0].fileUrl,
      });
    }

    const newData = response.map((data, index) => ({
      id: data.id,
      image: listRecruiter[index].avatarImg,
      job: data.jobName,
      name_company: listRecruiter[index].enterprise,
      location: getLocation(data.location),
      salary: getSalary(data.minSalary, data.maxSalary, data.salary),
      jobType: data.jobTypes[0],
      experience:
        data.experience > 0
          ? data.experience + " năm kinh nghiệm"
          : "Không y/c kinh nghiệm",
      dueTime: getTimeCloseJob(data.endReceiveCV),
    }));

    args(newData);
  };

  const handleApplyJD = () => {
    if (!user) {
      setOpenNotLogin(true);
      return;
    }
    setOpenPopup(true);
  };

  const handleSaveJD = () => {
    if (!user) {
      history.push(
        routes.loginPage.path +
          `?role=Candidate&redirect=${routes.workDetail.parentPath}/${jdId}`
      );
      return;
    }

    const data = [jdId];

    UserService.saveJD(user.id, data)
      .then(async () => {
        notification.success({
          message: "Thành công",
          description: "Đã lưu công việc bạn quan tâm",
          duration: 5,
          style: { top: 50 },
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const handleClickCompany = () => {
    history.push(
      routes.recruiterDetail.parentPath + `/${jdData?.recruiterId}`,
      {
        id: jdData?.id,
        job: jdData?.jobName,
      }
    );
  };

  return (
    <>
      <HeaderSeo
        title={`Việc làm ${jdData?.jobName}`}
        description={`"Lao động online - Công việc ${jdData?.jobName} phù hợp cho với bạn"`}
      />
      <div
        className={cx(
          "mx-auto h-auto lg:max-w-[1175px] bg-[#F2F7FF] z-1 select-none mb-[120px]",
          theme?.background?.gradient
        )}
      >
        <ScrollTop />
        <div className="px-4">
          <Breadcrumb
            data={[
              { name: "Danh sách công việc", path: routes.workList.path },
              { name: jdData?.jobName },
            ]}
          />

          <div className="w-full min-h-[160px] bg-white rounded-[15px] p-[10px] md:p-[24px]">
            <div className="w-full grid grid-col-1 sm:grid-cols-10">
              <div className="col-span-1 sm:col-span-7 flex">
                <div className="w-[80px] h-[80px] rounded-[50%] flex justify-center items-center p-2 bg-[#eee]">
                  {jdData?.companyAvatar && (
                    <img
                      src={jdData.companyAvatar}
                      alt=""
                      className="max-w-full max-h-full object-cover"
                    />
                  )}
                </div>
                <div className="ml-2">
                  <p className="text-black font-[600] text-[18px]">
                    {jdData?.jobName}
                  </p>
                  <p
                    className="cursor-pointer hover:underline"
                    onClick={() =>
                      history.push(
                        routes.recruiterDetail.parentPath +
                          `/${jdData?.recruiterId}`
                      )
                    }
                  >
                    {jdData?.companyName}
                  </p>
                  <div className="flex justify-start items-center">
                    <div className="w-[12px] h-[12px]">
                      <img
                        src={"/asserts/location.png"}
                        alt="location icon"
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <p className="text-[14px] ml-2">
                      {getLocation(jdData?.location)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-1 sm:col-span-3 flex justify-end items-start">
                <div className="flex-col">
                  <div
                    className={cx(
                      "px-3 py-2 text-white rounded-[24px] flex",
                      theme?.button?.background
                    )}
                  >
                    <SendOutlined style={{ fontSize: "12px" }} />
                    <p
                      className="text-[12px] md:text-[14px] text-white ml-2 cursor-pointer"
                      onClick={handleApplyJD}
                    >
                      Ứng tuyển ngay
                    </p>
                  </div>

                  <div className="flex justify-end items-end">
                    <div className="w-[150px] px-3 py-1 text-black rounded-[24px] flex justify-center items-center border border-gray mt-2">
                      <div className="w-[11px] h-[11px]">
                        <img
                          src={"/asserts/vector.png"}
                          alt=""
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <p
                        className="text-[12px] text-black ml-2 cursor-pointer"
                        onClick={handleSaveJD}
                      >
                        Lưu tin tuyển
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr className="my-[36px]" />

            <div className="mt-[40px]">
              <p className="text-black font-[700] text-[20px]">
                Chi tiết công việc
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 mt-4">
                <div>
                  <div className="text-black flex justify-start items-center text-[16px]">
                    <IconCircle />
                    Mức lương
                  </div>
                  <p>
                    {getSalary(
                      jdData?.minSalary,
                      jdData?.maxSalary,
                      jdData?.salary
                    )}
                  </p>
                  <div className="text-black flex justify-start items-center text-[16px] mt-2">
                    <IconCircle />
                    Vị trí
                  </div>
                  <p>{jdData?.levels && jdData.levels[0]}</p>

                  <div className="text-black flex justify-start items-center text-[16px] mt-2">
                    <IconCircle />
                    Số lượng tuyển dụng cho vị trí
                  </div>
                  <p>{jdData?.positions}</p>
                </div>

                <div>
                  <div className="text-black flex justify-start items-center text-[16px] mt-2">
                    <IconCircle />
                    Hình thức
                  </div>
                  <p>{jdData?.jobTypes && jdData.jobTypes[0]}</p>

                  <div className="text-black flex justify-start items-center text-[16px] mt-2">
                    <IconCircle />
                    Kinh nghiệm
                  </div>
                  <p>
                    {jdData?.experience > 0
                      ? jdData.experience + " năm kinh nghiệm"
                      : "Không yêu cầu kinh nghiệm"}
                  </p>

                  <div className="text-black flex justify-start items-center text-[16px] mt-2">
                    <IconCircle />
                    Địa điểm làm việc
                  </div>
                  <p>- {jdData?.location}</p>
                </div>
              </div>
            </div>

            <div className="mt-[40px]">
              <p className="text-black font-[700] text-[20px]">
                Mô tả công việc
              </p>
              <p className="text-[16px] text-black mt-[20px]">
                {jdData?.jobDescription &&
                  jdData.jobDescription.map((item, index) => {
                    if (item && index === 0) {
                      return (
                        <>
                          <p className="text-[18px] font-[600] text-black">
                            {index + 1}. Nhiệm vụ
                          </p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: marked(item),
                            }}
                          />
                        </>
                      );
                    }
                    if (item && index === 1) {
                      return (
                        <>
                          <p className="text-[18px] font-[600] text-black">
                            {index + 1}. Yêu cầu công việc
                          </p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: marked(item),
                            }}
                          />
                        </>
                      );
                    }
                    if (item && index === 2) {
                      return (
                        <>
                          <p className="text-[18px] font-[600] text-black">
                            {index + 1}. Quyền lợi
                          </p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: marked(item),
                            }}
                          />
                        </>
                      );
                    }
                    return null;
                  })}
              </p>
            </div>
          </div>

          <div
            className="w-full min-h-[130px] bg-white rounded-[15px] p-[12px] md:p-[24px] mt-4 cursor-pointer"
            onClick={handleClickCompany}
          >
            <div className="grid grid-cols-7 md:grid-cols-11">
              <div className="w-[80px] h-[80px] rounded-[50%] flex justify-center items-center p-2 bg-[#eee] col-span-2 sm:col-span-1">
                {jdData?.companyAvatar && (
                  <img
                    src={jdData.companyAvatar}
                    alt=""
                    className="max-w-full max-h-full object-cover"
                  />
                )}
              </div>
              <div className="col-span-5 md:col-span-10">
                <p>Công ty</p>
                <p
                  className="text-black font-[600] text-[18px] line-clamp-1 cursor-pointer hover:underline"
                  onClick={() =>
                    history.push(
                      routes.recruiterDetail.parentPath + `/${jdData?.id}`
                    )
                  }
                >
                  <Tooltip title={jdData?.companyName}>
                    {jdData?.companyName}
                  </Tooltip>
                </p>
                <div className="flex justify-start items-center">
                  <div className="w-[12px] h-[12px]">
                    <img
                      src={"/asserts/location.png"}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <p className="text-[14px] ml-2 text-black">
                    {getLocation(jdData?.location)}
                  </p>
                </div>
                <div className="flex justify-start items-center">
                  <div className="w-[12px] h-[12px]">
                    <img
                      src={"/icons/notepad2.png"}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <p className="text-[14px] ml-2">Ghi chú về công ty.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full min-h-[90vh] mt-[40px]">
            <div className="w-full">
              <p
                className={cx(
                  "text-[20px] font-[600]",
                  theme?.background?.text
                )}
              >
                Các công việc liên quan
              </p>
              {listSuggesstion && listSuggesstion.length > 0 ? (
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-[40px]">
                  {listSuggesstion.map((item) => (
                    <CardBox data={item} />
                  ))}
                </div>
              ) : (
                <p className={cx(theme?.background?.sub_text)}>
                  Không tìm thấy công việc phù hợp
                </p>
              )}
            </div>
          </div>
        </div>
        <ApplyCV
          open={openPopup}
          onCancel={() => setOpenPopup(!openPopup)}
          onChangeActive={() => setOpenPopup(!openPopup)}
          recruiterId={jdData && jdData.recruiterId}
          jdName={jdData && jdData.jobName}
          jdId={jdId}
        />
        <ChooseCVNotLogin
          active={openNotLogin}
          onChangeActive={() => setOpenNotLogin(!openNotLogin)}
        />
      </div>
    </>
  );
}

function IconCircle() {
  return (
    <div className="w-3 h-3 bg-[#1495F3] rounded-[50%] relative before:w-1 before:h-1 before:bg-white before:absolute before:top-1 before:left-1 before:rounded-[50%] mr-1"></div>
  );
}

export default WorkDetail;
