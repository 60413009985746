import axios from "axios";

function isTokenExpired(token) {
  if (!token) {
    return true;
  }

  const tokenData = parseToken(token);

  if (!tokenData || !tokenData.exp) {
    return true;
  }

  const currentTime = Math.floor(Date.now() / 1000);

  return tokenData.exp < currentTime;
}

function parseToken(token) {
  try {
    const tokenParts = token.split(".");
    if (tokenParts.length === 3) {
      const payload = JSON.parse(atob(tokenParts[1]));
      return payload;
    }
  } catch (error) {
    console.error("Failed to parse token:", error);
  }
  return null;
}

const apiParsing = axios.create({
  baseURL: "http://192.168.1.32:9004/api/v1/",
  headers: {
    "Content-Type": "application/json",
  },
});

apiParsing.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (token && !isTokenExpired(token)) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      if (token) {
        localStorage.removeItem("User");
        localStorage.removeItem("admin");
        localStorage.removeItem("token");
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiParsing;
