/*
    Màn xác thực tài khoản
*/
import React, { useState } from "react";
import { Divider, Form, Image, notification, Modal } from "antd";
import Stack from "@mui/material/Stack";
import { Bf, Bt } from "../../components/BtForm";
import { FormInputNumber } from "../../components/LoginInput";
import { InfoCircleOutlined, QrcodeOutlined } from "@ant-design/icons";
import { resendOTP } from "../../services/PORT";
import UserService from "../../services/UserService";

const send = "/asserts/Send.png";

export const SignUp3 = (props) => {
  const [checkOTP] = useState(props.checkOTP);
  const [api, contextHolder] = notification.useNotification({ top: 80 });; // định nghĩa thông báo

  const resendClickOTP = () => {
    const dataResend = {
      email: props.data && props.data.email,
      role: props.data && props.data.role,
    };

    UserService.sendData(dataResend, resendOTP)
      .then((res) => {
        api.success({
          message: "Thành công",
          description: "Gửi lại OTP thành công",
          style: { top: 50 },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      open={props.open}
      footer={<></>}
      width={"600px"}
      onCancel={props.onCancel}
    >
      {contextHolder}
      {/* {props.loading ? (
        props.data && props.data.role === "Candidate" ? (
          <Image src={load} preview={false} />
        ) : (
          // history.push('/login', { role: 'Candidate' })
          <Image src={sendRC} preview={false} />
        )
      ) : ( */}
      <Stack direction={"column"} sx={styleLayout1}>
        <htitleMNCV>Xác thực Tài Khoản</htitleMNCV>
        <br />
        <p>Xác thực tài khoản của bạn để tiếp tục.</p>
        <p>
          Chúng tôi đã gửi một mã OTP xác thực tới email{" "}
          {/*<pRadio>0966***628</pRadio>*/}
        </p>
        <p>
          Hãy kiếm tra tin nhắn của bạn và nhập mã OTP được cung cấp để xác thực
          tài khoản của bạn.
        </p>
        <Divider />

        {/* Nút thay đổi mã OTP và trợ giúp xác thực */}
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Bf title={"Thay đổi cách nhận mã OTP"} />
          <Bf title={"Tôi cần trợ giúp với việc xác thực"} />
        </Stack>
        <Divider />

        {/* Phần nhập mã OTP và nút gửi lại mã OTP */}
        <Form layout="vertical">
          <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
            <FormInputNumber
              title={<pRadio>Nhập mã OTP</pRadio>}
              placeholder={"Nhập mã gửi tới điện thoại hoặc email"}
              style={styleInput}
              styles={{ width: "80%" }}
              prefix={<QrcodeOutlined style={{ color: styleBt2.background }} />}
              onChange={props.onChange}
            />
            <Form.Item label=" ">
              <Bt
                title={"Gửi lại mã"}
                style={styleBt2}
                onClick={resendClickOTP}
              />
            </Form.Item>
          </Stack>
        </Form>

        {/* Thông báo thông tin OTP không chính xác */}
        {checkOTP && (
          <Stack
            direction={"row"}
            spacing={1}
            justifyContent="flex-start"
            alignItems={"center"}
            sx={{ color: "#E60019", width: "100%" }}
          >
            <InfoCircleOutlined />
            <div>Mã OTP không chính xác</div>
          </Stack>
        )}
        <Divider />
        <Bt title={"Xác thực"} style={styleBt2} onClick={props.onCickConfirm} />
        <Image src={send} preview={false} />
      </Stack>
      {/* )} */}
    </Modal>
  );
};

const styleLayout1 = {
  height: "100%",
};

const styleInput = {
  borderRadius: 10,
  width: "100%",
  height: "40px",
  paddingRight: "2%",
  paddingTop: "0.6%",
  paddingBottom: "0.5%",
};

const styleBt2 = {
  borderRadius: 12,
  background: "var(--blue-01, #05F)",
  width: "100%",
  height: "40px",
};
