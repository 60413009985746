import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import UserService from "../../services/UserService";
import { getImage, listJDValidated } from "../../services/PORT";
import { routes } from "../PathRouting";
import {
  CardHomepage,
  CardHomepageAdding,
} from "../../components/Recruiter/CardHomepage";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";
import queryString from "query-string";
import { getImageUrl, getLocation } from "../../utils";
import CardCvCreate from "../../components/Card/CardCvCreate";
import ShowCandidateCv from "../../components/Modals/ShowCandidateCv";
import dayjs from "dayjs";
import RecruiterService from "../../services/RecruiterService";
import RecruiterConfirmSign from "../../components/Modals/RecruiterConfirmSign";

function RecruiterDashboard() {
  const { theme } = useTheme();
  const [listJD, setListJD] = useState();
  const [logo, setLogo] = useState();
  const [listCvs, setListCvs] = useState();
  const [refCode, setRefCode] = useState();
  const [totalCv, setTotalCv] = useState(0);

  const [active, setActive] = useState(false);
  const [cvShow, setCvShow] = useState();

  const [recruiter, setRecruiter] = useState();
  const user = JSON.parse(localStorage.getItem("User"));

  const history = useHistory();

  useEffect(() => {
    if (!recruiter) getRecruiterInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recruiter]);

  useEffect(() => {
    if (!listJD && recruiter) {
      getListJd();
      getListCv();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listJD, recruiter]);

  const getRecruiterInfo = async () => {
    try {
      const params = queryString.stringify({
        email: user?.email,
        number: 1,
      });
      const response = await RecruiterService.getRecruiterInfo(params);
      const data = response.data.data;
      setRecruiter(data[0]);
      setRefCode(data[1]);
    } catch (error) {
      console.log(new Error(error));
    }
  };

  const getListJd = async () => {
    const image = await UserService.getImage(getImage, {
      filename: recruiter.avatarImg,
    });
    setLogo(image.data?.data[0]?.fileUrl);
    const response = await UserService.getListJDByRecruiterID(
      listJDValidated,
      recruiter.id,
      true,
      0,
      3,
      "ACTIVE"
    );
    const data = response.data.data;
    if (data.length < 2) setListJD([]);
    else setListJD(data.filter((item) => !item.count));
  };

  const getListCv = async () => {
    const params = queryString.stringify({
      status: "POTENTIAL",
      offset: 0,
      limit: 24,
    });

    const response = await UserService.getAllCvShow(params);

    let data = response.data.data;
    data = data.items.filter((c) => c.pdfImage);

    let listPdfImage = data.map((item) => item.pdfImage);
    listPdfImage = await UserService.getImageByName(listPdfImage);

    const result = data.map((item) => {
      const profile = item.blockInfo[0].builder.profile;

      return {
        ...profile,
        location: getLocation(profile.location),
        image: getImageUrl(listPdfImage.data.data, item.pdfImage),
        filename: item.cvName + `-cv.pdf`,
        name: item.cvName,
        original_image: item.pdfImage,
        createAt: dayjs(new Date(item.createAt)).format("DD-MM-YYYY"),
      };
    });

    setListCvs(result);
    setTotalCv(data.length);
  };

  return (
    <div
      className={cx(
        "px-[5%] py-[4%] min-h-[100vh] pb-[120px] space-y-4",
        theme?.background?.text
      )}
    >
      <div>
        <h1 className="text-blue-500 font-[600] text-[18px]">
          Dashboard nhà tuyển dụng
        </h1>
        <h3 className={cx("font-[600] text-[34px]", theme?.background?.text)}>
          {recruiter?.enterprise}
        </h3>
      </div>
      <div className="w-full bg-white rounded-[18px] shadow-md relative">
        <div className="grid grid-cols-4 md:grid-cols-10 px-6 py-4">
          <div className="col-span-1 flex justify-start items-center">
            <div className="w-[80px] h-[80px] rounded-[50%] flex justify-center items-center">
              <img
                src={logo}
                alt=""
                className="max-w-full max-h-full object-cover"
              />
            </div>
          </div>
          <div className="col-span-3 pl-4 md:pl-0 md:col-span-7">
            <p className="font-[600] text-black">Công ty</p>
            <p className="text-[18px] text-black font-[600]">
              {recruiter?.enterprise}
            </p>
            <div className="text-black">
              <p>
                <b>Mã giới thiệu:</b>{" "}
                {refCode?.code ? refCode.code : "Chưa cập nhật"}
              </p>
              <p>
                <b>Link liên kết:</b>{" "}
                {refCode?.link ? refCode.link : "Chưa cập nhật"}
              </p>
            </div>
            <div className="flex justify-start items-center">
              <img
                src="/asserts/location.png"
                alt=""
                className="w-[18px] h-[18px]"
              />
              <p className="ml-1 text-black">
                {recruiter?.enterpriseAddress &&
                recruiter.enterpriseAddress.length > 0
                  ? recruiter.enterpriseAddress[0]
                  : "Chưa cập nhật địa chỉ"}
              </p>
            </div>
            <div className="flex justify-start items-center">
              <img
                src="/icons/notepad2.png"
                alt=""
                className="w-[18px] h-[18px]"
              />
              <p className="ml-1 text-black">
                {recruiter?.note || "Ghi chú về công ty."}
              </p>
            </div>
          </div>
          <div className="col-span-4 mt-2 md:mt-0 md:col-span-2 flex justify-end items-start">
            <div
              className="px-4 py-2 bg-[#1495F3] text-[12px] text-white rounded-[14px] cursor-pointer flex justify-center items-center"
              onClick={() => history.push(routes.recuiterInfo.path)}
            >
              Xem chi tiết{" "}
              <span className="text-[18px] ml-1 flex justify-center items-center">
                &gt;
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-between">
        <h1 className="font-[600] text-[24px]">Quản lý bài tuyển dụng</h1>
        <span
          className="cursor-pointer hover:underline text-[14px]"
          onClick={() => history.push(routes.recruiterManageJd.path)}
        >
          Xem tất cả &gt;
        </span>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        <CardHomepageAdding
          onClick={() => history.push(routes.recruiterCreateJd.path)}
        />
        {listJD &&
          listJD.length > 0 &&
          listJD.map((item, idx) => {
            return (
              <CardHomepage
                key={"job-" + idx + "-list-item"}
                jobName={item.jobName}
                location={item.location}
                createAt={item.createAt}
                count={item.countCVs}
                status={item.status}
                onClick={() =>
                  history.push(routes.detailJDs.parentPath + `/${item.id}`)
                }
              />
            );
          })}
      </div>
      <div className="">
        <h4 className={cx(theme?.background?.text)}>
          Danh sách CV tiềm năng ({totalCv})
        </h4>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 my-[40px]">
          {listCvs &&
            totalCv > 0 &&
            listCvs.map((data, idx) => (
              <CardCvCreate
                key={"cv-" + idx + "-card-item"}
                data={data}
                isUpload
                onChangeImage={(value) => {
                  setActive(!active);
                  setCvShow(value);
                }}
              />
            ))}
        </div>
      </div>
      <ShowCandidateCv
        active={active}
        onChangeActive={() => setActive(!active)}
        data={cvShow}
      />
      <RecruiterConfirmSign data={recruiter} />
    </div>
  );
}

export default RecruiterDashboard;
