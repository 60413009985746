export const OPTION_SALARY = [
  {
    value: "Salary_Range",
    label: "Khoảng Lương",
  },
  {
    value: "Final_Salary",
    label: "Chính Xác",
  },
  {
    value: "Starting_Salary",
    label: "Lương Khởi Điểm",
  },
  {
    value: "Exchange_Salary",
    label: "Lương thỏa thuận",
  },
];

export const ADMIN_ROLE = {
  SUPPER_ADMIN: "Super Admin",
  POST_MANAGER: "Post Manager",
  ADS_MANAGER: "Ads Manager",
};

export const OPTION_MONTH = [
  {
    value: "Day",
    label: "Ngày",
  },
  {
    value: "Month",
    label: "Tháng",
  },
  {
    value: "Year",
    label: "Năm",
  },
];

export const OPTION_EDUCATION = [
  {
    value: "Vocational",
    label: "Trung cấp nghề/Trung cấp chuyên nghiệp",
  },
  {
    value: "University",
    label: "Đại học/Cao đẳng",
  },
  {
    value: "Postgraduate",
    label: "Cao học",
  },
  {
    value: "Null",
    label: "Không yêu cầu",
  },
];

export const DATA_PAYMENT_FAKE = [
  {
    code: "R01",
    name: "COMBO SUPER R01",
    description: [
      "Trong 30 ngày, hiển thị ưu tiên, gợi ý cho ứng viên trên đầu trang thuộc nhóm ngành nghề liên quan.",
      "Biên tập viết JD hấp dẫn, hiệu quả từ những chuyên gia MPB hàng đầu,có kinh nghiệm chuyên sâu trong ngành nhân sự.",
      "Cam kết có hồ sơ nhanh chóng, có số lượng đạt yêu cầu của khách hàng.",
      "Tuyển dụng cực hiệu quả các vị trí cấp nhân viên từ 0-5 năm kinh nghiệm.",
      "CV đã trải qua khâu lọc hồ sơ theo tiêu chí tuyển dụng",
    ],
    detail: [
      {
        title: "Đăng tuyển TOP ngành",
        date: "30 ngày",
        amount: 1,
        price: 1290000,
      },
      {
        title: "Biên tập JD chuẩn nghiệp vụ",
        date: "",
        amount: 1,
        price: 1290000,
      },
      {
        title: "Cam kêt có hồ sơ",
        date: "30 ngày",
        amount: 10,
        price: 5000000,
        number: 1,
      },
      {
        title: "Cam kêt có hồ sơ",
        date: "30 ngày",
        amount: 15,
        price: 6750000,
        number: 2,
      },
      {
        title: "Cam kêt có hồ sơ",
        date: "30 ngày",
        amount: 30,
        price: 12000000,
        number: 3,
      },
      {
        title: "Cam kêt có hồ sơ",
        date: "30 ngày",
        amount: 40,
        price: 14000000,
        number: 4,
      },
    ],
  },
  {
    code: "R02",
    name: "COMBO SUPER R02",
    description: [
      "Trong 30 ngày, hiển thị ưu tiên, gợi ý cho ứng viên trên đầu trang thuộc nhóm ngành nghề liên quan.",
      "Biên tập viết JD hấp dẫn, hiệu quả từ những chuyên gia MPB hàng đầu,có kinh nghiệm chuyên sâu trong ngành nhân sự.",
      "Cam kết có hồ sơ nhanh chóng, có số lượng đạt yêu cầu của khách hàng.",
      "Tuyển dụng cực hiệu quả các vị trí cấp nhân viên từ 0-5 năm kinh nghiệm.",
      "CV đã trải qua khâu lọc hồ sơ theo tiêu chí tuyển dụng",
    ],
    detail: [
      {
        title: "Đăng tuyển TOP ngành",
        date: "30 ngày",
        amount: 1,
        price: 1990000,
      },
      {
        title: "Biên tập JD chuẩn nghiệp vụ",
        date: "",
        amount: 1,
        price: 1290000,
      },
      {
        title: "Cam kêt có hồ sơ",
        date: "30 ngày",
        amount: 10,
        price: 8000000,
        number: 1,
      },
      {
        title: "Cam kêt có hồ sơ",
        date: "30 ngày",
        amount: 15,
        price: 9000000,
        number: 2,
      },
      {
        title: "Cam kêt có hồ sơ",
        date: "30 ngày",
        amount: 30,
        price: 15000000,
        number: 3,
      },
      {
        title: "Cam kêt có hồ sơ",
        date: "30 ngày",
        amount: 40,
        price: 18000000,
        number: 4,
      },
    ],
  },
  {
    code: "R03",
    name: "COMBO SUPER R03",
    description: [
      "Trong 30 ngày, hiển thị ưu tiên, gợi ý cho ứng viên trên đầu trang thuộc nhóm ngành nghề liên quan.",
      "Biên tập viết JD hấp dẫn, hiệu quả từ những chuyên gia MPB hàng đầu,có kinh nghiệm chuyên sâu trong ngành nhân sự.",
      "Cam kết có hồ sơ nhanh chóng, có số lượng đạt yêu cầu của khách hàng.",
      "Tuyển dụng cực hiệu quả các vị trí cấp nhân viên từ 0-5 năm kinh nghiệm.",
      "CV đã trải qua khâu lọc hồ sơ theo tiêu chí tuyển dụng",
    ],
    detail: [
      {
        title: "Đăng tuyển TOP ngành",
        date: "30 ngày",
        amount: 1,
        price: 1990000,
      },
      {
        title: "Biên tập JD chuẩn nghiệp vụ",
        date: "",
        amount: 1,
        price: 1290000,
      },
      {
        title: "Cam kêt có hồ sơ",
        date: "30 ngày",
        amount: 10,
        price: 8000000,
        number: 1,
      },
      {
        title: "Cam kêt có hồ sơ",
        date: "30 ngày",
        amount: 15,
        price: 9000000,
        number: 2,
      },
      {
        title: "Cam kêt có hồ sơ",
        date: "30 ngày",
        amount: 30,
        price: 15000000,
        number: 3,
      },
      {
        title: "Cam kêt có hồ sơ",
        date: "30 ngày",
        amount: 40,
        price: 18000000,
        number: 4,
      },
    ],
  },
];

export const DATA_PAYMENT_CANDIDATE = [
  {
    name: "Tư Vấn Tiếng Việt",
    sub_name: "Review CV",
    price: 100000,
    sub_price: 400000,
    description: [
      "Chuyên gia đánh giá, nhận xét và gợi ý giải pháp sửa đổi CV để ghi điểm với nhà tuyển dụng, tăng cơ hội mời phỏng vấn.",
      "Chuyên gia đồng thời tư vấn thêm các nội dung: cách viết trong CV, phong cách thiết kế phù hợp với lĩnh vực bạn đang quan tâm, cách viết trong các trường hợp ít kinh nghiệm, chuyển ngành, gap-year, cách viết thích hợp với vị trí và ngành nghề của bạn,...",
      "Sau khi nhận email góp ý lần đầu từ LDO, bạn có thể phản hồi lại trong vòng 3 ngày.",
    ],
    image: "/asserts/Banner1.jpg",
  },
  {
    name: "Tư Vấn Tiếng Anh",
    sub_name: "Review CV",
    price: 300000,
    sub_price: 500000,
    description: [
      "Chuyên gia nhận xét và gợi ý cách sửa thiết kế, câu từ tiếng Anh, chỉ lỗi ngữ pháp để ghi điểm với nhà tuyển dụng, tăng cơ hội mời phỏng vấn.",
      "Chuyên gia gợi ý cách viết trong các trường hợp ít kinh nghiệm, chuyển ngành, gap-year, cách viết thích hợp với vị trí và ngành nghề của bạn,...",
      "Chuyên gia giải đáp các câu hỏi bạn đang băn khoăn về hồ sơ trong đơn đăng ký.",
      "Sau khi nhận email góp ý lần đầu từ LDO, bạn có thể phản hồi lại trong vòng 3 ngày.",
    ],
    image: "/asserts/Banner1.jpg",
  },
  {
    name: "Dịch CV Việt - Anh",
    sub_name: "CV Translation: Vietnamese to English",
    price: 100000,
    sub_price: 400000,
    description: [
      "Chuyên gia đánh giá, nhận xét và gợi ý giải pháp sửa đổi CV để ghi điểm với nhà tuyển dụng, tăng cơ hội mời phỏng vấn.",
      "Chuyên gia đồng thời tư vấn thêm các nội dung: cách viết trong CV, phong cách thiết kế phù hợp với lĩnh vực bạn đang quan tâm, cách viết trong các trường hợp ít kinh nghiệm, chuyển ngành, gap-year, cách viết thích hợp với vị trí và ngành nghề của bạn,...",
      "Chuyên gia đánh giá, nhận xét và gợi ý giải pháp sửa đổi CV để ghi điểm với nhà tuyển dụng, tăng cơ hội mời phỏng vấn.",
    ],
    image: "/asserts/Banner1.jpg",
  },
  {
    name: "Dịch và Tư Vấn CV Việt - Anh",
    sub_name: "CV Translation and review: Vietnamese to English",
    price: 100000,
    sub_price: 400000,
    description: [
      "Chuyên gia đánh giá, nhận xét và gợi ý giải pháp sửa đổi CV để ghi điểm với nhà tuyển dụng, tăng cơ hội mời phỏng vấn.",
      "Chuyên gia đồng thời tư vấn thêm các nội dung: cách viết trong CV, phong cách thiết kế phù hợp với lĩnh vực bạn đang quan tâm, cách viết trong các trường hợp ít kinh nghiệm, chuyển ngành, gap-year, cách viết thích hợp với vị trí và ngành nghề của bạn,...",
      "Chuyên gia đánh giá, nhận xét và gợi ý giải pháp sửa đổi CV để ghi điểm với nhà tuyển dụng, tăng cơ hội mời phỏng vấn.",
    ],
    image: "/asserts/Banner1.jpg",
  },
  {
    name: "Viết CV Nhanh Tiếng Anh",
    sub_name: "CV Translation and review: Vietnamese to English",
    price: 100000,
    sub_price: 500000,
    description: [
      "Chuyên gia đánh giá, nhận xét và gợi ý giải pháp sửa đổi CV để ghi điểm với nhà tuyển dụng, tăng cơ hội mời phỏng vấn.",
      "Chuyên gia đồng thời tư vấn thêm các nội dung: cách viết trong CV, phong cách thiết kế phù hợp với lĩnh vực bạn đang quan tâm, cách viết trong các trường hợp ít kinh nghiệm, chuyển ngành, gap-year, cách viết thích hợp với vị trí và ngành nghề của bạn,...",
      "Chuyên gia đánh giá, nhận xét và gợi ý giải pháp sửa đổi CV để ghi điểm với nhà tuyển dụng, tăng cơ hội mời phỏng vấn.",
    ],
    image: "/asserts/Banner1.jpg",
  },
];
