import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  changeActions,
  selectActions,
  selectFormHeader,
  selectFormOrder,
  selectFormToShow,
  selectSetting,
} from "../../redux/slice/setting";
import { selectBuilder } from "../../redux/slice/builder";
import { cx } from "../../../lib/cx";
import { convertImageToBase64, formatContentCv } from "../../configs";
import {
  Email,
  Facebook,
  LinkedIn,
  LocationCity,
  Person,
  Phone,
} from "@mui/icons-material";
import { getSubColor } from "../../configs/data";
import CopyRightComponent from "../SubComponent/CopyRightComponent";

function ReviewCV004Modal({ id }) {
  const { openPreview } = useAppSelector(selectActions);
  const setting = useAppSelector(selectSetting);
  const builder = useAppSelector(selectBuilder);
  const { profile, objective, avatar } = builder;
  const { left, right } = useAppSelector(selectFormOrder);
  const formToShow = useAppSelector(selectFormToShow);
  const formHeader = useAppSelector(selectFormHeader);

  const dispatch = useAppDispatch();

  useEffect(() => {
    convertImageToBase64(avatar.url, (base64Image) => {
      const imgElement = document.getElementById("avatar-img");
      if (imgElement) {
        imgElement.src = base64Image;
      }
    });
  }, [avatar.url]);

  const styleTitle = {
    fontFamily: `${setting.fontFamily}`,
    fontSize: `${Number(setting.fontSize) + 4}px`,
    color: `${setting.themeColor}`,
  };

  const styleText = {
    fontFamily: `${setting.fontFamily}`,
    fontSize: `${Number(setting.fontSize)}px`,
  };

  const styleIcon = {
    width: `${Number(setting.fontSize) + 8}px`,
    height: `${Number(setting.fontSize) + 8}px`,
    fontSize: `${Number(setting.fontSize) + 4}px`,
    color: `${setting.themeColor}`,
    marginTop: "3px",
    marginRight: "5px",
    padding: "0px",
    display: "inline-block",
  };

  const infoProfile = [
    {
      name: profile?.dateOfBirdth,
      icon: <Person style={styleIcon} />,
    },
    {
      name: profile?.email,
      icon: <Email style={styleIcon} />,
    },
    {
      name: profile?.facebook,
      icon: <Facebook style={styleIcon} />,
    },
    {
      name: profile?.linkedin,
      icon: <LinkedIn style={styleIcon} />,
    },
    {
      name: profile?.phoneNumber,
      icon: <Phone style={styleIcon} />,
    },
    {
      name: profile?.location,
      icon: <LocationCity style={styleIcon} />,
    },
  ];

  return (
    <div
      className={cx(
        `overflow-x-auto md:py-[180px] xl:py-0 fixed inset-0 z-9999 h-screen outline-none focus:outline-none`,
        openPreview ? "block" : "hidden"
      )}
      onClick={() => dispatch(changeActions({ field: "openPreview" }))}
    >
      <div className="relative w-full min-h-[100vh]">
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-20"></div>
        <div className="flex justify-center items-center mx-auto w-full px-[10px] py-[72px] min-h-[100vh] select-none">
          <div
            className="w-[793px] min-h-[1112px] bg-white relative"
            style={{
              fontFamily: `${setting.fontFamily}`,
            }}
            id={id}
          >
            <div className="flex">
              <div
                className="w-[322px] min-h-[1112px] pt-[30px] pb-4 px-4"
                style={{
                  background: `${getSubColor(
                    setting.templateName,
                    setting.themeColor
                  )}`,
                }}
              >
                <p
                  className="font-[700] text-center"
                  style={{
                    fontFamily: `${setting.fontFamily}`,
                    fontSize: `${Number(setting.fontSize) + 20}px`,
                    color: `${setting.themeColor}`,
                  }}
                >
                  {profile?.fullName}
                </p>
                <p
                  className="font-[600] text-center"
                  style={{
                    fontFamily: `${setting.fontFamily}`,
                    fontSize: `${Number(setting.fontSize) + 4}px`,
                  }}
                >
                  {profile?.applyPosition}
                </p>
                <div className="flex justify-center items-center">
                  <div className="w-[285px] h-[285px] p-4">
                    <div
                      className="w-full h-full bg-white cursor-pointer rounded-full "
                      style={{ border: `4px solid ${setting.themeColor}` }}
                    >
                      <img
                        id="avatar-img"
                        src={avatar.url}
                        alt=""
                        className="w-full h-full object-cover rounded-full"
                      />
                    </div>
                  </div>
                </div>

                <p className="font-[600] mt-2" style={styleTitle}>
                  Thông tin cá nhân
                </p>
                {infoProfile.map((item) => {
                  if (item.name) {
                    return (
                      <>
                        <p className="">
                          <span>{item.icon}</span>
                          <span className="mb-4" style={styleText}>
                            {item.name}
                          </span>
                        </p>
                      </>
                    );
                  }
                  return null;
                })}
                <div
                  style={{
                    border: `1px solid ${setting.themeColor}`,
                    marginTop: "10px",
                  }}
                />
                {left &&
                  left.map((item) => {
                    if (formToShow[item]) {
                      return (
                        <>
                          <p className="font-[700]" style={styleTitle}>
                            {formHeader[item]}
                          </p>
                          {builder[item] &&
                            builder[item].map((c) => (
                              <>
                                <p
                                  className={cx(
                                    "my-1",
                                    item === "skills" && "font-[600]"
                                  )}
                                  style={styleText}
                                >
                                  {c?.name}
                                </p>
                                {c?.description && (
                                  <p className="italic" style={styleText}>
                                    {c.description}
                                  </p>
                                )}
                              </>
                            ))}
                          <div
                            style={{
                              border: `1px solid ${setting.themeColor}`,
                              marginTop: "10px",
                            }}
                          />
                        </>
                      );
                    }
                    return null;
                  })}
              </div>
              <div className="w-[469px] h-full p-3">
                <div>
                  {right &&
                    right.map((item) => {
                      if (formToShow[item]) {
                        if (item === "objective") {
                          return (
                            <>
                              <div className="w-full">
                                <p
                                  className="text-black pb-1"
                                  style={styleTitle}
                                >
                                  <b>Mục tiêu nghề nghiệp</b>
                                </p>
                              </div>
                              <p style={styleText}>
                                {formatContentCv(objective, "objective")}
                              </p>
                              <div
                                style={{
                                  border: `1px solid ${setting.themeColor}`,
                                  marginTop: "10px",
                                }}
                              />
                            </>
                          );
                        }
                        if (
                          [
                            "cetifications",
                            "honors_and_awards",
                            "interestes",
                            "addtional_informations",
                          ].includes(item)
                        ) {
                          return (
                            <div className="mt-1">
                              <p style={styleTitle}>
                                <b>{formHeader[item]}</b>
                              </p>
                              {builder[item] &&
                                builder[item].map((c) => (
                                  <div className="flex space-x-2 pb-2">
                                    {c?.description && (
                                      <p
                                        className={cx(
                                          "italic w-[25%]",
                                          item === "skills" && "font-[600]"
                                        )}
                                        style={styleText}
                                      >
                                        {c.description}
                                      </p>
                                    )}
                                    <p
                                      className={cx(
                                        item === "skills" && "font-[600]"
                                      )}
                                      style={styleText}
                                    >
                                      {formatContentCv(c?.name, item)}
                                    </p>
                                  </div>
                                ))}
                              <div
                                style={{
                                  border: `1px solid ${setting.themeColor}`,
                                  marginTop: "10px",
                                }}
                              />
                            </div>
                          );
                        }
                        return (
                          <>
                            <div className="mt-2">
                              <div className="w-full">
                                <p style={styleTitle}>
                                  <b>{formHeader[item]}</b>
                                </p>
                              </div>
                              {builder[item] &&
                                builder[item].map((c) => (
                                  <>
                                    <div className="w-full mt-1">
                                      <div className="w-full flex justify-between items-center">
                                        <div className="w-[60%]">
                                          <p
                                            className="text-black font-[600]"
                                            style={{
                                              fontFamily: `${setting.fontFamily}`,
                                              fontSize: `${
                                                Number(setting.fontSize) + 4
                                              }px`,
                                            }}
                                          >
                                            {c?.title}
                                          </p>
                                        </div>
                                        {(c?.start_time || c?.end_time) && (
                                          <div
                                            className="text-black italic font-[600]"
                                            style={styleText}
                                          >
                                            {c?.start_time}
                                            <span> - </span>
                                            {c?.end_time}
                                          </div>
                                        )}
                                      </div>

                                      <div className="">
                                        {item === "projects" && (
                                          <span
                                            className="mr-1 italic"
                                            style={styleText}
                                          >
                                            <b>Số lượng người tham gia: </b>
                                          </span>
                                        )}
                                        <span
                                          className="italic"
                                          style={styleText}
                                        >
                                          <b>{c?.label}</b>
                                        </span>
                                      </div>

                                      {c?.sub_label && (
                                        <div className="">
                                          {item === "projects" && (
                                            <span
                                              className="mr-1 italic"
                                              style={styleText}
                                            >
                                              <b>Vị trí của bạn: </b>
                                            </span>
                                          )}
                                          <span
                                            className="italic"
                                            style={styleText}
                                          >
                                            <b>{c?.sub_label}</b>
                                          </span>
                                        </div>
                                      )}
                                      <p style={styleText}>
                                        {formatContentCv(c?.description, item)}
                                      </p>
                                    </div>
                                  </>
                                ))}
                            </div>
                            <div
                              style={{
                                border: `1px solid ${setting.themeColor}`,
                                marginTop: "10px",
                              }}
                            />
                          </>
                        );
                      }
                      return null;
                    })}
                </div>
              </div>
            </div>
            <CopyRightComponent />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewCV004Modal;
