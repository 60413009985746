import React, { useEffect } from "react";
import { cx } from "../../../lib/cx";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  changeActions,
  selectActions,
  selectFormHeader,
  selectFormOrder,
  selectFormToShow,
  selectSetting,
} from "../../redux/slice/setting";
import CopyRightComponent from "../SubComponent/CopyRightComponent";
import { selectBuilder } from "../../redux/slice/builder";
import { convertImageToBase64, formatContentCv } from "../../configs";
import { useProfileData } from "../../configs/profileData";

function ReviewCV006Modal({ id }) {
  const { openPreview } = useAppSelector(selectActions);
  const setting = useAppSelector(selectSetting);
  const builder = useAppSelector(selectBuilder);
  const { profile, objective, avatar } = builder;
  const { left, right } = useAppSelector(selectFormOrder);
  const formToShow = useAppSelector(selectFormToShow);
  const formHeader = useAppSelector(selectFormHeader);

  const { infoProfile } = useProfileData(setting.themeColor);

  const dispatch = useAppDispatch();

  useEffect(() => {
    convertImageToBase64(avatar.url, (base64Image) => {
      const imgElement = document.getElementById("avatar-img");
      if (imgElement) {
        imgElement.src = base64Image;
      }
    });
  }, [avatar.url]);

  const styleTitle = {
    fontFamily: `${setting.fontFamily}`,
    fontSize: `${Number(setting.fontSize) + 4}px`,
    color: `${setting.themeColor}`,
  };

  const styleText = {
    fontFamily: `${setting.fontFamily}`,
    fontSize: `${Number(setting.fontSize)}px`,
  };

  return (
    <div
      className={cx(
        `overflow-x-auto md:py-[180px] xl:py-0 fixed inset-0 z-9999 h-screen outline-none focus:outline-none`,
        openPreview ? "block" : "hidden"
      )}
      onClick={() => dispatch(changeActions({ field: "openPreview" }))}
    >
      <div className="relative w-full min-h-[100vh]">
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-20"></div>
        <div className="flex justify-center items-center mx-auto w-full px-[10px] py-[72px] min-h-[100vh] select-none">
          <div
            className="w-[793px] min-h-[1112px] bg-white relative"
            style={{
              fontFamily: `${setting.fontFamily}`,
            }}
            id={id}
          >
            <div className="flex">
              <div className="w-[396px] min-h-[1112px]">
                <div className="flex justify-center items-center">
                  <div className="w-[265px] h-[265px] p-4">
                    <div
                      className="w-full h-full bg-gray-1 cursor-pointer rounded-full "
                      style={{ border: `4px solid ${setting.themeColor}` }}
                    >
                      <img
                        id="avatar-img"
                        src={avatar.url}
                        alt=""
                        className="w-full h-full object-cover rounded-full"
                      />
                    </div>
                  </div>
                </div>
                <p
                  className="font-[700] text-center"
                  style={{
                    fontFamily: `${setting.fontFamily}`,
                    fontSize: `${Number(setting.fontSize) + 20}px`,
                  }}
                >
                  {profile?.fullName}
                </p>
                <p
                  className="font-[600] text-center italic"
                  style={{
                    fontFamily: `${setting.fontFamily}`,
                    fontSize: `${Number(setting.fontSize) + 4}px`,
                  }}
                >
                  {profile?.applyPosition}
                </p>
                <p
                  className="font-[600] px-4"
                  style={{
                    fontFamily: `${setting.fontFamily}`,
                    fontSize: `${Number(setting.fontSize) + 8}px`,
                    color: `${setting.themeColor}`,
                  }}
                >
                  Thông tin cá nhân
                </p>
                {infoProfile.map((item) => {
                  if (item.name) {
                    return (
                      <>
                        <p className="px-4">
                          <span>{item.icon}</span>
                          <span className="mb-4" style={styleText}>
                            {item.name}
                          </span>
                        </p>
                      </>
                    );
                  }
                  return null;
                })}
                <div className="px-4 pt-2">
                  {left &&
                    left.map((item) => {
                      if (["presenter"].includes(item)) {
                        return (
                          <>
                            <p
                              className="font-[700] mt-2 relative"
                              style={styleTitle}
                            >
                              {formHeader[item]}
                              <hr
                                className={cx("w-[55%] absolute right-0 top-6")}
                                style={{ color: `${setting.themeColor}` }}
                              />
                            </p>
                            {builder[item] &&
                              builder[item].map((c) => (
                                <>
                                  <p className="mt-2" style={styleText}>
                                    {c?.name}
                                  </p>
                                  {c?.description && (
                                    <p style={styleText}>{c.description}</p>
                                  )}
                                  {c?.email && (
                                    <p style={styleText}>Email: {c.email}</p>
                                  )}
                                  {c?.phone && (
                                    <p style={styleText}>
                                      Điện thoại: {c.phone}
                                    </p>
                                  )}
                                </>
                              ))}
                          </>
                        );
                      }
                      if (formToShow[item]) {
                        if (item === "objective") {
                          return (
                            <>
                              <div className="w-full">
                                <p
                                  className="text-black pb-1 relative"
                                  style={styleTitle}
                                >
                                  <b>Mục tiêu nghề nghiệp</b>
                                  <hr
                                    className={cx(
                                      "w-[40%] absolute right-0 top-6"
                                    )}
                                    style={{ color: `${setting.themeColor}` }}
                                  />
                                </p>
                              </div>
                              <p style={styleText}>
                                {formatContentCv(objective, "objective")}
                              </p>
                            </>
                          );
                        }
                        return (
                          <>
                            <p
                              className="font-[700] mt-2 relative"
                              style={styleTitle}
                            >
                              {formHeader[item]}
                              <hr
                                className={cx("w-[75%] absolute right-0 top-6")}
                                style={{ color: `${setting.themeColor}` }}
                              />
                            </p>
                            {builder[item] &&
                              builder[item].map((c) => (
                                <>
                                  <p
                                    className={cx("my-1 font-[600]")}
                                    style={styleText}
                                  >
                                    {c?.name}
                                  </p>
                                  {c?.description && (
                                    <p className="italic" style={styleText}>
                                      {c.description}
                                    </p>
                                  )}
                                </>
                              ))}
                          </>
                        );
                      }
                      return null;
                    })}
                </div>
              </div>
              <div className="w-[397px] min-h-[1112px] px-4 pt-[30px]">
                {right &&
                  right.map((item) => {
                    if (formToShow[item]) {
                      if (
                        [
                          "cetifications",
                          "honors_and_awards",
                          "addtional_informations",
                        ].includes(item)
                      ) {
                        return (
                          <div className="mt-1">
                            <p className="relative" style={styleTitle}>
                              <b className="">{formHeader[item]}</b>
                              <hr
                                className={cx(
                                  "absolute right-0 top-6",
                                  item === "honors_and_awards" && "w-[35%]",
                                  item === "cetifications" && "w-[70%]",
                                  item === "addtional_informations" && "w-[60%]"
                                )}
                                style={{ color: `${setting.themeColor}` }}
                              />
                            </p>
                            {builder[item] &&
                              builder[item].map((c) => (
                                <div className="flex space-x-2 pb-2">
                                  {c?.description && (
                                    <p
                                      className={cx("italic w-[25%]")}
                                      style={styleText}
                                    >
                                      {c.description}
                                    </p>
                                  )}
                                  <p style={styleText}>
                                    {formatContentCv(c?.name, item)}
                                  </p>
                                </div>
                              ))}
                          </div>
                        );
                      }
                      return (
                        <>
                          <div className="mt-2">
                            <div className="w-full">
                              <p className="relative" style={styleTitle}>
                                <b>{formHeader[item]}</b>
                                <hr
                                  className={cx(
                                    "absolute right-0 top-6",
                                    item === "educations" && "w-[75%]",
                                    item === "workExperiences" && "w-[45%]",
                                    item === "activeties" && "w-[70%]"
                                  )}
                                  style={{ color: `${setting.themeColor}` }}
                                />
                              </p>
                            </div>
                            {builder[item] &&
                              builder[item].map((c) => (
                                <>
                                  <div className="w-full mt-1">
                                    <div className="w-full">
                                      <div className="w-[60%]">
                                        <p
                                          className="text-black font-[600]"
                                          style={{
                                            fontFamily: `${setting.fontFamily}`,
                                            fontSize: `${
                                              Number(setting.fontSize) + 4
                                            }px`,
                                          }}
                                        >
                                          {c?.title}
                                        </p>
                                      </div>
                                      {(c?.start_time || c?.end_time) && (
                                        <div
                                          className="text-black italic font-[600]"
                                          style={styleText}
                                        >
                                          {c?.start_time}
                                          <span> - </span>
                                          {c?.end_time}
                                        </div>
                                      )}
                                    </div>

                                    <p className="italic" style={styleText}>
                                      <b>{c?.label}</b>
                                    </p>

                                    {c?.sub_label && (
                                      <p className="italic" style={styleText}>
                                        <b>{c?.sub_label}</b>
                                      </p>
                                    )}
                                    <p style={styleText}>
                                      {formatContentCv(c?.description, item)}
                                    </p>
                                  </div>
                                </>
                              ))}
                          </div>
                        </>
                      );
                    }
                    return null;
                  })}
              </div>
            </div>
            <CopyRightComponent className="!text-black" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewCV006Modal;
