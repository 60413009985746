export const group = "groups";
export const groupDetail = "group/detail";
export const company = "company";
export const updateCompany = "update-company";
export const listAllCompany = "company/list-all";
export const createCompany = "create-company";
export const uploadAvataCompany = "files/company/upload";
export const createGroup = "create-group";
export const jdList = "job-description/list-all";
export const getJDbyID = "job-description";
export const getMemberOfGroup = "group/recruiters/available";
export const allUser = "group/recruiters";
export const addUserInGroup = "add-recruiter";
export const deleteGroup = "delete-group";
export const getUserDetail = "recruiter/detail"
