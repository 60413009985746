import { CheckCircleOutlined, MoreOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useRef, useState } from "react";
import * as pdfjs from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import AdminService from "../../services/AdminService";
import { cx } from "../../lib/cx";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function CardCV(props) {
  const {
    data,
    onChangeImage,
    onChangeStatus,
    showStatus,
    isChoose,
    className,
    isClick,
  } = props;

  const [isShow, setIsShow] = useState(false);
  const canvasRef = useRef();

  const renderPage = useCallback(async ({ pdfDoc, pageNum, scale }) => {
    const page = await pdfDoc.getPage(pageNum);
    const ctx = canvasRef.current.getContext("2d");
    const viewport = page.getViewport({ scale });

    canvasRef.current.width = viewport.width;
    canvasRef.current.height = viewport.height;

    page.render({ canvasContext: ctx, viewport: viewport });
  }, []);

  useEffect(() => {
    if (data?.image) {
      const { image } = data;
      const fetchPdf = async () => {
        const loadingTask = pdfjs.getDocument({
          src: image,
          url: image,
        });
        const pdfDoc = await loadingTask.promise;
        renderPage({ pdfDoc, pageNum: 1, scale: 1 });
      };
      fetchPdf();
    }
  }, [renderPage, data]);

  const updateStatusCv = (status) => {
    const dataSend = {
      ...status,
      img: data.img,
      email: data.email,
      role: "Quản trị viên",
    };
    AdminService.updateStatusCVByAdmin(dataSend).then(() => {
      onChangeStatus();
    });
  };

  return (
    <div
      className={`w-full h-[330px] bg-white shadow-md relative cursor-pointer ${className}`}
      onClick={() => !showStatus && onChangeImage(data)}
    >
      <div className="absolute bottom-0 left-0 w-full h-[30%] bg-black shadow-md cursor-pointer opacity-60 ease-linear flex justify-end items-end">
        <div className="bg-black w-full h-full p-[15px] flex justify-between items-center">
          <div className="w-full">
            <p className="text-white line-clamp-1 font-bold text-lg relative group">
              {data?.name}
            </p>
            <p className="text-gray-400">{data?.createAt}</p>
          </div>

          <div className="relative">
            <div
              className={`absolute w-[120px] h-auto -bottom-3 right-3 bg-white ${
                isShow ? "block" : "hidden"
              } z-9999`}
            >
              {props.hasOwnProperty("isRecruiter") && props.isRecruiter ? (
                <div
                  className="w-full px-2 text-black hover:bg-[blue] hover:text-white"
                  onClick={() =>
                    updateStatusCv({
                      isChecked: true,
                      isRejected: false,
                    })
                  }
                >
                  Lưu
                </div>
              ) : (
                <>
                  <div
                    className="w-full px-2 text-black hover:bg-[blue] hover:text-white"
                    onClick={() =>
                      updateStatusCv({
                        isChecked: true,
                        isRejected: false,
                      })
                    }
                  >
                    Chấp nhận
                  </div>
                  <div
                    className="w-full px-2 text-black hover:bg-[blue] hover:text-white"
                    onClick={() =>
                      updateStatusCv({
                        isChecked: false,
                        isRejected: true,
                      })
                    }
                  >
                    Từ chối
                  </div>
                  <div
                    className="w-full px-2 text-black hover:bg-[blue] hover:text-white"
                    onClick={() => setIsShow(false)}
                  >
                    Thoát
                  </div>
                </>
              )}
            </div>
            <div
              className={`absolute w-[160px] h-[100px] -bottom-6 right-4 bg-white opacity-0 z-[998]`}
              onMouseMove={() => setIsShow(false)}
            ></div>
            <MoreOutlined
              style={{ color: "white", fontSize: "18px", zIndex: "99999" }}
              onClick={() => isClick && setIsShow(!isShow)}
            />
          </div>
        </div>
      </div>
      {isChoose && isChoose === data.img && (
        <div className="w-full z-9999 absolute h-full flex justify-center items-center pb-[10%]">
          <CheckCircleOutlined style={{ color: "green", fontSize: "80px" }} />
        </div>
      )}
      <div
        className="w-full h-full"
        onClick={() => showStatus && onChangeImage(data)}
      >
        <canvas ref={canvasRef} className="max-w-full max-h-full" />
      </div>
    </div>
  );
}

export const CardCV3 = ({ data, onChangeImage }) => {
  const canvasRef = useRef();

  const renderPages = useCallback(async ({ pdfDoc, scale }) => {
    const ctx = canvasRef.current.getContext("2d");
    let totalHeight = 0;

    for (let i = 1; i <= pdfDoc.numPages; i++) {
      const page = await pdfDoc.getPage(i);
      const viewport = page.getViewport({ scale });
      canvasRef.current.width = Math.max(
        canvasRef.current.width,
        viewport.width
      );
      totalHeight += viewport.height;
    }

    canvasRef.current.height = totalHeight;

    let offsetY = 0;

    for (let i = 1; i <= pdfDoc.numPages; i++) {
      const page = await pdfDoc.getPage(i);
      const viewport = page.getViewport({ scale });
      const tempCanvas = document.createElement("canvas");
      const tempCtx = tempCanvas.getContext("2d");

      tempCanvas.width = viewport.width;
      tempCanvas.height = viewport.height;

      const renderedPage = await page.render({
        canvasContext: tempCtx,
        viewport: viewport,
      });
      await renderedPage.promise;

      ctx.drawImage(tempCanvas, 0, offsetY);
      offsetY += viewport.height;
    }
  }, []);

  useEffect(() => {
    if (data?.image) {
      const { image } = data;
      const fetchPdf = async () => {
        const loadingTask = pdfjs.getDocument({
          src: image,
          url: image,
        });
        const pdfDoc = await loadingTask.promise;
        renderPages({ pdfDoc, scale: 1.6 });
      };

      fetchPdf();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderPages, data]);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handlePdfClick = (event) => {
    event.stopPropagation(); 
  };

  return (
    <div
      className={cx(
        `overflow-x-auto xl:py-0 fixed inset-0 z-9999 h-[100vh] outline-none focus:outline-none`
      )}
    >
      <div className="relative py-[120px] w-full h-full">
        <div
          className="absolute top-0 left-0 w-full h-[100vh] bg-black opacity-20"
          onClick={onChangeImage}
        ></div>
        <div className="flex justify-center items-center mx-auto w-full px-[10px] py-[72px] h-full select-none">
          <div
            className="w-[700px] h-[90vh] relative cursor-pointer z-[9999]"
            onClick={() => onChangeImage(data)}
          >
            <div
              className="w-full h-full p-1"
              style={{ overflow: "auto", height: "100%" }}
            >
              <div style={{ maxWidth: "100%", borderRadius: "20px" }}>
                <canvas
                  ref={canvasRef}
                  className="w-full z-[99999]"
                  onClick={handlePdfClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardCV;
