import React from "react";
import TagCompany from "../Tag/TagCompany";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

function CardCompany(props) {
  const { index, type, data, onHandleClick, isAdd, className, onChangeAdd } =
    props;
  const colors = [
    "#0055FF",
    "#FF9900",
    "#5E2E86",
    "#5E2E86",
    "#FFF500",
    "#EF0032",
    "#00FF1A",
  ];
  return (
    <div
      className={`w-full h-[260px] rounded-[26px] bg-white cursor-default ${className}`}
    >
      <div
        className="w-full h-[30px] rounded-tl-[26px] rounded-tr-[26px]"
        style={{ background: `${colors[index % 7]}` }}
      ></div>
      <div className="p-[10px] w-full">
        {type === 1 ? (
          <div className="mb-[20px]">
            <Tooltip title={data?.groupName}>
              <div className="text-black text-[18px] line-clamp-2 font-[700] mb-3 w-full min-h-[50px] max-h-[50px]">
                Nhóm: {data?.groupName}
              </div>
            </Tooltip>

            <p className="text-black font-[600]">
              Số lượng nhân sự: {data?.numberEmployee}
            </p>
            <Tooltip title={data?.job}>
              <div className="text-black text-[14px] font-[600] min-h-[45px] max-h-[45px] line-clamp-2">
                Dự án: {data?.job}
              </div>
            </Tooltip>
          </div>
        ) : (
          <div>
            <div className="w-[90px] h-[90px] flex justify-center items-center">
              <img
                src={data?.avatar}
                alt=""
                className="max-w-full max-h-full object-cover"
              />
            </div>
            <p className="text-[20px] font-[600] text-black">Công ty</p>
            <p className="line-clamp-1 text-black">{data?.companyName}</p>
          </div>
        )}
        <div className="w-full grid grid-cols-5 gap-2">
          <div className="col-span-3">
            <TagCompany
              type={1}
              title={`Số lượng JD: ${data?.countJds || 0}`}
            />
            <TagCompany
              type={2}
              title={`Số ứng viên: ${data?.countCandidate || 0}`}
            />
          </div>
          <div className="col-span-2 flex-row justify-end items-end">
            {isAdd ? (
              <div
                className="px-3 py-1 text-white bg-[#268D61] rounded-[32px] cursor-pointer text-[11px] mb-1"
                onClick={() => {
                  onChangeAdd(data?.id);
                }}
              >
                Thêm nhóm +
              </div>
            ) : (
              <div className="w-full h-[30px]"></div>
            )}
            <div
              className="px-3 py-1 text-white bg-[#1495F3] flex justify-center items-center rounded-[32px] cursor-pointer text-[12px]"
              onClick={onHandleClick}
            >
              Chi tiết{" "}
              <ArrowRightOutlined
                style={{ color: "white", fontSize: "12px", marginLeft: "5px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardCompany;
