import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";
import SelectItem from "../../components/SelectItem";
import { FileOpenOutlined, SaveAs } from "@mui/icons-material";
import { DATA_ARRAY_FONT, DATA_FONT_SIZE, getListColor } from "../configs/data";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  changeActions,
  changeSetting,
  changeValidateForm,
  selectSetting,
  selectValidateForm,
} from "../redux/slice/setting";
import TagColor from "../components/Tag/TagColor";
import TagFontSize from "../components/Tag/TagFontSize";
import { debounce } from "../../utils";
import { useTheme } from "../../lib/theme/useTheme";
import { cx } from "../../lib/cx";

function SettingComponent({ onHandleExportPDF, onHandleSave, onHandleReview }) {
  const { theme } = useTheme();
  const setting = useAppSelector(selectSetting);
  const { cvName } = useAppSelector(selectValidateForm);
  const dispatch = useAppDispatch();

  const handleSettingChange = (field, value) => {
    dispatch(changeSetting({ field, value }));
    dispatch(changeValidateForm({ field: "cvName", value: "" }));
  };

  return (
    <div className="w-full mt-4 bg-white">
      <div className="w-full px-2 py-4 sm:flex sm:justify-between sm:items-center mx-auto lg:max-w-[1175px]">
        <div className="">
          <p className="text-black font-[600] text-[28px]">Tên cv</p>
          <input
            type="text"
            name="cvName"
            value={setting.cvName}
            onChange={(e) => handleSettingChange(e.target.name, e.target.value)}
            placeholder="Nhập tên cv của bạn"
            className={cx(
              "w-[350px] border border-t-0 border-x-0 border-b-1 focus:outline-none",
              cvName && "border-b-[red]"
            )}
          />
        </div>
        <div className="my-3 sm:my-0 flex justify-center items-center">
          <div
            className={cx(
              "text-[14px] px-4 py-1 mr-2 text-white rounded-[18px] cursor-pointer",
              theme?.button?.background
            )}
            onClick={onHandleReview}
          >
            <EyeOutlined style={{ fontSize: "16px", marginRight: "4px" }} />
            Xem trước
          </div>
          <div
            className={cx(
              "text-[14px] px-4 py-1 mr-2 text-white rounded-[18px] cursor-pointer",
              theme?.button?.background
            )}
            onClick={onHandleExportPDF}
          >
            <FileOpenOutlined
              style={{ fontSize: "16px", marginRight: "4px" }}
            />
            Tải xuống
          </div>
          <div
            className={cx(
              "text-[14px] px-4 py-1 text-white rounded-[18px] cursor-pointer",
              theme?.button?.background
            )}
            onClick={debounce(() => onHandleSave(), 100)}
          >
            <SaveAs style={{ fontSize: "16px", marginRight: "4px" }} />
            Lưu CV
          </div>
        </div>
      </div>
      <div className="w-full h-auto sm:flex mx-auto lg:max-w-[1175px]">
        {getListColor(setting?.templateName) &&
          getListColor(setting.templateName).length > 1 && (
            <div className="sm:w-[250px] h-auto py-[20px] border border-gray-1">
              <p className="text-center">Tông màu</p>
              <div className="flex space-x-1 justify-center items-center mt-2">
                {getListColor(setting.templateName).map((color, idx) => (
                  <TagColor
                    key={"color" + idx + "items"}
                    name="themeColor"
                    color={color}
                    activeColor={setting.themeColor}
                    onChangeColor={handleSettingChange}
                  />
                ))}
              </div>
            </div>
          )}
        <div className="sm:w-[250px] h-auto py-[10px] border border-gray-1">
          <p className="text-center">Cỡ chữ</p>
          <div className="flex justify-center items-center mt-2">
            <TagFontSize
              name="fontSize"
              data={DATA_FONT_SIZE}
              active={setting.fontSize}
              onChangeValue={handleSettingChange}
            />
          </div>
        </div>
        <div className="sm:w-[250px] h-auto py-[10px] border border-gray-1">
          <p className="text-center">Font chữ</p>
          <div className="flex justify-center items-center mt-2">
            <SelectItem
              name="fontFamily"
              data={DATA_ARRAY_FONT}
              valueInput={setting.fontFamily}
              title="Font family"
              onChangeValue={(e) => {
                handleSettingChange(e.target.name, e.target.value);
              }}
              parentClass="w-[180px] border"
              className="!py-1"
              childrenClass="boder"
            />
          </div>
        </div>
        <div className="sm:w-[150px] h-auto py-[10px] border border-gray-1">
          <p className="text-center">Thêm mục</p>
          <div
            className="flex justify-center items-center mt-4"
            onClick={() => {
              dispatch(changeActions({ field: "openAddMore" }));
            }}
          >
            <div className="w-[32px] h-[32px] rounded-[50%] bg-[#333] flex justify-center items-center cursor-pointer">
              <PlusOutlined
                style={{
                  color: "white",
                  fontSize: "22px",
                  fontWeight: "600px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingComponent;
