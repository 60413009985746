import React from "react";
import AvatarDefaultCv from "../../components/Avatar";
import { useAppSelector } from "../../redux/hooks";
import {
  selectFormOrder,
  selectFormToShow,
  selectSetting,
} from "../../redux/slice/setting";
import NamePositionBlock from "../../components/Block/NamePositionBlock";
import ObjectiveComponent from "../../components/SubComponent/ObjectiveComponent";
import PresenterComponent from "../../components/SubComponent/PresenterComponent";
import SubDoubleComponent from "../../components/SubComponent/SubDoubleComponent";
import ContactBlock from "../../components/Block/ContactBlock";

function LeftComponent() {
  const { themeColor } = useAppSelector(selectSetting);
  const { left } = useAppSelector(selectFormOrder);
  const formToShow = useAppSelector(selectFormToShow);

  const formTypeToComponent = {
    objective: (
      <ObjectiveComponent
        form="objective"
        titleColor={themeColor}
        border="!w-[45%]"
        position
      />
    ),
    skills: (
      <SubDoubleComponent
        form="skills"
        listTitle={{ title: "Tên kĩ năng" }}
        other="!text-black placeholder:text-gray font-[600]"
        position="other"
        titleColor={themeColor}
        border="w-[25%]"
      />
    ),
    presenter: (
      <PresenterComponent
        form="presenter"
        listTitle={{
          title: "Ông/Bà Họ tên người giới thiệu",
          description: "Chức vụ",
          email: "Email",
          phone: "Điện thoại",
        }}
        other="!text-black placeholder:text-gray"
        position="other"
        titleColor={themeColor}
        border="w-[68%]"
      />
    ),
  };
  return (
    <div>
      <div className="w-full flex justify-center items-center">
        <div className="w-[265px] h-[265px] p-4">
          <AvatarDefaultCv
            shape={`rounded-full`}
            className="!bg-gray-1"
            styleCss={{ border: `4px solid ${themeColor}` }}
          />
        </div>
      </div>
      <NamePositionBlock
        headCss={`text-center`}
        childCss="text-center italic"
      />
      <ContactBlock
        titleColor={themeColor}
        className="placeholder:!text-gray"
      />
      <div className="mt-2">
        {left &&
          left.map((field) => {
            if (formToShow[field]) {
              const component = formTypeToComponent[field];
              return <React.Fragment>{component}</React.Fragment>;
            }
            return null;
          })}
      </div>
    </div>
  );
}

export default LeftComponent;
