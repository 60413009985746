import { EditOutlined } from "@ant-design/icons";
import queryString from "query-string";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import CardCvShow from "../components/Card/CardCvShow";
import dayjs from "dayjs";

function CvAppliedPost(props) {
  const { data, onChangeCv } = props;
  const history = useHistory();
  const location = useLocation();
  return (
    <div className="w-full">
      <div className="w-full px-8 py-5 bg-white rounded-[16px]">
        <p className="text-black text-[18px] text-center font-[600]">
          Các CV đã nhận được
        </p>
        <div
          className="text-white px-4 py-2 text-[14px] flex justify-center items-center text-center bg-[#0055FF] mt-2 rounded-[12px] cursor-pointer"
          onClick={() => {
            history.push(
              location.pathname + "?" + queryString.stringify({ show: "all" })
            );
          }}
        >
          <EditOutlined
            style={{ font: "14px", marginRight: "6px", color: "white" }}
          />{" "}
          Xem tất cả CV ứng viên
        </div>
      </div>
      <div className="w-full my-4">
        {data &&
          data.length > 0 &&
          data.map(
            (cv, index) =>
              index < 10 && (
                <CardCvShow
                  data={{
                    name: cv?.cvName || "",
                    createAt: dayjs(new Date(cv.applyAt)).format("DD/MM/YYYY"),
                  }}
                  onHandleClick={() =>
                    onChangeCv({ name: cv?.cvName, image: cv.image })
                  }
                />
              )
          )}
      </div>
    </div>
  );
}

export default CvAppliedPost;
