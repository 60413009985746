import { Selects } from "./SelectForm.js";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { routes } from "../screens/PathRouting";
import queryString from "query-string";
import UserService from "../services/UserService.js";
import { cx } from "../lib/cx.js";

const Banner = (props) => {
  const history = useHistory();
  const [inputSearch, setInputSearch] = useState("");
  const [salaries, setSalaries] = useState();
  const [experience, setExperience] = useState();

  useEffect(() => {
    if (!salaries) getDefaultData();
  }, [salaries]);

  const handleRedirectSearch = (item) => {
    history.push(
      routes.workList.path + `?${queryString.stringify({ ...item, page: 1 })}`
    );
  };

  const getDefaultData = () => {
    UserService.getAllOption("salaryrange").then((result) => {
      setSalaries(result.data.data);
    });
    UserService.getAllOption("experience").then((result) => {
      setExperience(result.data.data);
    });
  };

  return (
    <div className={props.className}>
      <form className="w-full z-[50]">
        <div className="w-full px-2 py-2 bg-white mb-2 rounded-[22px] grid grid-cols-10">
          <div className="col-span-1 flex justify-center items-center">
            <img
              src="/asserts/icons/search.png"
              alt=""
              className="w-[16px] h-[16px] object-cover"
            />
          </div>
          <div className="col-span-6 flex">
            <input
              type="text"
              name="inputSearch"
              value={inputSearch}
              onChange={(e) => setInputSearch(e.target.value)}
              placeholder={"Tìm cơ hội việc làm"}
              className="w-full focus:outline-none"
              autoComplete="off"
            />
          </div>
          <div className="col-span-3 flex justify-end items-center">
            <div
              className={cx(
                "px-3 w-full flex justify-center items-center text-white text-[12px] md:text-[14px] rounded-[22px] cursor-pointer h-[40px]",
                props?.bgButton
              )}
              onClick={() => handleRedirectSearch({ jobName: inputSearch })}
            >
              Tìm kiếm
            </div>
          </div>
        </div>

        <div className="w-full space-y-2">
          <Selects
            className="w-full bg-white rounded-[18px] border-none px-2 py-1"
            data={experience}
            title="Tất cả kinh nghiệm"
            icon={
              <img
                src="/asserts/medalstar.png"
                alt=""
                className="w-[20px] h-[20px] mr-2 object-cover"
              />
            }
            bordered={false}
            onChange={(value) => handleRedirectSearch({ experience: value })}
          />
          <Selects
            className="w-full bg-white rounded-[18px] border-none px-2 py-1"
            data={salaries}
            title="Tất cả mức lương"
            icon={
              <img
                src="/asserts/dollarcircle.png"
                alt=""
                className="w-[20px] h-[20px] mr-2 object-cover"
              />
            }
            style={{ width: "100%", zIndex: 10 }}
            onChange={(value) => handleRedirectSearch({ salary: value })}
            bordered={false}
          />
        </div>
      </form>
    </div>
  );
};
export default Banner;
